import axios from "axios"
// 批量删除arr选中的数据，apiStr请求的接口
export async function selectBatchDelete(arr, apiStr) {
    let str = null;
    str = arr.join(",")
    const res = await axios({
        url: apiStr,
        method: "DELETE",
        params: {
            id__in: str,
        }
    })
    return res
};
export async function saveTableStyle(params, tableDataObj, model_name, user) {
    // console.log(tableDataObj,params);
    if (tableDataObj.id == undefined) {
        let res = await axios({
            method: "POST",
            data: {
                user,
                table_header_config: params.headerTable,
                table_css: params.tableStyle,
                model_name,
                permissions: tableDataObj.permissions,
                tag:tableDataObj.tag
            },
            url: "util_flow/sys_table_config/"
        });
      //  console.log(res);
        if (res.status == 200) {
            return "添加成功"
        } else {
            return "请联系管理员"
        }
        
    } 
    else {
        let res = await axios({
            method: "PUT",
            data: {
                user,
                table_header_config: params.headerTable,
                table_css: params.tableStyle,
                model_name,
                permissions: tableDataObj.permissions,
                tag:tableDataObj.tag
            },
            url: "util_flow/sys_table_config/" + tableDataObj.id + '/'
        });
        if (res.status == 200) {
            return "更改成功"
        } else {
            return "请联系管理员"
        }
    }
}

export async function disposeData(data){
    let arr = []
  //  console.log(data.layout_data.formData.fields);
    data.layout_data.formData.fields.forEach((item,key,index)=>{
        
    })
    return arr
}
