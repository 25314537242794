<template>
    <div>
        <h1 v-if="isSave">回款申请</h1>
        <el-form size="mini" :disabled="backMoneyReadonly" :model="backMoneyObj" :rules="backMoneyRules"
            label-position="top" label-width="130px" ref="backMoneyObjRef1">
            <el-form-item label="客户" prop="customerValue">
                <el-input v-model="backMoneyObj.customerValue" placeholder="客户名称" @keyup.enter.native="isCustomer = true"
                    @blur.stop="isCustomer = true" clearable>
                    <el-button size="mini" slot="prepend" icon="el-icon-search" @click="isCustomer = true"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="是否预付款" prop="is_advance">
                <el-select v-model="backMoneyObj.is_advance" placeholder="请选择" size="mini" clearable>
                    <el-option v-for="item in advanceOption" :key="item.id" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关联订单" prop="order" style="display: flex; flex-wrap: wrap;">
                <div style="flex: 1;">
                    <el-button size="mini" type="primary" style="margin-left: 10px;" @click="onSalesOrder">销售订单</el-button>
                    <div class="paymentInfoBox" v-if="orderObj != null">
                        <el-card v-if="backMoneyObj.is_advance" shadow="hover" class="selectCard">
                            <p>客户名称：{{ orderObj.customer }}</p>
                            <p>编号：{{ orderObj.order_number }}</p>
                            <p>付款方式：预付款</p>
                            <p>总金额：{{ orderObj.field1 }}</p>
                            <!-- <p>交易金额：{{ onAmount(orderObj.sum_should_receive) }}</p> -->
                            <!-- <p>预付款:{{ onAmount(orderObj.prepay_amount) }}</p> -->
                            <!-- <p>剩余应收:{{ onAmount(orderObj.sum_should_receive - orderObj.prepay_amount) }}</p> -->
                            <p>下单日期：{{ orderObj.order_date }}</p>
                            <p>通过时间：{{ orderObj.approved_date }}</p>
                            <template>
                                <i class="el-icon-check" style="position: absolute;top: 10px;right: 20px;"></i>
                            </template>
                        </el-card>
                        <el-card v-else shadow="hover" class="selectCard">
                            <p>客户名称：{{ orderObj.company_name }}</p>
                            <p>编号：{{ orderObj.num }}</p>
                            <p>付款方式：{{ orderObj.payment_method }}</p>
                            <p>交易金额：{{ onAmount(orderObj.sum_should_receive) }}</p>
                            <p>预付款:{{ onAmount(orderObj.prepay_amount) }}</p>
                            <p>剩余应收:{{ onAmount(orderObj.sum_should_receive - orderObj.prepay_amount) }}</p>
                            <p>下单日期：{{ orderObj.order_date }}</p>
                            <p>通过时间：{{ orderObj.approved_date }}</p>
                            <template>
                                <i class="el-icon-check" style="position: absolute;top: 10px;right: 20px;"></i>
                            </template>
                        </el-card>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="实际收款金额" prop="money">
                <el-input size="mini" @focus="getInputFocus($event)" v-model="backMoneyObj.money" type="number"
                    :min="0"></el-input>
            </el-form-item>
            <el-form-item label="实际收款日期" prop="real_return_time">
                <el-date-picker v-model="backMoneyObj.real_return_time" align="right" type="date" placeholder="选择日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="付款账户信息" prop="bank_id" style="display: flex; flex-wrap: wrap;">
                <el-button size="mini" type="primary" style="margin-left: 10px;" @click="focusPaymentInfo">账户信息</el-button>
                <div class="paymentInfoBox" v-if="paymentList.length > 0">
                    <el-card style="margin-bottom: 10px;" shadow="hover" v-for="(item, index) in paymentList" :key="index"
                        @click.native="elCardClick(item, index)"
                        :class="item.id == elCardIndex ? 'selectCard' : 'defaultCard'">
                        <p>账户名称：{{ item.account_title }}</p>
                        <p>账户号码：{{ item.account_number }}</p>
                        <p>开户银行：{{ item.open_bank }}</p>
                        <p>银行行号：{{ item.open_bank_account }}</p>
                        <template v-if="item.id == elCardIndex">
                            <i class="el-icon-check" style="position: absolute;top: 10px;right: 20px;"></i>
                        </template>
                    </el-card>
                </div>
            </el-form-item>
            <el-form-item label="销售订单附件" style="display: flex;flex-wrap: wrap;">
                <el-upload style="margin-left: 10px;" class="upload-demo" ref="upload" action="#" :file-list="fileList"
                    :on-change="uploadFile" :multiple="true" :auto-upload="false" :on-preview="handleFilePreview">
                    <el-button size="mini" slot="trigger" type="plain">点击上传</el-button>
                    <div slot="file" slot-scope="{file}">
                        <div class="uploadFile">
                            <div class="uploadFile_a">
                                <a :href="(file.url || file.file)" target="_blank" rel="noopener noreferrer">
                                    {{ file.name || file.file_name }}</a>
                            </div>
                            <div class="uploadFile_i">
                                <i v-if="file.status != undefined && file.status === 'success'"
                                    class="el-icon-circle-check"></i>
                                <i v-if="file.status != undefined && file.status === 'ready'" style="margin: 0;"
                                    class="el-icon-close el-upload-list__item " @click="handleRemove(file)"></i>
                            </div>
                        </div>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="利息(￥)" prop="interest">
                <el-input size="mini" @focus="getInputFocus($event)" v-model="backMoneyObj.interest" type="number"
                    :min="0"></el-input>
            </el-form-item>

            <el-form-item label="付款类型" prop="return_type">
                <el-select v-model="backMoneyObj.return_type" placeholder="请选择" size="mini" clearable>
                    <el-option v-for="item in paymentTypeList" :key="item.id" :label="item.label" :value="item.label">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="交易法人" prop="impersonal_entity">
                <el-select v-model="backMoneyObj.impersonal_entity" placeholder="请选择" clearable>
                    <el-option v-for="item in impersonal_entityList" :label="item.label" :value="item.label" clearable
                        :key="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="'客户'" :isChoose="true"
            :searchValue="backMoneyObj.customerValue" @onIsVisible="customerVisible" @onApplied="customerApplied">
        </inquireDialog>
        <div v-if="backMoneyObj.is_advance">
            <inquireDialog v-if="isSalesOrder" :isVisible="isSalesOrder" :obj="salesOrderInquireDialogObj" :title="'销售订单'"
                :isChoose="true" :searchValue="salesOrderValue" @onIsVisible="salesOrderVisible"
                @onApplied="salesOrderApplied">
            </inquireDialog>
        </div>
        <div v-else>
            <TableDialog v-if="isSalesOrder" :isTableDialog="isSalesOrder" :tableDialogObj="salesOrderObj"
                :tableDialogTitle="'销售订单'" :isRadio="true" :searchValue="salesOrderValue" :searchField="'search'"
                @onIsTableDialog="salesOrderVisible" @onApplied="salesOrderApplied">
            </TableDialog>
        </div>
        <div v-if="isSave" class="addBack_money_footer">
            <div :class="this.$store.state.iscollapse
                ? 'addBack_money_footer_padding1'
                : 'addBack_money_footer_padding2'
                "></div>
            <div style="width: 100%; border-top: 1px solid #dbd9d9; padding: 5px 15px">
                <el-button size="mini" type="primary" @click="onSaveOrders" style="margin-top: 10px">保存</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import TableDialog from "@/components/ProcessFlow/CRM/Forms/components/TableDialog.vue";
export default {
    components: {
        inquireDialog, TableDialog
    },
    props: ["isAddBackMoney", "readonly", "addBackMoneyCustomer", "backMoneyForm", "formTitle", "addBackMoneyId"],
    data() {
        return {
            orderObj: null,
            printList: ["回款申请"],
            backMoneyReadonly: this.readonly == true ? true : false,
            isSave: null,
            isCustomer: false,
            customerObj: null,               //客户对象
            backMoneyObj: {
                customerValue: null,         //客户名称
                customer: null,              //客户id
                money: null,                 //实际回款金额
                interest: 0,                 //利息
                order: null,
                order_number: null,
                order_obj: null,
                is_advance: false,
                order_file: [],
                return_type: '款到发货',           //付款类型
                real_return_time: null,      //实际回款日期
                // bank_type:'对公账户',       //账户类型
                impersonal_entity: null,     //交易法人
                bank_name: null,             //银行名称
                bank_id: null,             //银行id
                bank_user_name: null,        //账户名称
                bank_num: null,              //账户号码
            },
            customerDialogObj: {
                searchField: "search",
                url: "crm/customer_bulk/",
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: ""
                }
            },
            advanceOption: [
                {
                    label: '是',
                    value: true,
                }, {
                    label: '否',
                    value: false,
                },
            ],
            // 付款类型列表
            paymentTypeList: [],
            // 交易法人列表
            impersonal_entityList: [
                { value: 1, label: '宁波亨博电磁技术有限公司' },
                { value: 2, label: '深圳亨贝智控物联有限公司' },
                { value: 3, label: '宁波亨嘉科技有限公司' },
            ],
            elCardIndex: null,
            // 付款账户信息列表
            paymentList: [],
            // 文件列表
            fileList: [],
            fileFormData: null,
            // 默认日期
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
            salesOrderInquireDialogObj: {
                searchField: 'search',
                url: "crm/order_relationship_table/",
                params: {
                    per_page: 10,
                    page: 1,
                    is_approve: 1,
                    is_invalid: 0,
                    contract_order_type: 1,
                    customer: null,
                    search: "",
                },
            },
            isSalesOrder: false,
            // 销售订单
            salesOrderObj: {
                method: "POST",
                url: "crm/outbound_account_checking/should_receive_board/",
                data: {
                    dimension: "sales_order_id",
                    search: "",
                },
            },
            salesOrderValue: null,
            salesOrderValueObj: null,
            // salesOrderValueOrderNumber: null,
            backMoneyRules: {
                customerValue: [{ required: true, message: "请选择客户", trigger: ["blur", "change"] }],
                money: [{ required: true, message: "请输入实际收款金额", trigger: ["blur", "change"] }],
                is_advance: [{ required: true, message: "请选择回款方式", trigger: ["blur", "change"] }],
                interest: [{ required: true, message: "请输入利息", trigger: ["blur", "change"] }],
                real_return_time: [{ required: true, message: "请选择实际收款日期", trigger: ["blur", "change"] }],
                return_type: [{ required: true, message: "请选择付款类型", trigger: ["blur", "change"] }],
                impersonal_entity: [{ required: true, message: "请选择交易法人", trigger: ["blur", "change"] }],
                bank_id: [{ required: true, message: "付款账户信息" }],
                order: [{ required: true, message: "关联销售订单" }],
            }
        }
    },
    mounted() {
        if (this.addBackMoneyCustomer != undefined) {
            this.customerObj = this.addBackMoneyCustomer.customer;
            this.backMoneyObj.customerValue = this.addBackMoneyCustomer.customer.company_name;
            this.backMoneyObj.customer = this.addBackMoneyCustomer.customer.id;
        }
        this.getPaymentTypeList();
        let today = new Date();
        this.backMoneyObj.real_return_time =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
      //  console.log(this.backMoneyObj.real_return_time);
    },
    watch: {
        backMoneyForm: {
            handler(val) {
                if (this.formTitle == "回款申请") {
                    if (val != undefined) {
                        this.fileList = []
                        val.files.forEach(items => {
                            if (items.suite_field == "order_file") {
                                // console.log("111");
                                this.fileList.push({ name: items.file_name, url: 'https://back.hugtech.cc' + items.file })
                            }
                        })
                      //  console.log(val, '回款申请');
                        this.backMoneyObj.is_advance = val.is_advance == 'true' ? true : false
                        this.backMoneyObj.bank_id = val.bank_id
                        this.backMoneyObj.bank_name = val.bank_name
                        this.backMoneyObj.bank_user_name = val.bank_user_name
                        this.backMoneyObj.creator = val.creator
                        this.backMoneyObj.customer = val.customer
                        this.backMoneyObj.customerValue = val.customerValue
                        this.backMoneyObj.impersonal_entity = val.impersonal_entity
                        this.backMoneyObj.interest = val.interest
                        this.backMoneyObj.money = val.money
                        this.backMoneyObj.order = val.order
                        this.backMoneyObj.order_number = val.order_number
                        this.backMoneyObj.order_obj = val.order_obj
                        if (typeof (val.order_obj) === "string") {
                            this.orderObj = JSON.parse(val.order_obj)
                        } else {
                            this.orderObj = val.order_obj
                        }
                        this.backMoneyObj.files = val.files
                        this.backMoneyObj.num = val.num
                        this.backMoneyObj.real_return_time = val.real_return_time
                        this.backMoneyObj.return_type = val.return_type
                        this.focusPaymentInfo()
                        this.elCardIndex = val.bank_id || val.payment_information
                    }
                }
            },
        },
        backMoneyObj: {
            handler(val) {
                if (this.isAddBackMoney == false && this.addBackMoneyId == undefined) {
                    this.$emit("onAddBackMoney", this.backMoneyObj)
                    // console.log(val);
                }
            },
            deep: true
        },
        readonly: {
            handler(val) {
                if (val == undefined) {
                    this.backMoneyReadonly = false
                } else {
                    this.backMoneyReadonly = this.readonly
                }
            }
        },
        isAddBackMoney: {
            handler(val) {
                if (val == undefined) {
                    this.isSave = true
                } else {
                    this.isSave = val
                }
            },
            deep: true,
            immediate: true,
        },
        "backMoneyObj.customerValue": {
            handler(val) {
                if (val == '' || val == null) {
                    this.backMoneyObj.customer = null;
                    this.backMoneyObj.bank_name = null;
                    this.backMoneyObj.bank_num = null;
                    this.backMoneyObj.bank_user_name = null;
                    this.paymentList = []
                }
            }
        },
        "backMoneyObj.is_advance": {
            handler(val) {
                if (val) {
                    this.backMoneyObj.return_type = '预付款'
                } else {
                    if (this.orderObj != null && this.orderObj.payment_method != undefined && this.orderObj.payment_method != null && this.orderObj.payment_method != '') {
                        this.backMoneyObj.return_type = this.orderObj.payment_method
                    } else {
                        this.backMoneyObj.return_type = '款到发货'
                    }
                }
            }
        },
    },
    methods: {
        getInputFocus(event) {
            event.currentTarget.select();
        },
        handleFilePreview(file) {
          //  console.log(file);
        },
        // 销售订单数据
        async salesOrderApplied(val) {
            // console.log(val);
            if (this.backMoneyObj.is_advance) {
              //  console.log('预付款', val);
                this.salesOrderValueObj = val;
                this.backMoneyObj.order_number = val.order_number;
                this.backMoneyObj.order = val.id;
                this.backMoneyObj.order_obj = JSON.stringify(val);
                this.orderObj = val
                this.backMoneyObj.impersonal_entity = val.impersonal_entity
                this.$refs.backMoneyObjRef1.validateField('order', (error) => {
                    if (error) {
                        this.passwordError = error[0].message;
                    }
                })
            } else {
              //  console.log('付款', val);
                this.salesOrderValueObj = val;
                this.backMoneyObj.order = val.sales_order_id;
                this.backMoneyObj.order_obj = JSON.stringify(val);
                this.orderObj = val
                this.backMoneyObj.return_type = val.payment_method;
                this.backMoneyObj.impersonal_entity = val.impersonal_entity
                this.backMoneyObj.order_number = val.num;
                this.backMoneyObj.money = val.sum_should_receive - val.prepay_amount
                this.$refs.backMoneyObjRef1.validateField('order', (error) => {
                    if (error) {
                        this.passwordError = error[0].message;
                    }
                })
            }

        },
        //销售订单开关
        salesOrderVisible(val) {
            this.isSalesOrder = val;
        },
        // 销售订单按钮
        onSalesOrder() {
            // console.log(this.isSalesOrder);
            if (this.backMoneyObj.is_advance) {
                this.salesOrderValue = this.backMoneyObj.customerValue
            } else {
                if (this.backMoneyObj.customer != null && this.backMoneyObj.customer != '') {
                    this.salesOrderObj.data.customers = [this.backMoneyObj.customer]
                }
            }

            this.isSalesOrder = true;
        },
        // 保存
        async onSaveOrders() {
            // this.submitUpload();
            // console.log(this.backMoneyObj);

            this.$refs.backMoneyObjRef1.validate(async (valid) => {
                if (valid) {
                    if (this.backMoneyObj.order == null || this.backMoneyObj.order == undefined) {
                        return this.$message.error('请添加销售订单')
                    }
                    const formdata = new FormData()
                    for (let item in this.backMoneyObj) {
                        if (item == 'order_file') {
                        } else {
                            formdata.append(item, this.backMoneyObj[item])
                        }
                    }
                    this.$refs.upload.uploadFiles.forEach(items => {
                        formdata.append('order_file', items.raw)
                    })
                    let res = await this.$http({
                        url: 'crm/return_money/',
                        method: 'POST',
                        headers: {
                            "Content-Type": "multipart/form-data; boundary=--------------------------051005589955791443255030",
                        },
                        data: formdata
                    })
                    if (res.status == 200) {
                        this.$message.success("提交成功")
                    }
                }
            })

        },
        focusPaymentInfo() {
          //  console.log(this.backMoneyObj.customer);
            if (this.backMoneyObj.customer != null && this.backMoneyObj.customer != "") {
                this.getPaymentInfoList();
            } else {
                this.$message.warning("请先选择客户")
            }
        },
        // 卡片点击
        async elCardClick(item) {
            if (this.backMoneyReadonly) {
                return
            }
            this.elCardIndex = item.id;
            this.backMoneyObj.bank_num = item.account_number;
            this.backMoneyObj.bank_id = item.id;
            this.backMoneyObj.bank_user_name = item.account_title;
            this.backMoneyObj.bank_name = item.open_bank;
            this.$refs.backMoneyObjRef1.validateField('bank_id', (error) => {
                if (error) {
                    this.passwordError = error[0].message;
                }
            })
        },
        // 上传文件改变
        uploadFile(file, fileList) {
            this.backMoneyObj.order_file = []
            fileList.forEach(items => {
              //  console.log(items);
                if (items.status == "ready") {
                    let obj = {
                        uid: items.raw.uid,
                        lastModified: items.raw.lastModified,
                        lastModifiedDate: items.raw.lastModifiedDate,
                        name: items.raw.name,
                        size: items.raw.size,
                        type: items.raw.type,
                        webkitRelativePath: items.raw.webkitRelativePath,
                    }
                    this.backMoneyObj.order_file.push(obj)
                }
            });
            // this.isChangeImg = true
            // const formdata = new FormData();
            // console.log(file);
            // formdata.append("order_file", file.raw);
            // this.backMoneyObj.order_file.psuh(formdata)
          //  console.log(this.backMoneyObj.order_file);
            // // formdata.append("form_id", this.form_id);
            // this.fileFormData = formdata
            // console.log(this.fileFormData);
        },
        handleRemove(val) {
            let index = this.fileList.indexOf(val)
            this.fileList.splice(index, 1)
          //  console.log(val);
        },
        async submitUpload() {
            if (this.isChangeImg) {
                const file = await this.$http({
                    method: "PATCH",
                    url: "oa/oa_form_add_files/",
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                    data: this.fileFormData,
                });
                this.$message({
                    type: "success",
                    message: file.data,
                });
              //  console.log(file);
            }
        },
        // 获取开票资料
        async getPaymentInfoList() {
            if (this.backMoneyObj.bank_id != null) {
                let res = await this.$http.get("crm/payment_information/customer_payment_information/", { params: { customer_id: this.backMoneyObj.customer, id: this.backMoneyObj.bank_id } });
                // console.log(res.data);
                this.paymentList = res.data
            } else {
                let res = await this.$http.get("crm/payment_information/customer_payment_information/", { params: { customer_id: this.backMoneyObj.customer } });
                // console.log(res.data);
                this.paymentList = res.data
            }
        },
        // 获取付款类型
        async getPaymentTypeList() {
            let res = await this.$http.get("app02/label_control/label/", { params: { url: 'paymenttype/' } })
          //  console.log(res.data);
            this.paymentTypeList = res.data
        },
        // 客户Dialog开关
        customerVisible(data) {
            // console.log(data);
            this.isCustomer = data
        },
        // 客户Dialog数据
        customerApplied(data) {
            this.customerObj = data
            this.backMoneyObj.customer = data.id
            // console.log(data);
            this.backMoneyObj.customerValue = data.company_name
        },
        onAmount(item) {
            return (item * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
    }
}
</script>
<style lang="less" scoped>
.addBack_money_footer {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    display: flex;
}

.addBack_money_footer_padding1 {
    min-width: 64px;
}

.addBack_money_footer_padding2 {
    min-width: 222px;
}

.paymentInfoBox {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    .el-card {
        margin: 0px 10px 0px 0px;
        min-width: 300px;
        border-radius: 12px;

        &:hover {
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px;
            margin-top: 0px;
            // transition: all 0.5s;/*持续时间*/
        }
    }
}

.selectCard {
    position: relative;
    border: 1px solid rgb(143, 195, 31);
    color: rgb(143, 195, 31);

    .el-icon-check {
        font-size: 20px;
    }
}

.defaultCard {
    position: relative;
}

.el-input,
.el-select {
    width: 100%;
}

.uploadFile {
    display: flex;
    justify-content: space-between;
    align-content: center;
    // padding: 10px;

    .uploadFile_a {
        a {
            color: #606266;
            text-decoration: none;
        }
    }

    .uploadFile_i {
        margin-left: 10px;

        .el-icon-circle-check {
            color: #409EFF;
        }
    }

    &:hover {
        .uploadFile_a {
            a {
                color: rgb(143, 195, 31);
            }
        }
    }
}
</style>