<template>
  <div class="fc-relevancy" v-if="isDisabled != 0">
    <div>{{ label }}</div>
    <div v-if="isRelevancyTable" v-bind="$props" style="
        border:1px dashed #8fc31f;
        padding: 10px;
        border-radius: 12px;
        margin-bottom: 10px;
      ">
      <template v-for="(item, i) in  relevancyList ">
        <div class="relevancy" :key="i" v-if="relevancyList.length > 0">
          <div class="relevancy_title">
            <div>产品{{ i + 1 }}</div>
            <i class="el-icon-close" @click="deleteRelevancy(i)"></i>
          </div>
          <el-form :label-position="'top'" :ref="'relevancyform' + i" label-width="80px" :model="item" size="mini">
            <template v-for="(items, j) in  controlChildren ">
              <el-form-item
                v-if="(requiredObj.length > 0 && (requiredObj[j].formOperate !== 0)) || requiredObj.length == 0"
                :key="items.vModel" :label="items.label" :prop="items.vModel" :rules="rules[items.vModel]">
                <el-input v-model="item[items.vModel]"
                  :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                  placeholder="请输入内容" v-if="items.type == 'string'"></el-input>
                <el-input v-model="item[items.vModel]"
                  :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                  type="textarea" :autosize="{ minRows: 3, maxRows: 16 }" placeholder="请输入内容"
                  v-else-if="items.type == 'textarea'"></el-input>
                <el-input v-model="item[items.vModel]"
                  :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                  type="number" min="0" placeholder="请输入内容" v-else-if="items.type == 'number'"></el-input>
                <el-select v-else-if="items.type == 'select'"
                  :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                  v-model="item[items.vModel]" placeholder="请选择" clearable>
                  <el-option v-for=" item  in  items.option " :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-input v-else-if="items.type == 'object'"
                  :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true" size="mini"
                  v-model="item[items.vModel]" clearable @keyup.enter.native="
                    onProductSearch(item[items.vModel], items, i)
                    " @blur.stop="onProductSearch(item[items.vModel], items, i)"
                  @change="onProductChange(item[items.vModel], items, i)">
                  <el-button :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                    size="mini" slot="prepend" icon="el-icon-search"
                    @click="onProductSearch(item[items.vModel], items, i)"></el-button>
                </el-input>
              </el-form-item>
            </template>
          </el-form>
        </div>
      </template>
      <el-button style="
        width: 100%;
        border:none;
        border-radius: 12px;
      " @click="addRelevancy"
        :disabled="requiredObj.length == 0 ? false : requiredObj[0].formOperate == 2 ? false : true">
        <i class=" el-icon-plus"></i>添加
      </el-button>
    </div>
    <div v-else>
      <template v-for="(item, i) in  relevancyList">
        <div :key="item" class="relevancyTableForm">
          <el-form :ref="'relevancyform' + i" label-position="right" label-width="80px" :model="item" size="mini">
            <template v-for="(items, j) in  controlChildren ">
              <div style="display: flex;">
                <div style="min-width: 80px;"></div>
                <div style="display: flex;">
                  <div style="min-width: 120px; ">旧</div>
                  <div style="width: 100%;">新</div>
                </div>
              </div>
              <el-form-item style="display: flex;"
                v-if="(requiredObj.length > 0 && (requiredObj[j].formOperate !== 0)) || requiredObj.length == 0"
                :key="items.vModel" :label="items.label" :prop="items.vModel" :rules="rules[items.vModel]">
                <div style="display: flex;">
                  <div style="min-width: 120px;" v-if="items.type !== 'object'">
                    <span
                      v-if="relevancyList[0][objectKey][items.vModel] == null || relevancyList[0][objectKey][items.vModel] == undefined"></span>
                    <span v-else-if="items.type == 'array'">{{ relevancyList[0][objectKey][items.vModel].join(',')
                    }}</span>
                    <span v-else>{{ items.label == '账期' ? relevancyList[0][objectKey][items.vModel] == 0 ||
                      relevancyList[0][objectKey][items.vModel] == '' ? 'NA' :
                      relevancyList[0][objectKey][items.vModel] : relevancyList[0][objectKey][items.vModel] }}</span>
                  </div>
                  <div style="width: 100%;">
                    <el-input v-model="item[items.vModel]"
                      :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                      placeholder="请输入内容" v-if="items.type == 'string'"></el-input>
                    <el-input v-model="item[items.vModel]"
                      :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                      type="textarea" :autosize="{ minRows: 3, maxRows: 16 }" placeholder="请输入内容"
                      v-else-if="items.type == 'textarea'"></el-input>
                    <el-input v-model="item[items.vModel]"
                      :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                      type="number" min="0" placeholder="请输入内容" v-else-if="items.type == 'number'"></el-input>
                    <el-select v-else-if="items.type == 'select'" style="width:100%"
                      :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                      v-model="item[items.vModel]" placeholder="请选择" clearable>
                      <el-option v-for=" item  in  items.option " :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <template v-else-if="items.type == 'object'">
                      <el-input
                        :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                        size="mini" v-model="item[items.vModel][objectKey]" clearable @keyup.enter.native="
                          onProductSearch(item[items.vModel], items, i)
                          " @blurs.stop="onProductSearch(item[items.vModel], items, i)"
                        @change="onProductChange(item[items.vModel], items, i)">
                        <el-button
                          :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true"
                          size="mini" slot="prepend" icon="el-icon-search"
                          @click="onProductSearch(item[items.vModel], items, i)"></el-button>
                      </el-input>
                    </template>
                    <div v-else-if="items.type == 'array'">
                      <DateRangePicker v-model="item[items.vModel]" :defaultValue="item[items.vModel]"
                        :disabled="requiredObj.length == 0 ? false : requiredObj[j].formOperate == 2 ? false : true">
                      </DateRangePicker>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-form>
        </div>
      </template>
    </div>
    <inquireDialog v-if="dialogstr != null &&
      dialogObj != null &&
      dialogObj[dialogstr + 'Obj'].isDialog
      " :isChoose="!isRelevancyTable" :obj="dialogObj[dialogstr + 'Obj']"
      :isVisible="dialogObj[dialogstr + 'Obj'].isDialog" :title="dialogObj[dialogstr + 'Obj'].title"
      :searchValue="dialogObj[dialogstr + 'Obj'].params.search" @onIsVisible="onIsVisible" @onApplied="onApplied">
    </inquireDialog>
  </div>
</template>
<script>
import DateRangePicker from "@/components/OA/components/FormControls/DateRangePicker";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
export default {
  name: "fc-relevancy",
  model: {
    prop: "value",
    event: "change",
  },
  props: [
    "value",
    "label",
    "tag",
    "conf",
    "isRelevancyTable",
    "tagIcon",
    "defaultValue",
    "controlChildren",
    "requiredObj",
    "disabled",
    "required",
    "style",
    "changeTag",
    "proCondition",
    "formId",
    "renderKey",
    "layout",
  ],
  data() {
    return {
      isFcRelevancy: null,
      relevancyList: this.defaultValue,
      relevancyObj: {},
      dialogObj: null,
      dialogstr: null,
      dialogindex: null,
      objectKey: null,
      relevancyTableList: null,
    };
  },
  watch: {
    // isRelevancyTable: {
    //   handler(val) {
    //   //  console.log(val);
    //     this.$set(this, 'isFcRelevancy', val)
    //     // this.isFcRelevancy = val
    //   }
    // },
    value: {
      handler(val) {
        // console.log(val, "relevancyList1");
      },
    },
    controlChildren: {
      handler(val) {
        let obj = {};
        val.forEach((item) => {
          // this.relevancyObj[item.vModel] = null;
          if (item.type == "object") {
            if (!this.isRelevancyTable) {
              this.objectKey = item.vModel
              this.relevancyObj[item.vModel] = {}
              this.relevancyObj[item.vModel][item.vModel] = null
            } else {
              this.relevancyObj[item.vModel] = null;
            }
            obj[item.vModel + "Obj"] = {};
            obj[item.vModel + "Obj"].url = item.url;
            obj[item.vModel + "Obj"].isDialog = false;
            obj[item.vModel + "Obj"].title = item.label;
            obj[item.vModel + "Obj"].searchField = "search";
            obj[item.vModel + "Obj"].params = {
              per_page: 10,
              page: 1,
              search: "",
              distinct: 1,
            };
          } else {
            this.relevancyObj[item.vModel] = null;
          }
        });
        this.dialogObj = JSON.parse(JSON.stringify(obj));
        if (this.defaultValue.length == 0 && this.relevancyList.length == 0) {
          this.relevancyList = [];
          this.relevancyList.push(
            JSON.parse(JSON.stringify(this.relevancyObj))
          );
        } else {
          if (!this.isRelevancyTable) {
            // console.log(this.relevancyList[0][this.objectKey], "6666666")
          }
        }
      },
      deep: true,
      immediate: true,
    },
    defaultValue: {
      handler(val) {
        // console.log(val, "66666")
      },

    },
    relevancyList: {
      handler(val) {
        this.$emit("change", JSON.parse(JSON.stringify(val)));
      },
      deep: true,
    },

  },
  components: { inquireDialog, DateRangePicker },
  computed: {
    rules() {
      let obj = {}
      // console.log(this.controlChildren)
      this.controlChildren.forEach(items => {
        let arr = []
        obj[items.vModel] = arr
        if (items.required) {
          if (items.type == "select") {
            obj[items.vModel].push({
              required: items.required,
              message: "请选择" + items.label,
              trigger: ["change", "blur"]
            })
          } else {
            obj[items.vModel].push({
              required: items.required,
              message: "请输入" + items.label,
              trigger: ["change", "blur"]
            })
          }
        }
      })
      // console.log(obj);
      return obj
    },
    requiredArr() {
      let arr = []
      this.requiredObj.forEach(item => {
        arr.push(item.formOperate)
      })
      return arr
    },
    isDisabled() {
      let num = null
      this.requiredObj.forEach(item => {
        num += item.formOperate
      })
      return num
    }
  },
  created() {
  },
  methods: {
    addRelevancy() {
      this.relevancyList.push(this.relevancyObj);
    },
    onProductSearch(text, val, index) {

      if (this.isRelevancyTable) {
        this.dialogstr = val.vModel;
        this.dialogObj[this.dialogstr + "Obj"].params.search = text;
        this.dialogObj[this.dialogstr + "Obj"].isDialog = true;
        this.dialogindex = index;
      } else {
        this.dialogstr = val.vModel;
        this.dialogObj[this.dialogstr + "Obj"].params.search = text[this.objectKey];
        this.dialogObj[this.dialogstr + "Obj"].isDialog = true;
        this.dialogindex = index;
        // console.log(text)
        // console.log(val)
        // console.log(index)
      }

    },
    onProductChange(text, val, index) {
      if (this.isRelevancyTable) {
        this.dialogstr = val.vModel;
        this.dialogObj[this.dialogstr + "Obj"].isDialog = true;
        this.dialogObj[this.dialogstr + "Obj"].params.search = text;
        this.dialogindex = index;
      } else {
        this.dialogstr = val.vModel;
        this.dialogObj[this.dialogstr + "Obj"].isDialog = true;
        this.dialogObj[this.dialogstr + "Obj"].params.search = text[this.objectKey];
        this.dialogindex = index;
      }
    },
    onIsVisible(val) {
      this.dialogObj[this.dialogstr + "Obj"].isDialog = val;
    },
    onApplied(val) {
      if (this.isRelevancyTable) {
        // console.log(val);
        let arr = [];
        let arr1 = Object.keys(this.relevancyObj);
        val.forEach((item) => {
          let obj = {};
          arr1.forEach((items) => {
            if (item[items] != undefined) {
              obj[items] = item[items];
            } else {
              obj[items] = null;
            }
          });
          arr.push(obj);
        });
        this.relevancyList.splice(this.dialogindex, 1, ...arr);
        // console.log(this.dialogindex);
      } else {
        let arr1 = Object.keys(this.relevancyObj);
        let obj = {}
        arr1.forEach(item => {
          obj[item] = val[item]
        })
        obj.id = val.id
        // console.log(obj, '6666');
        this.relevancyList[0][this.objectKey] = obj
        // console.log(val.id)
        this.getlist(val)
      }
    },
    async getlist(val) {
      const res = await this.$http({
        method: "GET",
        url: `crm/customer/detail/${val.id}/`,
      })
      if (res.status == 200) {
        const list = JSON.parse(JSON.stringify(res.data.data))
        this.relevancyTableList = list
        // console.log(res.data.data);
        let arr1 = Object.keys(this.relevancyObj);
        arr1.forEach(item => {
          if (item == this.objectKey) {
          } else {
            this.relevancyList[0][item] = list[item]
            // console.log(list[item], item);
          }
        })
      }
    },
    deleteRelevancy(val) {
      if (this.relevancyList.length > 1) {
        this.relevancyList.splice(val, 1);
      } else {
      }
    },
  },
};
</script>
<style lang="less" scoped>
.relevancyTableForm {
  .el-form {
    .el-form-item {
      /deep/.el-form-item__content {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }
}

.fc-relevancy {}

.required {
  color: #f2725e;
}

.relevancy {
  border: 1px solid rgb(220, 223, 230);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;

  .relevancy_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-input,
  .el-textarea,
  .el-select {
    width: 100%;
  }

  // .relevancy_comment {
  //   width: 100%;
  //   display: flex;
  //   margin-bottom: 10px;

  //   .relevancy_comment_label {
  //     min-width: 70px;
  //   }

  //   .relevancy_comment_vModel {
  //     width: 90%;
  //   }

  //   .el-select {
  //     width: 100%;
  //   }
  // }
}
</style>
