<template>
    <div style="position: relative;" v-if="isSettingLoading">
        <el-popover v-if="!readonly" placement="top" trigger="hover">
            <div style="text-align: center;">
                设置表单
            </div>
            <i slot="reference" class="el-icon-setting" v-if="role_ids.includes(3)"
                style="cursor: pointer;position: absolute;top: 0;right:0;z-index: 1;font-size: 17px;"
                @click="onIsSetting"></i>
        </el-popover>
        <el-form v-if="inline" :ref="refs" :inline="inline" :disabled="readonly" label-width="100px" :model="formData"
            :rules="rules" size="mini" class="form_inline_true">
            <template v-for="(item, i) in settingForm.control_setting">
                <el-form-item :label="item.basic_settings.show_name"
                    :class="[item.type == '多文本输入框' || item.type == '表格' ? 'input_textarea' : '']"
                    :prop="item.type == '对象(单选)' ? item.basic_settings.field + '.label' : item.basic_settings.field"
                    :key="i" v-if="onIsRoleId(item.visible_rule.role_limit, role_ids) && item.permissions.show">
                    <template v-if="item.type === '文本输入框'">
                        <el-input style="width: 240px;" :disabled="!item.permissions.edit"
                            v-model="formData[item.basic_settings.field]" :placeholder="item.basic_settings.placeholder"
                            clearable>
                        </el-input>
                    </template>
                    <template v-else-if="item.type === '多文本输入框'">
                        <el-input style="width: 100%;" :disabled="!item.permissions.edit" type="textarea"
                            :autosize="{ minRows: 3, maxRows: 16 }" v-model="formData[item.basic_settings.field]"
                            :placeholder="item.basic_settings.placeholder">
                        </el-input>
                    </template>
                    <template v-else-if="item.type === '数字输入框'">
                        <el-input-number :disabled="!item.permissions.edit" style="width: 240px;" controls-position="right"
                            :min="minValue(item.numeric_settings.max_digits, item.numeric_settings.decimal_places)"
                            :max="maxValue(item.numeric_settings.max_digits, item.numeric_settings.decimal_places)"
                            :precision="item.numeric_settings.decimal_places" v-model="formData[item.basic_settings.field]"
                            :placeholder="item.basic_settings.placeholder">
                        </el-input-number>
                    </template>
                    <template v-else-if="item.type === '开关'">
                        <div style="width: 240px;">
                            <el-switch :disabled="!item.permissions.edit" v-model="formData[item.basic_settings.field]">
                            </el-switch>
                        </div>
                    </template>
                    <template v-else-if="item.type === '下拉选择框(单选)'">
                        <el-select :disabled="!item.permissions.edit" style="width: 240px;"
                            v-model="formData[item.basic_settings.field]" :placeholder="item.basic_settings.placeholder"
                            clearable @focus="onSelectRadio(item)" @change="onChangeRadio(item)">
                            <el-option v-for="(items, i) in item.options" :label="items[onSelectLabel(item)]"
                                :value="items[onSelectValue(item)]" :key="i">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else-if="item.type === '下拉选择框(多选)'">
                        <el-select :disabled="!item.permissions.edit" style="width: 240px;"
                            v-model="formData[item.basic_settings.field]" multiple collapse-tags
                            :placeholder="item.basic_settings.placeholder" clearable @focus="onSelectMultiple(item)">
                            <el-option v-for="(items, i) in item.options" :label="items[onSelectLabel(item)]"
                                :value="items[onSelectValue(item)]" :key="i">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else-if="item.type === '特殊选择(单选)'">
                        <div>
                            <el-select style="width: 240px;" v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder" clearable @focus="onFocusSelectDialog(item)"
                                @change="onChangeSelectDialog(item, formData[item.basic_settings.field])">
                                <el-option v-for="(items, i) in item.options" :label="items.label" :value="items"
                                    v-if="items.label != null" :key="i">
                                </el-option>
                                <div style="padding: 0 20px;">
                                    <el-button type="text" @click="onSelectDialog(item)">查看全部</el-button>
                                </div>
                                <div slot="empty" style="padding: 0 20px;">
                                    <el-button type="text" @click="onSelectDialog(item)">查看全部</el-button>
                                </div>
                            </el-select>
                        </div>
                    </template>
                    <template v-else-if="item.type === '对象(单选)'">
                        <div>
                            <el-input :disabled="!item.permissions.edit" style="width: 240px;"
                                v-model="formData[item.basic_settings.field].label"
                                :placeholder="item.basic_settings.placeholder" clearable
                                @keyup.enter.native="onCustomerRadio(item, formData[item.basic_settings.field].label)"
                                @clear="onCustomerClear(item)">
                                <el-button slot="prepend" :disabled="!item.permissions.edit" icon="el-icon-search"
                                    @click="onCustomerRadio(item, formData[item.basic_settings.field].label)"></el-button>
                            </el-input>
                        </div>
                    </template>
                    <template v-else-if="item.type === '对象(多选)'">
                        <div style="box-sizing: border-box; background-color: #fff;border-radius: 4px;overflow: hidden; display: flex;align-items: center;width: 240px;"
                            class="el-input-group--prepend">
                            <div class="el-input-group__prepend">
                                <el-button size="mini" icon="el-icon-search" :disabled="!item.permissions.edit"
                                    @click="onCustomerMultiple(item)"></el-button>
                            </div>
                            <div class="el-input__inner tag_el_input"
                                :class="[formData[item.basic_settings.field].length > 0 ? 'border_radius0' : 'border_radius4']"
                                style="min-width: 40px;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;">
                                <el-input v-model="customerValues" :disabled="!item.permissions.edit" placeholder="请输入内容"
                                    @keyup.enter.native="onCustomerMultiple(item)"></el-input>
                            </div>
                            <div v-if="formData[item.basic_settings.field].length > 0" style="min-width: 120px;"
                                class="input_tag" v-horizontal-scroll>
                                <div v-if="formData[item.basic_settings.field].length === 1" class="input_tag_1">
                                    <div style="width: 90px; font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;"
                                        :key="index" v-for="(tag, index) in formData[item.basic_settings.field]">
                                        {{ tag.label }}
                                    </div>
                                    <i class="el-icon-close" @click="onTagClose(item.basic_settings.field, 0)"></i>
                                </div>
                                <el-popover v-else placement="bottom" trigger="click">
                                    <div>
                                        <el-tag style="font-size: 12px;cursor: pointer !important;" :key="index"
                                            v-for="(tag, index) in formData[item.basic_settings.field]" closable
                                            :disable-transitions="false"
                                            @close="onTagClose(item.basic_settings.field, index)">
                                            {{ tag.label }}
                                        </el-tag>
                                    </div>
                                    <div slot="reference"
                                        style="font-size: 12px; display: flex;align-items: baseline;cursor: pointer !important;">
                                        <div
                                            style="width: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;">
                                            {{ formData[item.basic_settings.field][0].label }}
                                        </div>
                                        <div>
                                            外共{{
                                                (formData[item.basic_settings.field].length - 1) }}项
                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="item.type === '日期选择(范围)'">
                        <el-date-picker style="width: 240px;" :disabled="!item.permissions.edit"
                            v-model="formData[item.basic_settings.field]" type="daterange" align="right" unlink-panels
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy/MM/dd"
                            value-format="yyyy/MM/dd" :picker-options="pickerOptions" clearable>
                        </el-date-picker>
                    </template>
                    <template v-else-if="item.type === '日期选择'">
                        <el-date-picker style="width: 240px;" :disabled="!item.permissions.edit"
                            v-model="formData[item.basic_settings.field]" type="date" align="right"
                            :placeholder="item.basic_settings.placeholder" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            clearable>
                        </el-date-picker>
                    </template>
                    <template v-else-if="item.type === '组织架构(人员单选)'">
                        <div style="width: 240px;">
                            <div v-if="readonly"
                                style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                    <div :key="tagIndex">
                                        <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                    </div>
                                </template>
                            </div>
                            <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'user'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                            <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                placeholder="请输入内容"></el-input>
                        </div>
                    </template>
                    <template v-else-if="item.type === '组织架构(人员多选)'">
                        <div style="width: 240px;">
                            <div v-if="readonly"
                                style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                    <div :key="tagIndex">
                                        <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                    </div>
                                </template>
                            </div>
                            <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'user'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                            <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                placeholder="请输入内容"></el-input>
                        </div>
                    </template>
                    <template v-else-if="item.type === '组织架构(部门单选)'">
                        <div style="width: 240px;">
                            <div v-if="readonly"
                                style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                    <div :key="tagIndex">
                                        <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                    </div>
                                </template>
                            </div>
                            <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                        </div>
                    </template>
                    <template v-else-if="item.type === '组织架构(部门多选)'">
                        <div style="width: 240px;">
                            <div v-if="readonly"
                                style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                    <div :key="tagIndex">
                                        <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                    </div>
                                </template>
                            </div>
                            <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                        </div>
                    </template>
                    <template v-else-if="item.type === '表格'">
                        <div>
                            <slot name="shortcut_button"></slot>
                        </div>
                        <div v-if="$store.state.isphone"
                            style="border:1px dashed #8fc31f;padding: 10px;border-radius:12px;">
                            <template v-for="(items, index) in formData[item.basic_settings.field]">
                                <div :key="index"
                                    style="border:1px dashed #DCDFE6; padding:10px; margin-bottom:10px;border-radius:12px;">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <p> 产品{{ index + 1 }}</p>
                                        <i @click="onDeleteTable(index, item)" class="el-icon-close close_btn"></i>
                                    </div>
                                    <el-form :model="items" label-position="top" size="mini" :disabled="readonly">
                                        <template v-for="(column, j ) in item.particulars">
                                            <el-form-item :key="j" v-if="column.is_show && column.type == '数组'"
                                                style="width:100%;" :label="column.label" :prop="column.field">
                                                <el-input v-if="column.is_edit" v-model="items[column.field]" clearable
                                                    :placeholder="column.placeholder"
                                                    @keyup.enter.native="onParticulars(index, column, items[column.field], item.particulars)"
                                                    @blur.stop="onParticulars(index, column, items[column.field], item.particulars)">
                                                    <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                        @click="onParticulars(index, column, items[column.field], item.particulars)">
                                                    </el-button>
                                                </el-input>
                                                <div v-else>
                                                    <el-input disabled v-model="items[column.field]"
                                                        :placeholder="column.placeholder" clearable></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item style="width:100%;" v-if="column.is_show && column.type == '特殊数字'"
                                                :label="column.label" :prop="column.field" :key="j">
                                                <el-input v-if="column.is_edit" type="number"
                                                    v-model.number="items[column.field]" :placeholder="column.placeholder"
                                                    @blur="onSpecialNumber(index, column, items, item)"
                                                    clearable></el-input>
                                                <div v-else>
                                                    <el-input disabled v-model="items[column.field]"
                                                        :placeholder="column.placeholder" clearable></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item style="width:100%;" v-if="column.is_show && column.type == '特殊对象'"
                                                :label="column.label" :prop="column.field" :key="j">
                                                <el-input v-if="column.is_edit" size="mini" v-model="items[column.field]"
                                                    clearable disabled :placeholder="column.placeholder"
                                                    @keyup.enter.native="onSpecial(index, column, items, item)"
                                                    @blur.stop="onSpecial(index, column, items, item)">
                                                    <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                        @click="onSpecial(index, column, items, item)"></el-button>
                                                </el-input>
                                                <div v-else>
                                                    <el-input disabled v-model="items[column.field]"
                                                        :placeholder="column.placeholder" clearable></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item style="width:100%;"
                                                v-if="column.is_show && column.type == '特殊选择类别'" :label="column.label"
                                                :prop="column.field" :key="j">
                                                <el-select style="width:100%;" v-if="column.is_edit"
                                                    v-model="items[column.field]" :placeholder="column.placeholder"
                                                    @focus="onTableSelectFocus(column)"
                                                    @change="onTableSelectChange(index, column, items, item)">
                                                    <el-option v-for="(items, i) in tableOption[column.field]"
                                                        :label="items.label" :value="items" v-if="items.label != null"
                                                        :key="i">
                                                    </el-option>
                                                    <div style="padding: 0 20px;">
                                                        <el-button type="text"
                                                            @click="onTableSelectDialog(index, column, items, item)">查看全部</el-button>
                                                    </div>
                                                    <div slot="empty" style="padding: 0 20px;">
                                                        <el-button type="text"
                                                            @click="onTableSelectDialog(index, column, items, item)">查看全部</el-button>
                                                    </div>
                                                </el-select>
                                                <div v-else>
                                                    <el-input disabled v-model="items[column.field]"
                                                        :placeholder="column.placeholder" clearable></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item style="width:100%;" v-if="column.is_show && column.type == '文本'"
                                                :label="column.label" :prop="column.field" :key="j">
                                                <el-input v-if="column.is_edit" v-model="items[column.field]"
                                                    :placeholder="column.placeholder" clearable></el-input>
                                                <div v-else>
                                                    <el-input disabled v-model="items[column.field]"
                                                        :placeholder="column.placeholder" clearable></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item style="width:100%;" v-if="column.is_show && column.type == '数字'"
                                                :label="column.label" :prop="column.field" :key="j">
                                                <el-input v-if="column.is_edit" size="mini" type="number"
                                                    :placeholder="column.placeholder" v-model.number="items[column.field]"
                                                    clearable>
                                                </el-input>
                                                <div v-else>
                                                    <el-input disabled size="mini" type="number"
                                                        :placeholder="column.placeholder"
                                                        v-model.number="items[column.field]" clearable>
                                                    </el-input>
                                                </div>
                                            </el-form-item>
                                        </template>
                                    </el-form>
                                </div>
                            </template>
                            <div>
                                <el-button style="width: 100%;" :disabled="readonly" @click="onPushTable(item)"
                                    icon="el-icon-plus" type="text">添加产品</el-button>
                            </div>
                        </div>
                        <el-table style="margin-bottom:15px;" v-else show-summary
                            :data="formData[item.basic_settings.field]" ref="table_ref" width="1300px" size="mini" border
                            :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }">
                            <el-table-column label="序号" type="index" width="60px" prop="index">
                            </el-table-column>
                            <el-table-column label="设置" width="100px" prop="setting">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="onPushTable(item)">添加</el-button>
                                    <el-button type="text" @click="onDeleteTable(scope.$index, item)">删除</el-button>
                                </template>
                            </el-table-column>
                            <template v-for="(column, j) in item.particulars">
                                <el-table-column v-if="column.is_show && column.type == '数组'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                            clearable :placeholder="column.placeholder"
                                            @keyup.enter.native="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"
                                            @blur.stop="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)">
                                            <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                @click="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"></el-button>
                                        </el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '特殊数字'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" type="number"
                                            v-model.number="scope.row[column.field]" :placeholder="column.placeholder"
                                            @blur="onSpecialNumber(scope.$index, column, scope.row, item)"
                                            clearable></el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '特殊对象'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                            disabled clearable :placeholder="column.placeholder"
                                            @keyup.enter.native="onSpecial(scope.$index, column, scope.row, item)"
                                            @blur.stop="onSpecial(scope.$index, column, scope.row, item)">
                                            <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                @click="onSpecial(scope.$index, column, scope.row, item)"></el-button>
                                        </el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '特殊选择类别'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-select v-if="column.is_edit" v-model="scope.row[column.field]"
                                            :placeholder="column.placeholder" @focus="onTableSelectFocus(column)"
                                            @change="onTableSelectChange(scope.$index, column, scope.row, item)">
                                            <el-option v-for="(items, i) in tableOption[column.field]" :label="items.label"
                                                :value="items" v-if="items.label != null" :key="i">
                                            </el-option>
                                            <div style="padding: 0 20px;">
                                                <el-button type="text"
                                                    @click="onTableSelectDialog(scope.$index, column, scope.row, item)">查看全部</el-button>
                                            </div>
                                            <div slot="empty" style="padding: 0 20px;">
                                                <el-button type="text"
                                                    @click="onTableSelectDialog(scope.$index, column, scope.row, item)">查看全部</el-button>
                                            </div>
                                        </el-select>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '文本'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" v-model="scope.row[column.field]"
                                            :placeholder="column.placeholder" clearable></el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '日期选择'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-date-picker style="width: 100%;" v-if="column.is_edit"
                                            v-model="scope.row[column.field]" type="date" align="right"
                                            :placeholder="column.placeholder" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                            clearable>
                                        </el-date-picker>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '数字'" :label="column.label"
                                    :width="column.width" :prop="column.field" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" size="mini" type="number"
                                            :placeholder="column.placeholder" v-model.number="scope.row[column.field]"
                                            clearable>
                                        </el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                            </template>
                        </el-table>
                    </template>
                </el-form-item>
            </template>
            <el-form-item v-if="isFormBtn">
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button type="danger" @click="onReset">重置</el-button>
            </el-form-item>
        </el-form>
        <el-form v-else :ref="refs" :model="formData" :disabled="readonly" class="form_inline_false" label-width="100px"
            :rules="rules" size="mini">
            <div>
                <template v-for="(item, i) in settingForm.control_setting">
                    <el-form-item :label="item.basic_settings.show_name"
                        :class="[item.type == '多文本输入框' || item.type == '表格' ? 'input_textarea' : '']"
                        :prop="item.type == '对象(单选)' ? item.basic_settings.field + '.label' : item.basic_settings.field"
                        :key="i" v-if="onIsRoleId(item.visible_rule.role_limit, role_ids) && item.permissions.show">
                        <template v-if="item.type === '文本输入框'">
                            <el-input style="width: 100%;" :disabled="!item.permissions.edit"
                                v-model="formData[item.basic_settings.field]" :placeholder="item.basic_settings.placeholder"
                                clearable>
                            </el-input>
                        </template>
                        <template v-else-if="item.type === '多文本输入框'">
                            <el-input type="textarea" :disabled="!item.permissions.edit"
                                :autosize="{ minRows: 3, maxRows: 16 }" v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder">
                            </el-input>
                        </template>
                        <template v-else-if="item.type === '数字输入框'">
                            <el-input-number :disabled="!item.permissions.edit" style="width: 100%;"
                                controls-position="right" :min="minValue(item.numeric_settings.max_digits,
                                    item.numeric_settings.decimal_places)"
                                :max="maxValue(item.numeric_settings.max_digits, item.numeric_settings.decimal_places)"
                                :precision="item.numeric_settings.decimal_places"
                                v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder">
                            </el-input-number>
                        </template>
                        <template v-else-if="item.type === '开关'">
                            <div style="width: 100%;">
                                <el-switch :disabled="!item.permissions.edit" v-model="formData[item.basic_settings.field]">
                                </el-switch>
                            </div>
                        </template>
                        <template v-else-if="item.type === '下拉选择框(单选)'">
                            <el-select :disabled="!item.permissions.edit" style="width: 100%;"
                                v-model="formData[item.basic_settings.field]" :placeholder="item.basic_settings.placeholder"
                                clearable @focus="onSelectRadio(item)" @change="onChangeRadio(item)">
                                <el-option v-for="(items, i) in item.options" :label="items[item.model_class_field]"
                                    :value="items[item.model_class_value]" :key="i">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === '下拉选择框(多选)'">
                            <el-select :disabled="!item.permissions.edit" style="width: 100%;"
                                v-model="formData[item.basic_settings.field]" multiple collapse-tags
                                :placeholder="item.basic_settings.placeholder" clearable @focus="onSelectMultiple(item)">
                                <el-option v-for="(items, i) in item.options" :label="items[item.model_class_field]"
                                    :value="items[item.model_class_value]" :key="i">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === '对象(单选)'">
                            <div>
                                <el-input :disabled="!item.permissions.edit" style="width: 100%;"
                                    v-model="formData[item.basic_settings.field].label"
                                    :placeholder="item.basic_settings.placeholder" clearable
                                    @keyup.enter.native="onCustomerRadio(item, formData[item.basic_settings.field].label)"
                                    @clear="onCustomerClear(item)">
                                    <el-button :disabled="!item.permissions.edit" slot="prepend" icon="el-icon-search"
                                        @click="onCustomerRadio(item, formData[item.basic_settings.field].label)"></el-button>
                                </el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '对象(多选)'">
                            <div style="box-sizing: border-box; background-color: #fff;border-radius: 4px;overflow: hidden; display: flex;align-items: center;min-width: 240px;"
                                class="el-input-group--prepend">
                                <div class="el-input-group__prepend">
                                    <el-button size="mini" :disabled="!item.permissions.edit" icon="el-icon-search"
                                        @click="onCustomerMultiple(item)"></el-button>
                                </div>
                                <div class="el-input__inner tag_el_input"
                                    :class="[formData[item.basic_settings.field].length > 0 ? 'border_radius0' : 'border_radius4']"
                                    style="min-width: 40px;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;">
                                    <el-input v-model="customerValues" :disabled="!item.permissions.edit"
                                        :placeholder="item.basic_settings.placeholder"
                                        @keyup.enter.native="onCustomerMultiple(item.basic_settings.field)"></el-input>
                                </div>
                                <div v-if="formData[item.basic_settings.field].length > 0" style="min-width: 120px;"
                                    class="input_tag" v-horizontal-scroll>
                                    <div v-if="formData[item.basic_settings.field].length === 1" class="input_tag_1">
                                        <div style="width: 90px; font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;"
                                            :key="index" v-for="(tag, index) in formData[item.basic_settings.field]">
                                            {{ tag.label }}
                                        </div>
                                        <i class="el-icon-close" @click="onTagClose(item.basic_settings.field, 0)"></i>
                                    </div>
                                    <el-popover v-else placement="bottom" trigger="click">
                                        <div>
                                            <el-tag style="font-size: 12px;cursor: pointer !important;" :key="index"
                                                v-for="(tag, index) in formData[item.basic_settings.field]" closable
                                                :disable-transitions="false"
                                                @close="onTagClose(item.basic_settings.field, index)">
                                                {{ tag.label }}
                                            </el-tag>
                                        </div>
                                        <div slot="reference"
                                            style="font-size: 12px; display: flex;align-items: baseline;cursor: pointer !important;">
                                            <div
                                                style="width: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;">
                                                {{ formData[item.basic_settings.field][0].label }}
                                            </div>
                                            <div>
                                                外共{{
                                                    (formData[item.basic_settings.field].length - 1) }}项
                                            </div>
                                        </div>
                                    </el-popover>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="item.type === '日期选择(范围)'">
                            <el-date-picker :disabled="!item.permissions.edit" style="width: 100%;"
                                v-model="formData[item.basic_settings.field]" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy/MM/dd"
                                value-format="yyyy/MM/dd" :picker-options="pickerOptions" clearable>
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === '日期选择'">
                            <el-date-picker :disabled="!item.permissions.edit" style="width: 100%;"
                                v-model="formData[item.basic_settings.field]" type="date" align="right"
                                :placeholder="item.basic_settings.placeholder" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                clearable>
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === '组织架构(人员单选)'">
                            <div style="width: 100%;">
                                <div v-if="readonly"
                                    style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                    <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                        <div :key="tagIndex">
                                            <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                        </div>
                                    </template>
                                </div>
                                <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'user'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                                <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                    placeholder="请输入内容"></el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(人员多选)'">
                            <div style="width: 100%;">
                                <div v-if="readonly"
                                    style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                    <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                        <div :key="tagIndex">
                                            <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                        </div>
                                    </template>
                                </div>
                                <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'user'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                                <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                    placeholder="请输入内容"></el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(部门单选)'">
                            <div style="width: 100%;">
                                <div v-if="readonly"
                                    style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                    <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                        <div :key="tagIndex">
                                            <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                        </div>
                                    </template>
                                </div>
                                <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(部门多选)'">
                            <div style="width: 100%;">
                                <div v-if="readonly"
                                    style="box-sizing: border-box; width: 100%;border: 1px solid #dcdfe6;padding-left: 6px;font-size: 12px;min-height: 25px;line-height: 28px;border-radius: 4px;background: #fff;color: #606266;cursor: pointer;">
                                    <template v-for="(tag, tagIndex) in formData[item.basic_settings.field]">
                                        <div :key="tagIndex">
                                            <el-tag size="small" type="info">{{ tag.label }}</el-tag>
                                        </div>
                                    </template>
                                </div>
                                <OrgSelect v-else @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                            </div>
                        </template>
                        <template v-else-if="item.type === '表格'">
                            <div>
                                <slot name="shortcut_button"></slot>
                            </div>
                            <div v-if="$store.state.isphone"
                                style="border:1px dashed #8fc31f;padding: 10px;border-radius:12px;">
                                <template v-for="(items, index) in formData[item.basic_settings.field]">
                                    <div :key="index"
                                        style="border:1px dashed #DCDFE6; padding:10px; margin-bottom:10px;border-radius:12px;">
                                        <div style="display: flex;justify-content: space-between;align-items: center;">
                                            <p> 产品{{ index + 1 }}</p>
                                            <i @click="onDeleteTable(index, item)" class="el-icon-close close_btn"></i>
                                        </div>
                                        <!-- <el-button type="text" >删除</el-button> -->
                                        <el-form :model="items" label-position="top" size="mini">
                                            <template v-for="(column, j ) in item.particulars">
                                                <el-form-item :key="j" v-if="column.is_show && column.type == '数组'"
                                                    style="width:100%;" :label="column.label" :prop="column.field">
                                                    <el-input v-if="column.is_edit" v-model="items[column.field]" clearable
                                                        :placeholder="column.placeholder"
                                                        @keyup.enter.native="onParticulars(index, column, items[column.field], item.particulars)"
                                                        @blur.stop="onParticulars(index, column, items[column.field], item.particulars)">
                                                        <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                            @click="onParticulars(index, column, items[column.field], item.particulars)">
                                                        </el-button>
                                                    </el-input>
                                                    <div v-else>
                                                        <el-input disabled v-model="items[column.field]"
                                                            :placeholder="column.placeholder" clearable></el-input>
                                                    </div>
                                                </el-form-item>
                                                <el-form-item style="width:100%;"
                                                    v-if="column.is_show && column.type == '特殊对象'" :label="column.label"
                                                    :prop="column.field" :key="j">
                                                    <el-input v-if="column.is_edit" size="mini"
                                                        v-model="items[column.field]" clearable disabled
                                                        :placeholder="column.placeholder"
                                                        @keyup.enter.native="onSpecial(index, column, items, item)"
                                                        @blur.stop="onSpecial(index, column, items, item)">
                                                        <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                            @click="onSpecial(index, column, items, item)"></el-button>
                                                    </el-input>
                                                    <div v-else>
                                                        <el-input disabled v-model="items[column.field]"
                                                            :placeholder="column.placeholder" clearable></el-input>
                                                    </div>
                                                </el-form-item>
                                                <el-form-item style="width:100%;"
                                                    v-if="column.is_show && column.type == '特殊数字'" :label="column.label"
                                                    :prop="column.field" :key="j">
                                                    <el-input v-if="column.is_edit" type="number"
                                                        v-model.number="items[column.field]"
                                                        :placeholder="column.placeholder"
                                                        @blur="onSpecialNumber(index, column, items, item)"
                                                        clearable></el-input>
                                                    <div v-else>
                                                        <el-input disabled v-model="items[column.field]"
                                                            :placeholder="column.placeholder" clearable></el-input>
                                                    </div>
                                                </el-form-item>
                                                <el-form-item style="width:100%;"
                                                    v-if="column.is_show && column.type == '特殊选择类别'" :label="column.label"
                                                    :prop="column.field" :key="j">
                                                    <el-select style="width:100%;" v-if="column.is_edit"
                                                        v-model="items[column.field]" :placeholder="column.placeholder"
                                                        @focus="onTableSelectFocus(column)"
                                                        @change="onTableSelectChange(column)">
                                                        <div style="padding: 0 20px;">
                                                            <el-button type="text"
                                                                @click="onTableSelectDialog(index, items, column)">查看全部</el-button>
                                                        </div>
                                                        <div slot="empty" style="padding: 0 20px;">
                                                            <el-button type="text"
                                                                @click="onTableSelectDialog(index, items, column)">查看全部</el-button>
                                                        </div>
                                                    </el-select>
                                                    <div v-else>
                                                        <el-input disabled v-model="items[column.field]"
                                                            :placeholder="column.placeholder" clearable></el-input>
                                                    </div>
                                                </el-form-item>
                                                <el-form-item v-if="column.is_show && column.type == '文本'"
                                                    style="width:100%;" :label="column.label" :prop="column.field" :key="j">
                                                    <el-input v-if="column.is_edit" v-model="items[column.field]"
                                                        :placeholder="column.placeholder" clearable></el-input>
                                                    <div v-else>
                                                        <el-input disabled v-model="items[column.field]"
                                                            :placeholder="column.placeholder" clearable></el-input>
                                                    </div>
                                                </el-form-item>
                                                <el-form-item v-if="column.is_show && column.type == '数字'"
                                                    style="width:100%;" :label="column.label" :prop="column.field" :key="j">
                                                    <el-input v-if="column.is_edit" size="mini" type="number"
                                                        :placeholder="column.placeholder"
                                                        v-model.number="items[column.field]" clearable>
                                                    </el-input>
                                                    <div v-else>
                                                        <el-input disabled size="mini" type="number"
                                                            :placeholder="column.placeholder"
                                                            v-model.number="items[column.field]" clearable>
                                                        </el-input>
                                                    </div>
                                                </el-form-item>
                                            </template>
                                        </el-form>
                                    </div>
                                </template>
                                <div>
                                    <el-button style="width: 100%;" :disabled="readonly" @click="onPushTable(item)"
                                        icon="el-icon-plus" type="text">添加产品</el-button>
                                </div>
                            </div>
                            <el-table v-else style="margin-bottom:15px;" show-summary
                                :data="formData[item.basic_settings.field]" ref="table_ref" width="1300px" size="mini"
                                border :header-cell-style="{ 'text-align': 'center' }"
                                :cell-style="{ textAlign: 'center' }">
                                <el-table-column label="序号" type="index" width="60px"></el-table-column>
                                <el-table-column label="设置" width="100px" prop="setting">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="onPushTable(item)">添加</el-button>
                                        <el-button type="text" @click="onDeleteTable(scope.$index, item)">删除</el-button>
                                    </template>
                                </el-table-column>
                                <template v-for="(column, j) in item.particulars">
                                    <el-table-column v-if="column.is_show && column.type == '数组'" :label="column.label"
                                        :prop="column.field" :width="column.width" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                                clearable :placeholder="column.placeholder"
                                                @keyup.enter.native="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"
                                                @blur.stop="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)">
                                                <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                    @click="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"></el-button>
                                            </el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '特殊数字'" :label="column.label"
                                        :width="column.width" :prop="column.field" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" type="number"
                                                v-model.number="scope.row[column.field]" :placeholder="column.placeholder"
                                                @blur="onSpecialNumber(scope.$index, column, scope.row, item)"
                                                clearable></el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '特殊对象'" :label="column.label"
                                        :width="column.width" :prop="column.field" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                                disabled clearable :placeholder="column.placeholder"
                                                @keyup.enter.native="onSpecial(scope.$index, column, scope.row, item)"
                                                @blur.stop="onSpecial(scope.$index, column, scope.row, item)">
                                                <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                    @click="onSpecial(scope.$index, column, scope.row, item)"></el-button>
                                            </el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '文本'" :label="column.label"
                                        :prop="column.field" :width="column.width" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" v-model="scope.row[column.field]"
                                                :placeholder="column.placeholder" clearable></el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '数字'" :label="column.label"
                                        :prop="column.field" :width="column.width" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" type="number"
                                                :placeholder="column.placeholder" v-model.number="scope.row[column.field]"
                                                clearable>
                                            </el-input>
                                            <div v-else>
                                                <div>
                                                    {{ scope.row[column.field] }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                        </template>
                    </el-form-item>
                </template>
            </div>
            <el-form-item v-if="isFormBtn">
                <div style="display: flex;justify-content: flex-end;">
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button type="danger" @click="onReset">重置</el-button>
                </div>
            </el-form-item>
        </el-form>
        <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="customerTitle"
            :isChoose="true" :searchValue="customerValue" @onIsVisible="onIsVisible" @onApplied="onApplied">
        </inquireDialog>
        <inquireDialog v-if="isCustomers" :isVisible="isCustomers" :obj="customerDialogObj" :title="customersTitle"
            :isChoose="false" :searchValue="customerValues" @onIsVisible="onIsVisibles" @onApplied="onApplieds">
        </inquireDialog>
        <inquireDialog v-if="isParticulars" :isVisible="isParticulars" :obj="particularsObj" :title="'品目信息'"
            :isChoose="false" :searchValue="particularsValues" @onIsVisible="onIsVisiblesParticulars"
            @onApplied="onAppliedParticulars">
        </inquireDialog>
        <SettingDialog :title="'测试表单'" :isSetting="isSetting" :settingForm="settingForm" @onIsVisible="onSettingIsVisible">
        </SettingDialog>
        <inquireDialog v-if="isSelectDialogVisible" :isVisible="isSelectDialogVisible" :obj="selectDialogObj"
            :title="selectDialogTitle" :isChoose="true" :searchValue="selectDialogValue"
            @onIsVisible="onIsVisibleSelectDialog" @onApplied="onAppliedSelectDialog">
        </inquireDialog>
        <ExpenseCategory v-if="isCategory" :isExpenseCategory.sync="isCategory" :categoryValue="expenseCategory"
            @onIsCategory="onIsCategory" @onEmploy="onCategoryEmploy">
        </ExpenseCategory>
    </div>
</template>
<script>
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import OrgSelect from "@/components/OA/components/FormControls/OrgSelect/index.vue"
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue"
import SettingDialog from "@/components/ProcessFlow/CRM/Forms/components/SettingDialog1.vue";
import ExpenseCategory from "@/components/ERP/Setting/components/ExpenseCategory.vue"
import { getOperationalRecorder, setOperationalRecorder } from "@/api/operationalRecorder/operationalRecorder"
import {
    yesterdayRange,
    todayRange,
    currentWeekRange,
    lastWeekRange,
    currentMonthRange,
    lastMonthRange,
    currentMoneyMonth,
    lastMoneyMonth
} from "@/utils/FormDate.js";
export default {
    name: 'SettingForm',
    props: {
        settingForm: {
            type: Object,
            default: {},
        },
        refs: {
            type: String,
            default: "",
        },
        inline: {
            type: Boolean,
            default: false,
        },
        isFormBtn: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        isSettingLoading: {
            type: Boolean,
            default: false,
        }

    },
    components: {
        OrgSelect,
        inquireDialog,
        SettingDialog,
        ExpenseCategory,
    },
    data() {
        return {
            expenseCategory: [],
            isCategory: false,
            expenseCategoryIndex: null,
            expenseCategoryColumn: null,
            tableStr: null,
            tableOption: {},

            isSelectDialogVisible: false,
            selectDialogObj: {
                searchField: "search",
                url: null,
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: ""
                }
            },
            selectDialogField: null,
            selectDialogClassValue: null,
            selectDialogSettingfield: null,
            selectDialogSettingfields: null,
            selectDialogTitle: null,
            selectDialogValue: null,
            defaultScene: null,

            isConsigneeNameVisible: false,
            isDeliverAddrVisible: false,
            isEditForm: false,
            isSetting: false,
            role_ids: localStorage.getItem('role_ids'),
            formData: {},
            particularsColumn: null,
            particularsIndex: null,
            particularsValues: null,
            particularsSetting: null,
            isParticulars: false,
            particularsObj: {
                searchField: "search",
                url: "crm/special_offer/",
                params: {
                    per_page: 10,
                    page: 1,
                    // model_name: "SpecialOfferTable",
                    // sys_name: 3,
                    // user: localStorage.getItem("user_id"),
                    search: "",
                    // distinct: 1,
                    // is_using: true,
                },
            },
            controlArr: [],
            rules: {},

            //客户多选
            isCustomers: false,
            customersTitle: null,
            //客户多选
            customerValues: null,
            customerFields: null,
            customerClassValues: null,
            customerLabels: null,
            customerLabels1: null,

            //客户单选
            customerValue: null,
            customerField: null,
            customerClassValue: null,
            customerLabel: null,
            customerLabel1: null,
            // 客户单选
            isCustomer: false,
            customerTitle: null,

            // 客户Dialog参数
            customerDialogObj: {
                searchField: "search",
                url: null,
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: ""
                }
            },


            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        // console.log(todayRange);
                        picker.$emit('pick', todayRange.split('-'));
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        picker.$emit('pick', yesterdayRange.split('-'));
                    }
                }, {
                    text: '本周',
                    onClick(picker) {
                        picker.$emit('pick', currentWeekRange.split('-'));
                    }
                }, {
                    text: '上周',
                    onClick(picker) {
                        picker.$emit('pick', lastWeekRange.split('-'));
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        picker.$emit('pick', currentMonthRange.split('-'));
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        picker.$emit('pick', lastMonthRange.split('-'));
                    }
                }, {
                    text: '本财务月',
                    onClick(picker) {
                        picker.$emit('pick', currentMoneyMonth.split('-'));
                    }
                }, {
                    text: '上财务月',
                    onClick(picker) {
                        picker.$emit('pick', lastMoneyMonth.split('-'));
                    }
                },]
            },
        }
    },
    methods: {
        onIsCategory(val) {
            this.isCategory = val;
        },
        async onCategoryEmploy(val) {
            try {
                let objSetting = JSON.parse(this.expenseCategoryColumn.setting);
                let obj = {
                    label: val[0][objSetting[this.expenseCategoryColumn.field].label],
                    value: val[0][objSetting[this.expenseCategoryColumn.field].value]
                }
                this.formData[this.tableStr][this.expenseCategoryIndex][this.expenseCategoryColumn.field] = obj;
                this.formData[this.tableStr][this.expenseCategoryIndex][this.expenseCategoryColumn.acquire] = obj[objSetting[this.expenseCategoryColumn.acquire]];
                let isItem = true
                this.tableOption[this.expenseCategoryColumn.field].forEach(item => {
                    if (item.value == obj.value) {
                        isItem = false
                    }
                })
                if (isItem) {
                    this.tableOption[this.expenseCategoryColumn.field].unshift(obj)
                }
                this.expenseCategory = [];
                this.isCategory = false;
                let res = null;
                let obj1 = {
                    job_id: window.localStorage.getItem("jobid"),
                    sys_name: 2,
                    url: this.expenseCategoryColumn.field,
                    description: this.settingForm.form_root,
                    operate_data: {
                        options: this.tableOption[this.expenseCategoryColumn.field]
                    }
                }
                if (this.tableOption[this.expenseCategoryColumn.acquire]) {
                    res = await setOperationalRecorder("PUT", obj1)
                } else {
                    res = await setOperationalRecorder("POST", obj1)
                }
            } catch (error) {
                return this.$message.error('请重新' + this.expenseCategoryColumn.basic_settings.show_name + "输入JSON数据")
            }
        },
        onTableSelectChange(index, column, row, item) {
            console.log(index, column, row, item);
            // console.log(column);
            try {
                let objSetting = JSON.parse(column.setting);
                this.formData[item.basic_settings.field][index][column.acquire] = this.formData[item.basic_settings.field][index][column.field][objSetting[column.acquire]]
            } catch (error) {
                return this.$message.error('请重新' + column.basic_settings.show_name + "输入JSON数据")
            }
        },
        async onTableSelectFocus(column) {
            const isValid = (value) => value != null && value !== '';
            let obj = {
                job_id: window.localStorage.getItem("jobid"),
                sys_name: 2,
                url: column.field,
                description: this.settingForm.form_root,
            }
            const res = await getOperationalRecorder(obj);
            if (res.status == 200) {
                if (isValid(res.data[0])) {
                    this.$set(this.tableOption, column.acquire, true)
                    this.$set(this.tableOption, column.field, [...res.data[0].operate_data.options])
                } else {
                    this.$set(this.tableOption, column.acquire, false)
                }
            }
        },
        onTableSelectDialog(index, column, row, item) {
            this.expenseCategoryColumn = column;
            this.expenseCategoryIndex = index;
            this.tableStr = item.basic_settings.field
            // console.log(index, column, row, item);
            this.isCategory = true;
            // this.$emit("onSpecials", index, column, row, item)
        },
        onChangeSelectDialog(item, data) {
            // console.log(item, data);
            this.formData[item.basic_settings.fields] = data.value
        },
        onIsVisibleSelectDialog(val) {
            this.isSelectDialogVisible = val
        },
        async onAppliedSelectDialog(val) {
            const isValid = (value) => value != null && value !== '';
            this.isEditForm = false
            let obj = {
                label: val[this.selectDialogField],
                value: val[this.selectDialogClassValue]
            };
            this.formData[this.selectDialogSettingfield] = obj;
            this.formData[this.selectDialogSettingfields] = val[this.selectDialogClassValue];
            let index = null;
            this.settingForm.control_setting.forEach((item, i) => {
                if (item.basic_settings.field == this.selectDialogSettingfield && item.basic_settings.fields == this.selectDialogSettingfields) {
                    index = i
                }
            })
            this.settingForm.control_setting[index].options.unshift(obj);
            this.settingForm.control_setting[index].options.forEach((item, i) => {
                if (!isValid(item.label)) {
                    this.settingForm.control_setting[index].options.splice(i, 1)
                }
            })

            let obj1 = {
                job_id: window.localStorage.getItem("jobid"),
                sys_name: 2,
                url: this.settingForm.control_setting[index].basic_settings.field,
                description: this.settingForm.form_root,
                operate_data: {
                    options: this.settingForm.control_setting[index].options
                }
            }
            console.log(obj1);
            if (isValid(this.defaultScene)) {
                const res = await setOperationalRecorder("PUT", obj1)
                console.log(res);
            } else {
                const res = await setOperationalRecorder("POST", obj1)
                console.log(res);
            }
            // console.log(obj, index)
            setTimeout(() => {
                this.isEditForm = true
            }, 500);
        },
        async onFocusSelectDialog(item) {
            const isValid = (value) => value != null && value !== '';
            this.isEditForm = false
            let obj = {
                job_id: window.localStorage.getItem("jobid"),
                sys_name: 2,
                url: item.basic_settings.field,
                description: this.settingForm.form_root,
            }
            const res = await getOperationalRecorder(obj);
            if (res.status == 200) {
                if (isValid(res.data[0])) {
                    this.defaultScene = res.data[0].operate_data?.options
                    item.options = this.defaultScene
                }
                setTimeout(() => {
                    this.isEditForm = true
                }, 500);
            }
        },
        onSelectDialog(item) {
            if (this.readonly) {
            } else {
                try {
                    let obj = JSON.parse(item.setting);
                    this.selectDialogField = obj[item.basic_settings.field].label
                    this.selectDialogClassValue = obj[item.basic_settings.field].value
                    this.selectDialogSettingfield = item.basic_settings.field
                    this.selectDialogSettingfields = item.basic_settings.fields
                    this.selectDialogTitle = item.basic_settings.show_name
                    this.selectDialogObj.url = item.url
                    this.isSelectDialogVisible = true
                } catch (error) {
                    return this.$message.error('请重新' + item.basic_settings.show_name + "输入JSON数据")
                }

            }
        },
        onSpecialNumber(index, column, row, item) {
            this.$emit("onSpecialNumbers", index, column, row, item)
        },
        onSpecial(index, column, row, item) {
            this.$emit("onSpecials", index, column, row, item)
        },
        onPushOA(val) {
            let str = null
            this.settingForm.control_setting.forEach(item => {
                if (item.basic_settings.show_name == '客户') {
                    str = item.basic_settings.field
                }
            })
            let obj = {
                customer: {
                    company_name: this.formData[str].label,
                    id: this.formData[str].value,
                },
            };
            // console.log('onPreview', obj, val, true)
            this.$emit('onPreview', obj, val, true)
        },
        addContact() {
            // console.log("收货人");
            let obj = {
                label: "联系人",
                value: 92,
            };
            this.onPushOA(obj);
        },
        addAddress() {
            // console.log("地址")
            let obj = {
                label: "收货地址",
                value: 173,
            };
            this.onPushOA(obj);
        },
        onPushTable(data) {
            let obj = {}
            data.particulars.forEach(item => {
                if (item.type == '对象') {
                    obj[item.field] = null
                    obj[item.front_label] = null
                    obj[item.front_value] = null
                } else {
                    obj[item.field] = null
                }
            })
            this.formData[data.basic_settings.field].push(obj)
        },
        onDeleteTable(index, data) {
            // console.log(this.formData[data.basic_settings.field].length)
            if (!this.readonly) {
                if (this.formData[data.basic_settings.field].length > 1) {
                    this.formData[data.basic_settings.field].splice(index, 1)
                }
            }

        },
        handleIsEditForm(val) {
            this.isEditForm = val
        },
        onAppliedParticulars(val) {
            const isValid = (value) => value != null && value !== '';
            let arr = []
            val.forEach(items => {
                let obj = {}
                this.particularsColumn.forEach(item => {
                    if (isValid(this.particularsSetting[item.field])) {
                        obj[item.field] = items[this.particularsSetting[item.field]]
                    } else {
                        obj[item.field] = null
                    }
                })
                arr.push(obj)
            })
            let str = null
            this.settingForm.control_setting.forEach(item => {
                if (item.type == '表格') {
                    str = item.basic_settings.field
                }
            })
            this.formData[str].splice(this.particularsIndex, 1, ...arr)
        },
        onIsVisiblesParticulars(val) {
            this.isParticulars = val
        },
        onParticulars(index, item, str, particulars) {
            try {
                let obj = JSON.parse(item.setting);
                this.particularsObj.url = item.url
                this.particularsIndex = index
                this.particularsValues = str
                this.particularsColumn = particulars
                this.particularsSetting = obj
                this.isParticulars = true;
            } catch (error) {
                return this.$message.error('请重新' + item.basic_settings.show_name + "输入JSON数据")
            }
        },
        onIsSetting() {
            this.isSetting = true
        },
        onSettingIsVisible(val) {
            this.isSetting = val
            this.getSettingForm()
        },
        async getSettingForm() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/form_control_setting/",
                params: {
                    form_root: this.settingForm.form_root,
                    classification: this.settingForm.classification,
                }
            })
            if (res.data[0] != null) {
                this.settingForm = JSON.parse(JSON.stringify(res.data[0]))
            }
        },
        onChangeRadio(item) {
            const isValid = (value) => value != null && value !== '';
            console.log(item.basic_settings.field);
            console.log(item.basic_settings.fields)


            return
            if (this.formData[val.basic_settings.field] === null, this.formData[val.basic_settings.field] === '') {
                this.formData[val.basic_settings.fields] = null
            } else {
                val.options.forEach(item => {
                    if (val.model_class_value !== val.model_class_field) {
                        if (item[val.model_class_value] === this.formData[val.basic_settings.field]) {
                            this.formData[val.basic_settings.fields] = item[val.model_class_field]
                        }
                    }
                })
            }
        },
        onSelectRadio(val) {
            this.getSelect(val)
        },
        onSelectLabel(item) {
            // console.log(item.setting);
            try {
                let obj = JSON.parse(item.setting);
                return obj[item.basic_settings.field].label
            } catch (error) {
                return this.$message.error('请重新' + item.basic_settings.show_name + "输入JSON数据")
            }

        },
        onSelectValue(item) {
            try {
                let obj = JSON.parse(item.setting);
                return obj[item.basic_settings.field].value
            } catch (error) {
                return this.$message.error('请重新' + item.basic_settings.show_name + "输入JSON数据")
            }
        },
        onSelectMultiple(val) {
            this.getSelect(val)
        },
        async getSelect(val) {
            this.isEditForm = false
            if (val.url != null && val.url != '') {
                const res = await this.$http({
                    method: "GET",
                    url: val.url,
                })
                if (val.url == 'crm/customer_payment_days_verify/?customer_id=1') {
                    val.options = res.data.credit_rule_choice
                } else {
                    val.options = res.data
                }
            }
            setTimeout(() => {
                this.isEditForm = true
            }, 500);
        },
        onOrganization(val, item, type) {
            if (type === '组织架构(人员单选)') {
                this.formData[item.fields] = val[0].user_sys_id
            } else if (type === '组织架构(人员多选)') {
                let arr = []
                val.forEach(item => {
                    arr.push(item.user_sys_id)
                })
                if (this.formData[item.fields] != null) {
                    this.formData[item.fields] = [...this.formData[item.fields], ...arr]
                } else {
                    this.formData[item.fields] = [...arr]
                }
            } else if (type === '组织架构(部门单选)') {
                this.formData[item.fields] = val[0].dept_id
            } else {
                let arr = []
                val.forEach(item => {
                    arr.push(item.dept_id)
                })
                if (this.formData[item.fields] != null) {
                    this.formData[item.fields] = [...this.formData[item.fields], ...arr]
                } else {
                    this.formData[item.fields] = [...arr]
                }
            }
            this.formData[item.field] = val
        },
        onTagClose(str, index) {
            this.formData[str].splice(index, 1)
        },
        onSubmit() {
            this.$refs[this.refs].validate((valid) => {
                if (valid) {
                    this.$emit('onAppliedForm', this.formData)
                } else {
                    return false;
                }
            })
        },
        onReset() {
            this.$refs[this.refs].resetFields()
        },
        onCustomerClear(item) {
            this.formData[item.basic_settings.fields] = null
            this.formData[item.basic_settings.field] = {
                label: null,
                value: null,
            }
        },
        onCustomerRadio(item, str) {
            if (this.readonly) {
            } else {
                try {
                    let obj = JSON.parse(item.setting);
                    // console.log(item, str, obj);
                    this.customerField = obj[item.basic_settings.field].label
                    this.customerClassValue = obj[item.basic_settings.field].value
                    this.customerDialogObj.url = item.url
                    this.customerLabel = item.basic_settings.field
                    this.customerLabel1 = item.basic_settings.fields
                    this.customerValue = str
                    this.customerTitle = item.basic_settings.show_name
                    this.isCustomer = true
                } catch (error) {
                    return this.$message.error('请重新' + item.basic_settings.show_name + "输入JSON数据")
                }

            }
        },
        onCustomerMultiple(item) {
            if (this.readonly) {
                //  console.log(item, '多选', this.readonly);
            } else {
                try {
                    let obj = JSON.parse(item.setting);
                    this.customerFields = obj[item.basic_settings.field].label
                    this.customerClassValues = obj[item.basic_settings.field].value
                    this.customerDialogObj.url = item.url
                    this.customerLabels = item.basic_settings.field
                    this.customerLabels1 = item.basic_settings.fields
                    this.customersTitle = item.basic_settings.show_name
                    this.isCustomers = true
                } catch (error) {
                    return this.$message.error('请重新' + item.basic_settings.show_name + "输入JSON数据")
                }

            }
        },
        // 客户多选
        onIsVisibles(val) {
            this.isCustomers = val
        },
        //客户多选参数
        onApplieds(val) {
            let arr = []
            let idArr = []
            val.forEach(item => {
                let obj = {
                    label: item[this.customerFields],
                    value: item[this.customerClassValues],
                }
                arr.push(obj)
                idArr.push(item.id)
            });
            this.formData[this.customerLabels] = [...this.formData[this.customerLabels], ...arr]
            this.formData[this.customerLabels1] = [...this.formData[this.customerLabels1], ...idArr]
        },
        // 客户单选
        onIsVisible(val) {
            this.isCustomer = val
        },
        //客户单选参数
        onApplied(val) {
            let obj = {
                label: val[this.customerField],
                value: val[this.customerClassValue],
            }
            this.formData[this.customerLabel] = obj
            this.formData[this.customerLabel1] = val[this.customerClassValue]
        },
        minValue(num1, num2) {
            const isValid = (value) => value != null && value !== '';
            if (isValid(num1) && isValid(num2)) {
                return ('-' + ('9'.repeat(num1 - num2) + '.' + ('9'.repeat(num2)))) * 1
            }
        },
        maxValue(num1, num2) {
            const isValid = (value) => value != null && value !== '';
            if (isValid(num1) && isValid(num2)) {
                return ('9'.repeat(num1 - num2) + '.' + ('9'.repeat(num2))) * 1
            }
        },
        onIsRoleId(val, val1) {
            if (val.length > 0) {
                let flag = null
                val.forEach(item => {
                    if (val1.includes(item)) {
                        return flag = true
                    } else {
                        flag = false
                    }
                })
                return flag
            } else {
                return true;
            }
        }
    },
    watch: {
        settingForm: {
            handler(val) {
                // console.log(this.isEditForm, '777');
                if (this.isEditForm) {
                    let obj = {}
                    let rules = {}
                    val.control_setting.forEach(item => {
                        if (item != null) {
                            if (item.type == '对象(单选)') {
                                if (item.default_value == null || item.default_value == "") {
                                    obj[item.basic_settings.field] = {
                                        label: null,
                                        value: null,
                                    }
                                    obj[item.basic_settings.fields] = null;
                                } else {
                                    obj[item.basic_settings.field] = item.default_value
                                    obj[item.basic_settings.fields] = item.default_value.value
                                }
                                if (item.permissions.require) {
                                    rules[item.basic_settings.field + '.label'] = [{ required: true, message: item.basic_settings.placeholder, trigger: [], }]
                                }
                            } else if (item.type == '对象(多选)') {
                                obj[item.basic_settings.field] = []
                                obj[item.basic_settings.fields] = null
                                if (item.permissions.require) {
                                    rules[item.basic_settings.field] = [{ required: true, message: item.basic_settings.placeholder, trigger: [], }]
                                }
                            } else if (item.type == '表格') {
                                obj[item.basic_settings.field] = []
                                let obj1 = {}
                                item.particulars.forEach(item => {
                                    if (item.type == '对象') {
                                        obj1[item.field] = null
                                        obj1[item.front_label] = null
                                        obj1[item.front_value] = null
                                    } else {
                                        obj1[item.field] = null
                                    }
                                    if (item.type == '特殊选择类别') {
                                        this.$set(this.tableOption, item.field, [])
                                        // this.tableOption[item.field] = []
                                        if (item.acquire != null) {
                                            this.$set(this.tableOption, item.acquire, false)
                                            // this.tableOption[item.acquire] = false
                                        }
                                    }
                                    if (item.acquire != null) {
                                        obj1[item.acquire] = null
                                    }
                                })
                                obj[item.basic_settings.field].push(obj1)
                            } else {
                                if (item.type == '数字输入框') {
                                    if (item.default_value == null || item.default_value == '') {
                                        obj[item.basic_settings.field] = null
                                    } else {
                                        obj[item.basic_settings.field] = item.default_value * 1

                                    }
                                } else if (item.type == '组织架构(人员单选)' || item.type == '组织架构(人员多选)' || item.type == '组织架构(部门单选)' || item.type == '组织架构(部门多选)') {
                                    obj[item.basic_settings.field] = item.default_value
                                    obj[item.basic_settings.fields] = null
                                } else {
                                    obj[item.basic_settings.field] = item.default_value
                                }
                                if (item.permissions.require) {
                                    rules[item.basic_settings.field] = [{ required: true, message: item.basic_settings.placeholder, trigger: [], }]
                                }
                            }
                        }
                    })
                    this.rules = rules
                    this.formData = obj
                    this.$nextTick(() => {
                        this.$refs[this.refs].resetFields()
                        setTimeout(() => {
                            val.control_setting.forEach(item => {
                                if (item != null && item.type == '特殊选择(单选)') {
                                    // console.log(item, "特殊选择(单选)");
                                    this.onFocusSelectDialog(item);
                                }
                                if (item != null && item.type == '表格') {
                                    item.particulars.forEach(items => {
                                        if (items.type == '特殊选择类别') {
                                            // console.log(items, '特殊选择类别');
                                            this.onTableSelectFocus(items);
                                        }
                                    })
                                }
                            })
                        }, 500);
                    })
                }
            },
            deep: true,
            immediate: true,
        },
        formData: {
            handler(val) {
                if (this.isFormBtn) {
                } else {
                    // console.log("onAppliedForm", val);
                    this.$emit("onAppliedForm", val)
                }
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
    },
    created() {
        EventBus.$on('onIsEditForm', this.handleIsEditForm);
    },
    beforeDestroy() {
        EventBus.$off('onIsEditForm', this.handleIsEditForm);
    },
}
</script>
<style lang="less" scoped>
/deep/.form_inline_true {
    .el-form-item__label {
        // float: left;
        text-align: center !important;
        // vertical-align: middle;
        // padding: 0 10px 0 0 !important;
    }

    .tags {
        .as-input {
            min-height: 28px;
            line-height: 25px;
        }
    }

    .el-form-item {
        margin-bottom: 5px !important;
    }
}

.form_inline_false {
    .el-form-item__label {
        text-align: start !important;
    }

    .el-form-item__content {
        margin-left: 100px !important;
    }

    .el-form-item {
        margin-bottom: 5px !important;
    }
}

/deep/.input_textarea {
    width: 100%;

    .el-form-item__label {
        text-align: start !important;
    }

    .el-form-item__content {
        width: 100%;
        margin-left: 0 !important;
    }
}

.el-tag+.el-tag {
    margin-left: 5px;
}

.input_tag_1 {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: #8fc31f;
    }

    .el-icon-close {
        border-radius: 50%;
        text-align: center;
        position: relative;
        cursor: pointer;
        font-size: 12px;
        height: 16px;
        width: 16px;
        line-height: 16px;
        vertical-align: middle;

        &:hover {
            color: #fff;
            background-color: #8fc31f;
        }
    }
}

/deep/.input_tag {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 30px;
    line-height: 28px;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    .el-tag {

        box-sizing: border-box;
        height: 25px;
        line-height: 25px;
    }
}

/deep/.tag_el_input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 30px;
    line-height: 28px;
    border: 1px solid #DCDFE6;
    border-radius: 0;

    .el-input {
        display: flex;
    }

    .el-input__inner {
        border-width: 0;
        padding: 0;
        height: 25px;
        border: 0px solid rgb(89, 87, 87);
        line-height: 25px;
    }
}

.border_radius0 {
    border-radius: 0px !important;
}

.border_radius4 {
    border-radius: 4px !important;
}

// 合计行文本居中
/deep/.el-table__footer-wrapper {
    tr {
        td {
            text-align: center;
        }
    }
}

.close_btn {
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 3px 5px;
    display: inline-block;
    border-radius: 6px;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;

    &:hover {
        color: red;
    }
}

.close_btn:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.close_btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.close_btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.close_btn::after {
    background-color: #fff;
}

.close_btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
</style>