import Amount from './Amount'
import Calculation from './Calculation'
import TimeDuration from './TimeDuration'
import DateDuration from './DateDuration'
import OrgTransfer from './OrgTransfer'
import OrgSelect from './OrgSelect'
import InputTable from './InputTable'
import Autocompletion from './Autocompletion'
import Search from './Search'
import Map from './Map'
import Suite from './Suite'
import DatePicker from './DatePicker'
import Splicing from './Splicing'
import Instructions from './Instructions'
import Upload from './Upload'
import Relevancy from './Relevancy'
const components = [
  Amount,
  Calculation,
  TimeDuration,
  DateDuration,
  OrgTransfer,
  OrgSelect,
  InputTable,
  Autocompletion,
  Search,
  Map,
  Suite,
  DatePicker,
  Instructions,
  Splicing,
  Upload,
  Relevancy,
]

const install = function (Vue) {
  components.map(component => {
    Vue.component(component.name, component)
  })
}

export default {
  install
}
