import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/element-variables.scss'
import '@/style/index.less'
import axios from 'axios'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import wlGantt from 'wl-gantt'
import commons from '@/directives/click.js'
import 'wl-gantt/lib/wl-gantt.css'
import "./assets/iconfont/iconfont.css"
import FormControls from './components/OA/components/FormControls/index.js'
import './assets/iconfont/iconfont.css'
import Print from 'vue-print-nb'
import '@/utils/dialogdrag.js'
import '@/utils/dialogdrags.js'
import '@/utils/Directive.js'
import '@/utils/horizontalScroll.js'
import '@/utils/EscToClose.js'
// import '@/utils/scroll.js'
// import "./plugins/jsonViewer.js";
import introJS from 'intro.js'
import 'intro.js/introjs.css'
import ip from './ip'//本地地址
import { log } from 'util'
import { disAutoConnect } from 'vue-plugin-hiprint'
disAutoConnect(); // 取消自动连接
/**
 * introjs主题
 * introjs-dark
 * introjs-flattener
 * introjs-modern//黑色
 * introjs-nassim
 * introjs-nazanin
 * introjs-royal
*/
Vue.prototype.$intro = introJS
Vue.use(FormControls)
Vue.use(Print)
Vue.use(wlGantt)


Vue.directive('clickOut', commons.clickOut)
Vue.use(ElementUI)

Vue.config.productionTip = false
// 我们在main.js中用常量创建一个bus，然后将它放入Vue实例的原型对象中。

// axios.defaults.baseURL = '/api'
// axios.defaults.withCredentials = true
// axios.defaults.withCredentials=true;
// axios.defaults.baseURL = 'https://back.hugtech.cc/'
// axios.defaults.baseURL = 'https://lianghj.top:8888/api/private/v1/'
// axios.defaults.baseURL = 'http://127.0.0.1:8888/api/private/v1/'
// axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
  NProgress.start()
  // console.log(NProgress);
  // console.log(config);
  // if (config.url == "to_do_list/") {
  //   config.baseURL ='http://open.api.tianyancha.com'
  //   config.headers.Authorization ="a00b9c35-2501-437f-8f2c-d85c1f6780c5"
  // } else {
  config.headers.Authorization = window.localStorage.getItem('token') + '/' + window.localStorage.getItem('sessionid')
  // }

  return config
})
axios.interceptors.response.use(config => {
  // console.log(config);
  if (config.status === 254) {
    router.push('/login')
    return Vue.prototype.$message.error(config.data)
  }
  if (config.status === 255) {
    router.push('/login')
    return Vue.prototype.$message.error(config.data)
  }
  if (config.status === 253) {
    router.go(-1)
    return Vue.prototype.$message.error(config.data)
  }
  if (config.status === 258) {
    router.go(-1)
    return Vue.prototype.$message.error(config.data)
  }
  if (config.status === 222) {
    router.go('/')
    return Vue.prototype.$message.error(config.data)
  }
  NProgress.done()
  // console.log(config);
  return config
})
Vue.prototype.$http = axios
const apis = {
  production: 'https://back.hugtech.cc', // 线上 (生成环境)
  development: ip, // 本地 (开发环境)
  // accessHomeData: 'http://www.xmyxapp.com' // 其他api
}
// console.log(ip);
// Vue.prototype.$baseurl = 'https://back.hugtech.cc/'

Vue.prototype.$baseurl = process.env.NODE_ENV === 'production' ? apis.production : apis.development
// console.log(ip);
// Vue.prototype.$getComponentSize = function(){
//   return window.innerWidth <768 ?'mini' :null
// }
// function getCookieByName(name) {
//   const cookie = document.cookie;
//   return cookie.split(`; ${name}=`).pop().split(';').shift();
// }
Vue.filter('localstring', function (data) {
  return Number(data).toLocaleString()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
