<template>
    <div class="at-dialog-wrapper">
        <!--  :style="{ position: 'fixed', top: position.y + 'px', left: position.x + 'px' }" -->
        <div v-if="!mockList.length" class="at-dialog-empty">无搜索结果，请重新输入</div>
        <div v-for="(item, i) in mockList" :key="item.job_id" class="item" :class="{ 'active': i === index }"
            ref="usersRef">
            <div class="user_name" @click="clickAt($event, item)" @mouseenter="hoverAt(i)">{{ item.user_name
            }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AtDialog',
    props: {
        visible: Boolean,
        position: Object,
        mockData: Array,
        queryString: String
    },
    data() {
        return {
            users: [],
            index: -1,
            mockList: this.mockData
        }
    },
    watch: {
        queryString(val) {
            if (val) {
                this.mockList = this.mockData.filter(({ user_name }) => user_name.startsWith(val));
            } else {
                this.mockList = this.mockData;
            }
        }
    },
    mounted() {
        document.addEventListener('keyup', this.keyDownHandler)
    },
    destroyed() {
        document.removeEventListener('keyup', this.keyDownHandler)
    },
    created() {
        // this.getlist()
    },
    methods: {

        keyDownHandler(e) {
            if (e.code === 'Escape') {
                this.$emit('onHide')
                return
            }
            // 键盘按下 => ↓
            if (e.code === 'ArrowDown') {
                if (this.index >= this.mockList.length - 1) {
                    this.index = 0
                } else {
                    this.index = this.index + 1
                }
            }
            // 键盘按下 => ↑
            if (e.code === 'ArrowUp') {
                if (this.index <= 0) {
                    this.index = this.mockList.length - 1
                } else {
                    this.index = this.index - 1
                }
            }
            // 键盘按下 => 回车
            if (e.code === 'Enter') {
                if (this.mockList.length) {
                    const user = this.mockList[this.index]
                    this.$emit('onPickUser', user)
                    this.index = -1
                }
            }
        },
        clickAt(e, item) {
            const user = item
            this.$emit('onPickUser', user)
            this.index = -1
        },
        hoverAt(index) {
            this.index = index
        }
    }
}
</script>

<style scoped lang="scss">
.at-dialog-wrapper {
    width: 120px;
    max-height: 140px;
    overflow: auto;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-sizing: border-box;
    padding: 6px 0;
}

.at-dialog-empty {
    font-size: 14px;
    padding: 0 20px;
    color: #999;
}

.item {
    font-size: 14px;
    padding: 0 20px;
    line-height: 34px;
    cursor: pointer;
    color: #606266;

    &.active {
        background: #f5f7fa;
        color: blue;

        .id {
            color: blue;
        }
    }

    &:first-child {
        border-radius: 5px 5px 0 0;
    }

    &:last-child {
        border-radius: 0 0 5px 5px;
    }

    .id {
        font-size: 12px;
        color: rgb(83, 81, 81);
    }
}
</style>