<template>
    <div class="fc-org-select">
        <div class="tags">
            <el-button v-if="buttonType === 'button'" size="small" type="primary" icon="el-icon-plus"
                @click.stop="show = true" style="margin-bottom: 6px;">
                {{ title }}
            </el-button>
            <div class="input-box" :class="{ 'as-input': buttonType === 'input' }">
                <el-tag v-bind="tagConfig" class="org-tag" v-for="(item, index) in innerValue" :key="index"
                    @click.stop="show = true" @close.stop="onClose(index)">
                    {{ item.user_name }}
                </el-tag>
            </div>
        </div>
        <orgTransfer ref="transfer" :list="list" :isContain="isContain" :value="innerValue" :title="title"
            :searchable="searchable" :maxNum="maxNum" :tab="tab" :isCheckbox="isCheckbox" :show.sync="show"
            @confirm="onConfirm" />
    </div>
</template>
<script>
import orgTransfer from '@/components/Tools/OrgTransfer/index.vue'
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    name: 'fc-org-select',
    props: {
        value: {
            default: () => ([])
        },
        tab: {//类型 人员 部门 部门或人员
            default: () => ('all')
        },
        isCheckbox: {//单选或多选
            type: Boolean,
            default: true
        },
        title: {//标题
            type: String,
            default: '组织机构'
        },
        buttonType: {//按钮或输入框
            type: String,
            default: 'input'
        }, // or input
        searchable: {
            type: Boolean,
            default: true
        },
        maxNum: {
            type: Number,
            default: 99
        },
        tagConfig: {
            type: Object,
            default: () => ({
                type: 'info',
                closable: true,
                'disable-transitions': false,
                hit: false,
                color: undefined,
                size: 'small',
                effect: 'light'
            })
        },
        //数据
        list: {
            type: Array,
            required: true,
        },
        //包含子部门
        isContain: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tabKeys: [],
            show: false,
            innerValue: [],
            selectedData: []
        }
    },
    created() {

    },
    components: {
        orgTransfer
    },
    watch: {
        value: {
            handler(val) {
                if (val.length == 0) return
              //  console.log(val);
                this.innerValue = [...val]
                // this.$emit('updt')
                this.$refs.transfer.up(val)
                // this.$refs.transfer.up(this.innerValue)
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
    },
    methods: {

        onClose(index) {
            this.innerValue.splice(index, 1)
            this.$refs.transfer.up(this.innerValue)
            // console.log(index);
            // console.log(this.innerValue);
            // console.log(this.$refs.transfer);
            this.$emit('input', this.innerValue)
        },

        onConfirm(data) {
            this.innerValue = data
          //  console.log(data)
            this.$emit('input', this.innerValue)
            // this.$emit('updt', this.innerValue)
        },
        selectedLabels() {
            return 'yyyyyyyy'
        }
    }
}
</script>
<style lang="stylus" scoped>
.tags {
  .input-box.as-input{
    border: 1px solid #DCDFE6;
    padding-left: 6px;
    font-size: 12px;
    min-height: 32px;
    line-height: 30px;
    border-radius: 4px;
    background: white;
    color #606266
    cursor pointer
  }
  .org-tag{
    margin-right: 6px;
    max-width: 6rem;
    overflow hidden
    text-overflow ellipsis
    position relative
    padding-right 1rem
    vertical-align middle

    >>> .el-tag__close{
      position: absolute;
      right: 2px;
      top: 50%;
      margin-top: -7px;

    }
  }
}
</style>
