<template>
    <div style="display: flex;width: 100%;">
        <el-input-number style="width: 50%;" controls-position="right" :disabled="disabled" v-model="startDay" :min="1"
            :max="31" @input="updateRange"></el-input-number>
        <span>到</span>
        <el-input-number style="width: 50%;" controls-position="right" :disabled="disabled" v-model="endDay" :min="1"
            :max="31" @input="updateRange"></el-input-number>
    </div>
</template>

<script>
export default {
    props: ['disabled', 'defaultValue'],
    data() {
        return {
            startDay: this.defaultValue[0] || null,
            endDay: this.defaultValue[1] || null,
        };
    },
    methods: {
        updateRange() {
            // // 确保起始日期小于等于结束日期
            // if (this.startDay > this.endDay) {
            //     const temp = this.startDay;
            //     this.startDay = this.endDay;
            //     this.endDay = temp;
            // }

            // 触发值更新的事件，将选择的日期范围作为组件的值进行存储或传递给父组件
            this.$emit('input', [this.startDay, this.endDay]);
        },
    },
};
</script>