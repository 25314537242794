<template>
    <div>
        <h1 v-if="isSave">生产领料</h1>
        <div class="orders_form">
            <el-form size="mini" label-position="left" :disabled="isReadonly" :model="ordersObj" label-width="130px"
                :rules="ordersObjRules" :inline="true" ref="ordersObjRef1">
                <el-form-item label="发货仓库" prop="from_ware_house">
                    <el-input @focus="getInputFocus($event)" size="mini" v-model="ordersObj.from_ware_house_value"
                        placeholder="发货仓库" @keyup.enter.native="onFromWareHouse('发货仓库')"
                        @blur.stop="onFromWareHouse('发货仓库')" clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="onFromWareHouse('发货仓库')"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="接收仓库" prop="to_ware_house">
                    <el-input @focus="getInputFocus($event)" size="mini" v-model="ordersObj.to_ware_house_value"
                        placeholder="接收仓库" @keyup.enter.native="onToWareHouse('接收仓库')" @blur.stop="onFromWareHouse('接收仓库')"
                        clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="onToWareHouse('接收仓库')"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="职员" prop="staff">
                    <el-input @focus="getInputFocus($event)" size="mini" v-model="ordersObj.staff_value" placeholder="职员"
                        @keyup.enter.native="isUser = true" @blur.stop="isUser = true" clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="isUser = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="工单号" prop="work_num">
                    <el-input v-model="ordersObj.work_num" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
                <el-form-item label="领料日期" prop="pick_time">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="领料日期" v-model="ordersObj.pick_time"
                        style="width: 100%" :picker-options="pickerOptions" clearable></el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="orders_table">
            <el-table v-if="!$store.state.isphone" ref="table_ref" width="1300px" size="mini" :data="ordersObj.products"
                border :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }" show-summary
                :summary-method="getSummaries">
                <el-table-column label="序号" type="index" width="50px"></el-table-column>
                <el-table-column label="设置" width="100">
                    <template slot-scope="data">
                        <el-button type="text" @click="onPushOrders">添加</el-button>
                        <el-button type="text" @click="onDeleteOrders(data.$index)">删除</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="品目编码" prop="product_code" width="256px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" :disabled="isReadonly" size="mini"
                            v-model="data.row.product_code" placeholder="品目编码"
                            @keyup.enter.native="onOrdersProductSearch(data.$index)"
                            @change="onOrdersChange(data.row.product_code, data.$index)"
                            @blur.stop="onOrdersProductSearch(data.$index)" clearable>
                            <el-button size="mini" slot="prepend" icon="el-icon-search"
                                @click="onOrdersProductSearch(data.$index)"></el-button>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="品目名称" prop="product_title" width="150px">
                </el-table-column>
                <el-table-column label="品目规格" prop="specifications" width="210px">
                </el-table-column>
                <el-table-column label="批次号" prop="batch_num" width="210px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" :disabled="isReadonly" :min="1" clearable size="mini"
                            v-model="data.row.batch_num" placeholder="批次号">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="数量" prop="quantity" width="130px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" type="number" :disabled="isReadonly" :min="1" clearable
                            size="mini" v-model="data.row.quantity" placeholder="数量">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="单位" prop="unit" width="130px">
                </el-table-column>
                <!-- <el-table-column label="单价" prop="unit_price" width="130px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" type="number" :disabled="isReadonly" :min="1" clearable
                            size="mini" v-model="data.row.unit_price" placeholder="单价">
                        </el-input>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column label="金额" prop="production_money" width="130px">
                    <template slot-scope="data">
                        <div>
                            {{ data.row.production_money=data.row.quantity * data.row.unit_price }}
                        </div>
                    </template>
                </el-table-column> -->

                <el-table-column label="产品摘要" prop="abstract" width="210px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" size="mini" :disabled="isReadonly"
                            v-model="data.row.abstract" placeholder="摘要" clearable>
                        </el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else style="border: 1px dashed #8fc31f; padding: 10px; border-radius: 12px">
                <div v-if="!isReadonly">
                    <template v-for="(item, i) in ordersObj.products">
                        <div :key="i"
                            style="border: 1px solid #dcdfe6;padding: 10px;margin-bottom: 10px;border-radius: 12px; ">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <p>产品{{ i + 1 }}</p>
                                <i @click="onDeleteOrders(i)" class="el-icon-close"></i>
                            </div>
                            <el-form :model="item">
                                <el-form-item label="品目编码" prop="product_code">
                                    <el-input @focus="getInputFocus($event)" :disabled="isReadonly" size="mini"
                                        v-model="item.product_code" placeholder="品目编码"
                                        @keyup.enter.native="onOrdersProductSearch(i)" @blur.stop="onOrdersProductSearch(i)"
                                        @change="onOrdersChange(item.product_code, i)" clearable>
                                        <el-button size="mini" slot="prepend" icon="el-icon-search"
                                            @click="onOrdersProductSearch(i)"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="品目名称" prop="product_title">
                                    <!-- <div>{{ item.product_title }}</div> -->
                                    <el-input size="mini" v-model="item.product_title" disabled
                                        placeholder="品目名称"></el-input>
                                </el-form-item>
                                <el-form-item label="品目规格" prop="specifications">
                                    <el-input size="mini" v-model="item.specifications" disabled
                                        placeholder="品目规格"></el-input>
                                </el-form-item>
                                <el-form-item label="批次号" prop="batch_num">
                                    <el-input @focus="getInputFocus($event)" :disabled="isReadonly" clearable size="mini"
                                        v-model="item.batch_num" placeholder="批次号">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="数量" prop="product_title">
                                    <el-input @focus="getInputFocus($event)" type="number" :min="1" clearable size="mini"
                                        v-model="item.quantity" placeholder="数量">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="单位" prop="specifications">
                                    <el-input size="mini" v-model="item.unit" disabled placeholder="单位"></el-input>
                                </el-form-item>
                                <el-form-item label="产品摘要" prop="abstract">
                                    <el-input @focus="getInputFocus($event)" size="mini" v-model="item.abstract"
                                        placeholder="摘要" clearable>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                </div>
                <div v-else>
                    <!-- <div style="padding: 0 0 10px 10px">
                        总计：{{ Thousands(onTotal(ordersObj.products)) }}
                    </div> -->
                    <template v-for="(item, i) in ordersObj.products">
                        <div :key="i" @click="onOrdersObjProducts(item)"
                            style="padding: 10px;background-color: #f8ffed;margin-bottom: 10px;border-radius: 12px;">
                            <div
                                style=" min-width: 300px;font-weight: 600;font-size: 24px;color: rgb(143, 195, 31);padding: 5px;">
                                {{ item.product_code }}
                            </div>
                            <div style="font-weight: 600; font-size: 16px; padding: 5px">
                                {{ item.product_title }}
                            </div>
                            <div v-if="ordersObjProductsId.includes(item.product)" class="card_item">
                                <div>规：</div>
                                <div>{{ item.specifications }}</div>
                            </div>
                            <div class="card_item">
                                <div>批：</div>
                                <div>{{ item.batch_num }}</div>
                            </div>
                            <div class="card_item">
                                <div>数：</div>
                                <div>{{ item.quantity }}</div>
                            </div>
                            <div class="card_item">
                                <div>位：</div>
                                <div>{{ item.unit }}</div>
                            </div>
                            <div class="card_item">
                                <div>摘：</div>
                                <div>{{ item.abstract }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <inquireDialog v-if="isWarehouse" :isVisible="isWarehouse" :obj="warehouseObj" :title="'仓库'" :isChoose="true"
            :searchValue="warehouseValue" @onIsVisible="warehouseVisible" @onApplied="warehouseApplied">
        </inquireDialog>
        <inquireDialog v-if="isUser" :isVisible="isUser" :obj="userObj" :title="'职员'" :isChoose="true"
            :searchValue="userValue" @onIsVisible="onUserVisible" @onApplied="onUserApplied">
        </inquireDialog>
        <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'" :isChoose="false"
            :searchValue="productValue" @onIsVisible="productVisible" @onApplied="productApplied">
        </inquireDialog>
    </div>
</template>
<script>
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import { log } from 'util';
export default {
    props: [
        "isAddOrder",
        "addOrderForm",
        "addOrdersCustomer",
        "formTitle",
        "readonly",
        "addOrderId",
    ],
    data() {
        return {
            // 移动端显示产品规格
            ordersObjProductsId: [],
            isSave: false,
            //仓库开关
            isWarehouse: false,
            // 仓库类型
            warehouseTitle: null,
            //仓库id
            warehouseValue: null,
            // 获取仓库参数
            warehouseObj: {
                searchField: "keyword",
                url: "crm/outbound_product_warehouse/",
                params: {
                    per_page: 10,
                    page_number: 1,
                    user_id: localStorage.getItem("user_id"),
                    keyword: "",
                },
            },
            //生产是否可编辑
            isReadonly: this.readonly == undefined ? false : this.readonly,
            //生产领料参数
            ordersObj: {
                work_num: null,//工单号
                from_ware_house: null,//发货仓库
                from_ware_house_value: null,//发货仓库
                to_ware_house: null,//接收仓库
                to_ware_house_value: null,//接收仓库
                pick_time: null,//领料时间
                pick_type: 1,//领料时间
                staff_value: null,//创建人
                staff: null,//创建人
                products: [
                    {
                        product: null,
                        product_code: null,
                        product_title: null,
                        specifications: null,
                        // production_money: null,
                        // unit_price: null,
                        quantity: null,
                        unit: null,
                        batch_num: null,
                        abstract: null,
                    }
                ],
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                ],
            },
            //产品
            isProduct: false,
            //产品查询参数
            productValue: "",
            //产品下标
            quotationTableIndex: "",
            // 产品Dialog参数
            productDialogObj: {
                searchField: "search",
                url: "crm/special_offer/",
                params: {
                    per_page: 10,
                    page: 1,
                    model_name: "SpecialOfferTable",
                    sys_name: 3,
                    user: localStorage.getItem("user_id"),
                    search: "",
                    distinct: 1,
                    is_using: true,
                },
            },
            //职员查询参数
            userValue: "",
            // 是否显示职员
            isUser: false,
            //获取产品参数
            userObj: {
                searchField: "keyword",
                url: "crm/query_user/",
                params: {
                    page: 1,
                    per_page: 10,
                    user_id: localStorage.getItem("user_id"),
                    search: "",
                }
            },
            //生产领料验证
            ordersObjRules: {
                from_ware_house: [
                    {
                        required: true,
                        message: "请选择发货仓库",
                        trigger: ["blur", "change"],
                    },
                ],
                to_ware_house: [
                    {
                        required: true,
                        message: "请选择接收仓库",
                        trigger: ["blur", "change"],
                    },
                ],
                staff: [
                    {
                        required: true,
                        message: "请选择职员",
                        trigger: ["blur", "change"],
                    },
                ],
                work_num: [
                    {
                        required: true,
                        message: "请输入工单号",
                        trigger: ["blur", "change"],
                    },
                ],
                pick_time: [
                    {
                        required: true,
                        message: "请选择领料日期",
                        trigger: ["blur", "change"],
                    },
                ],


            },
        }
    },
    components: { inquireDialog },
    watch: {
        isAddOrder: {
            handler(val) {
                if (val == undefined) {
                    this.isSave = true;
                } else {
                    this.isSave = val;
                }
            },
            deep: true,
            immediate: true,
        },
        ordersObj: {
            handler(val) {
                // this.$emit("onAddOutboundOrder", obj)
                if (this.isAddOrder == false && this.addOrderId == undefined) {
                    this.$emit("onAddOutboundOrder", this.ordersObj, this.paymentDaysObj);
                  //  console.log(val, this.ordersObj);
                }
            },
            deep: true,
        },
        addOrderForm: {
            handler(val) {
                if (this.formTitle == "生产领料") {
                    if (val != undefined) {
                        for (const key in val) {
                            this.ordersObj[key] = val[key]
                        }
                    }
                }
            }

        }
    },
    methods: {
        getInputFocus(event) {
            event.currentTarget.select();
        },
        onUserVisible(data) {
          //  console.log(data);
            this.isUser = data
        },
        onUserApplied(data) {
            // console.log(data);
            this.ordersObj.staff_value = data.first_name
            this.ordersObj.staff = data.id
        },
        warehouseVisible(val) {
            this.isWarehouse = val
        },
        warehouseApplied(val) {
            if (this.warehouseTitle == '发货仓库') {
                // console.log(val);
                this.ordersObj.from_ware_house = val.id
                this.ordersObj.from_ware_house_value = val.warehouse_name
            } else if (this.warehouseTitle == '接收仓库') {
                // console.log(val);
                this.ordersObj.to_ware_house = val.id
                this.ordersObj.to_ware_house_value = val.warehouse_name
            }
            // this.isWarehouse = false
        },
        onFromWareHouse(val) {
            this.warehouseTitle = val
            this.warehouseValue = this.ordersObj.from_ware_house_value
            this.isWarehouse = true
        },
        onToWareHouse(val) {
            this.warehouseTitle = val
            this.warehouseValue = this.ordersObj.to_ware_house_value
            this.isWarehouse = true
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toLocaleString(); // Add thousand separator
                } else {
                    sums[index] = "";
                }
            });
            return sums;
        },
        onPushOrders() {
            this.ordersObj.products.push({
                product: null,
                product_code: null,
                product_title: null,
                specifications: null,
                // production_money: null,
                // unit_price: null,
                quantity: null,
                unit: null,
                batch_num: null,
                abstract: null,
            })
        },
        onDeleteOrders(index) {
            if (this.ordersObj.products.length != 1) {
                this.ordersObj.products.splice(index, 1)
            }
        },
        // 产品Dialog开关
        productVisible(data) {
            this.isProduct = data;
            this.quotationTableIndex = '';
        },
        onOrdersProductSearch(index) {
            this.isProduct = true;
            this.quotationTableIndex = index;
        },
        productApplied(data) {
          //  console.log(data);
            let productArr = data;
            const arr = [];
            productArr.forEach((item) => {
                arr.push({
                    product: item.product, //产品的外键id
                    product_code: item.field0,//品目编码
                    product_title: item.item_name,//品目名称
                    specifications: item.specifications,
                    // production_money: null, //产品数量
                    // unit_price: item.price, //入库单价
                    batch_num: null,
                    quantity: null, //产品数量
                    unit: item.unit,
                    abstract: null,
                });
            });
            this.ordersObj.products.splice(this.quotationTableIndex, 1, ...arr);
        },
        onOrdersObjProducts(val) {
            // console.log(val);
            if (this.ordersObjProductsId.includes(val.product)) {
                const index = this.ordersObjProductsId.indexOf(val.product);
                this.ordersObjProductsId.splice(index, 1);
            } else {
                this.ordersObjProductsId.push(val.product);
            }
        },
    },
    mounted() {
        let today = new Date();
        this.ordersObj.pick_time =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1 < 10
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1) +
            "-" +
            (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
    }
}
</script>
<style lang="less" scoped>
.orders_form {
    // width: 1300px;

    .el-form {
        // display: flex;
        flex-wrap: wrap;

        .el-form-item {
            flex: 1;
            margin-top: 0 !important;
            padding-left: 10px;
        }

        .el-form-item__content {
            width: 210px;
        }

        .el-date-editor {
            width: 210px !important;
        }

        .el-input {
            width: 210px;
        }

        .el-switch {
            width: 210px;
        }

        .el-select {
            width: 210px;
        }
    }
}

@media screen and (max-width: 1000px) {
    /deep/.el-textarea__inner {
        width: 260px !important;
    }
}

@media screen and (min-width: 1000px) {
    /deep/.el-textarea__inner {
        width: 460px !important;
    }
}

// 合计行文本居中
/deep/.el-table__footer-wrapper {
    tr {
        td {
            text-align: center;
        }
    }
}

.orders_table {

    // width: 1300px;
    .card_item {
        display: flex;
        justify-content: flex-start;
    }
}
</style>