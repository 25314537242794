
<template>
  <section v-show="show" class="h-transfer" :class="[tabConfig.length == 1 ? 'single-tab' : '']">
    <div class="mask"></div>
    <!-- 内容面板 -->
    <div class="transfer__content">
      <!-- 面板顶部标题 -->
      <header class="transfer__header">
        <i class="el-icon-monitor"></i>
        {{ title }}
        <i class="el-icon-close" @click="closeTransfer"></i>
      </header>
      <!-- 穿梭框主要内容 -->
      <div class="transfer__body">
        <!-- 左边穿梭框 -->
        <div class="transfer-pane">
          <div class="transfer-pane__tools">
            <el-input v-model="searchString" class="search-input" size="mini" style="width: 180px;" type="search"
              placeholder="搜索人员" :disabled="!searchable"></el-input>
            <span>
              <span style="margin-right: 1rem;font-size: 14px;">{{ selecte.length }} / {{ maxNum }}</span>
              <el-tooltip placement="top" content="清空">
                <i class="el-icon-delete" @click="removeAll"></i>
              </el-tooltip>
            </span>
          </div>
          <el-scrollbar class="transfer-pane__body shadow right-pane">
            <div v-for="(item, index) in selecte" :key="index" class="selected-item">
              <span>
                <!-- <i v-if="item.deptName" class="iconfont iconbumen"></i>
                                                      <i v-else class="iconfont iconyuangong"></i> &nbsp; -->
                <span>{{ item.label }}</span>
              </span>
              <i class="el-icon-delete" @click="removeData(item)"></i>
            </div>
          </el-scrollbar>
          <footer class="transfer__footer">
            <el-button type="info" plain size="mini" @click="confirm">确定</el-button>
            <el-button plain size="mini" @click="closeTransfer">取消</el-button>
          </footer>
        </div>
        <!-- 右边穿梭框 -->

        <div class="transfer-pane">
          <!-- 操作栏 -->
          <!-- <div class="transfer-pane__tools">

                                                  </div> -->
          <!-- 穿梭框 -->
          <div class="transfer-pane__body" style="height: 370px;">
            <div class="enough-mask" v-show="selecte.length >= maxNum">
              <span class="p-center">最多选择{{ maxNum }}项</span>
            </div>
            <div class="searchResPane" :class="{ active: searchMode }" v-loading="searchLoading">
              <div class="hidden-tag" @click="searchString = ''">关闭</div>
              <div v-for="(item, index) in searchRes" :key="index" class="item">
                <div>
                  <div>{{ getNodeProp(item, 'label') }}</div>
                  <div class="text-ellipsis search-res-tip">
                    {{ getNodeProp(item, 'searchResTip') }}
                  </div>
                </div>
                <el-checkbox
                  @change="checked => checked ? addData(item) : removeData(item, activeTabName, true)"></el-checkbox>
              </div>
            </div>

            <el-scrollbar style="height:100%;">
              <el-tabs v-model="activeTabName" type="border-card" style="min-height: 370px;">
                <el-tab-pane name="1"
                  :label="tab === 'all' ? '部门与人员' : tab === 'user' ? '人员' : tab === 'dep' ? '部门' : '人员'"
                  v-if="isCheckbox == false">
                  <el-tree :data="origindata" :props="defaultProps" show-checkbox :check-strictly="true"
                    @check="treeCheck" ref="tree" node-key="id" :filter-node-method="filterNode"></el-tree>
                </el-tab-pane>
                <el-tab-pane name="1"
                  :label="tab === 'all' ? '部门与人员' : tab === 'user' ? '人员' : tab === 'dep' ? '部门' : '人员'" v-else>
                  <el-tree :data="origindata" :props="defaultProps" show-checkbox :check-strictly="true"
                    @check-change="checkchange" ref="tree" node-key="id" :filter-node-method="filterNode"></el-tree>
                </el-tab-pane>
              </el-tabs>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-debugger */
/* eslint-disable no-return-assign */
/* eslint-disable handle-callback-err */

export default {
  name: 'fc-org-transfer',
  props: {
    // v-model 已经选择过的数据 用于回显
    value: {
      default: () => ([])
    },
    // 字符串数组使用配置文件的预设值
    // 对象数组需要包含 key/conf 两个属性 conf 会覆盖对应key的默认配置

    tab: {//user 只能选择人员 
      default: () => 'dep'
    },
    isCheckbox: {//false单选 true多选
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '组织机构'
    },
    show: {
      type: Boolean,
      reuired: true
    },
    // 是否支持模糊查询
    searchable: {
      type: Boolean,
      default: true
    },
    // 可选择的最大数量
    maxNum: {
      type: Number,
      default: 99
    }
  },
  data() {
    return {
      searchRes: [], // 搜索后的结果
      selectedData: [], // 用户手动选择的节点(在tree里面已经显示的节点)
      selecte: [],
      aloneCheckedData: [], // 已有的 但是未在tree中渲染的数据 例如回显时的数据
      isEnough: false, // 是否选择了足够的人数
      searchString: '',
      searchMode: false, // 是否展示搜索面板
      searchLoading: false,
      activeTabName: '1',
      tabConfig: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      origindata: []
    }
  },
  computed: {

  },
  created() {
    this.getdept()
  },
  watch: {
    value: {
      handler: function (val) {
      //  console.log('dddddddddd', val)
        this.initselect(val)

      },
      deep: true, immediate: true
    }
  },
  mounted() {
    // this.debounceSearch = debounce(this.searchDepUser, 500)

  },
  methods: {
    filterNode(value, origindata) {
      if (!value) return true;
      return origindata.label.indexOf(value) !== -1;
    },
    async getdept() {
      const res = await this.$http.get('pm/get_hugtech_organization/')

      this.origindata = [res.data.res_organization_data]
      // console.log(this.origindata);
      this.filtersdata()
      this.initselect(this.value)
    },
    initselect(data) {
      this.selecte = []
      const arr = []
      Array.from(data).map(item => {
        arr.push(item.id)
        this.checkchange(item, true)
      })
      this.$refs.tree.setCheckedKeys(arr)
    },
    up(res) {
      this.initselect(res)
    },
    filtersdata() {
      const that = this
      let idx = 1
      function removearr(arr) {
        arr.id = idx
        idx += 1
        if (arr.user_name) {
          arr.label = arr.user_name
        } else {
          arr.label = arr.dept_name[0]
          arr.dept_id = (arr.dept_id[0])
          if (that.tab === 'user') {
            arr.disabled = true
          }
        }
        // arr.children && arr.children.map(item => removearr(item))
        if (arr.children) {
          that.tab === 'dep' && (arr.children = arr.children.filter((item) => {
            return !item.user_name
          }))
          arr.children.map((item, index, rr) => {
            removearr(item)
          })
        }
      }
      this.origindata.map(it => {
        removearr(it)
      })
      // console.log(this.origindata)
    },

    searchDepUser() {
      if (!this.searchString) {
        this.searchRes = []
        return
      }
      this.searchLoading = true
      const activeConfig = this.getActiveConf()
      new Promise((resolve, reject) => {
        activeConfig.onsearch(this.searchString, resolve, reject)
      })
        .then(res => {
          this.searchRes = res.map(t => ({ nodeId: activeConfig.nodeId(t), ...t }))
        })
        .catch(err => console.warn(err))
        .finally(() => this.searchLoading = false)
    },
    // 多选
    checkchange(data, checked) {
      // console.log(data);
      // console.log(this.$refs.tree);
      // console.log(1);
      if (checked) {
        if (data.children) {
          delete data.children
        }
        this.selecte.push(data)
      } else {
        let i = null
        this.selecte.map((item, index) => {
          (item.$treeNodeId === data.$treeNodeId) && (i = index)
        })
        this.selecte.splice(i, 1)
      }
    },
    // 单选
    treeCheck(node, list) {
      if (list.checkedNodes[0] == undefined) {
        this.selecte = []
      } else {
        if (list.checkedKeys.length > 1) {
          // 单选实现
          this.$refs.tree.setCheckedKeys([node.PHY_ID])
          this.selecte = []
        } else {
          if (this.selecte.length == 2) {
            this.selecte = []
          } else {
            this.selecte = []
            this.selecte.push(node)
          }
        }
      }
    },
    addData(data) {
      const tabKey = this.activeTabName
      const tree = this.$refs[tabKey][0]
      tree.setChecked(data.nodeId, true)
      !tree.getCheckedKeys(data).includes(data.nodeId) &&
        !this.aloneCheckedData[tabKey].find(t => t.nodeId === data.nodeId) &&
        this.aloneCheckedData[tabKey].push(data)
    },

    removeData(data, tabKey, fromAloneData = false) {
    //  console.log(data, tabKey, fromAloneData );
      if (fromAloneData) {
        const index = this.aloneCheckedData[tabKey].findIndex(t => t.nodeId === data.nodeId)
        index > -1 && this.aloneCheckedData[tabKey].splice(index, 1)
      } else {
        // console.log(this.selecte);
        // this.selecte = this.selecte.filter(items => {
        //   if (items.id !== data.id) {
        //     return items
        //   }
        // })
        this.$refs.tree.setChecked(data.id, false) // 触发onCheckChange
        // console.log(data);
      }
    },

    removeAll() {
      this.$refs.tree.setCheckedKeys([])
      this.selecte = []
    },

    closeTransfer() {
      this.$emit('update:show', false)
      // this.isEnough = false
      // this.searchString = ''
      // this.confirm()
    },

    confirm() {
      const res = {}
      this.$emit('confirm', this.selecte)
      this.closeTransfer()
    },

    getActiveConf(tabKey) {
      const target = tabKey || this.activeTabName
      return this.tabConfig.find(t => t.tabKey === target)
    },

    getConfProp(propName, tabKey) {
      const conf = this.getActiveConf(tabKey)
      return conf ? conf[propName] : null
    },

    getNodeProp(data, propName, tabKey) {
      try {
        const prop = this.getConfProp(propName, tabKey)
        if (typeof prop === 'string') {
          return data[prop]
        }
        if (typeof prop === 'function') {
          return prop(data)
        }
      } catch (e) {
        console.error(e)
        return '执行出错，可联系开发人员'
      }
    }
  },
  watch: {
    searchString(newVal) {
      // this.searchMode = !!newVal
      this.$refs.tree.filter(newVal);
      // this.debounceSearch()
    }

  }
}
</script>
<style lang="stylus">
.h-transfer{
  text-align: left;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2999;
  line-height: 32px;

  > .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .el-tabs--border-card {
    box-shadow: none;
  }

  .el-tabs__content {
    overflow: visible;
    min-height: 250px;
  }

  .el-tabs__nav {
    width: 100%;
    display: flex;

    > .el-tabs__item {
      flex-grow: 1;
    }
  }

  &.single-tab .el-tabs__item {
    text-align: center;
    background: #f5f7fa !important;
    border-bottom: 1px solid #e4e7ed;
    border-right-width: 0px;
  }

  .el-tree-node__content > label.el-checkbox {
    // position: absolute;
    // right: 0;
  }

  .searchResPane{
    position: absolute;
    overflow-y: auto;
    z-index: 99;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid #dcdfe6;
    transition: top .5s;

    &.active{
        top: 0;
    }

    .hidden-tag{
        color:#999;
        font-size:12px;
        text-align:right;
        padding-top:4px;
        padding-right:12px;
        cursor pointer

        &:hover{
            color: #66b1ff;
        }
    }

    .item{
        padding: 4px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height 1.5
        &:hover{
            background-color: #ecf5ff;
            color: #66b1ff;
            cursor: pointer;
        }

        .search-res-tip{
          font-size:12px;
          color:#999;
          max-width: 280px;
        }
    }
  }

  .enough-mask{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    color: white;
    font-size: 16px;
    z-index: 100;
    height: 100%;
    background: rgba(0,0,0,0.5);
    letter-spacing: 4px;
  }

  .p-center{
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
  }

  .transfer__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background: white;
  overflow: hidden;
  border-radius: 4px;
}

.transfer__header {
  margin-bottom: 6px;
  background: rgb(89,87,87);
  padding: 6px 24px;
  color: white;
  .el-icon-close{
    cursor:pointer;
    float: right;
    margin-top: 10px;
  }
}

.transfer__body {
  padding: 12px 0;
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width:1024px) {
  .transfer__body {
  flex-direction: column
  align-items: center
}
.transfer__content{
  width: 400px;
}
.transfer-pane__body{
  height: 200px !important;
}
 }
.transfer-pane {
  width: 360px;
}
  .search-input  input{
    border: 1px solid #DCDFE6 !important;
    &:focus{
      border-color:#409EFF !important;
    }
  }

.transfer-pane__tools {
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:last-child {
    cursor: pointer;
  }
}

.transfer-pane__body {
  position relative
  width: 100%;
  height: 330px;
  overflow hidden
  font-size: 14px;

  >>> .el-scrollbar__view{
    height: 100%;
  }

  .el-tabs__item {
    height: 26px;
    line-height: 26px;
  }
}

.transfer-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;

  i {
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 20px;
    color: #696969;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .node-label {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  .node-checkbox {
    position: absolute;
    right: 0;
  }

  i {
    &:hover {
      color: #1485f8;
      cursor: pointer;
    }

    font-size: 10px;
  }
}

.right-pane {
  box-sizing: border-box;
  overflow-x: hidden;
  height: 290px;
  margin-bottom: 10px;

  .selected-item {
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #F5F7FA;
    }

    span {
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      &:hover {
        color: #1485f8;
        cursor: pointer;
      }
    }
  }
}

.dot{
  width: 2px;
  height: 2px;
  display: inline-block;
  border-radius: 50%;
  background: #4caf50;
}

  .text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
