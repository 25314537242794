<template>
    <div class="fc-splicing">
        <template v-for="item in splicingList">
            <span :key="item" v-if="formData['field' + item] != undefined">
                {{ getSplicing(formData['field' + item]) }}
            </span>
        </template>
    </div>
</template>
<script>
import ADDRESS from '@/components/OA/components/DynamicForm/components/generator/province.js'
export default {
    name: "fc-splicing",
    model: {
        prop: "value",
        event: "change",
    },
    props: [
        "formData",
        "tag",
        "conf",
        "tagIcon",
        "label",
        "formId",
        "defaultValue",
        "disabled",
        "splicingList",
        "labelWidth",
        "style",
        "readonly",
        "required",
        "regList",
        "changeTag",
        "proCondition",
        "asSummary",
    ],
    data() {
        return {
            list: ADDRESS
        }
    },
    watch: {

    },
    created() {

    },
    methods: {
        getSplicing(val) {
            if (Array.isArray(val)) {
                let str1 = ''
                let str2 = ''
                let str3 = ''
                if (val[0] != undefined) {
                    this.list.forEach(item => {
                        if (item.value == val[0]) {
                            str1 = item.label
                        }
                    })
                }
                if (val[1] != undefined) {
                    this.list.forEach(item => {
                        item.children.forEach(items => {
                            if (items.value == val[1]) {
                                str2 = items.label
                            }
                        })
                    })
                }
                if (val[2] != undefined) {
                    this.list.forEach(item => {
                        item.children.forEach(items => {
                            items.children.forEach(data => {
                                if (data.value == val[2]) {
                                    str3 = data.label
                                }
                            })
                        })
                    })
                }
                return str1 + str2 + str3
            } else {
                return val
            }
        }
    }
}
</script>
<style lang="less" scoped>
.fc-splicing {
    display: flex;
    align-items: center;
}
</style>