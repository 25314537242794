<template>
    <div>
        <el-dialog :visible.sync="isExpenseCategory" width="50%" v-if="isExpenseCategory" v-dialogDrags
            :close-on-click-modal="false" :show-close="false" :append-to-body="true"
            :fullscreen="isExpenseCategoryFullscreen">
            <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                <div class="title">
                    <span style="padding: 10px;">费用类别</span>
                </div>
                <div>
                    <i v-if="isExpenseCategoryFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isExpenseCategoryFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isExpenseCategoryFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;"
                        @click.stop="onIsExpenseCategory"></i>
                </div>
            </div>
            <div>
                <el-tree :data="treeList" :props="{
                    children: 'children',
                    label: 'label',
                }" :check-strictly="true" show-checkbox ref="productInfotree" node-key="id"
                    :filter-node-method="filterNode" @check="checkchange"></el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="role_ids.includes(3)" size="mini" @click="isTree = true">新增或修改</el-button>
                <el-button size="mini" @click="onIsExpenseCategory">取 消</el-button>
                <el-button size="mini" type="primary" @click="onAddExpenseCategory">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="添加" :visible.sync="isTree" v-if="isTree" width="50%" v-dialogDrags :close-on-click-modal="false"
            :show-close="false" :append-to-body="true" :fullscreen="isTreeFullscreen">
            <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                <div class="title">
                    <span style="padding: 10px;">新增类别</span>
                </div>
                <div>
                    <i v-if="isTreeFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isTreeFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isTreeFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onCancel('isTree')"></i>
                </div>
            </div>
            <div style="height: 400px; overflow: auto;min-width: 260px;">
                <el-tree default-expand-all check-strictly node-key="id" :data="treeList" :props="{
                    children: 'children',
                    label: 'label',
                }" :default-expanded-keys="[0]">
                    <div class="custom-tree-node" slot-scope="{ node, data }"
                        style="width: 90%;display: flex;align-items: center;">
                        <div>{{ node.label }}</div>
                        <div v-if="data.id != 0" class="tree_btn">
                            <el-popover popper-class="black" width="50" style="margin-right: 10px;" placement="top"
                                trigger="hover">
                                <div class="el-popconfirm" style="text-align: center">编辑</div>
                                <el-button slot="reference" type="text" class="text_edit" size="mini" icon="el-icon-edit"
                                    @click.stop="onEditCategory(data)">
                                </el-button>
                            </el-popover>
                            <el-popover popper-class="black" width="50" style="margin-right: 10px;" placement="top"
                                trigger="hover">
                                <div class="el-popconfirm" style="text-align: center">新增</div>
                                <el-button slot="reference" type="text" class="text_plus" size="mini" icon="el-icon-plus"
                                    @click.stop="onAddCategory(data)"></el-button>
                            </el-popover>
                            <el-popover popper-class="black" width="50" style="margin-right: 10px;" placement="top"
                                trigger="hover">
                                <div class="el-popconfirm" style="text-align: center">删除</div>
                                <el-button slot="reference" type="text" class="text_delete" size="mini"
                                    icon="el-icon-delete" @click.stop="onDeleteCategory(data)"></el-button>
                            </el-popover>
                        </div>
                        <div v-else class="tree_btn">
                            <el-popover popper-class="black" width="50" style="margin-right: 10px;" placement="top"
                                trigger="hover">
                                <div class="el-popconfirm" style="text-align: center">新增</div>
                                <el-button slot="reference" type="text" class="text_plus" size="mini" icon="el-icon-plus"
                                    @click.stop="onAddCategory(data)"></el-button>
                            </el-popover>
                        </div>
                    </div>
                </el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="isTree = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="isTree = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="isAddCategory" v-if="isAddCategory" width="50%" v-dialogDrags
            :close-on-click-modal="false" :show-close="false" :append-to-body="true" :fullscreen="isAddCategoryFullscreen">
            <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                <div class="title">
                    <span style="padding: 10px;">{{ addCategoryTitle }}类别</span>
                </div>
                <div>
                    <i v-if="isAddCategoryFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isAddCategoryFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isAddCategoryFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;"
                        @click.stop="onCancel('isAddCategory')"></i>
                </div>
            </div>
            <div>
                <el-form :model="categoryForm" size="mini" :rules="rules">
                    <el-form-item label="上一级名称" prop="up_level_name">
                        <el-input disabled v-model="categoryForm.up_level_name" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="classification_name">
                        <el-input v-model="categoryForm.classification_name" size="mini"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="onCancel('isAddCategory')">取 消</el-button>
                <el-button size="mini" type="primary" @click="onAddClassification">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
export default {
    props: {
        isExpenseCategory: {
            type: Boolean,
            default: false,
        },
        categoryValue: {
            type: Array,
            default: []
        }
    },
    components: {

    },
    data() {
        return {
            role_ids: JSON.parse(window.localStorage.getItem('role_ids')),
            categoryId: null,
            categoryForm: {
                upper_name: null,
                up_level_name: null,
                classification_name: null
            },
            dialogTree: null,
            isExpenseCategoryFullscreen: false,
            treeList: null,
            isTree: false,
            isTreeFullscreen: false,
            isAddCategory: false,
            isAddCategoryFullscreen: false,
            addCategoryTitle: '新增'
        }
    },
    methods: {
        async onAddClassification() {
            let obj = this.categoryForm
            if (this.addCategoryTitle == '新增') {
                const res = await this.$http({
                    method: "POST",
                    url: `erp/charge_code_classification/`,
                    data: obj,
                })
                if (res.status == 201) {
                    this.onCancel('isAddCategory')
                    this.getTree()
                } else {
                    this.$message.error(res.data)
                }
            } else {
                const res = await this.$http({
                    method: "PUT",
                    url: `erp/charge_code_classification/${this.categoryId}/`,
                    data: obj,
                })
                if (res.status == 200) {
                    this.onCancel('isAddCategory')
                    this.getTree()
                } else {
                    this.$message.error(res.data)
                }
            }
        },
        onDeleteCategory(data) {
          //  console.log(data);
        },
        async onEditCategory(data) {
            this.categoryId = data.id
            const res = await this.$http({
                method: "GET",
                url: `erp/charge_code_classification/${this.categoryId}/`,
            })
            this.categoryForm.upper_name = res.data.upper_name
            this.categoryForm.up_level_name = res.data.upper_leval_name
            this.categoryForm.classification_name = res.data.classification_name
            this.addCategoryTitle = '修改'
            this.isAddCategory = true
        },
        onAddCategory(data) {
          //  console.log(data);
            this.addCategoryTitle = '新增'
            this.isAddCategory = true
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.group_name.indexOf(value) !== -1;
        },
        checkchange(data, checked) {
            // console.log(checked.checkedNodes);
            if (checked.checkedNodes.length == 2) {
                //取消当前选中节点
                this.$refs.productInfotree.setCheckedNodes([data])
                this.dialogTree = [data];
            } else if (checked.checkedNodes.length === 1) {
                this.$refs.productInfotree.setCheckedNodes([data])
                this.dialogTree = [data];
            } else {
                this.$refs.productInfotree.setCheckedNodes([])
                this.dialogTree = null;
            }
            // console.log(checked.checkedNodes);
            // console.log(this.dialogTree);
        },
        async getTree() {
            const res = await this.$http({
                method: "GET",
                url: "erp/charge_code_classification/get_node_tree/"
            })
            if (res.status == 200) {
                this.treeList = [res.data]
            } else {
                this.$message.error(res.data)
            }
            this.$nextTick(() => {
              //  console.log(this.$refs.productInfotree);
                this.$refs.productInfotree.setCheckedNodes(this.categoryValue)
            })
          //  console.log(this.$refs.productInfotree);
        },
        onAddExpenseCategory() {
            this.$emit('onEmploy', this.dialogTree)
        },
        onIsExpenseCategory() {
            this.$emit("onIsCategory", false)
        },
        onFullscreen(val) {
            this[val] = !this[val]
        },
        onCancel(val) {
            this[val] = false
        },
    },
    watch: {
        isExpenseCategory: {
            handler(val) {
                if (val) {

                }
            },
            deep: true,
        }

    },
    mounted() {
        this.getTree()

    },
    created() {

    },
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
    border-radius: 12px;
}

/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

/deep/.custom-tree-node {
    .tree_btn {
        margin-left: 10px !important;
        display: none;
    }

    &:hover {
        .tree_btn {
            margin-left: 10px !important;
            display: block;

            .el-button--text {
                box-sizing: border-box;
                padding: 2px;
                font-size: 16px;


            }

            .text_edit {
                &:hover {
                    border: 1px solid #8fc31f;
                }
            }

            .text_plus {
                &:hover {
                    border: 1px solid #409eff;
                }
            }

            .text_delete {
                &:hover {
                    border: 1px solid #f56c6c;
                }
            }

            .el-icon-edit {

                color: #8fc31f;
            }

            .el-icon-plus {

                color: #409eff;
            }

            .el-icon-delete {

                color: #f56c6c;
            }


        }
    }
}
</style>