<template>
    <div>

        <div>
            <el-dialog v-if="isTableDialog" :modal="false" top="60px" v-dialogDrags :close-on-click-modal="false"
                :show-close="false" :visible.sync="isTableDialog" width="1000px" :fullscreen="isFullscreen">
                <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                    <div class="title">
                        <span style="padding: 10px;">查询{{ tableDialogTitle }}</span>
                    </div>
                    <div>
                        <i v-if="isFullscreen" class="iconfont icon-zuidahua "
                            style="font-size: 16px; cursor: pointer;margin-right: 10px;" @click.stop="onFullscreen"></i>
                        <i v-else class="iconfont icon-chuangkouzuidahua"
                            style="font-size: 16px; cursor: pointer;margin-right: 10px;" @click.stop="onFullscreen"></i>
                        <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onCancel"></i>
                    </div>
                </div>
                <div class="search_company_name">
                    <el-input v-model="searchDefaultValue" size="mini" placeholder="请输入内容" clearable
                        @keyup.enter.native="onSearch"></el-input>
                    <el-button type="primary" @click.stop="onSearch" size="mini">查询</el-button>
                </div>
                <div>
                    <el-table size="mini" ref="dialogTable" border :data="tableList" v-loading="isDialogTableLoading"
                        @select="onSelectionChange" @select-all="getSelectAll"
                        style="border-color: rgb(220, 223, 230); width: 100%;"
                        :cell-style="{ color: 'rgba(95, 93, 93, 1)', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(255,255,255,0)', textAlign: 'center' }"
                        :header-cell-style="{ color: 'rgba(151, 161, 163, 1)', height: '15px', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(244,246,250,1)', textAlign: 'center' }">
                        <el-table-column v-if="isRadio" width="50px" fixed="left" label="选择" align="center">
                            <template slot-scope="scope">
                                <el-radio :label="scope.row" v-model="radioValue">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column v-else type="selection" :reserve-selection="true"></el-table-column>
                        <el-table-column label="客户名称" fixed="left" prop="company_name" show-overflow-tooltip
                            min-width="180px"></el-table-column>
                        <el-table-column label="订单编号" prop="company_name" min-width="130px">
                            <template slot-scope="scope">
                                <div class="particulars" @click="onOrderNum(scope.row)">
                                    {{ scope.row.num }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="付款方式" prop="payment_method" min-width="120"></el-table-column>
                        <el-table-column label="下单日期" prop="order_date" min-width="120"></el-table-column>
                        <el-table-column label="通过时间" prop="approved_date" min-width="120"></el-table-column>
                        <el-table-column label="交易金额" prop="sum_should_receive">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_should_receive) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="预付款" prop="prepay_amount" min-width="120">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.prepay_amount) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="剩余应收" prop="prepay_amount" min-width="120">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_should_receive - scope.row.prepay_amount) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="本月应收" prop="sum_current_month">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_current_month) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="未来应收" prop="sum_future_month">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_future_month) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="逾期(两月以上)" prop="sum_overdue_over_than_two_month" min-width="120">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_overdue_over_than_two_month) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="逾期(两月)" prop="sum_overdue_two_month">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_overdue_two_month) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="逾期(一月)" prop="sum_overdue_one_month">
                            <template slot-scope="scope">
                                <div>
                                    {{ onAmount(scope.row.sum_overdue_one_month) }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="onCancel">取 消</el-button>
                    <el-button size="mini" type="primary" @click="onTableDialogApplied">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <el-drawer title="详情" :visible.sync="drawer" class="drawer_jsxPreview" :append-to-body="true" v-if="drawer"
            :wrapperClosable="false" :direction="direction" :size="size + '%'">
            <Conent v-if="drawer" :drawer="this.drawer" :isInForm="false" :form_id="this.form_id" :flow_id="this.flow_id"
                :approve_status="this.approve_status" :size="size" @onToPrint="onToPrint">
            </Conent>
        </el-drawer>
    </div>
</template>
<script>
import { debounce } from "@/utils/AntiShake"
import Conent from "@/components/OA/components/conent.vue";
export default {
    props: {
        isTableDialog: {
            type: Boolean,
            default: false,
        },
        tableDialogTitle: {
            type: String,
            default: "测试",
        },
        isRadio: {
            type: Boolean,
            default: false,
        },
        tableDialogObj: {
            type: Object,
            default: {
                method: 'GET',
                url: "",
                params: {}
            }
        },
        searchValue: {
            type: String,
            default: ''
        },
        searchField: {
            type: String,
            default: 'search'
        },
    },
    data() {
        return {
            size: 100,
            drawer: false,
            form_id: null,
            flow_id: null,
            approve_status: null,
            isFullscreen: false,
            tableList: null,
            radioValue: null,
            selectionValue: null,
            searchDefaultValue: this.searchValue
        }
    },
    computed: {
        size() {
            if (this.$store.state.isphone) {
                return 100;
            } else {
                return 50;
            }
        },
    },
    watch: {
    },
    components: {
        Conent
    },
    created() {
    },
    mounted() {
        this.getTableDialog(this.tableDialogObj)
    },
    methods: {
        onSearch() {
            if (this.tableDialogObj.method == "POST") {
                if (this.searchField == "search") {
                    this.tableDialogObj.data.search = this.searchDefaultValue
                } else {
                    this.tableDialogObj.data.keywrod = this.searchDefaultValue
                }
            } else {
                if (this.searchField == "search") {
                    this.tableDialogObj.params.search = this.searchDefaultValue
                } else {
                    this.tableDialogObj.params.keywrod = this.searchDefaultValue
                }
            }
            this.getTableDialog(this.tableDialogObj)
        },
        onOrderNum(res) {
            this.form_id = res.form_id
            this.flow_id = 189
            this.drawer = true
        },
        async getTableDialog(obj) {
            if (this.searchField == 'search') {
                if (obj.data.search == null || obj.data.search == '') {
                    delete obj.data.search
                }
            } else {
                if (obj.data.keywrod == null || obj.data.keywrod == '') {
                    delete obj.data.search
                }
            }
            const res = await this.$http(obj)
            if (res.status == 200) {
                this.tableList = res.data
            } else {
                this.$message.error(res.data)
            }
        },
        onFullscreen() {
            this.isFullscreen = !this.isFullscreen
        },
        onCancel() {
            this.$emit("onIsTableDialog", false)
        },
        onTableDialogApplied() {
            if (this.isRadio) {
                // console.log(this.radioValue);
                this.$emit('onApplied', this.radioValue)
            } else {
                // console.log(this.selectionValue);
                this.$emit('onApplied', this.selectionValue)
            }
            this.onCancel()
        },
        onSelectionChange(val) {
          //  console.log(val);
        },
        onAmount(item) {
            return (item * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
    },
}
</script>
<style lang="less" scoped>
.particulars {
    cursor: pointer;
    color: #8fc31f;

    &:hover {
        text-decoration: underline;
    }
}

.cellClickButton {
    color: #409eff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

.phone_bialog_scroll {
    height: 50vh;
    overflow: auto;
    padding: 10px;
    // border: 1px solid #000;

    .phone_bialog_scroll_item {
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: #eaeaed;
        position: relative;

        .phone_bialog_scroll_item_check {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 25px;
            color: #8fc31f;
        }

        .phone_bialog_scroll_items0 {
            font-weight: 700;
            color: #000;
        }

        .phone_bialog_scroll_items {
            font-size: 14px;
            color: #606266;
        }
    }

    .phone_bialog_scroll_item_active {

        .phone_bialog_scroll_items0,
        .phone_bialog_scroll_items {
            color: #8fc31f !important;
        }


    }
}

.search_company_name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .el-input {
        width: 260px;
        margin-right: 20px;
    }

}

.selection_box {
    position: relative;

    .selection_icon {
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
    }

    .selection_value {
        position: absolute;
        top: 20px;
        right: 0px;
        width: 300px;
        height: 480px;
        background: #fff;
        border-radius: 10px;
        z-index: 99;
        overflow-y: auto;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
        padding: 10px;

        .selection_title {
            width: 280px;
            background: #fff;
            // position: fixed;
            // top: 0px;
        }
    }

    .selection_value_item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
        margin-bottom: 10px;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.08);

        // &:hover {}

        .selection_value_item_name {
            width: 220px;
        }

        .selection_value_item_close {
            font-size: 17px;
            cursor: pointer;
        }
    }
}

.undialogTable {
    // width: 300px;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .undialogTable_icon {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        text-align: center;
        background: #F5F5F5;

        i {
            font-size: 30px;
            font-weight: 600;
            line-height: 100px;
            color: #AAAAAA;
        }
    }

    .undialogTable_text {
        margin-top: 10px;
    }
}
</style>