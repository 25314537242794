// 获取昨天的日期范围
var yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
var yesterdayStart = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
var yesterdayEnd = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
var yesterdayRange = formatDate(yesterdayStart) + '-' + formatDate(yesterdayEnd);

// 获取今天的日期范围
var today = new Date();
var todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var todayRange = formatDate(todayStart) + '-' + formatDate(todayEnd);
var yearRange = today.getFullYear()
// console.log(yearRange);
var todayStart = new Date(today.getFullYear(), 0, 1);
var todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var yearsRange = formatDate(todayStart) + '-' + formatDate(todayEnd);
// 获取本周的日期范围
var currentWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
var currentWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
var currentWeekRange = formatDate(currentWeekStart) + '-' + formatDate(currentWeekEnd);

// 获取上周的日期范围
var lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
var lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
var lastWeekRange = formatDate(lastWeekStart) + '-' + formatDate(lastWeekEnd);

// 获取本月的日期范围
var currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
var currentMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
var currentMonthRange = formatDate(currentMonthStart) + '-' + formatDate(currentMonthEnd);

// 获取上月的日期范围
var lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
var lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
var lastMonthRange = formatDate(lastMonthStart) + '-' + formatDate(lastMonthEnd);
//获取下周
var nextWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var nextWeekEnd = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
var nextWeekRange = formatDate(nextWeekStart) + '-' + formatDate(nextWeekEnd);
//获取下下周
var theWeekAfterNextStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var theWeekAfterNextEnd = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
var theWeekAfterNextRange = formatDate(theWeekAfterNextStart) + '-' + formatDate(theWeekAfterNextEnd);
//获取下月
var nextMonthStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var nextMonthEnd = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);
var nextMonthRange = formatDate(nextMonthStart) + '-' + formatDate(nextMonthEnd);
//获取下下月
var theMonthAfterNextStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
var theMonthAfterNextEnd = new Date(today.getTime() + 60 * 24 * 60 * 60 * 1000);
var theMonthAfterNextRange = formatDate(theMonthAfterNextStart) + '-' + formatDate(theMonthAfterNextEnd);


var currentMoneyMonth = getTime()
function getTime() {
    const end = new Date();
    const start = new Date();
    // console.log(start);
    let start1 = null;
    let end1 = null;
    // let day = end.getDate();
    // let month = end.getMonth() + 1;
    // let year = end.getFullYear();
    let day = end.getDate();
    let month = end.getMonth() + 1;
    let year = end.getFullYear();
    // let day = 25;
    // let month = 12;
    // let year = 2024;
    if (day < 24 || day == 24) {
        day = 24;
        let year1 = null
        if (month == 1) {
            month = 12;
            year1 = year - 1
        } else {
            month = month - 1;
            year1 = year
        }
        let months = null;
        if (month == 12) {
            months = 1;
            year = year
        } else {
            months = month + 1;
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = year1 + '/' + month + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        return time
    } else if (day > 24) {
        // month = month - 1;
        day = 25;
        let year2 = null
        let months = null;
        if (month == 12) {
            months = 1;
            year2 = year + 1
        } else {
            months = month + 1;
            year2 = year
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = year + '/' + month + '/' + day + '-' + year2 + '/' + months + '/' + (day - 1)
        return time
    }
}
var lastMoneyMonth = getLastTime()
function getLastTime() {
    const end = new Date();
    const start = new Date();
    let start1 = null;
    let end1 = null;
    let day = end.getDate();
    let month = end.getMonth() + 1;
    let year = end.getFullYear();
    // let day = 25;
    // let month = 12;
    // let year = 2024;
    if (day < 24 || day == 24) {
        day = 24;
        let year1 = null
        if (month == 1) {
            month = 11;
            year1 = year - 1
            year = year - 1
        } else if (month == 2) {
            month = 12;
            year1 = year - 1
        } else {
            month = month - 2;
            year1 = year
        }
        let months = null;

        if (month == 12) {
            months = 1;
            year = year
        } else {
            months = month + 1;
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        // console.log(day, day - 1);
        let time = year1 + '/' + month + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        return time
    } else if (day > 24) {
        // month = month - 1;
        day = 25;
        let year2 = null
        let months = null;
        if (month == 1) {
            months = 12;
            year2 = year - 1
        } else {
            months = month - 1;
            year2 = year
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = year2 + '/' + months + '/' + day + '-' + year + '/' + month + '/' + (day - 1)
        // console.log(time);
        return time
    }
}
var lastTwoMonths = getTime2()
function getTime2() {
    const end = new Date();
    const start = new Date();
    // console.log(start);
    let start1 = null;
    let end1 = null;
    // let day = end.getDate();
    // let month = end.getMonth() + 1;
    // let year = end.getFullYear();
    let day = end.getDate();
    let month = end.getMonth() + 1;
    let year = end.getFullYear();
    // let day = 25;
    // let month = 12;
    // let year = 2024;
    if (day < 24 || day == 24) {
        day = 24;
        let year1 = null
        if (month == 1) {
            month = 12;
            year1 = year - 1
        } else {
            month = month - 1;
            year1 = year
        }
        let months = null;
        if (month == 12) {
            months = 1;
            year = year
        } else {
            months = month + 1;
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = null
        if (month - 1 < 0) {

            time = (year1 - 1) + '/' + 12 + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        } else {
            time = year1 + '/' + (month - 1) + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        }
        return time
    } else if (day > 24) {
        // month = month - 1;
        day = 25;
        let year2 = null
        let months = null;
        if (month == 12) {
            months = 1;
            year2 = year + 1
        } else {
            months = month + 1;
            year2 = year
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = null
        if (month - 1 < 0) {
            time = (year - 1) + '/' + 12 + '/' + day + '-' + year2 + '/' + months + '/' + (day - 1)
        } else {
            time = year + '/' + (month - 1) + '/' + day + '-' + year2 + '/' + months + '/' + (day - 1)
        }
        return time
    }
}
var lastThreeMonths = getTime3()
function getTime3() {
    const end = new Date();
    const start = new Date();
    // console.log(start);
    let start1 = null;
    let end1 = null;
    // let day = end.getDate();
    // let month = end.getMonth() + 1;
    // let year = end.getFullYear();
    let day = end.getDate();
    let month = end.getMonth() + 1;
    let year = end.getFullYear();
    // let day = 25;
    // let month = 12;
    // let year = 2024;
    if (day < 24 || day == 24) {
        day = 24;
        let year1 = null
        if (month == 1) {
            month = 12;
            year1 = year - 1
        } else {
            month = month - 1;
            year1 = year
        }
        let months = null;
        if (month == 12) {
            months = 1;
            year = year
        } else {
            months = month + 1;
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = null
        if ((month - 2) == -1) {
            time = (year1 - 1) + '/' + 12 + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        } else if ((month - 2) == -2) {
            time = (year1 - 1) + '/' + 11 + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        } else {
            time = year1 + '/' + (month - 2) + '/' + (day + 1) + '-' + year + '/' + months + '/' + day;
        }
        return time
    } else if (day > 24) {
        // month = month - 1;
        day = 25;
        let year2 = null
        let months = null;
        if (month == 12) {
            months = 1;
            year2 = year + 1
        } else {
            months = month + 1;
            year2 = year
        }
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        months = months < 10 ? '0' + months : months;
        let time = null
        if ((month - 2) == -1) {
            time = (year - 1) + '/' + 12 + '/' + day + '-' + year2 + '/' + months + '/' + (day - 1)
        } else if ((month - 2) == -2) {
            time = (year - 1) + '/' + 11 + '/' + day + '-' + year2 + '/' + months + '/' + (day - 1)
        } else {
            time = year + '/' + (month - 2) + '/' + day + '-' + year2 + '/' + months + '/' + (day - 1)
        }
        return time
    }
}
// console.log(lastThreeMonths, "近三月");
// console.log(lastTwoMonths, "近两月");
// console.log(currentMoneyMonth, "本财务月");
// 格式化日期为 yyyy/mm/dd 格式
function formatDate(date) {
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return year + '/' + month + '/' + day;
}

let time = new Date().getTime

function TimestampToDate2(Timestamp) {
    let now = new Date(Timestamp),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
    return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
}

let MonthOption = [
    {
        label: "一月" + '(' + (today.getFullYear() - 1) + '/' + '12' + '/' + '25' + '-' + today.getFullYear() + '/' + '01' + '/' + '24' + ')',
        value: (today.getFullYear() - 1) + '/' + '12' + '/' + '25' + ' ' + today.getFullYear() + '/' + '01' + '/' + '24'
    },
    {
        label: "二月" + '(' + today.getFullYear() + '/' + '01' + '/' + '25' + '-' + today.getFullYear() + '/' + '02' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '01' + '/' + '25' + ' ' + today.getFullYear() + '/' + '02' + '/' + '24'
    },
    {
        label: "三月" + '(' + today.getFullYear() + '/' + '02' + '/' + '25' + '-' + today.getFullYear() + '/' + '03' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '02' + '/' + '25' + ' ' + today.getFullYear() + '/' + '03' + '/' + '24'
    },
    {
        label: "四月" + '(' + today.getFullYear() + '/' + '03' + '/' + '25' + '-' + today.getFullYear() + '/' + '04' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '03' + '/' + '25' + ' ' + today.getFullYear() + '/' + '04' + '/' + '24'
    },
    {
        label: "五月" + '(' + today.getFullYear() + '/' + '04' + '/' + '25' + '-' + today.getFullYear() + '/' + '05' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '04' + '/' + '25' + ' ' + today.getFullYear() + '/' + '05' + '/' + '24'
    },
    {
        label: "六月" + '(' + today.getFullYear() + '/' + '05' + '/' + '25' + '-' + today.getFullYear() + '/' + '06' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '05' + '/' + '25' + ' ' + today.getFullYear() + '/' + '06' + '/' + '24'
    },
    {
        label: "七月" + '(' + today.getFullYear() + '/' + '06' + '/' + '25' + '-' + today.getFullYear() + '/' + '07' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '06' + '/' + '25' + ' ' + today.getFullYear() + '/' + '07' + '/' + '24'
    },
    {
        label: "八月" + '(' + today.getFullYear() + '/' + '07' + '/' + '25' + '-' + today.getFullYear() + '/' + '08' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '07' + '/' + '25' + ' ' + today.getFullYear() + '/' + '08' + '/' + '24'
    },
    {
        label: "九月" + '(' + today.getFullYear() + '/' + '08' + '/' + '25' + '-' + today.getFullYear() + '/' + '09' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '08' + '/' + '25' + ' ' + today.getFullYear() + '/' + '09' + '/' + '24'
    },
    {
        label: "十月" + '(' + today.getFullYear() + '/' + '09' + '/' + '25' + '-' + today.getFullYear() + '/' + '10' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '09' + '/' + '25' + ' ' + today.getFullYear() + '/' + '10' + '/' + '24'
    },
    {
        label: "十一月" + '(' + today.getFullYear() + '/' + '10' + '/' + '25' + '-' + today.getFullYear() + '/' + '11' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '10' + '/' + '25' + ' ' + today.getFullYear() + '/' + '11' + '/' + '24'
    },
    {
        label: "十二月" + '(' + today.getFullYear() + '/' + '11' + '/' + '25' + '-' + today.getFullYear() + '/' + '12' + '/' + '24' + ')',
        value: today.getFullYear() + '/' + '11' + '/' + '25' + ' ' + today.getFullYear() + '/' + '12' + '/' + '24'
    },
]


let oneWeek = new Date(today.getTime() + (3600 * 1000 * 24 * 7))
let oneMonth = new Date(today.getTime() + (3600 * 1000 * 24 * 30))
let twoMonth = new Date(today.getTime() + (3600 * 1000 * 24 * 60))
let oneWeekRange = [today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(), oneWeek.getFullYear() + '-' + (oneWeek.getMonth() + 1) + '-' + oneWeek.getDate()]
let oneMonthRange = [today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(), oneMonth.getFullYear() + '-' + (oneMonth.getMonth() + 1) + '-' + oneMonth.getDate()]
let twoMonthRange = [today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(), twoMonth.getFullYear() + '-' + (twoMonth.getMonth() + 1) + '-' + twoMonth.getDate()]

// console.log('昨天:', yesterdayRange);
// console.log('今天:', todayRange);
// console.log('本周:', currentWeekRange);
// console.log('上周:', lastWeekRange);
// console.log('本月:', currentMonthRange);
// console.log('上月:', lastMonthRange);、
// console.log('下周:', nextWeekRange);、
// console.log('下下周:', theWeekAfterNextRange);、
// console.log('下月:', nextMonthRange);、
// console.log('下下月:', theMonthAfterNextRange);、

export {
    yesterdayRange,
    yearsRange,
    todayRange,
    currentWeekRange,
    lastWeekRange,
    currentMonthRange,
    lastMonthRange,
    currentMoneyMonth,
    lastMoneyMonth,
    nextWeekRange,
    theWeekAfterNextRange,
    nextMonthRange,
    theMonthAfterNextRange,
    yearRange,
    MonthOption,
    oneWeekRange,
    oneMonthRange,
    twoMonthRange,
    lastTwoMonths,
    lastThreeMonths,
}
