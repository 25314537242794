import FileSaver from "file-saver";
import * as XLSX from "xlsx";

const htmlToExcel = {
    getExcel(dom, title, data) {
        var excelTitle = title;
        // var xlsxParam = { raw: true }
        // var table = document.querySelector(dom).cloneNode(true)
        // table.removeChild(table.querySelector('.el-table__fixed')) 
      //  console.log(data, data.length);
        let fix = document.querySelector('.export_dialog_right .el-table__fixed')
      //  console.log(fix);
        if (fix) { // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
        //     // 这是个id选择器，我们在需要导出的表格上面加上id就行
            var wb = XLSX.utils.table_to_book(document.querySelector(dom).removeChild(fix))
            document.querySelector(dom).appendChild(fix)
            // wb.Sheets.Sheet1['!cols'][0] = { hidden: true }
            // wb.Sheets.Sheet1['!cols'][data.length+1] = { hidden: true }
            // console.log('wb: ', wb);
        } else {
        //     wb = XLSX.utils.table_to_book(document.querySelector(dom))
        //   //  console.log('wb: ', wb);
            wb = XLSX.utils.table_to_book(document.querySelector(dom));
        }
        for (let i = 1; i < data.length + 1; i++) {
            wb.Sheets.Sheet1["!cols"].push({ wpx: 150 });
            // wb.Sheets.Sheet1["!rows"].push({ hpx: 20 });
        }
        
        //设置单元格样式

        for (const key in wb.Sheets.Sheet1) {
            if (
                key !== "!cols" &&
                key !== "!fullref" &&
                key !== "!merges" &&
                key !== "!ref" &&
                key !== "!rows"
            ) {
                // console.log(111);
                //这里的s就是具体的样式，如果想设置不一样的样式可以看xlsx-style文档
                wb.Sheets.Sheet1[key].s = {
                    //边框
                    border: {
                        top: {
                            style: 'medium',
                        },
                        bottom: {
                            style: 'medium'
                        },
                        left: {
                            style: 'medium'
                        },
                        right: {
                            style: 'medium'
                        }
                    },
                    //对齐
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true
                    },
                    font: {
                        name: '仿宋',
                        sz: 14,
                        bold: true
                    },
                };
                
            }
        }
      //  console.log(wb);
        /* 获取二进制字符串作为输出 */
        var wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
        try {
            FileSaver.saveAs(
                new Blob([wbout], { type: "application/octet-stream" }),
                excelTitle + ".xlsx"
            );
        } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
    }
};

export default htmlToExcel;