import Vue from 'vue'
export async function getOperationalRecorder(obj) {
    const res = await Vue.prototype.$http({
        method: "GET",
        url: "configuration/operational_recorder/",
        params: obj,
    })
    return res
}
export async function setOperationalRecorder(str, obj) {
    const res = await Vue.prototype.$http({
        method: str,
        url: "configuration/operational_recorder/",
        data: obj,
    })
    return res
}