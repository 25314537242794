<template>
    <div>
        <SettingForm ref="SettingAddProcurementPlan" :refs="'purchasingRequisitionRef1'"
            :isSettingLoading="isSettingLoading" :inline="true" :isFormBtn="false" :readonly="readonly"
            :settingForm="settingForm" @onAppliedForm="onAppliedForm" @onSpecials="onSpecials">
            <div slot="shortcut_button" style="margin-bottom: 10px;">
                <el-button type="primary" size="mini" @click="onProcurementRequirement">带出采购需求</el-button>
            </div>
        </SettingForm>
        <inquireDialog v-if="isProcurementRequirement" :isVisible="isProcurementRequirement"
            :obj="procurementRequirementObj" :title="'采购需求'" :isChoose="false"
            :searchValue="procurementRequirementObj.params.search" @onIsVisible="procurementRequirementVisible"
            @onApplied="procurementRequirementApplied">
        </inquireDialog>
        <ExpenseCategory v-if="isCategory" :isExpenseCategory.sync="isCategory" :categoryValue="expenseCategory"
            @onIsCategory="onIsCategory" @onEmploy="onCategoryEmploy">
        </ExpenseCategory>
    </div>
</template>
<script>
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue"
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import SettingForm from "@/components/ProcessFlow/CRM/Forms/components/SettingForm1.vue";
import ExpenseCategory from "@/components/ERP/Setting/components/ExpenseCategory.vue"
import Decimal from 'decimal.js';
export default {
    props: ["isPurchasingRequisition", "addPurchasingRequisitionForm", 'formTitle', "readonly", "dialogCustomerForm"],
    components: {
        SettingForm,
        inquireDialog,
        ExpenseCategory,
    },
    data() {
        return {
            /* 费用类别 */
            expenseCategory: [],
            isCategory: false,
            expenseCategoryIndex: null,
            expenseCategoryField: null,

            isProcurementRequirement: false,
            procurementRequirementObj: {
                searchField: 'search',
                url: "erp/purchase_oder_relationship/",
                params: {
                    per_page: 10,
                    page: 1,
                    order_form_type: 1,
                    is_approve: 1,
                    search: "",
                    customer: null,
                }
            },
            isSettingLoading: false,
            purchasingForm: {
            },
            settingForm: {
                form_root: '采购计划',
                classification: '采购计划',
                control_setting: [{
                    default_value: null,
                    options: [
                        {
                            label: null,
                            value: null,
                        },
                    ],
                    type: '文本输入框',
                    model_class: null,
                    url: null,
                    model_class_field: null,
                    model_class_value: null,
                    is_numeric: false,
                    particulars: [{
                        type: null,
                        url: null,
                        field: null,
                        acquire: null,
                        is_edit: true,
                        is_show: true,
                        label: null,
                        placeholder: null,
                    }],
                    permissions: {
                        edit: true,
                        show: true,
                        update: true,
                        require: false
                    },
                    visible_rule: {
                        role_limit: []
                    },
                    basic_settings: {
                        show_name: "field",
                        field: "field",
                        fields: "field",
                        placeholder: "field"
                    },
                    numeric_settings: {
                        max_digits: null,
                        decimal_places: null
                    },
                }]
            },
        }
    },
    methods: {
        onIsCategory(val) {
            //  console.log(val)
            this.isCategory = val
        },
        onCategoryEmploy(val) {
            //  console.log(val)
            this.purchasingForm.details[this.expenseCategoryIndex][this.expenseCategoryField] = val[0].label
            this.purchasingForm.details[this.expenseCategoryIndex].f_charge_classification = val[0].id
            this.purchasingForm.details[this.expenseCategoryIndex].f_charge_classification_arr = val
            this.expenseCategory = val
            this.isCategory = false
        },
        onSpecials(index, column, row, item) {
            if (column.label == '费用类别') {
                this.expenseCategoryIndex = index;
                if (this.purchasingForm.details[this.expenseCategoryIndex].f_charge_classification_arr != undefined) {
                    this.expenseCategory = this.purchasingForm.details[this.expenseCategoryIndex].f_charge_classification_arr
                } else {
                    this.expenseCategory = []
                }
                this.expenseCategoryField = column.field;
                this.isCategory = true;
            }
        },
        async procurementRequirementApplied(val) {
            try {
                let arr = []
                const promises = val.map(async (item) => {
                    return await this.getOrderApplied(item.id);
                });
                const results = await Promise.all(promises);
                arr = results.filter(item => item !== null);
                let details = []
                arr.forEach(item => {
                    item.details.forEach(items => {
                        items.purchase_requirement_id = item.order_id
                        items.purchase_requirement_number = item.purchase_requirement_number
                        details.push(items);
                    })
                })
                this.purchasingForm.details = details
                if (this.$refs.SettingAddProcurementPlan.$refs.table_ref !== undefined) {
                    const columns = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].columns;
                    let summariesArr = this.getSummaries({ columns: columns, data: this.purchasingForm.details })
                    const footerWrapper = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
                    let cells = footerWrapper.querySelectorAll('.cell')
                    setTimeout(() => {
                        cells.forEach((item, i) => {
                            item.innerText = summariesArr[i]
                        })
                    }, 500);
                }
            } catch (error) {
                console.log(val)
            }
        },
        async getOrderApplied(id) {
            try {
                const res = await this.$http({
                    method: "GET",
                    url: `erp/purchase_oder_relationship/${id}`,
                })
                if (res.status === 200) {
                    let val = null;
                    res.data.oa_form.content.forEach(item => {
                        if (item.control_title === "表单套件") {
                            val = JSON.parse(item.value);
                        }
                    });
                    if (val) {
                        val.order_id = id
                    }
                    return val;
                } else {
                    return null;
                }
            } catch (error) {
                return null
            }
        },
        procurementRequirementVisible(val) {
            this.isProcurementRequirement = val
        },
        onProcurementRequirement() {
            this.isProcurementRequirement = true
        },
        onAppliedForm(val) {
            this.purchasingForm = val
        },
        async getSettingForm() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/form_control_setting/",
                params: {
                    form_root: '采购计划',
                    classification: '采购计划',
                }
            })
            if (res.data[0] != null) {
                this.settingForm = JSON.parse(JSON.stringify(res.data[0]))
                let today = new Date();
                let date = today.getFullYear() + "-" + (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
                this.settingForm.control_setting.forEach((item, i) => {
                    if (item.basic_settings.field == 'filing_date') {
                        item.default_value = date
                    }
                    if (item.basic_settings.field == 'is_tax') {
                        item.default_value = true
                    }
                    if (this.readonly) {
                        if (item.type == '下拉选择框(单选)' || item.type == '下拉选择框(多选)') {
                            this.getSelect(item)
                        }
                    }
                })
                this.isSettingLoading = true
            }
        },
        async getSelect(val) {
            if (val.url != null && val.url != '') {
                const res = await this.$http({
                    method: "GET",
                    url: val.url,
                })
                if (val.url == 'crm/customer_payment_days_verify/?customer_id=1') {
                    val.options = res.data.credit_rule_choice
                } else {
                    val.options = res.data
                }
            }
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
    },
    watch: {
        settingForm: {
            handler(val) {
                EventBus.$emit('onIsEditForm', true);
            }
        },
        addPurchasingRequisitionForm: {
            handler(val) {
                if (this.formTitle == '采购计划') {
                    if (val !== undefined) {
                        for (const key in val) {
                            this.purchasingForm[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        purchasingForm: {
            handler(val) {
                if (this.isPurchasingRequisition == false) {
                    this.$emit("onAddOutboundOrder", this.purchasingForm);
                }
            },
            deep: true,
        },
        "purchasingForm.details": {
            handler(val) {
                const isValid = (value) => value != null && value !== '';
                if (this.$store.state.isphone) {
                    val.forEach(item => {
                        if (isValid(item.unit_price) && isValid(item.quantity)) {
                            let s1 = item.unit_price.toString();
                            let s2 = item.quantity.toString();
                            let decimal1 = new Decimal(s1)
                            let decimal2 = new Decimal(s2)
                            let decimal3 = decimal1.times(decimal2)
                            row.total_actual_amount = decimal3 * 1
                        }
                    })
                } else {
                    if (this.$refs.SettingAddProcurementPlan.$refs.table_ref == undefined) {
                        return
                    }
                    const data = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].data;
                    const columns = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].columns;
                    data.forEach(row => {
                        if (isValid(row.unit_price) && isValid(row.quantity)) {
                            let s1 = row.unit_price.toString();
                            let s2 = row.quantity.toString();
                            let decimal1 = new Decimal(s1)
                            let decimal2 = new Decimal(s2)
                            let decimal3 = decimal1.times(decimal2)
                            row.total_actual_amount = decimal3 * 1
                        }
                    });
                    let arr = this.getSummaries({
                        columns: columns,
                        data: data
                    })
                    const footerWrapper = this.$refs.SettingAddProcurementPlan.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
                    const cells = footerWrapper.querySelectorAll('.cell');
                    cells.forEach((item, i) => {
                        item.innerText = arr[i]
                    })
                }
            },
            deep: true,
        },
        "purchasingForm.ware_house": {
            handler(val) {
                if (val != '' && val != null) {
                    if (this.purchasingForm.warehouse_obj.label != null) {
                        if (!this.readonly) {
                            this.purchasingForm.warehouseValue = this.purchasingForm.warehouse_obj.label
                        }
                    }
                }
            },
            deep: true,
        },
        "purchasingForm.warehouseValue": {
            handler(val) {
                if (val != undefined) {
                    if (this.purchasingForm.warehouse_obj.label == null) {
                        this.purchasingForm.warehouse_obj = {
                            label: val,
                            value: this.purchasingForm.ware_house,
                        }
                    }
                }
            },
            deep: true,
        }
    },
    mounted() {

    },
    created() {
        this.getSettingForm()
    },
}
</script>