<script>
import render from "@/utils/render.js";
import { trigger } from "./DynamicForm/components/generator/config.js";
import approvalsnode from "./approvalsnode.vue";
import { log } from "util";
import { OaEventBus } from "@/components/OA/components/eventBus.js"
/**
 * 校验组织机构组件是否为空
 */
const setFcOrgSelectRule = (conf, ctx) => {
  return {
    validator: (rule, value, callback) => {
      const val = ctx.formModel[rule.field];
      if (Object.keys(val || {}).length === 0) {
        callback();
        return;
      }
      const flag = val.length !== 0;
      callback(flag ? undefined : new Error(`${conf.title}不能为空`));
    },
    trigger: trigger[conf.tag],
    type: "object",
    required: true,
  };
};
/**
 * 收集表单必填项并组装成ElementUI表单校验得rules选项
 * 表格除外 表格自带校验
 */
// 表单控件验证
function buildRules(conf, ctx) {
  if (!trigger[conf.tag]) return;
  const rules = [];
  // if (conf.style.display != undefined) {
  //   if (conf.required && conf.style.display == 'block') {
  //       const type = Array.isArray(conf.defaultValue) ? "array" : undefined;
  //       let message = Array.isArray(conf.defaultValue)
  //         ? "请至少选择一个"
  //         : conf.placeholder;
  //       if (message === undefined) message = `${conf.label}不能为空`;
  //       conf.tag === "fc-org-select"
  //         ? rules.push(setFcOrgSelectRule(conf, ctx))
  //         : rules.push({
  //             required: true,
  //             type,
  //             message,
  //             trigger: trigger[conf.tag],
  //           });
  //     } else {
  //       return;
  //     }
  // } else {
    if (ctx.requiredArr.includes(conf.formId)) {
      return;
    }  
    if (conf.required) {
         const type = Array.isArray(conf.defaultValue) ? "array" : undefined;
         let message = Array.isArray(conf.defaultValue)
           ? "请至少选择一个"
           : conf.placeholder;
         if (message === undefined) message = `${conf.label}不能为空`;
         conf.tag === "fc-org-select"
           ? rules.push(setFcOrgSelectRule(conf, ctx))
           : rules.push({
               required: true,
               type,
               message,
               trigger: trigger[conf.tag],
        });
       } else {
         return;
       }
    
  // }
 
  // 自定义正则匹配
  if (conf.regList && Array.isArray(conf.regList)) {
    conf.regList.forEach((item) => {
      if (item.pattern) {
        rules.push({
          pattern: eval(item.pattern),
          message: item.message,
          trigger: trigger[conf.tag],
        });
      }
    });
  }
  ctx.ruleList[conf.vModel] = rules;
}
//设置控件值
const setData = (ctx, val, prop, init = false) => {
  if (!prop) return;
    //  console.log(ctx.formModel, prop, val)
  ctx.$set(ctx.formModel, prop, val);
};
let _isMounted = false; // 收集默认值 渲染完成之后防止重复收集默认值
const buildData = (ctx, value, prop) => setData(ctx, value, prop, true);
const layouts = {
  colFormItem: function (conf, h, ctx, isList = false) {
    buildRules(conf, ctx);
    // !_isMounted && buildData(ctx, conf.defaultValue, conf.vModel)
    let labelWidth = "";
    if (conf.labelWidth) labelWidth = `${conf.labelWidth}px`;
    if (isList) labelWidth = "0px";
    const required =
      (!trigger[conf.tag] && conf.required) || conf.tag === "fc-org-select";
    //控件值变化事件
    const handleInput = (val) => {
      //  console.log(conf, conf.vModel);
      //  console.log(val, conf.vModel);
      //  conf.defaultValue=val
      //ctx.$set(conf, 'defaultValue', val);
        //  console.log(ctx, val, conf.vModel)
      setData(ctx, val, conf.vModel);
      if(conf.tag==='fc-upload'){
        conf.defaultValue = val;
      }
      if (conf.tag === "fc-org-select" || conf.tag === "fc-upload") {
        /**
         * 组织机构组件数据复杂
         * async-validator不一定能准确捕获到数据变化
         * 所以在这里手动校验一次
         */
        ctx.$refs[ctx.confGlobal.formRef].validateField(conf.vModel, () => {});
      }
    };
    const item = (
      <el-col span={conf.span} class="formCol" style="padding:0px;">
        <el-form-item
          label-width={labelWidth}
          label={
            isList
              ? ""
              : conf.tag == "fc-instructions"
              ? ""
              : ["fc_suiteform", "fc-relevancy"].includes(conf.tag)
              ? ""
              : conf.label
          }
          style={conf.style}
          prop={conf.vModel}
          class={[
            conf.required == true ? "is-required" : "",
            ["fc_suiteform", "fc-relevancy"].includes(conf.tag)
              ? "suiteformFormItem"
              :ctx.formData.labelPosition =="top"?"suiteformFormItem":"suiteformFormItem1",
          ]}
        >
          <render
            formData={ctx.formModel}
            conf={conf}
            style={conf.style}
            value={ctx.formModel[conf.vModel]}
            autocompletionFormData={
              conf.tag === "fc-autocompletion" ? ctx.formData.fields : ""
            }
            readonly={ctx.confGlobal_permission}
            ref={
              conf.rowType === "table"
                ? conf.vModel
                : conf.tag == "fc_suiteform"
                ? "refSuiteform"
                :  conf.tag == "fc-relevancy"
                ? "refRelevancy"+conf.formId
                : undefined
            }
            onInput={handleInput}
          />
        </el-form-item>
      </el-col>
    );
    if (isList) {
      const tableTitle = (
        <el-col
          span={24}
          style="font-size: 14px;padding:6px 0px;margin-bottom: 4px;border-bottom: 1px solid #e5e5e5;"
        >
          {conf.label}
        </el-col>
      );
      return [tableTitle, item];
    }
    return item;
  },

  rowFormItem: function (conf, h, ctx) {
    if (conf.rowType === "table") {
      ctx.tableRefs[conf.vModel] = conf;
      const param = {
        ...conf,
        config: conf,
        formSize: ctx.confGlobal.size,
        labelWidth: `${conf.labelWidth || ctx.confGlobal.labelWidth}px`,
      };
      return this.colFormItem(param, h, ctx, conf.type === "list");
    }
    buildRules(conf, ctx);
    const props = {
      type: conf.type === "default" ? undefined : conf.type,
      justify: conf.type === "default" ? undefined : conf.justify,
      align: conf.type === "default" ? undefined : conf.align,
      gutter: typeof conf.gutter === "number" ? conf.gutter : undefined,
    };

    const row = (
      <el-col span={conf.span || 24}>
        <el-row {...{ props: props }} style={conf.style}>
          {Array.isArray(conf.children) &&
            conf.children.map((el) => layouts[el.layout](el, h, ctx))}
        </el-row>
      </el-col>
    );
    let divider;
    if (conf.showDivider) {
      let explain;
      if (conf.cmpType === "custom" && conf.explain) {
        explain = (
          <el-tooltip effect="dark" content="组件说明" placement="top">
            <i
              class="el-icon-info"
              onClick={ctx.showExplain.bind(this, conf.explain, conf.label)}
              style="margin-right: 8px; color: #E6A23C;cursor: pointer;"
            ></i>
          </el-tooltip>
        );
      }
      divider = (
        <el-col span={24} style={conf.style}>
          <el-divider content-position="left">
            {explain} {conf.label}
          </el-divider>
        </el-col>
      );
      return [divider, row];
    }
    return row;
  },
};
export default {
  props: ["id", "form_id","specialDialog", "dialogForm", "oacreateform"],
  data() {
    return {
      submitTimer:null,
      submitNum:30,
      isSubmit:false,
      isUpload:false,
      requiredArr:[],
      //当前节点隐藏控件
      node_disabled: [],
      //下拉选择关联控件id
      selectArr: [],
      //关联表格
      relevancyArr:[],
      //表单控件权限
      selectRequiredArr: null,
      job_id: window.localStorage.getItem("jobid"),
      //表单数据
      formData: null,
      //表单权限
      permission: null,
      //验证规则
      ruleList: {},
      //控件数据
      formModel: {},
      //流程节点数据
      timeline: [],
      //设置审批人上传数据
      approver_data: null,
      //是否设置审批人
      isOptional: null,
      //设置审批人
      optionalObj: null,
      //表单表格ref
      tableRefs: {},
      //抽屉title
      drawerTitle: "",
      //抽屉文字
      drawerText: "",
      //抽屉开关
      drawerVisible: false,
      //抽屉大小
      containerWidth: 100,
      formal: true,
      //必填一项
      rowObj: {},
      //所有文件上传控件 filesObj: {},

      //文件控件id
      filesId: null,
      //控件文件filesArr: [],
    };
  },
  components: {
    /* 审批节点 */
    approvalsnode,
  },
  computed: {
    //表单数据
    confGlobal() {
      return this.formData;
    },
    //表单权限
    confGlobal_permission() {
      return this.permission;
    },
    //表单验证数据
    requiredlist() {
      const arr = [];
      this.confGlobal.fields.forEach((item) => {
        if (item.required) {
          arr.push(item.vModel);
        }
      });
      return arr;
    },
  },
  watch: {
    isSubmit:{
      handler(val){
        if(!val){
        //  console.log("关联控件")
          clearInterval(this.submitTimer)
          this.submitNum=30
        }
      },
      deep:true,
    },
    submitNum:{
      handler(val){
        //  console.log(val)
        if (val < 0 || val === 0) {
         this.isSubmit=false
            setTimeout(() => {
                clearInterval(this.submitTimer)
            }, 500)
        }
      },
    },
    oacreateform: {
      handler(val) {
        if (val != undefined) {
          this.formData = val;
        }
      },
    },
    formData: {
      handler(val) {
        if ((val = !undefined)) {
          if (val.fields) {
            this.initDefaultData(val);
            if (this.id != undefined) {
              this.submitnode();
            }
            _isMounted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    formModel: {
      handler(val) {
        this.onAssociatedControls(this.formData);
        this.formData.fields.forEach((item) => {
          if (item.tag == "el-select") {
            if (val[item.vModel] != undefined) {
              let control = null;
              item.options.forEach((items) => {
                if (val[item.vModel] == items.value) {
                  control = items.data;
                }
              });
              //  console.log(this.selectArr,"关联控件")
              //  console.log(this.node_disabled,"当前节点隐藏控件")
              //  console.log(control, '显示控件');
              this.selecControls(this.selectArr,this.node_disabled,control)
              // this.formData.fields.forEach((item, j) => {
                // if (control != null) {
                //   control.forEach((items) => {
                //     if (item.formId == items) {
                //       item.style = {};
                //       item.style.display = "block";
                //       this.selectRequiredArr.forEach(
                //         (selectRequiredArrItems) => {
                //           if (selectRequiredArrItems.formId == items) {
                //           //  console.log(selectRequiredArrItems);
                //             item.required = selectRequiredArrItems.required;
                //           }
                //         }
                //       );
                //     }
                //   });
                // }
              // });
            }
          }
        });
      },
      deep: true,
    },
    id: {
      handler(val) {
        if (val != undefined) {
          {
            /* this.getFormData() */
          }
        }
      },
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getFormData();
    }
    //OaEventBus.$on('onSpecialOrder', this.specialOrder);
  },
  methods: {
    onIsEmptyAll(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.unit_price,item.quantity,item.special_offer_price]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    onIsEmpty(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.unit_price,item.quantity]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    onIsEmptyQuantity(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.quantity]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    onIsEmptyExpenseCategoryAll(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.unit_price,item.quantity,item.f_charge_classification]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    specialOrder(val){
    //  console.log(val,"6666666666666666")
    },
    //生产领料
    postAddProductionMaterialRequisition(){
      const isValid = (value) => value != null && value !== '';
      let RefAddProductionMaterialRequisition=this.$refs.refSuiteform.$children[0].$refs.AddProductionMaterialRequisition.$refs
      RefAddProductionMaterialRequisition.ordersObjRef1.validate(async (valid) => {
        if(valid){
        //  console.log(valid)
          let obj = this.initiatorrewrite(this.formModel);
          let dialogFromList = JSON.parse(
            obj.content_data[this.formData.fields[0].formId]
          );
          let arr = [];
          let isEmpty = false;
          dialogFromList.products.forEach((item, i) => {
            if (isValid(item.product)) {
              if(isValid(item.quantity)){
                item.quantity = Number(item.quantity);
              }
              if(isValid(item.unit_price)){
                item.unit_price = Number(item.unit_price);
              }
              arr.push(item);
            }
          });
           isEmpty=this.onIsEmptyQuantity(arr)
          if (isEmpty) {
            this.isSubmit=false
            return this.$message.warning("请填写产品数量");
          }
          dialogFromList.products = arr;
          dialogFromList.content_data = obj.content_data;
          let postSampleSubmissionsObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
          postSampleSubmissionsObj.products = arr;
          dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postSampleSubmissionsObj);
          if (dialogFromList.products.length > 0) {
            const res = await this.$http({
              method: "POST",
              url: "erp/picking_list/",
              data: dialogFromList,
            });
            if (res.status === 200) {
              this.isUpload=true
              if (this.specialDialog != undefined) {
                if(this.specialDialog==='independent'){
                  this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                }else{
                  this.$emit("getlist", {arr: [],data: res, });
                }
              } else {
                this.$router.push("submityes");
              }
            } else {
              this.isSubmit=false
              return this.$message.error(res.data);
            }
          } else {
            this.isSubmit=false
            return this.$message.error("请添加产品");
          }
        }else{
          this.isSubmit=false
        }
      })
    },
      //出库申请(亿看)
    postAddOutbounDeliveryOrder() {
      const isValid = (value) => value != null && value !== '';
      let RefAddOutboundOrder = this.$refs.refSuiteform.$children[0].$refs.AddOutboundOrder.$refs;
      RefAddOutboundOrder.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
          if (valid) {
            let obj = this.initiatorrewrite(this.formModel);
            let arr = [];
            let ordersObj = JSON.parse(
              obj.content_data[this.formData.fields[0].formId]
            );
            let isEmpty = false;
            ordersObj.products.forEach((item, i) => {
              if (isValid(item.product_id)) {
                if(isValid(item.quantity)){
                 item.quantity = Number(item.quantity);
                }
                if(isValid(item.unit_price)){
                 item.unit_price = Number(item.unit_price);
                }
                arr.push(item);
              }
            });
            isEmpty=this.onIsEmpty(arr)
            if (isEmpty) {
              this.isSubmit=false
              return this.$message.warning("请填写产品数量或成交单价");
            }
            ordersObj.products = arr;
            if (ordersObj.sales_order_id == null) {
              delete ordersObj.sales_order_id;
            }
            if (ordersObj.products.length > 0) {
              const res = await this.$http({
                url: "crm/sales_outbound_table/ecount_create/",
                method: "POST",
                data: ordersObj,
              });
              //console.log(res);
              if (res.status === 200) {
                this.isUpload=true
                if (this.specialDialog != undefined) {
                  if(this.specialDialog==='independent'){
                    this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                  }else{
                    this.$emit("getlist", {arr: [],data: res, });
                  }
                } else {
                  this.$router.push("submityes");
                }
              } else {
                this.$message.error(res.data);
                this.isSubmit=false
              }
            } else {
              this.isSubmit=false
              return this.$message.error("请添加产品");
            }
          }else{
            this.isSubmit=false
          }
        })
        return
      RefAddOutboundOrder.outboundOrdersObjRef1.validate(async (valid) => {
        if (valid) {
          RefAddOutboundOrder.outboundOrdersObjRef2.validate(async (valid) => {
            if (valid) {
              RefAddOutboundOrder.outboundOrdersObjRef3.validate(
                async (valid) => {
                  if (valid) {
                    let obj = this.initiatorrewrite(this.formModel);
                    let arr = [];
                    //console.log(obj.content_data[this.formData.fields[0].formId])
                    let ordersObj = JSON.parse(
                      obj.content_data[this.formData.fields[0].formId]
                    );
                    ordersObj.products.forEach((item, i) => {
                      if (item.product_id != null) {
                        item.quantity = Number(item.quantity);
                        item.unit_price = Number(item.unit_price);
                        arr.push(item);
                      }
                    });
                    if (arr.length > 0) {
                      ordersObj.products = arr;
                    }
                    if (ordersObj.sales_order_id == null) {
                      delete ordersObj.sales_order_id;
                    }
                    if (ordersObj.products.length > 0) {
                      const res = await this.$http({
                        url: "crm/sales_outbound_table/ecount_create/",
                       
                        method: "POST",
                        data: ordersObj,
                      });
                      //console.log(res);
                      if (res.status === 200) {
                        this.isUpload=true
                        if (this.specialDialog != undefined) {
                          if(this.specialDialog==='independent'){
                            this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                          }else{
                            this.$emit("getlist", {arr: [],data: res, });
                          }
                        } else {
                          this.$router.push("submityes");
                        }
                      } else {
                        this.$message.error(res.data);
                        this.isSubmit=false
                      }
                    } else {
                      this.isSubmit=false
                      return this.$message.error("请添加产品");
                    }
                  }else{
                    this.isSubmit=false
                  }
                }
              );
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
  
    //出库申请
    postAddOutboundOrder() {
      const isValid = (value) => value != null && value !== '';
      let RefAddOutboundOrder =this.$refs.refSuiteform.$children[0].$refs.AddOutboundOrder.$refs;
      RefAddOutboundOrder.outboundOrdersObjRef1.validate(async (valid) => {
        if (valid) {
          RefAddOutboundOrder.outboundOrdersObjRef2.validate(async (valid) => {
            if (valid) {
              RefAddOutboundOrder.outboundOrdersObjRef3.validate(
                async (valid) => {
                  if (valid) {
                    let obj = this.initiatorrewrite(this.formModel);
                    let arr = [];
                    //console.log(obj.content_data[this.formData.fields[0].formId])
                    let ordersObj = JSON.parse(
                      obj.content_data[this.formData.fields[0].formId]
                    );
                    ordersObj.products.forEach((item, i) => {
                      if (isValid(item.product_id)) {
                        if(isValid(item.quantity)){
                          item.quantity = Number(item.quantity);
                        }
                        if(isValid(item.unit_price)){
                          item.unit_price = Number(item.unit_price);
                        }
                        arr.push(item);
                      }
                    });
                    if (arr.length > 0) {
                      ordersObj.products = arr;
                    }
                    if (ordersObj.sales_order_id == null) {
                      delete ordersObj.sales_order_id;
                    }
                    if (ordersObj.products.length > 0) {
                      const res = await this.$http({
                        url: "crm/sales_outbound_table/",
                        method: "POST",
                        data: ordersObj,
                      });
                      //console.log(res);
                      if (res.status === 200) {
                        this.isUpload=true
                        if (this.specialDialog != undefined) {
                          if(this.specialDialog==='independent'){
                            this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                          }else{
                            this.$emit("getlist", {arr: [],data: res, });
                          }
                        } else {
                          this.$router.push("submityes");
                        }
                      } else {
                        this.$message.error(res.data);
                        this.isSubmit=false
                      }
                    } else {
                      return this.$message.error("请添加产品");
                    }
                  }else{
                    this.isSubmit=false
                  }
                }
              );
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
    //送样申请
    postSampleSubmissions() {
      const isValid = (value) => value != null && value !== '';
      let RefAddQuotation =this.$refs.refSuiteform.$children[0].$refs.SampleSubmissions.$refs
      RefAddQuotation.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
        if (valid) {
              this.$refs[this.confGlobal.formRef].validate(async (valid) => {
                if (valid){
                  let obj = this.initiatorrewrite(this.formModel);
                  let dialogFromList = JSON.parse(
                    obj.content_data[this.formData.fields[0].formId]
                  );
                  let arr = [];
                  let isEmpty = false;
                  dialogFromList.products.forEach((item, i) => {
                    if (isValid(item.product_id)) {
                     if(isValid(item.quantity)){
                        item.quantity = Number(item.quantity);
                      }
                      if(isValid(item.unit_price)){
                        item.unit_price = Number(item.unit_price);
                      }
                      arr.push(item);
                    }
                  });
                  isEmpty=this.onIsEmptyAll(arr)
                  if (isEmpty) {
                    this.isSubmit=false
                    return this.$message.warning("请填写产品数量或参考单价");
                  }
                  dialogFromList.products = arr;
                  dialogFromList.content_data = obj.content_data;
                  let postSampleSubmissionsObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
                  postSampleSubmissionsObj.products = arr;
                  dialogFromList.content_data[this.formData.fields[0].formId] =JSON.stringify(postSampleSubmissionsObj);
                  // console.log(dialogFromList,"送样");
                  if (dialogFromList.products.length > 0) {
                    const res = await this.$http({
                      method: "POST",
                      url: "crm/order_relationship_table/",
                      data: dialogFromList,
                    });
                    if (res.status === 200) {
                      this.isUpload=true
                      if (this.specialDialog != undefined) {
                        if(this.specialDialog==='independent'){
                          this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                        }else{
                          this.$emit("getlist", {arr: [],data: res, });
                        }
                      } else {
                        this.$router.push("submityes");
                      }
                    } else {
                      this.isSubmit=false
                      this.$message.error(res.data);
                    }
                  } else {
                    this.isSubmit=false
                    return this.$message.error("请添加产品");
                  }
                } else{
                  this.isSubmit=false
                }
              });
            }else{
              this.isSubmit=false
            }
      })
      return
      //let RefAddQuotation =this.$refs.refSuiteform.$children[0].$refs.SampleSubmissions.$refs;
      RefAddQuotation.sendSamplesRef.validate(async (valid) => {
        if (valid) {
          RefAddQuotation.sendSamplesRef2.validate(async (valid) => {
            if (valid) {
              this.$refs[this.confGlobal.formRef].validate(async (valid) => {
                if (valid){
                  let obj = this.initiatorrewrite(this.formModel);
                  let dialogFromList = JSON.parse(
                    obj.content_data[this.formData.fields[0].formId]
                  );
                  let arr = [];
                  let isEmpty = false;
                  dialogFromList.products.forEach((item, i) => {
                    if (item.product_id !== "" && item.product_id != null) {
                      arr.push(item);
                      item.quantity = Number(item.quantity);
                      item.unit_price = Number(item.unit_price);
                    }
                  });
                  arr.forEach((item, i) => {
                    if (item.quantity == "" || item.quantity == null||item.special_offer_price=== ""||item.special_offer_price=== null) {
                    //  console.log(item.quantity);
                      isEmpty = true;
                    }
                  });
                  if (isEmpty) {
                    this.isSubmit=false
                    return this.$message.warning("请填写产品数量或参考单价");
                  }
                  dialogFromList.products = arr;
                  dialogFromList.content_data = obj.content_data;
                  let postSampleSubmissionsObj = JSON.parse(
                    dialogFromList.content_data[this.formData.fields[0].formId]
                  );
                  postSampleSubmissionsObj.products = arr;
                  dialogFromList.content_data[this.formData.fields[0].formId] =
                    JSON.stringify(postSampleSubmissionsObj);

                //  console.log(dialogFromList);
                  if (dialogFromList.products.length > 0) {
                    const res = await this.$http({
                      method: "POST",
                      url: "crm/order_relationship_table/",
                      data: dialogFromList,
                    });
                    if (res.status === 200) {
                      this.isUpload=true
                      if (this.specialDialog != undefined) {
                        if(this.specialDialog==='independent'){
                          this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                        }else{
                          this.$emit("getlist", {arr: [],data: res, });
                        }
                      } else {
                        this.$router.push("submityes");
                      }
                    } else {
                      this.isSubmit=false
                      this.$message.error(res.data);
                    }
                  } else {
                    this.isSubmit=false
                    return this.$message.error("请添加产品");
                  }
                } else{
                  this.isSubmit=false
                }
              });
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
    //报价申请
    postAddQuotation() {
      const isValid = (value) => value != null && value !== '';
      let RefAddQuotation = this.$refs.refSuiteform.$children[0].$refs.AddQuotation.$refs;
      RefAddQuotation.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
        if (valid) {
          let obj = this.initiatorrewrite(this.formModel);
          let dialogFromList =JSON.parse(obj.content_data[this.formData.fields[0].formId]);
          let arr = [];
          let isEmpty = false;
          dialogFromList.products.forEach((item, i) => {
            if (isValid(item.product_id)) {
              if(isValid(item.quantity)){
                item.quantity = Number(item.quantity);
              }
              if(isValid(item.unit_price)){
                item.unit_price = Number(item.unit_price);
              }
              arr.push(item);
            }
          });
          isEmpty=this.onIsEmptyAll(arr)
          if (isEmpty) {
            this.isSubmit=false
            return this.$message.warning("请填写产品数量或参考单价");
          }
          dialogFromList.products = arr;
          dialogFromList.content_data = obj.content_data;
        //  console.log(dialogFromList,"报价")
          if (dialogFromList.products.length > 0) {
            const res = await this.$http({
              method: "POST",
              url: "crm/order_relationship_table/",
              data: dialogFromList,
            });
            if (res.status === 200) {
              this.isUpload=true
              if (this.specialDialog != undefined) {
                if(this.specialDialog==='independent'){
                   this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                 }else{
                   this.$emit("getlist", {arr: [],data: res, });
                 }
              } else {
                this.$router.push("submityes");
              }
            } else {
              this.isSubmit=false
              this.$message.error(res.data);
            }
          } else {
            this.isSubmit=false
            return this.$message.error("请添加产品");
          }
        }else{
          this.isSubmit=false
        }
      })
      return
      RefAddQuotation.quotationRef1.validate(async (valid) => {
        if (valid) {
          RefAddQuotation.quotationRef2.validate(async (valid) => {
            if (valid) {
              RefAddQuotation.quotationRef3.validate(async (valid) => {
                if (valid) {
                  let obj = this.initiatorrewrite(this.formModel);
                  let dialogFromList = JSON.parse(
                    obj.content_data[this.formData.fields[0].formId]
                  );
                  let arr = [];
                  let isEmpty = false;
                  dialogFromList.products.forEach((item, i) => {
                    if (item.product_id !== "" && item.product_id != null) {
                      arr.push(item);
                      item.quantity = Number(item.quantity);
                      item.unit_price = Number(item.unit_price);
                    }
                  });
                  arr.forEach((item, i) => {
                    if (item.quantity == "" || item.quantity == null||item.special_offer_price=== ""||item.special_offer_price=== null) {
                    //  console.log(item.quantity);
                      isEmpty = true;
                    }
                  });
                  if (isEmpty) {
                    this.isSubmit=false
                    return this.$message.warning("请填写产品数量或参考单价");
                  }
                  dialogFromList.products = arr;
                  dialogFromList.content_data = obj.content_data;
                  if (dialogFromList.products.length > 0) {
                    const res = await this.$http({
                      method: "POST",
                      url: "crm/order_relationship_table/",
                      data: dialogFromList,
                    });
                    if (res.status === 200) {
                      this.isUpload=true
                      if (this.specialDialog != undefined) {
                        if(this.specialDialog==='independent'){
                           this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                         }else{
                           this.$emit("getlist", {arr: [],data: res, });
                         }
                      } else {
                        this.$router.push("submityes");
                      }
                    } else {
                      this.isSubmit=false
                      this.$message.error(res.data);
                    }
                  } else {
                    this.isSubmit=false
                    return this.$message.error("请添加产品");
                  }
                }else{
                  this.isSubmit=false
                }
              });
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
    //订单申请
    postAddOrders() {
      const isValid = (value) => value != null && value !== '';
      let RefAddOrders =this.$refs.refSuiteform.$children[0].$refs.AddOrders.$refs;
        RefAddOrders.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid) => {
          if (valid) {
            this.$refs[this.confGlobal.formRef].validate(
              async (valid) => {
                if (valid){
                  let obj = this.initiatorrewrite(this.formModel);
                  let dialogFromList = JSON.parse(
                    obj.content_data[this.formData.fields[0].formId]
                  );
                  let arr = [];
                  let isEmpty = false;
                  dialogFromList.products.forEach((item, i) => {
                    if (isValid(item.product_id)) {
                      if(isValid(item.quantity)){
                      item.quantity = Number(item.quantity);
                      }
                      if(isValid(item.unit_price)){
                      item.unit_price = Number(item.unit_price);
                      }
                      arr.push(item);
                    }
                  });
                  isEmpty=this.onIsEmptyAll(arr)
                  if (isEmpty) {
                    this.isSubmit=false
                    return this.$message.warning("请填写产品数量或参考单价");
                  }
                  dialogFromList.products = arr;
                  dialogFromList.content_data = obj.content_data;
                  let postSampleSubmissionsObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
                  postSampleSubmissionsObj.products = arr;
                  dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postSampleSubmissionsObj);
                  if (dialogFromList.products.length > 0) {
                    const res = await this.$http({
                      method: "POST",
                      url: "crm/order_relationship_table/",
                      data: dialogFromList,
                    });
                    if (res.status === 200) {
                      this.isUpload=true
                      if (this.specialDialog != undefined) {
                        if(this.specialDialog==='independent'){
                          this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                        }else{
                          this.$emit("getlist", {arr: [],data: res, });
                        }
                      } else {
                        this.$router.push("submityes");
                      }
                    } else {
                      this.isSubmit=false
                      return this.$message.error(res.data);
                    }
                  } else {
                    this.isSubmit=false
                    return this.$message.error("请添加产品");
                  }
                }else{
                  this.isSubmit=false
                }
              });
            }else{
              this.isSubmit=false
            }
         })
      return
      RefAddOrders.ordersObjRef1.validate(async (valid) => {
        if (valid) {
          RefAddOrders.ordersObjRef2.validate(async (valid) => {
            if (valid) {
              RefAddOrders.ordersObjRef3.validate(async (valid) => {
                if (valid) {
                  this.$refs[this.confGlobal.formRef].validate(
                    async (valid) => {
                      if (valid){
                        let obj = this.initiatorrewrite(this.formModel);
                        let dialogFromList = JSON.parse(
                          obj.content_data[this.formData.fields[0].formId]
                        );
                        let arr = [];
                        let isEmpty = false;
                        dialogFromList.products.forEach((item, i) => {
                          if (
                            item.product_id !== "" &&
                            item.product_id != null &&
                            item
                          ) {
                            arr.push(item);
                            item.quantity = Number(item.quantity);
                            item.unit_price = Number(item.unit_price);
                          }
                        });
                        arr.forEach((item, i) => {
                          if (item.quantity == "" || item.quantity == null||item.special_offer_price=== ""||item.special_offer_price=== null) {
                          //  console.log(item.quantity);
                            isEmpty = true;
                          }
                        });
                        if (isEmpty) {
                          this.isSubmit=false
                          return this.$message.warning("请填写产品数量或参考单价");
                        }
                        dialogFromList.products = arr;
                        dialogFromList.content_data = obj.content_data;
                        let postSampleSubmissionsObj = JSON.parse(
                          dialogFromList.content_data[
                            this.formData.fields[0].formId
                          ]
                        );
                        postSampleSubmissionsObj.products = arr;
                        dialogFromList.content_data[
                          this.formData.fields[0].formId
                        ] = JSON.stringify(postSampleSubmissionsObj);
                        if (dialogFromList.products.length > 0) {
                          const res = await this.$http({
                            method: "POST",
                            url: "crm/order_relationship_table/",
                            data: dialogFromList,
                          });
                          if (res.status === 200) {
                            this.isUpload=true
                            if (this.specialDialog != undefined) {
                              if(this.specialDialog==='independent'){
                                this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                              }else{
                                this.$emit("getlist", {arr: [],data: res, });
                              }
                            } else {
                              this.$router.push("submityes");
                            }
                          } else {
                            this.isSubmit=false
                            return this.$message.error(res.data);
                          }
                        } else {
                          this.isSubmit=false
                          return this.$message.error("请添加产品");
                        }
                      }else{
                        this.isSubmit=false
                      }
                    }
                  );
                }else{
                  this.isSubmit=false
                }
              });
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
     //长期合同
    postAddLongTermAgreement() {
      const isValid = (value) => value != null && value !== '';
      let RefAddOrders = this.$refs.refSuiteform.$children[0].$refs.AddOrders.$refs;
      RefAddOrders.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
        if (valid) {
            this.$refs[this.confGlobal.formRef].validate(
              async (valid) => {
                if (valid) {
                  let obj = this.initiatorrewrite(this.formModel);
                  let dialogFromList = JSON.parse(
                    obj.content_data[this.formData.fields[0].formId]
                  );
                  let arr = [];
                  let isEmpty = false;
                  dialogFromList.products.forEach((item, i) => {
                    if (isValid(item.product_id)) {
                      if(isValid(item.quantity)){
                        item.quantity = Number(item.quantity);
                      }
                      if(isValid(item.unit_price)){
                        item.unit_price = Number(item.unit_price);
                      }
                      arr.push(item);
                    }
                  });
                  isEmpty=this.onIsEmptyAll(arr)
                  if (isEmpty) {
                    this.isSubmit=false
                    return this.$message.warning("请填写产品数量或参考单价");
                  }
                  dialogFromList.products = arr;
                  dialogFromList.content_data = obj.content_data;
                  let postSampleSubmissionsObj = JSON.parse(
                    dialogFromList.content_data[
                      this.formData.fields[0].formId
                    ]
                  );
                  postSampleSubmissionsObj.products = arr;
                  dialogFromList.content_data[
                    this.formData.fields[0].formId
                  ] = JSON.stringify(postSampleSubmissionsObj);
                //  console.log(dialogFromList,'长期订单')
                  if (dialogFromList.products.length > 0) {
                    const res = await this.$http({
                      method: "POST",
                      url: "crm/order_relationship_table/",
                      data: dialogFromList,
                    });
                    if (res.status === 200) {
                      this.isUpload=true
                      if (this.specialDialog != undefined) {
                        if(this.specialDialog==='independent'){
                          this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                        }else{
                          this.$emit("getlist", {arr: [],data: res, });
                        }
                      } else {
                        this.$router.push("submityes");
                      }
                    } else {
                      this.isSubmit=false
                      return this.$message.error(res.data);
                    }
                  } else {
                    this.isSubmit=false
                    return this.$message.error("请添加产品");
                  }
                }else{
                  this.isSubmit=false
                }
              }
            );
          }else{
            this.isSubmit=false
          }
      })
      return
      RefAddOrders.ordersObjRef1.validate(async (valid) => {
        if (valid) {
          RefAddOrders.ordersObjRef2.validate(async (valid) => {
            if (valid) {
              RefAddOrders.ordersObjRef3.validate(async (valid) => {
                if (valid) {
                  this.$refs[this.confGlobal.formRef].validate(
                    async (valid) => {
                      if (valid) {
                        let obj = this.initiatorrewrite(this.formModel);
                        let dialogFromList = JSON.parse(
                          obj.content_data[this.formData.fields[0].formId]
                        );
                        let arr = [];
                        let isEmpty = false;
                        dialogFromList.products.forEach((item, i) => {
                          if (
                            item.product_id !== "" &&
                            item.product_id != null &&
                            item
                          ) {
                            arr.push(item);
                            item.quantity = Number(item.quantity);
                            item.unit_price = Number(item.unit_price);
                          }
                        });
                        arr.forEach((item, i) => {
                          if (item.quantity == "" || item.quantity == null||item.special_offer_price=== ""||item.special_offer_price=== null) {
                          //  console.log(item.quantity);
                            isEmpty = true;
                          }
                        });
                        if (isEmpty) {
                          this.isSubmit=false
                          return this.$message.warning("请填写产品数量或参考单价");
                        }
                        dialogFromList.products = arr;
                        dialogFromList.content_data = obj.content_data;
                        let postSampleSubmissionsObj = JSON.parse(
                          dialogFromList.content_data[
                            this.formData.fields[0].formId
                          ]
                        );
                        postSampleSubmissionsObj.products = arr;
                        dialogFromList.content_data[
                          this.formData.fields[0].formId
                        ] = JSON.stringify(postSampleSubmissionsObj);
                        if (dialogFromList.products.length > 0) {
                          const res = await this.$http({
                            method: "POST",
                            url: "crm/order_relationship_table/",
                            data: dialogFromList,
                          });
                          if (res.status === 200) {
                            this.isUpload=true
                            if (this.specialDialog != undefined) {
                              if(this.specialDialog==='independent'){
                                this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                              }else{
                                this.$emit("getlist", {arr: [],data: res, });
                              }
                            } else {
                              this.$router.push("submityes");
                            }
                          } else {
                            this.isSubmit=false
                            return this.$message.error(res.data);
                          }
                        } else {
                          this.isSubmit=false
                          return this.$message.error("请添加产品");
                        }
                      }else{
                        this.isSubmit=false
                      }
                    }
                  );
                }else{
                  this.isSubmit=false
                }
              });
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
    //售后申请
    postUndOrder() {
      const isValid = (value) => value != null && value !== '';
      let RefundOrder =this.$refs.refSuiteform.$children[0].$refs.RefundOrder.$refs;
      RefundOrder.refundOrderRef1.validate(async (valid) => {
        if (valid) {
          RefundOrder.refundOrderRef2.validate(async (valid) => {
            if (valid) {
              let obj = this.initiatorrewrite(this.formModel);
              let dialogFromList = JSON.parse(
                obj.content_data[this.formData.fields[0].formId]
              );
              let arr = [];
              let isEmpty = false;
              dialogFromList.products.forEach((item, i) => {
                if (isValid(item.product_id)) {
                  if(isValid(item.quantity)){
                    item.quantity = Number(item.quantity);
                  }
                  arr.push(item);
                }
              });
              isEmpty=this.onIsEmptyQuantity(arr)
              if (isEmpty) {
                this.isSubmit=false
                return this.$message.warning("请填写产品数量");
              }
              dialogFromList.products = arr;
              if (dialogFromList.products.length > 0) {
                const res = await this.$http({
                  url: "crm/order_relationship_table/",
                  method: "POST",
                  data: dialogFromList,
                });

                if (res.status === 200) {
                  this.isUpload=true
                  if (this.specialDialog != undefined) {
                    if(this.specialDialog==='independent'){
                      this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                    }else{
                      this.$emit("getlist", {arr: [],data: res, });
                    }
                  } else {
                    this.$router.push("submityes");
                  }
                } else {
                  this.isSubmit=false
                  this.$message.error(res.data);
                }
              } else {
                this.isSubmit=false
                return this.$message.error("请添加产品");
              }
            }else{
              this.isSubmit=false
            }
          });
        }else{
          this.isSubmit=false
        }
      });
    },
    //回款申请
    postBackMoney() {
      let BackMoney =this.$refs.refSuiteform.$children[0].$refs.RefBackMoney.$refs;
      BackMoney.backMoneyObjRef1.validate(async (valid) => {
        if (valid) {
          let obj = this.initiatorrewrite(this.formModel);
          let dialogFromList = JSON.parse(
            obj.content_data[this.formData.fields[0].formId]
          );
          if (dialogFromList.order == null ||dialogFromList.order == undefined) {
            this.isSubmit=false
            return this.$message.error("请添加销售订单");
          }
          dialogFromList.content_data = obj.content_data;
          let postSampleSubmissionsObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
          dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postSampleSubmissionsObj);
          const formdata = new FormData();
          for (let item in dialogFromList) {
            if (item == "order_file") {
            } else {
            //  console.log(item, dialogFromList[item])
              formdata.append(item, dialogFromList[item]);
            }
          }
          BackMoney.upload.uploadFiles.forEach((items) => {
            formdata.append("order_file", items.raw);
          });
          let res = await this.$http({
            url: "crm/return_money/",
            method: "POST",
            headers: {
              "Content-Type":
                "multipart/form-data; boundary=--------------------------051005589955791443255030",
            },
            data: formdata,
          });
          if (res.status == 200) {
            this.isUpload=true
            if (this.specialDialog != undefined) {
              if(this.specialDialog==='independent'){
                this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
              }else{
                this.$emit("getlist", {arr: [],data: res, });
              }
            } else {
              this.$router.push("submityes");
            }
          } else {
            this.isSubmit=false
            this.$message.error(res.data);
          }
        }else{
           this.isSubmit=false
        }
      });
    },
    //采购入库申请
    postStoragePurchasing(){
      const isValid = (value) => value != null && value !== '';
      let refPurchasing =this.$refs.refSuiteform.$children[0].$refs.AddPurchasing.$refs
      //  console.log(refPurchasing);
      refPurchasing.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid) =>{
        if (valid) {
            let obj = this.initiatorrewrite(this.formModel);
            let dialogFromList = JSON.parse(obj.content_data[this.formData.fields[0].formId]);
            let arr = [];
            let isEmpty = false;
            dialogFromList.details.forEach((item, i) => {
              if (isValid(item.product)) {
                if(isValid(item.quantity)){
                  item.quantity = Number(item.quantity);
                }
                if(isValid(item.unit_price)){
                  item.unit_price = Number(item.unit_price);
                }
                arr.push(item);
              }
            });
            isEmpty=this.onIsEmptyQuantity(arr)
            if (isEmpty) {
              this.isSubmit=false
               return this.$message.warning("请填写产品数量");
             }
              dialogFromList.details = arr;  
              dialogFromList.content_data = obj.content_data;
              let postSampleSubmissionsObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
              postSampleSubmissionsObj.details = arr;
              dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postSampleSubmissionsObj);
              if (dialogFromList.details.length > 0){
                const res = await this.$http({
                    method: "POST",
                    url: "erp/put_storage/",
                    data: dialogFromList,
                  });
                  if (res.status === 200) {
                   this.isUpload=true
                   if (this.specialDialog != undefined) {
                    if(this.specialDialog==='independent'){
                      this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                    }else{
                      this.$emit("getlist", {arr: [],data: res, });
                    }
                   } else {
                     this.$router.push("submityes");
                   }
                 } else {
                  this.isSubmit=false
                   return this.$message.error(res.data);
                 }
              } else {
                this.isSubmit=false
                return this.$message.error("请添加产品");
              }
        }else{
          this.isSubmit=false
        }
      })
    },
    onIsEmptyDemandApplication(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.p_charge_classification,item.p_charge_code,item.quantity]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    //零星申请
    postDemandApplication(){
      const isValid = (value) => value != null && value !== '';
      let demandApplicationRef =this.$refs.refSuiteform.$children[0].$refs.AddDemandApplication.$refs
      demandApplicationRef.demandApplicationRef1.validate(async (valid)=>{
        if(valid){
          let obj = this.initiatorrewrite(this.formModel);
            let dialogFromList = JSON.parse(obj.content_data[this.formData.fields[0].formId]);
            let arr = [];
            let isEmpty = false;
            dialogFromList.details.forEach((item,i)=>{
              if(isValid(item.product)){
                if(isValid(item.quantity)){
                  item.quantity = Number(item.quantity);
                }
                if(isValid(item.unit_price)){
                  item.unit_price = Number(item.unit_price);
                }
                arr.push(item)
              }
            })
            // arr.forEach((item, i) => {
            //   if (item.p_charge_classification==null||item.p_charge_code==null||item.quantity==null||item.product==null|| item.p_charge_classification==''||item.p_charge_code==''||item.quantity==''||item.product=='') {
            //   //  console.log(item.quantity);
            //     isEmpty = true;
            //   }
            // });
            isEmpty=this.onIsEmptyDemandApplication(arr)
            if (isEmpty) {
              this.isSubmit=false
               return this.$message.warning("请填完整添加明细");
             }
             dialogFromList.details = arr;  
             dialogFromList.content_data = obj.content_data;
             let postDemandApplicationObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
             postDemandApplicationObj.details = arr;
             dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postDemandApplicationObj);
             //console.log(dialogFromList)
             //return
             if (dialogFromList.details.length > 0){
                const res=await this.$http({
                  method: "POST",
                  url: "erp/purchase_oder_relationship/",
                  data: dialogFromList,
                })
                if (res.status === 200) {
                  this.isUpload=true
                   if (this.specialDialog != undefined) {
                    if(this.specialDialog==='independent'){
                      this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                    }else{
                      this.$emit("getlist", {arr: [],data: res, });
                    }
                   } else {
                     this.$router.push("submityes");
                   }
                }else{
                  this.isSubmit=false;
                  return this.$message.error(res.data);
                }
             }else{
                this.isSubmit=false
                return this.$message.error("请添加明细");
             }
        }
      })
    },
    //采购需求申请/采购计划申请
    postPurchasingRequisition(){
      const isValid = (value) => value != null && value !== '';
      let purchasingRequisitionRef =this.$refs.refSuiteform.$children[0].$refs.AddPurchasingRequisition.$children[0].$refs
      purchasingRequisitionRef.purchasingRequisitionRef1.validate(async (valid)=>{
        if(valid){
          let obj = this.initiatorrewrite(this.formModel);
          let dialogFromList = JSON.parse(obj.content_data[this.formData.fields[0].formId]);
          let arr = []
          let isEmpty = false;
          dialogFromList.details.forEach((item,i)=>{
              if(isValid(item.product)){
                if(isValid(item.quantity)){
                  item.quantity = Number(item.quantity);
                }
                if(isValid(item.unit_price)){
                  item.unit_price = Number(item.unit_price);
                }
                arr.push(item)
              }
            })
           isEmpty=this.onIsEmptyExpenseCategoryAll(arr)
          //  console.log(isEmpty);
          if (isEmpty) {
             this.isSubmit=false
              return this.$message.warning("请填写产品费用类别或数量");
            }
          dialogFromList.details = arr; 
          dialogFromList.content_data = obj.content_data;
          let postDemandApplicationObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
          postDemandApplicationObj.details = arr;
          dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postDemandApplicationObj);
          if (dialogFromList.details.length > 0){
            const res=await this.$http({
                  method: "POST",
                  url: "erp/purchase_oder_relationship/",
                  data: dialogFromList,
                })
             if (res.status === 200) {
               this.isUpload=true
                if (this.specialDialog != undefined) {
                  if(this.specialDialog==='independent'){
                    this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                  }else{
                  this.$emit("getlist", {arr: [],data: res, });
                  }
                } else {
                  this.$router.push("submityes");
                }
             }else{
               this.isSubmit=false;
               return this.$message.error(res.data);
             }
          }else{
            this.isSubmit=false
            return this.$message.error("请添加明细");
          }
        }
      })
    },
    //采购订单
    postPurchasingOrders(){
      const isValid = (value) => value != null && value !== '';
      let purchasingRequisitionRef =this.$refs.refSuiteform.$children[0].$refs.AddPurchasingRequisition.$children[0].$refs
      purchasingRequisitionRef.purchasingRef1.validate(async(valid)=>{
        if(valid){
          let obj = this.initiatorrewrite(this.formModel);
          let dialogFromList = JSON.parse(obj.content_data[this.formData.fields[0].formId]);
          let arr = []
          let isEmpty = false;
          dialogFromList.details.forEach((item,i)=>{
              if(isValid(item.product)){
                if(isValid(item.quantity)){
                   item.quantity = Number(item.quantity);
                 }
                 if(isValid(item.unit_price)){
                   item.unit_price = Number(item.unit_price);
                 }
                arr.push(item)
              }
            })
          isEmpty=this.onIsEmptyExpenseCategoryAll(arr)
          if (isEmpty) {
           this.isSubmit=false
            return this.$message.warning("请填写产品费用类别或数量、单价");
          }
          dialogFromList.details = arr; 
          dialogFromList.content_data = obj.content_data;
          let postDemandApplicationObj = JSON.parse(dialogFromList.content_data[this.formData.fields[0].formId]);
          postDemandApplicationObj.details = arr;
          dialogFromList.content_data[this.formData.fields[0].formId] = JSON.stringify(postDemandApplicationObj);
          if (dialogFromList.details.length > 0){
            const res=await this.$http({
                  method: "POST",
                  url: "erp/purchase_oder_relationship/",
                  data: dialogFromList,
                })
             if (res.status === 200) {
               this.isUpload=true
                if (this.specialDialog != undefined) {
                  if(this.specialDialog==='independent'){
                    this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                  }else{
                  this.$emit("getlist", {arr: [],data: res, });
                  }
                } else {
                  this.$router.push("submityes");
                }
             }else{
               this.isSubmit=false;
               return this.$message.error(res.data);
             }
          }else{
             this.isSubmit=false
             return this.$message.error("请添加明细");
          }
        }
      })
    },
    //设置审批人
    onOptional(val) {
    //  console.log(val);
      let id = Object.keys(val)[0];
      this.optionalObj[id] = val[id];
    //  console.log(this.optionalObj);
    },
    //删除文件
    async delefiles() {
      const files = await this.$http({
        method: "DELETE",
        url: "oa/oa_form_files_bulk_del/",
        data: arr,
      });
      if (files.status != 204) {
        this.$message.error(res.data);
      }
    },
    //提交表单
    async submitForm() {
      this.isSubmit=true
      this.submitTimer=setInterval(() => {
            this.submitNum--
        }, 1000)
      if (
        JSON.stringify(this.optionalObj) !== "{}" &&
        this.optionalObj != null
      ) {
        let approver_data = [];
        for (const key in this.optionalObj) {
        //  console.log(this.optionalObj[key]);
          if (this.optionalObj[key].length > 0) {
            this.optionalObj[key].forEach((items) => {
              let obj = {};
              obj.flow = this.id * 1;
              obj.node = key * 1;
              obj.approver_job_id = items.job_id;
              approver_data.push(obj);
            });
          }
        }
        let arr = Object.keys(this.optionalObj).filter(
          (key) => this.optionalObj[key].length === 0
        );
        if (arr.length > 0) {
          this.isOptional = false;
        } else {
          this.isOptional = true;
        }
        this.approver_data = approver_data;
      } else {
        this.isOptional = null;
      }
      if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "出库申请") {
        this.postAddOutboundOrder();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "出库申请(亿看)") {
        this.postAddOutbounDeliveryOrder();
      } else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "送样申请") {
        this.postSampleSubmissions();
      } else if ( this.formData.fields[0].tag == "fc_suiteform" && this.formData.fields[0].source == "报价申请") {
        this.postAddQuotation();
      } else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "订单申请") {
        this.postAddOrders();
      }else if (this.formData.fields[0].tag == "fc_suiteform" && this.formData.fields[0].source == "生产领料") {
        this.postAddProductionMaterialRequisition();
      } else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "长期合同") {
        this.postAddLongTermAgreement();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "售后申请") {
        this.postUndOrder();
      } else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "回款申请") {
        this.postBackMoney();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "采购入库申请") {
        this.postStoragePurchasing();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "零星申请") {
        this.postDemandApplication();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "采购需求申请") {
        this.postPurchasingRequisition();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "采购计划") {
        this.postPurchasingRequisition();
      }else if (this.formData.fields[0].tag == "fc_suiteform" &&this.formData.fields[0].source == "采购订单") {
        this.postPurchasingOrders();
      } else {
        let isForm = true;
        if (this.formData.fields[0].source == "客户申请") {
          this.$refs.refSuiteform.$children[0].$refs.AddAssignmentRequest.$refs.assignmentRequestFormRef.validate(
            async (valid) => {
              isForm = valid;
            }
          );
        }
        if (this.formData.fields[0].source == "供应商分配申请") {
          this.$refs.refSuiteform.$children[0].$refs.AddSupplierAssignment.$refs.assignmentRequestFormRef.validate(
            async (valid) => {
              isForm = valid;
            }
          );
        }
        if (!isForm) {
          return;
        }
        const isTableValid = this.checkTableData();
        this.$refs[this.confGlobal.formRef].validate(async (valid, i) => {
          if (this.relevancyArr.length > 0) {
            const RelevancyArr=[]
            this.relevancyArr.forEach(item => {
                const RelevancyObj ={}
                RelevancyObj['refRelevancy'] = this.$refs[('refRelevancy' + item)].$children[0].$refs;
                RelevancyObj['refRelevancyArr']=Object.keys(this.$refs[('refRelevancy'+item)].$children[0].$refs)
                RelevancyArr.push(RelevancyObj)
              })
            let validArr = []
             for (const data of RelevancyArr) {
                await Promise.all(data.refRelevancyArr.map(item => {
                  return new Promise((resolve, reject) => {
                  //  console.log(data.refRelevancy[item])
                    data.refRelevancy[item][0].validate(async (valid, i) => {
                      validArr.push(valid);
                      resolve();  // 标记当前验证已完成
                    });
                  });
                }));
              }
              if(validArr.includes(false)) return
          }
          if (valid) {
            if (!isTableValid) return;
            //console.log(this.formModel)
            let obj = this.initiatorrewrite(this.formModel);
            //console.log(obj)
            if (this.isOptional === null) {
            } else {
              if (this.isOptional) {
                obj.approver_data = this.approver_data;
              } else {
                this.isSubmit=false
                return this.$message.error("请添加审批人");
              }
            }
            try {
              this.formData.fields.forEach((item, i) => {
                let title = "";
                if (item.layout == "rowFormItem" && item.showRequired) {
                  if (item.showDivider) {
                    item.children.forEach((items, j) => {
                      title = title + items.label + "或";
                    });
                    //console.log(this.rowObj[item.formId]);
                    if (this.rowObj[item.formId].includes(true)) {
                    } else {
                      this.isSubmit=false
                      throw this.$message.error("请输入" + title);
                    }
                  } else {
                   // console.log("隐藏");
                  }
                }
              });
            } catch (e) {
              return;
            }
            const res = await this.$http.post("oa/oa_form_add/", obj);
            if (res.status !== 200) {
              this.delefiles();
              this.$message.error(res.data);
              this.isSubmit=false
            } else {
              this.isUpload=true
              if (this.specialDialog != undefined) {
                if(this.specialDialog==='independent'){
                  this.$router.push({ path: "submityes", query: { specialDialog: this.specialDialog } })
                }else{
                this.$emit("getlist", {arr: [],data: res, });
                }
              } else {
                this.$router.push("submityes");
              }
            }
         }else{
           this.isSubmit=false
         }
        });
      }
    },
    //获取表单数据
    async getFormData() {
      const isValid = (value) => value != null && value !== '';
      let content_data=null;
      if(isValid(this.form_id)){
        const format=await this.$http({
          method:"GET",
          url:"oa/oa_flow_format/",
          params:{flow_id:this.id,form_id:this.form_id},
        })
        if(format.status==200){
          content_data=format.data
        }
      }
      const res = await this.$http({
        method: "GET",
        url: "oa/oa_flow_format/",
        params: {
          flow_id: this.id,
        },
      });
      if (res.status == 200) {
        this.node_disabled=[]
        this.drawerText = res.data.form_title;
        this.permission = res.data.permission_data;
        this.permission.forEach(item => {
          if (item.formOperate==0) {
            this.node_disabled.push(item.formId)
          }
        })
        this.selectRequiredArr = JSON.parse(
          JSON.stringify(res.data.layout_data.formData.fields)
        );
        this.formData = res.data.layout_data.formData;
        this.is_special_process = res.data.is_special_process;
        this.rowObj = {};
        this.relevancyArr=[]
        this.formData.fields.forEach((item) => {
          if (item.tag === "fc-upload") {
            item.uploadFormId = {
              flow_id: this.id,
            };
          }
          if (item.tag == 'fc-relevancy') {
            this.relevancyArr.push(item.formId)
          }
          if (item.layout == "rowFormItem") {
            item.children.forEach((items) => {
              if (items.tag === "fc-upload") {
                items.uploadFormId = {
                  flow_id: this.id,
                };
              }
            });
          }
          if (item.showDivider != undefined && item.showRequired) {
            this.rowObj[item.formId] = [];
            item.children.forEach((items) => {
              this.rowObj[item.formId].push(false);
            });
          }
        });
        this.formData.fields.forEach((item, i) => {
          this.permission.forEach((items, j) => {
            if (item.layout == "rowFormItem") {
              if (item.tag == "fc-input-table") {
                item.children.forEach((childrenItem, x) => {
                  if (childrenItem.formId == items.formId) {
                    if (items.formOperate==1) {
                      item.children[x].readonly = true;
                      item.children[x].disabled = true;
                    } else if (items.formOperate==2) {
                      item.children[x].readonly = false;
                      item.children[x].disabled = false;
                    } else {
                      item.children[x].readonly = true;
                      item.children[x].disabled = true;
                      item.children[x].required = false;
                    }
                  }
                });
              } else {
                if (item.formId == items.formId) {
                  if (items.formOperate==0) {
                    item.style.display = "none";
                    item.showDivider = false;
                  } else {
                    item.showDivider = true;
                  }
                }
                item.children.forEach((childrenItem, x) => {
                  if (childrenItem.formId == items.formId) {
                    if (items.formOperate==1) {
                      item.children[x].readonly = true;
                      item.children[x].disabled = true;
                    } else if (items.formOperate==2) {
                      item.children[x].readonly = false;
                      item.children[x].disabled = false;
                      item.children[x].changeTag = true;
                    } else {
                      item.children[x].style.display = "none";
                      item.children[x].required = false;
                    }
                  }
                });
              }
            } else if (item.tag == "fc-relevancy") {
              if (item.formId == items.formId) {
                item.requiredObj = JSON.parse(JSON.stringify(items.formOperate))
              }
            } else {
              if (item.formId == items.formId) {
                if (items.formOperate==1) {
                  item.readonly = true;
                  item.disabled = true;
                } else if (items.formOperate==2) {
                  item.readonly = false;
                  item.disabled = false;
                } else {
                  item.style.display = "none";
                  item.required = false;
                }
              }
            }
          });
        });
        if (this.dialogForm != undefined) {
          this.formData.fields.forEach((item) => {
            if (item.tag == "fc_suiteform") {
              item.dialogForm = this.dialogForm;
            } else if (item.tag == "fc-autocompletion") {
              if (item.label == "客户名称") {
                if (this.dialogForm.customer != undefined) {
                  item.defaultValue = {
                    value: this.dialogForm.customer.id,
                    label: this.dialogForm.customer.company_name,
                  };
                }
              } else if (item.label == "客户") {
                if (this.dialogForm.customer != undefined) {
                  item.defaultValue = {
                    value: this.dialogForm.customer.id,
                    label: this.dialogForm.customer.company_name,
                  };
                }
              } else if (item.label == "联系人") {
                if (this.dialogForm.salesman != undefined) {
                  item.defaultValue = {
                    value: this.dialogForm.salesman.value,
                    label: this.dialogForm.salesman.label,
                  };
                }
              } else if (item.label == "业务员") {
                if (this.dialogForm.salesman != undefined) {
                  item.defaultValue = {
                    value: this.dialogForm.salesman.value,
                    label: this.dialogForm.salesman.label,
                  };
                }
              } else if (item.label == "跟进人") {
                if (this.dialogForm.salesman != undefined) {
                  item.defaultValue = {
                    value: this.dialogForm.salesman.value,
                    label: this.dialogForm.salesman.label,
                  };
                }
              } else if (item.label == "客户联系人") {
                if (this.dialogForm.contacts != undefined) {
                  item.defaultValue = {
                    value: this.dialogForm.contacts.value,
                    label: this.dialogForm.contacts.label,
                  };
                }
              }
            } else if (item.tag == "fc-relevancy") {
              if (item.isRelevancyTable) {
              } else {
                let arr = []
                let objectKey=null
                item.controlChildren.forEach(items => {
                  if (items.type == "object") {
                    objectKey=items.vModel
                  }
                  arr.push(items.vModel)
                })
                item.defaultValue = []
                let obj={}
                arr.forEach(items => {
                   obj[items] = this.dialogForm.data[items]
                })
                obj.id = this.dialogForm.data.id
                let obj1={}
                arr.forEach(items => {
                  if (items === objectKey) {
                    obj1[objectKey]=obj
                  } else {
                    obj1[items]= this.dialogForm.data[items]
                  }
                })
                item.defaultValue.push(obj1)
              }
            }
          });
        }
        this.onAssociatedControls(this.formData);
        this.selecControls(this.selectArr,this.node_disabled,[])
        this.initDefaultData(this.formData);
        this.submitnode();
        if(isValid(content_data)){
          console.log("再次提交");
          this.formData.fields.forEach(item=>{
            if(item.style.display!=='none'){
              console.log(item.formId)
              content_data.control_content_data.forEach(items=>{
                if(items.formId==item.formId){
                  item.defaultValue=items.value
                }
              })
            }
          })
        }
      } else {
        this.$message.error(res.data);
        this.isSubmit=false
      }
    },
    //获取流程节点队列
    async submitnode() {
      try {
        this.requiredlist.forEach((item, i) => {
          if (!this.formModel[item]) {
            if (this.formData.fields[i].required == false) {
            } else {
              throw new Error();
            }
          }
        });
      } catch (error) {
        return;
      }
      const data = this.initiatorrewrite(this.formModel);
      const res =
        this.formal && (await this.$http.post("oa/oa_flow_node_queue/", data));
      this.formal && (this.timeline = res.data);
      let obj = {};
      this.timeline.forEach((item) => {
        if (!Array.isArray(item.approver)) {
          if (item.approver == "optional") {
            this.isOptional = false;
            obj[item.id] = [];
          }
        }
      });
      this.optionalObj = obj;
    },
    //重置表单数据
    resetForm() {
    //  console.log(this.$refs,"重置")
      this.$refs[this.confGlobal.formRef].resetFields();
      this.resetTableData();
    },
    //重置表格数据
    resetTableData() {
      Object.keys(this.tableRefs).forEach((vModel) => {
        // console.log(vModel);
        // 由于render.js返回的动态组件 所以动态组件相当于是render的子组件
        const res = this.$refs[vModel].$children[0].reset();
        // console.log(res);
      });
    },
    checkTableData() {
      let valid = true;
      Object.keys(this.tableRefs).forEach((vModel) => {
      //  console.log(this.$refs[vModel]);
        const res = this.$refs[vModel].$children[0].submit(); // 返回false或表单数据
        res ? (this.formModel[vModel] = res) : (valid = false);
      });
      return valid;
    },
    //抽屉数据
    showExplain(explainText, title) {
      if (!explainText) return;
      this.drawerTitle = title;
      this.drawerText = explainText;
      this.drawerVisible = true;
    },
    //抽屉
    buildDrawer(h) {
      const content = <pre style="">{this.drawerText}</pre>;
      return h(
        "el-drawer",
        {
          props: {
            title: this.drawerTitle + "说明",
            visible: this.drawerVisible,
          },
          on: {
            "update:visible": (val) => (this.drawerVisible = val),
          },
        },
        [content]
      );
    },
    //节流
    throttle(fun, time) {
      let initialtime = 0;
      return function () {
        const currenttime = new Date();
        if (currenttime - initialtime > time) {
          fun.apply(this, arguments);
          initialtime = currenttime;
        }
      };
    },
    //渲染表单
    buildForm(h) {
      if (this.confGlobal.fields == undefined) {
        return;
      }
      const labelPosition = this.confGlobal.labelPosition || "left";
      const content = this.confGlobal.fields.map((c) => {
        return layouts[c.layout](c, h, this);
      });

      const formObject = {
        props: {
          model: this.formModel,
          rules: this.ruleList,
          size: this.confGlobal.size,
          labelWidth: this.confGlobal.labelWidth + "px",
          labelPosition: this.confGlobal.labelPosition || undefined,
        },
        on: {
          validate: this.throttle(this.submitnode, 1500),
        },
        ref: this.confGlobal.formRef,
      };
      const btns = (
        <el-col span={24}>
          <el-form-item style="text-align:right;">
            {/* <el-button type="primary" onClick={this.saveDraft}>
              保存草稿
            </el-button> */}
            <el-button
              type="primary" disabled={this.isSubmit}
              onClick={this.throttle(this.submitForm, 1000)}
            >
            <span v-show={this.isSubmit}>{this.submitNum}秒可再次</span>提交
            </el-button>
            <el-button onClick={this.resetForm}>重置</el-button>
          </el-form-item>
        </el-col>
      );
      // 因为使用jsx时  el-form 的 model 一直无法正确填充，故采用createElement直接渲染
      return h("el-form", formObject, [content, btns]);
    },
    //控件默认值
    initDefaultData(config) {
      config.fields.forEach((field) => {
        if (field.showDivider != undefined) {
          field.children.forEach((item) => {
            this.$set(this.formModel, item.vModel, item.defaultValue);
          });
        } else {
          if (field.tag == "el-select") {
            for (let i = 0; i < field.options.length; i++) {
              if (field.options[i].label == field.defaultValue) {
                field.defaultValue = field.options[i].value;
              }
            }
          }
          this.$set(this.formModel, field.vModel, field.defaultValue);
        }
      });
    },

    //提交表单数据
    initiatorrewrite(dt) {
      const data = { ...dt };
      Object.keys(data).forEach((item, key, i) => {
        for (let j = 0; j < this.formData.fields.length; j++) {
          if (this.formData.fields[j].vModel == item) {
            if (Array.isArray(data[item])) {
              data[this.formData.fields[j].formId] = data[item];
            } else {
              data[this.formData.fields[j].formId] = data[item];
            }
          } else if (this.formData.fields[j].showDivider != undefined) {
            this.formData.fields[j].children.forEach((items, x) => {
              if (items.vModel == item) {
                data[items.formId] = data[items.vModel];
              }
            });
          }
        }
        delete data[item];
      });
      this.formData.fields.forEach((item) => {
        if (item.style != undefined &&item.style.display != undefined &&item.style.display == "none") {
          delete data[item.formId];
        }
      });
      this.formData.fields.forEach((item) => {
        if (item.tag == "fc-upload") {
          for (const key in data) {
            if (item.formId == key) {
              let arr = [];
              if (data[key] != null) {
                data[key].forEach((items) => {
                  arr.push(items.id);
                });
              }
              data[key] = arr;
            }
          }
        }
        if (item.layout == "rowFormItem") {
          item.children.forEach((items, x) => {
            if (items.tag == "fc-upload") {
              for (const key in data) {
                if (items.formId == key) {
                  let arr = [];
                  if (data[key] == null) {
                    data[key] = arr;
                  } else {
                    data[key].forEach((items) => {
                      arr.push(items.id);
                    });
                    data[key] = arr;
                  }
                }
              }
            }
          });
        }
      });
      this.formData.fields.forEach((item) => {
        if (item.showDivider != undefined && item.showRequired) {
          item.children.forEach((items, x) => {
            if (data[items.formId] != undefined &&data[items.formId] != null &&data[items.formId] != "") {
              if (items.tag == "fc-upload") {
                if (data[items.formId].length > 0) {
                  this.rowObj[item.formId][x] = true;
                } else {
                  this.rowObj[item.formId][x] = false;
                }
              } else {
                this.rowObj[item.formId][x] = true;
              }
            } else {
              this.rowObj[item.formId][x] = false;
            }
          });
        }
      });
      //console.log(this.rowObj);
      for (const key in data) {
        if (data[key] === undefined) {
          delete data[key];
        } else if (data[key] === null) {
          data[key] = "";
        }
        if (data[key] === true) {
          data[key] = "True";
        } else if (data[key] === false) {
          data[key] = "False";
        } else {
          if (data[key] instanceof Array) {
            data[key] = JSON.stringify(data[key]);
          } else if (data[key] instanceof Object) {
            data[key] = JSON.stringify(data[key]);
          }
        }
      }
      return {
        content_data: data,
        flow_id: this.id * 1,
        initiator_job_id: this.job_id,
      };
    },
    //下拉框关联控件
    onAssociatedControls(val) {
      let arr = [];
      val.fields.forEach((item) => {
        if (item.tag == "el-select") {
          item.options.forEach((items) => {
            // console.log(items.data)
            arr = arr.concat(items.data);
          });
        }
      });
      arr = Array.from(new Set(arr));
      arr.forEach((item, i) => {
        if (typeof item !== "number") {
          arr.splice(i, 1);
        }
      });
      this.selectArr = arr;
      // console.log(this.selectArr)
      // console.log(arr)
      // val.fields.forEach((item, i) => {
      //   arr.forEach((items) => {
      //     if (item.formId == items) {
      //       item.style.display = "none";
      //     }
      //   });
      // });
    },
    selecControls(selectArr, node_disabled, control) {
      // console.log(selectArr,node_disabled,control)
      this.requiredArr=[]
      const set = new Set(control);
      selectArr = selectArr.filter((item) => !set.has(item))
      //  console.log(selectArr, '关联控件')
      this.formData.fields.forEach((item, j) => {
        //  console.log(selectArr.includes(item.formId))
        //  console.log(node_disabled.includes(item.formId))
        if (selectArr.includes(item.formId) || node_disabled.includes(item.formId)) {
          item.style.display = "none";
          item.required = false;
          this.requiredArr.push(item.formId)
        } else {
          if (item.style == undefined) {
            item.style={}
            item.style.display = "block";
          } else {
              item.style.display = "block";
          }
          this.selectRequiredArr.forEach(selectRequiredArrItems => {
            if (selectRequiredArrItems.formId==item.formId) {
              item.required = selectRequiredArrItems.required
            }
          })
          if(item.readonly){
           this.requiredArr.push(item.formId)
          }
        }
      });
      //  console.log(this.requiredArr,"7777");
    },
    async delefiles1(dt){
      const data = { ...dt };
      Object.keys(data).forEach((item, key, i) => {
        for (let j = 0; j < this.formData.fields.length; j++) {
          if (this.formData.fields[j].vModel == item) {
            if (Array.isArray(data[item])) {
              data[this.formData.fields[j].formId] = data[item];
            } else {
              data[this.formData.fields[j].formId] = data[item];
            }
          } else if (this.formData.fields[j].showDivider != undefined) {
            this.formData.fields[j].children.forEach((items, x) => {
              if (items.vModel == item) {
                data[items.formId] = data[items.vModel];
              }
            });
          }
        }
        delete data[item];
      });
      this.formData.fields.forEach((item) => {
        if (item.style != undefined &&item.style.display != undefined &&item.style.display == "none") {
          delete data[item.formId];
        }
      });
      let arr = [];
      this.formData.fields.forEach((item) => {
        if (item.tag == "fc-upload") {
          for (const key in data) {
            if (item.formId == key) {
              if (data[key] != null) {
                data[key].forEach((items) => {
                  arr.push(items.id);
                  });
              }
            }
          }
        }
        if (item.layout == "rowFormItem") {
          item.children.forEach((items, x) => {
            if (items.tag == "fc-upload") {
              for (const key in data) {
                if (items.formId == key) {
                  if (data[key] != null) {
                    data[key].forEach((items) => {
                      arr.push(items.id);
                    });
                  }
                }
              }
            }
          });
        }
      });
    //  console.log(arr)
      const files = await this.$http({
        method: "DELETE",
        url: "oa/oa_form_files_bulk_del/",
        data: arr,
      });
    //  console.log(files)
      if (files.status != 204) {
        this.$message.error(res.data);
      }
    },
  },
  beforeDestroy() {
  //  console.log(this.isUpload,"销毁中........")
    if(this.isUpload){
    //  console.log(this.isUpload,"提交成功........")
    }else{
      this.delefiles1(this.formModel)
    }
    //OaEventBus.$off('onSpecialOrder', this.specialOrder);
  },
  render(h) {
    if (!this.confGlobal) {
      return <div v-loading="true" class="loading-mask"></div>;
    }
    return (
      <div
        class="preview-container"
        style={"width:" + this.containerWidth + "%;"}
      >
        <el-row gutter={this.confGlobal.gutter} style="margin:0px;">
          {this.buildForm(h)}
        </el-row>
        <approvalsnode
          v-on:onOptional={this.onOptional}
          timeline={this.timeline}
        ></approvalsnode>
        <div class="width-slider">
          <el-slider
            vModel={this.containerWidth}
            min={40}
            max={100}
            style={
              "margin-right:20px;  display:" +
              (this.formal ? "none;" : "block;")
            }
          ></el-slider>
        </div>
        {this.buildDrawer(h)}
      </div>
    );
  },
};
</script>
<style lang="stylus" scoped>
.preview-container {
  border-radius: 6px;
  // box-shadow 0 0 15px rgba(0, 0, 0, .1)
  border: 0px solid black;

  .width-slider {
    width: 150px;
    position: absolute;
    top: 0;
    right: 2rem;
  }
}

.showDivider.form-container {
  margin-bottom: 2rem;
}

.loading-mask {
  width: 100vw;
  height: 100vh;
}
.suiteformFormItem{
 &>>>.el-form-item__content{
  margin-left: 0px !important;
 }
}
.suiteformFormItem1{
 &>>>.el-form-item__content{
  margin-left: 100px !important;
 }
}
.formCol{
  padding-left:0px !important;
}
</style>
