<template>
    <div>
        <SettingForm ref="ProcurementOrders" :refs="'ordersObjRef1'" :isSettingLoading="isSettingLoading" :inline="true"
            :isFormBtn="false" :readonly="readonly" :settingForm="settingForm" @onAppliedForm="onAppliedForm"
            @onSpecials="onSpecials">
            <div slot="shortcut_button" style="margin-bottom: 10px;">
                <el-button type="primary" size="mini" @click="onPurchaseOrder">带出采购订单</el-button>
            </div>
        </SettingForm>
        <inquireDialog v-if="isPurchaseOrder" :isVisible="isPurchaseOrder" :obj="purchaseOrderObj" :title="'采购订单'"
            :isChoose="false" :searchValue="purchaseOrderObj.params.search" @onIsVisible="purchaseOrderVisible"
            @onApplied="purchaseOrderApplied">
        </inquireDialog>
    </div>
</template>
<script>
import { debounce } from "@/utils/AntiShake";
import Preview from "@/components/OA/components/jsxPreview.vue";
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import SettingForm from "@/components/ProcessFlow/CRM/Forms/components/SettingForm1.vue";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue"
import Decimal from 'decimal.js';
export default {
    components: {
        SettingForm, Preview, inquireDialog,
    },
    props: [
        "isAddOrder",
        "addOrderForm",
        "addOrdersCustomer",
        "formTitle",
        "readonly",
        "addOrderId",
    ],
    data() {
        return {
            isSettingLoading: false,
            settingForm: {
                form_root: '采购入库申请1',
                classification: '采购入库申请1',
                control_setting: [{
                    default_value: null,
                    options: [
                        {
                            label: null,
                            value: null,
                        },
                    ],
                    type: '文本输入框',
                    model_class: null,
                    url: null,
                    model_class_field: null,
                    model_class_value: null,
                    is_numeric: false,
                    particulars: [{
                        type: null,
                        url: null,
                        form_data: null,
                        table_data: null,
                        front_label: null,
                        front_value: null,
                        end_label: null,
                        end_value: null,
                        relevance: null,
                        field: null,
                        acquire: null,
                        is_edit: true,
                        is_show: true,
                        label: null,
                        placeholder: null,
                    }],
                    permissions: {
                        edit: true,
                        show: true,
                        update: true,
                        require: false
                    },
                    visible_rule: {
                        role_limit: []
                    },
                    basic_settings: {
                        show_name: "field",
                        field: "field",
                        fields: "field",
                        placeholder: "field"
                    },
                    numeric_settings: {
                        max_digits: null,
                        decimal_places: null
                    },
                }]
            },
            ordersObj: {},
            isPurchaseOrder: false,
            purchaseOrderObj: {
                searchField: 'search',
                url: 'erp/purchase_oder_relationship/',
                params: {
                    per_page: 10,
                    page: 1,
                    order_form_type: 5,
                    is_approve: 1,
                    search: "",
                    customer: null,
                }
            }
        }
    },
    methods: {
        onPurchaseOrder() {
            this.isPurchaseOrder = true
        },
        purchaseOrderVisible(val) {
            this.isPurchaseOrder = val
        },
        async purchaseOrderApplied(val) {
            try {
                let arr = [];
                const promises = val.map(async (item) => { return await this.getPurchaseOrder(item.id) })
                const results = await Promise.all(promises);
                arr = results.filter(item => item !== null);
                let details = []
                arr.forEach(item => {
                    item.details.forEach(items => {
                        delete items.purchase_requirement_id
                        delete items.purchase_requirement_number
                        delete items.purchase_plan_id
                        delete items.purchase_plan_number
                        items.purchase_order = item.order_id
                        items.purchase_order_number = item.purchase_order_number
                        details.push(items);
                    })
                })
                this.ordersObj.details = details
                if (this.$refs.ProcurementOrders.$refs.table_ref !== undefined) {
                    const columns = this.$refs.ProcurementOrders.$refs.table_ref[0].columns;
                    let summariesArr = this.getSummaries({ columns: columns, data: this.ordersObj.details })
                    const footerWrapper = this.$refs.ProcurementOrders.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
                    const cells = footerWrapper.querySelectorAll('.cell');
                    cells.forEach((item, i) => {
                        item.innerText = summariesArr[i]
                    })

                }
            } catch (error) {
                console.log(val);
            }
        },
        async getPurchaseOrder(id) {
            try {
                const res = await this.$http({
                    method: "GET",
                    url: `erp/purchase_oder_relationship/${id}`,
                })
                if (res.status === 200) {
                    let val = null;
                    res.data.oa_form.content.forEach(item => {
                        if (item.control_title === "表单套件") {
                            val = JSON.parse(item.value);
                        }
                    });
                    if (val) {
                        val.order_id = id
                    }
                    return val;
                } else {
                    return null;
                }
            } catch (error) {
                return null
            }
        },
        onAppliedForm(val) {
            this.ordersObj = val
        },
        async getSettingForm() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/form_control_setting/",
                params: {
                    form_root: '采购入库申请1',
                    classification: '采购入库申请1',
                }
            })
            if (res.data[0] != null) {
                let obj = JSON.parse(JSON.stringify(res.data[0]))
                this.settingForm = obj
                this.isSettingLoading = true
            }
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
    },
    watch: {
        settingForm: {
            handler(val) {
                EventBus.$emit('onIsEditForm', true);
            }
        },
        "ordersObj.details": {
            handler(val) {
                const isValid = (value) => value != null && value !== '';
                if (this.$store.state.isphone) {
                    val.forEach(item => {
                        if (isValid(item.quantity) && isValid(item.unit_price)) {
                            let s1 = item.unit_price.toString();
                            let s2 = item.quantity.toString();
                            let decimal1 = new Decimal(s1)
                            let decimal2 = new Decimal(s2)
                            let decimal3 = decimal1.times(decimal2)
                            item.total_actual_amount = decimal3 * 1
                        }
                    })
                } else {
                    if (this.$refs.ProcurementOrders.$refs.table_ref === undefined) {
                        return
                    }
                    const data = this.$refs.ProcurementOrders.$refs.table_ref[0].data;
                    const columns = this.$refs.ProcurementOrders.$refs.table_ref[0].columns;
                    data.forEach(row => {
                        if (isValid(row.unit_price) && isValid(row.quantity)) {
                            let s1 = row.unit_price.toString();
                            let s2 = row.quantity.toString();
                            let decimal1 = new Decimal(s1)
                            let decimal2 = new Decimal(s2)
                            let decimal3 = decimal1.times(decimal2)
                            row.total_actual_amount = decimal3 * 1
                        }
                    });
                    let arr = this.getSummaries({
                        columns: columns,
                        data: data
                    })
                    const footerWrapper = this.$refs.ProcurementOrders.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
                    const cells = footerWrapper.querySelectorAll('.cell');
                    cells.forEach((item, i) => {
                        item.innerText = arr[i]
                    })
                }
            },
            deep: true,
        },
        // "ordersObj.customer": {
        //     handler(val) {
        //         if (val != '' && val != null) {
        //             if (this.ordersObj.customer_obj.label != null) {
        //                 if (!this.readonly) {
        //                     this.ordersObj.customerValue = this.ordersObj.customer_obj.label
        //                 }
        //             }
        //         }
        //     },
        //     deep: true,
        // },
        // "ordersObj.to_ware_house": {
        //     handler(val) {
        //         if (val != '' && val != null) {
        //             if (this.ordersObj.to_ware_house_obj.label !== null) {
        //                 if (!this.readonly) {
        //                     this.ordersObj.to_ware_house_value = this.ordersObj.to_ware_house_obj.label
        //                 }
        //             }
        //         }
        //     }
        // },
        ordersObj: {
            handler(val) {
                if (this.isAddOrder == false) {
                    this.$emit("onAddOutboundOrder", this.ordersObj)
                }
            }
        },
        addOrderForm: {
            handler(val) {
                if (this.formTitle == '采购入库申请') {
                    if (val != undefined) {
                        for (const key in val) {
                            this.ordersObj[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {

    },
    created() {
        this.getSettingForm()
    },
}
</script>