import Vue from 'vue'
Vue.directive('horizontal-scroll', {
    inserted(el) {
        el.addEventListener('wheel', handleScroll);
    },
    unbind(el) {
        el.removeEventListener('wheel', handleScroll);
    }
});

const handleScroll = (event) => {
    // 获取鼠标滚轮的滚动方向
    const delta = Math.sign(event.deltaY);

    // 获取滚动容器和内容元素
    const container = event.currentTarget;
    const content = container.querySelector('.content');

    // 计算滚动条的滚动距离
    const scrollAmount = delta * 100;

    // 更新滚动条位置
    container.scrollLeft += scrollAmount;

    // 阻止页面滚动
    event.preventDefault();
};