
import Vue from 'vue'
// 输入框获取焦点选中文字
Vue.directive('focus-select', {
    inserted(el) {
        let select = el.querySelector('.el-input__inner')
        // console.log(el, select)
        select.addEventListener('focus', function () {
            select.select();
        });
    }
});