<script>
import Signature from "@/components/OA/components/FormControls/Signature/index.vue";
import render from "@/utils/render.js";
import { trigger } from "./DynamicForm/components/generator/config.js";
import approvalsnode from "./approvalsnode.vue";
import InputBox from "@/components/Tools/InputBox.vue";
import orgSelect from "./FormControls/OrgSelect/dome.vue";
// import uploadpictures from "./uploadpictures.vue";
// import uploadfiles from "./uploadfiles.vue";
import { log } from "util";
import html2canvas from "html2canvas";
/**
 * 校验组织机构组件是否为空
 */
const setFcOrgSelectRule = (conf, ctx) => {
  return {
    validator: (rule, value, callback) => {
      const val = ctx.formModel[rule.field];
      if (Object.keys(val || {}).length === 0) {
        callback();
        return;
      }

      // var count = 0
      // console.log(val,'xxxxx');
      // conf.tabList.forEach(key => {
      //   val && Array.isArray(val[key]) && (count += val[key].length)
      // })
      const flag = val.length !== 0;
      callback(flag ? undefined : new Error(`${conf.title}不能为空`));
    },
    trigger: trigger[conf.tag],
    type: "object",
    required: true,
  };
};
/**
 * 收集表单必填项并组装成ElementUI表单校验得rules选项
 * 表格除外 表格自带校验
 */
function buildRules(conf, ctx) {
  if (!trigger[conf.tag]) return;
  const rules = [];
  // if (conf.style.display!=undefined) {
  //    if (conf.required&&conf.style.display=='block') {
  //       const type = Array.isArray(conf.defaultValue) ? "array" : undefined;
  //       let message = Array.isArray(conf.defaultValue)
  //         ? "请至少选择一个"
  //         : conf.placeholder;
  //       if (message === undefined) message = `${conf.label}不能为空`;
  //       conf.tag === "fc-org-select"
  //         ? rules.push(setFcOrgSelectRule(conf, ctx))
  //         : rules.push({
  //             required: true,
  //             type,
  //             message,
  //             trigger: trigger[conf.tag],
  //           });
  //     } else {
  //       return;
  //     }
  // } else {
    // console.log(ctx.requiredArr.includes(conf.formId),conf.label)
      if (ctx.requiredArr.includes(conf.formId)) {
      
      } else {
         if (conf.required) {
            const type = Array.isArray(conf.defaultValue) ? "array" : undefined;
            let message = Array.isArray(conf.defaultValue)
              ? "请至少选择一个"
              : conf.placeholder;
            if (message === undefined) message = `${conf.label}不能为空`;
            conf.tag === "fc-org-select"
              ? rules.push(setFcOrgSelectRule(conf, ctx))
              : rules.push({
                  required: true,
                  type,
                  message,
                  trigger: trigger[conf.tag],
                });
          } else {
            return;
          }
      }
     
  // }
 
  // 自定义正则匹配
  if (conf.regList && Array.isArray(conf.regList)) {
    conf.regList.forEach((item) => {
      if (item.pattern) {
        rules.push({
          pattern: eval(item.pattern),
          message: item.message,
          trigger: trigger[conf.tag],
        });
      }
    });
  }
  ctx.ruleList[conf.vModel] = rules;
  // console.log(rules)
  // console.log(ctx.ruleList[conf.vModel])
}

const setData = (ctx, val, prop, init = false) => {
  if (!prop) return;
  ctx.$set(ctx.formModel, prop, val);
};

let _isMounted = false; // 收集默认值 渲染完成之后防止重复收集默认值

const buildData = (ctx, value, prop) => setData(ctx, value, prop, true);

const layouts = {
  colFormItem: function (conf, h, ctx, isList = false) {
    buildRules(conf, ctx);
    // !_isMounted && buildData(ctx, conf.defaultValue, conf.vModel)
    let labelWidth = "";
    if (conf.labelWidth) labelWidth = `${conf.labelWidth}px`;
    if (isList) labelWidth = "0px";
    const required =
      (!trigger[conf.tag] && conf.required) || conf.tag === "fc-org-select";
    const handleInput = (val) => {
      // console.log(val,conf.vModel)
      setData(ctx, val, conf.vModel);
      if (conf.tag === "fc-upload") {
        conf.defaultValue = val;
        ctx.$refs[ctx.confGlobal.formRef].validateField(conf.vModel, () => {});
        // console.log(conf.defaultValue )
      } else if (conf.tag === "fc-org-select") {
        /**
         * 组织机构组件数据复杂
         * async-validator不一定能准确捕获到数据变化
         * 所以在这里手动校验一次
         */
        ctx.$refs[ctx.confGlobal.formRef].validateField(conf.vModel, () => {});
      } else if(conf.tag === 'fc_suiteform'){}else {
        conf.defaultValue=val
      }
    };
    // 控件内容
    // console.log(conf.disabled);
    const item = (
      <el-col span={conf.span}>
        <el-form-item
          label-width={labelWidth}
          label={
            isList
              ? ""
              : conf.tag == "fc-instructions"
              ? ""
              : ["fc_suiteform", "fc-relevancy"].includes(conf.tag)
              ? ""
              : conf.label
          }
          style={conf.style}
          prop={conf.vModel}
          class={[conf.required == true ? "is-required" : ""]}
        >
          <render
            formData={ctx.formModel}
            conf={conf}
            style={conf.style}
            value={ctx.formModel[conf.vModel]}
            autocompletionFormData={
              conf.tag === "fc-autocompletion" ? ctx.formData.fields : ""
            }
            readonly={ctx.confGlobal_permission}
            ref={
              conf.rowType === "table"
                ? conf.vModel
                : conf.tag == "fc_suiteform"
                ? "refSuiteform"
                : conf.tag == "fc-relevancy"
                ? "refRelevancy"+conf.formId
                : undefined
            }
            onInput={handleInput}
          />
        </el-form-item>
      </el-col>
    );
    // console.log(conf, ctx);
    if (isList) {
      const tableTitle = (
        <el-col
          span={24}
          style="font-size: 14px;padding:6px 0px;margin-bottom: 4px;border-bottom: 1px solid #e5e5e5;"
        >
          {conf.label}
        </el-col>
      );
      return [tableTitle, item];
    }
    return item;
  },

  rowFormItem: function (conf, h, ctx) {
    if (conf.rowType === "table") {
      ctx.tableRefs[conf.vModel] = conf;
      const param = {
        ...conf,
        config: conf,
        formSize: ctx.confGlobal.size,
        labelWidth: `${conf.labelWidth || ctx.confGlobal.labelWidth}px`,
      };
      return this.colFormItem(param, h, ctx, conf.type === "list");
    }
    buildRules(conf, ctx);
    const props = {
      type: conf.type === "default" ? undefined : conf.type,
      justify: conf.type === "default" ? undefined : conf.justify,
      align: conf.type === "default" ? undefined : conf.align,
      gutter: typeof conf.gutter === "number" ? conf.gutter : undefined,
    };
    const row = (
      <el-col span={conf.span || 24}>
        <el-row {...{ props: props }} style={conf.style}>
          {Array.isArray(conf.children) &&
            conf.children.map((el) => layouts[el.layout](el, h, ctx))}
        </el-row>
      </el-col>
    );
    let divider;
    if (conf.showDivider) {
      let explain;
      if (conf.cmpType === "custom" && conf.explain) {
        explain = (
          <el-tooltip effect="dark" content="组件说明" placement="top">
            <i
              class="el-icon-info"
              onClick={ctx.showExplain.bind(this, conf.explain, conf.label)}
              style="margin-right: 8px; color: #E6A23C;cursor: pointer;"
            ></i>
          </el-tooltip>
        );
      }
      divider = (
        <el-col span={24} style={conf.style}>
          <el-divider content-position="left">
            {explain} {conf.label}
          </el-divider>
        </el-col>
      );
      return [divider, row];
    }
    return row;
  },
};

export default {
  data() {
    return {
      //重启
      isReboot:false,
      requiredArr:[],
      //当前节点隐藏控件
      node_disabled: [],
      //下拉选择关联控件id
      selectArr: [],
      //表单控件权限
      selectRequiredArr: null,
      //关联表格
      relevancyArr:[],
      isCareOf: null,
      isSendBack: null,
      isResetForm: null,
      isApostille: null,
      currentName: decodeURIComponent(
        escape(window.atob(window.localStorage.getItem("name")))
      ),
      print_setting: null,
      signatureDataId: null,
      signatureUrl: null,
      is_signature: null,
      isSignature: false,
      rowObj: {},
      inputList: null, //@内容和人员
      isInput: true, //@数据 开关
      mockData: null, //@组织数据
      tableRefs: {},
      drawerTitle: "",
      drawerText: "",
      drawerVisible: false,
      containerWidth: 100,
      // confGlobal: this.formData || null,
      formModel: {},
      advanced_setting: null,
      print_Arr: null,
      ruleList: {},
      timeline: [],
      commentContent: null,
      flag: false,
      optionList: [],
      value: "请选择",
      approver_id: null,
      Apostille_data: {},
      addArr: [],
      files: [],
      fileflag: false,
      formData: null,
      permission: null,
      content_fields: null,
      currentid: null,
      counter_idx: null,
      current_approver: null,
      current_approver_title: null,
      initiator_name: null,
      form_title: null,
      form_status_label: null,
      approve_number: null,
      isPrint: false, //是否打印
      forbid: null,
      revocationflag: null,
      printobj: {
        id: "prints",
        popTitle: "审批流程",
      },
      filesObj: {},
      filesId: null,
    };
  },
  components: {
    approvalsnode,
    orgSelect,
    InputBox,
    Signature,
    // uploadfiles,
    // uploadpictures,
  },
  computed: {
    confGlobal() {
      // console.log(this.content_fields);
      return this.formData;
    },
    confGlobal_permission() {
      return this.permission;
    },
    confGlobal_content_fields() {
      return this.content_fields;
    },
    requiredlist() {
      const arr = [];
      this.confGlobal.fields.forEach((item) => {
        if (item.required) {
          arr.push(item.vModel);
        }
      });
      return arr;
    },
  },
  // props: ['formData', 'content_fields', 'currentid', 'form_id', 'flow_id', 'permission'],
  props: ["form_id", "flow_id", "size", "isInForm"],
  mounted() {
    this.fun();
    this.getPersonnel();
    this.signatureIsValid();
    window.windowDeleFile = (data) => {
      this.handleClickDeleFile(data);
      // this.fun();
    };
    window.windowDownloadFile = (data) => {
      // console.log(data);
      this.handleClickDownloadFile(data);
      this.fun();
    };
  },
  watch: {
    formData: {
      handler(val) {
        if (this.permission === null) {
          return;
        }
        if (val.fields) {
          this.initDefaultData(val);
          _isMounted = true;
        }
      },
      deep: true,
      immediate: true,
    },

    formModel: {
      handler(val) {
        // console.log(JSON.stringify(val));
        if (this.formData === null) {
          return;
        }
        // this.relevancy_control(this.formData);
        // this.formData.fields.forEach((item) => {
        //   if (item.tag == "el-select") {
        //     if (val[item.vModel] != undefined) {
        //       let control = null;
        //       item.options.forEach((items) => {
        //         if (val[item.vModel] == items.value) {
        //           control = items.data;
        //         }
        //       });
        //       this.formData.fields.forEach((item) => {
        //         control.forEach((items) => {
        //           if (item.formId == items) {
        //             item.style = {};
        //             item.style.display = "block";
        //             item.required = true;
        //           }
        //         });
        //       });
        //     }
        //   }
        // });
       
        this.onAssociatedControls(this.formData);
        let control = [];
          this.formData.fields.forEach((item) => {
            if (item.tag == "el-select") {
                // console.log(val[item.vModel] != undefined&&val[item.vModel] != "","下拉选择")
                if (val[item.vModel] != undefined&&val[item.vModel] != "") {
                  item.options.forEach((items) => {
                    {/* console.log(val[item.vModel]===items.value,"判断选择关联控件",val,items); */}
                   if (val[item.vModel] === items.value) {
                      if(items.data != undefined&&items.data.length>0) {
                         items.data.forEach(controlItem => {
                           control.push(controlItem)
                        })
                      }
                    }
                  });
                  //  if (control.length > 0) {
                  //   control.forEach(controlId => {
                  //     this.formData.fields.forEach((dataItem,i) => {
                  //       if (dataItem.formId == controlId) {
                  //         dataItem.style.display = "block"
                  //       }
                  //      })
                  //   })
                  // }
                }
              }
          });
        // console.log(this.selectArr, this.node_disabled, control)
        this.selecControls(this.selectArr, this.node_disabled, control)
        this.submitnode()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onIsEmptyDemandApplication(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.p_charge_classification,item.p_charge_code,item.quantity]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    onIsEmptyQuantity(arr){
      let isEmpty = false;
      arr.forEach((item, i) => {
         let arr1=[item.quantity]
         arr1.forEach((items,j)=>{
           if(items!==0&&!items){
             return isEmpty=true
           }
         })
       });
       return isEmpty;
    },
    onAgainResubmit(){
      this.$router.push({path:'/oaintoform',query:{id:this.flow_id,form_id:this.form_id}})
    },
   async postOperationalRecorder(str,obj){
      const res=await this.$http({
        method:str,
        url:"configuration/operational_recorder/",
        data:obj,
      })
    //  console.log(res)
    },
    async getOperationalRecorder(obj){
      const res=await this.$http({
        method:"GET",
        url:"configuration/operational_recorder/",
        params:obj,
      })
      if(res.status==200){
        if(res.data.length>0){
          let buttons=[]
          res.data[0].operate_data.remark_arr.forEach((items,)=>{
            buttons.push(items)
          })
          return buttons
        }else{
          return []
        }
      }else{
        return []
      }
    },
    getProductIdAll(data){
      const isValid = (value) => value != null && value !== '';
      let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
      if(dialogFromList.content_data!=undefined){
        let val=data.content_data
        delete dialogFromList.content_data
        let obj={};
        for(const key in val){
          if(val[key]!=undefined){
            obj[key]=JSON.parse(val[key])
          }
        }
        delete obj[this.formData.fields[0].formId].content_data
        dialogFromList.content_data=obj
      }
      let isEmpty = false;
      let arr=[];
      dialogFromList.products.forEach((item, i) => {
          if (isValid(item.product_id)) {
            if(isValid(item.quantity)){
              item.quantity = Number(item.quantity);
             }
             if(isValid(item.unit_price)){
               item.unit_price = Number(item.unit_price);
             }
             arr.push(item);
          }
        });
      dialogFromList.products=arr
      arr.forEach((item, i) => {
        let arr1=[item.unit_price,item.quantity,item.special_offer_price]
          arr1.forEach((items,j)=>{
            if(items!==0&&!items){
              return isEmpty=true
            }
          })
        });
      return {
        dialogFromList:dialogFromList,
        isEmpty:isEmpty,
      }
    },
    getProductIdQuantity(data){
      const isValid = (value) => value != null && value !== '';
      let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
      if(dialogFromList.content_data!=undefined){
        let val=data.content_data
        delete dialogFromList.content_data
        let obj={};
        for(const key in val){
          if(val[key]!=undefined){
            obj[key]=JSON.parse(val[key])
          }
        }
        delete obj[this.formData.fields[0].formId].content_data
        dialogFromList.content_data=obj
      }
      let arr = [];
      let isEmpty = false;
      dialogFromList.products.forEach((item, i) => {
          if (isValid(item.product)) {
            if(isValid(item.quantity)){
              item.quantity = Number(item.quantity);
             }
             if(isValid(item.unit_price)){
               item.unit_price = Number(item.unit_price);
             }
             arr.push(item);
          }
        });
      dialogFromList.products=arr
      arr.forEach((item, i) => {
        let arr1=[item.quantity]
          arr1.forEach((items,j)=>{
            if(items!==0&&!items){
              return isEmpty=true
            }
          })
        });
      return {
        dialogFromList:dialogFromList,
        isEmpty:isEmpty,
      }
    },
    getProductIdQuantityUnitPrice(data,str){
      const isValid = (value) => value != null && value !== '';
      let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
      if(dialogFromList.content_data!=undefined){
        let val=data.content_data
        delete dialogFromList.content_data
        let obj={};
        for(const key in val){
          if(val[key]!=undefined){
            obj[key]=JSON.parse(val[key])
          }
        }
        delete obj[this.formData.fields[0].formId].content_data
        dialogFromList.content_data=obj
      }
      let arr = [];
      let isEmpty = false;
      dialogFromList[str].forEach((item, i) => {
        console.log(item)
        console.log(isValid(item.product))
          if (isValid(item.product)) {
            if(isValid(item.quantity)){
              item.quantity = Number(item.quantity);
             }
             if(isValid(item.unit_price)){
               item.unit_price = Number(item.unit_price);
             }
             arr.push(item);
          }
        });
        console.log(arr)
      dialogFromList[str]=arr
      arr.forEach((item, i) => {
        let arr1=[item.unit_price,item.quantity]
          arr1.forEach((items,j)=>{
            if(items!==0&&!items){
              return isEmpty=true
            }
          })
        });
      return {
        dialogFromList:dialogFromList,
        isEmpty:isEmpty,
      }
    },
    getProductIdQuantityUnitPrice1(data,str){
      const isValid = (value) => value != null && value !== '';
      let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
      if(dialogFromList.content_data!=undefined){
        let val=data.content_data
        delete dialogFromList.content_data
        let obj={};
        for(const key in val){
          if(val[key]!=undefined){
            obj[key]=JSON.parse(val[key])
          }
        }
        delete obj[this.formData.fields[0].formId].content_data
        dialogFromList.content_data=obj
      }
      let arr = [];
      let isEmpty = false;
      dialogFromList[str].forEach((item, i) => {
          if (isValid(item.product_id)) {
            if(isValid(item.quantity)){
              item.quantity = Number(item.quantity);
             }
             if(isValid(item.unit_price)){
               item.unit_price = Number(item.unit_price);
             }
             arr.push(item);
          }
        });
      dialogFromList[str]=arr
      arr.forEach((item, i) => {
        let arr1=[item.unit_price,item.quantity]
          arr1.forEach((items,j)=>{
            if(items!==0&&!items){
              return isEmpty=true
            }
          })
        });
      return {
        dialogFromList:dialogFromList,
        isEmpty:isEmpty,
      }
    },
    onIsEmptyExpenseCategoryAll(data,str){
      const isValid = (value) => value != null && value !== '';
      let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
      if(dialogFromList.content_data!=undefined){
        let val=data.content_data
        delete dialogFromList.content_data
        let obj={};
        for(const key in val){
          if(val[key]!=undefined){
            obj[key]=JSON.parse(val[key])
          }
        }
        delete obj[this.formData.fields[0].formId].content_data
        dialogFromList.content_data=obj
      }
      let arr = [];
      let isEmpty = false;
      dialogFromList[str].forEach((item, i) => {
          if (isValid(item.product)) {
            if(isValid(item.quantity)){
              item.quantity = Number(item.quantity);
             }
             if(isValid(item.unit_price)){
               item.unit_price = Number(item.unit_price);
             }
             arr.push(item);
          }
        });
      dialogFromList[str]=arr
      arr.forEach((item, i) => {
        let arr1=[item.unit_price,item.quantity,item.f_charge_classification]
          arr1.forEach((items,j)=>{
            if(items!==0&&!items){
              return isEmpty=true
            }
          })
        });
      return {
        dialogFromList:dialogFromList,
        isEmpty:isEmpty,
      }
    },
    async onReboot() {
      const data = {}
      data.operate_type=9  
      data.job_id=window.localStorage.getItem("jobid") 
      data.flow_id = this.flow_id;
      data.form_id = this.form_id; 
      data.remark = '重走'; 
      const res = await this.$http({
          method: "POST",
          url: "oa/form_operate/",
          data
      })
     if(res.status == 200){
      this.$message.success('流程重启成功')
      if (this.isInForm) {
        this.$emit("onInformConent", false);
      } else {
        this.$router.push("oahome");
      }
     }else{
      this.$message.error(res.data)
     }
    },
    //撤销刷新
    approverdelete(data) {
      // console.log(data);
      if (data.status === 204) {
        this.fun();
      } else {
        this.$message.error(res.data);
      }
    },
    // 删除附件
    async handleClickDeleFile(data) {
      // console.log("删除", data);
      const res = await this.$http({
        method: "DELETE",
        url: `oa/oa_form_files_bulk_del/`,
        data: [data],
      });
      // console.log(res);
      this.$nextTick(() => {
        this.fun();
      });
    },
    // 下载附件文件
    handleClickDownloadFile(data) {
      // console.log('/media/file/oa_file/' + data.file_name, data.file_name);
      this.downloadFile(
        "/media/file/oa_file/" + data.file_name,
        data.file_name
      );
    },
    // 下载文件
    async downloadFile(file_url, file_name) {
      // file_name 下载文件名成带后缀
      // console.log(file_url);
      // let file_url = '/media/file/item_file/高级设置配置数据互通.jpg'
      const res = await this.$http({
        method: "POST",
        url: "util_flow/file_download/",
        data: {
          file_url: file_url,
        },
        responseType: "blob",
      });
      if (res.status == 200) {
        const data = res.data;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.$message.error(res.data);
      }
    },
      //获取流程节点队列
    async submitnode() {
    //  console.log('获取流程')
      /* this.$refs[this.confGlobal.formRef].validate(async (valid, i) => { 
        if (this.relevancyArr.length > 0) {
          const RelevancyArr=[]
          this.relevancyArr.forEach(item => {
              const RelevancyObj ={}
              RelevancyObj['refRelevancy'] = this.$refs[('refRelevancy' + item)].$children[0].$refs;
              RelevancyObj['refRelevancyArr']=Object.keys(this.$refs[('refRelevancy'+item)].$children[0].$refs)
              RelevancyArr.push(RelevancyObj)
            })
          let validArr = []
          for (const data of RelevancyArr) {
              await Promise.all(data.refRelevancyArr.map(item => {
                return new Promise((resolve, reject) => {
                  data.refRelevancy[item][0].validate(async (valid, i) => {
                    validArr.push(valid);
                    resolve();  // 标记当前验证已完成
                  });
                });
              }));
            }
          if(validArr.includes(false)) return
        }
        try {
            this.formData.fields.forEach((item, i) => {
              let title = "";
              if (item.layout == "rowFormItem" && item.showRequired) {
                if (item.showDivider) {
                  item.children.forEach((items, j) => {
                    title = title + items.label + "或";
                  });
                  if (this.rowObj[item.formId].includes(true)) {
                  } else {
                    throw this.$message.error("请输入" + title);
                  }
                } else {
                  // console.log("隐藏");
                }
              }
            });
          } catch (e) {
            return;
          }
         const data = this.initiatorrewrite(this.formModel);
          data.flow_id = this.flow_id;
          data.form_id = this.form_id;
          data.node_id = this.currentid;
          data.counter_idx = this.counter_idx;
          const res =await this.$http.post("oa/oa_flow_node_queue/", data);
          this.timeline = res.data;
          if (res.data[0].approver !== null) {
              this.revocationflag =
                res.data[0].approver[0].job_id ===
                window.localStorage.getItem("jobid")
                  ? this.forbid
                    ? this.forbid
                    : false
                  : true;
            } else {
              this.revocationflag = true;
            } 
      }) */
    },
    // node排序
    //  processData(data) {
    //   let allApprovals = [];
    //   data.forEach(item => {
    //     if (item.node_approve_data && item.node_approve_data.length > 0) {
    //       allApprovals = allApprovals.concat(item.node_approve_data);
    //     }
    //   });

    //   allApprovals.sort((a, b) => {
    //     return new Date(a.approve_datetime) - new Date(b.approve_datetime);
    //   });

    //   allApprovals.forEach((approval, index) => {
    //     approval.index = index + 1;
    //   });

    //   return data;
    // },
    // 获取表单数据
    async fun() {
      const data = {};
      data.flow_id = this.flow_id;
      data.form_id = this.form_id;
      data.job_id = window.localStorage.getItem("jobid");
      const list = await this.$http.get(
        "oa/oa_flow_format/?flow_id=" +
          this.flow_id +
          "&form_id=" +
          this.form_id
      );
       //console.log(list,'999999999999999999999s');
      if (list.status === 200) {
      this.print_setting = list.data.advanced_setting.print_setting;
      this.advanced_setting = list.data.advanced_setting;
      this.print_Arr = list.data.print_list;
        const res = await this.$http.post("oa/oa_flow_node_queue/", data);
        this.isPrint = list.data.advanced_setting.is_selectTemplate;
        this.approve_number = list.data.approve_number;
        this.current_approver = list.data.current_approver;
        this.current_approver_title = list.data.current_approver.join(",");
        this.initiator_name = list.data.initiator_name;
        this.form_title = list.data.form_title;
        this.form_status = list.data.form_status;
        this.form_status_label = list.data.form_status_label;
        this.forbid =this.form_status_label == "已通过"? true: this.form_status_label == "已拒绝"? true: this.form_status_label == "已撤销"? true: false;
        this.counter_idx = list.data.current_node_idx;
        this.currentid = list.data.current_node_id;
        this.permission = list.data.permission_data;
        this.node_disabled = []
        this.permission.forEach(item => {
          if (item.formOperate==0) {
            this.node_disabled.push(item.formId)
          }
        })
        this.content_fields = list.data.control_content_data;
        this.selectRequiredArr = JSON.parse(JSON.stringify(list.data.layout_data.formData.fields));
        if(list.data.layout_data.formData!=undefined){
          this.formData = JSON.parse(JSON.stringify(list.data.layout_data.formData));
        }
        this.is_signature = list.data.advanced_setting.is_signature;
        this.timeline = res.data;
      //  console.log(this.timeline)
        let timelineArr = [];
        this.timeline.forEach((item) => {
          if (item.node_name == "加签审批人" && item.node == null) {
            timelineArr.push(item.idx);
          }
        });
        if (timelineArr.includes(this.counter_idx)) {
          // console.log(this.forbid);
          this.isApostille = this.forbid ? this.forbid : true;
        } else {
          this.isApostille = this.forbid ? this.forbid : false;
        }
        if (res.data[0].approver !== null) {
          this.isReboot =this.advanced_setting.isReboot?list.data.form_status == 3 ? res.data[0].approver[0].job_id === window.localStorage.getItem("jobid") ? true : false : false:false;
          this.revocationflag =res.data[0].approver[0].job_id ===window.localStorage.getItem("jobid")? this.forbid? this.forbid: false: true;
        } else {
          this.isReboot = false;
          this.revocationflag = true;
        }
        this.isCareOf = this.forbid;
        this.isSendBack = this.forbid;
        this.isResetForm = this.forbid;
        if (this.counter_idx == 1) {
          this.isCareOf = true;
          this.isSendBack = true;
          this.isResetForm = true;
          this.isApostille = true;
          this.revocationflag = true;
        }
      } else {
        this.$message("流程表格错误");
      }
      let content = this.content_fields;
      let arr = this.formData.fields;
      let content_obj = {};
      content.forEach((item) => {
        arr.forEach((items, i) => {
          if (items.showDivider != undefined) {
            items.children.forEach((val, j) => {
              if (val.formId == item.formId) {
                content_obj[item.formId] = item.value;
              }
            });
          } else {
            if (items.formId == item.formId) {
              if (items.tag == "el-cascader") {
                content_obj[item.formId] = JSON.parse(item.store_value);
              } else {
                content_obj[item.formId] = item.value;
              }
            }
          }
        });
      });
      this.formData.fields.forEach((item) => {
        if (item.tag === "fc-upload") {
          item.uploadFormId = {
            flow_id: this.flow_id,
            form_id: this.form_id,
          };
        }
        if (item.tag == 'fc-relevancy') {
            this.relevancyArr.push(item.formId)
          }
        if (item.layout == "rowFormItem") {
          item.children.forEach((items) => {
            if (items.tag === "fc-upload") {
              items.uploadFormId = {
                flow_id: this.flow_id,
                form_id: this.form_id,
              };
            }
          });
        }
        if (item.showDivider != undefined && item.showRequired) {
          this.rowObj[item.formId] = [];
          item.children.forEach((items, x) => {
            this.rowObj[item.formId].push(false);
          });
        }
      });
      arr.forEach((item) => {
        if (item.showDivider != undefined && item.showRequired) {
          this.rowObj[item.formId] = [];
          item.children.forEach((items, x) => {
            if (content_obj[items.formId] != undefined) {
              this.rowObj[item.formId][x] = true;
            }
          });
        }
      });
      arr.forEach((item, i) => {
        if (item.showDivider != undefined) {
          item.children.forEach((items, j) => {
            if (items.tag == "fc-autocompletion") {
              item.children[j].defaultValue = content_obj[items.formId];
            } else if (items.tag == "el-cascader") {
              item.children[j].defaultValue = content_obj[items.formId];
            } else if (items.tag == "fc-date-duration") {
              item.children[j].defaultValue = JSON.parse(
                content_obj[items.formId]
              );
            } else {
              if (content_obj[items.formId] == "True") {
                item.children[j].defaultValue = true;
              } else if (content_obj[items.formId] == "False") {
                item.children[j].defaultValue = false;
              } else {
                item.children[j].defaultValue = content_obj[items.formId];
              }
            }
          });
        }
        if (item.tag == "fc-input-table") {
          // console.log(content_obj[item.formId]);
          item.defaultValue = content_obj[item.formId];
          // item.defaultValue = this.biaoge(eval("(" + content_obj[item.formId] + ")"), item)
          // console.log(item.defaultValue);
        } else if (item.tag == "fc_map") {
          // console.log(content_obj[item.formId]);
          // console.log(item.defaultValue);
          item.defaultValue = content_obj[item.formId];
        } else if (item.tag == "el-cascader") {
          // console.log(content_obj[item.formId]);
          // console.log(item.defaultValue);
          item.defaultValue = content_obj[item.formId];
        } else if (item.tag == "fc-date-duration") {
          // console.log(content_obj[item.formId]);
          // console.log(item.defaultValue);
          item.defaultValue = JSON.parse(content_obj[item.formId]);
        } else if (item.tag == "fc-relevancy") {
          // console.log(content_obj[item.formId]);
          // console.log(item.defaultValue);
          item.defaultValue = JSON.parse(JSON.stringify(content_obj[item.formId]));
         //  console.log(item.defaultValue);
        } else if (item.tag == "fc_suiteform") {
          item.defaultValue = content_obj[item.formId];
        } else if (item.tag == "fc-autocompletion") {
          // console.log(item.defaultValue);
          item.defaultValue = content_obj[item.formId];
        } else {
          if (content_obj[item.formId] == "True") {
            item.defaultValue = true;
          } else if (content_obj[item.formId] == "False") {
            item.defaultValue = false;
          } else {
            item.defaultValue = content_obj[item.formId];
          }
        }
      });
      this.formData.fields.forEach((item, i) => {
        this.permission.forEach((items, j) => {
          if (item.children == undefined && item.tag != "fc-input-table") {
            if (item.formId == items.formId) {
              if (items.formOperate==1) {
                item.readonly = true;
                item.disabled = true;
                item.changeTag = false;
              } else if (items.formOperate==2) {
                item.readonly = false;
                item.disabled = false;
                item.changeTag = true;
              } else {
                if (item.tag !== "fc-relevancy") {
                  item.style.display = "none";
                  // item.required = false;
                } else {
                 item.requiredObj = JSON.parse(JSON.stringify(items.formOperate))
                }
                
              }
            }
          } else {
            if (item.tag == "fc-input-table") {
              item.children.forEach((childrenItem, x) => {
                if (childrenItem.formId == items.formId) {
                  if (items.formOperate==1) {
                    item.children[x].readonly = true;
                    item.children[x].disabled = true;
                    item.children[x].changeTag = false;
                  } else if (items.formOperate==2) {
                    item.children[x].readonly = false;
                    item.children[x].disabled = false;
                    item.children[x].changeTag = true;
                  } else {
                    item.children[x].readonly = false;
                    item.children[x].disabled = false;
                    item.children[x].changeTag = false;
                  }
                }
              });
            } else {
              item.children.forEach((childrenItem, x) => {
                if (childrenItem.formId == items.formId) {
                  if (items.formOperate==1) {
                    item.children[x].readonly = true;
                    item.children[x].disabled = true;
                    item.children[x].changeTag = false;
                  } else if (items.formOperate==2) {
                    item.children[x].readonly = false;
                    item.children[x].disabled = false;
                    item.children[x].changeTag = true;
                  } else {
                    item.children[x].style.display = "none";
                    // item.children[x].required = false;
                  }
                }
              });
            }
          }
        });
      });
      // this.relevancy_control(this.formData);
      this.onAssociatedControls(this.formData);
      this.selecControls(this.selectArr,this.node_disabled,[])
    },
    //下拉框关联控件
    onAssociatedControls(val) {
      // console.log(val)
      let arr = [];
      val.fields.forEach((item) => {
        if (item.tag == "el-select") {
          item.options.forEach((items) => {
            arr = arr.concat(items.data);
          });
        }
      });
      arr = Array.from(new Set(arr));
      arr.forEach((item, i) => {
        if (typeof item !== "number") {
          arr.splice(i, 1);
        }
      });
      this.selectArr = arr;
      // val.fields.forEach((item, i) => {
      //   arr.forEach((items) => {
      //     if (item.formId == items) {
      //       item.style = {};
      //       item.style.display = "none";
      //       item.required = false;
      //       this.$set(this.formData.fields[i], "required", false);
      //       this.$set(this.formData.fields[i], "defaultValue", undefined);
      //     }
      //   });
      // });
    },
    selecControls(selectArr, node_disabled, control) {
      // console.log(selectArr,"关联控件")
      // console.log(node_disabled,"节点控件")
      // console.log( control,"显示控件")
      // return
      this.requiredArr=[] 
      const set = new Set(control);
      selectArr = selectArr.filter((item) => !set.has(item))
      // console.log(selectArr, '关联控件')
      // console.log(node_disabled, '节点控件')
      this.formData.fields.forEach((item, j) => {
        // console.log(selectArr.includes(item.formId) || node_disabled.includes(item.formId),item.label,item.formId)
        if (selectArr.includes(item.formId) || node_disabled.includes(item.formId)) {
          item.style.display = "none";
           this.requiredArr.push(item.formId)
          } else {
            item.style.display = "block";
            if(item.readonly){
              this.requiredArr.push(item.formId)
            }
          }
      });
      this.selectArr = selectArr
      this.node_disabled = node_disabled
      // console.log(selectArr, node_disabled, control)
      // console.log(this.selectArr, this.node_disabled,this.requiredArr)
    },
    async signatureIsValid() {
      let res = await this.$http({
        method: "GET",
        url: "oa/signature_is_valid/",
        params: {
          flow_id: this.flow_id,
          form_id: this.form_id,
          node_id: this.currentid,
          job_id: window.localStorage.getItem("jobid"),
        },
      });
      this.signatureDataId = res.data.id;
      this.signatureUrl = res.data.url;
      if (res.data == "签名图片已过期") {
        this.signatureDataId = null;
      }
    },
    async submitForm1() {
      if (this.signatureDataId != null) {
        let res = await this.$http({
          method: "POST",
          url: "oa/deal_with_signature_form_relation/",
          data: {
            form_id: this.form_id,
            node_id: this.currentid,
            signature_id: this.signatureDataId,
          },
        });
      }
    },
    // 同意
    async submitForm() {
      if (this.counter_idx != 1) {
        if (this.is_signature) {
          if (this.signatureDataId == null) {
            return this.$message.error("请签名");
          }
        }
      }
      const remarkRes= await this.getOperationalRecorder({job_id:window.localStorage.getItem("jobid"),sys_name:2,url:"/conent",description:"同意"})
      let buttons =[]
      if(remarkRes.length>0){
        remarkRes.forEach((items,i)=>{
            buttons.push({id:('btn'+(i+1)),label:items,opinion:i+1})
          })
      }else{
        buttons =[
          { id: 'btn1', label: '同意', opinion: 1 },
          { id: 'btn2', label: 'OK', opinion: 2 },
          { id: 'btn3', label: '已办理', opinion: 3 },
        ];
      }
      const style ="padding: 5px;margin: 5px;background-color: #dad8d8;border: none;border-radius: 10px;white-space: nowrap;";
      const h = this.$createElement;
      const buttonElements = buttons.map(button =>
        h(
          "button",
          {
            attrs: {
              id: button.id,
              style: style,
            },
            on: {
              click: () => {
                this.opinion(button.opinion);
              },
            },
          },
          button.label
        )
      );
      this.$msgbox({
        title: "审批同意意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", { style: "" }, [
              h("span", {}, "内容:"),
              h("textarea", {
                attrs: {
                  placeholder: "请输入同意理由",
                  class: "el-textarea__inner",
                  autocomplete: "off",
                  rows: 3,
                  id: "commentContent",
                },
                value: this.commentContent,
                on: { input: this.onCommentInputChange },
              }),
            ]),
            h(
              "div",
              {
                style: "display: flex;flex-wrap: wrap;",
              },
              buttonElements
            ),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const isTableValid = this.checkTableData();
            this.$refs[this.confGlobal.formRef].validate(async (valid, i) => {
              if (this.relevancyArr.length > 0) {
                const RelevancyArr=[]
                this.relevancyArr.forEach(item => {
                    const RelevancyObj ={}
                    RelevancyObj['refRelevancy'] = this.$refs[('refRelevancy' + item)].$children[0].$refs;
                    RelevancyObj['refRelevancyArr']=Object.keys(this.$refs[('refRelevancy'+item)].$children[0].$refs)
                    RelevancyArr.push(RelevancyObj)
                  })
                let validArr = []
                for (const data of RelevancyArr) {
                    await Promise.all(data.refRelevancyArr.map(item => {
                      return new Promise((resolve, reject) => {
                        data.refRelevancy[item][0].validate(async (valid, i) => {
                          validArr.push(valid);
                          resolve();  // 标记当前验证已完成
                        });
                      });
                    }));
                  }
                if(validArr.includes(false)) return
              }
              if (!valid) {
                instance.confirmButtonLoading = false;
                done();
                this.$message({
                  type: "error",
                  message: "表单未填写完成",
                });
                return;
              }
              if (!isTableValid) {
                instance.confirmButtonLoading = false;
                return;
              }
              const data = this.initiatorrewrite(this.formModel);
              data.flow_id = this.flow_id;
              data.form_id = this.form_id;
              data.node_id = this.currentid;
              data.counter_idx = this.counter_idx;
              data.remark = this.commentContent;
              try {
                this.formData.fields.forEach((item, i) => {
                  let title = "";
                  if (item.layout == "rowFormItem" && item.showRequired) {
                    if (item.showDivider) {
                      item.children.forEach((items, j) => {
                        title = title + items.label + "或";
                      });
                      if (this.rowObj[item.formId].includes(true)) {
                      } else {
                        throw this.$message.error("请输入" + title);
                      }
                    } else {
                    }
                  }
                });
              } catch (e) {
                return;
              }
              let is_fc_suiteform =false
              if(this.formData.fields[0].tag==='fc_suiteform'){
                 if(this.formData.fields[0].source == "出库申请"){
                  let RefAddOutboundOrder =this.$refs.refSuiteform.$children[0].$refs.AddOutboundOrder.$refs;
                  RefAddOutboundOrder.outboundOrdersObjRef1.validate(async (valid)=>{
                    if(valid){
                      RefAddOutboundOrder.outboundOrdersObjRef2.validate(async (valid)=>{
                        if(valid){
                          RefAddOutboundOrder.outboundOrdersObjRef3.validate(async (valid)=>{
                            if(valid){
                              is_fc_suiteform=valid
                               let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
                               let arr = [];
                               dialogFromList.products.forEach((item, i) => {
                                   if (item.product_id !== "" &&item.product_id != null) {
                                     if(item.quantity!=null&&item.quantity!=''){
                                       item.quantity = Number(item.quantity);
                                      }
                                      if(item.unit_price!=null&&item.unit_price!=''){
                                        item.unit_price = Number(item.unit_price);
                                      }
                                      arr.push(item);
                                   }
                                 });
                               dialogFromList.content_data=data.content_data
                               data.content_data[this.formData.fields[0].formId]=JSON.stringify(dialogFromList)
                            }
                          })
                        }
                      })
                    }
                  })
                 }else if(this.formData.fields[0].source == "出库申请(亿看)"){
                  let RefAddOutboundOrder = this.$refs.refSuiteform.$children[0].$refs.AddOutboundOrder.$refs;
                  // console.log(RefAddOutboundOrder)
                  RefAddOutboundOrder.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
                    if(valid){
                    //  console.log("出库申请")
                      is_fc_suiteform=valid
                      let obj=this.getProductIdQuantityUnitPrice1(data,'products')
                      // console.log(obj)
                      if (obj.isEmpty) {
                        this.$message.warning("请填写产品数量或成交单价");
                        is_fc_suiteform=false
                      }
                      data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                    }
                  })
                 }else if(this.formData.fields[0].source == "送样申请"){
                  let RefAddQuotation =this.$refs.refSuiteform.$children[0].$refs.SampleSubmissions.$refs
                  // console.log(RefAddQuotation)
                  RefAddQuotation.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
                  //  console.log("送样申请")
                    is_fc_suiteform=valid
                    let obj=this.getProductIdAll(data)
                    if (obj.isEmpty) {
                      this.$message.warning("请填写产品数量或参考单价、成交单价");
                      is_fc_suiteform=false
                    }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "报价申请"){
                  let RefAddQuotation = this.$refs.refSuiteform.$children[0].$refs.AddQuotation.$refs;
                  // console.log(RefAddQuotation)
                  RefAddQuotation.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
                  //  console.log("报价申请")
                    is_fc_suiteform=valid
                    let obj=this.getProductIdAll(data)
                    if (obj.isEmpty) {
                      this.$message.warning("请填写产品数量或参考单价、成交单价");
                      is_fc_suiteform=false
                    }
                     data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "订单申请"){
                  let RefAddOrders =this.$refs.refSuiteform.$children[0].$refs.AddOrders.$refs;
                  RefAddOrders.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
                     is_fc_suiteform=valid
                     let obj=this.getProductIdAll(data)
                     if (obj.isEmpty) {
                       this.$message.warning("请填写产品数量或参考单价、成交单价");
                       is_fc_suiteform=false
                     }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "生产领料"){
                  const isValid = (value) => value != null && value !== '';
                  let RefAddProductionMaterialRequisition=this.$refs.refSuiteform.$children[0].$refs.AddProductionMaterialRequisition.$refs
                  // console.log(RefAddProductionMaterialRequisition)
                  RefAddProductionMaterialRequisition.ordersObjRef1.validate(async (valid)=>{
                  //  console.log("生产领料")
                    is_fc_suiteform=validlet 
                    let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
                    let arr = [];
                    let isEmpty = false;
                    dialogFromList.products.forEach((item, i) => {
                        if (isValid(item.product)) {
                          if(isValid(item.quantity)){
                            item.quantity = Number(item.quantity);
                           }
                           arr.push(item);
                        }
                      });
                    isEmpty=this.onIsEmptyQuantity(arr)
                     if (isEmpty) {
                       this.$message.warning("请填写产品数量");
                       is_fc_suiteform=false
                     }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "长期合同"){
                  let RefAddOrders = this.$refs.refSuiteform.$children[0].$refs.AddOrders.$refs;
                  // console.log(RefAddOrders)
                  RefAddOrders.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
                  //  console.log("长期合同")
                    is_fc_suiteform=valid
                    let obj=this.getProductIdAll(data)
                    if (obj.isEmpty) {
                      this.$message.warning("请填写产品数量或参考单价、成交单价");
                      is_fc_suiteform=false
                    }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "售后申请"){
                  const isValid = (value) => value != null && value !== '';
                  let RefundOrder =this.$refs.refSuiteform.$children[0].$refs.RefundOrder.$refs;
                  // console.log(RefundOrder)
                  RefundOrder.refundOrderRef1.validate(async (valid)=>{
                    if(valid){
                      RefundOrder.refundOrderRef2.validate(async (valid)=>{
                        if(valid){
                        //  console.log("售后申请")
                          is_fc_suiteform=valid
                          let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
                          let arr = [];
                          dialogFromList.products.forEach((item, i) => {
                              if (isValid(item.product_id)) {
                                if(isValid(item.quantity)){
                                  item.quantity = Number(item.quantity);
                                 }
                                 if(isValid(item.unit_price)){
                                   item.unit_price = Number(item.unit_price);
                                 }
                                 arr.push(item);
                              }
                            });
                          dialogFromList.content_data=data.content_data
                          data.content_data[this.formData.fields[0].formId]=JSON.stringify(dialogFromList)
                        }
                      })
                    }
                  })
                 }else if(this.formData.fields[0].source == "回款申请"){
                  let BackMoney =this.$refs.refSuiteform.$children[0].$refs.RefBackMoney.$refs;
                  // console.log(BackMoney)
                  BackMoney.backMoneyObjRef1.validate(async (valid)=>{
                  //  console.log("回款申请")
                    is_fc_suiteform=valid
                  })
                 }else if(this.formData.fields[0].source == "采购入库申请"){
                  let refPurchasing =this.$refs.refSuiteform.$children[0].$refs.AddPurchasing.$refs
                  // console.log(refPurchasing)
                  refPurchasing.ProcurementOrders.$refs.ordersObjRef1.validate(async (valid)=>{
                  //  console.log("采购入库申请")
                    is_fc_suiteform=valid 
                    let obj=this.getProductIdQuantityUnitPrice(data,'details');
                     if (obj.isEmpty) {
                       this.$message.warning("请填写产品数量");
                       is_fc_suiteform=false
                     }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "零星申请"){
                  const isValid = (value) => value != null && value !== '';
                  let demandApplicationRef =this.$refs.refSuiteform.$children[0].$refs.AddDemandApplication.$refs
                  // console.log(demandApplicationRef)
                  demandApplicationRef.demandApplicationRef1.validate(async (valid)=>{
                  //  console.log("零星申请")
                    is_fc_suiteform=valid
                    let dialogFromList = JSON.parse(data.content_data[this.formData.fields[0].formId]);
                     let arr = [];
                     let isEmpty = false;
                     dialogFromList.details.forEach((item, i) => {
                         if (isValid(item.product)) {
                           if(isValid(item.quantity)){
                             item.quantity = Number(item.quantity);
                            }
                            if(isValid(item.unit_price)){
                              item.unit_price = Number(item.unit_price);
                            }
                            arr.push(item);
                         }
                       });
                      isEmpty = this.onIsEmptyDemandApplication(arr)
                     if (isEmpty) {
                       this.$message.warning("请填完整添加明细");
                       is_fc_suiteform=false
                     }
                    dialogFromList.content_data=data.content_data
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "采购需求申请"){
                  let purchasingRequisitionRef =this.$refs.refSuiteform.$children[0].$refs.AddPurchasingRequisition.$children[0].$refs
                  // console.log(purchasingRequisitionRef)
                  purchasingRequisitionRef.purchasingRequisitionRef1.validate(async(valid)=>{
                  //  console.log("采购需求申请")
                    is_fc_suiteform=valid
                    let obj=this.onIsEmptyExpenseCategoryAll(data,'details')
                     if (obj.isEmpty) {
                       this.$message.warning("请填完整添加明细");
                       is_fc_suiteform=false
                     }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "采购计划"){
                  let purchasingRequisitionRef =this.$refs.refSuiteform.$children[0].$refs.AddPurchasingRequisition.$children[0].$refs
                  // console.log(purchasingRequisitionRef)
                  purchasingRequisitionRef.purchasingRequisitionRef1.validate(async (valid)=>{
                  //  console.log("采购计划")
                    is_fc_suiteform=valid
                    let obj=this.onIsEmptyExpenseCategoryAll(data,'details')
                     if (obj.isEmpty) {
                       this.$message.warning("请填完整添加明细");
                       is_fc_suiteform=false
                     }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "采购订单"){
                  let purchasingRequisitionRef =this.$refs.refSuiteform.$children[0].$refs.AddPurchasingRequisition.$children[0].$refs
                  // console.log(purchasingRequisitionRef)
                  purchasingRequisitionRef.purchasingRef1.validate(async(valid)=>{
                  //  console.log("采购订单")
                    is_fc_suiteform=valid
                    let obj=this.onIsEmptyExpenseCategoryAll(data,'details')
                    if (obj.isEmpty) {
                      this.$message.warning("请填完整添加明细");
                      is_fc_suiteform=false
                    }
                    data.content_data[this.formData.fields[0].formId]=JSON.stringify(obj.dialogFromList)
                  })
                 }else if(this.formData.fields[0].source == "客户申请"){
                  let addAssignmentRequest=this.$refs.refSuiteform.$children[0].$refs.AddAssignmentRequest.$refs
                  // console.log(addAssignmentRequest)
                  addAssignmentRequest.assignmentRequestFormRef.validate(async (valid) =>{
                  //  console.log("客户申请")
                    is_fc_suiteform=valid
                  })
                 }else if(this.formData.fields[0].source == "供应商分配申请"){
                  let addSupplierAssignment=this.$refs.refSuiteform.$children[0].$refs.AddSupplierAssignment.$refs
                  // console.log(addSupplierAssignment)
                  addSupplierAssignment.assignmentRequestFormRef.validate(async (valid) =>{
                  //  console.log("供应商分配申请")
                    is_fc_suiteform=valid
                  })
                 }
              }else{
                is_fc_suiteform=true
              }
               if(!is_fc_suiteform){
                  instance.confirmButtonLoading = false;
                  done();
                  return 
               }
              //  console.log(data,"1522")
              const res = await this.$http.post("oa/form_operate/", data);
              if (res.status == 200) {
                this.$message.success(res.data);
                if (this.is_signature) {
                  this.submitForm1();
                }
                instance.confirmButtonLoading = false;
                done();
                if(data.remark.length<20){
                  let operationalRecorderObj={
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent",
                    description:"同意",
                    operate_data:{
                      remark_arr:[]
                    },
                  }
                  if(remarkRes.length>0){
                    let remarkArr=[...remarkRes]
                    if(!remarkArr.includes(data.remark)){
                      if(remarkArr.length<5){
                        remarkArr.push(data.remark)
                      }else{
                        remarkArr.splice(0,1)
                        remarkArr.push(data.remark)
                      }
                      operationalRecorderObj.operate_data.remark_arr=remarkArr
                      this.postOperationalRecorder("PUT",operationalRecorderObj)
                    }
                  }else{
                    operationalRecorderObj.operate_data.remark_arr.push(data.remark)
                    this.postOperationalRecorder("POST",operationalRecorderObj) 
                  }
                }
                if (this.isInForm) {
                  this.$emit("onInformConent", false);
                } else {
                  this.$router.push("oahome");
                }
              } else {
                instance.confirmButtonLoading = false;
                done();
                this.$message({
                  type: "失败",
                  message: res.data,
                });
              }
            });
          } else {
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    async delefiles() {
      // console.log(this.filesObj);
      let arr = [];
      for (const item in this.filesObj) {
        // console.log(this.filesObj[item]);
        this.filesObj[item].forEach((items) => {
          arr.push(items);
        });
      }
      const files = await this.$http({
        method: "DELETE",
        url: "oa/oa_form_files_bulk_del/",
        data: arr,
      });
      if (res.status != 204) {
        this.$message.error(res.data);
      }
      // console.log(files, "失败文件");
    },
    onCommentInputChange() {
      this.commentContent = document.getElementById("commentContent").value;
    },
    opinion(i) {
      // console.log(document.getElementById(`btn${i}`).innerHTML);
      this.commentContent = document.getElementById(`btn${i}`).innerHTML;
      document.getElementById("commentContent").value = document.getElementById(`btn${i}`).innerHTML;
    },
    // 撤销
   async revocation() {
      const style ="padding: 5px;margin: 5px;background-color: #dad8d8;border: none;border-radius: 10px;white-space: nowrap;";
      const remarkRes=await this.getOperationalRecorder({
          job_id:window.localStorage.getItem("jobid"),
          sys_name:2,
          url:"/conent",
          description:"撤销"})
      const h = this.$createElement;
      let buttons =[]
      if(remarkRes.length>0){
          remarkRes.forEach((items,i)=>{
            buttons.push({id:('btn'+(i+1)),label:items,opinion:i+1})
          })
        }else{
          buttons =[
            { id: 'btn1', label: '同意', opinion: 1 },
            { id: 'btn2', label: 'OK', opinion: 2 },
            { id: 'btn3', label: '已办理', opinion: 3 },
          ];
        }
      const buttonElements = buttons.map(button =>
        h(
          "button",
          {
            attrs: {
              id: button.id,
              style: style,
            },
            on: {
              click: () => {
                this.opinion(button.opinion);
              },
            },
          },
          button.label
        )
      );
      this.$msgbox({
        title: "审批撤销意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", { style: "" }, [
              h("span", {}, this.form_title+`撤销理由:`),
              h("textarea", {
                attrs: {
                  placeholder: "请输入撤销理由",
                  class: "el-textarea__inner",
                  autocomplete: "off",
                  rows: 3,
                  id: "commentContent",
                },
                value: this.commentContent,
                on: { input: this.onCommentInputChange },
              }),
            ]),
            h(
              "div",
              {
                style: "display: flex;flex-wrap: wrap;",
              },
              buttonElements
            ),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const isTableValid = this.checkTableData();
            const data = this.initiatorrewrite(this.formModel);
            data.operate_type = 8;
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            data.remark = this.commentContent;
            const res = await this.$http.post("oa/form_operate/", data);
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              if(data.remark.length<20){
                  let operationalRecorderObj={
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent",
                    description:"撤销",
                    operate_data:{
                      remark_arr:[]
                    },
                  }
                  if(remarkRes.length>0){
                    let remarkArr=[...remarkRes]
                    if(!remarkArr.includes(data.remark)){
                      if(remarkArr.length<5){
                        remarkArr.push(data.remark)
                      }else{
                        remarkArr.splice(0,1)
                        remarkArr.push(data.remark)
                      }
                      operationalRecorderObj.operate_data.remark_arr=remarkArr
                      this.postOperationalRecorder("PUT",operationalRecorderObj)
                    }
                  }else{
                    operationalRecorderObj.operate_data.remark_arr.push(data.remark)
                    this.postOperationalRecorder("POST",operationalRecorderObj) 
                  }
                }
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
              done();
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
            instance.confirmButtonLoading = false;
          } else {
            done();
          }
        },
      }).then((action) => {});
    },

    noderewrite(dt) {
      const data = { ...dt };
      Object.keys(data).forEach((item) => {
        data[item.substring(5)] = data[item];
        delete data[item];
      });

      this.content_fields.filter((item, i) => {
        if (Object.values(item)[0] == data[i + 1]) {
          data[i + 1] = Object.values(item)[1];
        }
      });
      return {
        content_data: data,
        flow_id: this.flow_id * 1,
        form_id: this.form_id * 1,
        job_id: window.localStorage.getItem("jobid"),
      };
    },
    // 整理数据
    initiatorrewrite(dt) {
      const data = { ...dt };
      Object.keys(data).forEach((item) => {
        for (let j = 0; j < this.formData.fields.length; j++) {
          if (this.formData.fields[j].vModel == item) {
            if (this.formData.fields[j].tag == "fc-autocompletion") {
              for (let i = 0; i < this.content_fields.length; i++) {
                if (this.content_fields[i].formId ==this.formData.fields[j].formId) {
                  data[this.formData.fields[j].formId] =this.content_fields[i].value;
                }
              }
            } else {
              data[this.formData.fields[j].formId] = data[item];
            }
          } else if (this.formData.fields[j].showDivider != undefined) {
            this.formData.fields[j].children.forEach((items, x) => {
              if (items.vModel == item) {
                data[items.formId] = data[item];
              }
            });
          }
        }
        delete data[item];
      });
      // this.content_fields.filter((item, i) => {
      // //  console.log(this.formData.fields[i].showDivider ,this.formData.fields[i],"77777777777777777")
      //   if (this.formData.fields[i] != undefined &&this.formData.fields[i].showDivider == undefined) {
      //     if (this.formData.fields[i].tag == "el-select") {
      //     //  console.log(item,"666666666666666666666")
      //       data[item.formId * 1] = item.store_value;
      //     }
      //   }
      // });
      this.formData.fields.forEach((item) => {
        if (item.tag == "fc-upload") {
          for (const key in data) {
            if (item.formId == key) {
              let arr = [];
              if (typeof data[key]!='string'&&data[key] != null) {
                data[key].forEach((items) => {
                  arr.push(items.id);
                });
              }
              data[key] = arr;
            }
          }
        }
        if (item.layout == "rowFormItem") {
          item.children.forEach((items, x) => {
            if (items.tag == "fc-upload") {
              for (const key in data) {
                if (items.formId == key) {
                  let arr = [];
                  if (data[key] == null) {
                    data[key] = arr;
                  } else {
                    if (data[key].length > 0) {
                      data[key].forEach((items) => {
                        arr.push(items.id);
                      });
                    }
                    data[key] = arr;
                  }
                }
              }
            }
          });
        }
      });
      this.formData.fields.forEach((item) => {
        if (item.showDivider != undefined && item.showRequired) {
          item.children.forEach((items, x) => {
            if (
              data[items.formId] != undefined &&
              data[items.formId] != null &&
              data[items.formId] != ""
            ) {
              if (items.tag == "fc-upload") {
                if (data[items.formId].length > 0) {
                  this.rowObj[item.formId][x] = true;
                } else {
                  this.rowObj[item.formId][x] = false;
                }
              } else {
                // console.log(this.rowObj[item.formId]);
                this.rowObj[item.formId][x] = true;
              }
            } else {
              this.rowObj[item.formId][x] = false;
            }
          });
        }
      });
      this.formData.fields.forEach((item)=>{
        if(item.tag==="fc_suiteform"){
         if(data[item.formId].content_data!=undefined){
          let obj=data[item.formId]
          delete obj.content_data
          data[item.formId]=obj
          //  console.log(obj)
          }
        }
      })
      for (const key in data) {
        if (typeof data[key] === "number") {
          data[key] = data[key].toString();
        }
        if (data[key] === true) {
          data[key] = "True";
        } else if (data[key] === false) {
          data[key] = "False";
        }
      }
       //console.log(data);
      for (const item in data) {
        if (data[item] === undefined) {
          delete data[item];
        }
        if (typeof data[item] !== "string") {
          data[item] = JSON.stringify(data[item]);
        }
      }

     //console.log(data,this.formModel);
      return {
        content_data: data,
        operate_type: 1,
        job_id: window.localStorage.getItem("jobid"),
        remark: "同意",
      };
    },
    //拒绝
    async refusing() {
      const h = this.$createElement;
      const style ="padding: 5px;margin: 5px;background-color: #dad8d8;border: none;border-radius: 10px;white-space: nowrap;";
      const remarkRes=await this.getOperationalRecorder({
          job_id:window.localStorage.getItem("jobid"),
          sys_name:2,
          url:"/conent",
          description:"拒绝"
      })
      let buttons =[]
        if(remarkRes.length>0){
          remarkRes.forEach((items,i)=>{
            buttons.push({id:('btn'+(i+1)),label:items,opinion:i+1})
          })
        }else{
          buttons = [
            { id: 'btn1', label: '不同意', opinion: 1 },
            { id: 'btn2', label: 'NO', opinion: 2 },
            { id: 'btn3', label: '内容不符合要求', opinion: 3 },
          ];
        }
      const buttonElements = buttons.map(button =>
        h(
          "button",
          {
            attrs: {
              id: button.id,
              style: style,
            },
            on: {
              click: () => {
                this.opinion(button.opinion);
              },
            },
          },
          button.label
        )
      );
      this.$msgbox({
        title: "审批拒绝意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", { style: "" }, [
              h("span", {}, "内容:"),
              h("textarea", {
                attrs: {
                  placeholder: "请输入回复内容",
                  class: "el-textarea__inner",
                  autocomplete: "off",
                  rows: 3,
                  id: "commentContent",
                },
                value: this.commentContent,
                on: { input: this.onCommentInputChange },
              }),
            ]),
            h("div", { style: "display: flex;flex-wrap: wrap;" },buttonElements),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            data.remark = this.commentContent;
            data.operate_type = 7;
            const res = await this.$http.post("oa/form_operate/", data);
            if (res.status == 200) {
              this.$message({
                type: "提交成功",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              done();
              if(data.remark.length<20){
                  let operationalRecorderObj={
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent",
                    description:"拒绝",
                    operate_data:{
                      remark_arr:[]
                    },
                  }
                  if(remarkRes.length>0){
                    let remarkArr=[...remarkRes]
                    if(!remarkArr.includes(data.remark)){
                      if(remarkArr.length<5){
                        remarkArr.push(data.remark)
                      }else{
                        remarkArr.splice(0,1)
                        remarkArr.push(data.remark)
                      }
                      operationalRecorderObj.operate_data.remark_arr=remarkArr
                      this.postOperationalRecorder("PUT",operationalRecorderObj)
                    }
                  }else{
                    operationalRecorderObj.operate_data.remark_arr.push(data.remark)
                    this.postOperationalRecorder("POST",operationalRecorderObj) 
                  }
                }
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
          } else {
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    // 数据打印
    toPrint() {
      // console.log(this.content_fields, this.formData, this.timeline);
      let obj = {};
      this.content_fields.forEach((item, index) => {
        if (this.form_title == "订单申请") {
          if (item.formId == 1) {
            obj = item.value;
          } else if (item.formId == 7) {
            obj.huiqian = item.value;
          }
        } else {
          // console.log(item.value[0])
          if (item.formId == 1) {
            obj = item.value;
          } else {
             if (item.value instanceof  Object) {
              obj[item.formId] = item.value.label;
             } else {
              ////  console.log(JSON.parse(item.value))
               obj[item.formId] = item.value;
            }
          }
        }
      });
       // console.log('开始')
       // console.log(this.form_title);
       // console.log(obj);
       // console.log(this.print_Arr);
       // console.log(this.print_setting);
       // console.log(this.advanced_setting);
       // console.log(this.timeline);
       // console.log(this.form_status_label);
       // return
      this.$emit("onToPrint", {
        form_title: this.form_title,
        isPrint: true,
        data: obj,
        arr: this.print_Arr,
        print_setting: this.print_setting,
        advanced_setting: this.advanced_setting,
        signatureArr: this.timeline,
        form_status_label: this.form_status_label,
      });
    },
    // 保存title和字段
    saveTag() {
      let arr = [];
      this.$nextTick(() => {
        // console.log(this.$refs.refSuiteform, this.formData.fields[0]);
        if (
          this.formData.fields[0].tag == "fc_suiteform" &&
          this.formData.fields[0].source == "订单申请"
        ) {
          let RefAddOrders =
            this.$refs.refSuiteform.$children[0].$refs.AddOrders.$refs;
          // console.log("进来");
          // console.log(RefAddOrders);
          RefAddOrders.ordersObjRef1.$children.forEach((item, index) => {
            let obj = {
              title: item.label,
              vModel: item.prop,
            };
            arr.push(obj);
          });
          RefAddOrders.ordersObjRef2.$children.forEach((item, index) => {
            let obj = {
              title: item.label,
              vModel: item.prop,
            };
            arr.push(obj);
          });
          RefAddOrders.ordersObjRef3.$children.forEach((item, index) => {
            let obj = {
              title: item.label,
              vModel: item.prop,
            };
            arr.push(obj);
          });
          let arr1 = [];
          RefAddOrders.table_ref.$children.forEach((item, index) => {
            if (item.prop != undefined && index > 1) {
              let obj = {
                title: item.label,
                vModel: item.prop,
              };
              arr1.push(obj);
            }
          });
          // console.log("进来了");
          arr.arr1 = arr1;
        }
        // console.log(arr);
      });
      // console.log(arr, "1111");
      return arr;
    },
    // 退回上一步
    async resetForm() {
      const h = this.$createElement;
      const style = "padding: 5px;margin: 5px;background-color: #dad8d8;border: none;border-radius: 10px;white-space: nowrap;";
      const remarkRes=await this.getOperationalRecorder({
          job_id:window.localStorage.getItem("jobid"),
          sys_name:2,
          url:"/conent",
          description:"退回上一步"
      })
      let buttons =[];
        if(remarkRes.length>0){
          remarkRes.forEach((items,i)=>{
            buttons.push({id:('btn'+(i+1)),label:items,opinion:i+1})
          })
        }else{
          buttons = [
            { id: 'btn1', label: '不同意', opinion: 1 },
            { id: 'btn2', label: 'NO', opinion: 2 },
            { id: 'btn3', label: '内容不符合要求', opinion: 3 },
          ];
        }
  
      const buttonElements = buttons.map(button =>
        h(
          "button",
          {
            attrs: {
              id: button.id,
              style: style,
            },
            on: {
              click: () => {
                this.opinion(button.opinion);
              },
            },
          },
          button.label
        )
      );
      this.$msgbox({
        title: "审批退回意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", { style: "" }, [
              h("span", {}, "内容:"),
              h("textarea", {
                attrs: {
                  placeholder: "请输入回复内容",
                  class: "el-textarea__inner",
                  autocomplete: "off",
                  rows: 3,
                  id: "commentContent",
                },
                value: this.commentContent,
                on: { input: this.onCommentInputChange },
              }),
            ]),
            h("div", { style: "display: flex;flex-wrap: wrap;" },buttonElements),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            data.remark = this.commentContent;
            data.operate_type = 2;
            const res = await this.$http.post("oa/form_operate/", data);
            // console.log(res.data);
            if (res.status == 200) {
              this.$message({
                type: "提交成功",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              done();
              if(data.remark.length<20){
                  let operationalRecorderObj={
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent",
                    description:"退回上一步",
                    operate_data:{
                      remark_arr:[]
                    },
                  }
                  if(remarkRes.length>0){
                    let remarkArr=[...remarkRes]
                    if(!remarkArr.includes(data.remark)){
                      if(remarkArr.length<5){
                        remarkArr.push(data.remark)
                      }else{
                        remarkArr.splice(0,1)
                        remarkArr.push(data.remark)
                      }
                      operationalRecorderObj.operate_data.remark_arr=remarkArr
                      this.postOperationalRecorder("PUT",operationalRecorderObj)
                    }
                  }else{
                    operationalRecorderObj.operate_data.remark_arr.push(data.remark)
                    this.postOperationalRecorder("POST",operationalRecorderObj) 
                  }
                }
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
            // });
          } else {
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    // 退回
    async sendBack() {
      const data = {};
      data.operate_type = 31;
      data.flow_id = this.flow_id;
      data.form_id = this.form_id;
      data.node_id = this.currentid;
      data.counter_idx = this.counter_idx;
      data.job_id = window.localStorage.getItem("jobid");
      const res = await this.$http.post("oa/form_operate/", data);
      this.optionList = res.data;
      const h = this.$createElement;
      const style ="padding: 5px;margin: 5px;background-color: #dad8d8;border: none;border-radius: 10px;white-space: nowrap;";
      const remarkRes=await this.getOperationalRecorder({
          job_id:window.localStorage.getItem("jobid"),
          sys_name:2,
          url:"/conent",
          description:"退回"
      })
      let buttons =[]
        if(remarkRes.length>0){
          remarkRes.forEach((items,i)=>{
            buttons.push({id:('btn'+(i+1)),label:items,opinion:i+1})
          })
        }else{
          buttons = [
            { id: 'btn1', label: '内容不符合要求', opinion: 1 },
            { id: 'btn2', label: '信息错误', opinion: 2 },
            { id: 'btn3', label: '已办理', opinion: 3 },
          ];
        }
      const buttonElements = buttons.map(button =>
        h(
          "button",
          {
            attrs: {
              id: button.id,
              style: style,
            },
            on: {
              click: () => {
                this.opinion(button.opinion);
              },
            },
          },
          button.label
        )
      );
      const that = this;
      this.$msgbox({
        title: "审批退回意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h(
              "div",
              {
                style: "",
              },
              [
                h("span", {}, "内容:"),
                h("textarea", {
                  attrs: {
                    placeholder: "请输入退回理由",
                    class: "el-textarea__inner",
                    autocomplete: "off",
                    rows: 3,
                    id: "commentContent",
                  },
                  value: this.commentContent,
                  on: { input: this.onCommentInputChange },
                }),
              ]
            ),
            h("div", {}, [
              h("p", {}, "退回人:"),
              h(
                "el-select",
                {
                  attrs: {
                    id: "selectbox",
                    style: "width :100%;",
                  },
                  value: that.value,
                  on: {
                    change: (e) => {
                      // console.log(e);
                      this.approver_id = e.approver_id;
                      document.getElementById("selectbox").value =
                        e.approver_user_name; // select下拉框值改变，回显到页面上
                    },
                  },
                },
                [
                  this.optionList.map((it) => {
                    return h("el-option", {
                      props: {
                        label: it.approver_user_name + it.node_name,
                        key: it,
                        value: it,
                      },
                    });
                  }),
                ]
              ),
            ]),
            h(
              "div",
              {
                style: "display: flex;flex-wrap: wrap;",
              },
              buttonElements
            ),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.remark = this.commentContent;
            data.operate_type = 3;
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            data.approver_id = this.approver_id;
            delete data.content_data;
            if(this.approver_id==null||this.approver_id==''||this.approver_id==undefined){
              instance.confirmButtonLoading = false;
              return this.$message.warning('请选择退回人')
            }
            const res = await this.$http.post("oa/form_operate/", data);
            if (res.status == 200) {
              this.$message({
                type: "提交成功",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              done();
              if(data.remark.length<20){
                  let operationalRecorderObj={
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent",
                    description:"退回",
                    operate_data:{
                      remark_arr:[]
                    },
                  }
                  if(remarkRes.length>0){
                    let remarkArr=[...remarkRes]
                    if(!remarkArr.includes(data.remark)){
                      if(remarkArr.length<5){
                        remarkArr.push(data.remark)
                      }else{
                        remarkArr.splice(0,1)
                        remarkArr.push(data.remark)
                      }
                      operationalRecorderObj.operate_data.remark_arr=remarkArr
                      this.postOperationalRecorder("PUT",operationalRecorderObj)
                    }
                  }else{
                    operationalRecorderObj.operate_data.remark_arr.push(data.remark)
                    this.postOperationalRecorder("POST",operationalRecorderObj) 
                  }
                }
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
              
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
            // });
          } else {
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    // 转交
    CareOf() {
      const h = this.$createElement;
      this.$msgbox({
        title: "审批转交意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", {}, [
              h("span", {}, "转交人:"),
              h(
                orgSelect,
                {
                  ref: "orgSelectCareOfRef",
                  on: {
                    input: (data) => {
                      this.Apostille_data = data[0];
                      // console.log(data[0]);
                    },
                  },
                },
                []
              ),
            ]),
            h(
              "div",
              {
                style: "",
              },
              [
                h("span", {}, "内容:"),
                h("textarea", {
                  attrs: {
                    placeholder: "请输入转交理由",
                    class: "el-textarea__inner",
                    autocomplete: "off",
                    rows: 3,
                    id: "commentContent",
                  },
                  value: this.commentContent,
                  on: { input: this.onCommentInputChange },
                }),
              ]
            ),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.operate_type = 5;
            data.pass_job_id = this.Apostille_data.job_id;
            data.remark = this.commentContent;
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            delete data.content_data;
            // console.log(data);
            const res = await this.$http.post("oa/form_operate/", data);
            // console.log(res);
            if (res.status == 200) {
              this.$message({
                type: "转交成功",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
              this.Apostille_data = {};
            this.$refs.orgSelectCareOfRef.innerValue = [];
            this.$refs.orgSelectCareOfRef.$refs.transfer.$refs.tree.setCheckedKeys(
              []
            );
            this.$refs.orgSelectCareOfRef.$refs.transfer.$refs.tree.selecte =
              [];
              done();
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
            // });
          } else {
            this.Apostille_data = {};
            this.$refs.orgSelectCareOfRef.innerValue = [];
            this.$refs.orgSelectCareOfRef.$refs.transfer.$refs.tree.setCheckedKeys(
              []
            );
            this.$refs.orgSelectCareOfRef.$refs.transfer.$refs.tree.selecte =
              [];
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    // 加签
    async Apostille() {
      const h = this.$createElement;
      this.$msgbox({
        title: "审批加签意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", {}, [
              h("span", {}, "加签人:"),
              h(
                orgSelect,
                {
                  ref: "orgSelectApostilleRef",
                  on: {
                    input: (data) => {
                      this.Apostille_data = data[0];
                      // console.log(data[0]);
                    },
                  },
                },
                []
              ),
            ]),
            h(
              "div",
              {
                style: "",
              },
              [
                h("span", {}, "内容:"),
                h("textarea", {
                  attrs: {
                    placeholder: "请输入加签理由",
                    class: "el-textarea__inner",
                    autocomplete: "off",
                    rows: 3,
                    id: "commentContent",
                  },
                  value: this.commentContent,
                  on: { input: this.onCommentInputChange },
                }),
              ]
            ),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.remark = this.commentContent;
            data.operate_type = 4;
            data.counter_job_id = this.Apostille_data.job_id;
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            delete data.content_data;
            const res = await this.$http.post("oa/form_operate/", data);
            // console.log(res);
            if (res.status == 200) {
              this.$message({
                type: "加签成功",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
              this.Apostille_data = {};
            this.$refs.orgSelectApostilleRef.$refs.transfer.$refs.tree.setCheckedKeys(
              []
            );
            this.$refs.orgSelectApostilleRef.$refs.transfer.$refs.tree.selecte =
              [];
            this.$refs.orgSelectApostilleRef.innerValue = [];
              done();
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
            // });
          } else {
            this.Apostille_data = {};
            this.$refs.orgSelectApostilleRef.$refs.transfer.$refs.tree.setCheckedKeys(
              []
            );
            this.$refs.orgSelectApostilleRef.$refs.transfer.$refs.tree.selecte =
              [];
            this.$refs.orgSelectApostilleRef.innerValue = [];
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    // @ 获取组织数据
    async getPersonnel() {
      const res = await this.$http({
        method: "GET",
        url: "pm/get_hugtech_organization/",
      });
      this.mockData = res.data.res_user_data;
    },
    // 评论
    comment() {
      const h = this.$createElement;
      this.$msgbox({
        title: "审批评论",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h(
              "div",
              {
                style: "",
              },
              [
                h("span", {}, "评论:"),
                // h("textarea", {
                //   attrs: {
                //     placeholder: "请输入评论内容",
                //     class: "el-textarea__inner",
                //     autocomplete: "off",
                //     rows: 3,
                //     id: "commentContent",
                //   },
                //   value: this.commentContent,
                //   on: { input: this.onCommentInputChange },
                // }),
                h(InputBox, {
                  props: {
                    mockData: this.mockData,
                    isInput: this.isInput,
                  },
                  on: {
                    onInputBox: (data) => {
                      this.inputList = data;
                    },
                  },
                }),
              ]
            ),
            h(
              "div",
              {
                style: "",
              },
              [
                h("span", {}, "附件:"),
                h(
                  "el-upload",
                  {
                    style: "margin:10px 0",
                    attrs: {
                      action: "",
                      accept: "image/*",
                      multiple: true,
                      "auto-upload": false,
                    },
                    nativeOn: {
                      change: ($event) => {
                        this.fileflag = true;
                        const file = $event.target.files;
                        for (let i = 0; i < file.length; i++) {
                          this.files.push(file[i]);
                        }
                        // console.log(this.files);
                      },
                    },
                  },
                  "选择图片"
                ),
                h(
                  "el-upload",
                  {
                    attrs: {
                      action: "",
                      multiple: true,
                      "auto-upload": false,
                    },
                    nativeOn: {
                      change: ($event) => {
                        this.fileflag = true;
                        const file = $event.target.files;
                        // console.log(file);
                        for (let i = 0; i < file.length; i++) {
                          this.files.push(file[i]);
                        }
                      },
                    },
                  },
                  "选择文件"
                ),
              ]
            ),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          // document.getElementById("commentContent").value = null;
          // console.log(this.addArr);
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.approve_content = this.inputList.content;
            data.remark = this.inputList.content;
            data.operate_type = 6;
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            delete data.content_data;
            // console.log(data);
            const res = await this.$http.post("oa/form_operate/", data);
            // console.log(res.data.comment_id);
            if (res.status == 200) {
              // console.log(this.inputList);
              // console.log(this.inputList.userList);
              // console.log(this.fileflag);
              // console.log(this.files);
              // return
              if (this.inputList.userList !== undefined) {
                if (this.inputList.userList.length > 0) {
                  let AtArr = [];
                  this.inputList.userList.forEach((items) => {
                    //console.log(items);
                    let data = {};
                    data.comment_object = {
                      model: "OAFlowComment",
                      value: res.data.comment_id,
                    };
                    data.interaction_type_object = {
                      model: "OAFlowForm",
                      value: this.form_id,
                    };
                    data.initiator = window.localStorage.getItem("jobid");
                    data.target = items.job_id;
                    AtArr.push(data);
                    //data.form = this.form_id;
                    //data.comment_id = res.data.comment_id;
                    //data.initiator = window.localStorage.getItem("jobid");
                    //data.target = items.job_id;
                  });
                  this.pushManager(AtArr);
                }
              }

              // this.this.commentContent = null
              if (this.fileflag) {
                // console.log(this.node_id, this.currentid);
                const formdata = new FormData();
                formdata.append("flow_id", this.flow_id);
                formdata.append("form_id", this.form_id);
                formdata.append("node_id", this.currentid);
                this.files.forEach((item) => {
                  formdata.append("files", item);
                });
                formdata.append("comment_id", res.data.comment_id);
                // console.log(this.files[0].name.split('.')[1]);
                if (
                  this.files[0].name.split(".")[1] == "jpg" ||
                  this.files[0].name.split(".")[1] == "webp" ||
                  this.files[0].name.split(".")[1] == "png" ||
                  this.files[0].name.split(".")[1] == "jpeg"
                ) {
                  const file = await this.$http({
                    method: "POST",
                    url: "oa/oa_form_add_images/",
                    headers: {
                      "Content-Type": "multipart/form-data;",
                    },
                    data: formdata,
                  });
                  this.$message({
                    type: "success",
                    message: file.data,
                  });
                  instance.confirmButtonLoading = false;
                  if (this.isInForm) {
                    this.$emit("onInformConent", false);
                  } else {
                    this.$router.push("oahome");
                  }
                  done();
                } else {
                  const file = await this.$http({
                    method: "POST",
                    url: "oa/oa_form_add_files/",
                    headers: {
                      "Content-Type": "multipart/form-data;",
                    },
                    data: formdata,
                  });
                  this.$message({
                    type: "success",
                    message: file.data,
                  });
                  instance.confirmButtonLoading = false;
                  if (this.isInForm) {
                    this.$emit("onInformConent", false);
                  } else {
                    this.$router.push("oahome");
                  }
                  done();
                }
              } else {
                this.$message({
                  type: "success",
                  message: res.data.result_message,
                });
                instance.confirmButtonLoading = false;
                if (this.isInForm) {
                  this.$emit("onInformConent", false);
                } else {
                  this.$router.push("oahome");
                }
                done();
              }
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }

            // });
          } else {
             instance.confirmButtonLoading = false;
            this.inputList = null;
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },
    async onHastenWork(){
      const isValid = (value) => value != null && value !== '';
      const h = this.$createElement;
      const style ="padding: 5px;margin: 5px;background-color: #dad8d8;border: none;border-radius: 10px;white-space: nowrap;";
      const remarkRes=await this.getOperationalRecorder({
          job_id:window.localStorage.getItem("jobid"),
          sys_name:2,
          url:"/conent",
          description:"催办"
      })
      const information=await this.$http({
        method:"GET",
        url:"configuration/operational_recorder/",
        params:{
          job_id:window.localStorage.getItem("jobid"),
          sys_name:2,
          url:"/conent"+this.form_id,
          description:"催办消息",
        },
      })
      let isNum=null
      if(isValid(information.data[0])){
        let num =new Date().getTime()
        let num1=information.data[0].operate_data.time
        let num2=num-num1
        if(num2>60000){
          isNum=false
        }else{
          isNum=true
        }
      }
      if(isNum){
        return this.$message.warning("请稍后催办");
      }
      let buttons =[]
        if(remarkRes.length>0){
          remarkRes.forEach((items,i)=>{
            buttons.push({id:('btn'+(i+1)),label:items,opinion:i+1})
          })
        }else{
          buttons = [
            { id: 'btn1', label: '请审批', opinion: 1 },
          ];
        }
      const buttonElements = buttons.map(button =>
        h(
          "button",
          {
            attrs: {
              id: button.id,
              style: style,
            },
            on: {
              click: () => {
                this.opinion(button.opinion);
              },
            },
          },
          button.label
        )
      );
      this.$msgbox({
        title: "催办意见",
        message: h(
          "div",
          {
            attrs: {
              style: "",
            },
          },
          [
            h("div", { style: "" }, [
              h("span", {}, "内容:"),
              h("textarea", {
                attrs: {
                  placeholder: "请输入回复内容",
                  class: "el-textarea__inner",
                  autocomplete: "off",
                  rows: 3,
                  id: "commentContent",
                },
                value: this.commentContent,
                on: { input: this.onCommentInputChange },
              }),
            ]),
            h("div", { style: "display: flex;flex-wrap: wrap;" },buttonElements),
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          document.getElementById("commentContent").value = null;
          if (action === "confirm") {
            // 点击确定 要做的事情
            instance.confirmButtonLoading = true;
            const data = this.initiatorrewrite(this.formModel);
            data.flow_id = this.flow_id;
            data.form_id = this.form_id;
            data.node_id = this.currentid;
            data.counter_idx = this.counter_idx;
            data.remark = this.commentContent;
            data.operate_type = 10;
            delete data.content_data
            const res = await this.$http.post("oa/form_operate/", data);
            if (res.status == 200) {
              this.$message({
                type: "提交成功",
                message: res.data,
              });
              instance.confirmButtonLoading = false;
              done();
              if(isValid(information.data[0])){
                const postTime=await this.$http({
                  method:"PUT",
                  url:"configuration/operational_recorder/",
                  data:{
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent"+this.form_id,
                    description:"催办消息",
                    operate_data:{
                      time:new Date().getTime(),
                    }
                  }
                })
              }else{
                const postTime=await this.$http({
                  method:"POST",
                  url:"configuration/operational_recorder/",
                  data:{
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent"+this.form_id,
                    description:"催办消息",
                    operate_data:{
                      time:new Date().getTime(),
                    }
                  }
                })
              }
              if(data.remark.length<20){
                  let operationalRecorderObj={
                    job_id:window.localStorage.getItem("jobid"),
                    sys_name:2,
                    url:"/conent",
                    description:"催办",
                    operate_data:{
                      remark_arr:[]
                    },
                  }
                  if(remarkRes.length>0){
                    let remarkArr=[...remarkRes]
                    if(!remarkArr.includes(data.remark)){
                      if(remarkArr.length<5){
                        remarkArr.push(data.remark)
                      }else{
                        remarkArr.splice(0,1)
                        remarkArr.push(data.remark)
                      }
                      operationalRecorderObj.operate_data.remark_arr=remarkArr
                      this.postOperationalRecorder("PUT",operationalRecorderObj)
                    }
                  }else{
                    operationalRecorderObj.operate_data.remark_arr.push(data.remark)
                    this.postOperationalRecorder("POST",operationalRecorderObj) 
                  }
                }
              if (this.isInForm) {
                this.$emit("onInformConent", false);
              } else {
                this.$router.push("oahome");
              }
            } else {
              instance.confirmButtonLoading = false;
              this.$message({
                type: "失败",
                message: res.data,
              });
            }
          } else {
            done();
          }
        },
      }).then((action) => {
        // this.$message({
        //   type: "info",
        //   message: "message",
        // });
      });
    },

    // @请求
    async pushManager(val) {
      const res = await this.$http({
        method: "POST",
        url: "configuration/at_someone/",
        data: val,
      });
      // console.log(res);
    },
    resetTableData() {
      Object.keys(this.tableRefs).forEach((vModel) => {
        // console.log(vModel);
        // 由于render.js返回的动态组件 所以动态组件相当于是render的子组件
        const res = this.$refs[vModel].$children[0].reset();
      });
    },
    // 下载
    createImg() {
      let arr = document.querySelectorAll(".hidden");
      arr.forEach((item) => {
        item.style.display = "none";
      });
      let content = this.$refs.content;
      // console.log(content);
      let scrollHeight = content.scrollHeight + 200;
      let scrollWidth = content.scrollWidth + 200;
      html2canvas(content, {
        scale: window.devicePixelRatio * 2,
        useCORS: true, //开启跨域配置，但和allowTaint不能共存
        width: scrollWidth,
        height: scrollHeight,
        // windowWidth: scrollWidth,
        // windowHeight: scrollHeight,
        // logging: true,
        x: -100,
        y: -50,
      }).then((canvas) => {
        this.operType = "edit";
        let dataURL = canvas.toDataURL("image/jpg");
        let link = document.createElement("a");
        link.href = dataURL;
        let filename = `${new Date().getTime()}.jpg`; //文件名称
        link.setAttribute("download", filename);
        link.style.display = "none"; //a标签隐藏
        document.body.appendChild(link);
        link.click();
        arr.forEach((item) => {
          item.style.display = "block";
        });
      });
    },

    checkTableData() {
      let valid = true;
      Object.keys(this.tableRefs).forEach((vModel) => {
        const res = this.$refs[vModel].$children[0].submit(); // 返回false或表单数据
        res ? (this.formModel[vModel] = res) : (valid = false);
      });
      return valid;
    },

    showExplain(explainText, title) {
      if (!explainText) return;
      this.drawerTitle = title;
      this.drawerText = explainText;
      this.drawerVisible = true;
    },
    buildForm(h) {
      const labelPosition = top;
      const content = this.confGlobal.fields.map((c) =>
        layouts[c.layout](c, h, this)
      );
      const title1 = (
        <el-col span={24}>
          <div>
            <div class="approve_number">
              <p>审批编号：{this.approve_number}</p>
            </div>
            <div style="display:flex;justify-content:space-between;align-items:end;margin-bottom:10px">
              <h1 class="form_title">
                {this.initiator_name}提交的{this.form_title}
              </h1>
              <div
                style="font-size:12px;"
                class={this.form_status_label == "拒绝" ? "red" : "ger"}
              >
                <span>
                  {this.current_approver_title}
                  {this.form_status_label}
                </span>
              </div>
            </div>
          </div>
        </el-col>
      );
      const title2 = (
        <el-col span={24}>
          <div>
            <div class="approve_number">
              <p>审批编号：{this.approve_number}</p>
              <p style="display:flex;">
                <el-tooltip
                  class="item"
                  effect="light"
                  content="再次提交"
                  placement="top"
                >
                  <el-button type="text" onClick={this.onAgainResubmit} class="print">
                    <i class="iconfont icon-yifaqi"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="light"
                  content="打印"
                  placement="top"
                >
                  <el-button type="text" v-print={this.printobj} class="print">
                    <i class="iconfont icon-invoice"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="light"
                  content="下载"
                  placement="top"
                >
                  <el-button type="text" onClick={this.createImg} class="print">
                    <i class="iconfont icon-download"></i>
                  </el-button>
                </el-tooltip>
              </p>
            </div>
            <div style="display:flex;justify-content:space-between;align-items:end;margin-bottom:10px">
              <h1 class="form_title">
                {this.initiator_name}提交的{this.form_title}
              </h1>
              <div
                style="font-size:12px;"
                class={this.form_status_label == "已通过" ?  "status_font_over"  :this.form_status_label == "已拒绝" ?  "status_font_red"  :this.form_status_label == "已撤销" ?  "status_font_gray"  :"status_font_default"}
              >
                <span>
                  {this.form_status_label == '已撤销'?'发起人':this.current_approver_title}
                  {this.form_status_label}
                </span>
              </div>
            </div>
          </div>
        </el-col>
      );
      const title = this.size == 0 ? title1 : title2;
      const formObject = {
        props: {
          model: this.formModel,
          rules: this.ruleList,
          size: this.confGlobal.size,
          labelWidth: this.confGlobal.labelWidth + "px",
          labelPosition: "top",
        },
        attrs: {
          id: "prints",
        },
        on: {
          // validate: this.submitnode
        },
        ref: this.confGlobal.formRef,
      };
      const flow = (
        <el-col span={24}>
          <el-form-item label="流程" style="margin-bottom:100px">
            <approvalsnode
              v-on:approverdelete={this.approverdelete}
              v-on:onIsSignature={this.onIsSignature}
              timeline={this.timeline}
              isSignature={this.is_signature}
              currentid={this.currentid}
              counter_idx={this.counter_idx}
              signatureUrl={this.signatureUrl}
            ></approvalsnode>
          </el-form-item>
        </el-col>
      );
      let isName = this.current_approver.includes(this.currentName);
      {
        /* console.log(this.current_approver)
    //  console.log(this.currentName)
    //  console.log(isName) */
      }
      const btns2 = (
        <el-col
          span={24}
          style="position:fixed;left:15px; bottom:0px; background-color:#FFFFFF; z-index:1999;width:92%"
        >
          <el-form-item>
            <div style=" box-sizing: border-box;display:flex; justify-content:space-between;align-items:center; overflow: auto;">
              <div style="display:flex;align-items:center;">
                <el-popover class="hidden" popper-class="popperhidden" placement="top" trigger="click">
                  <div style="display:flex;flex-direction:column;">
                    <el-button
                      type="text"
                      disabled={this.isSendBack}
                      class="btn"
                      style="margin:0 !important;"
                      onClick={this.sendBack}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-download"
                        style="font-size:30px ;"
                      ></i>
                      <p>退回</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      disabled={this.isApostille}
                      class="btn"
                      style="margin:0 !important;"
                      onClick={this.Apostille}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-check-fill"
                        style="font-size:30px ;"
                      ></i>
                      <p>加签</p></div>
                    </el-button>
                    <el-button type="text" class="btn"  style="margin:0 !important;" onClick={this.comment}>
                      <div style="display:flex;align-items: center;"><i
                        class="el-icon-chat-dot-square"
                        style="font-size:30px ;"
                      ></i>
                      <p>评论</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      class="btn"
                      style="margin:0 !important;"
                      disabled={this.isCareOf}
                      onClick={this.CareOf}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"> <i
                        class="iconfont icon-customer-certified"
                        style="font-size:30px;"
                      ></i>
                      <p> 转交</p>
                     </div>
                    </el-button>
                    <el-button
                      type="text"
                      class="btn"
                      style="margin:0 !important;"
                      disabled={this.revocationflag}
                      onClick={this.revocation}
                       v-show={ this.form_status==6?false:true}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont  icon-return"
                        style="font-size:30px;"
                      ></i>
                      <p>撤销</p></div>
                    </el-button>
                     <el-button
                      type="text"
                      style="margin:0 !important;"
                      v-show={this.isReboot }
                      class="rcoa hidden"
                      onClick={this.onReboot}
                    >
                    <div style="display:flex;align-items: center;"><i class="iconfont icon-loading" style="font-size:30px;"></i>
                      <p>重启流程</p></div>
                    </el-button>
                  </div>
                  <div slot="reference">
                    <el-button
                      type="text"
                      style="padding:5px 15px;color:#000000;"
                    >
                      <i
                        class="iconfont icon-more"
                        style="font-size:30px ;"
                      ></i>
                      <p>更多</p>
                    </el-button>
                  </div>
                </el-popover>
              </div>
              <div style="display:flex; align-items:center;">
                <el-button
                  class="hidden"
                  disabled={this.isResetForm}
                  onClick={this.resetForm}
                  v-show={ this.form_status==6?true:isName}
                >
                {this.form_status==6?this.form_title+'撤销退回上一步':'退回上一步'}
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  onClick={this.refusing}
                  v-show={ this.form_status==6?false:isName}
                >
                  拒绝
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  type="primary"
                  onClick={this.submitForm}
                  v-show={ this.form_status==6?false:isName}
                >
                  同意
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      );
      const btns4 = (
        <el-col
          span={24}
          style="background-color:#FFFFFF;"
        >
          <el-form-item>
            <div style=" box-sizing: border-box;display:flex; justify-content:space-between;align-items:center; overflow: auto;">
              <div style="display:flex;align-items:center;">
                <el-popover class="hidden" popper-class="popperhidden" placement="top" trigger="click">
                  <div style="display:flex;flex-direction:column;">
                    <el-button
                      type="text"
                      disabled={this.isSendBack}
                      class="btn"
                      style="margin:0 !important;"
                      onClick={this.sendBack}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-download"
                        style="font-size:30px ;"
                      ></i>
                      <p>退回</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      disabled={this.isApostille}
                      class="btn"
                      style="margin:0 !important;"
                      onClick={this.Apostille}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-check-fill"
                        style="font-size:30px ;"
                      ></i>
                      <p>加签</p></div>
                    </el-button>
                    <el-button type="text" class="btn"  style="margin:0 !important;" onClick={this.comment}>
                      <div style="display:flex;align-items: center;"><i
                        class="el-icon-chat-dot-square"
                        style="font-size:30px ;"
                      ></i>
                      <p>评论</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      class="btn"
                      style="margin:0 !important;"
                      disabled={this.isCareOf}
                      onClick={this.CareOf}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"> <i
                        class="iconfont icon-customer-certified"
                        style="font-size:30px;"
                      ></i>
                      <p> 转交</p>
                     </div>
                    </el-button>
                    <el-button
                      type="text"
                      class="btn"
                      style="margin:0 !important;"
                      disabled={this.revocationflag}
                      onClick={this.revocation}
                       v-show={ this.form_status==6?false:true}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont  icon-return"
                        style="font-size:30px;"
                      ></i>
                      <p>撤销</p></div>
                    </el-button>
                     <el-button
                      type="text"
                      style="margin:0 !important;"
                      v-show={this.isReboot }
                      class="rcoa hidden"
                      onClick={this.onReboot}
                    >
                    <div style="display:flex;align-items: center;"><i class="iconfont icon-loading" style="font-size:30px;"></i>
                      <p>重启流程</p></div>
                    </el-button>
                  </div>
                  <div slot="reference">
                    <el-button
                      type="text"
                      style="padding:5px 15px;color:#000000;"
                    >
                      <i
                        class="iconfont icon-more"
                        style="font-size:30px ;"
                      ></i>
                      <p>更多</p>
                    </el-button>
                  </div>
                </el-popover>
              </div>
              <div style="display:flex; align-items:center;">
                <el-button
                  class="hidden"
                  disabled={this.isResetForm}
                  onClick={this.resetForm}
                  v-show={ this.form_status==6?true:isName}
                >
                {this.form_status==6?this.form_title+'撤销退回上一步':'退回上一步'}
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  onClick={this.refusing}
                  v-show={ this.form_status==6?false:isName}
                >
                  拒绝
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  type="primary"
                  onClick={this.submitForm}
                  v-show={ this.form_status==6?false:isName}
                >
                  同意
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      );
      const btns = (
        <el-col
          span={24}
          style="position:fixed;right:1%; bottom:0px; background-color:#FFFFFF; z-index:9999;width:49%"
        >
          <el-form-item>
            <div style=" box-sizing: border-box;display:flex; justify-content:space-between;align-items:center; ">
              <div style="display:flex;align-items:center;">
                <el-button
                  v-show={ this.form_status==6?false:isName}
                  type="text"
                  disabled={this.isCareOf}
                  onClick={this.CareOf}
                  class="rcoa  hidden"
                >
                  <i
                    class="iconfont icon-customer-certified"
                    style="font-size:30px;"
                  ></i>
                  <p>转交</p>
                </el-button>
                <el-button
                  type="text"
                  disabled={this.revocationflag}
                  onClick={this.revocation}
                  class="rcoa hidden"
                   v-show={ this.form_status==6?false:true}
                >
                  <i class="iconfont icon-return" style="font-size:30px;"></i>
                  <p>撤销</p>
                </el-button>
                 
                <el-popover class="hidden" popper-class="popperhidden" placement="top" trigger="click">
                  <div style="display:flex;flex-direction:column; cursor: pointer;">
                    <el-button
                      type="text"
                      style="margin:0 !important;"
                      disabled={this.isSendBack}
                      class="btn hidden"
                      onClick={this.sendBack}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-download"
                        style="font-size:30px ;"
                      ></i>
                      <p>退回</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      style="margin:0 !important;"
                      disabled={this.isApostille}
                      class="btn hidden"
                      onClick={this.Apostille}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-check-fill"
                        style="font-size:30px ;"
                      ></i>
                      <p>加签</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      style="margin:0 !important;"
                      class="btn hidden"
                      onClick={this.comment}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="el-icon-chat-dot-square"
                        style="font-size:30px ;"
                      ></i>
                      <p>评论</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      style="margin:0 !important;"
                      class="btn hidden"
                      onClick={this.onHastenWork}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="el-icon-chat-dot-square"
                        style="font-size:30px ;"
                      ></i>
                      <p>催办</p></div>
                    </el-button>
                  </div>
                  <div slot="reference">
                    <el-button
                      type="text"
                      style="padding:5px 15px;color:#000000;"
                    >
                      <i
                        class="iconfont icon-more"
                        style="font-size:30px ;"
                      ></i>
                      <p>更多</p>
                    </el-button>
                  </div>
                </el-popover>
                <el-button
                  v-show={this.isPrint}
                  class="hidden"
                  type="text"
                  plain
                  onClick={this.toPrint}
                  style="padding:5px 15px; color:#000000; "
                >
                  <i class="iconfont icon-invoice" style="font-size:30px;"></i>
                  <p>打印</p>
                </el-button>
              </div>
              <div style="display:flex; align-items:center;">
                <el-button
                  type="text"
                  v-show={this.isReboot }
                  class="rcoa hidden"
                  onClick={this.onReboot}
                >
                  <i class="iconfont icon-loading" style="font-size:30px;"></i>
                  <p>重启流程</p>
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.isResetForm}
                  onClick={this.resetForm}
                  v-show={ this.form_status==6?true:isName}
                >
                  {this.form_status==6?this.form_title+'撤销退回上一步':'退回上一步'}
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  onClick={this.refusing}
                  v-show={ this.form_status==6?false:isName}
                >
                  拒绝
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  type="primary"
                  onClick={this.submitForm}
                  v-show={ this.form_status==6?false:isName}
                >
                  同意
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      );
      const btns1 = (
        <el-col
          span={24}
          style="position:fixed;right:1.5%; bottom:0px; background-color:#FFFFFF; ;z-index:9999;width:98%"
        >
          <el-form-item>
            <div style=" box-sizing: border-box;display:flex; justify-content:space-between;align-items:center; overflow: auto;">
              <div style="display:flex;align-items:center;">
                <el-popover class="hidden" popper-class="popperhidden" placement="top" trigger="click">
                  <div style="display:flex;flex-direction:column;">
                    <el-button
                      type="text"
                      style="margin:0 !important;"
                      disabled={this.isSendBack}
                      class="btn"
                      onClick={this.sendBack}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-download"
                        style="font-size:30px ;"
                      ></i>
                      <p>退回</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      disabled={this.isApostille}
                      class="btn"
                      style="margin:0 !important;"
                      onClick={this.Apostille}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-catalog-check-fill"
                        style="font-size:30px ;"
                      ></i>
                      <p>加签</p></div>
                      
                    </el-button>
                    <el-button type="text" class="btn"  style="margin:0 !important;" onClick={this.comment}>
                      <div style="display:flex;align-items: center;"><i
                        class="el-icon-chat-dot-square"
                        style="font-size:30px ;"
                      ></i>
                      <p>评论</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      style="margin:0 !important;"
                      class="btn hidden"
                      onClick={this.onHastenWork}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="el-icon-chat-dot-square"
                        style="font-size:30px ;"
                      ></i>
                      <p>催办</p></div>
                    </el-button>
                    <el-button
                      type="text"
                      class="btn"
                      style="margin:0 !important;"
                      disabled={this.isCareOf}
                      onClick={this.CareOf}
                      v-show={ this.form_status==6?false:isName}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont icon-customer-certified"
                        style="font-size:30px ;"
                      ></i>
                      <p>转交</p></div>
                      
                    </el-button>
                    <el-button
                      type="text"
                      class="btn"
                      style="margin:0 !important;"
                      disabled={this.revocationflag}
                      onClick={this.revocation}
                      v-show={ this.form_status==6?false:true}
                    >
                    <div style="display:flex;align-items: center;"><i
                        class="iconfont  icon-return"
                        style="font-size:30px;"
                      ></i>
                      <p>撤销</p></div>
                      
                    </el-button>
                    
                  </div>
                  <div slot="reference">
                    <el-button
                      type="text"
                      style="padding:5px 15px;color:#000000;"
                    >
                      <i
                        class="iconfont icon-more"
                        style="font-size:30px ;"
                      ></i>
                      <p>更多</p>
                    </el-button>
                  </div>
                </el-popover>
              </div>
              <div style="display:flex; align-items:center;">
                 <el-button
                  type="text"
                  v-show={this.isReboot }
                  class="rcoa hidden"
                  onClick={this.onReboot}
                >
                  <i class="iconfont icon-loading" style="font-size:30px;"></i>
                  <p>重启流程</p>
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.isResetForm}
                  onClick={this.resetForm}
                  v-show={ this.form_status==6?true:isName}
                >
                {this.form_status==6?this.form_title+'撤销退回上一步':'退回上一步'}
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  onClick={this.refusing}
                  v-show={ this.form_status==6?false:isName}
                >
                  拒绝
                </el-button>
                <el-button
                  class="hidden"
                  disabled={this.forbid}
                  type="primary"
                  onClick={this.submitForm}
                  v-show={ this.form_status==6?false:isName}
                >
                  同意
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      );

      // 因为使用jsx时  el-form 的 model 一直无法正确填充，故采用createElement直接渲染
      // return h("el-form", formObject, [
      //   title,
      //   content,
      //   flow,
      //   this.size == 50 ? btns : btns1,
      // ]);
      /*   return h("div", {}, [
          h('div', { ref: 'content', }, [
            h("el-form", formObject, [
              title,
              content,
              flow,
            ])
          ]),
          h('div', {}, [h("el-form", formObject, [this.size == 50 ? btns : btns1,])])
        ]); */
      return h(
        "div",
        {
          ref: "content",
        },
        [
          h("el-form", formObject, [
            title,
            content,
            flow,
            this.size == 0 ? btns2 : this.size == 10 ? btns4 :this.size == 50 ? btns : btns1,
          ]),
        ]
      );
    },
    onIsSignature(val) {
      this.isSignature = val;
    },
    onSignCancel(val) {
      this.isSignature = val;
    },
    async onSignature(val) {
      const formdata = new FormData();
      formdata.append("sign", val.file);
      let res = await this.$http({
        method: "POST",
        url: "sign_image/",
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=--------------------------051005589955791443255030",
        },
        data: formdata,
      });
      // console.log(res.data);
      if (res.status == 200) {
        this.signatureDataId = res.data.id;
        this.signatureUrl = res.data.url;
      }
      this.isSignature = false;
    },
    buildDialog(h) {
      let signature = (
        <div style="position:fixed ;top:0;left:0;z-index:9999; width:100%;height:100%; background-color: #fff;">
          <Signature
            v-on:onSignature={this.onSignature}
            v-on:onSignCancel={this.onSignCancel}
            isSignature={this.isSignature}
          ></Signature>
        </div>
      );

      return h("div", {}, [signature]);
    },
    // 收集表单数据
    initDefaultData(config) {
      config.fields.forEach((field) => {
        if (field.showDivider != undefined) {
          field.children.forEach((item, j) => {
            this.$set(this.formModel, item.vModel, item.defaultValue);
          });
        } else {
          if (field.tag == "el-select") {
            for (let i = 0; i < field.options.length; i++) {
              if (field.options[i].label == field.defaultValue) {
                field.defaultValue = field.options[i].value;
              }
            }
          }
          this.$set(this.formModel, field.vModel, field.defaultValue);
        }
      });
    },
  },

  render(h) {
    if (!this.confGlobal) {
      return <div v-loading="true" class="loading-mask"></div>;
    }
    return (
      <div
        class="preview-container"
        style={"width:" + this.containerWidth + "%;"}
      >
        <el-row style="padding:20px">{this.buildForm(h)}</el-row>
        {this.isSignature == false ? "" : this.buildDialog(h)}
      </div>
    );
  },
};
</script>
<style lang="less">
@media screen and(max-width:1000px) {
  .el-message-box {
    width: 350px !important;
  }
}

.el-input-number {
  .el-input__inner {
    text-align: left !important;
  }
}

.file_name {
  color: #000000;

  .file_delete {
    margin: 0 0 0 10px;

    &:hover {
      color: #f56c6c;
    }
  }

  .file_download {
    margin: 0 0 0 10px;

    &:hover {
      color: rgb(143, 195, 31);
    }
  }
}

.editor {
  border-color: #dcdfe6 !important;

  &:focus {
    border-color: rgb(143, 195, 31) !important;
  }
}

.popperhidden {
  min-width: auto !important;
  padding: 0 !important;
}
</style>
<style lang="stylus" scoped>
.box {
  width: 48%;
}

.rcoa {
  padding: 5px 15px;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.is-disabled {
  color: #C0C4CC !important;
}

.box1 {
  width: 98%;
}

.btn {
  padding: 10px;
  color: #000000;
  display: flex;
  // flex-direction: column;
  align-items: center;
}

.approve_number {
  font-size: 12px;
  color: #A0A5AB;
  display: flex;
  justify-content: space-between;

  i {
    font-size: 16px;
  }

  :hover {
    color: rgb(143,195, 31);
  }
  /deep/.el-button--text{
    padding:0 !important;
  }
}

.form_title {
  margin-top: 10px;
  font-size: 17px;
}

.red {
  color: red;
}

.ger {
  color: rgb(143,195, 31);
}
.gray {
  color: rgb(166, 166, 166);
}
.status_font_over {
    color: rgb(143, 195, 31);
}

.status_font_red {
    color: red;
}

.status_font_gray {
    color: rgb(166, 166, 166);
}

.status_font_default {
  color:#000;
}
.print {
  color: #000000;
}
 
@media print {
  .hidden {
    display: none;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
    border: 1px solid #EBEEF5 !important;
    background-color: #FFFFFF !important;
    color: #303133 !important;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  border: 1px solid #EBEEF5 !important;
    background-color: #FFFFFF !important;
    color: #303133 !important;

    }
</style>
