// main.js 或一个单独的指令文件
import Vue from 'vue';

Vue.directive('esc-close', {
  bind(el, binding, vnode) {
    function handleKeydown(event) {
      if (event.key === 'Escape') {
        // 查找 el-dialog 实例并关闭它
        const dialog = vnode.context.$refs[binding.arg];
        if (dialog) {
          dialog.visible = false;
        }
      }
    }

    // 监听键盘事件
    document.addEventListener('keydown', handleKeydown);

    // 清理事件监听器
    el.__handleKeydown__ = handleKeydown;
  },
  unbind(el) {
    document.removeEventListener('keydown', el.__handleKeydown__);
    delete el.__handleKeydown__;
  }
});