<template>
    <div class="refund_order">
        <h1 v-if="isSave">售后申请</h1>
        <div class="orders_form">
            <el-form size="mini" :disabled="refundOrderReadonly" label-position="left" :model="ordersObj"
                label-width="130px" :inline="true" :rules="refundOrderRules" ref="refundOrderRef1">
                <el-form-item label="客户" prop="customerValue">
                    <el-input v-model="ordersObj.customerValue" placeholder="客户名称" @keyup.enter.native="isCustomer = true"
                        @blur.stop="isCustomer = true" clearable>
                        <el-button size="mini" slot="prepend" icon="el-icon-search" @click="isCustomer = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="业务员" prop="creator_id">
                    <template>
                        <el-select v-model="ordersObj.creator_id" placeholder="请选择" clearable>
                            <el-option v-for="item in salesmanList" :label="item.label" :value="item.value" clearable
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="退货日期" prop="order_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="退货日期" v-model="ordersObj.order_date"
                        style="width: 100%" :picker-options="pickerOptions" clearable></el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="orders_table">
            <el-table v-if="!$store.state.isphone" size="mini" width="1300px" :data="ordersObj.products" border
                :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }">
                <el-table-column label="序号" type="index" width="50px"></el-table-column>
                <el-table-column label="设置" width="100">
                    <template slot-scope="data">
                        <el-button type="text" @click="onPushOrders">添加</el-button>
                        <el-button type="text" @click="onDeleteOrders(data.$index)">删除</el-button>
                    </template>
                </el-table-column>

                <el-table-column label="品目编码" prop="product_code" width="210px">
                    <template slot-scope="data">
                        <el-input size="mini" :disabled="refundOrderReadonly" v-model="data.row.product_code"
                            placeholder="品目编码" @keyup.enter.native="onOrdersProductSearch(data.$index)"
                            @blur.stop="onOrdersProductSearch(data.$index)"
                            @change="onOrdersChange(data.row.product_code, data.$index)" clearable>
                            <el-button size="mini" slot="prepend" icon="el-icon-search"
                                @click="onOrdersProductSearch(data.$index)"></el-button>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="品目名称" prop="product_title" width="210px">
                    <!-- <template slot-scope="data">
                        <el-input size="mini" v-model="data.row.product_title" @input="onUnitPrice(data.$index)"
                            placeholder="品目名称">
                        </el-input>
                    </template> -->
                </el-table-column>
                <el-table-column label="品目规格" prop="specifications" width="210px">
                    <!-- <template slot-scope="data">
                        <el-input size="mini" v-model="data.row.specifications" @input="onUnitPrice(data.$index)"
                            placeholder="品目规格">
                        </el-input>
                    </template> -->
                </el-table-column>
                <el-table-column label="数量" prop="quantity" width="210px">
                    <template slot-scope="data">
                        <el-input type="number" :disabled="refundOrderReadonly" :min="1" clearable size="mini"
                            v-model="data.row.quantity" placeholder="数量">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="成交单价" prop="unit_price" width="210px">
                    <template slot-scope="data">
                        <el-input size="mini" type="number" :disabled="refundOrderReadonly" v-model="data.row.unit_price"
                            @blur="onUnitPrice(data.$index)" placeholder="成交单价" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column label="成交金额" prop="total_actual_amount" width="210px">
                    <template slot-scope="data">
                        <div>
                            {{ accMul(data.row.quantity, data.row.unit_price, data.$index) }}
                            <!-- {{ data.row.total_actual_amount=data.row.quantity * data.row.unit_price }} -->
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="产品摘要" prop="abstract" width="210px">
                    <template slot-scope="data">
                        <el-input size="mini" :disabled="refundOrderReadonly" v-model="data.row.abstract" placeholder="产品摘要"
                            clearable>
                        </el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else style="border: 1px dashed #8fc31f;padding: 10px;border-radius:12px;">
                <template v-for="(item, i) in ordersObj.products">
                    <div :key="i" style="border:1px solid #DCDFE6; padding:10px; margin-bottom:10px;border-radius:12px;">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <p> 产品{{ i + 1 }}</p>
                            <i @click="onDeleteOrders(i)" class="el-icon-close"></i>
                        </div>
                        <el-form :model="item">
                            <el-form-item label="品目编码" prop="product_code">
                                <el-input size="mini" :disabled="refundOrderReadonly" v-model="item.product_code"
                                    placeholder="品目编码" @keyup.enter.native="onOrdersProductSearch(i)"
                                    @blur.stop="onOrdersProductSearch(i)" @change="onOrdersChange(item.product_code, i)"
                                    clearable>
                                    <el-button size="mini" slot="prepend" icon="el-icon-search"
                                        @click="onOrdersProductSearch(i)"></el-button>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="品目名称" prop="product_title">
                                <el-input size="mini" disabled v-model="item.product_title" placeholder="品目名称" clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="品目规格" prop="specifications">
                                <el-input size="mini" disabled v-model="item.specifications" placeholder="品目名称" clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="数量" prop="quantity">
                                <el-input type="number" :disabled="refundOrderReadonly" :min="1" clearable size="mini"
                                    v-model="item.quantity" placeholder="数量">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="成交单价" prop="unit_price">
                                <el-input size="mini" type="number" :disabled="refundOrderReadonly"
                                    v-model="item.unit_price" @blur="onUnitPrice(i)" placeholder="成交单价" clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="成交金额" prop="total_actual_amount">
                                <div>
                                    {{ accMul(item.quantity, item.unit_price, i) }}
                                    <!-- {{ item.total_actual_amount=item.quantity * item.unit_price }} -->
                                </div>
                            </el-form-item>
                            <el-form-item label="产品摘要" prop="abstract">
                                <el-input size="mini" :disabled="refundOrderReadonly" v-model="item.abstract"
                                    placeholder="产品摘要" clearable>
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </template>
                <div>
                    <el-button style="width: 100%;" :disabled="ordersReadonly" @click="onPushOrders" icon="el-icon-plus"
                        type="text">添加产品</el-button>
                </div>
            </div>
        </div>
        <div class="orders_form" style="border: 2px dotted #b5e4c2;margin: 10px 0px;border-radius: 12px;">
            <!-- {{ ordersObj.impersonal_entity }} -->
            <el-form size="mini" label-position="left" :model="ordersObj" :disabled="refundOrderReadonly"
                label-width="130px" :inline="true" :rules="refundOrderRules" ref="refundOrderRef2">
                <el-form-item label="交易法人" prop="impersonal_entity">
                    <el-select v-model="ordersObj.impersonal_entity" placeholder="请选择" clearable>
                        <el-option v-for="item in impersonal_entityList" :label="item.label" :value="item.label" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="发货仓库" prop="deliver_date">
                    <el-select v-model="ordersObj.shipsWarehouse" placeholder="请选择" clearable>
                        <el-option v-for="item in shipsWarehouseList" :label="item.label" :value="item.label"
                            clearable :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="退回仓库" prop="warehouseValue">
                    <el-input size="mini" v-model="ordersObj.warehouseValue" placeholder="退回仓库"
                        @keyup.enter.native="isWarehouse = true" @blur.stop="isWarehouse = true" clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="isWarehouse = true"></el-button>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="交货日期类型" prop="deliver_date">
                    <el-select v-model="ordersObj.deliver_date_type" placeholder="请选择" clearable>
                        <el-option v-for="item in deliveryDateList" :label="item.label" :value="item.label"
                            clearable :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->

                <!-- <el-form-item label="交货日期" prop="deliver_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择交货日期"
                        v-model="ordersObj.deliver_date" style="width: 100%;"
                        :picker-options="pickerOptions"></el-date-picker>
                </el-form-item> -->

                <!-- <el-form-item label="付款方式" prop="payment_method">
                    <el-select v-model="ordersObj.payment_method" placeholder="请选择" clearable>
                        <el-option v-for="item in paymentMethodOption" :label="item.label" :value="item.value" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预付款比例" prop="AdvancePayment_ratio" v-if="ordersObj.payment_method==1">
                    <el-input v-model="ordersObj.AdvancePayment_ratio" placeholder="预付款比例" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="提货方式" prop="deliver_method">
                    <template>
                        <el-select v-model="ordersObj.deliver_method" placeholder="请选择">
                            <el-option v-for="item in deliverMethodOption" :label="item.label" :value="item.value" clearable
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item> -->
                <el-form-item label="退货原因简述" prop="reason">
                    <el-input v-model="ordersObj.reason" placeholder="退货原因简述" clearable>
                    </el-input>
                </el-form-item>

                <!-- <el-form-item label="运费规则" prop="freight_cost_rule">
                    <template>
                        <el-select v-model="ordersObj.freight_cost_rule" placeholder="请选择" clearable>
                            <el-option v-for="item in freightCostRuleOption" :label="item.label" :value="item.value"
                                clearable :key="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item> -->
            </el-form>
        </div>
        <!-- <div class="orders_form" style="border: 2px dotted #bbc3e9;border-radius: 12px;">
            <el-form size="mini" :model="ordersObj" label-width="120px" :inline="true">
                <el-form-item label="账期" prop="payment_days">
                    <el-input v-model="ordersObj.payment_days" placeholder="账期" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="订单有效期" prop="order_date">
                    <el-input v-model="ordersObj.order_life_date" placeholder="订单有效期" clearable></el-input>
                </el-form-item> 
                <br>
                <el-form-item label="其他条款1" prop="other_terms1">
                    <el-input type="textarea" v-model="ordersObj.other_terms1" placeholder="其他条款1" clearable>
                    </el-input>
                </el-form-item>
                <br>
                <el-form-item label="其他条款2" prop="other_terms2">
                    <el-input type="textarea" v-model="ordersObj.other_terms2" placeholder="其他条款2" clearable>
                    </el-input>
                </el-form-item>
                <br>
                <el-form-item label="其他条款3" prop="other_terms3">
                    <el-input type="textarea" v-model="ordersObj.other_terms3" placeholder="其他条款3" clearable>
                    </el-input>
                </el-form-item>
            </el-form>
        </div> -->
        <div v-if="isSave">
            <el-button size="mini" type="primary" @click="onSaveOrders" style="margin-top: 10px">保存</el-button>
        </div>
        <inquireDialog v-if="isWarehouse" :isVisible="isWarehouse" :obj="warehouseObj" :title="'仓库'" :isChoose="true"
            :searchValue="ordersObj.warehouseValue" @onIsVisible="warehouseVisible" @onApplied="warehouseApplied">
        </inquireDialog>
        <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="'客户'" :isChoose="true"
            :searchValue="ordersObj.customerValue" @onIsVisible="customerVisible" @onApplied="customerApplied">
        </inquireDialog>
        <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'" :isChoose="false"
            :searchValue="productValue" @onIsVisible="productVisible" @onApplied="productApplied">
        </inquireDialog>
        <inquireDialog v-if="isSpecial" :isVisible="isSpecial" :obj="specialDialogObj" :title="'特价'" :isChoose="true"
            :searchValue="specialValue" @onIsVisible="specialVisible" @onApplied="specialApplied">
        </inquireDialog>
    </div>
</template>
<script>
import { debounce } from "@/utils/AntiShake";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import data from "@/components/Tools/SubassemblyTools/PrintJs/net-provider1";
import Decimal from 'decimal.js';
export default {
    props: ["isRefundOrder", "refundOrderForm", "formTitle", "readonly", "refundOrderId"],
    data() {
        return {
            printList: ['售后申请'],
            refundOrderReadonly: null,
            // 是否显示
            isSave: this.isRefundOrder == undefined ? true : this.isRefundOrder,
            // 业务员列表
            salesmanList: [],
            // 付款方式
            // paymentMethodOption: [
            //     {
            //         label: "预付",
            //         value: 2,
            //     },
            //     {
            //         label: "款到发货",
            //         value: 1,
            //     },
            //     {
            //         label: "约定账期",
            //         value: 3,
            //     },
            // ],
            // 运费规则
            // freightCostRuleOption: [
            //     {
            //         label: "单笔销售订单金额大于1000，由供方承担",
            //         value: 1,
            //     },
            //     {
            //         label: "无",
            //         value: 2,
            //     },
            //     {
            //         label: "需方提供，以订单明细的运费为准",
            //         value: 3,
            //     },
            // ],
            // 提货方式
            // deliverMethodOption: [
            //     {
            //         label: "工厂交货（客户自提）",
            //         value: 1,
            //     },
            //     {
            //         label: "交货到客户指定点",
            //         value: 2,
            //     },
            //     {
            //         label: "交货到客户公司",
            //         value: 3,
            //     },
            //     {
            //         label: "交货给客户指定承运人（运费客户自理）",
            //         value: 4,
            //     },
            // ],
            //添加退货申请数据
            ordersObj: {
                warehouseValue: null,
                customerValue: "",
                impersonal_entity: null, //交易法人 1代表宁波亨博电磁技术有限公司  2代表深圳亨贝智控物联有限公司  3代表宁波亨嘉科技有限公司
                ware_house_id: null, //发货仓库 1代表宁波仓  2代表深圳仓
                // deliveryDate:null, //交期  1代表订单回签后XX个工作日  2代表预付款后XX个工作日  3代表全款后XX个工作日
                customer_id: "",
                creator_id: null,
                // order_number: "",
                contract_order_type: 5, //订单固定传1，标识这是一个订单
                order_date: "", //退货日期
                // order_life_date: "", //订单有效期至
                // other_terms1: "",
                // other_terms2: "",
                // other_terms3: "",
                // payment_method: "",// 1代表预付款 2代表款到发货，3代表约定账期
                // // freight_cost_rule: "",//1代表单笔销售订单金额大于1000，由供方承担，2代表无，3代表需方提供，以订单明细的运费为准
                is_package_recycle: true,//包装是否回收，1代表是，0代表否
                // payment_days: "30",//账期
                // AdvancePayment_ratio: "",//预付比例
                // deliver_method: "",//交提货方式,1代表工厂交货（客户自提），2代表交货到客户指定点，3代表交货到客户公司，4代表交货给客户指定承运人（运费客户自理）
                // deliver_addr:null,//交接地点/承运人
                // deliver_date: "",//交货日期
                // deliver_date_type:null,
                reason: '',
                products: [
                    {
                        product_code: null,
                        product_title: null,
                        specifications: null,
                        product_id: null, //产品的外键id
                        unit_price: null, //入库单价
                        special_offer_price: null, //入库单价
                        quantity: null, //产品数量
                        special_offer_obj: null,
                        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        // special_offer_title: null, //
                        special_offer: null, //可以为空,也就是可以不关联特价申请单id
                        abstract: null
                    },
                    {
                        product_code: null,
                        product_title: null,
                        specifications: null,
                        product_id: null, //产品的外键id
                        unit_price: null, //入库单价
                        special_offer_price: null, //入库单价
                        quantity: null, //产品数量
                        special_offer_obj: null,
                        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        // special_offer_title: null, //
                        special_offer: null, //可以为空,也就是可以不关联特价申请单id
                        abstract: null
                    },
                    {
                        product_code: null,
                        product_title: null,
                        specifications: null,
                        product_id: null, //产品的外键id
                        unit_price: null, //入库单价
                        quantity: null, //产品数量
                        special_offer_price: null, //入库单价
                        special_offer_obj: null,
                        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        // special_offer_title: null, //
                        special_offer: null, //可以为空,也就是可以不关联特价申请单id
                        abstract: null
                    },
                ]
            },
            // 客户名称
            // customerValue: "",
            // 客户对象
            customerObj: {},
            // 客户Dialog开关
            isCustomer: false,
            // 客户Dialog参数
            customerDialogObj: {
                searchField: "search",
                url: "crm/customer_bulk/",
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: ""
                }
            },
            //产品名称
            productValue: "",
            // 产品开关
            isProduct: false,
            //产品数组
            productArr: [],
            //  发货仓库开关
            isWarehouse: false,
            // warehouseValue:null,
            warehouseValueObj: null,
            // 发货仓库Dialog参数
            warehouseObj: {
                url: 'crm/outbound_product_warehouse/',
                params: {
                    per_page: 10,
                    page_number: 1,
                    user_id: localStorage.getItem("user_id"),
                    keyword: ""
                }
            },
            //订单的产品表格当前index
            quotationTableIndex: "",
            // 产品Dialog参数
            productDialogObj: {
                url: 'crm/outbound_product_item_code/',
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    keyword: "",
                }
            },
            // 特价开关
            isSpecial: false,
            // 特价名称
            specialValue: "",
            // 订单的产品当前index
            specialTableIndex: "",
            // 特价Dialog参数
            specialDialogObj: {
                url: 'crm/order_special_offer/',
                data: {
                    // per_page: 10,
                    // page_number: 1,
                    // user_id: localStorage.getItem("user_id"),
                    // keyword: ""
                }
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                ],
            },

            // 交易法人列表
            impersonal_entityList: [
                { value: 1, label: '宁波亨博电磁技术有限公司' },
                { value: 2, label: '深圳亨贝智控物联有限公司' },
                { value: 3, label: '宁波亨嘉科技有限公司' },
            ],
            // shipsWarehouseList: [
            //     { value: 1, label: '宁波仓' },
            //     { value: 2, label: '深圳仓' },
            // ],
            // 交期列表
            deliveryDateList: [
                { value: 1, label: '订单回签后XX个工作日' },
                { value: 2, label: '预付款后XX个工作日' },
                { value: 3, label: '全款后XX个工作日' },
            ],
            refundOrderRules: {
                customerValue: [
                    {
                        required: true,
                        message: "请添加客户",
                        trigger: ["change", "blur"],
                    },
                ],
                creator_id: [
                    {
                        required: true,
                        message: "请选择业务员",
                        trigger: ["change", "blur"],
                    },
                ],
                order_date: [
                    {
                        required: true,
                        message: "请选择退货日期",
                        trigger: ["change", "blur"],
                    },
                ],
                impersonal_entity: [
                    {
                        required: true,
                        message: "请选择交易法人",
                        trigger: ["change", "blur"],
                    },
                ],
                warehouseValue: [
                    {
                        required: true,
                        message: "请选择退回仓库",
                        trigger: ["change", "blur"],
                    },
                ],
                reason: [
                    {
                        required: true,
                        message: "请输入退货原因简述",
                        trigger: ["change", "blur"],
                    },
                ],
            },

        }
    },
    components: {
        inquireDialog,
    },
    mounted() {
        this.getSalesManList();
        let today = new Date();
        this.ordersObj.order_date =
            today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
        if (!this.isSave) {
            if (this.formTitle == undefined && this.refundOrderId == undefined) {
                this.ordersObj.customerValue = this.refundOrderForm.customer.company_name;
                this.customerObj = this.refundOrderForm.customer;
                this.ordersObj.customer_id = this.refundOrderForm.customer.id;
                this.ordersObj.creator_id = this.refundOrderForm.salesman.value;
            }
        }

        if (this.$route.query.id != undefined && this.$route.path !== "/oaintoform") {
            this.getList(this.$route.query.id)
        }
        if (this.refundOrderId != undefined) {
            this.getList(this.refundOrderId)
        }
        this.funDate(180)
        // let date = new Date();

        // let timeStr = date.getFullYear() + "-" + ((date.getMonth()+1)<10?'0'+(date.getMonth()+1):(date.getMonth()+1)) + "-" +(date.getDate()<10?'0'+date.getDate():date.getDate());
        // this.ordersObj.order_life_date = timeStr
        // console.log(timeStr);
    },
    watch: {
        "ordersObj.warehouseValue": {
            handler(val) {
                if (val !== null) {
                    this.ordersObj.ware_house_id = this.warehouseValueObj.id
                    this.ordersObj.ware_house_title = val
                }
            }
        },
        ordersObj: {
            handler(val) {
                // this.$emit("onAddOutboundOrder", obj)
                if (this.isRefundOrder == false && this.refundOrderId == undefined) {
                    this.$emit("onAddOutboundOrder", this.ordersObj)
                  //  console.log(val);
                }
            },
            deep: true
        },
        refundOrderForm: {
            handler(val) {
                if (this.formTitle == "售后申请") {
                    if (val != undefined) {
                        // this.getList(val.id)
                        //                         {
                        //     "warehouseValue": "宁波-成品仓",
                        //     "customerValue": "宁波市奉化捷瑞自动化设备有限公司",
                        //     "impersonal_entity": "宁波亨博电磁技术有限公司",
                        //     "ware_house_id": 20,
                        //     "customer_id": 1,
                        //     "creator_id": 48,
                        //     "contract_order_type": 5,
                        //     "order_date": "2023-11-14",
                        //     "is_package_recycle": true,
                        //     "reason": "66666",
                        //     "products": [
                        //         {
                        //             "product_code": "0BS20",
                        //             "product_title": "汇流板",
                        //             "specifications": "0BS20 底出气",
                        //             "product_id": 1,
                        //             "unit_price": 0,
                        //             "special_offer_price": "0.000000",
                        //             "quantity": 1,
                        //             "special_offer_obj": null,
                        //             "total_actual_amount": 0,
                        //             "special_offer": null,
                        //             "abstract": "1",
                        //             "order_id": 1
                        //         },
                        //         {
                        //             "product_code": "0SH08",
                        //             "product_title": "汇流板",
                        //             "specifications": "0SH08",
                        //             "product_id": 2,
                        //             "unit_price": 0,
                        //             "special_offer_price": "0.000000",
                        //             "quantity": 1,
                        //             "special_offer_obj": null,
                        //             "total_actual_amount": 0,
                        //             "special_offer": null,
                        //             "abstract": "2",
                        //             "order_id": 1
                        //         }
                        //     ],
                        //     "order_life_date": "2024-6-11",
                        //     "ware_house_title": "宁波-成品仓",
                        //     "goods_returned_number": "TH20231214000001",
                        //     "id": 1,
                        //     "creator_name": "晋江涛",
                        //     "avatar": ""
                        // }
                        this.ordersObj.warehouseValue = val.warehouseValue
                        this.ordersObj.customerValue = val.customerValue
                        this.ordersObj.impersonal_entity = val.impersonal_entity
                        this.ordersObj.ware_house_id = val.ware_house_id
                        this.ordersObj.customer_id = val.customer_id
                        this.ordersObj.creator_id = val.creator_id
                        this.ordersObj.contract_order_type = val.contract_order_type
                        this.ordersObj.order_date = val.order_date
                        this.ordersObj.is_package_recycle = val.is_package_recycle
                        this.ordersObj.reason = val.reason
                        this.ordersObj.order_life_date = val.order_life_date
                        this.ordersObj.ware_house_title = val.ware_house_title
                        this.ordersObj.goods_returned_number = val.goods_returned_number
                        this.ordersObj.id = val.id
                        this.ordersObj.creator_name = val.creator_name
                        this.ordersObj.avatar = val.avatar
                        this.ordersObj.products = []
                        val.products.forEach(item => {
                            this.ordersObj.products.push({
                                product_code: item.product_code,
                                product_title: item.product_title,
                                specifications: item.specifications,
                                product_id: item.product_id, //产品的外键id
                                unit_price: item.unit_price, //入库单价
                                special_offer_price: item.special_offer_price, //入库单价
                                quantity: item.quantity, //产品数量
                                special_offer_obj: item.special_offer_obj,
                                total_actual_amount: item.total_residue_amount,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                                special_offer: item.special_offer, //可以为空,也就是可以不关联特价申请单id
                                abstract: item.abstract,
                                order_id: item.order_id
                            })
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
        readonly: {
            handler(val) {
                if (val == undefined) {
                    this.refundOrderReadonly = false
                } else {
                    this.refundOrderReadonly = val
                }
            }
        }
    },
    methods: {
        accMul(arg1, arg2, index) {
            if (arg1 === null || arg2 === null || arg1 === '' || arg2 === '') {
                return ""
            } else {
                // console.log(arg1,arg2);
                var m = 0,
                    s1 = arg1.toString(),
                    s2 = arg2.toString();
                try {
                    m += s1.split('.')[1].length;
                } catch (e) { }
                try {
                    m += s2.split('.')[1].length;
                } catch (e) { }
                // this.ordersObj.products[index].total_actual_amount = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
                // return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
                let decimal1 = new Decimal(s1)
                let decimal2 = new Decimal(s2)
                let decimal3 = decimal1.times(decimal2)
              //  console.log(decimal3, 6666);
                this.ordersObj.products[index].total_actual_amount = decimal3
                return decimal3
            }
        },
        Thousands(num) {
            return (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
        // 仓库开关
        warehouseVisible(data) {
            this.isWarehouse = data
        },
        // 仓库参数
        warehouseApplied(data) {
            this.warehouseValueObj = data
            this.ordersObj.warehouseValue = data.warehouse_name
        },
        funDate(aa) {
            let date1 = new Date()
            let time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();//time1表示当前时间
            let date2 = new Date(date1);
            date2.setDate(date1.getDate() + aa);
            let time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
            this.ordersObj.order_life_date = time2
        },
        async getSalesManList() {
            let res = await this.$http({
                method: 'GET',
                url: 'crm/data_source/',
                params: {
                    source: 'salesman',
                }
            })
            this.salesmanList = res.data;
        },
        async getList(val) {
            const res = await this.$http({
                method: "GET",
                url: `crm/order_relationship_table/${val}/`,
            })
          //  console.log(res.data);
            if (this.formTitle == "售后申请") {
                this.ordersObj.id = res.data.id
                this.ordersObj.goods_returned_number = res.data.goods_returned_number
            }
            this.warehouseValueObj = {
                id: res.data.ware_house.id,
                warehouseValue: res.data.ware_house.warehouse_namewarehouse_name
            }
            this.ordersObj.customerValue = res.data.customer.company_name
            this.customerObj = res.data.customer
            this.ordersObj.customer_id = res.data.customer.id
            // this.ordersObj.order_number = res.data.order_number
            // this.ordersObj.contract_order_type = res.data.contract_order_type
            this.ordersObj.order_date = res.data.order_date
            this.ordersObj.reason = res.data.reason
            // this.ordersObj.order_life_date = res.data.order_life_date
            // this.ordersObj.other_terms1 = res.data.other_terms1
            // this.ordersObj.other_terms2 = res.data.other_terms2
            // this.ordersObj.other_terms3 = res.data.other_terms3
            // this.ordersObj.payment_method = res.data.payment_method
            // // this.ordersObj.freight_cost_rule = res.data.freight_cost_rule
            // this.ordersObj.payment_days = res.data.payment_days
            // this.ordersObj.AdvancePayment_ratio = res.data.AdvancePayment_ratio
            // this.ordersObj.deliver_method = res.data.deliver_method
            // this.ordersObj.deliver_date = res.data.deliver_date
            // this.ordersObj.deliver_date_type = res.data.deliver_date_type
            this.ordersObj.creator_id = res.data.creator.id
            this.ordersObj.impersonal_entity = res.data.impersonal_entity
            this.ordersObj.warehouseValue = res.data.ware_house.warehouse_name
            // this.ordersObj.deliver_addr= res.data.deliver_addr
            this.ordersObj.ware_house_id = res.data.ware_house.id
            // if (res.data.is_package_recycle == 1) {
            //     this.ordersObj.is_package_recycle = true
            // } else {
            //     this.ordersObj.is_package_recycle = false
            // }
            this.ordersObj.products = []
            res.data.order_product_table.forEach(item => {
                this.ordersObj.products.push({
                    product_code: item.product.item_code,
                    product_title: item.product.item_name,
                    specifications: item.product.specifications,
                    product_id: item.product.id, //产品的外键id
                    unit_price: item.unit_price, //入库单价
                    special_offer_price: item.unit_price, //入库单价
                    quantity: item.quantity, //产品数量
                    special_offer_obj: null,
                    total_actual_amount: item.total_residue_amount,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                    // special_offer_title: null, //
                    special_offer: item.special_offer, //可以为空,也就是可以不关联特价申请单id
                    abstract: item.abstract
                })
            })
          //  console.log(this.ordersObj);

        },
        // 客户Dialog开关
        customerVisible(data) {
            // console.log(data);
            this.isCustomer = data
        },
        // 客户Dialog数据
        customerApplied(data) {
            this.customerObj = data
            this.ordersObj.customer_id = data.id
            // console.log(data);
            this.ordersObj.customerValue = data.company_name
        },
        // 产品Dialog开关
        productVisible(data) {
            this.isProduct = data
        },
        // 产品Dialog数据
        productApplied(data) {
          //  console.log(data);
            this.productArr = data
            const arr = []
            this.productArr.forEach(item => {
                arr.push({
                    product_code: item.item_code,
                    product_title: item.item_name,
                    specifications: item.specifications,
                    product_id: item.id, //产品的外键id
                    unit_price: item.in_unit_price, //入库单价
                    special_offer_price: item.in_unit_price, //入库单价
                    quantity: null, //产品数量
                    special_offer_obj: null,
                    total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                    // special_offer_title: null, //
                    special_offer: null, //可以为空,也就是可以不关联特价申请单id
                    abstract: null
                })
            });
            this.ordersObj.products.splice(this.quotationTableIndex, 1, ...arr)
        },
        onOrdersProductSearch(index) {
            this.isProduct = true
            this.quotationTableIndex = index
        },
        onOrdersChange(data, index) {
          //  console.log(data, index);
            this.productValue = data
            this.quotationTableIndex = index
            this.ordersObj.products[index].product_title = ''
            this.ordersObj.products[index].specifications = ''
        },
        // 添加订单的产品
        onPushOrders() {
            this.ordersObj.products.push({
                product_code: null,
                product_title: null,
                specifications: null,
                product_id: null, //产品的外键id
                unit_price: null, //入库单价
                special_offer_price: null, //入库单价
                quantity: null, //产品数量
                special_offer_obj: null,
                total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                // special_offer_title: null, //
                special_offer: null, //可以为空,也就是可以不关联特价申请单id
                abstract: null
            })
        },
        //删除报价单的产品
        onDeleteOrders(index) {
            if (this.ordersObj.products.length != 1) {
                this.ordersObj.products.splice(index, 1)
            }
        },
        onOrdersSpecialChange(title, index) {
          //  console.log(title);
            this.specialValue = data
            this.specialTableIndex = index
        },
        // 特价Dialog开关
        specialVisible(val) {
            this.isSpecial = false
        },
        // 特价Dialog数据
        specialApplied(val) {
          //  console.log(val);
          let obj = {
                approve_number: val.approve_number,
                field0: val.field0,
                field1: val.field1,
                field2: val.field2,
                field3: val.field3,
                field4: val.field4,
                field6: val.field6,
                field7: val.field7,
                id: val.id,
                is_using: val.is_using,
                item_code: val.item_code,
                item_name: val.item_name,
                max_price: val.max_price,
                min_price: val.min_price,
                price: val.price,
                priority: val.priority,
                product: val.product,
                special_offer_creator: val.special_offer_creator,
                special_offer_name: val.special_offer_name,
                specifications: val.specifications,
                tag: val.tag,
                unit: val.unit,
            }
            this.ordersObj.products[this.specialTableIndex].special_offer_obj = obj
            // this.ordersObj.products[this.specialTableIndex].special_offer_title = val.price
            this.ordersObj.products[this.specialTableIndex].special_offer = val.id
            this.ordersObj.products[this.specialTableIndex].unit_price = val.price
          //  console.log(this.ordersObj.products[this.specialTableIndex].unit_price);
            if (this.ordersObj.products[this.specialTableIndex].unit_price != null) {
                if (val.price != undefined) {
                    if (this.ordersObj.products[this.specialTableIndex].unit_price >= val.price) {
                    } else {
                        this.ordersObj.products[this.specialTableIndex].unit_price = null
                        this.$message.error('价格不允许')
                    }
                } else {
                    if (this.ordersObj.products[this.specialTableIndex].unit_price >= val.min_price && this.ordersObj.products[this.specialTableIndex].unit_price <= val.max_price) {
                    } else {
                        this.ordersObj.products[this.specialTableIndex].unit_price = null
                        this.$message.error('价格不允许')
                    }
                }
            }
        },
        // 判断单价
        onUnitPrice: debounce(function (index) {
            // console.log(index);
            // console.log(this.ordersObj.products[index].special_offer_obj);
            if (this.ordersObj.products[index].special_offer_obj !== null) {
                if (this.ordersObj.products[index].special_offer_obj.price != undefined) {
                  //  console.log(this.ordersObj.products[index].unit_price, this.ordersObj.products[index].special_offer_obj.price);
                    if (Number(this.ordersObj.products[index].unit_price) >= Number(this.ordersObj.products[index].special_offer_obj.price)) {
                    } else {
                      //  console.log('111');
                        this.ordersObj.products[index].unit_price = ""
                        this.$message.error('价格不允许')
                    }
                } else {
                    if (this.ordersObj.products[index].unit_price >= this.ordersObj.products[index].special_offer_obj.min_price && this.ordersObj.products[index].unit_price <= this.ordersObj.products[index].special_offer_obj.max_price) {
                    } else {
                      //  console.log("222");
                        this.ordersObj.products[index].unit_price = ""
                        this.$message.error('价格不允许')
                    }
                }
            }

        }, 500),
        // 保存销售订单
        async onSaveOrders() {
            let arr = []
            this.ordersObj.products.forEach((item, i) => {
                if (item.product_id != null) {
                    arr.push(item)
                    item.quantity = Number(item.quantity)
                    item.unit_price = Number(item.unit_price)
                }
            })
            if (arr.length > 0) {
                this.ordersObj.products = arr
            }
            if (this.ordersObj.products.length == 0) {
                this.$message.warning("产品表不能为空")
            } else if (this.ordersObj.customerValue == '') {
                this.$message.warning("请选择客户")
            }
            // else if(this.ordersObj.deliver_method==''){
            //     this.$message.warning("请选择提货方式")
            // }
            // else if(this.ordersObj.freight_cost_rule=''){
            //     this.$message.warning("请选择运费规则")
            // }
            // else if(this.ordersObj.order_date=''){
            //     this.$message.warning("请选择下单日期")
            // }
            else {
                let isEmpty = false
                this.ordersObj.products.forEach((item, index) => {
                  //  console.log(item);
                    if (item.quantity == '' || item.quantity == null) {
                      //  console.log(item.quantity);
                        isEmpty = true;
                    }
                })
              //  console.log(isEmpty);
                if (isEmpty) {
                    this.$message.warning("请填写产品数量")
                } else {
                    let obj = this.ordersObj
                    // if (obj.is_package_recycle) {
                    //     obj.is_package_recycle = 1
                    // } else {
                    //     obj.is_package_recycle = 2
                    // }
                    delete obj.deliver_date
                    if (this.$route.query.id == null) {
                        const res = await this.$http({
                            url: "crm/order_relationship_table/",
                            method: "POST",
                            data: obj
                        })
                      //  console.log(res);
                        if (res.status === 200) {
                            this.$message.success('新增成功')
                        } else {
                            this.$message.error(res.data)
                        }
                    } else {
                        const res = await this.$http({
                            url: `crm/order_relationship_table/${this.$route.query.id}/`,
                            method: "PUT",
                            data: obj
                        })
                      //  console.log(res);
                        if (res.status === 200) {
                            this.$message.success('修改成功')
                        } else {
                            this.$message.error(res.data)
                        }
                    }
                }
            }


        },
        onTotalActualAmount(amount, index) {
            this.ordersObj.products[index].total_actual_amount = amount
        },
    }
};
</script>
<style lang="less" scoped>
.refund_order {
    width: 100%;
    overflow: auto;
}

.orders_form {
    // width: 1300px;

    .el-form {
        // display: flex;
        flex-wrap: wrap;

        .el-form-item {
            flex: 1;
            margin-top: 15px;
            padding-left: 10px;
        }

        .el-form-item__content {
            width: 210px;
        }

        .el-date-editor {
            width: 210px !important;
        }

        .el-input {
            width: 210px;
        }

        .el-switch {
            width: 210px;
        }

        .el-select {
            width: 210px;
        }
    }
}

/deep/.el-textarea__inner {
    width: 560px !important;
}

.orders_table {
    // width: 1300px;
}
</style>