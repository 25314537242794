<template>
    <div class="fc-instructions">
        {{ defaultValue }}
    </div>
</template>
<script>
export default {
    name: "fc-instructions",
    model: {
        prop: "value",
        event: "change",
    },
    props: [
        "formData",
        "tag",
        "conf",
        "tagIcon",
        "label",
        "formId",
        "defaultValue",
        "disabled",
        "splicingList",
        "labelWidth",
        "style",
        "readonly",
        "required",
        "regList",
        "changeTag",
        "proCondition",
        "asSummary",
    ],
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.fc-instructions{
    white-space: pre-line;
}
</style>