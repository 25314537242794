<template>
    <div>
        <div>
            <el-form ref="demandApplicationRef1" size="mini" :rules="demandApplicationRules" :model="demandApplicationForm"
                :disabled="is_Readonly">
                <el-form-item label="申请人" prop="creator_arr">
                    <OrgSelect @input="onCreator" :maxNum="1" :tab="'user'" :value="demandApplicationForm.creator_arr"
                        :isCheckbox="true" buttonType="button" :title="'申请人'">
                    </OrgSelect><el-input style="display: none;" v-model="demandApplicationForm.creator_arr"
                        placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="申请部门" prop="dept">
                    <OrgSelect @input="onDept" :maxNum="1" :tab="'dep'" :value="demandApplicationForm.dept"
                        :isCheckbox="true" buttonType="button" :title="'部门'">
                    </OrgSelect>
                    <el-input style="display: none;" v-model="demandApplicationForm.dept" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="申请日期" prop="filing_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="申请日期"
                        v-model="demandApplicationForm.filing_date" style="width: 100%;" :picker-options="pickerOptions"
                        clearable></el-date-picker>
                </el-form-item>
                <el-form-item label="申请事由" prop="reason">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                        v-model="demandApplicationForm.reason"></el-input>
                </el-form-item>
                <el-form-item label="申请事由" prop="engineer_reason">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                        v-model="demandApplicationForm.engineer_reason"></el-input>
                </el-form-item>
                <el-form-item label="法人单位" prop="legal_entity">
                    <template>
                        <el-select style="width: 100%;" v-model="demandApplicationForm.legal_entity" placeholder="请选择"
                            clearable>
                            <el-option v-for="item in legalPersonOption" :label="item.label" :value="item.value"
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="需求日期" prop="required_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="需求日期"
                        v-model="demandApplicationForm.required_date" style="width: 100%" :picker-options="pickerOptions"
                        clearable></el-date-picker>
                </el-form-item>
                <el-form-item label="项目专案" prop="project">
                    <el-input v-model="demandApplicationForm.project" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
            </el-form>
        </div>
        <!-- <div>
            <el-button type="primary" size="mini" @click="onAddParticulars">添加明细</el-button>
        </div> -->
        <div v-if="is_Readonly">
            <el-table v-if="!$store.state.isphone" :data="demandApplicationForm.details" ref="table_ref" min-width="1300px"
                size="mini" border :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }"
                show-summary :summary-method="getSummaries">
                <el-table-column type="index" label="序号" width="50px" fixed="left">
                </el-table-column>
                <el-table-column prop="product_code" label="品目编码">
                </el-table-column>
                <el-table-column prop="product_title" label="品目名称">
                </el-table-column>
                <el-table-column prop="expense_code_obj" label="费用代码">
                    <template slot-scope="scope">
                        <div v-if="scope.row.expense_code_obj != null">
                            {{ scope.row.expense_code_obj.code_name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="expense_category_arr" label="费用类型">
                    <template slot-scope="scope">
                        <div v-for="(item, i) in scope.row.expense_category_arr" :key="i">
                            {{ item.label }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="quantity" label="数量" width="120px">
                </el-table-column>
                <el-table-column prop="unit_price" label="单价" width="120px">
                </el-table-column>
                <el-table-column prop="unit" label="单位" width="60px">
                </el-table-column>
                <el-table-column prop="total_actual_amount" label="金额" width="120px">
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                </el-table-column>
                <!-- <el-table-column label="操作" min-width="175px" fixed="right">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="primary" size="mini" icon="el-icon-edit"
                                @click="onAddParticulars(scope.$index)"></el-button>
                            <el-button type="success" size="mini" icon="el-icon-plus" @click="onPushDetail()"></el-button>
                            <el-button type="danger" size="mini" icon="el-icon-delete"
                                @click="onDeleteDetail(scope.$index)"></el-button>
                        </div>
                    </template>
                </el-table-column> -->
            </el-table>
            <div v-else>
                <template v-for="(item, index) in demandApplicationForm.details">
                    <div :key="index"
                        style="padding: 10px;background-color: #f8ffed;margin-bottom: 10px;border-radius: 12px;">
                        <div
                            style=" min-width: 300px;font-weight: 600;font-size: 24px;color: rgb(143, 195, 31);padding: 5px;">
                            {{ item.product_code }}
                        </div>
                        <div style="font-weight: 600; font-size: 16px; padding: 5px">
                            {{ item.product_title }}
                        </div>
                        <div class="card_item">
                            <div>码：</div>
                            <div>{{ item.expense_code_obj.code_name }}</div>
                        </div>
                        <div class="card_item">
                            <div>类：</div>
                            <div>
                                <div v-for="(items, i) in item.expense_category_arr" :key="i">
                                    {{ items.label }}
                                </div>
                            </div>
                        </div>
                        <div class="card_item">
                            <div>价：</div>
                            <div>
                                {{ Thousands(item.unit_price) }}
                            </div>
                        </div>
                        <div class="card_item">
                            <div>总：</div>
                            <div>
                                {{ Thousands(accMul(item.quantity, item.unit_price, index)) }}
                            </div>
                        </div>
                        <div class="card_item">
                            <div>摘：</div>
                            <div>{{ item.abstract }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-else>
            <el-table v-if="!$store.state.isphone" :data="demandApplicationForm.details" ref="table_ref" min-width="1300px"
                size="mini" border :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }"
                show-summary :summary-method="getSummaries">
                <el-table-column type="index" label="序号" width="50px" fixed="left">
                </el-table-column>
                <el-table-column prop="product_code" label="品目编码">
                </el-table-column>
                <el-table-column prop="product_title" label="品目名称">
                </el-table-column>
                <el-table-column prop="expense_code_obj" label="费用代码">
                    <template slot-scope="scope">
                        <div v-if="scope.row.expense_code_obj != null">
                            {{ scope.row.expense_code_obj.code_name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="expense_category_arr" label="费用类型">
                    <template slot-scope="scope">
                        <div v-for="(item, i) in scope.row.expense_category_arr" :key="i">
                            {{ item.label }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="quantity" label="数量" width="120px">
                </el-table-column>
                <el-table-column prop="unit_price" label="单价" width="120px">
                </el-table-column>
                <el-table-column prop="unit" label="单位" width="60px">
                </el-table-column>
                <el-table-column prop="total_actual_amount" label="金额" width="120px">
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                </el-table-column>
                <el-table-column label="操作" min-width="175px" fixed="right">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="primary" size="mini" icon="el-icon-edit"
                                @click="onAddParticulars(scope.$index)"></el-button>
                            <el-button type="success" size="mini" icon="el-icon-plus" @click="onPushDetail()"></el-button>
                            <el-button type="danger" size="mini" icon="el-icon-delete"
                                @click="onDeleteDetail(scope.$index)"></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else style="border: 2px dashed #b5e4c2;margin: 10px 0px;border-radius: 12px;padding: 10px;">
                <template v-for="(item, index) in demandApplicationForm.details">
                    <div :key="index"
                        style="border: 1px solid #dcdfe6;padding: 10px;margin-bottom: 10px;border-radius: 12px; ">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <p>产品{{ index + 1 }}</p>
                            <i style="padding: 5px; cursor: pointer;" class="el-icon-close"
                                @click="onDeleteDetail(index)"></i>
                        </div>
                        <el-form :model="item" size="mini">
                            <el-form-item label="品目编码" prop="product_code">
                                <el-input v-model="item.product_code" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="品目名称" prop="product_title">
                                <el-input v-model="item.product_title" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="费用代码" prop="expense_code_obj">
                                <el-input v-model="item.expense_code_obj" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="费用类型" prop="expense_category_arr">
                                <el-input v-model="item.expense_category_arr" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="数量" prop="quantity">
                                <el-input v-model="item.quantity" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="单价" prop="unit_price">
                                <el-input v-model="item.unit_price" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="单位" prop="unit">
                                <el-input v-model="item.unit" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="金额" prop="total_actual_amount">
                                <el-input v-model="item.total_actual_amount" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="备注" prop="remark">
                                <el-input v-model="item.remark" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                        </el-form>
                        <div>
                            <el-button style="width: 100%;" icon="el-icon-edit"
                                @click="onAddParticulars(index)">修改</el-button>
                        </div>
                    </div>
                </template>
                <div>
                    <el-button style="width: 100%;" type="success" icon="el-icon-plus"
                        @click="onPushDetail()">添加</el-button>
                </div>
            </div>
        </div>
        <!-- <div>
            <el-form :model="demandApplicationForm" size="mini" :disabled="is_Readonly">
                <el-form-item label="总金额">
                    <el-input v-model="input" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
                <el-form-item label="附件">
                    <el-input v-model="input" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
            </el-form>
        </div> -->
        <Particulars :isDialog.sync="isDialog" :dialogValue="dialogValue" @onIsDialog="onIsDialog" @onEmploy="onEmploy">
        </Particulars>
    </div>
</template>
<script>
import Particulars from "@/components/ProcessFlow/CRM/Forms/DemandApplication/components/Particulars.vue"
import OrgSelect from "@/components/OA/components/FormControls/OrgSelect/index.vue"
import Decimal from 'decimal.js';
export default {
    props: ["isDemandApplication", "addDemandApplicationForm", 'formTitle', "readonly", "dialogCustomerForm"],
    components: {
        OrgSelect,
        Particulars,
    },
    data() {
        return {
            tableIndex: null,
            isDialog: false,
            dialogValue: null,
            demandApplicationForm: {
                order_form_type: 3,
                creator: null,
                creator_arr: null,
                dept: null,
                filing_date: null,
                reason: null,
                engineer_reason: null,
                legal_entity: null,
                required_date: null,
                details: [
                    {
                        p_charge_code: null,
                        p_charge_classification: null,
                        expense_code_obj: null,
                        expense_category_arr: null,
                        remark: null,
                        product: null,
                        product_code: null,
                        product_title: null,
                        quantity: null,
                        unit: null,
                        specifications: null,
                        unit_price: null,
                        total_actual_amount: null,
                    },
                ]
            },
            is_Readonly: this.readonly == undefined ? false : this.readonly,
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                ],
            },
            legalPersonOption: null,
            demandApplicationRules: {
                creator_arr: [{
                    // validator: this.validateCreator,
                    required: true,
                    message: "请选择申请人",
                    trigger: ["blur", "change"],
                }],
                dept: [{
                    // validator: this.validateDept,
                    required: true,
                    message: "请选择部门",
                    trigger: ["blur", "change"],
                }],
                filing_date: [{
                    required: true,
                    message: "请选择申请日期",
                    trigger: ["blur", "change"],
                }],
                reason: [{
                    required: true,
                    message: "请输入申请事由",
                    trigger: ["blur", "change"],
                }],
                engineer_reason: [{
                    required: true,
                    message: "请输入申请事由",
                    trigger: ["blur", "change"],
                }],
                legal_entity: [{
                    required: true,
                    message: "请选择法人单位",
                    trigger: ["blur", "change"],
                }],
                required_date: [{
                    required: true,
                    message: "请选择需求日期",
                    trigger: ["blur", "change"],
                }],
                project: [{
                    required: true,
                    message: "请输入项目专案",
                    trigger: ["blur", "change"],
                }],
            },
        }
    },
    methods: {
        accMul(arg1, arg2, index) {
            if (arg1 === null || arg2 === null || arg1 === '' || arg2 === '') {
                return "";
            } else {
                var m = 0,
                    s1 = arg1.toString(),
                    s2 = arg2.toString();
                try {
                    m += s1.split(".")[1].length;
                } catch (e) { }
                try {
                    m += s2.split(".")[1].length;
                } catch (e) { }
                let decimal1 = new Decimal(s1)
                let decimal2 = new Decimal(s2)
                let decimal3 = decimal1.times(decimal2)
                this.demandApplicationForm.details[index].total_actual_amount = decimal3 * 1
                return decimal3 * 1
            }
        },
        Thousands(num) {
            return (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
        onPushDetail() {
            this.demandApplicationForm.details.push({
                p_charge_code: null,
                p_charge_classification: null,
                expense_code_obj: null,
                expense_category_arr: null,
                remark: null,
                product: null,
                product_code: null,
                product_title: null,
                quantity: null,
                unit: null,
                specifications: null,
                unit_price: null,
                total_actual_amount: null,
            })
        },
        onDeleteDetail(index) {
            if (this.demandApplicationForm.details.length > 1) {
                this.demandApplicationForm.details.splice(index, 1)
            }
        },
        async getLegalPerson() {
            const res = await this.$http({
                method: "GET",
                url: "app02/label_control/label/?url=TradingLegalEntity/",
            })
            this.legalPersonOption = res.data
        },
        onCreator(val) {
            // console.log(val);
            this.demandApplicationForm.creator_arr = val
        },
        onDept(val) {
          //  console.log(val);
            this.demandApplicationForm.dept = val
        },
        onAddParticulars(val) {
            this.tableIndex = val
            this.dialogValue = JSON.parse(JSON.stringify(this.demandApplicationForm.details[this.tableIndex]))
            this.isDialog = true
        },
        onEmploy(val) {
            // console.log(val);
            // console.log(this.tableIndex);
            this.demandApplicationForm.details.splice(this.tableIndex, 1, val)
            this.isDialog = false
        },
        onIsDialog(val) {
            this.isDialog = val
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toLocaleString(); // Add thousand separator
                } else {
                    sums[index] = "";
                }
            });
            return sums;
        },
        validateCreator(rule, value, callback) {
          //  console.log(value);
            // console.log(rule, value, callback);
            if (value != null && value.length > 0) {
                callback();
            } else {
                callback(new Error('请选择申请人'));
            }
        },
        validateDept(rule, value, callback) {
            // console.log(rule, value, callback);
            if (value != null && value.length > 0) {
                callback();
            } else {
                callback(new Error('请选择部门'));
            }
        },
    },
    watch: {
        readonly: {
            handler(val) {
                if (val == undefined) {
                    this.is_Readonly = false;
                } else {
                    this.is_Readonly = this.readonly;
                }
            },
            deep: true,
        },
        demandApplicationForm: {
            handler(val) {
                if (this.isDemandApplication == false) {
                    this.$emit("onAddOutboundOrder", this.demandApplicationForm);
                }
            },
            deep: true,
        },
        addDemandApplicationForm: {
            handler(val) {
                if (this.formTitle == "零星申请") {
                    if (val !== undefined) {
                        for (const key in val) {
                            this.demandApplicationForm[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        },
        "demandApplicationForm.creator_arr": {
            handler(val) {
                this.demandApplicationForm.creator = this.demandApplicationForm.creator_arr[0].user_sys_id
            },
            deep: true,
        }
    },
    mounted() {

    },
    created() {
        this.getLegalPerson()
    },
}
</script>
<style lang="less" scoped>
.card_item {
    display: flex;
    justify-content: flex-start;
}

/deep/.el-table__footer-wrapper {
    tr {
        td {
            text-align: center;
        }
    }
}
</style>