<template>
    <div style="">
        <!-- {{ defaultValue }}{{ isDefaultValue }} -->
        <el-date-picker style="width: 100%" v-model="timeRange" v-bind="{ ...$props, ...$attrs }" placeholder="选择日期">
        </el-date-picker>
    </div>
</template>
<script>
export default {
    model: {
        prop: "value",
        event: "change",
    },
    props: [
        "value",
        "label",
        "tag",
        "tagIcon",
        "placeholder",
        "defaultValue",
        "isDefaultValue",
        "type",
        "span",
        "labelWidth",
        "style",
        "disabled",
        "clearable",
        "required",
        "format",
        "value-format",
        "readonly",
        "regList",
        "changeTag",
        "proCondition",
        "asSummary"
    ],
    name: "fc-date-picker",
    data() {
        return {
            timeRange: this.value || this.defaultValue
        }
    },
    watch: {
        timeRange(val) {
            this.$emit('change', val)
        },
        value(val) {
            if (val !== this.timeRange) {
                this.timeRange = val;
            }
        },
        isDefaultValue: {
            handler(val) {
                if (val) {
                    // console.log(this.defaultValue);
                    if (this.defaultValue == null) {
                        this.timeRange = new Date().format(this.format)
                    }
                }
            },
            deep: true,
            immediate: true

        }
    },
}
</script>