<template>
    <div class="fc_suiteform">
        <!-- {{ readonly }}<br>
        {{ source }}<br>
        {{ disabled }}<br>
        只读{{ required }} -->
        <AddAssignmentRequest ref="AddAssignmentRequest" v-if="title == '客户申请'" :dialogCustomerForm="customer"
            :readonly="readonly" @onAssignmentRequest="onAddForm" :AddAssignmentRequestForm="formObj"
            :isAssignmentRequest="isForm" :formTitle="title">
        </AddAssignmentRequest>
        <AddSupplierAssignment ref="AddSupplierAssignment" v-else-if="title == '供应商分配申请'" :dialogCustomerForm="customer"
            :readonly="readonly" @onAssignmentRequest="onAddForm" :AddAssignmentRequestForm="formObj"
            :isAssignmentRequest="isForm" :formTitle="title">
        </AddSupplierAssignment>
        <SampleSubmissions ref="SampleSubmissions" v-else-if="title == '送样申请'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :sampleSubmissionsFormCustomer="customer" :sampleSubmissionsForm="formObj"
            :isSampleSubmissions="isForm" :formTitle="title"></SampleSubmissions>
        <AddQuotation ref="AddQuotation" v-else-if="title == '报价申请'" :readonly="readonly" @onAddOutboundOrder="onAddForm"
            :addQuotationForm="formObj" :addQuotationCustomer="customer" :isAddQuotation="isForm" :formTitle="title">
        </AddQuotation>
        <AddOrders ref="AddOrders" v-else-if="title == '订单申请'" :readonly="readonly" @onAddOutboundOrder="onAddForm"
            :addOrderForm="formObj" :addOrdersCustomer="customer" :isAddOrder="isForm" :formTitle="title">
        </AddOrders>
        <AddLongTermAgreement ref="AddOrders" v-else-if="title == '长期合同'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :addOrderForm="formObj" :addOrdersCustomer="customer" :isAddOrder="isForm"
            :formTitle="title">
        </AddLongTermAgreement>
        <AddOutboundOrder ref="AddOutboundOrder" v-else-if="title == '出库申请(亿看)'" :readonly="readonly" :formTitle="title"
            :isSaveAddOutboundOrder="isForm" :addOrderForm="formObj" :addOrdersCustomer="customer"
            @onAddOutboundOrder="onAddForm">
        </AddOutboundOrder>
        <AddOutbounDeliveryOrder ref="AddOutboundOrder" v-else-if="title == '出库申请'" :readonly="readonly" :formTitle="title"
            :isSaveAddOutboundOrder="isForm" :addOrderForm="formObj" :addOrdersCustomer="customer"
            @onAddOutboundOrder="onAddForm">
        </AddOutbounDeliveryOrder>
        <RefundOrder ref="RefundOrder" v-else-if="title == '售后申请'" :readonly="readonly" @onAddOutboundOrder="onAddForm"
            :refundOrderForm="formObj" :isRefundOrder="isForm" :formTitle="title"></RefundOrder>
        <AddBackMoney ref="RefBackMoney" v-else-if="title == '回款申请'" :readonly="readonly" @onAddBackMoney="onAddForm"
            :backMoneyForm="formObj" :addBackMoneyCustomer="customer" :isAddBackMoney="isForm" :formTitle="title">
        </AddBackMoney>
        <AddPurchasing ref="AddPurchasing" v-else-if="title == '采购入库申请'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :addOrderForm="formObj" :addOrdersCustomer="customer" :isAddOrder="isForm"
            :formTitle="title">
        </AddPurchasing>
        <AddProductionMaterialRequisition ref="AddProductionMaterialRequisition" v-else-if="title == '生产领料'"
            :readonly="readonly" @onAddOutboundOrder="onAddForm" :addOrderForm="formObj" :addOrdersCustomer="customer"
            :isAddOrder="isForm" :formTitle="title">
        </AddProductionMaterialRequisition>
        <AddDemandApplication ref="AddDemandApplication" v-else-if="title == '零星申请'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :addDemandApplicationForm="formObj" :dialogCustomerForm="customer"
            :isDemandApplication="isForm" :formTitle="title">
        </AddDemandApplication>
        <AddPurchasingRequisition ref="AddPurchasingRequisition" v-else-if="title == '采购需求申请'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :addPurchasingRequisitionForm="formObj" :dialogCustomerForm="customer"
            :isPurchasingRequisition="isForm" :formTitle="title"></AddPurchasingRequisition>
        <ProcurementPlan ref="AddPurchasingRequisition" v-else-if="title == '采购计划'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :addPurchasingRequisitionForm="formObj" :dialogCustomerForm="customer"
            :isPurchasingRequisition="isForm" :formTitle="title"></ProcurementPlan>
        <ProcurementOrders ref="AddPurchasingRequisition" v-else-if="title == '采购订单'" :readonly="readonly"
            @onAddOutboundOrder="onAddForm" :addPurchasingRequisitionForm="formObj" :dialogCustomerForm="customer"
            :isPurchasingRequisition="isForm" :formTitle="title"></ProcurementOrders>
    </div>
</template>
<script>
//客户申请
import AddSupplierAssignment from "@/components/ProcessFlow/CRM/Forms/SupplierAssignment/AddSupplierAssignment.vue"
//客户申请
import AddAssignmentRequest from "@/components/ProcessFlow/CRM/Forms/AssignmentRequest/AddAssignmentRequest.vue"
// 送样申请
import SampleSubmissions from "@/components/ProcessFlow/CRM/Forms/SampleSubmissions/AddSampleSubmissions.vue"
//报价申请
import AddQuotation from "@/components/ProcessFlow/CRM/Forms/Quotation/AddQuotation.vue"
//订单申请
import AddOrders from "@/components/ProcessFlow/CRM/Forms/Orders/AddOrders.vue"
//长期合同
import AddLongTermAgreement from "@/components/ProcessFlow/CRM/Forms/LongTermAgreement/AddLongTermAgreement.vue"
// 出库申请
import AddOutboundOrder from "@/components/ProcessFlow/CRM/SalesOutbound/AddOutboundOrder.vue"
// 出库申请(亿看)
import AddOutbounDeliveryOrder from "@/components/ProcessFlow/CRM/Forms/OutboundDeliveryOrder/AddOutbounDeliveryOrder.vue"
// 退货申请
import RefundOrder from "@/components/ProcessFlow/CRM/Forms/RefundOrder/RefundOrder.vue"
// 回款申请
import AddBackMoney from "@/components/ProcessFlow/CRM/Forms/BackMoney/AddBackMoney.vue"
//采购入库申请
import AddPurchasing from "@/components/ProcessFlow/CRM/Forms/Storage/Purchasing/AddPurchasing.vue"
// 生产领料
import AddProductionMaterialRequisition from "@/components/ProcessFlow/CRM/Forms/ProductionMaterialRequisition/AddProductionMaterialRequisition.vue"
// 零星采购
import AddDemandApplication from "@/components/ProcessFlow/CRM/Forms/DemandApplication/AddDemandApplication.vue"
// 采购需求申请
import AddPurchasingRequisition from "@/components/ProcessFlow/CRM/Forms/PurchasingRequisition/AddPurchasingRequisition.vue"
//采购计划
import ProcurementPlan from "@/components/ProcessFlow/CRM/Forms/ProcurementPlan/AddProcurementPlan.vue"
//采购订单
import ProcurementOrders from "@/components/ProcessFlow/CRM/Forms/ProcurementOrders/AddProcurementOrders.vue"

import { timeCalculate } from '@/utils'
export default {
    name: "fc_suiteform",
    model: {
        prop: ['value', 'dialogForm'],
        event: 'change'
    },
    props: [
        'value',
        'tagIcon',
        'tag',
        'formId',
        'disabled',
        'required',
        "conf",
        "dialogForm",
        'readonly',
        'source',
        'style',
        "defaultValue",
        "pluginList"
    ],
    data() {
        return {
            title: null,
            isForm: false,
            formObj: null,
            flag: false,
            customer: null,

        }
    },
    components: {
        AddSupplierAssignment,
        AddQuotation,
        AddOutboundOrder,
        AddOrders,
        AddLongTermAgreement,
        AddAssignmentRequest,
        SampleSubmissions,
        RefundOrder,
        AddBackMoney,
        AddPurchasing,
        AddOutbounDeliveryOrder,
        AddProductionMaterialRequisition,
        AddDemandApplication,
        AddPurchasingRequisition,
        ProcurementPlan,
        ProcurementOrders,
    },
    watch: {
        dialogForm: {
            handler(val) {
                this.customer = val
            },
            deep: true,
            immediate: true
        },
        defaultValue: {
            handler(val) {
                this.formObj = val
            },
            deep: true,
            immediate: true
        },
        // value: {
        //     handler(val) {
        //       //  console.log(val);
        //     }
        // },
        timeRange: {
            handler(val) {
                if (val.length != 0) {
                    let res = timeCalculate(new Date(val[0]).getTime(), new Date(val[1]).getTime())
                    this.timeRangeValue = res
                } else {
                    this.timeRangeValue = ""
                }
            },
        },
        source: {
            handler(val) {
              //  console.log(val);
                this.title = val
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        onAddForm(data) {
            // console.log(data);
            data.is_suit = true
            this.$emit("change", data)
        },
        // onAddQuotation(val) {
        //     data.is_suit=true
        //     this.$emit("change", val)
        // },
        // onAddOutboundOrder(data) {
        //     data.is_suit=true
        //     this.$emit("change", data)
        // },
        // onAddBackMoney(data) {
        //     data.is_suit=true
        //     this.$emit("change", data)
        // },
    }
}
</script>