<template>
    <div class="fc_search">
        <!-- <div>{{ gesearch }}</div> -->
        <el-input v-model="state" :disabled="disabled" @keyup.enter.native="search" @blur.once="search"></el-input>
        <el-dialog :visible.sync="isDialogTableVisible" :modal="false" :close-on-click-modal="false" v-dialogDrags>
            <span slot="title">
                <div class="title">
                    <div>{{ label }}</div>
                    <div v-if="!Multi_selected">
                        <div>
                            <el-select style="width: 250px;" clearable :popper-append-to-body="false" v-model="search_value"
                                size="mini" filterable placeholder="请选择" @keyup.enter.native="btn_search">
                                <el-option v-for="item in auto_complete_data" :key="item.value" :label="item.label"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <span>
                                <el-button type="primary" size="mini" @click="btn_search">搜索</el-button>
                            </span>
                        </div>
                    </div>
                </div>
            </span>
            <!-- <div>{{ this.cell_width }}</div> -->

            <div>
                <el-steps :active="num" align-center process-status="wait">
                    <template v-for="item in step">
                        <el-step :key="item" :title="item"></el-step>
                    </template>
                </el-steps>
                <h4><span v-if="flang"><span>{{ num }}.</span>{{ step[num - 1] }}</span></h4>
                <div>

                    <div v-if="!this.Multi_selected">
                        <div class="box">
                            <el-table ref="multipleTable" :data="tableData" border @row-click="rowClick" width="100%"
                                highlight-current-row>
                                <el-table-column label="选择" width="55" align="center">
                                    <template slot-scope="scope">
                                        <el-radio v-model="tenderProjectId" :label="scope.row.value">
                                            <span></span>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <template v-for="(item, key) in this.header_data">
                                    <el-table-column :label="item" align="center" :key="key" :width="cell_width_px(key)">
                                        <template slot-scope="scope">
                                            <span v-if="key == 0">
                                                {{ scope.row.value }}
                                            </span>
                                            <span v-else-if="key == 1">
                                                {{ scope.row.label }}
                                            </span>
                                            <span v-else>{{ filterdata(scope.row['data' + key]) }}</span>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                        </div>
                    </div>
                    <div v-else>
                        <div>
                            <el-table ref="multipleTable1" :data="tableData" border
                                @selection-change="handleSelectionChange" width="100%">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <template v-for="(item, key) in this.header_data">
                                    <el-table-column :label="item" align="center" :key="key">
                                        <template slot-scope="scope">
                                            <span v-if="key == 0">
                                                {{ scope.row.value }}
                                            </span>
                                            <span v-else-if="key == 1">
                                                {{ scope.row.label }}
                                            </span>
                                            <span v-else>{{ filterdata(scope.row['data' + key]) }}</span>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                        </div>

                    </div>
                </div>

            </div>
            <span slot="footer">
                <el-scrollbar>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :page-sizes="[10, 20, 50, 100]" :page-size="page_size" layout="sizes, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </el-scrollbar>
                <div>
                    <el-button type="primary" size="mini" @click="btn_returned">重置</el-button>
                    <el-button :disabled="this.num == step.length ? true : false" type="primary" size="mini"
                        @click="btn_search">搜索</el-button>
                    <el-button :disabled="this.num == step.length ? false : true" size="mini"
                        @click="confirm">确定</el-button>
                    <el-button size="mini" @click="cancel">取消</el-button>
                </div>

            </span>
        </el-dialog>
    </div>
</template>
<script>
import { number } from 'echarts';
import { log } from 'util';
export default {
    model: {
        prop: "value",
        event: "change",
    },
    props: [
        'value',
        'label',
        'tag',
        'tagIcon',
        'placeholder',
        'defaultValue',
        'span',
        'labelWidth',
        'style',
        'clearable',
        'source',
        'tablelist',
        'prepend',
        'append',
        'prefix-icon',
        'suffix-icon',
        'maxlength',
        'show-word-limit',
        'readonly',
        'disabled',
        'required',
        'regList',
        'changeTag',
        'proCondition',
        'asSummary',
        'formId',
        'renderKey',
        'layout',
        'gesearch',
        'config_children'

    ],
    name: "fc_search",

    data() {
        return {
            state: "",  //控件回显值
            restaurants: [],
            isDialogTableVisible: false,
            tableData: [], //模糊搜索表格控件每一步的返回值
            header_data: [], //返回值表头
            cell_width: [], //返回值表格宽度
            total: 0, //总条数
            size: 1, //页
            page_size: 10, //每页条数
            more_multipleSelection: [],
            tenderProjectId: null, //选中的radio的id
            auto_complete_data: [], //自动补全数据
            step: [],  //进度条步骤标识字符串array
            search_value: '', //搜索控件的显示值
            num: 0, //步骤下标
            Multi_selected: true, //是否搜索
            // Multi_selected: true, //
            flang: false,
            multipleSelection: {}, //每一步选择的查询对象
            initial_control: [],//初始表格每条数所有控件
            initial_table: [],//初始表格每条数据
            str: '',

        };
    },
    watch: {
        isDialogTableVisible: {
            handler(val) {
                if (val == true) {
                    let data = {
                        keyword: this.state,
                        page_number: this.size,
                        per_page: this.page_size,
                    }
                    this.getlist(data)
                    this.initialControl()
                } else {
                    this.num = 0
                    this.flang = false
                    this.tableData = []
                    this.header_data = []
                    this.total = 0
                    this.multipleSelection = null
                }
            },
            immediate: true,//实时监听
        },
        defaultValue: {
            handler(val) {
                // console.log(val, 'dee');
                if (val == undefined) {
                    this.state = ""
                } else {
                    this.state = eval("(" + this.defaultValue + ")").label
                }
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler(val) {
                // console.log(val, 'vvvvv');
                if (typeof val === 'object') {
                  //  console.log(val);
                    this.state = val.label
                } else {
                    // console.log(this.value);
                    this.state = eval("(" + this.value + ")").label
                  //  console.log(this.state);
                }
            },
            immediate: true,
            deep: true
        }
    },
    components: {}, //导入的组件
    created() {
    },
    methods: {

        cell_width_px(key) {
            // console.log(a, b);

            return this.cell_width[key]
        },
        filterdata(data) {
            // console.log(typeof data);
            if (typeof data == 'string') {
                return data
            } else if (typeof data == 'number') {
                return data
            } else if (typeof data == 'object') {
                if (data == null) {
                    return null
                } else {
                    let str = ""
                    Object.values(data).forEach(item => {
                        str = str + item + ","
                    })
                    return str
                }
            } else if (typeof data == "boolean") {
                if (data == true) {
                    return "是"
                } else {
                    return "否"
                }
            }
        },
        async getlist(data) {
            const res = await this.$http({
                method: "POST",
                url: this.source.split("*")[0],
                // url: 'crm/get_order_product_table_product2/',
                headers: {
                    "content-type": 'application/json'
                },
                data: JSON.stringify(data)
            })
            // console.log(res);
            if (res.status == 200) {

                if (res.data.auto_complete_data !== null) {
                    this.auto_complete_data = res.data.auto_complete_data
                    this.step = res.data.step
                    this.num = this.num + 1
                    this.flang = true
                } else {
                    this.flang = false
                }

                if (res.data.operate_type == "Multi-selected") {
                    this.Multi_selected = true
                } else {
                    this.Multi_selected = false

                }
              //  console.log(res);
                if (res.data.main_data !== null) {
                    // console.log('111');
                    this.tableData = res.data.main_data.tableData
                    this.header_data = res.data.main_data.header_data
                    this.total = res.data.main_data.total_records
                    this.cell_width = res.data.main_data.cell_width
                  //  console.log(this.cell_width);
                    this.$message.success('查找成功')
                }
            } else {
                this.$message.error(res.data)
            }

        },
        async getlist1(data) {
            const res = await this.$http({
                method: "POST",
                url: this.source.split("*")[0],
                // url: 'crm/get_order_product_table_product2/',
                headers: {
                    "content-type": 'application/json'
                },
                data: JSON.stringify(data)
            })
            // console.log(res);
            if (res.status == 200) {
                if (res.data.auto_complete_data !== null) {
                    this.auto_complete_data = res.data.auto_complete_data
                    this.step = res.data.step
                    // this.num = this.num + 1
                    this.flang = true
                } else {
                    this.flang = false
                }

                if (res.data.operate_type == 'Multi-selected') {
                    this.Multi_selected = true
                } else {
                    this.Multi_selected = false

                }
              //  console.log(res);
                if (res.data.main_data !== null) {
                    // console.log('111');
                    this.tableData = res.data.main_data.tableData
                    this.header_data = res.data.main_data.header_data

                    this.total = res.data.main_data.total_records
                    this.$message.success('查找成功')
                }
            } else {
                this.$message.error(res.data)
            }

        },
        btn_returned() {
            this.num = 0
            let arr = JSON.parse(this.initial_table)
            arr.forEach((item, i) => {
                if (item.tag == this.str) {
                    item.value = null
                }
            })
            this.initial_table = JSON.stringify(arr)

            let data = {
                page_number: this.size,
                per_page: this.page_size,
                keyword: ''
            }
            this.getlist(data)

        },
        btn_search() {
            // console.log(this.search_value);
            let data = {
                page_number: this.size,
                per_page: this.page_size,
            }
          //  console.log(this.multipleSelection);
            if (this.multipleSelection == null) {//搜索框搜索
                data['keyword' + this.num] = this.search_value.value
                this.getlist(data)
                this.amount(this.search_value)
            } else {//单选择搜索
                // console.log(this.multipleSelection);
                this.amount(this.multipleSelection)
                data['keyword' + this.num] = this.multipleSelection.value
                this.getlist(data)

            }
            this.search_value = null
            this.multipleSelection = null

        },
        amount(data) {//收集搜索数据
            let each_search = {}
            let sum_search = {}
            each_search.value = data.value
            each_search.label = data.label
            sum_search[this.header_data[1]] = each_search
            this.initialTable(sum_search)
        },
        initialControl() {//初始控件
            // let arr = []
            this.config_children.forEach(items => {
                let obj = {}
                obj[items.tag] = items.label
                this.initial_control.push(obj)
            })
            this.initial_table = JSON.stringify(this.gesearch[0])
        },
        initialTable(obj) {
            // console.log(Object.keys(obj));
            let arr = JSON.parse(this.initial_table)
            arr.forEach((item, i) => {
                if (Object.keys(obj) == this.initial_control[i][item.tag]) {
                    // console.log(item);
                    this.str = item.tag
                    item.value = JSON.stringify(obj[(this.initial_control[i][item.tag])])
                }
                // console.log;
            })
            // console.log(arr);
            this.initial_table = JSON.stringify(arr)
        },
        echo(data) {
            let dataarr = null
            if (this.Multi_selected === false) {
                dataarr = []
                dataarr.push(data)
                let arr = []
                let arr1 = this.shuju(dataarr)
                // console.log(arr1);
                arr1.forEach((item, i) => {
                    let obj = {}
                    for (const key in item) {
                      //  console.log(key);
                        this.config_children.forEach((items, j) => {
                            if (items.label == key) {
                                obj[items.formId] = item[key]
                            }

                        })
                    }
                    arr.push(obj)
                })
              //  console.log(arr);

              //  console.log(this.initial_table);
                let list = []
                let initial_table = JSON.parse(this.initial_table)

                arr.forEach((item, i) => {

                  //  console.log(item);
                    for (const key in item) {
                        initial_table.forEach(items => {
                            if (key == items.formId) {
                                items.value = item[key]
                            }
                        })
                    }

                })
                list.push(initial_table)
                // console.log(initial_table);
                return list
            } else {
                // dataarr = data
                let arr = []
                let arr1 = this.shuju(data)
                data.forEach((item, i) => {
                    arr.push(JSON.parse(this.initial_table))
                })
                arr.forEach((item, i) => {
                    // let str = JSON.parse(item)
                    item.forEach((items, j) => {
                        if (items.tag == 'fc_search') {
                            items.value = data[i]
                        } else if (items.tag == 'el-input-number') {
                            items.value = this.add(items.formId, arr1, i, j)
                        } else if (items.tag == 'el-input') {
                            items.value = null
                        }
                    })
                })
                return arr
            }
            /*  let arr = []
             let arr1 = this.shuju(dataarr)
             data.forEach((item, i) => {
                 arr.push(JSON.parse(this.initial_table))
             })
             arr.forEach((item, i) => {
                 item.forEach((items, j) => {
                     if (items.tag == 'fc_search') {
                         items.value = JSON.stringify(data[i])
                     } else if (items.tag == 'el-input-number') {
                         items.value = this.add(items.formId, arr1, i, j)
                     } else if (items.tag == 'el-input') {
                         items.value = null
                     }
                 })
             })
             return arr */
        },
        add(id, arr1, i, j) {

            return arr1[i][this.config_children[j].label]
        },
        shuju(data) {
            // console.log(this.Multi_selected);
            // this.this.Multi_selected


            let str = []
            // console.log(data);
            data.forEach((item) => {
                let arr = []
                for (const key in item) {
                    arr.push(item[key])
                }
                str.push(arr)
            })
            // console.log(str);
            let arr1 = []
            let header_data = this.header_data
            // console.log(header_data);
            str.forEach((item) => {
                let obj = {}
                item.forEach((items, j) => {
                    // console.log(items);
                    obj[header_data[j]] = items
                })
                arr1.push(obj)
            })
            // console.log(arr1);
            return arr1

        },
        handleSizeChange(val) {
            this.page_size = val
            let data = {
                keyword: this.state,
                page_number: this.size,
                per_page: this.page_size,
            }
            this.getlist1(data)
        },
        handleCurrentChange(val) {
            this.size = val
            let data = {
                keyword: this.state,
                page_number: this.size,
                per_page: this.page_size,
            }
            this.getlist1(data)
        },
        confirm() {
            // console.log('1111');
            // console.log(this.multipleSelection);
            let data = this.echo(this.multipleSelection)
          //  console.log(data);
            this.$emit('shuju', data)
            this.isDialogTableVisible = false
        },
        cancel() {
            this.isDialogTableVisible = false
        },
        search() {

            this.isDialogTableVisible = true
        },
        rowClick(row) {
            this.tenderProjectId = row.value;
            this.multipleSelection = row
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    },
};
</script>
<style lang="less" scoped>
/deep/.el-table .el-table__body td:not(:first-child) {
    vertical-align: middle !important;
}

/deep/.el-dialog {
    min-width: 600px !important;
    height: 600px;
    z-index: 2;
}

/deep/.el-dialog::-webkit-scrollbar {
    // z-index: 1 !important;
    display: none;
}

/deep/.el-dialog__footer {
    background: #FFFFFF;
    position: sticky;
    bottom: -0.5px;
    z-index: 3;
}

/deep/.el-dialog__header {
    background-color: rgb(89,87,87) !important;
    padding: 0px;
    line-height: 40px;
    position: sticky;
    top: 0;
    z-index: 3;
    color: white !important;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:first-child {
            margin-left: 20px;
        }

        div:last-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 40px;

            .inp {
                margin-right: 20px;
            }
        }
    }

    .el-dialog__headerbtn {
        top: 10px;
        right: 10px;
    }
}
</style>
