<template>
    <div>
        <el-dialog title="添加" v-if="isParticulars" :top="$store.state.isphone ? '5vh' : '15vh'"
            :visible.sync="isParticulars" :width="$store.state.isphone ? '100%' : '40%'" v-dialogDrags
            :close-on-click-modal="false" :show-close="false" :fullscreen="isParticularsFullscreen">
            <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                <div class="title">
                    <span style="padding: 10px;">添加明细</span>
                </div>
                <div>
                    <i v-if="isParticularsFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isParticularsFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isParticularsFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onIsParticulars"></i>
                </div>
            </div>
            <div style="position: relative;">
                <i v-if="role_ids.includes(3)" @click.stop="isSettingTable = true"
                    style="position: absolute;top: 0;right: 0;font-size: 16px;z-index: 9;cursor: pointer;"
                    class="el-icon-setting"></i>
                <el-form :model="particularsForm" size="mini" ref="particularsRef" :rules="particularsRules">
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['product_code'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['product_code'].basic_settings.show_name == '' || settingForm.control_setting['product_code'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['product_code'].basic_settings.show_name"
                        prop="product_code">
                        <el-input v-model="particularsForm.product_code" placeholder="请输入内容" clearable>
                            <el-button size="mini" slot="prepend" icon="el-icon-search"
                                @click="onProductSearch()"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['product_title'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['product_title'].basic_settings.show_name == '' || settingForm.control_setting['product_title'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['product_title'].basic_settings.show_name"
                        prop="product_title">
                        <el-input v-model="particularsForm.product_title" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['quantity'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['quantity'].basic_settings.show_name == '' || settingForm.control_setting['quantity'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['quantity'].basic_settings.show_name"
                        prop="quantity">
                        <el-input-number style="width: 100%;" v-model="particularsForm.quantity" controls-position="right"
                            :min="minValue(settingForm.control_setting['quantity'].numeric_settings.max_digits, settingForm.control_setting['quantity'].numeric_settings.decimal_places)"
                            :max="maxValue(settingForm.control_setting['quantity'].numeric_settings.max_digits, settingForm.control_setting['quantity'].numeric_settings.decimal_places)"></el-input-number>
                    </el-form-item>
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['unit'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['unit'].basic_settings.show_name == '' || settingForm.control_setting['unit'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['unit'].basic_settings.show_name"
                        prop="unit">
                        <el-input v-model="particularsForm.unit" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['specifications'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['specifications'].basic_settings.show_name == '' || settingForm.control_setting['specifications'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['specifications'].basic_settings.show_name"
                        prop="specifications">
                        <el-input v-model="particularsForm.specifications" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['unit_price'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['unit_price'].basic_settings.show_name == '' || settingForm.control_setting['unit_price'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['unit_price'].basic_settings.show_name"
                        prop="unit_price">
                        <el-input-number style="width: 100%;" v-model="particularsForm.unit_price" controls-position="right"
                            :min="minValue(settingForm.control_setting['unit_price'].numeric_settings.max_digits, settingForm.control_setting['unit_price'].numeric_settings.decimal_places)"
                            :max="maxValue(settingForm.control_setting['unit_price'].numeric_settings.max_digits, settingForm.control_setting['unit_price'].numeric_settings.decimal_places)"></el-input-number>
                        <!-- <el-input v-model="particularsForm.unit_price" placeholder="请输入内容" clearable></el-input> -->
                    </el-form-item>
                    <el-form-item
                        v-if="haveCommonElement(settingForm.control_setting['total_actual_amount'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['total_actual_amount'].basic_settings.show_name == '' || settingForm.control_setting['total_actual_amount'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['total_actual_amount'].basic_settings.show_name"
                        prop="total_actual_amount">
                        <el-input-number style="width: 100%;" v-model="particularsForm.total_actual_amount"
                            controls-position="right"
                            :min="minValue(settingForm.control_setting['total_actual_amount'].numeric_settings.max_digits, settingForm.control_setting['total_actual_amount'].numeric_settings.decimal_places)"
                            :max="maxValue(settingForm.control_setting['total_actual_amount'].numeric_settings.max_digits, settingForm.control_setting['total_actual_amount'].numeric_settings.decimal_places)"></el-input-number>
                        <!-- <el-input v-model="particularsForm.total_actual_amount" placeholder="请输入内容" clearable></el-input> -->
                    </el-form-item>
                    <el-form-item prop="expense_code_obj"
                        v-if="haveCommonElement(settingForm.control_setting['expense_code_obj'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['expense_code_obj'].basic_settings.show_name == '' || settingForm.control_setting['expense_code_obj'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['expense_code_obj'].basic_settings.show_name">
                        <template>
                            <el-select style="width: 100%;" v-model="particularsForm.expense_code_obj" placeholder="请选择">
                                <el-option v-for="item in chargeCodeOption" :label="item.code_name" :value="item"
                                    :key="item.id">
                                </el-option>
                            </el-select>
                        </template>
                        <!-- <el-input v-model="particularsForm.expense_code_obj" placeholder="请输入内容" clearable></el-input> -->
                    </el-form-item>
                    <el-form-item prop="expense_category_arr"
                        v-if="haveCommonElement(settingForm.control_setting['expense_category_arr'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['expense_category_arr'].basic_settings.show_name == '' || settingForm.control_setting['expense_category_arr'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['expense_category_arr'].basic_settings.show_name">
                        <div
                            class="capsule el-input el-input--mini el-input-group el-input-group--prepend el-input--suffix">
                            <div class="capsule1 el-icon-search el-input-group__prepend" @click="onExpenseCategory"></div>
                            <div class="capsule2">
                                <span style="
                    display: inline-block;
                    background: #c0c4cc;
                    margin-left: 5px;
                    line-height: 15px;
                    padding: 2px;
                    border-radius: 4px;
                  " v-for="(item, i) in expenseCategory" :key="i">{{ item.label }}
                                    <span class="el-icon-close" @click="expenseCategory.splice(i, 1)"></span></span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item prop="remark"
                        v-if="haveCommonElement(settingForm.control_setting['remark'].visible_rule.role_limit, role_ids)"
                        :label="settingForm.control_setting['remark'].basic_settings.show_name == '' || settingForm.control_setting['remark'].basic_settings.show_name == null ? '标题1' : settingForm.control_setting['remark'].basic_settings.show_name">
                        <el-input v-model="particularsForm.remark" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                </el-form>
                <ExpenseCategory v-if="isCategory" :isExpenseCategory.sync="isCategory" :categoryValue="expenseCategory"
                    @onIsCategory="onIsCategory" @onEmploy="onCategoryEmploy">
                </ExpenseCategory>
                <SettingForm v-if="isSettingTable" :settingTable="settingTable" :isSettingTable.sync="isSettingTable"
                    :settingForm="settingForm" :settingTitle="'设置表单'" @onIsSetting="onIsSetting" @onSetting="onSetting">
                </SettingForm>
                <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'"
                    :isChoose="true" :searchValue="productValue" @onIsVisible="productVisible" @onApplied="productApplied">
                </inquireDialog>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="onIsParticulars">取 消</el-button>
                <el-button size="mini" type="primary" @click="onEmployParticulars">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import SettingForm from "@/components/Tools/SettingForm/SettingForm.vue"
import ExpenseCategory from "@/components/ERP/Setting/components/ExpenseCategory.vue"
import { log } from 'util'
export default {
    props: {
        isDialog: {
            type: Boolean,
            default: false,
        },
        dialogValue: {
            type: Object,
            default: {}
        }
    },
    components: {
        ExpenseCategory, SettingForm, inquireDialog,
    },
    data() {
        return {
            isProduct: false,
            productDialogObj: {
                searchField: "search",
                url: "crm/special_offer/",
                params: {
                    per_page: 10,
                    page: 1,
                    // model_name: "SpecialOfferTable",
                    // sys_name: 3,
                    // user: localStorage.getItem("user_id"),
                    search: "",
                    distinct: 1,
                    is_using: true,
                },
            },

            /* 设置表单 */
            role_ids: JSON.parse(window.localStorage.getItem('role_ids')),
            isSettingTable: false,
            settingForm: {
                form_root: '需求添加明细',
                classification: '添加',
                control_setting: {
                    product_code: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '品目编码', //显示名称
                            placeholder: '请输入品目编码'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    product_title: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '品目名称', //显示名称
                            placeholder: '请输入品目名称'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    quantity: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '数量', //显示名称
                            placeholder: '请输入数量'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    unit: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '单位', //显示名称
                            placeholder: '请输入单位'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    specifications: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '规格', //显示名称
                            placeholder: '请输入规格'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    unit_price: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '单价', //显示名称
                            placeholder: '请输入单价'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    total_actual_amount: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '预估金额', //显示名称
                            placeholder: '请输入预估金额'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    expense_code_obj: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '费用代码', //显示名称
                            placeholder: '请输入费用代码'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    expense_category_arr: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '费用类别', //显示名称
                            placeholder: '请输入费用类别'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                    remark: {
                        default_value: null, //默认值
                        basic_settings: { //基础设置
                            show_name: '备注', //显示名称
                            placeholder: '请输入备注'
                        },
                        permissions: { //权限
                            require: false, //是否必填
                            update: true, //是否可以修改
                            edit: true, //是否可编辑
                        },
                        numeric_settings: { //数字专有设置
                            decimal_places: null, //小数位数
                            max_digits: null, //最大位数
                        },
                        visible_rule: { //可见规则
                            role_limit: [], //限定此id的角色可见
                        }
                    },
                }
            },
            settingTable: [
                {
                    title: '品目编码',
                    temp_key: 'product_code',
                },
                {
                    title: '品目名称',
                    temp_key: 'product_title',
                },
                {
                    title: '数量',
                    temp_key: 'quantity',
                },
                {
                    title: '单位',
                    temp_key: 'unit',
                },
                {
                    title: '规格',
                    temp_key: 'specifications',
                },
                {
                    title: '单价',
                    temp_key: 'unit_price',
                },
                {
                    title: '预估金额',
                    temp_key: 'total_actual_amount',
                },
                {
                    title: '费用代码',
                    temp_key: 'expense_code_obj',
                },
                {
                    title: '费用类型',
                    temp_key: 'expense_category_arr',
                },
                {
                    title: '备注',
                    temp_key: 'remark',
                },
            ],

            /* 费用类别 */
            expenseCategory: [],
            isCategory: false,

            /* 费用代码 */
            chargeCodeOption: null,

            /* 添加明细 */
            isParticulars: false,
            isParticularsFullscreen: false,
            particularsForm: {
                // expense_type: null,
                expense_code_obj: null,
                p_charge_code: null,
                p_charge_classification: null,
                expense_category_arr: null,
                remark: null,
                product: null,
                product_code: null,
                product_title: null,
                quantity: null,
                unit: null,
                specifications: null,
                unit_price: null,
                total_money: null,
            },
            particularsRules: {
                "product_code": [
                    {
                        "required": true,
                        "message": "请输入品目编码",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "unit": [
                    {
                        "required": true,
                        "message": "请输入单位",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "quantity": [
                    {
                        "required": true,
                        "message": "请输入数量",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "unit_price": [
                    {
                        "required": true,
                        "message": "请输入单价",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "total_actual_amount": [
                    {
                        "required": true,
                        "message": "请输入预估金额",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "expense_code_obj": [
                    {
                        "required": true,
                        "message": "请输入费用代码",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "remark": [
                    {
                        "required": true,
                        "message": "请输入备注",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "product_title": [
                    {
                        "required": true,
                        "message": "请输入品目名称",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "specifications": [
                    {
                        "required": true,
                        "message": "请输入规格",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ],
                "expense_category_arr": [
                    {
                        "required": true,
                        "message": "请输入费用类别",
                        "trigger": [
                            "blur",
                            "change"
                        ]
                    }
                ]
            },
        }
    },
    methods: {
        onProductSearch() {
            this.isProduct = true;
            // this.quotationTableIndex = index;
        },
        productVisible(val) {
            this.isProduct = val
        },
        productApplied(val) {
          //  console.log(val);
            this.particularsForm.product = val.id
            this.particularsForm.product_code = val.item_code
            this.particularsForm.product_title = val.item_name
            this.particularsForm.specifications = val.specifications
            this.particularsForm.unit = val.unit
            this.particularsForm.unit_price = val.price
        },

        /* 获取表单 */
        onIsSetting(val) {
            this.isSettingTable = val
        },
        onSetting(val) {
          //  console.log(val); this.$route.params
        },
        maxValue(num1, num2) {
            return ('9'.repeat(num1 - num2) + '.' + ('9'.repeat(num2))) * 1

        },
        minValue(num1, num2) {
            return ('-' + ('9'.repeat(num1 - num2) + '.' + ('9'.repeat(num2)))) * 1
        },
        haveCommonElement(arr1, arr2) {
            if (arr1.length > 0) {
                for (let i = 0; i < arr1.length; i++) {
                    if (arr2.includes(arr1[i])) {
                        return true;
                    }
                }
                return false;
            } else {
                return true;
            }
        },
        async getForm() {
            const res = await this.$http({
                method: 'GET',
                url: "rbac/form_control_setting/",
                params: {
                    form_root: '需求添加明细',
                    classification: '添加',
                }
            })
            if (res.data[0] != null) {
                this.settingForm = JSON.parse(JSON.stringify(res.data[0]))
                this.settingTable.forEach((item, i) => {
                    if (this.settingForm.control_setting[item.temp_key].basic_settings.show_name != '' && this.settingForm.control_setting[item.temp_key].basic_settings.show_name != null) {
                        item.title = this.settingForm.control_setting[item.temp_key].basic_settings.show_name
                    }
                })
                this.particularsRules = {}
                for (const key in this.settingForm.control_setting) {
                    if (this.settingForm.control_setting[key].permissions.require) {
                        if (this.settingForm.control_setting[key].numeric_settings.max_digits > 0) {

                        }
                        this.$set(this.particularsRules, key, [{ required: true, message: this.settingForm.control_setting[key].basic_settings.placeholder, trigger: ['blur', 'change'] }])
                        // obj[key] = [{ required: true, message: this.settingForm.control_setting[key].basic_settings.placeholder, trigger: ['change'], immediate: false }]
                    }
                }
                this.$nextTick(() => {
                    this.$refs.particularsRef.clearValidate()
                })
                // console.log(this.particularsRules);
            }
        },

        /*费用类别 */
        onExpenseCategory() {
            this.isCategory = true
        },
        onCategoryEmploy(val) {
          //  console.log(val);
            this.expenseCategory = val
            this.particularsForm.expense_category_arr = val
            // let arr = []
            // val.forEach(item => {
            //     arr.push(item.id)
            // })
            // this.particularsForm.p_charge_classification = val.id
            this.isCategory = false
        },
        onIsCategory(val) {
            this.isCategory = val
        },

        /* 添加明细 */
        onEmployParticulars() {
            this.$emit("onEmploy", this.particularsForm)
        },
        onIsParticulars() {
            this.$emit('onIsDialog', false)
        },

        /* 费用代码 */
        async getChargeCode() {
            const res = await this.$http({
                method: "GET",
                url: "erp/charge_code/",
            })
            if (res.status == 200) {
                this.chargeCodeOption = res.data.table_body_data
            } else {
                this.$message.error(res.data)
            }
        }

    },
    watch: {
        isDialog: {
            handler(val) {
                // console.log(val)
                if (val) {
                    this.getForm()
                    this.getChargeCode()
                    this.isParticulars = val
                } else {
                    this.isParticulars = val
                    // this.$emit('onIsDialog', false)
                }
            },
            deep: true,
        },
        dialogValue: {
            handler(val) {
                if (val != undefined) {
                    this.particularsForm = val
                }
            },
            deep: true,
        },
        'particularsForm.unit_price': {
            handler(val) {
                this.particularsForm.total_actual_amount = this.particularsForm.unit_price * this.particularsForm.quantity
            },
            deep: true,
        },
        'particularsForm.quantity': {
            handler(val) {
                this.particularsForm.total_actual_amount = this.particularsForm.unit_price * this.particularsForm.quantity
            },
            deep: true,
        },
        "particularsForm.expense_code_obj": {
            handler(val) {
                this.particularsForm.p_charge_code = val.id
            },
            deep: true,
        },
        "particularsForm.expense_category_arr": {
            handler(val) {
                // let arr = []
                // val.forEach(item => {
                //     arr.push(item.id)
                // })
                this.particularsForm.p_charge_classification = val[0].id
            },
            deep: true,
        }
    },
    mounted() {

    },
    created() {

    },
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

.capsule {
    width: 80%;
    min-height: 28px;
    line-height: normal;
    display: inline-table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;



    .capsule2 {
        height: 28px;
        vertical-align: middle;
        display: table-cell;
        height: 28px;
        line-height: 28px;
        padding-right: 30px;
        border: 1px solid;
        border-color: #e4e6ed;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>