export default [
  {
    "label": "北京市",
    "value": "1101",
    "children": [
      {
        "label": "东城区",
        "value": "110101",
        "children": [
          {
            "label": "东华门街道",
            "value": "110101001"
          },
          {
            "label": "景山街道",
            "value": "110101002"
          },
          {
            "label": "交道口街道",
            "value": "110101003"
          },
          {
            "label": "安定门街道",
            "value": "110101004"
          },
          {
            "label": "北新桥街道",
            "value": "110101005"
          },
          {
            "label": "东四街道",
            "value": "110101006"
          },
          {
            "label": "朝阳门街道",
            "value": "110101007"
          },
          {
            "label": "建国门街道",
            "value": "110101008"
          },
          {
            "label": "东直门街道",
            "value": "110101009"
          },
          {
            "label": "和平里街道",
            "value": "110101010"
          },
          {
            "label": "前门街道",
            "value": "110101011"
          },
          {
            "label": "崇文门外街道",
            "value": "110101012"
          },
          {
            "label": "东花市街道",
            "value": "110101013"
          },
          {
            "label": "龙潭街道",
            "value": "110101014"
          },
          {
            "label": "体育馆路街道",
            "value": "110101015"
          },
          {
            "label": "天坛街道",
            "value": "110101016"
          },
          {
            "label": "永定门外街道",
            "value": "110101017"
          }
        ]
      },
      {
        "label": "西城区",
        "value": "110102",
        "children": [
          {
            "label": "西长安街街道",
            "value": "110102001"
          },
          {
            "label": "新街口街道",
            "value": "110102003"
          },
          {
            "label": "月坛街道",
            "value": "110102007"
          },
          {
            "label": "展览路街道",
            "value": "110102009"
          },
          {
            "label": "德胜街道",
            "value": "110102010"
          },
          {
            "label": "金融街街道",
            "value": "110102011"
          },
          {
            "label": "什刹海街道",
            "value": "110102012"
          },
          {
            "label": "大栅栏街道",
            "value": "110102013"
          },
          {
            "label": "天桥街道",
            "value": "110102014"
          },
          {
            "label": "椿树街道",
            "value": "110102015"
          },
          {
            "label": "陶然亭街道",
            "value": "110102016"
          },
          {
            "label": "广安门内街道",
            "value": "110102017"
          },
          {
            "label": "牛街街道",
            "value": "110102018"
          },
          {
            "label": "白纸坊街道",
            "value": "110102019"
          },
          {
            "label": "广安门外街道",
            "value": "110102020"
          }
        ]
      },
      {
        "label": "朝阳区",
        "value": "110105",
        "children": [
          {
            "label": "建外街道",
            "value": "110105001"
          },
          {
            "label": "朝外街道",
            "value": "110105002"
          },
          {
            "label": "呼家楼街道",
            "value": "110105003"
          },
          {
            "label": "三里屯街道",
            "value": "110105004"
          },
          {
            "label": "左家庄街道",
            "value": "110105005"
          },
          {
            "label": "香河园街道",
            "value": "110105006"
          },
          {
            "label": "和平街街道",
            "value": "110105007"
          },
          {
            "label": "安贞街道",
            "value": "110105008"
          },
          {
            "label": "亚运村街道",
            "value": "110105009"
          },
          {
            "label": "小关街道",
            "value": "110105010"
          },
          {
            "label": "酒仙桥街道",
            "value": "110105011"
          },
          {
            "label": "麦子店街道",
            "value": "110105012"
          },
          {
            "label": "团结湖街道",
            "value": "110105013"
          },
          {
            "label": "六里屯街道",
            "value": "110105014"
          },
          {
            "label": "八里庄街道",
            "value": "110105015"
          },
          {
            "label": "双井街道",
            "value": "110105016"
          },
          {
            "label": "劲松街道",
            "value": "110105017"
          },
          {
            "label": "潘家园街道",
            "value": "110105018"
          },
          {
            "label": "垡头街道",
            "value": "110105019"
          },
          {
            "label": "南磨房地区",
            "value": "110105021"
          },
          {
            "label": "高碑店地区",
            "value": "110105022"
          },
          {
            "label": "将台地区",
            "value": "110105023"
          },
          {
            "label": "太阳宫地区",
            "value": "110105024"
          },
          {
            "label": "大屯街道",
            "value": "110105025"
          },
          {
            "label": "望京街道",
            "value": "110105026"
          },
          {
            "label": "小红门地区",
            "value": "110105027"
          },
          {
            "label": "十八里店地区",
            "value": "110105028"
          },
          {
            "label": "平房地区",
            "value": "110105029"
          },
          {
            "label": "东风地区",
            "value": "110105030"
          },
          {
            "label": "奥运村街道",
            "value": "110105031"
          },
          {
            "label": "来广营地区",
            "value": "110105032"
          },
          {
            "label": "常营地区",
            "value": "110105033"
          },
          {
            "label": "三间房地区",
            "value": "110105034"
          },
          {
            "label": "管庄地区",
            "value": "110105035"
          },
          {
            "label": "金盏地区",
            "value": "110105036"
          },
          {
            "label": "孙河地区",
            "value": "110105037"
          },
          {
            "label": "崔各庄地区",
            "value": "110105038"
          },
          {
            "label": "东坝地区",
            "value": "110105039"
          },
          {
            "label": "黑庄户地区",
            "value": "110105040"
          },
          {
            "label": "豆各庄地区",
            "value": "110105041"
          },
          {
            "label": "王四营地区",
            "value": "110105042"
          },
          {
            "label": "东湖街道",
            "value": "110105043"
          },
          {
            "label": "首都机场街道",
            "value": "110105400"
          }
        ]
      },
      {
        "label": "丰台区",
        "value": "110106",
        "children": [
          {
            "label": "右安门街道",
            "value": "110106001"
          },
          {
            "label": "太平桥街道",
            "value": "110106002"
          },
          {
            "label": "西罗园街道",
            "value": "110106003"
          },
          {
            "label": "大红门街道",
            "value": "110106004"
          },
          {
            "label": "南苑街道",
            "value": "110106005"
          },
          {
            "label": "东高地街道",
            "value": "110106006"
          },
          {
            "label": "东铁匠营街道",
            "value": "110106007"
          },
          {
            "label": "卢沟桥街道",
            "value": "110106008"
          },
          {
            "label": "丰台街道",
            "value": "110106009"
          },
          {
            "label": "新村街道",
            "value": "110106010"
          },
          {
            "label": "长辛店街道",
            "value": "110106011"
          },
          {
            "label": "云岗街道",
            "value": "110106012"
          },
          {
            "label": "方庄地区",
            "value": "110106013"
          },
          {
            "label": "宛平城地区",
            "value": "110106014"
          },
          {
            "label": "马家堡街道",
            "value": "110106015"
          },
          {
            "label": "和义街道",
            "value": "110106016"
          },
          {
            "label": "卢沟桥地区",
            "value": "110106017"
          },
          {
            "label": "花乡地区",
            "value": "110106018"
          },
          {
            "label": "南苑地区",
            "value": "110106019"
          },
          {
            "label": "长辛店镇",
            "value": "110106100"
          },
          {
            "label": "王佐镇",
            "value": "110106101"
          }
        ]
      },
      {
        "label": "石景山区",
        "value": "110107",
        "children": [
          {
            "label": "八宝山街道",
            "value": "110107001"
          },
          {
            "label": "老山街道",
            "value": "110107002"
          },
          {
            "label": "八角街道",
            "value": "110107003"
          },
          {
            "label": "古城街道",
            "value": "110107004"
          },
          {
            "label": "苹果园街道",
            "value": "110107005"
          },
          {
            "label": "金顶街街道",
            "value": "110107006"
          },
          {
            "label": "广宁街道",
            "value": "110107009"
          },
          {
            "label": "五里坨街道",
            "value": "110107010"
          },
          {
            "label": "鲁谷街道",
            "value": "110107011"
          }
        ]
      },
      {
        "label": "海淀区",
        "value": "110108",
        "children": [
          {
            "label": "万寿路街道",
            "value": "110108001"
          },
          {
            "label": "永定路街道",
            "value": "110108002"
          },
          {
            "label": "羊坊店街道",
            "value": "110108003"
          },
          {
            "label": "甘家口街道",
            "value": "110108004"
          },
          {
            "label": "八里庄街道",
            "value": "110108005"
          },
          {
            "label": "紫竹院街道",
            "value": "110108006"
          },
          {
            "label": "北下关街道",
            "value": "110108007"
          },
          {
            "label": "北太平庄街道",
            "value": "110108008"
          },
          {
            "label": "学院路街道",
            "value": "110108010"
          },
          {
            "label": "中关村街道",
            "value": "110108011"
          },
          {
            "label": "海淀街道",
            "value": "110108012"
          },
          {
            "label": "青龙桥街道",
            "value": "110108013"
          },
          {
            "label": "清华园街道",
            "value": "110108014"
          },
          {
            "label": "燕园街道",
            "value": "110108015"
          },
          {
            "label": "香山街道",
            "value": "110108016"
          },
          {
            "label": "清河街道",
            "value": "110108017"
          },
          {
            "label": "花园路街道",
            "value": "110108018"
          },
          {
            "label": "西三旗街道",
            "value": "110108019"
          },
          {
            "label": "马连洼街道",
            "value": "110108020"
          },
          {
            "label": "田村路街道",
            "value": "110108021"
          },
          {
            "label": "上地街道",
            "value": "110108022"
          },
          {
            "label": "万柳地区",
            "value": "110108023"
          },
          {
            "label": "东升地区",
            "value": "110108024"
          },
          {
            "label": "曙光街道",
            "value": "110108025"
          },
          {
            "label": "温泉地区",
            "value": "110108026"
          },
          {
            "label": "四季青地区",
            "value": "110108027"
          },
          {
            "label": "西北旺地区",
            "value": "110108028"
          },
          {
            "label": "苏家坨地区",
            "value": "110108029"
          },
          {
            "label": "上庄地区",
            "value": "110108030"
          }
        ]
      },
      {
        "label": "门头沟区",
        "value": "110109",
        "children": [
          {
            "label": "大峪街道",
            "value": "110109001"
          },
          {
            "label": "城子街道",
            "value": "110109002"
          },
          {
            "label": "东辛房街道",
            "value": "110109003"
          },
          {
            "label": "大台街道",
            "value": "110109004"
          },
          {
            "label": "王平地区",
            "value": "110109005"
          },
          {
            "label": "永定地区",
            "value": "110109006"
          },
          {
            "label": "龙泉地区",
            "value": "110109007"
          },
          {
            "label": "潭柘寺镇",
            "value": "110109101"
          },
          {
            "label": "军庄镇",
            "value": "110109104"
          },
          {
            "label": "雁翅镇",
            "value": "110109105"
          },
          {
            "label": "斋堂镇",
            "value": "110109106"
          },
          {
            "label": "清水镇",
            "value": "110109107"
          },
          {
            "label": "妙峰山镇",
            "value": "110109108"
          }
        ]
      },
      {
        "label": "房山区",
        "value": "110111",
        "children": [
          {
            "label": "城关街道",
            "value": "110111001"
          },
          {
            "label": "新镇街道",
            "value": "110111002"
          },
          {
            "label": "向阳街道",
            "value": "110111004"
          },
          {
            "label": "东风街道",
            "value": "110111005"
          },
          {
            "label": "迎风街道",
            "value": "110111006"
          },
          {
            "label": "星城街道",
            "value": "110111007"
          },
          {
            "label": "良乡地区",
            "value": "110111008"
          },
          {
            "label": "周口店地区",
            "value": "110111009"
          },
          {
            "label": "琉璃河地区",
            "value": "110111010"
          },
          {
            "label": "拱辰街道",
            "value": "110111011"
          },
          {
            "label": "西潞街道",
            "value": "110111012"
          },
          {
            "label": "阎村镇",
            "value": "110111101"
          },
          {
            "label": "窦店镇",
            "value": "110111103"
          },
          {
            "label": "石楼镇",
            "value": "110111104"
          },
          {
            "label": "长阳镇",
            "value": "110111105"
          },
          {
            "label": "河北镇",
            "value": "110111107"
          },
          {
            "label": "长沟镇",
            "value": "110111108"
          },
          {
            "label": "大石窝镇",
            "value": "110111109"
          },
          {
            "label": "张坊镇",
            "value": "110111110"
          },
          {
            "label": "十渡镇",
            "value": "110111111"
          },
          {
            "label": "青龙湖镇",
            "value": "110111112"
          },
          {
            "label": "韩村河镇",
            "value": "110111115"
          },
          {
            "label": "霞云岭乡",
            "value": "110111208"
          },
          {
            "label": "南窖乡",
            "value": "110111209"
          },
          {
            "label": "佛子庄乡",
            "value": "110111210"
          },
          {
            "label": "大安山乡",
            "value": "110111211"
          },
          {
            "label": "史家营乡",
            "value": "110111212"
          },
          {
            "label": "蒲洼乡",
            "value": "110111213"
          }
        ]
      },
      {
        "label": "通州区",
        "value": "110112",
        "children": [
          {
            "label": "中仓街道",
            "value": "110112001"
          },
          {
            "label": "新华街道",
            "value": "110112002"
          },
          {
            "label": "北苑街道",
            "value": "110112003"
          },
          {
            "label": "玉桥街道",
            "value": "110112004"
          },
          {
            "label": "永顺地区",
            "value": "110112005"
          },
          {
            "label": "梨园地区",
            "value": "110112006"
          },
          {
            "label": "潞源街道",
            "value": "110112007"
          },
          {
            "label": "通运街道",
            "value": "110112008"
          },
          {
            "label": "宋庄镇",
            "value": "110112104"
          },
          {
            "label": "张家湾镇",
            "value": "110112105"
          },
          {
            "label": "漷县镇",
            "value": "110112106"
          },
          {
            "label": "马驹桥镇",
            "value": "110112109"
          },
          {
            "label": "西集镇",
            "value": "110112110"
          },
          {
            "label": "台湖镇",
            "value": "110112114"
          },
          {
            "label": "永乐店镇",
            "value": "110112117"
          },
          {
            "label": "潞城镇",
            "value": "110112119"
          },
          {
            "label": "于家务回族乡",
            "value": "110112209"
          }
        ]
      },
      {
        "label": "顺义区",
        "value": "110113",
        "children": [
          {
            "label": "胜利街道",
            "value": "110113001"
          },
          {
            "label": "光明街道",
            "value": "110113002"
          },
          {
            "label": "仁和地区",
            "value": "110113003"
          },
          {
            "label": "后沙峪地区",
            "value": "110113004"
          },
          {
            "label": "天竺地区",
            "value": "110113005"
          },
          {
            "label": "杨镇地区",
            "value": "110113006"
          },
          {
            "label": "牛栏山地区",
            "value": "110113007"
          },
          {
            "label": "南法信地区",
            "value": "110113008"
          },
          {
            "label": "马坡地区",
            "value": "110113009"
          },
          {
            "label": "石园街道",
            "value": "110113010"
          },
          {
            "label": "空港街道",
            "value": "110113011"
          },
          {
            "label": "双丰街道",
            "value": "110113012"
          },
          {
            "label": "旺泉街道",
            "value": "110113013"
          },
          {
            "label": "高丽营镇",
            "value": "110113101"
          },
          {
            "label": "李桥镇",
            "value": "110113104"
          },
          {
            "label": "李遂镇",
            "value": "110113105"
          },
          {
            "label": "南彩镇",
            "value": "110113106"
          },
          {
            "label": "北务镇",
            "value": "110113108"
          },
          {
            "label": "大孙各庄镇",
            "value": "110113109"
          },
          {
            "label": "张镇",
            "value": "110113110"
          },
          {
            "label": "龙湾屯镇",
            "value": "110113111"
          },
          {
            "label": "木林镇",
            "value": "110113112"
          },
          {
            "label": "北小营镇",
            "value": "110113113"
          },
          {
            "label": "北石槽镇",
            "value": "110113115"
          },
          {
            "label": "赵全营镇",
            "value": "110113116"
          }
        ]
      },
      {
        "label": "昌平区",
        "value": "110114",
        "children": [
          {
            "label": "城北街道",
            "value": "110114001"
          },
          {
            "label": "南口地区",
            "value": "110114002"
          },
          {
            "label": "马池口地区",
            "value": "110114003"
          },
          {
            "label": "沙河地区",
            "value": "110114004"
          },
          {
            "label": "城南街道",
            "value": "110114005"
          },
          {
            "label": "东小口地区",
            "value": "110114007"
          },
          {
            "label": "天通苑北街道",
            "value": "110114008"
          },
          {
            "label": "天通苑南街道",
            "value": "110114009"
          },
          {
            "label": "霍营街道",
            "value": "110114010"
          },
          {
            "label": "回龙观街道",
            "value": "110114011"
          },
          {
            "label": "龙泽园街道",
            "value": "110114012"
          },
          {
            "label": "史各庄街道",
            "value": "110114013"
          },
          {
            "label": "阳坊镇",
            "value": "110114104"
          },
          {
            "label": "小汤山镇",
            "value": "110114110"
          },
          {
            "label": "南邵镇",
            "value": "110114111"
          },
          {
            "label": "崔村镇",
            "value": "110114112"
          },
          {
            "label": "百善镇",
            "value": "110114113"
          },
          {
            "label": "北七家镇",
            "value": "110114115"
          },
          {
            "label": "兴寿镇",
            "value": "110114116"
          },
          {
            "label": "流村镇",
            "value": "110114118"
          },
          {
            "label": "十三陵镇",
            "value": "110114119"
          },
          {
            "label": "延寿镇",
            "value": "110114120"
          }
        ]
      },
      {
        "label": "大兴区",
        "value": "110115",
        "children": [
          {
            "label": "兴丰街道",
            "value": "110115001"
          },
          {
            "label": "林校路街道",
            "value": "110115002"
          },
          {
            "label": "清源街道",
            "value": "110115003"
          },
          {
            "label": "亦庄地区",
            "value": "110115004"
          },
          {
            "label": "黄村地区",
            "value": "110115005"
          },
          {
            "label": "旧宫地区",
            "value": "110115006"
          },
          {
            "label": "西红门地区",
            "value": "110115007"
          },
          {
            "label": "瀛海地区",
            "value": "110115008"
          },
          {
            "label": "观音寺街道",
            "value": "110115009"
          },
          {
            "label": "天宫院街道",
            "value": "110115010"
          },
          {
            "label": "高米店街道",
            "value": "110115011"
          },
          {
            "label": "荣华街道",
            "value": "110115012"
          },
          {
            "label": "博兴街道",
            "value": "110115013"
          },
          {
            "label": "青云店镇",
            "value": "110115103"
          },
          {
            "label": "采育镇",
            "value": "110115104"
          },
          {
            "label": "安定镇",
            "value": "110115105"
          },
          {
            "label": "礼贤镇",
            "value": "110115106"
          },
          {
            "label": "榆垡镇",
            "value": "110115107"
          },
          {
            "label": "庞各庄镇",
            "value": "110115108"
          },
          {
            "label": "北臧村镇",
            "value": "110115109"
          },
          {
            "label": "魏善庄镇",
            "value": "110115110"
          },
          {
            "label": "长子营镇",
            "value": "110115111"
          },
          {
            "label": "北京经济技术开发区",
            "value": "110115403"
          },
          {
            "label": "中关村国家自主创新示范区大兴生物医药产业基地",
            "value": "110115404"
          },
          {
            "label": "国家新媒体产业基地",
            "value": "110115405"
          },
          {
            "label": "大兴国际机场",
            "value": "110115406"
          }
        ]
      },
      {
        "label": "怀柔区",
        "value": "110116",
        "children": [
          {
            "label": "泉河街道",
            "value": "110116001"
          },
          {
            "label": "龙山街道",
            "value": "110116002"
          },
          {
            "label": "怀柔地区",
            "value": "110116003"
          },
          {
            "label": "雁栖地区",
            "value": "110116004"
          },
          {
            "label": "庙城地区",
            "value": "110116005"
          },
          {
            "label": "北房镇",
            "value": "110116102"
          },
          {
            "label": "杨宋镇",
            "value": "110116103"
          },
          {
            "label": "桥梓镇",
            "value": "110116105"
          },
          {
            "label": "怀北镇",
            "value": "110116106"
          },
          {
            "label": "汤河口镇",
            "value": "110116107"
          },
          {
            "label": "渤海镇",
            "value": "110116108"
          },
          {
            "label": "九渡河镇",
            "value": "110116109"
          },
          {
            "label": "琉璃庙镇",
            "value": "110116110"
          },
          {
            "label": "宝山镇",
            "value": "110116111"
          },
          {
            "label": "长哨营满族乡",
            "value": "110116211"
          },
          {
            "label": "喇叭沟门满族乡",
            "value": "110116213"
          },
          {
            "label": "北京雁栖经济开发区",
            "value": "110116400"
          }
        ]
      },
      {
        "label": "平谷区",
        "value": "110117",
        "children": [
          {
            "label": "滨河街道",
            "value": "110117001"
          },
          {
            "label": "兴谷街道",
            "value": "110117002"
          },
          {
            "label": "渔阳地区",
            "value": "110117003"
          },
          {
            "label": "峪口地区",
            "value": "110117004"
          },
          {
            "label": "马坊地区",
            "value": "110117005"
          },
          {
            "label": "金海湖地区",
            "value": "110117006"
          },
          {
            "label": "东高村镇",
            "value": "110117101"
          },
          {
            "label": "山东庄镇",
            "value": "110117102"
          },
          {
            "label": "南独乐河镇",
            "value": "110117104"
          },
          {
            "label": "大华山镇",
            "value": "110117105"
          },
          {
            "label": "夏各庄镇",
            "value": "110117106"
          },
          {
            "label": "马昌营镇",
            "value": "110117108"
          },
          {
            "label": "王辛庄镇",
            "value": "110117110"
          },
          {
            "label": "大兴庄镇",
            "value": "110117111"
          },
          {
            "label": "刘家店镇",
            "value": "110117112"
          },
          {
            "label": "镇罗营镇",
            "value": "110117114"
          },
          {
            "label": "黄松峪乡",
            "value": "110117213"
          },
          {
            "label": "熊儿寨乡",
            "value": "110117214"
          }
        ]
      },
      {
        "label": "密云区",
        "value": "110118",
        "children": [
          {
            "label": "鼓楼街道",
            "value": "110118001"
          },
          {
            "label": "果园街道",
            "value": "110118002"
          },
          {
            "label": "檀营地区",
            "value": "110118003"
          },
          {
            "label": "密云镇",
            "value": "110118100"
          },
          {
            "label": "溪翁庄镇",
            "value": "110118101"
          },
          {
            "label": "西田各庄镇",
            "value": "110118102"
          },
          {
            "label": "十里堡镇",
            "value": "110118103"
          },
          {
            "label": "河南寨镇",
            "value": "110118104"
          },
          {
            "label": "巨各庄镇",
            "value": "110118105"
          },
          {
            "label": "穆家峪镇",
            "value": "110118106"
          },
          {
            "label": "太师屯镇",
            "value": "110118107"
          },
          {
            "label": "高岭镇",
            "value": "110118108"
          },
          {
            "label": "不老屯镇",
            "value": "110118109"
          },
          {
            "label": "冯家峪镇",
            "value": "110118110"
          },
          {
            "label": "古北口镇",
            "value": "110118111"
          },
          {
            "label": "大城子镇",
            "value": "110118112"
          },
          {
            "label": "东邵渠镇",
            "value": "110118113"
          },
          {
            "label": "北庄镇",
            "value": "110118114"
          },
          {
            "label": "新城子镇",
            "value": "110118115"
          },
          {
            "label": "石城镇",
            "value": "110118116"
          },
          {
            "label": "北京密云经济开发区",
            "value": "110118400"
          }
        ]
      },
      {
        "label": "延庆区",
        "value": "110119",
        "children": [
          {
            "label": "百泉街道",
            "value": "110119001"
          },
          {
            "label": "香水园街道",
            "value": "110119002"
          },
          {
            "label": "儒林街道",
            "value": "110119003"
          },
          {
            "label": "延庆镇",
            "value": "110119100"
          },
          {
            "label": "康庄镇",
            "value": "110119101"
          },
          {
            "label": "八达岭镇",
            "value": "110119102"
          },
          {
            "label": "永宁镇",
            "value": "110119103"
          },
          {
            "label": "旧县镇",
            "value": "110119104"
          },
          {
            "label": "张山营镇",
            "value": "110119105"
          },
          {
            "label": "四海镇",
            "value": "110119106"
          },
          {
            "label": "千家店镇",
            "value": "110119107"
          },
          {
            "label": "沈家营镇",
            "value": "110119108"
          },
          {
            "label": "大榆树镇",
            "value": "110119109"
          },
          {
            "label": "井庄镇",
            "value": "110119110"
          },
          {
            "label": "大庄科乡",
            "value": "110119200"
          },
          {
            "label": "刘斌堡乡",
            "value": "110119201"
          },
          {
            "label": "香营乡",
            "value": "110119202"
          },
          {
            "label": "珍珠泉乡",
            "value": "110119203"
          }
        ]
      }
    ]
  },
  {
    "label": "天津市",
    "value": "1201",
    "children": [
      {
        "label": "和平区",
        "value": "120101",
        "children": [
          {
            "label": "劝业场街道",
            "value": "120101001"
          },
          {
            "label": "小白楼街道",
            "value": "120101002"
          },
          {
            "label": "五大道街道",
            "value": "120101003"
          },
          {
            "label": "新兴街道",
            "value": "120101004"
          },
          {
            "label": "南营门街道",
            "value": "120101005"
          },
          {
            "label": "南市街道",
            "value": "120101006"
          }
        ]
      },
      {
        "label": "河东区",
        "value": "120102",
        "children": [
          {
            "label": "大王庄街道",
            "value": "120102001"
          },
          {
            "label": "大直沽街道",
            "value": "120102002"
          },
          {
            "label": "中山门街道",
            "value": "120102003"
          },
          {
            "label": "富民路街道",
            "value": "120102004"
          },
          {
            "label": "二号桥街道",
            "value": "120102005"
          },
          {
            "label": "春华街道",
            "value": "120102006"
          },
          {
            "label": "唐家口街道",
            "value": "120102007"
          },
          {
            "label": "向阳楼街道",
            "value": "120102008"
          },
          {
            "label": "常州道街道",
            "value": "120102009"
          },
          {
            "label": "上杭路街道",
            "value": "120102010"
          },
          {
            "label": "东新街道",
            "value": "120102011"
          },
          {
            "label": "鲁山道街道",
            "value": "120102012"
          },
          {
            "label": "天津铁厂街道",
            "value": "120102013"
          }
        ]
      },
      {
        "label": "河西区",
        "value": "120103",
        "children": [
          {
            "label": "大营门街道",
            "value": "120103001"
          },
          {
            "label": "下瓦房街道",
            "value": "120103002"
          },
          {
            "label": "桃园街道",
            "value": "120103003"
          },
          {
            "label": "挂甲寺街道",
            "value": "120103004"
          },
          {
            "label": "马场街道",
            "value": "120103005"
          },
          {
            "label": "越秀路街道",
            "value": "120103006"
          },
          {
            "label": "友谊路街道",
            "value": "120103007"
          },
          {
            "label": "天塔街道",
            "value": "120103008"
          },
          {
            "label": "尖山街道",
            "value": "120103009"
          },
          {
            "label": "陈塘庄街道",
            "value": "120103010"
          },
          {
            "label": "柳林街道",
            "value": "120103011"
          },
          {
            "label": "东海街道",
            "value": "120103012"
          },
          {
            "label": "梅江街道",
            "value": "120103013"
          },
          {
            "label": "太湖路街道",
            "value": "120103014"
          }
        ]
      },
      {
        "label": "南开区",
        "value": "120104",
        "children": [
          {
            "label": "长虹街道",
            "value": "120104001"
          },
          {
            "label": "鼓楼街道",
            "value": "120104002"
          },
          {
            "label": "兴南街道",
            "value": "120104003"
          },
          {
            "label": "广开街道",
            "value": "120104004"
          },
          {
            "label": "万兴街道",
            "value": "120104005"
          },
          {
            "label": "学府街道",
            "value": "120104006"
          },
          {
            "label": "向阳路街道",
            "value": "120104007"
          },
          {
            "label": "嘉陵道街道",
            "value": "120104008"
          },
          {
            "label": "王顶堤街道",
            "value": "120104009"
          },
          {
            "label": "水上公园街道",
            "value": "120104010"
          },
          {
            "label": "体育中心街道",
            "value": "120104011"
          },
          {
            "label": "华苑街道",
            "value": "120104012"
          }
        ]
      },
      {
        "label": "河北区",
        "value": "120105",
        "children": [
          {
            "label": "光复道街道",
            "value": "120105001"
          },
          {
            "label": "望海楼街道",
            "value": "120105002"
          },
          {
            "label": "鸿顺里街道",
            "value": "120105003"
          },
          {
            "label": "新开河街道",
            "value": "120105004"
          },
          {
            "label": "铁东路街道",
            "value": "120105005"
          },
          {
            "label": "建昌道街道",
            "value": "120105006"
          },
          {
            "label": "宁园街道",
            "value": "120105007"
          },
          {
            "label": "王串场街道",
            "value": "120105008"
          },
          {
            "label": "江都路街道",
            "value": "120105009"
          },
          {
            "label": "月牙河街道",
            "value": "120105010"
          }
        ]
      },
      {
        "label": "红桥区",
        "value": "120106",
        "children": [
          {
            "label": "西于庄街道",
            "value": "120106001"
          },
          {
            "label": "双环村街道",
            "value": "120106002"
          },
          {
            "label": "咸阳北路街道",
            "value": "120106003"
          },
          {
            "label": "丁字沽街道",
            "value": "120106004"
          },
          {
            "label": "西沽街道",
            "value": "120106005"
          },
          {
            "label": "三条石街道",
            "value": "120106006"
          },
          {
            "label": "邵公庄街道",
            "value": "120106008"
          },
          {
            "label": "芥园街道",
            "value": "120106009"
          },
          {
            "label": "铃铛阁街道",
            "value": "120106010"
          },
          {
            "label": "和苑街道",
            "value": "120106012"
          }
        ]
      },
      {
        "label": "东丽区",
        "value": "120110",
        "children": [
          {
            "label": "张贵庄街道",
            "value": "120110001"
          },
          {
            "label": "丰年村街道",
            "value": "120110002"
          },
          {
            "label": "万新街道",
            "value": "120110003"
          },
          {
            "label": "无瑕街道",
            "value": "120110004"
          },
          {
            "label": "新立街道",
            "value": "120110005"
          },
          {
            "label": "华明街道",
            "value": "120110006"
          },
          {
            "label": "金钟街道",
            "value": "120110007"
          },
          {
            "label": "军粮城街道",
            "value": "120110008"
          },
          {
            "label": "金桥街道",
            "value": "120110009"
          },
          {
            "label": "华新街道",
            "value": "120110010"
          },
          {
            "label": "东丽湖街道",
            "value": "120110011"
          },
          {
            "label": "天津经济技术开发区西区",
            "value": "120110400"
          },
          {
            "label": "天津航空物流区",
            "value": "120110401"
          },
          {
            "label": "天津空港经济区",
            "value": "120110402"
          },
          {
            "label": "东丽区经济技术开发区",
            "value": "120110403"
          },
          {
            "label": "综合保税区",
            "value": "120110405"
          },
          {
            "label": "航空新城",
            "value": "120110406"
          },
          {
            "label": "服务滨海新区建设管理委员会",
            "value": "120110407"
          }
        ]
      },
      {
        "label": "西青区",
        "value": "120111",
        "children": [
          {
            "label": "西营门街道",
            "value": "120111001"
          },
          {
            "label": "李七庄街道",
            "value": "120111002"
          },
          {
            "label": "赤龙南街道",
            "value": "120111003"
          },
          {
            "label": "中北镇",
            "value": "120111100"
          },
          {
            "label": "杨柳青镇",
            "value": "120111101"
          },
          {
            "label": "辛口镇",
            "value": "120111102"
          },
          {
            "label": "张家窝镇",
            "value": "120111103"
          },
          {
            "label": "精武镇",
            "value": "120111104"
          },
          {
            "label": "大寺镇",
            "value": "120111105"
          },
          {
            "label": "王稳庄镇",
            "value": "120111106"
          },
          {
            "label": "天津经济技术开发区微电子小区",
            "value": "120111400"
          },
          {
            "label": "新技术产业园区",
            "value": "120111401"
          },
          {
            "label": "西青区开发区",
            "value": "120111450"
          }
        ]
      },
      {
        "label": "津南区",
        "value": "120112",
        "children": [
          {
            "label": "双新街道",
            "value": "120112001"
          },
          {
            "label": "双林街道",
            "value": "120112002"
          },
          {
            "label": "咸水沽镇",
            "value": "120112100"
          },
          {
            "label": "葛沽镇",
            "value": "120112101"
          },
          {
            "label": "小站镇",
            "value": "120112102"
          },
          {
            "label": "双港镇",
            "value": "120112103"
          },
          {
            "label": "辛庄镇",
            "value": "120112104"
          },
          {
            "label": "双桥河镇",
            "value": "120112106"
          },
          {
            "label": "八里台镇",
            "value": "120112107"
          },
          {
            "label": "北闸口镇",
            "value": "120112108"
          },
          {
            "label": "铁路十八局",
            "value": "120112401"
          },
          {
            "label": "长青",
            "value": "120112403"
          },
          {
            "label": "津南开发区东区",
            "value": "120112404"
          },
          {
            "label": "津南开发区西区",
            "value": "120112405"
          },
          {
            "label": "海河教育园区",
            "value": "120112407"
          },
          {
            "label": "双林农场",
            "value": "120112500"
          },
          {
            "label": "津南国家农业园区",
            "value": "120112501"
          }
        ]
      },
      {
        "label": "北辰区",
        "value": "120113",
        "children": [
          {
            "label": "果园新村街道",
            "value": "120113001"
          },
          {
            "label": "集贤里街道",
            "value": "120113002"
          },
          {
            "label": "普东街道",
            "value": "120113003"
          },
          {
            "label": "瑞景街道",
            "value": "120113004"
          },
          {
            "label": "佳荣里街道",
            "value": "120113005"
          },
          {
            "label": "青源街道",
            "value": "120113006"
          },
          {
            "label": "广源街道",
            "value": "120113007"
          },
          {
            "label": "天穆镇",
            "value": "120113100"
          },
          {
            "label": "北仓镇",
            "value": "120113101"
          },
          {
            "label": "双街镇",
            "value": "120113102"
          },
          {
            "label": "双口镇",
            "value": "120113103"
          },
          {
            "label": "青光镇",
            "value": "120113104"
          },
          {
            "label": "宜兴埠镇",
            "value": "120113106"
          },
          {
            "label": "小淀镇",
            "value": "120113107"
          },
          {
            "label": "大张庄镇",
            "value": "120113108"
          },
          {
            "label": "西堤头镇",
            "value": "120113111"
          },
          {
            "label": "科技园区北区",
            "value": "120113400"
          },
          {
            "label": "科技园区南区",
            "value": "120113401"
          },
          {
            "label": "天津医药医疗器械工业园",
            "value": "120113402"
          },
          {
            "label": "天津陆路港物流装备产业园",
            "value": "120113403"
          },
          {
            "label": "天津风电产业园",
            "value": "120113404"
          },
          {
            "label": "红旗农场",
            "value": "120113500"
          },
          {
            "label": "曙光农场",
            "value": "120113501"
          }
        ]
      },
      {
        "label": "武清区",
        "value": "120114",
        "children": [
          {
            "label": "杨村街道",
            "value": "120114001"
          },
          {
            "label": "下朱庄街道",
            "value": "120114002"
          },
          {
            "label": "东蒲洼街道",
            "value": "120114003"
          },
          {
            "label": "黄庄街道",
            "value": "120114004"
          },
          {
            "label": "徐官屯街道",
            "value": "120114005"
          },
          {
            "label": "运河西街道",
            "value": "120114006"
          },
          {
            "label": "梅厂镇",
            "value": "120114101"
          },
          {
            "label": "大碱厂镇",
            "value": "120114102"
          },
          {
            "label": "崔黄口镇",
            "value": "120114103"
          },
          {
            "label": "大良镇",
            "value": "120114104"
          },
          {
            "label": "下伍旗镇",
            "value": "120114105"
          },
          {
            "label": "南蔡村镇",
            "value": "120114106"
          },
          {
            "label": "大孟庄镇",
            "value": "120114107"
          },
          {
            "label": "泗村店镇",
            "value": "120114108"
          },
          {
            "label": "河西务镇",
            "value": "120114109"
          },
          {
            "label": "城关镇",
            "value": "120114110"
          },
          {
            "label": "东马圈镇",
            "value": "120114111"
          },
          {
            "label": "黄花店镇",
            "value": "120114112"
          },
          {
            "label": "石各庄镇",
            "value": "120114113"
          },
          {
            "label": "王庆坨镇",
            "value": "120114114"
          },
          {
            "label": "汊沽港镇",
            "value": "120114115"
          },
          {
            "label": "河北屯镇",
            "value": "120114116"
          },
          {
            "label": "上马台镇",
            "value": "120114117"
          },
          {
            "label": "大王古庄镇",
            "value": "120114118"
          },
          {
            "label": "陈咀镇",
            "value": "120114119"
          },
          {
            "label": "豆张庄镇",
            "value": "120114120"
          },
          {
            "label": "曹子里镇",
            "value": "120114121"
          },
          {
            "label": "大黄堡镇",
            "value": "120114122"
          },
          {
            "label": "高村镇",
            "value": "120114123"
          },
          {
            "label": "白古屯镇",
            "value": "120114124"
          },
          {
            "label": "天津经济技术开发区逸仙科学工业园",
            "value": "120114400"
          },
          {
            "label": "武清经济技术开发区",
            "value": "120114401"
          },
          {
            "label": "天津武清商务区",
            "value": "120114402"
          },
          {
            "label": "天津京滨工业园",
            "value": "120114403"
          },
          {
            "label": "天津京津电子商务产业园",
            "value": "120114404"
          },
          {
            "label": "天津武清汽车产业园",
            "value": "120114405"
          },
          {
            "label": "天津京津科技谷",
            "value": "120114406"
          },
          {
            "label": "京津高村科技创新园",
            "value": "120114407"
          },
          {
            "label": "武清农场",
            "value": "120114500"
          }
        ]
      },
      {
        "label": "宝坻区",
        "value": "120115",
        "children": [
          {
            "label": "海滨街道",
            "value": "120115001"
          },
          {
            "label": "宝平街道",
            "value": "120115002"
          },
          {
            "label": "钰华街道",
            "value": "120115003"
          },
          {
            "label": "周良街道",
            "value": "120115006"
          },
          {
            "label": "潮阳街道",
            "value": "120115007"
          },
          {
            "label": "朝霞街道",
            "value": "120115008"
          },
          {
            "label": "大口屯镇",
            "value": "120115101"
          },
          {
            "label": "王卜庄镇",
            "value": "120115103"
          },
          {
            "label": "方家庄镇",
            "value": "120115104"
          },
          {
            "label": "林亭口镇",
            "value": "120115105"
          },
          {
            "label": "八门城镇",
            "value": "120115106"
          },
          {
            "label": "大钟庄镇",
            "value": "120115107"
          },
          {
            "label": "新安镇",
            "value": "120115108"
          },
          {
            "label": "霍各庄镇",
            "value": "120115111"
          },
          {
            "label": "新开口镇",
            "value": "120115113"
          },
          {
            "label": "大唐庄镇",
            "value": "120115115"
          },
          {
            "label": "牛道口镇",
            "value": "120115119"
          },
          {
            "label": "史各庄镇",
            "value": "120115120"
          },
          {
            "label": "郝各庄镇",
            "value": "120115121"
          },
          {
            "label": "牛家牌镇",
            "value": "120115123"
          },
          {
            "label": "尔王庄镇",
            "value": "120115124"
          },
          {
            "label": "黄庄镇",
            "value": "120115125"
          },
          {
            "label": "口东镇",
            "value": "120115126"
          },
          {
            "label": "大白镇",
            "value": "120115127"
          },
          {
            "label": "宝坻经济开发区",
            "value": "120115456"
          },
          {
            "label": "里自沽农场",
            "value": "120115500"
          },
          {
            "label": "黄庄农场",
            "value": "120115501"
          },
          {
            "label": "大钟农场",
            "value": "120115502"
          }
        ]
      },
      {
        "label": "滨海新区",
        "value": "120116",
        "children": [
          {
            "label": "塘沽街道",
            "value": "120116001"
          },
          {
            "label": "杭州道街道",
            "value": "120116005"
          },
          {
            "label": "新河街道",
            "value": "120116006"
          },
          {
            "label": "大沽街道",
            "value": "120116007"
          },
          {
            "label": "新北街道",
            "value": "120116008"
          },
          {
            "label": "北塘街道",
            "value": "120116009"
          },
          {
            "label": "胡家园街道",
            "value": "120116011"
          },
          {
            "label": "新港街道",
            "value": "120116012"
          },
          {
            "label": "新村街道",
            "value": "120116013"
          },
          {
            "label": "天津经济技术开发区东区泰达街道",
            "value": "120116014"
          },
          {
            "label": "汉沽街道",
            "value": "120116031"
          },
          {
            "label": "寨上街道",
            "value": "120116032"
          },
          {
            "label": "茶淀街道",
            "value": "120116033"
          },
          {
            "label": "大港街道",
            "value": "120116051"
          },
          {
            "label": "古林街道",
            "value": "120116053"
          },
          {
            "label": "海滨街道",
            "value": "120116054"
          },
          {
            "label": "新城镇",
            "value": "120116100"
          },
          {
            "label": "杨家泊镇",
            "value": "120116135"
          },
          {
            "label": "太平镇",
            "value": "120116156"
          },
          {
            "label": "小王庄镇",
            "value": "120116157"
          },
          {
            "label": "中塘镇",
            "value": "120116158"
          },
          {
            "label": "天津经济技术开发区（其他片区）",
            "value": "120116400"
          },
          {
            "label": "天津港保税区",
            "value": "120116401"
          },
          {
            "label": "天津滨海新区高新技术产业开发区",
            "value": "120116402"
          },
          {
            "label": "东疆保税港区",
            "value": "120116403"
          },
          {
            "label": "中新天津生态城",
            "value": "120116406"
          }
        ]
      },
      {
        "label": "宁河区",
        "value": "120117",
        "children": [
          {
            "label": "芦台镇",
            "value": "120117100"
          },
          {
            "label": "宁河镇",
            "value": "120117102"
          },
          {
            "label": "苗庄镇",
            "value": "120117103"
          },
          {
            "label": "丰台镇",
            "value": "120117104"
          },
          {
            "label": "岳龙镇",
            "value": "120117105"
          },
          {
            "label": "板桥镇",
            "value": "120117106"
          },
          {
            "label": "潘庄镇",
            "value": "120117107"
          },
          {
            "label": "造甲城镇",
            "value": "120117109"
          },
          {
            "label": "七里海镇",
            "value": "120117110"
          },
          {
            "label": "大北涧沽镇",
            "value": "120117111"
          },
          {
            "label": "东棘坨镇",
            "value": "120117112"
          },
          {
            "label": "北淮淀镇",
            "value": "120117113"
          },
          {
            "label": "俵口镇",
            "value": "120117114"
          },
          {
            "label": "廉庄镇",
            "value": "120117115"
          },
          {
            "label": "宁河区贸易开发区",
            "value": "120117400"
          },
          {
            "label": "宁河区经济开发区",
            "value": "120117401"
          },
          {
            "label": "天津未来科技城天津经济技术开发区片区",
            "value": "120117402"
          },
          {
            "label": "天津未来科技城滨海高新区片区",
            "value": "120117403"
          },
          {
            "label": "天津未来科技城宁河片区现代产业区组团",
            "value": "120117451"
          },
          {
            "label": "天津未来科技城宁河片区潘庄工业区组团",
            "value": "120117452"
          },
          {
            "label": "天津未来科技城宁河片区北淮淀组团",
            "value": "120117453"
          },
          {
            "label": "潘庄农场",
            "value": "120117500"
          }
        ]
      },
      {
        "label": "静海区",
        "value": "120118",
        "children": [
          {
            "label": "华康街道",
            "value": "120118001"
          },
          {
            "label": "静海镇",
            "value": "120118100"
          },
          {
            "label": "唐官屯镇",
            "value": "120118101"
          },
          {
            "label": "独流镇",
            "value": "120118102"
          },
          {
            "label": "王口镇",
            "value": "120118103"
          },
          {
            "label": "台头镇",
            "value": "120118104"
          },
          {
            "label": "子牙镇",
            "value": "120118105"
          },
          {
            "label": "陈官屯镇",
            "value": "120118106"
          },
          {
            "label": "中旺镇",
            "value": "120118107"
          },
          {
            "label": "大邱庄镇",
            "value": "120118108"
          },
          {
            "label": "蔡公庄镇",
            "value": "120118109"
          },
          {
            "label": "梁头镇",
            "value": "120118110"
          },
          {
            "label": "团泊镇",
            "value": "120118111"
          },
          {
            "label": "双塘镇",
            "value": "120118112"
          },
          {
            "label": "大丰堆镇",
            "value": "120118113"
          },
          {
            "label": "沿庄镇",
            "value": "120118114"
          },
          {
            "label": "西翟庄镇",
            "value": "120118115"
          },
          {
            "label": "良王庄乡",
            "value": "120118200"
          },
          {
            "label": "杨成庄乡",
            "value": "120118201"
          },
          {
            "label": "天津静海经济开发区",
            "value": "120118400"
          },
          {
            "label": "天津子牙循环经济产业区",
            "value": "120118402"
          },
          {
            "label": "团泊新城",
            "value": "120118403"
          }
        ]
      },
      {
        "label": "蓟州区",
        "value": "120119",
        "children": [
          {
            "label": "文昌街道",
            "value": "120119001"
          },
          {
            "label": "渔阳镇",
            "value": "120119100"
          },
          {
            "label": "洇溜镇",
            "value": "120119101"
          },
          {
            "label": "官庄镇",
            "value": "120119102"
          },
          {
            "label": "马伸桥镇",
            "value": "120119103"
          },
          {
            "label": "下营镇",
            "value": "120119104"
          },
          {
            "label": "邦均镇",
            "value": "120119105"
          },
          {
            "label": "别山镇",
            "value": "120119106"
          },
          {
            "label": "尤古庄镇",
            "value": "120119108"
          },
          {
            "label": "上仓镇",
            "value": "120119109"
          },
          {
            "label": "下仓镇",
            "value": "120119111"
          },
          {
            "label": "罗庄子镇",
            "value": "120119112"
          },
          {
            "label": "白涧镇",
            "value": "120119113"
          },
          {
            "label": "侯家营镇",
            "value": "120119115"
          },
          {
            "label": "桑梓镇",
            "value": "120119116"
          },
          {
            "label": "东施古镇",
            "value": "120119117"
          },
          {
            "label": "下窝头镇",
            "value": "120119118"
          },
          {
            "label": "杨津庄镇",
            "value": "120119119"
          },
          {
            "label": "出头岭镇",
            "value": "120119120"
          },
          {
            "label": "西龙虎峪镇",
            "value": "120119121"
          },
          {
            "label": "穿芳峪镇",
            "value": "120119122"
          },
          {
            "label": "东二营镇",
            "value": "120119123"
          },
          {
            "label": "许家台镇",
            "value": "120119124"
          },
          {
            "label": "礼明庄镇",
            "value": "120119125"
          },
          {
            "label": "东赵各庄镇",
            "value": "120119126"
          },
          {
            "label": "州河湾镇",
            "value": "120119127"
          },
          {
            "label": "孙各庄乡",
            "value": "120119203"
          },
          {
            "label": "天津市蓟州区京津州河科技产业园管理委员会",
            "value": "120119401"
          }
        ]
      }
    ]
  },
  {
    "label": "河北省",
    "value": "13",
    "children": [
      {
        "label": "石家庄市",
        "value": "1301",
        "children": [
          {
            "label": "长安区",
            "value": "130102"
          },
          {
            "label": "桥西区",
            "value": "130104"
          },
          {
            "label": "新华区",
            "value": "130105"
          },
          {
            "label": "井陉矿区",
            "value": "130107"
          },
          {
            "label": "裕华区",
            "value": "130108"
          },
          {
            "label": "藁城区",
            "value": "130109"
          },
          {
            "label": "鹿泉区",
            "value": "130110"
          },
          {
            "label": "栾城区",
            "value": "130111"
          },
          {
            "label": "井陉县",
            "value": "130121"
          },
          {
            "label": "正定县",
            "value": "130123"
          },
          {
            "label": "行唐县",
            "value": "130125"
          },
          {
            "label": "灵寿县",
            "value": "130126"
          },
          {
            "label": "高邑县",
            "value": "130127"
          },
          {
            "label": "深泽县",
            "value": "130128"
          },
          {
            "label": "赞皇县",
            "value": "130129"
          },
          {
            "label": "无极县",
            "value": "130130"
          },
          {
            "label": "平山县",
            "value": "130131"
          },
          {
            "label": "元氏县",
            "value": "130132"
          },
          {
            "label": "赵县",
            "value": "130133"
          },
          {
            "label": "石家庄高新技术产业开发区",
            "value": "130171"
          },
          {
            "label": "石家庄循环化工园区",
            "value": "130172"
          },
          {
            "label": "辛集市",
            "value": "130181"
          },
          {
            "label": "晋州市",
            "value": "130183"
          },
          {
            "label": "新乐市",
            "value": "130184"
          }
        ]
      },
      {
        "label": "唐山市",
        "value": "1302",
        "children": [
          {
            "label": "路南区",
            "value": "130202"
          },
          {
            "label": "路北区",
            "value": "130203"
          },
          {
            "label": "古冶区",
            "value": "130204"
          },
          {
            "label": "开平区",
            "value": "130205"
          },
          {
            "label": "丰南区",
            "value": "130207"
          },
          {
            "label": "丰润区",
            "value": "130208"
          },
          {
            "label": "曹妃甸区",
            "value": "130209"
          },
          {
            "label": "滦南县",
            "value": "130224"
          },
          {
            "label": "乐亭县",
            "value": "130225"
          },
          {
            "label": "迁西县",
            "value": "130227"
          },
          {
            "label": "玉田县",
            "value": "130229"
          },
          {
            "label": "河北唐山芦台经济开发区",
            "value": "130271"
          },
          {
            "label": "唐山市汉沽管理区",
            "value": "130272"
          },
          {
            "label": "唐山高新技术产业开发区",
            "value": "130273"
          },
          {
            "label": "河北唐山海港经济开发区",
            "value": "130274"
          },
          {
            "label": "遵化市",
            "value": "130281"
          },
          {
            "label": "迁安市",
            "value": "130283"
          },
          {
            "label": "滦州市",
            "value": "130284"
          }
        ]
      },
      {
        "label": "秦皇岛市",
        "value": "1303",
        "children": [
          {
            "label": "海港区",
            "value": "130302"
          },
          {
            "label": "山海关区",
            "value": "130303"
          },
          {
            "label": "北戴河区",
            "value": "130304"
          },
          {
            "label": "抚宁区",
            "value": "130306"
          },
          {
            "label": "青龙满族自治县",
            "value": "130321"
          },
          {
            "label": "昌黎县",
            "value": "130322"
          },
          {
            "label": "卢龙县",
            "value": "130324"
          },
          {
            "label": "秦皇岛市经济技术开发区",
            "value": "130371"
          },
          {
            "label": "北戴河新区",
            "value": "130372"
          }
        ]
      },
      {
        "label": "邯郸市",
        "value": "1304",
        "children": [
          {
            "label": "邯山区",
            "value": "130402"
          },
          {
            "label": "丛台区",
            "value": "130403"
          },
          {
            "label": "复兴区",
            "value": "130404"
          },
          {
            "label": "峰峰矿区",
            "value": "130406"
          },
          {
            "label": "肥乡区",
            "value": "130407"
          },
          {
            "label": "永年区",
            "value": "130408"
          },
          {
            "label": "临漳县",
            "value": "130423"
          },
          {
            "label": "成安县",
            "value": "130424"
          },
          {
            "label": "大名县",
            "value": "130425"
          },
          {
            "label": "涉县",
            "value": "130426"
          },
          {
            "label": "磁县",
            "value": "130427"
          },
          {
            "label": "邱县",
            "value": "130430"
          },
          {
            "label": "鸡泽县",
            "value": "130431"
          },
          {
            "label": "广平县",
            "value": "130432"
          },
          {
            "label": "馆陶县",
            "value": "130433"
          },
          {
            "label": "魏县",
            "value": "130434"
          },
          {
            "label": "曲周县",
            "value": "130435"
          },
          {
            "label": "邯郸经济技术开发区",
            "value": "130471"
          },
          {
            "label": "邯郸冀南新区",
            "value": "130473"
          },
          {
            "label": "武安市",
            "value": "130481"
          }
        ]
      },
      {
        "label": "邢台市",
        "value": "1305",
        "children": [
          {
            "label": "桥东区",
            "value": "130502"
          },
          {
            "label": "桥西区",
            "value": "130503"
          },
          {
            "label": "邢台县",
            "value": "130521"
          },
          {
            "label": "临城县",
            "value": "130522"
          },
          {
            "label": "内丘县",
            "value": "130523"
          },
          {
            "label": "柏乡县",
            "value": "130524"
          },
          {
            "label": "隆尧县",
            "value": "130525"
          },
          {
            "label": "任县",
            "value": "130526"
          },
          {
            "label": "南和县",
            "value": "130527"
          },
          {
            "label": "宁晋县",
            "value": "130528"
          },
          {
            "label": "巨鹿县",
            "value": "130529"
          },
          {
            "label": "新河县",
            "value": "130530"
          },
          {
            "label": "广宗县",
            "value": "130531"
          },
          {
            "label": "平乡县",
            "value": "130532"
          },
          {
            "label": "威县",
            "value": "130533"
          },
          {
            "label": "清河县",
            "value": "130534"
          },
          {
            "label": "临西县",
            "value": "130535"
          },
          {
            "label": "河北邢台经济开发区",
            "value": "130571"
          },
          {
            "label": "南宫市",
            "value": "130581"
          },
          {
            "label": "沙河市",
            "value": "130582"
          }
        ]
      },
      {
        "label": "保定市",
        "value": "1306",
        "children": [
          {
            "label": "竞秀区",
            "value": "130602"
          },
          {
            "label": "莲池区",
            "value": "130606"
          },
          {
            "label": "满城区",
            "value": "130607"
          },
          {
            "label": "清苑区",
            "value": "130608"
          },
          {
            "label": "徐水区",
            "value": "130609"
          },
          {
            "label": "涞水县",
            "value": "130623"
          },
          {
            "label": "阜平县",
            "value": "130624"
          },
          {
            "label": "定兴县",
            "value": "130626"
          },
          {
            "label": "唐县",
            "value": "130627"
          },
          {
            "label": "高阳县",
            "value": "130628"
          },
          {
            "label": "容城县",
            "value": "130629"
          },
          {
            "label": "涞源县",
            "value": "130630"
          },
          {
            "label": "望都县",
            "value": "130631"
          },
          {
            "label": "安新县",
            "value": "130632"
          },
          {
            "label": "易县",
            "value": "130633"
          },
          {
            "label": "曲阳县",
            "value": "130634"
          },
          {
            "label": "蠡县",
            "value": "130635"
          },
          {
            "label": "顺平县",
            "value": "130636"
          },
          {
            "label": "博野县",
            "value": "130637"
          },
          {
            "label": "雄县",
            "value": "130638"
          },
          {
            "label": "保定高新技术产业开发区",
            "value": "130671"
          },
          {
            "label": "保定白沟新城",
            "value": "130672"
          },
          {
            "label": "涿州市",
            "value": "130681"
          },
          {
            "label": "定州市",
            "value": "130682"
          },
          {
            "label": "安国市",
            "value": "130683"
          },
          {
            "label": "高碑店市",
            "value": "130684"
          }
        ]
      },
      {
        "label": "张家口市",
        "value": "1307",
        "children": [
          {
            "label": "桥东区",
            "value": "130702"
          },
          {
            "label": "桥西区",
            "value": "130703"
          },
          {
            "label": "宣化区",
            "value": "130705"
          },
          {
            "label": "下花园区",
            "value": "130706"
          },
          {
            "label": "万全区",
            "value": "130708"
          },
          {
            "label": "崇礼区",
            "value": "130709"
          },
          {
            "label": "张北县",
            "value": "130722"
          },
          {
            "label": "康保县",
            "value": "130723"
          },
          {
            "label": "沽源县",
            "value": "130724"
          },
          {
            "label": "尚义县",
            "value": "130725"
          },
          {
            "label": "蔚县",
            "value": "130726"
          },
          {
            "label": "阳原县",
            "value": "130727"
          },
          {
            "label": "怀安县",
            "value": "130728"
          },
          {
            "label": "怀来县",
            "value": "130730"
          },
          {
            "label": "涿鹿县",
            "value": "130731"
          },
          {
            "label": "赤城县",
            "value": "130732"
          },
          {
            "label": "张家口经济开发区",
            "value": "130771"
          },
          {
            "label": "张家口市察北管理区",
            "value": "130772"
          },
          {
            "label": "张家口市塞北管理区",
            "value": "130773"
          }
        ]
      },
      {
        "label": "承德市",
        "value": "1308",
        "children": [
          {
            "label": "双桥区",
            "value": "130802"
          },
          {
            "label": "双滦区",
            "value": "130803"
          },
          {
            "label": "鹰手营子矿区",
            "value": "130804"
          },
          {
            "label": "承德县",
            "value": "130821"
          },
          {
            "label": "兴隆县",
            "value": "130822"
          },
          {
            "label": "滦平县",
            "value": "130824"
          },
          {
            "label": "隆化县",
            "value": "130825"
          },
          {
            "label": "丰宁满族自治县",
            "value": "130826"
          },
          {
            "label": "宽城满族自治县",
            "value": "130827"
          },
          {
            "label": "围场满族蒙古族自治县",
            "value": "130828"
          },
          {
            "label": "承德高新技术产业开发区",
            "value": "130871"
          },
          {
            "label": "平泉市",
            "value": "130881"
          }
        ]
      },
      {
        "label": "沧州市",
        "value": "1309",
        "children": [
          {
            "label": "新华区",
            "value": "130902"
          },
          {
            "label": "运河区",
            "value": "130903"
          },
          {
            "label": "沧县",
            "value": "130921"
          },
          {
            "label": "青县",
            "value": "130922"
          },
          {
            "label": "东光县",
            "value": "130923"
          },
          {
            "label": "海兴县",
            "value": "130924"
          },
          {
            "label": "盐山县",
            "value": "130925"
          },
          {
            "label": "肃宁县",
            "value": "130926"
          },
          {
            "label": "南皮县",
            "value": "130927"
          },
          {
            "label": "吴桥县",
            "value": "130928"
          },
          {
            "label": "献县",
            "value": "130929"
          },
          {
            "label": "孟村回族自治县",
            "value": "130930"
          },
          {
            "label": "河北沧州经济开发区",
            "value": "130971"
          },
          {
            "label": "沧州高新技术产业开发区",
            "value": "130972"
          },
          {
            "label": "沧州渤海新区",
            "value": "130973"
          },
          {
            "label": "泊头市",
            "value": "130981"
          },
          {
            "label": "任丘市",
            "value": "130982"
          },
          {
            "label": "黄骅市",
            "value": "130983"
          },
          {
            "label": "河间市",
            "value": "130984"
          }
        ]
      },
      {
        "label": "廊坊市",
        "value": "1310",
        "children": [
          {
            "label": "安次区",
            "value": "131002"
          },
          {
            "label": "广阳区",
            "value": "131003"
          },
          {
            "label": "固安县",
            "value": "131022"
          },
          {
            "label": "永清县",
            "value": "131023"
          },
          {
            "label": "香河县",
            "value": "131024"
          },
          {
            "label": "大城县",
            "value": "131025"
          },
          {
            "label": "文安县",
            "value": "131026"
          },
          {
            "label": "大厂回族自治县",
            "value": "131028"
          },
          {
            "label": "廊坊经济技术开发区",
            "value": "131071"
          },
          {
            "label": "霸州市",
            "value": "131081"
          },
          {
            "label": "三河市",
            "value": "131082"
          }
        ]
      },
      {
        "label": "衡水市",
        "value": "1311",
        "children": [
          {
            "label": "桃城区",
            "value": "131102"
          },
          {
            "label": "冀州区",
            "value": "131103"
          },
          {
            "label": "枣强县",
            "value": "131121"
          },
          {
            "label": "武邑县",
            "value": "131122"
          },
          {
            "label": "武强县",
            "value": "131123"
          },
          {
            "label": "饶阳县",
            "value": "131124"
          },
          {
            "label": "安平县",
            "value": "131125"
          },
          {
            "label": "故城县",
            "value": "131126"
          },
          {
            "label": "景县",
            "value": "131127"
          },
          {
            "label": "阜城县",
            "value": "131128"
          },
          {
            "label": "河北衡水高新技术产业开发区",
            "value": "131171"
          },
          {
            "label": "衡水滨湖新区",
            "value": "131172"
          },
          {
            "label": "深州市",
            "value": "131182"
          }
        ]
      }
    ]
  },
  {
    "label": "山西省",
    "value": "14",
    "children": [
      {
        "label": "太原市",
        "value": "1401",
        "children": [
          {
            "label": "小店区",
            "value": "140105"
          },
          {
            "label": "迎泽区",
            "value": "140106"
          },
          {
            "label": "杏花岭区",
            "value": "140107"
          },
          {
            "label": "尖草坪区",
            "value": "140108"
          },
          {
            "label": "万柏林区",
            "value": "140109"
          },
          {
            "label": "晋源区",
            "value": "140110"
          },
          {
            "label": "清徐县",
            "value": "140121"
          },
          {
            "label": "阳曲县",
            "value": "140122"
          },
          {
            "label": "娄烦县",
            "value": "140123"
          },
          {
            "label": "山西转型综合改革示范区",
            "value": "140171"
          },
          {
            "label": "古交市",
            "value": "140181"
          }
        ]
      },
      {
        "label": "大同市",
        "value": "1402",
        "children": [
          {
            "label": "新荣区",
            "value": "140212"
          },
          {
            "label": "平城区",
            "value": "140213"
          },
          {
            "label": "云冈区",
            "value": "140214"
          },
          {
            "label": "云州区",
            "value": "140215"
          },
          {
            "label": "阳高县",
            "value": "140221"
          },
          {
            "label": "天镇县",
            "value": "140222"
          },
          {
            "label": "广灵县",
            "value": "140223"
          },
          {
            "label": "灵丘县",
            "value": "140224"
          },
          {
            "label": "浑源县",
            "value": "140225"
          },
          {
            "label": "左云县",
            "value": "140226"
          },
          {
            "label": "山西大同经济开发区",
            "value": "140271"
          }
        ]
      },
      {
        "label": "阳泉市",
        "value": "1403",
        "children": [
          {
            "label": "城区",
            "value": "140302"
          },
          {
            "label": "矿区",
            "value": "140303"
          },
          {
            "label": "郊区",
            "value": "140311"
          },
          {
            "label": "平定县",
            "value": "140321"
          },
          {
            "label": "盂县",
            "value": "140322"
          }
        ]
      },
      {
        "label": "长治市",
        "value": "1404",
        "children": [
          {
            "label": "潞州区",
            "value": "140403"
          },
          {
            "label": "上党区",
            "value": "140404"
          },
          {
            "label": "屯留区",
            "value": "140405"
          },
          {
            "label": "潞城区",
            "value": "140406"
          },
          {
            "label": "襄垣县",
            "value": "140423"
          },
          {
            "label": "平顺县",
            "value": "140425"
          },
          {
            "label": "黎城县",
            "value": "140426"
          },
          {
            "label": "壶关县",
            "value": "140427"
          },
          {
            "label": "长子县",
            "value": "140428"
          },
          {
            "label": "武乡县",
            "value": "140429"
          },
          {
            "label": "沁县",
            "value": "140430"
          },
          {
            "label": "沁源县",
            "value": "140431"
          },
          {
            "label": "山西长治高新技术产业园区",
            "value": "140471"
          }
        ]
      },
      {
        "label": "晋城市",
        "value": "1405",
        "children": [
          {
            "label": "城区",
            "value": "140502"
          },
          {
            "label": "沁水县",
            "value": "140521"
          },
          {
            "label": "阳城县",
            "value": "140522"
          },
          {
            "label": "陵川县",
            "value": "140524"
          },
          {
            "label": "泽州县",
            "value": "140525"
          },
          {
            "label": "高平市",
            "value": "140581"
          }
        ]
      },
      {
        "label": "朔州市",
        "value": "1406",
        "children": [
          {
            "label": "朔城区",
            "value": "140602"
          },
          {
            "label": "平鲁区",
            "value": "140603"
          },
          {
            "label": "山阴县",
            "value": "140621"
          },
          {
            "label": "应县",
            "value": "140622"
          },
          {
            "label": "右玉县",
            "value": "140623"
          },
          {
            "label": "山西朔州经济开发区",
            "value": "140671"
          },
          {
            "label": "怀仁市",
            "value": "140681"
          }
        ]
      },
      {
        "label": "晋中市",
        "value": "1407",
        "children": [
          {
            "label": "榆次区",
            "value": "140702"
          },
          {
            "label": "榆社县",
            "value": "140721"
          },
          {
            "label": "左权县",
            "value": "140722"
          },
          {
            "label": "和顺县",
            "value": "140723"
          },
          {
            "label": "昔阳县",
            "value": "140724"
          },
          {
            "label": "寿阳县",
            "value": "140725"
          },
          {
            "label": "太谷县",
            "value": "140726"
          },
          {
            "label": "祁县",
            "value": "140727"
          },
          {
            "label": "平遥县",
            "value": "140728"
          },
          {
            "label": "灵石县",
            "value": "140729"
          },
          {
            "label": "介休市",
            "value": "140781"
          }
        ]
      },
      {
        "label": "运城市",
        "value": "1408",
        "children": [
          {
            "label": "盐湖区",
            "value": "140802"
          },
          {
            "label": "临猗县",
            "value": "140821"
          },
          {
            "label": "万荣县",
            "value": "140822"
          },
          {
            "label": "闻喜县",
            "value": "140823"
          },
          {
            "label": "稷山县",
            "value": "140824"
          },
          {
            "label": "新绛县",
            "value": "140825"
          },
          {
            "label": "绛县",
            "value": "140826"
          },
          {
            "label": "垣曲县",
            "value": "140827"
          },
          {
            "label": "夏县",
            "value": "140828"
          },
          {
            "label": "平陆县",
            "value": "140829"
          },
          {
            "label": "芮城县",
            "value": "140830"
          },
          {
            "label": "永济市",
            "value": "140881"
          },
          {
            "label": "河津市",
            "value": "140882"
          }
        ]
      },
      {
        "label": "忻州市",
        "value": "1409",
        "children": [
          {
            "label": "忻府区",
            "value": "140902"
          },
          {
            "label": "定襄县",
            "value": "140921"
          },
          {
            "label": "五台县",
            "value": "140922"
          },
          {
            "label": "代县",
            "value": "140923"
          },
          {
            "label": "繁峙县",
            "value": "140924"
          },
          {
            "label": "宁武县",
            "value": "140925"
          },
          {
            "label": "静乐县",
            "value": "140926"
          },
          {
            "label": "神池县",
            "value": "140927"
          },
          {
            "label": "五寨县",
            "value": "140928"
          },
          {
            "label": "岢岚县",
            "value": "140929"
          },
          {
            "label": "河曲县",
            "value": "140930"
          },
          {
            "label": "保德县",
            "value": "140931"
          },
          {
            "label": "偏关县",
            "value": "140932"
          },
          {
            "label": "五台山风景名胜区",
            "value": "140971"
          },
          {
            "label": "原平市",
            "value": "140981"
          }
        ]
      },
      {
        "label": "临汾市",
        "value": "1410",
        "children": [
          {
            "label": "尧都区",
            "value": "141002"
          },
          {
            "label": "曲沃县",
            "value": "141021"
          },
          {
            "label": "翼城县",
            "value": "141022"
          },
          {
            "label": "襄汾县",
            "value": "141023"
          },
          {
            "label": "洪洞县",
            "value": "141024"
          },
          {
            "label": "古县",
            "value": "141025"
          },
          {
            "label": "安泽县",
            "value": "141026"
          },
          {
            "label": "浮山县",
            "value": "141027"
          },
          {
            "label": "吉县",
            "value": "141028"
          },
          {
            "label": "乡宁县",
            "value": "141029"
          },
          {
            "label": "大宁县",
            "value": "141030"
          },
          {
            "label": "隰县",
            "value": "141031"
          },
          {
            "label": "永和县",
            "value": "141032"
          },
          {
            "label": "蒲县",
            "value": "141033"
          },
          {
            "label": "汾西县",
            "value": "141034"
          },
          {
            "label": "侯马市",
            "value": "141081"
          },
          {
            "label": "霍州市",
            "value": "141082"
          }
        ]
      },
      {
        "label": "吕梁市",
        "value": "1411",
        "children": [
          {
            "label": "离石区",
            "value": "141102"
          },
          {
            "label": "文水县",
            "value": "141121"
          },
          {
            "label": "交城县",
            "value": "141122"
          },
          {
            "label": "兴县",
            "value": "141123"
          },
          {
            "label": "临县",
            "value": "141124"
          },
          {
            "label": "柳林县",
            "value": "141125"
          },
          {
            "label": "石楼县",
            "value": "141126"
          },
          {
            "label": "岚县",
            "value": "141127"
          },
          {
            "label": "方山县",
            "value": "141128"
          },
          {
            "label": "中阳县",
            "value": "141129"
          },
          {
            "label": "交口县",
            "value": "141130"
          },
          {
            "label": "孝义市",
            "value": "141181"
          },
          {
            "label": "汾阳市",
            "value": "141182"
          }
        ]
      }
    ]
  },
  {
    "label": "内蒙古自治区",
    "value": "15",
    "children": [
      {
        "label": "呼和浩特市",
        "value": "1501",
        "children": [
          {
            "label": "新城区",
            "value": "150102"
          },
          {
            "label": "回民区",
            "value": "150103"
          },
          {
            "label": "玉泉区",
            "value": "150104"
          },
          {
            "label": "赛罕区",
            "value": "150105"
          },
          {
            "label": "土默特左旗",
            "value": "150121"
          },
          {
            "label": "托克托县",
            "value": "150122"
          },
          {
            "label": "和林格尔县",
            "value": "150123"
          },
          {
            "label": "清水河县",
            "value": "150124"
          },
          {
            "label": "武川县",
            "value": "150125"
          },
          {
            "label": "呼和浩特金海工业园区",
            "value": "150171"
          },
          {
            "label": "呼和浩特经济技术开发区",
            "value": "150172"
          }
        ]
      },
      {
        "label": "包头市",
        "value": "1502",
        "children": [
          {
            "label": "东河区",
            "value": "150202"
          },
          {
            "label": "昆都仑区",
            "value": "150203"
          },
          {
            "label": "青山区",
            "value": "150204"
          },
          {
            "label": "石拐区",
            "value": "150205"
          },
          {
            "label": "白云鄂博矿区",
            "value": "150206"
          },
          {
            "label": "九原区",
            "value": "150207"
          },
          {
            "label": "土默特右旗",
            "value": "150221"
          },
          {
            "label": "固阳县",
            "value": "150222"
          },
          {
            "label": "达尔罕茂明安联合旗",
            "value": "150223"
          },
          {
            "label": "包头稀土高新技术产业开发区",
            "value": "150271"
          }
        ]
      },
      {
        "label": "乌海市",
        "value": "1503",
        "children": [
          {
            "label": "海勃湾区",
            "value": "150302"
          },
          {
            "label": "海南区",
            "value": "150303"
          },
          {
            "label": "乌达区",
            "value": "150304"
          }
        ]
      },
      {
        "label": "赤峰市",
        "value": "1504",
        "children": [
          {
            "label": "红山区",
            "value": "150402"
          },
          {
            "label": "元宝山区",
            "value": "150403"
          },
          {
            "label": "松山区",
            "value": "150404"
          },
          {
            "label": "阿鲁科尔沁旗",
            "value": "150421"
          },
          {
            "label": "巴林左旗",
            "value": "150422"
          },
          {
            "label": "巴林右旗",
            "value": "150423"
          },
          {
            "label": "林西县",
            "value": "150424"
          },
          {
            "label": "克什克腾旗",
            "value": "150425"
          },
          {
            "label": "翁牛特旗",
            "value": "150426"
          },
          {
            "label": "喀喇沁旗",
            "value": "150428"
          },
          {
            "label": "宁城县",
            "value": "150429"
          },
          {
            "label": "敖汉旗",
            "value": "150430"
          }
        ]
      },
      {
        "label": "通辽市",
        "value": "1505",
        "children": [
          {
            "label": "科尔沁区",
            "value": "150502"
          },
          {
            "label": "科尔沁左翼中旗",
            "value": "150521"
          },
          {
            "label": "科尔沁左翼后旗",
            "value": "150522"
          },
          {
            "label": "开鲁县",
            "value": "150523"
          },
          {
            "label": "库伦旗",
            "value": "150524"
          },
          {
            "label": "奈曼旗",
            "value": "150525"
          },
          {
            "label": "扎鲁特旗",
            "value": "150526"
          },
          {
            "label": "通辽经济技术开发区",
            "value": "150571"
          },
          {
            "label": "霍林郭勒市",
            "value": "150581"
          }
        ]
      },
      {
        "label": "鄂尔多斯市",
        "value": "1506",
        "children": [
          {
            "label": "东胜区",
            "value": "150602"
          },
          {
            "label": "康巴什区",
            "value": "150603"
          },
          {
            "label": "达拉特旗",
            "value": "150621"
          },
          {
            "label": "准格尔旗",
            "value": "150622"
          },
          {
            "label": "鄂托克前旗",
            "value": "150623"
          },
          {
            "label": "鄂托克旗",
            "value": "150624"
          },
          {
            "label": "杭锦旗",
            "value": "150625"
          },
          {
            "label": "乌审旗",
            "value": "150626"
          },
          {
            "label": "伊金霍洛旗",
            "value": "150627"
          }
        ]
      },
      {
        "label": "呼伦贝尔市",
        "value": "1507",
        "children": [
          {
            "label": "海拉尔区",
            "value": "150702"
          },
          {
            "label": "扎赉诺尔区",
            "value": "150703"
          },
          {
            "label": "阿荣旗",
            "value": "150721"
          },
          {
            "label": "莫力达瓦达斡尔族自治旗",
            "value": "150722"
          },
          {
            "label": "鄂伦春自治旗",
            "value": "150723"
          },
          {
            "label": "鄂温克族自治旗",
            "value": "150724"
          },
          {
            "label": "陈巴尔虎旗",
            "value": "150725"
          },
          {
            "label": "新巴尔虎左旗",
            "value": "150726"
          },
          {
            "label": "新巴尔虎右旗",
            "value": "150727"
          },
          {
            "label": "满洲里市",
            "value": "150781"
          },
          {
            "label": "牙克石市",
            "value": "150782"
          },
          {
            "label": "扎兰屯市",
            "value": "150783"
          },
          {
            "label": "额尔古纳市",
            "value": "150784"
          },
          {
            "label": "根河市",
            "value": "150785"
          }
        ]
      },
      {
        "label": "巴彦淖尔市",
        "value": "1508",
        "children": [
          {
            "label": "临河区",
            "value": "150802"
          },
          {
            "label": "五原县",
            "value": "150821"
          },
          {
            "label": "磴口县",
            "value": "150822"
          },
          {
            "label": "乌拉特前旗",
            "value": "150823"
          },
          {
            "label": "乌拉特中旗",
            "value": "150824"
          },
          {
            "label": "乌拉特后旗",
            "value": "150825"
          },
          {
            "label": "杭锦后旗",
            "value": "150826"
          }
        ]
      },
      {
        "label": "乌兰察布市",
        "value": "1509",
        "children": [
          {
            "label": "集宁区",
            "value": "150902"
          },
          {
            "label": "卓资县",
            "value": "150921"
          },
          {
            "label": "化德县",
            "value": "150922"
          },
          {
            "label": "商都县",
            "value": "150923"
          },
          {
            "label": "兴和县",
            "value": "150924"
          },
          {
            "label": "凉城县",
            "value": "150925"
          },
          {
            "label": "察哈尔右翼前旗",
            "value": "150926"
          },
          {
            "label": "察哈尔右翼中旗",
            "value": "150927"
          },
          {
            "label": "察哈尔右翼后旗",
            "value": "150928"
          },
          {
            "label": "四子王旗",
            "value": "150929"
          },
          {
            "label": "丰镇市",
            "value": "150981"
          }
        ]
      },
      {
        "label": "兴安盟",
        "value": "1522",
        "children": [
          {
            "label": "乌兰浩特市",
            "value": "152201"
          },
          {
            "label": "阿尔山市",
            "value": "152202"
          },
          {
            "label": "科尔沁右翼前旗",
            "value": "152221"
          },
          {
            "label": "科尔沁右翼中旗",
            "value": "152222"
          },
          {
            "label": "扎赉特旗",
            "value": "152223"
          },
          {
            "label": "突泉县",
            "value": "152224"
          }
        ]
      },
      {
        "label": "锡林郭勒盟",
        "value": "1525",
        "children": [
          {
            "label": "二连浩特市",
            "value": "152501"
          },
          {
            "label": "锡林浩特市",
            "value": "152502"
          },
          {
            "label": "阿巴嘎旗",
            "value": "152522"
          },
          {
            "label": "苏尼特左旗",
            "value": "152523"
          },
          {
            "label": "苏尼特右旗",
            "value": "152524"
          },
          {
            "label": "东乌珠穆沁旗",
            "value": "152525"
          },
          {
            "label": "西乌珠穆沁旗",
            "value": "152526"
          },
          {
            "label": "太仆寺旗",
            "value": "152527"
          },
          {
            "label": "镶黄旗",
            "value": "152528"
          },
          {
            "label": "正镶白旗",
            "value": "152529"
          },
          {
            "label": "正蓝旗",
            "value": "152530"
          },
          {
            "label": "多伦县",
            "value": "152531"
          },
          {
            "label": "乌拉盖管委会",
            "value": "152571"
          }
        ]
      },
      {
        "label": "阿拉善盟",
        "value": "1529",
        "children": [
          {
            "label": "阿拉善左旗",
            "value": "152921"
          },
          {
            "label": "阿拉善右旗",
            "value": "152922"
          },
          {
            "label": "额济纳旗",
            "value": "152923"
          },
          {
            "label": "内蒙古阿拉善经济开发区",
            "value": "152971"
          }
        ]
      }
    ]
  },
  {
    "label": "辽宁省",
    "value": "21",
    "children": [
      {
        "label": "沈阳市",
        "value": "2101",
        "children": [
          {
            "label": "和平区",
            "value": "210102"
          },
          {
            "label": "沈河区",
            "value": "210103"
          },
          {
            "label": "大东区",
            "value": "210104"
          },
          {
            "label": "皇姑区",
            "value": "210105"
          },
          {
            "label": "铁西区",
            "value": "210106"
          },
          {
            "label": "苏家屯区",
            "value": "210111"
          },
          {
            "label": "浑南区",
            "value": "210112"
          },
          {
            "label": "沈北新区",
            "value": "210113"
          },
          {
            "label": "于洪区",
            "value": "210114"
          },
          {
            "label": "辽中区",
            "value": "210115"
          },
          {
            "label": "康平县",
            "value": "210123"
          },
          {
            "label": "法库县",
            "value": "210124"
          },
          {
            "label": "新民市",
            "value": "210181"
          }
        ]
      },
      {
        "label": "大连市",
        "value": "2102",
        "children": [
          {
            "label": "中山区",
            "value": "210202"
          },
          {
            "label": "西岗区",
            "value": "210203"
          },
          {
            "label": "沙河口区",
            "value": "210204"
          },
          {
            "label": "甘井子区",
            "value": "210211"
          },
          {
            "label": "旅顺口区",
            "value": "210212"
          },
          {
            "label": "金州区",
            "value": "210213"
          },
          {
            "label": "普兰店区",
            "value": "210214"
          },
          {
            "label": "长海县",
            "value": "210224"
          },
          {
            "label": "瓦房店市",
            "value": "210281"
          },
          {
            "label": "庄河市",
            "value": "210283"
          }
        ]
      },
      {
        "label": "鞍山市",
        "value": "2103",
        "children": [
          {
            "label": "铁东区",
            "value": "210302"
          },
          {
            "label": "铁西区",
            "value": "210303"
          },
          {
            "label": "立山区",
            "value": "210304"
          },
          {
            "label": "千山区",
            "value": "210311"
          },
          {
            "label": "台安县",
            "value": "210321"
          },
          {
            "label": "岫岩满族自治县",
            "value": "210323"
          },
          {
            "label": "海城市",
            "value": "210381"
          }
        ]
      },
      {
        "label": "抚顺市",
        "value": "2104",
        "children": [
          {
            "label": "新抚区",
            "value": "210402"
          },
          {
            "label": "东洲区",
            "value": "210403"
          },
          {
            "label": "望花区",
            "value": "210404"
          },
          {
            "label": "顺城区",
            "value": "210411"
          },
          {
            "label": "抚顺县",
            "value": "210421"
          },
          {
            "label": "新宾满族自治县",
            "value": "210422"
          },
          {
            "label": "清原满族自治县",
            "value": "210423"
          }
        ]
      },
      {
        "label": "本溪市",
        "value": "2105",
        "children": [
          {
            "label": "平山区",
            "value": "210502"
          },
          {
            "label": "溪湖区",
            "value": "210503"
          },
          {
            "label": "明山区",
            "value": "210504"
          },
          {
            "label": "南芬区",
            "value": "210505"
          },
          {
            "label": "本溪满族自治县",
            "value": "210521"
          },
          {
            "label": "桓仁满族自治县",
            "value": "210522"
          }
        ]
      },
      {
        "label": "丹东市",
        "value": "2106",
        "children": [
          {
            "label": "元宝区",
            "value": "210602"
          },
          {
            "label": "振兴区",
            "value": "210603"
          },
          {
            "label": "振安区",
            "value": "210604"
          },
          {
            "label": "宽甸满族自治县",
            "value": "210624"
          },
          {
            "label": "东港市",
            "value": "210681"
          },
          {
            "label": "凤城市",
            "value": "210682"
          }
        ]
      },
      {
        "label": "锦州市",
        "value": "2107",
        "children": [
          {
            "label": "古塔区",
            "value": "210702"
          },
          {
            "label": "凌河区",
            "value": "210703"
          },
          {
            "label": "太和区",
            "value": "210711"
          },
          {
            "label": "黑山县",
            "value": "210726"
          },
          {
            "label": "义县",
            "value": "210727"
          },
          {
            "label": "凌海市",
            "value": "210781"
          },
          {
            "label": "北镇市",
            "value": "210782"
          }
        ]
      },
      {
        "label": "营口市",
        "value": "2108",
        "children": [
          {
            "label": "站前区",
            "value": "210802"
          },
          {
            "label": "西市区",
            "value": "210803"
          },
          {
            "label": "鲅鱼圈区",
            "value": "210804"
          },
          {
            "label": "老边区",
            "value": "210811"
          },
          {
            "label": "盖州市",
            "value": "210881"
          },
          {
            "label": "大石桥市",
            "value": "210882"
          }
        ]
      },
      {
        "label": "阜新市",
        "value": "2109",
        "children": [
          {
            "label": "海州区",
            "value": "210902"
          },
          {
            "label": "新邱区",
            "value": "210903"
          },
          {
            "label": "太平区",
            "value": "210904"
          },
          {
            "label": "清河门区",
            "value": "210905"
          },
          {
            "label": "细河区",
            "value": "210911"
          },
          {
            "label": "阜新蒙古族自治县",
            "value": "210921"
          },
          {
            "label": "彰武县",
            "value": "210922"
          }
        ]
      },
      {
        "label": "辽阳市",
        "value": "2110",
        "children": [
          {
            "label": "白塔区",
            "value": "211002"
          },
          {
            "label": "文圣区",
            "value": "211003"
          },
          {
            "label": "宏伟区",
            "value": "211004"
          },
          {
            "label": "弓长岭区",
            "value": "211005"
          },
          {
            "label": "太子河区",
            "value": "211011"
          },
          {
            "label": "辽阳县",
            "value": "211021"
          },
          {
            "label": "灯塔市",
            "value": "211081"
          }
        ]
      },
      {
        "label": "盘锦市",
        "value": "2111",
        "children": [
          {
            "label": "双台子区",
            "value": "211102"
          },
          {
            "label": "兴隆台区",
            "value": "211103"
          },
          {
            "label": "大洼区",
            "value": "211104"
          },
          {
            "label": "盘山县",
            "value": "211122"
          }
        ]
      },
      {
        "label": "铁岭市",
        "value": "2112",
        "children": [
          {
            "label": "银州区",
            "value": "211202"
          },
          {
            "label": "清河区",
            "value": "211204"
          },
          {
            "label": "铁岭县",
            "value": "211221"
          },
          {
            "label": "西丰县",
            "value": "211223"
          },
          {
            "label": "昌图县",
            "value": "211224"
          },
          {
            "label": "调兵山市",
            "value": "211281"
          },
          {
            "label": "开原市",
            "value": "211282"
          }
        ]
      },
      {
        "label": "朝阳市",
        "value": "2113",
        "children": [
          {
            "label": "双塔区",
            "value": "211302"
          },
          {
            "label": "龙城区",
            "value": "211303"
          },
          {
            "label": "朝阳县",
            "value": "211321"
          },
          {
            "label": "建平县",
            "value": "211322"
          },
          {
            "label": "喀喇沁左翼蒙古族自治县",
            "value": "211324"
          },
          {
            "label": "北票市",
            "value": "211381"
          },
          {
            "label": "凌源市",
            "value": "211382"
          }
        ]
      },
      {
        "label": "葫芦岛市",
        "value": "2114",
        "children": [
          {
            "label": "连山区",
            "value": "211402"
          },
          {
            "label": "龙港区",
            "value": "211403"
          },
          {
            "label": "南票区",
            "value": "211404"
          },
          {
            "label": "绥中县",
            "value": "211421"
          },
          {
            "label": "建昌县",
            "value": "211422"
          },
          {
            "label": "兴城市",
            "value": "211481"
          }
        ]
      }
    ]
  },
  {
    "label": "吉林省",
    "value": "22",
    "children": [
      {
        "label": "长春市",
        "value": "2201",
        "children": [
          {
            "label": "南关区",
            "value": "220102"
          },
          {
            "label": "宽城区",
            "value": "220103"
          },
          {
            "label": "朝阳区",
            "value": "220104"
          },
          {
            "label": "二道区",
            "value": "220105"
          },
          {
            "label": "绿园区",
            "value": "220106"
          },
          {
            "label": "双阳区",
            "value": "220112"
          },
          {
            "label": "九台区",
            "value": "220113"
          },
          {
            "label": "农安县",
            "value": "220122"
          },
          {
            "label": "长春经济技术开发区",
            "value": "220171"
          },
          {
            "label": "长春净月高新技术产业开发区",
            "value": "220172"
          },
          {
            "label": "长春高新技术产业开发区",
            "value": "220173"
          },
          {
            "label": "长春汽车经济技术开发区",
            "value": "220174"
          },
          {
            "label": "榆树市",
            "value": "220182"
          },
          {
            "label": "德惠市",
            "value": "220183"
          }
        ]
      },
      {
        "label": "吉林市",
        "value": "2202",
        "children": [
          {
            "label": "昌邑区",
            "value": "220202"
          },
          {
            "label": "龙潭区",
            "value": "220203"
          },
          {
            "label": "船营区",
            "value": "220204"
          },
          {
            "label": "丰满区",
            "value": "220211"
          },
          {
            "label": "永吉县",
            "value": "220221"
          },
          {
            "label": "吉林经济开发区",
            "value": "220271"
          },
          {
            "label": "吉林高新技术产业开发区",
            "value": "220272"
          },
          {
            "label": "吉林中国新加坡食品区",
            "value": "220273"
          },
          {
            "label": "蛟河市",
            "value": "220281"
          },
          {
            "label": "桦甸市",
            "value": "220282"
          },
          {
            "label": "舒兰市",
            "value": "220283"
          },
          {
            "label": "磐石市",
            "value": "220284"
          }
        ]
      },
      {
        "label": "四平市",
        "value": "2203",
        "children": [
          {
            "label": "铁西区",
            "value": "220302"
          },
          {
            "label": "铁东区",
            "value": "220303"
          },
          {
            "label": "梨树县",
            "value": "220322"
          },
          {
            "label": "伊通满族自治县",
            "value": "220323"
          },
          {
            "label": "公主岭市",
            "value": "220381"
          },
          {
            "label": "双辽市",
            "value": "220382"
          }
        ]
      },
      {
        "label": "辽源市",
        "value": "2204",
        "children": [
          {
            "label": "龙山区",
            "value": "220402"
          },
          {
            "label": "西安区",
            "value": "220403"
          },
          {
            "label": "东丰县",
            "value": "220421"
          },
          {
            "label": "东辽县",
            "value": "220422"
          }
        ]
      },
      {
        "label": "通化市",
        "value": "2205",
        "children": [
          {
            "label": "东昌区",
            "value": "220502"
          },
          {
            "label": "二道江区",
            "value": "220503"
          },
          {
            "label": "通化县",
            "value": "220521"
          },
          {
            "label": "辉南县",
            "value": "220523"
          },
          {
            "label": "柳河县",
            "value": "220524"
          },
          {
            "label": "梅河口市",
            "value": "220581"
          },
          {
            "label": "集安市",
            "value": "220582"
          }
        ]
      },
      {
        "label": "白山市",
        "value": "2206",
        "children": [
          {
            "label": "浑江区",
            "value": "220602"
          },
          {
            "label": "江源区",
            "value": "220605"
          },
          {
            "label": "抚松县",
            "value": "220621"
          },
          {
            "label": "靖宇县",
            "value": "220622"
          },
          {
            "label": "长白朝鲜族自治县",
            "value": "220623"
          },
          {
            "label": "临江市",
            "value": "220681"
          }
        ]
      },
      {
        "label": "松原市",
        "value": "2207",
        "children": [
          {
            "label": "宁江区",
            "value": "220702"
          },
          {
            "label": "前郭尔罗斯蒙古族自治县",
            "value": "220721"
          },
          {
            "label": "长岭县",
            "value": "220722"
          },
          {
            "label": "乾安县",
            "value": "220723"
          },
          {
            "label": "吉林松原经济开发区",
            "value": "220771"
          },
          {
            "label": "扶余市",
            "value": "220781"
          }
        ]
      },
      {
        "label": "白城市",
        "value": "2208",
        "children": [
          {
            "label": "洮北区",
            "value": "220802"
          },
          {
            "label": "镇赉县",
            "value": "220821"
          },
          {
            "label": "通榆县",
            "value": "220822"
          },
          {
            "label": "吉林白城经济开发区",
            "value": "220871"
          },
          {
            "label": "洮南市",
            "value": "220881"
          },
          {
            "label": "大安市",
            "value": "220882"
          }
        ]
      },
      {
        "label": "延边朝鲜族自治州",
        "value": "2224",
        "children": [
          {
            "label": "延吉市",
            "value": "222401"
          },
          {
            "label": "图们市",
            "value": "222402"
          },
          {
            "label": "敦化市",
            "value": "222403"
          },
          {
            "label": "珲春市",
            "value": "222404"
          },
          {
            "label": "龙井市",
            "value": "222405"
          },
          {
            "label": "和龙市",
            "value": "222406"
          },
          {
            "label": "汪清县",
            "value": "222424"
          },
          {
            "label": "安图县",
            "value": "222426"
          }
        ]
      }
    ]
  },
  {
    "label": "黑龙江省",
    "value": "23",
    "children": [
      {
        "label": "哈尔滨市",
        "value": "2301",
        "children": [
          {
            "label": "道里区",
            "value": "230102"
          },
          {
            "label": "南岗区",
            "value": "230103"
          },
          {
            "label": "道外区",
            "value": "230104"
          },
          {
            "label": "平房区",
            "value": "230108"
          },
          {
            "label": "松北区",
            "value": "230109"
          },
          {
            "label": "香坊区",
            "value": "230110"
          },
          {
            "label": "呼兰区",
            "value": "230111"
          },
          {
            "label": "阿城区",
            "value": "230112"
          },
          {
            "label": "双城区",
            "value": "230113"
          },
          {
            "label": "依兰县",
            "value": "230123"
          },
          {
            "label": "方正县",
            "value": "230124"
          },
          {
            "label": "宾县",
            "value": "230125"
          },
          {
            "label": "巴彦县",
            "value": "230126"
          },
          {
            "label": "木兰县",
            "value": "230127"
          },
          {
            "label": "通河县",
            "value": "230128"
          },
          {
            "label": "延寿县",
            "value": "230129"
          },
          {
            "label": "尚志市",
            "value": "230183"
          },
          {
            "label": "五常市",
            "value": "230184"
          }
        ]
      },
      {
        "label": "齐齐哈尔市",
        "value": "2302",
        "children": [
          {
            "label": "龙沙区",
            "value": "230202"
          },
          {
            "label": "建华区",
            "value": "230203"
          },
          {
            "label": "铁锋区",
            "value": "230204"
          },
          {
            "label": "昂昂溪区",
            "value": "230205"
          },
          {
            "label": "富拉尔基区",
            "value": "230206"
          },
          {
            "label": "碾子山区",
            "value": "230207"
          },
          {
            "label": "梅里斯达斡尔族区",
            "value": "230208"
          },
          {
            "label": "龙江县",
            "value": "230221"
          },
          {
            "label": "依安县",
            "value": "230223"
          },
          {
            "label": "泰来县",
            "value": "230224"
          },
          {
            "label": "甘南县",
            "value": "230225"
          },
          {
            "label": "富裕县",
            "value": "230227"
          },
          {
            "label": "克山县",
            "value": "230229"
          },
          {
            "label": "克东县",
            "value": "230230"
          },
          {
            "label": "拜泉县",
            "value": "230231"
          },
          {
            "label": "讷河市",
            "value": "230281"
          }
        ]
      },
      {
        "label": "鸡西市",
        "value": "2303",
        "children": [
          {
            "label": "鸡冠区",
            "value": "230302"
          },
          {
            "label": "恒山区",
            "value": "230303"
          },
          {
            "label": "滴道区",
            "value": "230304"
          },
          {
            "label": "梨树区",
            "value": "230305"
          },
          {
            "label": "城子河区",
            "value": "230306"
          },
          {
            "label": "麻山区",
            "value": "230307"
          },
          {
            "label": "鸡东县",
            "value": "230321"
          },
          {
            "label": "虎林市",
            "value": "230381"
          },
          {
            "label": "密山市",
            "value": "230382"
          }
        ]
      },
      {
        "label": "鹤岗市",
        "value": "2304",
        "children": [
          {
            "label": "向阳区",
            "value": "230402"
          },
          {
            "label": "工农区",
            "value": "230403"
          },
          {
            "label": "南山区",
            "value": "230404"
          },
          {
            "label": "兴安区",
            "value": "230405"
          },
          {
            "label": "东山区",
            "value": "230406"
          },
          {
            "label": "兴山区",
            "value": "230407"
          },
          {
            "label": "萝北县",
            "value": "230421"
          },
          {
            "label": "绥滨县",
            "value": "230422"
          }
        ]
      },
      {
        "label": "双鸭山市",
        "value": "2305",
        "children": [
          {
            "label": "尖山区",
            "value": "230502"
          },
          {
            "label": "岭东区",
            "value": "230503"
          },
          {
            "label": "四方台区",
            "value": "230505"
          },
          {
            "label": "宝山区",
            "value": "230506"
          },
          {
            "label": "集贤县",
            "value": "230521"
          },
          {
            "label": "友谊县",
            "value": "230522"
          },
          {
            "label": "宝清县",
            "value": "230523"
          },
          {
            "label": "饶河县",
            "value": "230524"
          }
        ]
      },
      {
        "label": "大庆市",
        "value": "2306",
        "children": [
          {
            "label": "萨尔图区",
            "value": "230602"
          },
          {
            "label": "龙凤区",
            "value": "230603"
          },
          {
            "label": "让胡路区",
            "value": "230604"
          },
          {
            "label": "红岗区",
            "value": "230605"
          },
          {
            "label": "大同区",
            "value": "230606"
          },
          {
            "label": "肇州县",
            "value": "230621"
          },
          {
            "label": "肇源县",
            "value": "230622"
          },
          {
            "label": "林甸县",
            "value": "230623"
          },
          {
            "label": "杜尔伯特蒙古族自治县",
            "value": "230624"
          },
          {
            "label": "大庆高新技术产业开发区",
            "value": "230671"
          }
        ]
      },
      {
        "label": "伊春市",
        "value": "2307",
        "children": [
          {
            "label": "伊美区",
            "value": "230717"
          },
          {
            "label": "乌翠区",
            "value": "230718"
          },
          {
            "label": "友好区",
            "value": "230719"
          },
          {
            "label": "嘉荫县",
            "value": "230722"
          },
          {
            "label": "汤旺县",
            "value": "230723"
          },
          {
            "label": "丰林县",
            "value": "230724"
          },
          {
            "label": "大箐山县",
            "value": "230725"
          },
          {
            "label": "南岔县",
            "value": "230726"
          },
          {
            "label": "金林区",
            "value": "230751"
          },
          {
            "label": "铁力市",
            "value": "230781"
          }
        ]
      },
      {
        "label": "佳木斯市",
        "value": "2308",
        "children": [
          {
            "label": "向阳区",
            "value": "230803"
          },
          {
            "label": "前进区",
            "value": "230804"
          },
          {
            "label": "东风区",
            "value": "230805"
          },
          {
            "label": "郊区",
            "value": "230811"
          },
          {
            "label": "桦南县",
            "value": "230822"
          },
          {
            "label": "桦川县",
            "value": "230826"
          },
          {
            "label": "汤原县",
            "value": "230828"
          },
          {
            "label": "同江市",
            "value": "230881"
          },
          {
            "label": "富锦市",
            "value": "230882"
          },
          {
            "label": "抚远市",
            "value": "230883"
          }
        ]
      },
      {
        "label": "七台河市",
        "value": "2309",
        "children": [
          {
            "label": "新兴区",
            "value": "230902"
          },
          {
            "label": "桃山区",
            "value": "230903"
          },
          {
            "label": "茄子河区",
            "value": "230904"
          },
          {
            "label": "勃利县",
            "value": "230921"
          }
        ]
      },
      {
        "label": "牡丹江市",
        "value": "2310",
        "children": [
          {
            "label": "东安区",
            "value": "231002"
          },
          {
            "label": "阳明区",
            "value": "231003"
          },
          {
            "label": "爱民区",
            "value": "231004"
          },
          {
            "label": "西安区",
            "value": "231005"
          },
          {
            "label": "林口县",
            "value": "231025"
          },
          {
            "label": "牡丹江经济技术开发区",
            "value": "231071"
          },
          {
            "label": "绥芬河市",
            "value": "231081"
          },
          {
            "label": "海林市",
            "value": "231083"
          },
          {
            "label": "宁安市",
            "value": "231084"
          },
          {
            "label": "穆棱市",
            "value": "231085"
          },
          {
            "label": "东宁市",
            "value": "231086"
          }
        ]
      },
      {
        "label": "黑河市",
        "value": "2311",
        "children": [
          {
            "label": "爱辉区",
            "value": "231102"
          },
          {
            "label": "逊克县",
            "value": "231123"
          },
          {
            "label": "孙吴县",
            "value": "231124"
          },
          {
            "label": "北安市",
            "value": "231181"
          },
          {
            "label": "五大连池市",
            "value": "231182"
          },
          {
            "label": "嫩江市",
            "value": "231183"
          }
        ]
      },
      {
        "label": "绥化市",
        "value": "2312",
        "children": [
          {
            "label": "北林区",
            "value": "231202"
          },
          {
            "label": "望奎县",
            "value": "231221"
          },
          {
            "label": "兰西县",
            "value": "231222"
          },
          {
            "label": "青冈县",
            "value": "231223"
          },
          {
            "label": "庆安县",
            "value": "231224"
          },
          {
            "label": "明水县",
            "value": "231225"
          },
          {
            "label": "绥棱县",
            "value": "231226"
          },
          {
            "label": "安达市",
            "value": "231281"
          },
          {
            "label": "肇东市",
            "value": "231282"
          },
          {
            "label": "海伦市",
            "value": "231283"
          }
        ]
      },
      {
        "label": "大兴安岭地区",
        "value": "2327",
        "children": [
          {
            "label": "漠河市",
            "value": "232701"
          },
          {
            "label": "呼玛县",
            "value": "232721"
          },
          {
            "label": "塔河县",
            "value": "232722"
          },
          {
            "label": "加格达奇区",
            "value": "232761"
          },
          {
            "label": "松岭区",
            "value": "232762"
          },
          {
            "label": "新林区",
            "value": "232763"
          },
          {
            "label": "呼中区",
            "value": "232764"
          }
        ]
      }
    ]
  },
  {
    "label": "上海市",
    "value": "3101",
    "children": [
      {
        "label": "黄浦区",
        "value": "310101",
        "children": [
          {
            "label": "南京东路街道",
            "value": "310101002"
          },
          {
            "label": "外滩街道",
            "value": "310101013"
          },
          {
            "label": "半淞园路街道",
            "value": "310101015"
          },
          {
            "label": "小东门街道",
            "value": "310101017"
          },
          {
            "label": "豫园街道",
            "value": "310101018"
          },
          {
            "label": "老西门街道",
            "value": "310101019"
          },
          {
            "label": "五里桥街道",
            "value": "310101020"
          },
          {
            "label": "打浦桥街道",
            "value": "310101021"
          },
          {
            "label": "淮海中路街道",
            "value": "310101022"
          },
          {
            "label": "瑞金二路街道",
            "value": "310101023"
          }
        ]
      },
      {
        "label": "徐汇区",
        "value": "310104",
        "children": [
          {
            "label": "天平路街道",
            "value": "310104003"
          },
          {
            "label": "湖南路街道",
            "value": "310104004"
          },
          {
            "label": "斜土路街道",
            "value": "310104007"
          },
          {
            "label": "枫林路街道",
            "value": "310104008"
          },
          {
            "label": "长桥街道",
            "value": "310104010"
          },
          {
            "label": "田林街道",
            "value": "310104011"
          },
          {
            "label": "虹梅路街道",
            "value": "310104012"
          },
          {
            "label": "康健新村街道",
            "value": "310104013"
          },
          {
            "label": "徐家汇街道",
            "value": "310104014"
          },
          {
            "label": "凌云路街道",
            "value": "310104015"
          },
          {
            "label": "龙华街道",
            "value": "310104016"
          },
          {
            "label": "漕河泾街道",
            "value": "310104017"
          },
          {
            "label": "华泾镇",
            "value": "310104103"
          },
          {
            "label": "漕河泾新兴技术开发区",
            "value": "310104501"
          }
        ]
      },
      {
        "label": "长宁区",
        "value": "310105",
        "children": [
          {
            "label": "华阳路街道",
            "value": "310105001"
          },
          {
            "label": "江苏路街道",
            "value": "310105002"
          },
          {
            "label": "新华路街道",
            "value": "310105004"
          },
          {
            "label": "周家桥街道",
            "value": "310105005"
          },
          {
            "label": "天山路街道",
            "value": "310105006"
          },
          {
            "label": "仙霞新村街道",
            "value": "310105008"
          },
          {
            "label": "虹桥街道",
            "value": "310105009"
          },
          {
            "label": "程家桥街道",
            "value": "310105010"
          },
          {
            "label": "北新泾街道",
            "value": "310105011"
          },
          {
            "label": "新泾镇",
            "value": "310105102"
          }
        ]
      },
      {
        "label": "静安区",
        "value": "310106",
        "children": [
          {
            "label": "江宁路街道",
            "value": "310106006"
          },
          {
            "label": "石门二路街道",
            "value": "310106011"
          },
          {
            "label": "南京西路街道",
            "value": "310106012"
          },
          {
            "label": "静安寺街道",
            "value": "310106013"
          },
          {
            "label": "曹家渡街道",
            "value": "310106014"
          },
          {
            "label": "天目西路街道",
            "value": "310106015"
          },
          {
            "label": "北站街道",
            "value": "310106016"
          },
          {
            "label": "宝山路街道",
            "value": "310106017"
          },
          {
            "label": "共和新路街道",
            "value": "310106018"
          },
          {
            "label": "大宁路街道",
            "value": "310106019"
          },
          {
            "label": "彭浦新村街道",
            "value": "310106020"
          },
          {
            "label": "临汾路街道",
            "value": "310106021"
          },
          {
            "label": "芷江西路街道",
            "value": "310106022"
          },
          {
            "label": "彭浦镇",
            "value": "310106101"
          }
        ]
      },
      {
        "label": "普陀区",
        "value": "310107",
        "children": [
          {
            "label": "曹杨新村街道",
            "value": "310107005"
          },
          {
            "label": "长风新村街道",
            "value": "310107014"
          },
          {
            "label": "长寿路街道",
            "value": "310107015"
          },
          {
            "label": "甘泉路街道",
            "value": "310107016"
          },
          {
            "label": "石泉路街道",
            "value": "310107017"
          },
          {
            "label": "宜川路街道",
            "value": "310107020"
          },
          {
            "label": "万里街道",
            "value": "310107021"
          },
          {
            "label": "真如镇街道",
            "value": "310107022"
          },
          {
            "label": "长征镇",
            "value": "310107102"
          },
          {
            "label": "桃浦镇",
            "value": "310107103"
          }
        ]
      },
      {
        "label": "虹口区",
        "value": "310109",
        "children": [
          {
            "label": "欧阳路街道",
            "value": "310109009"
          },
          {
            "label": "曲阳路街道",
            "value": "310109010"
          },
          {
            "label": "广中路街道",
            "value": "310109011"
          },
          {
            "label": "嘉兴路街道",
            "value": "310109014"
          },
          {
            "label": "凉城新村街道",
            "value": "310109016"
          },
          {
            "label": "四川北路街道",
            "value": "310109017"
          },
          {
            "label": "北外滩街道",
            "value": "310109018"
          },
          {
            "label": "江湾镇街道",
            "value": "310109019"
          }
        ]
      },
      {
        "label": "杨浦区",
        "value": "310110",
        "children": [
          {
            "label": "定海路街道",
            "value": "310110001"
          },
          {
            "label": "平凉路街道",
            "value": "310110006"
          },
          {
            "label": "江浦路街道",
            "value": "310110008"
          },
          {
            "label": "四平路街道",
            "value": "310110009"
          },
          {
            "label": "控江路街道",
            "value": "310110012"
          },
          {
            "label": "长白新村街道",
            "value": "310110013"
          },
          {
            "label": "延吉新村街道",
            "value": "310110015"
          },
          {
            "label": "殷行街道",
            "value": "310110016"
          },
          {
            "label": "大桥街道",
            "value": "310110018"
          },
          {
            "label": "五角场街道",
            "value": "310110019"
          },
          {
            "label": "新江湾城街道",
            "value": "310110020"
          },
          {
            "label": "长海路街道",
            "value": "310110021"
          }
        ]
      },
      {
        "label": "闵行区",
        "value": "310112",
        "children": [
          {
            "label": "江川路街道",
            "value": "310112001"
          },
          {
            "label": "古美街道",
            "value": "310112006"
          },
          {
            "label": "新虹街道",
            "value": "310112008"
          },
          {
            "label": "浦锦街道",
            "value": "310112009"
          },
          {
            "label": "莘庄镇",
            "value": "310112101"
          },
          {
            "label": "七宝镇",
            "value": "310112102"
          },
          {
            "label": "颛桥镇",
            "value": "310112103"
          },
          {
            "label": "华漕镇",
            "value": "310112106"
          },
          {
            "label": "虹桥镇",
            "value": "310112107"
          },
          {
            "label": "梅陇镇",
            "value": "310112108"
          },
          {
            "label": "吴泾镇",
            "value": "310112110"
          },
          {
            "label": "马桥镇",
            "value": "310112112"
          },
          {
            "label": "浦江镇",
            "value": "310112114"
          },
          {
            "label": "莘庄工业区",
            "value": "310112501"
          }
        ]
      },
      {
        "label": "宝山区",
        "value": "310113",
        "children": [
          {
            "label": "友谊路街道",
            "value": "310113003"
          },
          {
            "label": "吴淞街道",
            "value": "310113007"
          },
          {
            "label": "张庙街道",
            "value": "310113008"
          },
          {
            "label": "罗店镇",
            "value": "310113101"
          },
          {
            "label": "大场镇",
            "value": "310113102"
          },
          {
            "label": "杨行镇",
            "value": "310113103"
          },
          {
            "label": "月浦镇",
            "value": "310113104"
          },
          {
            "label": "罗泾镇",
            "value": "310113106"
          },
          {
            "label": "顾村镇",
            "value": "310113109"
          },
          {
            "label": "高境镇",
            "value": "310113111"
          },
          {
            "label": "庙行镇",
            "value": "310113112"
          },
          {
            "label": "淞南镇",
            "value": "310113113"
          },
          {
            "label": "宝山城市工业园区",
            "value": "310113501"
          }
        ]
      },
      {
        "label": "嘉定区",
        "value": "310114",
        "children": [
          {
            "label": "新成路街道",
            "value": "310114001"
          },
          {
            "label": "真新街道",
            "value": "310114002"
          },
          {
            "label": "嘉定镇街道",
            "value": "310114004"
          },
          {
            "label": "南翔镇",
            "value": "310114102"
          },
          {
            "label": "安亭镇",
            "value": "310114103"
          },
          {
            "label": "马陆镇",
            "value": "310114106"
          },
          {
            "label": "徐行镇",
            "value": "310114109"
          },
          {
            "label": "华亭镇",
            "value": "310114111"
          },
          {
            "label": "外冈镇",
            "value": "310114114"
          },
          {
            "label": "江桥镇",
            "value": "310114118"
          },
          {
            "label": "菊园新区",
            "value": "310114401"
          },
          {
            "label": "嘉定工业区",
            "value": "310114501"
          }
        ]
      },
      {
        "label": "浦东新区",
        "value": "310115",
        "children": [
          {
            "label": "潍坊新村街道",
            "value": "310115004"
          },
          {
            "label": "陆家嘴街道",
            "value": "310115005"
          },
          {
            "label": "周家渡街道",
            "value": "310115007"
          },
          {
            "label": "塘桥街道",
            "value": "310115008"
          },
          {
            "label": "上钢新村街道",
            "value": "310115009"
          },
          {
            "label": "南码头路街道",
            "value": "310115010"
          },
          {
            "label": "沪东新村街道",
            "value": "310115011"
          },
          {
            "label": "金杨新村街道",
            "value": "310115012"
          },
          {
            "label": "洋泾街道",
            "value": "310115013"
          },
          {
            "label": "浦兴路街道",
            "value": "310115014"
          },
          {
            "label": "东明路街道",
            "value": "310115015"
          },
          {
            "label": "花木街道",
            "value": "310115016"
          },
          {
            "label": "川沙新镇",
            "value": "310115103"
          },
          {
            "label": "高桥镇",
            "value": "310115104"
          },
          {
            "label": "北蔡镇",
            "value": "310115105"
          },
          {
            "label": "合庆镇",
            "value": "310115110"
          },
          {
            "label": "唐镇",
            "value": "310115114"
          },
          {
            "label": "曹路镇",
            "value": "310115117"
          },
          {
            "label": "金桥镇",
            "value": "310115120"
          },
          {
            "label": "高行镇",
            "value": "310115121"
          },
          {
            "label": "高东镇",
            "value": "310115123"
          },
          {
            "label": "张江镇",
            "value": "310115125"
          },
          {
            "label": "三林镇",
            "value": "310115130"
          },
          {
            "label": "惠南镇",
            "value": "310115131"
          },
          {
            "label": "周浦镇",
            "value": "310115132"
          },
          {
            "label": "新场镇",
            "value": "310115133"
          },
          {
            "label": "大团镇",
            "value": "310115134"
          },
          {
            "label": "康桥镇",
            "value": "310115136"
          },
          {
            "label": "航头镇",
            "value": "310115137"
          },
          {
            "label": "祝桥镇",
            "value": "310115139"
          },
          {
            "label": "泥城镇",
            "value": "310115140"
          },
          {
            "label": "宣桥镇",
            "value": "310115141"
          },
          {
            "label": "书院镇",
            "value": "310115142"
          },
          {
            "label": "万祥镇",
            "value": "310115143"
          },
          {
            "label": "老港镇",
            "value": "310115144"
          },
          {
            "label": "南汇新城镇",
            "value": "310115145"
          },
          {
            "label": "芦潮港农场",
            "value": "310115401"
          },
          {
            "label": "东海农场",
            "value": "310115402"
          },
          {
            "label": "朝阳农场",
            "value": "310115403"
          },
          {
            "label": "中国（上海）自由贸易试验区（保税片区）",
            "value": "310115501"
          },
          {
            "label": "金桥经济技术开发区",
            "value": "310115502"
          },
          {
            "label": "张江高科技园区",
            "value": "310115503"
          }
        ]
      },
      {
        "label": "金山区",
        "value": "310116",
        "children": [
          {
            "label": "石化街道",
            "value": "310116001"
          },
          {
            "label": "朱泾镇",
            "value": "310116101"
          },
          {
            "label": "枫泾镇",
            "value": "310116102"
          },
          {
            "label": "张堰镇",
            "value": "310116103"
          },
          {
            "label": "亭林镇",
            "value": "310116104"
          },
          {
            "label": "吕巷镇",
            "value": "310116105"
          },
          {
            "label": "廊下镇",
            "value": "310116107"
          },
          {
            "label": "金山卫镇",
            "value": "310116109"
          },
          {
            "label": "漕泾镇",
            "value": "310116112"
          },
          {
            "label": "山阳镇",
            "value": "310116113"
          },
          {
            "label": "金山工业区",
            "value": "310116503"
          }
        ]
      },
      {
        "label": "松江区",
        "value": "310117",
        "children": [
          {
            "label": "岳阳街道",
            "value": "310117001"
          },
          {
            "label": "永丰街道",
            "value": "310117002"
          },
          {
            "label": "方松街道",
            "value": "310117003"
          },
          {
            "label": "中山街道",
            "value": "310117004"
          },
          {
            "label": "广富林街道",
            "value": "310117005"
          },
          {
            "label": "九里亭街道",
            "value": "310117006"
          },
          {
            "label": "泗泾镇",
            "value": "310117102"
          },
          {
            "label": "佘山镇",
            "value": "310117103"
          },
          {
            "label": "车墩镇",
            "value": "310117104"
          },
          {
            "label": "新桥镇",
            "value": "310117105"
          },
          {
            "label": "洞泾镇",
            "value": "310117106"
          },
          {
            "label": "九亭镇",
            "value": "310117107"
          },
          {
            "label": "泖港镇",
            "value": "310117109"
          },
          {
            "label": "石湖荡镇",
            "value": "310117116"
          },
          {
            "label": "新浜镇",
            "value": "310117117"
          },
          {
            "label": "叶榭镇",
            "value": "310117120"
          },
          {
            "label": "小昆山镇",
            "value": "310117121"
          },
          {
            "label": "松江工业区",
            "value": "310117501"
          },
          {
            "label": "佘山度假区",
            "value": "310117504"
          },
          {
            "label": "上海松江出口加工区",
            "value": "310117507"
          }
        ]
      },
      {
        "label": "青浦区",
        "value": "310118",
        "children": [
          {
            "label": "夏阳街道",
            "value": "310118001"
          },
          {
            "label": "盈浦街道",
            "value": "310118002"
          },
          {
            "label": "香花桥街道",
            "value": "310118003"
          },
          {
            "label": "朱家角镇",
            "value": "310118102"
          },
          {
            "label": "练塘镇",
            "value": "310118103"
          },
          {
            "label": "金泽镇",
            "value": "310118104"
          },
          {
            "label": "赵巷镇",
            "value": "310118105"
          },
          {
            "label": "徐泾镇",
            "value": "310118106"
          },
          {
            "label": "华新镇",
            "value": "310118107"
          },
          {
            "label": "重固镇",
            "value": "310118109"
          },
          {
            "label": "白鹤镇",
            "value": "310118110"
          }
        ]
      },
      {
        "label": "奉贤区",
        "value": "310120",
        "children": [
          {
            "label": "西渡街道",
            "value": "310120001"
          },
          {
            "label": "奉浦街道",
            "value": "310120002"
          },
          {
            "label": "南桥镇",
            "value": "310120101"
          },
          {
            "label": "奉城镇",
            "value": "310120102"
          },
          {
            "label": "庄行镇",
            "value": "310120104"
          },
          {
            "label": "金汇镇",
            "value": "310120106"
          },
          {
            "label": "四团镇",
            "value": "310120109"
          },
          {
            "label": "青村镇",
            "value": "310120111"
          },
          {
            "label": "柘林镇",
            "value": "310120118"
          },
          {
            "label": "海湾镇",
            "value": "310120123"
          },
          {
            "label": "上海市奉贤区海湾旅游区",
            "value": "310120503"
          },
          {
            "label": "金海社区",
            "value": "310120504"
          }
        ]
      },
      {
        "label": "崇明区",
        "value": "310151",
        "children": [
          {
            "label": "城桥镇",
            "value": "310151101"
          },
          {
            "label": "堡镇",
            "value": "310151102"
          },
          {
            "label": "新河镇",
            "value": "310151103"
          },
          {
            "label": "庙镇",
            "value": "310151104"
          },
          {
            "label": "竖新镇",
            "value": "310151105"
          },
          {
            "label": "向化镇",
            "value": "310151106"
          },
          {
            "label": "三星镇",
            "value": "310151107"
          },
          {
            "label": "港沿镇",
            "value": "310151108"
          },
          {
            "label": "中兴镇",
            "value": "310151109"
          },
          {
            "label": "陈家镇",
            "value": "310151110"
          },
          {
            "label": "绿华镇",
            "value": "310151111"
          },
          {
            "label": "港西镇",
            "value": "310151112"
          },
          {
            "label": "建设镇",
            "value": "310151113"
          },
          {
            "label": "新海镇",
            "value": "310151114"
          },
          {
            "label": "东平镇",
            "value": "310151115"
          },
          {
            "label": "长兴镇",
            "value": "310151116"
          },
          {
            "label": "新村乡",
            "value": "310151201"
          },
          {
            "label": "横沙乡",
            "value": "310151202"
          },
          {
            "label": "前卫农场",
            "value": "310151401"
          },
          {
            "label": "东平林场",
            "value": "310151402"
          },
          {
            "label": "上实现代农业园区",
            "value": "310151501"
          }
        ]
      }
    ]
  },
  {
    "label": "江苏省",
    "value": "32",
    "children": [
      {
        "label": "南京市",
        "value": "3201",
        "children": [
          {
            "label": "玄武区",
            "value": "320102"
          },
          {
            "label": "秦淮区",
            "value": "320104"
          },
          {
            "label": "建邺区",
            "value": "320105"
          },
          {
            "label": "鼓楼区",
            "value": "320106"
          },
          {
            "label": "浦口区",
            "value": "320111"
          },
          {
            "label": "栖霞区",
            "value": "320113"
          },
          {
            "label": "雨花台区",
            "value": "320114"
          },
          {
            "label": "江宁区",
            "value": "320115"
          },
          {
            "label": "六合区",
            "value": "320116"
          },
          {
            "label": "溧水区",
            "value": "320117"
          },
          {
            "label": "高淳区",
            "value": "320118"
          }
        ]
      },
      {
        "label": "无锡市",
        "value": "3202",
        "children": [
          {
            "label": "锡山区",
            "value": "320205"
          },
          {
            "label": "惠山区",
            "value": "320206"
          },
          {
            "label": "滨湖区",
            "value": "320211"
          },
          {
            "label": "梁溪区",
            "value": "320213"
          },
          {
            "label": "新吴区",
            "value": "320214"
          },
          {
            "label": "江阴市",
            "value": "320281"
          },
          {
            "label": "宜兴市",
            "value": "320282"
          }
        ]
      },
      {
        "label": "徐州市",
        "value": "3203",
        "children": [
          {
            "label": "鼓楼区",
            "value": "320302"
          },
          {
            "label": "云龙区",
            "value": "320303"
          },
          {
            "label": "贾汪区",
            "value": "320305"
          },
          {
            "label": "泉山区",
            "value": "320311"
          },
          {
            "label": "铜山区",
            "value": "320312"
          },
          {
            "label": "丰县",
            "value": "320321"
          },
          {
            "label": "沛县",
            "value": "320322"
          },
          {
            "label": "睢宁县",
            "value": "320324"
          },
          {
            "label": "徐州经济技术开发区",
            "value": "320371"
          },
          {
            "label": "新沂市",
            "value": "320381"
          },
          {
            "label": "邳州市",
            "value": "320382"
          }
        ]
      },
      {
        "label": "常州市",
        "value": "3204",
        "children": [
          {
            "label": "天宁区",
            "value": "320402"
          },
          {
            "label": "钟楼区",
            "value": "320404"
          },
          {
            "label": "新北区",
            "value": "320411"
          },
          {
            "label": "武进区",
            "value": "320412"
          },
          {
            "label": "金坛区",
            "value": "320413"
          },
          {
            "label": "溧阳市",
            "value": "320481"
          }
        ]
      },
      {
        "label": "苏州市",
        "value": "3205",
        "children": [
          {
            "label": "虎丘区",
            "value": "320505"
          },
          {
            "label": "吴中区",
            "value": "320506"
          },
          {
            "label": "相城区",
            "value": "320507"
          },
          {
            "label": "姑苏区",
            "value": "320508"
          },
          {
            "label": "吴江区",
            "value": "320509"
          },
          {
            "label": "苏州工业园区",
            "value": "320571"
          },
          {
            "label": "常熟市",
            "value": "320581"
          },
          {
            "label": "张家港市",
            "value": "320582"
          },
          {
            "label": "昆山市",
            "value": "320583"
          },
          {
            "label": "太仓市",
            "value": "320585"
          }
        ]
      },
      {
        "label": "南通市",
        "value": "3206",
        "children": [
          {
            "label": "崇川区",
            "value": "320602"
          },
          {
            "label": "港闸区",
            "value": "320611"
          },
          {
            "label": "通州区",
            "value": "320612"
          },
          {
            "label": "如东县",
            "value": "320623"
          },
          {
            "label": "南通经济技术开发区",
            "value": "320671"
          },
          {
            "label": "启东市",
            "value": "320681"
          },
          {
            "label": "如皋市",
            "value": "320682"
          },
          {
            "label": "海门市",
            "value": "320684"
          },
          {
            "label": "海安市",
            "value": "320685"
          }
        ]
      },
      {
        "label": "连云港市",
        "value": "3207",
        "children": [
          {
            "label": "连云区",
            "value": "320703"
          },
          {
            "label": "海州区",
            "value": "320706"
          },
          {
            "label": "赣榆区",
            "value": "320707"
          },
          {
            "label": "东海县",
            "value": "320722"
          },
          {
            "label": "灌云县",
            "value": "320723"
          },
          {
            "label": "灌南县",
            "value": "320724"
          },
          {
            "label": "连云港经济技术开发区",
            "value": "320771"
          },
          {
            "label": "连云港高新技术产业开发区",
            "value": "320772"
          }
        ]
      },
      {
        "label": "淮安市",
        "value": "3208",
        "children": [
          {
            "label": "淮安区",
            "value": "320803"
          },
          {
            "label": "淮阴区",
            "value": "320804"
          },
          {
            "label": "清江浦区",
            "value": "320812"
          },
          {
            "label": "洪泽区",
            "value": "320813"
          },
          {
            "label": "涟水县",
            "value": "320826"
          },
          {
            "label": "盱眙县",
            "value": "320830"
          },
          {
            "label": "金湖县",
            "value": "320831"
          },
          {
            "label": "淮安经济技术开发区",
            "value": "320871"
          }
        ]
      },
      {
        "label": "盐城市",
        "value": "3209",
        "children": [
          {
            "label": "亭湖区",
            "value": "320902"
          },
          {
            "label": "盐都区",
            "value": "320903"
          },
          {
            "label": "大丰区",
            "value": "320904"
          },
          {
            "label": "响水县",
            "value": "320921"
          },
          {
            "label": "滨海县",
            "value": "320922"
          },
          {
            "label": "阜宁县",
            "value": "320923"
          },
          {
            "label": "射阳县",
            "value": "320924"
          },
          {
            "label": "建湖县",
            "value": "320925"
          },
          {
            "label": "盐城经济技术开发区",
            "value": "320971"
          },
          {
            "label": "东台市",
            "value": "320981"
          }
        ]
      },
      {
        "label": "扬州市",
        "value": "3210",
        "children": [
          {
            "label": "广陵区",
            "value": "321002"
          },
          {
            "label": "邗江区",
            "value": "321003"
          },
          {
            "label": "江都区",
            "value": "321012"
          },
          {
            "label": "宝应县",
            "value": "321023"
          },
          {
            "label": "扬州经济技术开发区",
            "value": "321071"
          },
          {
            "label": "仪征市",
            "value": "321081"
          },
          {
            "label": "高邮市",
            "value": "321084"
          }
        ]
      },
      {
        "label": "镇江市",
        "value": "3211",
        "children": [
          {
            "label": "京口区",
            "value": "321102"
          },
          {
            "label": "润州区",
            "value": "321111"
          },
          {
            "label": "丹徒区",
            "value": "321112"
          },
          {
            "label": "镇江新区",
            "value": "321171"
          },
          {
            "label": "丹阳市",
            "value": "321181"
          },
          {
            "label": "扬中市",
            "value": "321182"
          },
          {
            "label": "句容市",
            "value": "321183"
          }
        ]
      },
      {
        "label": "泰州市",
        "value": "3212",
        "children": [
          {
            "label": "海陵区",
            "value": "321202"
          },
          {
            "label": "高港区",
            "value": "321203"
          },
          {
            "label": "姜堰区",
            "value": "321204"
          },
          {
            "label": "泰州医药高新技术产业开发区",
            "value": "321271"
          },
          {
            "label": "兴化市",
            "value": "321281"
          },
          {
            "label": "靖江市",
            "value": "321282"
          },
          {
            "label": "泰兴市",
            "value": "321283"
          }
        ]
      },
      {
        "label": "宿迁市",
        "value": "3213",
        "children": [
          {
            "label": "宿城区",
            "value": "321302"
          },
          {
            "label": "宿豫区",
            "value": "321311"
          },
          {
            "label": "沭阳县",
            "value": "321322"
          },
          {
            "label": "泗阳县",
            "value": "321323"
          },
          {
            "label": "泗洪县",
            "value": "321324"
          },
          {
            "label": "宿迁经济技术开发区",
            "value": "321371"
          }
        ]
      }
    ]
  },
  {
    "label": "浙江省",
    "value": "33",
    "children": [
      {
        "label": "杭州市",
        "value": "3301",
        "children": [
          {
            "label": "上城区",
            "value": "330102"
          },
          {
            "label": "下城区",
            "value": "330103"
          },
          {
            "label": "江干区",
            "value": "330104"
          },
          {
            "label": "拱墅区",
            "value": "330105"
          },
          {
            "label": "西湖区",
            "value": "330106"
          },
          {
            "label": "滨江区",
            "value": "330108"
          },
          {
            "label": "萧山区",
            "value": "330109"
          },
          {
            "label": "余杭区",
            "value": "330110"
          },
          {
            "label": "富阳区",
            "value": "330111"
          },
          {
            "label": "临安区",
            "value": "330112"
          },
          {
            "label": "桐庐县",
            "value": "330122"
          },
          {
            "label": "淳安县",
            "value": "330127"
          },
          {
            "label": "建德市",
            "value": "330182"
          }
        ]
      },
      {
        "label": "宁波市",
        "value": "3302",
        "children": [
          {
            "label": "海曙区",
            "value": "330203"
          },
          {
            "label": "江北区",
            "value": "330205"
          },
          {
            "label": "北仑区",
            "value": "330206"
          },
          {
            "label": "镇海区",
            "value": "330211"
          },
          {
            "label": "鄞州区",
            "value": "330212"
          },
          {
            "label": "奉化区",
            "value": "330213"
          },
          {
            "label": "象山县",
            "value": "330225"
          },
          {
            "label": "宁海县",
            "value": "330226"
          },
          {
            "label": "余姚市",
            "value": "330281"
          },
          {
            "label": "慈溪市",
            "value": "330282"
          }
        ]
      },
      {
        "label": "温州市",
        "value": "3303",
        "children": [
          {
            "label": "鹿城区",
            "value": "330302"
          },
          {
            "label": "龙湾区",
            "value": "330303"
          },
          {
            "label": "瓯海区",
            "value": "330304"
          },
          {
            "label": "洞头区",
            "value": "330305"
          },
          {
            "label": "永嘉县",
            "value": "330324"
          },
          {
            "label": "平阳县",
            "value": "330326"
          },
          {
            "label": "苍南县",
            "value": "330327"
          },
          {
            "label": "文成县",
            "value": "330328"
          },
          {
            "label": "泰顺县",
            "value": "330329"
          },
          {
            "label": "温州经济技术开发区",
            "value": "330371"
          },
          {
            "label": "瑞安市",
            "value": "330381"
          },
          {
            "label": "乐清市",
            "value": "330382"
          },
          {
            "label": "龙港市",
            "value": "330383"
          }
        ]
      },
      {
        "label": "嘉兴市",
        "value": "3304",
        "children": [
          {
            "label": "南湖区",
            "value": "330402"
          },
          {
            "label": "秀洲区",
            "value": "330411"
          },
          {
            "label": "嘉善县",
            "value": "330421"
          },
          {
            "label": "海盐县",
            "value": "330424"
          },
          {
            "label": "海宁市",
            "value": "330481"
          },
          {
            "label": "平湖市",
            "value": "330482"
          },
          {
            "label": "桐乡市",
            "value": "330483"
          }
        ]
      },
      {
        "label": "湖州市",
        "value": "3305",
        "children": [
          {
            "label": "吴兴区",
            "value": "330502"
          },
          {
            "label": "南浔区",
            "value": "330503"
          },
          {
            "label": "德清县",
            "value": "330521"
          },
          {
            "label": "长兴县",
            "value": "330522"
          },
          {
            "label": "安吉县",
            "value": "330523"
          }
        ]
      },
      {
        "label": "绍兴市",
        "value": "3306",
        "children": [
          {
            "label": "越城区",
            "value": "330602"
          },
          {
            "label": "柯桥区",
            "value": "330603"
          },
          {
            "label": "上虞区",
            "value": "330604"
          },
          {
            "label": "新昌县",
            "value": "330624"
          },
          {
            "label": "诸暨市",
            "value": "330681"
          },
          {
            "label": "嵊州市",
            "value": "330683"
          }
        ]
      },
      {
        "label": "金华市",
        "value": "3307",
        "children": [
          {
            "label": "婺城区",
            "value": "330702"
          },
          {
            "label": "金东区",
            "value": "330703"
          },
          {
            "label": "武义县",
            "value": "330723"
          },
          {
            "label": "浦江县",
            "value": "330726"
          },
          {
            "label": "磐安县",
            "value": "330727"
          },
          {
            "label": "兰溪市",
            "value": "330781"
          },
          {
            "label": "义乌市",
            "value": "330782"
          },
          {
            "label": "东阳市",
            "value": "330783"
          },
          {
            "label": "永康市",
            "value": "330784"
          }
        ]
      },
      {
        "label": "衢州市",
        "value": "3308",
        "children": [
          {
            "label": "柯城区",
            "value": "330802"
          },
          {
            "label": "衢江区",
            "value": "330803"
          },
          {
            "label": "常山县",
            "value": "330822"
          },
          {
            "label": "开化县",
            "value": "330824"
          },
          {
            "label": "龙游县",
            "value": "330825"
          },
          {
            "label": "江山市",
            "value": "330881"
          }
        ]
      },
      {
        "label": "舟山市",
        "value": "3309",
        "children": [
          {
            "label": "定海区",
            "value": "330902"
          },
          {
            "label": "普陀区",
            "value": "330903"
          },
          {
            "label": "岱山县",
            "value": "330921"
          },
          {
            "label": "嵊泗县",
            "value": "330922"
          }
        ]
      },
      {
        "label": "台州市",
        "value": "3310",
        "children": [
          {
            "label": "椒江区",
            "value": "331002"
          },
          {
            "label": "黄岩区",
            "value": "331003"
          },
          {
            "label": "路桥区",
            "value": "331004"
          },
          {
            "label": "三门县",
            "value": "331022"
          },
          {
            "label": "天台县",
            "value": "331023"
          },
          {
            "label": "仙居县",
            "value": "331024"
          },
          {
            "label": "温岭市",
            "value": "331081"
          },
          {
            "label": "临海市",
            "value": "331082"
          },
          {
            "label": "玉环市",
            "value": "331083"
          }
        ]
      },
      {
        "label": "丽水市",
        "value": "3311",
        "children": [
          {
            "label": "莲都区",
            "value": "331102"
          },
          {
            "label": "青田县",
            "value": "331121"
          },
          {
            "label": "缙云县",
            "value": "331122"
          },
          {
            "label": "遂昌县",
            "value": "331123"
          },
          {
            "label": "松阳县",
            "value": "331124"
          },
          {
            "label": "云和县",
            "value": "331125"
          },
          {
            "label": "庆元县",
            "value": "331126"
          },
          {
            "label": "景宁畲族自治县",
            "value": "331127"
          },
          {
            "label": "龙泉市",
            "value": "331181"
          }
        ]
      }
    ]
  },
  {
    "label": "安徽省",
    "value": "34",
    "children": [
      {
        "label": "合肥市",
        "value": "3401",
        "children": [
          {
            "label": "瑶海区",
            "value": "340102"
          },
          {
            "label": "庐阳区",
            "value": "340103"
          },
          {
            "label": "蜀山区",
            "value": "340104"
          },
          {
            "label": "包河区",
            "value": "340111"
          },
          {
            "label": "长丰县",
            "value": "340121"
          },
          {
            "label": "肥东县",
            "value": "340122"
          },
          {
            "label": "肥西县",
            "value": "340123"
          },
          {
            "label": "庐江县",
            "value": "340124"
          },
          {
            "label": "合肥高新技术产业开发区",
            "value": "340171"
          },
          {
            "label": "合肥经济技术开发区",
            "value": "340172"
          },
          {
            "label": "合肥新站高新技术产业开发区",
            "value": "340173"
          },
          {
            "label": "巢湖市",
            "value": "340181"
          }
        ]
      },
      {
        "label": "芜湖市",
        "value": "3402",
        "children": [
          {
            "label": "镜湖区",
            "value": "340202"
          },
          {
            "label": "弋江区",
            "value": "340203"
          },
          {
            "label": "鸠江区",
            "value": "340207"
          },
          {
            "label": "三山区",
            "value": "340208"
          },
          {
            "label": "芜湖县",
            "value": "340221"
          },
          {
            "label": "繁昌县",
            "value": "340222"
          },
          {
            "label": "南陵县",
            "value": "340223"
          },
          {
            "label": "无为县",
            "value": "340225"
          },
          {
            "label": "芜湖经济技术开发区",
            "value": "340271"
          },
          {
            "label": "安徽芜湖长江大桥经济开发区",
            "value": "340272"
          }
        ]
      },
      {
        "label": "蚌埠市",
        "value": "3403",
        "children": [
          {
            "label": "龙子湖区",
            "value": "340302"
          },
          {
            "label": "蚌山区",
            "value": "340303"
          },
          {
            "label": "禹会区",
            "value": "340304"
          },
          {
            "label": "淮上区",
            "value": "340311"
          },
          {
            "label": "怀远县",
            "value": "340321"
          },
          {
            "label": "五河县",
            "value": "340322"
          },
          {
            "label": "固镇县",
            "value": "340323"
          },
          {
            "label": "蚌埠市高新技术开发区",
            "value": "340371"
          },
          {
            "label": "蚌埠市经济开发区",
            "value": "340372"
          }
        ]
      },
      {
        "label": "淮南市",
        "value": "3404",
        "children": [
          {
            "label": "大通区",
            "value": "340402"
          },
          {
            "label": "田家庵区",
            "value": "340403"
          },
          {
            "label": "谢家集区",
            "value": "340404"
          },
          {
            "label": "八公山区",
            "value": "340405"
          },
          {
            "label": "潘集区",
            "value": "340406"
          },
          {
            "label": "凤台县",
            "value": "340421"
          },
          {
            "label": "寿县",
            "value": "340422"
          }
        ]
      },
      {
        "label": "马鞍山市",
        "value": "3405",
        "children": [
          {
            "label": "花山区",
            "value": "340503"
          },
          {
            "label": "雨山区",
            "value": "340504"
          },
          {
            "label": "博望区",
            "value": "340506"
          },
          {
            "label": "当涂县",
            "value": "340521"
          },
          {
            "label": "含山县",
            "value": "340522"
          },
          {
            "label": "和县",
            "value": "340523"
          }
        ]
      },
      {
        "label": "淮北市",
        "value": "3406",
        "children": [
          {
            "label": "杜集区",
            "value": "340602"
          },
          {
            "label": "相山区",
            "value": "340603"
          },
          {
            "label": "烈山区",
            "value": "340604"
          },
          {
            "label": "濉溪县",
            "value": "340621"
          }
        ]
      },
      {
        "label": "铜陵市",
        "value": "3407",
        "children": [
          {
            "label": "铜官区",
            "value": "340705"
          },
          {
            "label": "义安区",
            "value": "340706"
          },
          {
            "label": "郊区",
            "value": "340711"
          },
          {
            "label": "枞阳县",
            "value": "340722"
          }
        ]
      },
      {
        "label": "安庆市",
        "value": "3408",
        "children": [
          {
            "label": "迎江区",
            "value": "340802"
          },
          {
            "label": "大观区",
            "value": "340803"
          },
          {
            "label": "宜秀区",
            "value": "340811"
          },
          {
            "label": "怀宁县",
            "value": "340822"
          },
          {
            "label": "太湖县",
            "value": "340825"
          },
          {
            "label": "宿松县",
            "value": "340826"
          },
          {
            "label": "望江县",
            "value": "340827"
          },
          {
            "label": "岳西县",
            "value": "340828"
          },
          {
            "label": "安徽安庆经济开发区",
            "value": "340871"
          },
          {
            "label": "桐城市",
            "value": "340881"
          },
          {
            "label": "潜山市",
            "value": "340882"
          }
        ]
      },
      {
        "label": "黄山市",
        "value": "3410",
        "children": [
          {
            "label": "屯溪区",
            "value": "341002"
          },
          {
            "label": "黄山区",
            "value": "341003"
          },
          {
            "label": "徽州区",
            "value": "341004"
          },
          {
            "label": "歙县",
            "value": "341021"
          },
          {
            "label": "休宁县",
            "value": "341022"
          },
          {
            "label": "黟县",
            "value": "341023"
          },
          {
            "label": "祁门县",
            "value": "341024"
          }
        ]
      },
      {
        "label": "滁州市",
        "value": "3411",
        "children": [
          {
            "label": "琅琊区",
            "value": "341102"
          },
          {
            "label": "南谯区",
            "value": "341103"
          },
          {
            "label": "来安县",
            "value": "341122"
          },
          {
            "label": "全椒县",
            "value": "341124"
          },
          {
            "label": "定远县",
            "value": "341125"
          },
          {
            "label": "凤阳县",
            "value": "341126"
          },
          {
            "label": "苏滁现代产业园",
            "value": "341171"
          },
          {
            "label": "滁州经济技术开发区",
            "value": "341172"
          },
          {
            "label": "天长市",
            "value": "341181"
          },
          {
            "label": "明光市",
            "value": "341182"
          }
        ]
      },
      {
        "label": "阜阳市",
        "value": "3412",
        "children": [
          {
            "label": "颍州区",
            "value": "341202"
          },
          {
            "label": "颍东区",
            "value": "341203"
          },
          {
            "label": "颍泉区",
            "value": "341204"
          },
          {
            "label": "临泉县",
            "value": "341221"
          },
          {
            "label": "太和县",
            "value": "341222"
          },
          {
            "label": "阜南县",
            "value": "341225"
          },
          {
            "label": "颍上县",
            "value": "341226"
          },
          {
            "label": "阜阳合肥现代产业园区",
            "value": "341271"
          },
          {
            "label": "阜阳经济技术开发区",
            "value": "341272"
          },
          {
            "label": "界首市",
            "value": "341282"
          }
        ]
      },
      {
        "label": "宿州市",
        "value": "3413",
        "children": [
          {
            "label": "埇桥区",
            "value": "341302"
          },
          {
            "label": "砀山县",
            "value": "341321"
          },
          {
            "label": "萧县",
            "value": "341322"
          },
          {
            "label": "灵璧县",
            "value": "341323"
          },
          {
            "label": "泗县",
            "value": "341324"
          },
          {
            "label": "宿州马鞍山现代产业园区",
            "value": "341371"
          },
          {
            "label": "宿州经济技术开发区",
            "value": "341372"
          }
        ]
      },
      {
        "label": "六安市",
        "value": "3415",
        "children": [
          {
            "label": "金安区",
            "value": "341502"
          },
          {
            "label": "裕安区",
            "value": "341503"
          },
          {
            "label": "叶集区",
            "value": "341504"
          },
          {
            "label": "霍邱县",
            "value": "341522"
          },
          {
            "label": "舒城县",
            "value": "341523"
          },
          {
            "label": "金寨县",
            "value": "341524"
          },
          {
            "label": "霍山县",
            "value": "341525"
          }
        ]
      },
      {
        "label": "亳州市",
        "value": "3416",
        "children": [
          {
            "label": "谯城区",
            "value": "341602"
          },
          {
            "label": "涡阳县",
            "value": "341621"
          },
          {
            "label": "蒙城县",
            "value": "341622"
          },
          {
            "label": "利辛县",
            "value": "341623"
          }
        ]
      },
      {
        "label": "池州市",
        "value": "3417",
        "children": [
          {
            "label": "贵池区",
            "value": "341702"
          },
          {
            "label": "东至县",
            "value": "341721"
          },
          {
            "label": "石台县",
            "value": "341722"
          },
          {
            "label": "青阳县",
            "value": "341723"
          }
        ]
      },
      {
        "label": "宣城市",
        "value": "3418",
        "children": [
          {
            "label": "宣州区",
            "value": "341802"
          },
          {
            "label": "郎溪县",
            "value": "341821"
          },
          {
            "label": "泾县",
            "value": "341823"
          },
          {
            "label": "绩溪县",
            "value": "341824"
          },
          {
            "label": "旌德县",
            "value": "341825"
          },
          {
            "label": "宣城市经济开发区",
            "value": "341871"
          },
          {
            "label": "宁国市",
            "value": "341881"
          },
          {
            "label": "广德市",
            "value": "341882"
          }
        ]
      }
    ]
  },
  {
    "label": "福建省",
    "value": "35",
    "children": [
      {
        "label": "福州市",
        "value": "3501",
        "children": [
          {
            "label": "鼓楼区",
            "value": "350102"
          },
          {
            "label": "台江区",
            "value": "350103"
          },
          {
            "label": "仓山区",
            "value": "350104"
          },
          {
            "label": "马尾区",
            "value": "350105"
          },
          {
            "label": "晋安区",
            "value": "350111"
          },
          {
            "label": "长乐区",
            "value": "350112"
          },
          {
            "label": "闽侯县",
            "value": "350121"
          },
          {
            "label": "连江县",
            "value": "350122"
          },
          {
            "label": "罗源县",
            "value": "350123"
          },
          {
            "label": "闽清县",
            "value": "350124"
          },
          {
            "label": "永泰县",
            "value": "350125"
          },
          {
            "label": "平潭县",
            "value": "350128"
          },
          {
            "label": "福清市",
            "value": "350181"
          }
        ]
      },
      {
        "label": "厦门市",
        "value": "3502",
        "children": [
          {
            "label": "思明区",
            "value": "350203"
          },
          {
            "label": "海沧区",
            "value": "350205"
          },
          {
            "label": "湖里区",
            "value": "350206"
          },
          {
            "label": "集美区",
            "value": "350211"
          },
          {
            "label": "同安区",
            "value": "350212"
          },
          {
            "label": "翔安区",
            "value": "350213"
          }
        ]
      },
      {
        "label": "莆田市",
        "value": "3503",
        "children": [
          {
            "label": "城厢区",
            "value": "350302"
          },
          {
            "label": "涵江区",
            "value": "350303"
          },
          {
            "label": "荔城区",
            "value": "350304"
          },
          {
            "label": "秀屿区",
            "value": "350305"
          },
          {
            "label": "仙游县",
            "value": "350322"
          }
        ]
      },
      {
        "label": "三明市",
        "value": "3504",
        "children": [
          {
            "label": "梅列区",
            "value": "350402"
          },
          {
            "label": "三元区",
            "value": "350403"
          },
          {
            "label": "明溪县",
            "value": "350421"
          },
          {
            "label": "清流县",
            "value": "350423"
          },
          {
            "label": "宁化县",
            "value": "350424"
          },
          {
            "label": "大田县",
            "value": "350425"
          },
          {
            "label": "尤溪县",
            "value": "350426"
          },
          {
            "label": "沙县",
            "value": "350427"
          },
          {
            "label": "将乐县",
            "value": "350428"
          },
          {
            "label": "泰宁县",
            "value": "350429"
          },
          {
            "label": "建宁县",
            "value": "350430"
          },
          {
            "label": "永安市",
            "value": "350481"
          }
        ]
      },
      {
        "label": "泉州市",
        "value": "3505",
        "children": [
          {
            "label": "鲤城区",
            "value": "350502"
          },
          {
            "label": "丰泽区",
            "value": "350503"
          },
          {
            "label": "洛江区",
            "value": "350504"
          },
          {
            "label": "泉港区",
            "value": "350505"
          },
          {
            "label": "惠安县",
            "value": "350521"
          },
          {
            "label": "安溪县",
            "value": "350524"
          },
          {
            "label": "永春县",
            "value": "350525"
          },
          {
            "label": "德化县",
            "value": "350526"
          },
          {
            "label": "金门县",
            "value": "350527"
          },
          {
            "label": "石狮市",
            "value": "350581"
          },
          {
            "label": "晋江市",
            "value": "350582"
          },
          {
            "label": "南安市",
            "value": "350583"
          }
        ]
      },
      {
        "label": "漳州市",
        "value": "3506",
        "children": [
          {
            "label": "芗城区",
            "value": "350602"
          },
          {
            "label": "龙文区",
            "value": "350603"
          },
          {
            "label": "云霄县",
            "value": "350622"
          },
          {
            "label": "漳浦县",
            "value": "350623"
          },
          {
            "label": "诏安县",
            "value": "350624"
          },
          {
            "label": "长泰县",
            "value": "350625"
          },
          {
            "label": "东山县",
            "value": "350626"
          },
          {
            "label": "南靖县",
            "value": "350627"
          },
          {
            "label": "平和县",
            "value": "350628"
          },
          {
            "label": "华安县",
            "value": "350629"
          },
          {
            "label": "龙海市",
            "value": "350681"
          }
        ]
      },
      {
        "label": "南平市",
        "value": "3507",
        "children": [
          {
            "label": "延平区",
            "value": "350702"
          },
          {
            "label": "建阳区",
            "value": "350703"
          },
          {
            "label": "顺昌县",
            "value": "350721"
          },
          {
            "label": "浦城县",
            "value": "350722"
          },
          {
            "label": "光泽县",
            "value": "350723"
          },
          {
            "label": "松溪县",
            "value": "350724"
          },
          {
            "label": "政和县",
            "value": "350725"
          },
          {
            "label": "邵武市",
            "value": "350781"
          },
          {
            "label": "武夷山市",
            "value": "350782"
          },
          {
            "label": "建瓯市",
            "value": "350783"
          }
        ]
      },
      {
        "label": "龙岩市",
        "value": "3508",
        "children": [
          {
            "label": "新罗区",
            "value": "350802"
          },
          {
            "label": "永定区",
            "value": "350803"
          },
          {
            "label": "长汀县",
            "value": "350821"
          },
          {
            "label": "上杭县",
            "value": "350823"
          },
          {
            "label": "武平县",
            "value": "350824"
          },
          {
            "label": "连城县",
            "value": "350825"
          },
          {
            "label": "漳平市",
            "value": "350881"
          }
        ]
      },
      {
        "label": "宁德市",
        "value": "3509",
        "children": [
          {
            "label": "蕉城区",
            "value": "350902"
          },
          {
            "label": "霞浦县",
            "value": "350921"
          },
          {
            "label": "古田县",
            "value": "350922"
          },
          {
            "label": "屏南县",
            "value": "350923"
          },
          {
            "label": "寿宁县",
            "value": "350924"
          },
          {
            "label": "周宁县",
            "value": "350925"
          },
          {
            "label": "柘荣县",
            "value": "350926"
          },
          {
            "label": "福安市",
            "value": "350981"
          },
          {
            "label": "福鼎市",
            "value": "350982"
          }
        ]
      }
    ]
  },
  {
    "label": "江西省",
    "value": "36",
    "children": [
      {
        "label": "南昌市",
        "value": "3601",
        "children": [
          {
            "label": "东湖区",
            "value": "360102"
          },
          {
            "label": "西湖区",
            "value": "360103"
          },
          {
            "label": "青云谱区",
            "value": "360104"
          },
          {
            "label": "湾里区",
            "value": "360105"
          },
          {
            "label": "青山湖区",
            "value": "360111"
          },
          {
            "label": "新建区",
            "value": "360112"
          },
          {
            "label": "南昌县",
            "value": "360121"
          },
          {
            "label": "安义县",
            "value": "360123"
          },
          {
            "label": "进贤县",
            "value": "360124"
          }
        ]
      },
      {
        "label": "景德镇市",
        "value": "3602",
        "children": [
          {
            "label": "昌江区",
            "value": "360202"
          },
          {
            "label": "珠山区",
            "value": "360203"
          },
          {
            "label": "浮梁县",
            "value": "360222"
          },
          {
            "label": "乐平市",
            "value": "360281"
          }
        ]
      },
      {
        "label": "萍乡市",
        "value": "3603",
        "children": [
          {
            "label": "安源区",
            "value": "360302"
          },
          {
            "label": "湘东区",
            "value": "360313"
          },
          {
            "label": "莲花县",
            "value": "360321"
          },
          {
            "label": "上栗县",
            "value": "360322"
          },
          {
            "label": "芦溪县",
            "value": "360323"
          }
        ]
      },
      {
        "label": "九江市",
        "value": "3604",
        "children": [
          {
            "label": "濂溪区",
            "value": "360402"
          },
          {
            "label": "浔阳区",
            "value": "360403"
          },
          {
            "label": "柴桑区",
            "value": "360404"
          },
          {
            "label": "武宁县",
            "value": "360423"
          },
          {
            "label": "修水县",
            "value": "360424"
          },
          {
            "label": "永修县",
            "value": "360425"
          },
          {
            "label": "德安县",
            "value": "360426"
          },
          {
            "label": "都昌县",
            "value": "360428"
          },
          {
            "label": "湖口县",
            "value": "360429"
          },
          {
            "label": "彭泽县",
            "value": "360430"
          },
          {
            "label": "瑞昌市",
            "value": "360481"
          },
          {
            "label": "共青城市",
            "value": "360482"
          },
          {
            "label": "庐山市",
            "value": "360483"
          }
        ]
      },
      {
        "label": "新余市",
        "value": "3605",
        "children": [
          {
            "label": "渝水区",
            "value": "360502"
          },
          {
            "label": "分宜县",
            "value": "360521"
          }
        ]
      },
      {
        "label": "鹰潭市",
        "value": "3606",
        "children": [
          {
            "label": "月湖区",
            "value": "360602"
          },
          {
            "label": "余江区",
            "value": "360603"
          },
          {
            "label": "贵溪市",
            "value": "360681"
          }
        ]
      },
      {
        "label": "赣州市",
        "value": "3607",
        "children": [
          {
            "label": "章贡区",
            "value": "360702"
          },
          {
            "label": "南康区",
            "value": "360703"
          },
          {
            "label": "赣县区",
            "value": "360704"
          },
          {
            "label": "信丰县",
            "value": "360722"
          },
          {
            "label": "大余县",
            "value": "360723"
          },
          {
            "label": "上犹县",
            "value": "360724"
          },
          {
            "label": "崇义县",
            "value": "360725"
          },
          {
            "label": "安远县",
            "value": "360726"
          },
          {
            "label": "龙南县",
            "value": "360727"
          },
          {
            "label": "定南县",
            "value": "360728"
          },
          {
            "label": "全南县",
            "value": "360729"
          },
          {
            "label": "宁都县",
            "value": "360730"
          },
          {
            "label": "于都县",
            "value": "360731"
          },
          {
            "label": "兴国县",
            "value": "360732"
          },
          {
            "label": "会昌县",
            "value": "360733"
          },
          {
            "label": "寻乌县",
            "value": "360734"
          },
          {
            "label": "石城县",
            "value": "360735"
          },
          {
            "label": "瑞金市",
            "value": "360781"
          }
        ]
      },
      {
        "label": "吉安市",
        "value": "3608",
        "children": [
          {
            "label": "吉州区",
            "value": "360802"
          },
          {
            "label": "青原区",
            "value": "360803"
          },
          {
            "label": "吉安县",
            "value": "360821"
          },
          {
            "label": "吉水县",
            "value": "360822"
          },
          {
            "label": "峡江县",
            "value": "360823"
          },
          {
            "label": "新干县",
            "value": "360824"
          },
          {
            "label": "永丰县",
            "value": "360825"
          },
          {
            "label": "泰和县",
            "value": "360826"
          },
          {
            "label": "遂川县",
            "value": "360827"
          },
          {
            "label": "万安县",
            "value": "360828"
          },
          {
            "label": "安福县",
            "value": "360829"
          },
          {
            "label": "永新县",
            "value": "360830"
          },
          {
            "label": "井冈山市",
            "value": "360881"
          }
        ]
      },
      {
        "label": "宜春市",
        "value": "3609",
        "children": [
          {
            "label": "袁州区",
            "value": "360902"
          },
          {
            "label": "奉新县",
            "value": "360921"
          },
          {
            "label": "万载县",
            "value": "360922"
          },
          {
            "label": "上高县",
            "value": "360923"
          },
          {
            "label": "宜丰县",
            "value": "360924"
          },
          {
            "label": "靖安县",
            "value": "360925"
          },
          {
            "label": "铜鼓县",
            "value": "360926"
          },
          {
            "label": "丰城市",
            "value": "360981"
          },
          {
            "label": "樟树市",
            "value": "360982"
          },
          {
            "label": "高安市",
            "value": "360983"
          }
        ]
      },
      {
        "label": "抚州市",
        "value": "3610",
        "children": [
          {
            "label": "临川区",
            "value": "361002"
          },
          {
            "label": "东乡区",
            "value": "361003"
          },
          {
            "label": "南城县",
            "value": "361021"
          },
          {
            "label": "黎川县",
            "value": "361022"
          },
          {
            "label": "南丰县",
            "value": "361023"
          },
          {
            "label": "崇仁县",
            "value": "361024"
          },
          {
            "label": "乐安县",
            "value": "361025"
          },
          {
            "label": "宜黄县",
            "value": "361026"
          },
          {
            "label": "金溪县",
            "value": "361027"
          },
          {
            "label": "资溪县",
            "value": "361028"
          },
          {
            "label": "广昌县",
            "value": "361030"
          }
        ]
      },
      {
        "label": "上饶市",
        "value": "3611",
        "children": [
          {
            "label": "信州区",
            "value": "361102"
          },
          {
            "label": "广丰区",
            "value": "361103"
          },
          {
            "label": "广信区",
            "value": "361104"
          },
          {
            "label": "玉山县",
            "value": "361123"
          },
          {
            "label": "铅山县",
            "value": "361124"
          },
          {
            "label": "横峰县",
            "value": "361125"
          },
          {
            "label": "弋阳县",
            "value": "361126"
          },
          {
            "label": "余干县",
            "value": "361127"
          },
          {
            "label": "鄱阳县",
            "value": "361128"
          },
          {
            "label": "万年县",
            "value": "361129"
          },
          {
            "label": "婺源县",
            "value": "361130"
          },
          {
            "label": "德兴市",
            "value": "361181"
          }
        ]
      }
    ]
  },
  {
    "label": "山东省",
    "value": "37",
    "children": [
      {
        "label": "济南市",
        "value": "3701",
        "children": [
          {
            "label": "历下区",
            "value": "370102"
          },
          {
            "label": "市中区",
            "value": "370103"
          },
          {
            "label": "槐荫区",
            "value": "370104"
          },
          {
            "label": "天桥区",
            "value": "370105"
          },
          {
            "label": "历城区",
            "value": "370112"
          },
          {
            "label": "长清区",
            "value": "370113"
          },
          {
            "label": "章丘区",
            "value": "370114"
          },
          {
            "label": "济阳区",
            "value": "370115"
          },
          {
            "label": "莱芜区",
            "value": "370116"
          },
          {
            "label": "钢城区",
            "value": "370117"
          },
          {
            "label": "平阴县",
            "value": "370124"
          },
          {
            "label": "商河县",
            "value": "370126"
          },
          {
            "label": "济南高新技术产业开发区",
            "value": "370171"
          }
        ]
      },
      {
        "label": "青岛市",
        "value": "3702",
        "children": [
          {
            "label": "市南区",
            "value": "370202"
          },
          {
            "label": "市北区",
            "value": "370203"
          },
          {
            "label": "黄岛区",
            "value": "370211"
          },
          {
            "label": "崂山区",
            "value": "370212"
          },
          {
            "label": "李沧区",
            "value": "370213"
          },
          {
            "label": "城阳区",
            "value": "370214"
          },
          {
            "label": "即墨区",
            "value": "370215"
          },
          {
            "label": "青岛高新技术产业开发区",
            "value": "370271"
          },
          {
            "label": "胶州市",
            "value": "370281"
          },
          {
            "label": "平度市",
            "value": "370283"
          },
          {
            "label": "莱西市",
            "value": "370285"
          }
        ]
      },
      {
        "label": "淄博市",
        "value": "3703",
        "children": [
          {
            "label": "淄川区",
            "value": "370302"
          },
          {
            "label": "张店区",
            "value": "370303"
          },
          {
            "label": "博山区",
            "value": "370304"
          },
          {
            "label": "临淄区",
            "value": "370305"
          },
          {
            "label": "周村区",
            "value": "370306"
          },
          {
            "label": "桓台县",
            "value": "370321"
          },
          {
            "label": "高青县",
            "value": "370322"
          },
          {
            "label": "沂源县",
            "value": "370323"
          }
        ]
      },
      {
        "label": "枣庄市",
        "value": "3704",
        "children": [
          {
            "label": "市中区",
            "value": "370402"
          },
          {
            "label": "薛城区",
            "value": "370403"
          },
          {
            "label": "峄城区",
            "value": "370404"
          },
          {
            "label": "台儿庄区",
            "value": "370405"
          },
          {
            "label": "山亭区",
            "value": "370406"
          },
          {
            "label": "滕州市",
            "value": "370481"
          }
        ]
      },
      {
        "label": "东营市",
        "value": "3705",
        "children": [
          {
            "label": "东营区",
            "value": "370502"
          },
          {
            "label": "河口区",
            "value": "370503"
          },
          {
            "label": "垦利区",
            "value": "370505"
          },
          {
            "label": "利津县",
            "value": "370522"
          },
          {
            "label": "广饶县",
            "value": "370523"
          },
          {
            "label": "东营经济技术开发区",
            "value": "370571"
          },
          {
            "label": "东营港经济开发区",
            "value": "370572"
          }
        ]
      },
      {
        "label": "烟台市",
        "value": "3706",
        "children": [
          {
            "label": "芝罘区",
            "value": "370602"
          },
          {
            "label": "福山区",
            "value": "370611"
          },
          {
            "label": "牟平区",
            "value": "370612"
          },
          {
            "label": "莱山区",
            "value": "370613"
          },
          {
            "label": "长岛县",
            "value": "370634"
          },
          {
            "label": "烟台高新技术产业开发区",
            "value": "370671"
          },
          {
            "label": "烟台经济技术开发区",
            "value": "370672"
          },
          {
            "label": "龙口市",
            "value": "370681"
          },
          {
            "label": "莱阳市",
            "value": "370682"
          },
          {
            "label": "莱州市",
            "value": "370683"
          },
          {
            "label": "蓬莱市",
            "value": "370684"
          },
          {
            "label": "招远市",
            "value": "370685"
          },
          {
            "label": "栖霞市",
            "value": "370686"
          },
          {
            "label": "海阳市",
            "value": "370687"
          }
        ]
      },
      {
        "label": "潍坊市",
        "value": "3707",
        "children": [
          {
            "label": "潍城区",
            "value": "370702"
          },
          {
            "label": "寒亭区",
            "value": "370703"
          },
          {
            "label": "坊子区",
            "value": "370704"
          },
          {
            "label": "奎文区",
            "value": "370705"
          },
          {
            "label": "临朐县",
            "value": "370724"
          },
          {
            "label": "昌乐县",
            "value": "370725"
          },
          {
            "label": "潍坊滨海经济技术开发区",
            "value": "370772"
          },
          {
            "label": "青州市",
            "value": "370781"
          },
          {
            "label": "诸城市",
            "value": "370782"
          },
          {
            "label": "寿光市",
            "value": "370783"
          },
          {
            "label": "安丘市",
            "value": "370784"
          },
          {
            "label": "高密市",
            "value": "370785"
          },
          {
            "label": "昌邑市",
            "value": "370786"
          }
        ]
      },
      {
        "label": "济宁市",
        "value": "3708",
        "children": [
          {
            "label": "任城区",
            "value": "370811"
          },
          {
            "label": "兖州区",
            "value": "370812"
          },
          {
            "label": "微山县",
            "value": "370826"
          },
          {
            "label": "鱼台县",
            "value": "370827"
          },
          {
            "label": "金乡县",
            "value": "370828"
          },
          {
            "label": "嘉祥县",
            "value": "370829"
          },
          {
            "label": "汶上县",
            "value": "370830"
          },
          {
            "label": "泗水县",
            "value": "370831"
          },
          {
            "label": "梁山县",
            "value": "370832"
          },
          {
            "label": "济宁高新技术产业开发区",
            "value": "370871"
          },
          {
            "label": "曲阜市",
            "value": "370881"
          },
          {
            "label": "邹城市",
            "value": "370883"
          }
        ]
      },
      {
        "label": "泰安市",
        "value": "3709",
        "children": [
          {
            "label": "泰山区",
            "value": "370902"
          },
          {
            "label": "岱岳区",
            "value": "370911"
          },
          {
            "label": "宁阳县",
            "value": "370921"
          },
          {
            "label": "东平县",
            "value": "370923"
          },
          {
            "label": "新泰市",
            "value": "370982"
          },
          {
            "label": "肥城市",
            "value": "370983"
          }
        ]
      },
      {
        "label": "威海市",
        "value": "3710",
        "children": [
          {
            "label": "环翠区",
            "value": "371002"
          },
          {
            "label": "文登区",
            "value": "371003"
          },
          {
            "label": "威海火炬高技术产业开发区",
            "value": "371071"
          },
          {
            "label": "威海经济技术开发区",
            "value": "371072"
          },
          {
            "label": "威海临港经济技术开发区",
            "value": "371073"
          },
          {
            "label": "荣成市",
            "value": "371082"
          },
          {
            "label": "乳山市",
            "value": "371083"
          }
        ]
      },
      {
        "label": "日照市",
        "value": "3711",
        "children": [
          {
            "label": "东港区",
            "value": "371102"
          },
          {
            "label": "岚山区",
            "value": "371103"
          },
          {
            "label": "五莲县",
            "value": "371121"
          },
          {
            "label": "莒县",
            "value": "371122"
          },
          {
            "label": "日照经济技术开发区",
            "value": "371171"
          }
        ]
      },
      {
        "label": "临沂市",
        "value": "3713",
        "children": [
          {
            "label": "兰山区",
            "value": "371302"
          },
          {
            "label": "罗庄区",
            "value": "371311"
          },
          {
            "label": "河东区",
            "value": "371312"
          },
          {
            "label": "沂南县",
            "value": "371321"
          },
          {
            "label": "郯城县",
            "value": "371322"
          },
          {
            "label": "沂水县",
            "value": "371323"
          },
          {
            "label": "兰陵县",
            "value": "371324"
          },
          {
            "label": "费县",
            "value": "371325"
          },
          {
            "label": "平邑县",
            "value": "371326"
          },
          {
            "label": "莒南县",
            "value": "371327"
          },
          {
            "label": "蒙阴县",
            "value": "371328"
          },
          {
            "label": "临沭县",
            "value": "371329"
          },
          {
            "label": "临沂高新技术产业开发区",
            "value": "371371"
          },
          {
            "label": "临沂经济技术开发区",
            "value": "371372"
          },
          {
            "label": "临沂临港经济开发区",
            "value": "371373"
          }
        ]
      },
      {
        "label": "德州市",
        "value": "3714",
        "children": [
          {
            "label": "德城区",
            "value": "371402"
          },
          {
            "label": "陵城区",
            "value": "371403"
          },
          {
            "label": "宁津县",
            "value": "371422"
          },
          {
            "label": "庆云县",
            "value": "371423"
          },
          {
            "label": "临邑县",
            "value": "371424"
          },
          {
            "label": "齐河县",
            "value": "371425"
          },
          {
            "label": "平原县",
            "value": "371426"
          },
          {
            "label": "夏津县",
            "value": "371427"
          },
          {
            "label": "武城县",
            "value": "371428"
          },
          {
            "label": "德州经济技术开发区",
            "value": "371471"
          },
          {
            "label": "德州运河经济开发区",
            "value": "371472"
          },
          {
            "label": "乐陵市",
            "value": "371481"
          },
          {
            "label": "禹城市",
            "value": "371482"
          }
        ]
      },
      {
        "label": "聊城市",
        "value": "3715",
        "children": [
          {
            "label": "东昌府区",
            "value": "371502"
          },
          {
            "label": "茌平区",
            "value": "371503"
          },
          {
            "label": "阳谷县",
            "value": "371521"
          },
          {
            "label": "莘县",
            "value": "371522"
          },
          {
            "label": "东阿县",
            "value": "371524"
          },
          {
            "label": "冠县",
            "value": "371525"
          },
          {
            "label": "高唐县",
            "value": "371526"
          },
          {
            "label": "临清市",
            "value": "371581"
          }
        ]
      },
      {
        "label": "滨州市",
        "value": "3716",
        "children": [
          {
            "label": "滨城区",
            "value": "371602"
          },
          {
            "label": "沾化区",
            "value": "371603"
          },
          {
            "label": "惠民县",
            "value": "371621"
          },
          {
            "label": "阳信县",
            "value": "371622"
          },
          {
            "label": "无棣县",
            "value": "371623"
          },
          {
            "label": "博兴县",
            "value": "371625"
          },
          {
            "label": "邹平市",
            "value": "371681"
          }
        ]
      },
      {
        "label": "菏泽市",
        "value": "3717",
        "children": [
          {
            "label": "牡丹区",
            "value": "371702"
          },
          {
            "label": "定陶区",
            "value": "371703"
          },
          {
            "label": "曹县",
            "value": "371721"
          },
          {
            "label": "单县",
            "value": "371722"
          },
          {
            "label": "成武县",
            "value": "371723"
          },
          {
            "label": "巨野县",
            "value": "371724"
          },
          {
            "label": "郓城县",
            "value": "371725"
          },
          {
            "label": "鄄城县",
            "value": "371726"
          },
          {
            "label": "东明县",
            "value": "371728"
          },
          {
            "label": "菏泽经济技术开发区",
            "value": "371771"
          },
          {
            "label": "菏泽高新技术开发区",
            "value": "371772"
          }
        ]
      }
    ]
  },
  {
    "label": "河南省",
    "value": "41",
    "children": [
      {
        "label": "郑州市",
        "value": "4101",
        "children": [
          {
            "label": "中原区",
            "value": "410102"
          },
          {
            "label": "二七区",
            "value": "410103"
          },
          {
            "label": "管城回族区",
            "value": "410104"
          },
          {
            "label": "金水区",
            "value": "410105"
          },
          {
            "label": "上街区",
            "value": "410106"
          },
          {
            "label": "惠济区",
            "value": "410108"
          },
          {
            "label": "中牟县",
            "value": "410122"
          },
          {
            "label": "郑州经济技术开发区",
            "value": "410171"
          },
          {
            "label": "郑州高新技术产业开发区",
            "value": "410172"
          },
          {
            "label": "郑州航空港经济综合实验区",
            "value": "410173"
          },
          {
            "label": "巩义市",
            "value": "410181"
          },
          {
            "label": "荥阳市",
            "value": "410182"
          },
          {
            "label": "新密市",
            "value": "410183"
          },
          {
            "label": "新郑市",
            "value": "410184"
          },
          {
            "label": "登封市",
            "value": "410185"
          }
        ]
      },
      {
        "label": "开封市",
        "value": "4102",
        "children": [
          {
            "label": "龙亭区",
            "value": "410202"
          },
          {
            "label": "顺河回族区",
            "value": "410203"
          },
          {
            "label": "鼓楼区",
            "value": "410204"
          },
          {
            "label": "禹王台区",
            "value": "410205"
          },
          {
            "label": "祥符区",
            "value": "410212"
          },
          {
            "label": "杞县",
            "value": "410221"
          },
          {
            "label": "通许县",
            "value": "410222"
          },
          {
            "label": "尉氏县",
            "value": "410223"
          },
          {
            "label": "兰考县",
            "value": "410225"
          }
        ]
      },
      {
        "label": "洛阳市",
        "value": "4103",
        "children": [
          {
            "label": "老城区",
            "value": "410302"
          },
          {
            "label": "西工区",
            "value": "410303"
          },
          {
            "label": "瀍河回族区",
            "value": "410304"
          },
          {
            "label": "涧西区",
            "value": "410305"
          },
          {
            "label": "吉利区",
            "value": "410306"
          },
          {
            "label": "洛龙区",
            "value": "410311"
          },
          {
            "label": "孟津县",
            "value": "410322"
          },
          {
            "label": "新安县",
            "value": "410323"
          },
          {
            "label": "栾川县",
            "value": "410324"
          },
          {
            "label": "嵩县",
            "value": "410325"
          },
          {
            "label": "汝阳县",
            "value": "410326"
          },
          {
            "label": "宜阳县",
            "value": "410327"
          },
          {
            "label": "洛宁县",
            "value": "410328"
          },
          {
            "label": "伊川县",
            "value": "410329"
          },
          {
            "label": "洛阳高新技术产业开发区",
            "value": "410371"
          },
          {
            "label": "偃师市",
            "value": "410381"
          }
        ]
      },
      {
        "label": "平顶山市",
        "value": "4104",
        "children": [
          {
            "label": "新华区",
            "value": "410402"
          },
          {
            "label": "卫东区",
            "value": "410403"
          },
          {
            "label": "石龙区",
            "value": "410404"
          },
          {
            "label": "湛河区",
            "value": "410411"
          },
          {
            "label": "宝丰县",
            "value": "410421"
          },
          {
            "label": "叶县",
            "value": "410422"
          },
          {
            "label": "鲁山县",
            "value": "410423"
          },
          {
            "label": "郏县",
            "value": "410425"
          },
          {
            "label": "平顶山高新技术产业开发区",
            "value": "410471"
          },
          {
            "label": "平顶山市城乡一体化示范区",
            "value": "410472"
          },
          {
            "label": "舞钢市",
            "value": "410481"
          },
          {
            "label": "汝州市",
            "value": "410482"
          }
        ]
      },
      {
        "label": "安阳市",
        "value": "4105",
        "children": [
          {
            "label": "文峰区",
            "value": "410502"
          },
          {
            "label": "北关区",
            "value": "410503"
          },
          {
            "label": "殷都区",
            "value": "410505"
          },
          {
            "label": "龙安区",
            "value": "410506"
          },
          {
            "label": "安阳县",
            "value": "410522"
          },
          {
            "label": "汤阴县",
            "value": "410523"
          },
          {
            "label": "滑县",
            "value": "410526"
          },
          {
            "label": "内黄县",
            "value": "410527"
          },
          {
            "label": "安阳高新技术产业开发区",
            "value": "410571"
          },
          {
            "label": "林州市",
            "value": "410581"
          }
        ]
      },
      {
        "label": "鹤壁市",
        "value": "4106",
        "children": [
          {
            "label": "鹤山区",
            "value": "410602"
          },
          {
            "label": "山城区",
            "value": "410603"
          },
          {
            "label": "淇滨区",
            "value": "410611"
          },
          {
            "label": "浚县",
            "value": "410621"
          },
          {
            "label": "淇县",
            "value": "410622"
          },
          {
            "label": "鹤壁经济技术开发区",
            "value": "410671"
          }
        ]
      },
      {
        "label": "新乡市",
        "value": "4107",
        "children": [
          {
            "label": "红旗区",
            "value": "410702"
          },
          {
            "label": "卫滨区",
            "value": "410703"
          },
          {
            "label": "凤泉区",
            "value": "410704"
          },
          {
            "label": "牧野区",
            "value": "410711"
          },
          {
            "label": "新乡县",
            "value": "410721"
          },
          {
            "label": "获嘉县",
            "value": "410724"
          },
          {
            "label": "原阳县",
            "value": "410725"
          },
          {
            "label": "延津县",
            "value": "410726"
          },
          {
            "label": "封丘县",
            "value": "410727"
          },
          {
            "label": "新乡高新技术产业开发区",
            "value": "410771"
          },
          {
            "label": "新乡经济技术开发区",
            "value": "410772"
          },
          {
            "label": "新乡市平原城乡一体化示范区",
            "value": "410773"
          },
          {
            "label": "卫辉市",
            "value": "410781"
          },
          {
            "label": "辉县市",
            "value": "410782"
          },
          {
            "label": "长垣市",
            "value": "410783"
          }
        ]
      },
      {
        "label": "焦作市",
        "value": "4108",
        "children": [
          {
            "label": "解放区",
            "value": "410802"
          },
          {
            "label": "中站区",
            "value": "410803"
          },
          {
            "label": "马村区",
            "value": "410804"
          },
          {
            "label": "山阳区",
            "value": "410811"
          },
          {
            "label": "修武县",
            "value": "410821"
          },
          {
            "label": "博爱县",
            "value": "410822"
          },
          {
            "label": "武陟县",
            "value": "410823"
          },
          {
            "label": "温县",
            "value": "410825"
          },
          {
            "label": "焦作城乡一体化示范区",
            "value": "410871"
          },
          {
            "label": "沁阳市",
            "value": "410882"
          },
          {
            "label": "孟州市",
            "value": "410883"
          }
        ]
      },
      {
        "label": "濮阳市",
        "value": "4109",
        "children": [
          {
            "label": "华龙区",
            "value": "410902"
          },
          {
            "label": "清丰县",
            "value": "410922"
          },
          {
            "label": "南乐县",
            "value": "410923"
          },
          {
            "label": "范县",
            "value": "410926"
          },
          {
            "label": "台前县",
            "value": "410927"
          },
          {
            "label": "濮阳县",
            "value": "410928"
          },
          {
            "label": "河南濮阳工业园区",
            "value": "410971"
          },
          {
            "label": "濮阳经济技术开发区",
            "value": "410972"
          }
        ]
      },
      {
        "label": "许昌市",
        "value": "4110",
        "children": [
          {
            "label": "魏都区",
            "value": "411002"
          },
          {
            "label": "建安区",
            "value": "411003"
          },
          {
            "label": "鄢陵县",
            "value": "411024"
          },
          {
            "label": "襄城县",
            "value": "411025"
          },
          {
            "label": "许昌经济技术开发区",
            "value": "411071"
          },
          {
            "label": "禹州市",
            "value": "411081"
          },
          {
            "label": "长葛市",
            "value": "411082"
          }
        ]
      },
      {
        "label": "漯河市",
        "value": "4111",
        "children": [
          {
            "label": "源汇区",
            "value": "411102"
          },
          {
            "label": "郾城区",
            "value": "411103"
          },
          {
            "label": "召陵区",
            "value": "411104"
          },
          {
            "label": "舞阳县",
            "value": "411121"
          },
          {
            "label": "临颍县",
            "value": "411122"
          },
          {
            "label": "漯河经济技术开发区",
            "value": "411171"
          }
        ]
      },
      {
        "label": "三门峡市",
        "value": "4112",
        "children": [
          {
            "label": "湖滨区",
            "value": "411202"
          },
          {
            "label": "陕州区",
            "value": "411203"
          },
          {
            "label": "渑池县",
            "value": "411221"
          },
          {
            "label": "卢氏县",
            "value": "411224"
          },
          {
            "label": "河南三门峡经济开发区",
            "value": "411271"
          },
          {
            "label": "义马市",
            "value": "411281"
          },
          {
            "label": "灵宝市",
            "value": "411282"
          }
        ]
      },
      {
        "label": "南阳市",
        "value": "4113",
        "children": [
          {
            "label": "宛城区",
            "value": "411302"
          },
          {
            "label": "卧龙区",
            "value": "411303"
          },
          {
            "label": "南召县",
            "value": "411321"
          },
          {
            "label": "方城县",
            "value": "411322"
          },
          {
            "label": "西峡县",
            "value": "411323"
          },
          {
            "label": "镇平县",
            "value": "411324"
          },
          {
            "label": "内乡县",
            "value": "411325"
          },
          {
            "label": "淅川县",
            "value": "411326"
          },
          {
            "label": "社旗县",
            "value": "411327"
          },
          {
            "label": "唐河县",
            "value": "411328"
          },
          {
            "label": "新野县",
            "value": "411329"
          },
          {
            "label": "桐柏县",
            "value": "411330"
          },
          {
            "label": "南阳高新技术产业开发区",
            "value": "411371"
          },
          {
            "label": "南阳市城乡一体化示范区",
            "value": "411372"
          },
          {
            "label": "邓州市",
            "value": "411381"
          }
        ]
      },
      {
        "label": "商丘市",
        "value": "4114",
        "children": [
          {
            "label": "梁园区",
            "value": "411402"
          },
          {
            "label": "睢阳区",
            "value": "411403"
          },
          {
            "label": "民权县",
            "value": "411421"
          },
          {
            "label": "睢县",
            "value": "411422"
          },
          {
            "label": "宁陵县",
            "value": "411423"
          },
          {
            "label": "柘城县",
            "value": "411424"
          },
          {
            "label": "虞城县",
            "value": "411425"
          },
          {
            "label": "夏邑县",
            "value": "411426"
          },
          {
            "label": "豫东综合物流产业聚集区",
            "value": "411471"
          },
          {
            "label": "河南商丘经济开发区",
            "value": "411472"
          },
          {
            "label": "永城市",
            "value": "411481"
          }
        ]
      },
      {
        "label": "信阳市",
        "value": "4115",
        "children": [
          {
            "label": "浉河区",
            "value": "411502"
          },
          {
            "label": "平桥区",
            "value": "411503"
          },
          {
            "label": "罗山县",
            "value": "411521"
          },
          {
            "label": "光山县",
            "value": "411522"
          },
          {
            "label": "新县",
            "value": "411523"
          },
          {
            "label": "商城县",
            "value": "411524"
          },
          {
            "label": "固始县",
            "value": "411525"
          },
          {
            "label": "潢川县",
            "value": "411526"
          },
          {
            "label": "淮滨县",
            "value": "411527"
          },
          {
            "label": "息县",
            "value": "411528"
          },
          {
            "label": "信阳高新技术产业开发区",
            "value": "411571"
          }
        ]
      },
      {
        "label": "周口市",
        "value": "4116",
        "children": [
          {
            "label": "川汇区",
            "value": "411602"
          },
          {
            "label": "淮阳区",
            "value": "411603"
          },
          {
            "label": "扶沟县",
            "value": "411621"
          },
          {
            "label": "西华县",
            "value": "411622"
          },
          {
            "label": "商水县",
            "value": "411623"
          },
          {
            "label": "沈丘县",
            "value": "411624"
          },
          {
            "label": "郸城县",
            "value": "411625"
          },
          {
            "label": "太康县",
            "value": "411627"
          },
          {
            "label": "鹿邑县",
            "value": "411628"
          },
          {
            "label": "河南周口经济开发区",
            "value": "411671"
          },
          {
            "label": "项城市",
            "value": "411681"
          }
        ]
      },
      {
        "label": "驻马店市",
        "value": "4117",
        "children": [
          {
            "label": "驿城区",
            "value": "411702"
          },
          {
            "label": "西平县",
            "value": "411721"
          },
          {
            "label": "上蔡县",
            "value": "411722"
          },
          {
            "label": "平舆县",
            "value": "411723"
          },
          {
            "label": "正阳县",
            "value": "411724"
          },
          {
            "label": "确山县",
            "value": "411725"
          },
          {
            "label": "泌阳县",
            "value": "411726"
          },
          {
            "label": "汝南县",
            "value": "411727"
          },
          {
            "label": "遂平县",
            "value": "411728"
          },
          {
            "label": "新蔡县",
            "value": "411729"
          },
          {
            "label": "河南驻马店经济开发区",
            "value": "411771"
          }
        ]
      },
      {
        "label": "省直辖县级行政区划",
        "value": "4190",
        "children": [
          {
            "label": "济源市",
            "value": "419001"
          }
        ]
      }
    ]
  },
  {
    "label": "湖北省",
    "value": "42",
    "children": [
      {
        "label": "武汉市",
        "value": "4201",
        "children": [
          {
            "label": "江岸区",
            "value": "420102"
          },
          {
            "label": "江汉区",
            "value": "420103"
          },
          {
            "label": "硚口区",
            "value": "420104"
          },
          {
            "label": "汉阳区",
            "value": "420105"
          },
          {
            "label": "武昌区",
            "value": "420106"
          },
          {
            "label": "青山区",
            "value": "420107"
          },
          {
            "label": "洪山区",
            "value": "420111"
          },
          {
            "label": "东西湖区",
            "value": "420112"
          },
          {
            "label": "汉南区",
            "value": "420113"
          },
          {
            "label": "蔡甸区",
            "value": "420114"
          },
          {
            "label": "江夏区",
            "value": "420115"
          },
          {
            "label": "黄陂区",
            "value": "420116"
          },
          {
            "label": "新洲区",
            "value": "420117"
          }
        ]
      },
      {
        "label": "黄石市",
        "value": "4202",
        "children": [
          {
            "label": "黄石港区",
            "value": "420202"
          },
          {
            "label": "西塞山区",
            "value": "420203"
          },
          {
            "label": "下陆区",
            "value": "420204"
          },
          {
            "label": "铁山区",
            "value": "420205"
          },
          {
            "label": "阳新县",
            "value": "420222"
          },
          {
            "label": "大冶市",
            "value": "420281"
          }
        ]
      },
      {
        "label": "十堰市",
        "value": "4203",
        "children": [
          {
            "label": "茅箭区",
            "value": "420302"
          },
          {
            "label": "张湾区",
            "value": "420303"
          },
          {
            "label": "郧阳区",
            "value": "420304"
          },
          {
            "label": "郧西县",
            "value": "420322"
          },
          {
            "label": "竹山县",
            "value": "420323"
          },
          {
            "label": "竹溪县",
            "value": "420324"
          },
          {
            "label": "房县",
            "value": "420325"
          },
          {
            "label": "丹江口市",
            "value": "420381"
          }
        ]
      },
      {
        "label": "宜昌市",
        "value": "4205",
        "children": [
          {
            "label": "西陵区",
            "value": "420502"
          },
          {
            "label": "伍家岗区",
            "value": "420503"
          },
          {
            "label": "点军区",
            "value": "420504"
          },
          {
            "label": "猇亭区",
            "value": "420505"
          },
          {
            "label": "夷陵区",
            "value": "420506"
          },
          {
            "label": "远安县",
            "value": "420525"
          },
          {
            "label": "兴山县",
            "value": "420526"
          },
          {
            "label": "秭归县",
            "value": "420527"
          },
          {
            "label": "长阳土家族自治县",
            "value": "420528"
          },
          {
            "label": "五峰土家族自治县",
            "value": "420529"
          },
          {
            "label": "宜都市",
            "value": "420581"
          },
          {
            "label": "当阳市",
            "value": "420582"
          },
          {
            "label": "枝江市",
            "value": "420583"
          }
        ]
      },
      {
        "label": "襄阳市",
        "value": "4206",
        "children": [
          {
            "label": "襄城区",
            "value": "420602"
          },
          {
            "label": "樊城区",
            "value": "420606"
          },
          {
            "label": "襄州区",
            "value": "420607"
          },
          {
            "label": "南漳县",
            "value": "420624"
          },
          {
            "label": "谷城县",
            "value": "420625"
          },
          {
            "label": "保康县",
            "value": "420626"
          },
          {
            "label": "老河口市",
            "value": "420682"
          },
          {
            "label": "枣阳市",
            "value": "420683"
          },
          {
            "label": "宜城市",
            "value": "420684"
          }
        ]
      },
      {
        "label": "鄂州市",
        "value": "4207",
        "children": [
          {
            "label": "梁子湖区",
            "value": "420702"
          },
          {
            "label": "华容区",
            "value": "420703"
          },
          {
            "label": "鄂城区",
            "value": "420704"
          }
        ]
      },
      {
        "label": "荆门市",
        "value": "4208",
        "children": [
          {
            "label": "东宝区",
            "value": "420802"
          },
          {
            "label": "掇刀区",
            "value": "420804"
          },
          {
            "label": "沙洋县",
            "value": "420822"
          },
          {
            "label": "钟祥市",
            "value": "420881"
          },
          {
            "label": "京山市",
            "value": "420882"
          }
        ]
      },
      {
        "label": "孝感市",
        "value": "4209",
        "children": [
          {
            "label": "孝南区",
            "value": "420902"
          },
          {
            "label": "孝昌县",
            "value": "420921"
          },
          {
            "label": "大悟县",
            "value": "420922"
          },
          {
            "label": "云梦县",
            "value": "420923"
          },
          {
            "label": "应城市",
            "value": "420981"
          },
          {
            "label": "安陆市",
            "value": "420982"
          },
          {
            "label": "汉川市",
            "value": "420984"
          }
        ]
      },
      {
        "label": "荆州市",
        "value": "4210",
        "children": [
          {
            "label": "沙市区",
            "value": "421002"
          },
          {
            "label": "荆州区",
            "value": "421003"
          },
          {
            "label": "公安县",
            "value": "421022"
          },
          {
            "label": "监利县",
            "value": "421023"
          },
          {
            "label": "江陵县",
            "value": "421024"
          },
          {
            "label": "荆州经济技术开发区",
            "value": "421071"
          },
          {
            "label": "石首市",
            "value": "421081"
          },
          {
            "label": "洪湖市",
            "value": "421083"
          },
          {
            "label": "松滋市",
            "value": "421087"
          }
        ]
      },
      {
        "label": "黄冈市",
        "value": "4211",
        "children": [
          {
            "label": "黄州区",
            "value": "421102"
          },
          {
            "label": "团风县",
            "value": "421121"
          },
          {
            "label": "红安县",
            "value": "421122"
          },
          {
            "label": "罗田县",
            "value": "421123"
          },
          {
            "label": "英山县",
            "value": "421124"
          },
          {
            "label": "浠水县",
            "value": "421125"
          },
          {
            "label": "蕲春县",
            "value": "421126"
          },
          {
            "label": "黄梅县",
            "value": "421127"
          },
          {
            "label": "龙感湖管理区",
            "value": "421171"
          },
          {
            "label": "麻城市",
            "value": "421181"
          },
          {
            "label": "武穴市",
            "value": "421182"
          }
        ]
      },
      {
        "label": "咸宁市",
        "value": "4212",
        "children": [
          {
            "label": "咸安区",
            "value": "421202"
          },
          {
            "label": "嘉鱼县",
            "value": "421221"
          },
          {
            "label": "通城县",
            "value": "421222"
          },
          {
            "label": "崇阳县",
            "value": "421223"
          },
          {
            "label": "通山县",
            "value": "421224"
          },
          {
            "label": "赤壁市",
            "value": "421281"
          }
        ]
      },
      {
        "label": "随州市",
        "value": "4213",
        "children": [
          {
            "label": "曾都区",
            "value": "421303"
          },
          {
            "label": "随县",
            "value": "421321"
          },
          {
            "label": "广水市",
            "value": "421381"
          }
        ]
      },
      {
        "label": "恩施土家族苗族自治州",
        "value": "4228",
        "children": [
          {
            "label": "恩施市",
            "value": "422801"
          },
          {
            "label": "利川市",
            "value": "422802"
          },
          {
            "label": "建始县",
            "value": "422822"
          },
          {
            "label": "巴东县",
            "value": "422823"
          },
          {
            "label": "宣恩县",
            "value": "422825"
          },
          {
            "label": "咸丰县",
            "value": "422826"
          },
          {
            "label": "来凤县",
            "value": "422827"
          },
          {
            "label": "鹤峰县",
            "value": "422828"
          }
        ]
      },
      {
        "label": "省直辖县级行政区划",
        "value": "4290",
        "children": [
          {
            "label": "仙桃市",
            "value": "429004"
          },
          {
            "label": "潜江市",
            "value": "429005"
          },
          {
            "label": "天门市",
            "value": "429006"
          },
          {
            "label": "神农架林区",
            "value": "429021"
          }
        ]
      }
    ]
  },
  {
    "label": "湖南省",
    "value": "43",
    "children": [
      {
        "label": "长沙市",
        "value": "4301",
        "children": [
          {
            "label": "芙蓉区",
            "value": "430102"
          },
          {
            "label": "天心区",
            "value": "430103"
          },
          {
            "label": "岳麓区",
            "value": "430104"
          },
          {
            "label": "开福区",
            "value": "430105"
          },
          {
            "label": "雨花区",
            "value": "430111"
          },
          {
            "label": "望城区",
            "value": "430112"
          },
          {
            "label": "长沙县",
            "value": "430121"
          },
          {
            "label": "浏阳市",
            "value": "430181"
          },
          {
            "label": "宁乡市",
            "value": "430182"
          }
        ]
      },
      {
        "label": "株洲市",
        "value": "4302",
        "children": [
          {
            "label": "荷塘区",
            "value": "430202"
          },
          {
            "label": "芦淞区",
            "value": "430203"
          },
          {
            "label": "石峰区",
            "value": "430204"
          },
          {
            "label": "天元区",
            "value": "430211"
          },
          {
            "label": "渌口区",
            "value": "430212"
          },
          {
            "label": "攸县",
            "value": "430223"
          },
          {
            "label": "茶陵县",
            "value": "430224"
          },
          {
            "label": "炎陵县",
            "value": "430225"
          },
          {
            "label": "云龙示范区",
            "value": "430271"
          },
          {
            "label": "醴陵市",
            "value": "430281"
          }
        ]
      },
      {
        "label": "湘潭市",
        "value": "4303",
        "children": [
          {
            "label": "雨湖区",
            "value": "430302"
          },
          {
            "label": "岳塘区",
            "value": "430304"
          },
          {
            "label": "湘潭县",
            "value": "430321"
          },
          {
            "label": "湖南湘潭高新技术产业园区",
            "value": "430371"
          },
          {
            "label": "湘潭昭山示范区",
            "value": "430372"
          },
          {
            "label": "湘潭九华示范区",
            "value": "430373"
          },
          {
            "label": "湘乡市",
            "value": "430381"
          },
          {
            "label": "韶山市",
            "value": "430382"
          }
        ]
      },
      {
        "label": "衡阳市",
        "value": "4304",
        "children": [
          {
            "label": "珠晖区",
            "value": "430405"
          },
          {
            "label": "雁峰区",
            "value": "430406"
          },
          {
            "label": "石鼓区",
            "value": "430407"
          },
          {
            "label": "蒸湘区",
            "value": "430408"
          },
          {
            "label": "南岳区",
            "value": "430412"
          },
          {
            "label": "衡阳县",
            "value": "430421"
          },
          {
            "label": "衡南县",
            "value": "430422"
          },
          {
            "label": "衡山县",
            "value": "430423"
          },
          {
            "label": "衡东县",
            "value": "430424"
          },
          {
            "label": "祁东县",
            "value": "430426"
          },
          {
            "label": "衡阳综合保税区",
            "value": "430471"
          },
          {
            "label": "湖南衡阳高新技术产业园区",
            "value": "430472"
          },
          {
            "label": "湖南衡阳松木经济开发区",
            "value": "430473"
          },
          {
            "label": "耒阳市",
            "value": "430481"
          },
          {
            "label": "常宁市",
            "value": "430482"
          }
        ]
      },
      {
        "label": "邵阳市",
        "value": "4305",
        "children": [
          {
            "label": "双清区",
            "value": "430502"
          },
          {
            "label": "大祥区",
            "value": "430503"
          },
          {
            "label": "北塔区",
            "value": "430511"
          },
          {
            "label": "新邵县",
            "value": "430522"
          },
          {
            "label": "邵阳县",
            "value": "430523"
          },
          {
            "label": "隆回县",
            "value": "430524"
          },
          {
            "label": "洞口县",
            "value": "430525"
          },
          {
            "label": "绥宁县",
            "value": "430527"
          },
          {
            "label": "新宁县",
            "value": "430528"
          },
          {
            "label": "城步苗族自治县",
            "value": "430529"
          },
          {
            "label": "武冈市",
            "value": "430581"
          },
          {
            "label": "邵东市",
            "value": "430582"
          }
        ]
      },
      {
        "label": "岳阳市",
        "value": "4306",
        "children": [
          {
            "label": "岳阳楼区",
            "value": "430602"
          },
          {
            "label": "云溪区",
            "value": "430603"
          },
          {
            "label": "君山区",
            "value": "430611"
          },
          {
            "label": "岳阳县",
            "value": "430621"
          },
          {
            "label": "华容县",
            "value": "430623"
          },
          {
            "label": "湘阴县",
            "value": "430624"
          },
          {
            "label": "平江县",
            "value": "430626"
          },
          {
            "label": "岳阳市屈原管理区",
            "value": "430671"
          },
          {
            "label": "汨罗市",
            "value": "430681"
          },
          {
            "label": "临湘市",
            "value": "430682"
          }
        ]
      },
      {
        "label": "常德市",
        "value": "4307",
        "children": [
          {
            "label": "武陵区",
            "value": "430702"
          },
          {
            "label": "鼎城区",
            "value": "430703"
          },
          {
            "label": "安乡县",
            "value": "430721"
          },
          {
            "label": "汉寿县",
            "value": "430722"
          },
          {
            "label": "澧县",
            "value": "430723"
          },
          {
            "label": "临澧县",
            "value": "430724"
          },
          {
            "label": "桃源县",
            "value": "430725"
          },
          {
            "label": "石门县",
            "value": "430726"
          },
          {
            "label": "常德市西洞庭管理区",
            "value": "430771"
          },
          {
            "label": "津市市",
            "value": "430781"
          }
        ]
      },
      {
        "label": "张家界市",
        "value": "4308",
        "children": [
          {
            "label": "永定区",
            "value": "430802"
          },
          {
            "label": "武陵源区",
            "value": "430811"
          },
          {
            "label": "慈利县",
            "value": "430821"
          },
          {
            "label": "桑植县",
            "value": "430822"
          }
        ]
      },
      {
        "label": "益阳市",
        "value": "4309",
        "children": [
          {
            "label": "资阳区",
            "value": "430902"
          },
          {
            "label": "赫山区",
            "value": "430903"
          },
          {
            "label": "南县",
            "value": "430921"
          },
          {
            "label": "桃江县",
            "value": "430922"
          },
          {
            "label": "安化县",
            "value": "430923"
          },
          {
            "label": "益阳市大通湖管理区",
            "value": "430971"
          },
          {
            "label": "湖南益阳高新技术产业园区",
            "value": "430972"
          },
          {
            "label": "沅江市",
            "value": "430981"
          }
        ]
      },
      {
        "label": "郴州市",
        "value": "4310",
        "children": [
          {
            "label": "北湖区",
            "value": "431002"
          },
          {
            "label": "苏仙区",
            "value": "431003"
          },
          {
            "label": "桂阳县",
            "value": "431021"
          },
          {
            "label": "宜章县",
            "value": "431022"
          },
          {
            "label": "永兴县",
            "value": "431023"
          },
          {
            "label": "嘉禾县",
            "value": "431024"
          },
          {
            "label": "临武县",
            "value": "431025"
          },
          {
            "label": "汝城县",
            "value": "431026"
          },
          {
            "label": "桂东县",
            "value": "431027"
          },
          {
            "label": "安仁县",
            "value": "431028"
          },
          {
            "label": "资兴市",
            "value": "431081"
          }
        ]
      },
      {
        "label": "永州市",
        "value": "4311",
        "children": [
          {
            "label": "零陵区",
            "value": "431102"
          },
          {
            "label": "冷水滩区",
            "value": "431103"
          },
          {
            "label": "祁阳县",
            "value": "431121"
          },
          {
            "label": "东安县",
            "value": "431122"
          },
          {
            "label": "双牌县",
            "value": "431123"
          },
          {
            "label": "道县",
            "value": "431124"
          },
          {
            "label": "江永县",
            "value": "431125"
          },
          {
            "label": "宁远县",
            "value": "431126"
          },
          {
            "label": "蓝山县",
            "value": "431127"
          },
          {
            "label": "新田县",
            "value": "431128"
          },
          {
            "label": "江华瑶族自治县",
            "value": "431129"
          },
          {
            "label": "永州经济技术开发区",
            "value": "431171"
          },
          {
            "label": "永州市金洞管理区",
            "value": "431172"
          },
          {
            "label": "永州市回龙圩管理区",
            "value": "431173"
          }
        ]
      },
      {
        "label": "怀化市",
        "value": "4312",
        "children": [
          {
            "label": "鹤城区",
            "value": "431202"
          },
          {
            "label": "中方县",
            "value": "431221"
          },
          {
            "label": "沅陵县",
            "value": "431222"
          },
          {
            "label": "辰溪县",
            "value": "431223"
          },
          {
            "label": "溆浦县",
            "value": "431224"
          },
          {
            "label": "会同县",
            "value": "431225"
          },
          {
            "label": "麻阳苗族自治县",
            "value": "431226"
          },
          {
            "label": "新晃侗族自治县",
            "value": "431227"
          },
          {
            "label": "芷江侗族自治县",
            "value": "431228"
          },
          {
            "label": "靖州苗族侗族自治县",
            "value": "431229"
          },
          {
            "label": "通道侗族自治县",
            "value": "431230"
          },
          {
            "label": "怀化市洪江管理区",
            "value": "431271"
          },
          {
            "label": "洪江市",
            "value": "431281"
          }
        ]
      },
      {
        "label": "娄底市",
        "value": "4313",
        "children": [
          {
            "label": "娄星区",
            "value": "431302"
          },
          {
            "label": "双峰县",
            "value": "431321"
          },
          {
            "label": "新化县",
            "value": "431322"
          },
          {
            "label": "冷水江市",
            "value": "431381"
          },
          {
            "label": "涟源市",
            "value": "431382"
          }
        ]
      },
      {
        "label": "湘西土家族苗族自治州",
        "value": "4331",
        "children": [
          {
            "label": "吉首市",
            "value": "433101"
          },
          {
            "label": "泸溪县",
            "value": "433122"
          },
          {
            "label": "凤凰县",
            "value": "433123"
          },
          {
            "label": "花垣县",
            "value": "433124"
          },
          {
            "label": "保靖县",
            "value": "433125"
          },
          {
            "label": "古丈县",
            "value": "433126"
          },
          {
            "label": "永顺县",
            "value": "433127"
          },
          {
            "label": "龙山县",
            "value": "433130"
          },
          {
            "label": "湖南永顺经济开发区",
            "value": "433173"
          }
        ]
      }
    ]
  },
  {
    "label": "广东省",
    "value": "44",
    "children": [
      {
        "label": "广州市",
        "value": "4401",
        "children": [
          {
            "label": "荔湾区",
            "value": "440103"
          },
          {
            "label": "越秀区",
            "value": "440104"
          },
          {
            "label": "海珠区",
            "value": "440105"
          },
          {
            "label": "天河区",
            "value": "440106"
          },
          {
            "label": "白云区",
            "value": "440111"
          },
          {
            "label": "黄埔区",
            "value": "440112"
          },
          {
            "label": "番禺区",
            "value": "440113"
          },
          {
            "label": "花都区",
            "value": "440114"
          },
          {
            "label": "南沙区",
            "value": "440115"
          },
          {
            "label": "从化区",
            "value": "440117"
          },
          {
            "label": "增城区",
            "value": "440118"
          },
          {
            "label": "其他区",
            "value": "440118"
          }
        ]
      },
      {
        "label": "韶关市",
        "value": "4402",
        "children": [
          {
            "label": "武江区",
            "value": "440203"
          },
          {
            "label": "浈江区",
            "value": "440204"
          },
          {
            "label": "曲江区",
            "value": "440205"
          },
          {
            "label": "始兴县",
            "value": "440222"
          },
          {
            "label": "仁化县",
            "value": "440224"
          },
          {
            "label": "翁源县",
            "value": "440229"
          },
          {
            "label": "乳源瑶族自治县",
            "value": "440232"
          },
          {
            "label": "新丰县",
            "value": "440233"
          },
          {
            "label": "乐昌市",
            "value": "440281"
          },
          {
            "label": "南雄市",
            "value": "440282"
          }
        ]
      },
      {
        "label": "深圳市",
        "value": "4403",
        "children": [
          {
            "label": "罗湖区",
            "value": "440303"
          },
          {
            "label": "福田区",
            "value": "440304"
          },
          {
            "label": "南山区",
            "value": "440305"
          },
          {
            "label": "宝安区",
            "value": "440306"
          },
          {
            "label": "龙岗区",
            "value": "440307"
          },
          {
            "label": "盐田区",
            "value": "440308"
          },
          {
            "label": "龙华区",
            "value": "440309"
          },
          {
            "label": "坪山区",
            "value": "440310"
          },
          {
            "label": "光明区",
            "value": "440311"
          }
        ]
      },
      {
        "label": "珠海市",
        "value": "4404",
        "children": [
          {
            "label": "香洲区",
            "value": "440402"
          },
          {
            "label": "斗门区",
            "value": "440403"
          },
          {
            "label": "金湾区",
            "value": "440404"
          }
        ]
      },
      {
        "label": "汕头市",
        "value": "4405",
        "children": [
          {
            "label": "龙湖区",
            "value": "440507"
          },
          {
            "label": "金平区",
            "value": "440511"
          },
          {
            "label": "濠江区",
            "value": "440512"
          },
          {
            "label": "潮阳区",
            "value": "440513"
          },
          {
            "label": "潮南区",
            "value": "440514"
          },
          {
            "label": "澄海区",
            "value": "440515"
          },
          {
            "label": "南澳县",
            "value": "440523"
          }
        ]
      },
      {
        "label": "佛山市",
        "value": "4406",
        "children": [
          {
            "label": "禅城区",
            "value": "440604"
          },
          {
            "label": "南海区",
            "value": "440605"
          },
          {
            "label": "顺德区",
            "value": "440606"
          },
          {
            "label": "三水区",
            "value": "440607"
          },
          {
            "label": "高明区",
            "value": "440608"
          }
        ]
      },
      {
        "label": "江门市",
        "value": "4407",
        "children": [
          {
            "label": "蓬江区",
            "value": "440703"
          },
          {
            "label": "江海区",
            "value": "440704"
          },
          {
            "label": "新会区",
            "value": "440705"
          },
          {
            "label": "台山市",
            "value": "440781"
          },
          {
            "label": "开平市",
            "value": "440783"
          },
          {
            "label": "鹤山市",
            "value": "440784"
          },
          {
            "label": "恩平市",
            "value": "440785"
          }
        ]
      },
      {
        "label": "湛江市",
        "value": "4408",
        "children": [
          {
            "label": "赤坎区",
            "value": "440802"
          },
          {
            "label": "霞山区",
            "value": "440803"
          },
          {
            "label": "坡头区",
            "value": "440804"
          },
          {
            "label": "麻章区",
            "value": "440811"
          },
          {
            "label": "遂溪县",
            "value": "440823"
          },
          {
            "label": "徐闻县",
            "value": "440825"
          },
          {
            "label": "廉江市",
            "value": "440881"
          },
          {
            "label": "雷州市",
            "value": "440882"
          },
          {
            "label": "吴川市",
            "value": "440883"
          }
        ]
      },
      {
        "label": "茂名市",
        "value": "4409",
        "children": [
          {
            "label": "茂南区",
            "value": "440902"
          },
          {
            "label": "电白区",
            "value": "440904"
          },
          {
            "label": "高州市",
            "value": "440981"
          },
          {
            "label": "化州市",
            "value": "440982"
          },
          {
            "label": "信宜市",
            "value": "440983"
          }
        ]
      },
      {
        "label": "肇庆市",
        "value": "4412",
        "children": [
          {
            "label": "端州区",
            "value": "441202"
          },
          {
            "label": "鼎湖区",
            "value": "441203"
          },
          {
            "label": "高要区",
            "value": "441204"
          },
          {
            "label": "广宁县",
            "value": "441223"
          },
          {
            "label": "怀集县",
            "value": "441224"
          },
          {
            "label": "封开县",
            "value": "441225"
          },
          {
            "label": "德庆县",
            "value": "441226"
          },
          {
            "label": "四会市",
            "value": "441284"
          }
        ]
      },
      {
        "label": "惠州市",
        "value": "4413",
        "children": [
          {
            "label": "惠城区",
            "value": "441302"
          },
          {
            "label": "惠阳区",
            "value": "441303"
          },
          {
            "label": "博罗县",
            "value": "441322"
          },
          {
            "label": "惠东县",
            "value": "441323"
          },
          {
            "label": "龙门县",
            "value": "441324"
          }
        ]
      },
      {
        "label": "梅州市",
        "value": "4414",
        "children": [
          {
            "label": "梅江区",
            "value": "441402"
          },
          {
            "label": "梅县区",
            "value": "441403"
          },
          {
            "label": "大埔县",
            "value": "441422"
          },
          {
            "label": "丰顺县",
            "value": "441423"
          },
          {
            "label": "五华县",
            "value": "441424"
          },
          {
            "label": "平远县",
            "value": "441426"
          },
          {
            "label": "蕉岭县",
            "value": "441427"
          },
          {
            "label": "兴宁市",
            "value": "441481"
          }
        ]
      },
      {
        "label": "汕尾市",
        "value": "4415",
        "children": [
          {
            "label": "城区",
            "value": "441502"
          },
          {
            "label": "海丰县",
            "value": "441521"
          },
          {
            "label": "陆河县",
            "value": "441523"
          },
          {
            "label": "陆丰市",
            "value": "441581"
          }
        ]
      },
      {
        "label": "河源市",
        "value": "4416",
        "children": [
          {
            "label": "源城区",
            "value": "441602"
          },
          {
            "label": "紫金县",
            "value": "441621"
          },
          {
            "label": "龙川县",
            "value": "441622"
          },
          {
            "label": "连平县",
            "value": "441623"
          },
          {
            "label": "和平县",
            "value": "441624"
          },
          {
            "label": "东源县",
            "value": "441625"
          }
        ]
      },
      {
        "label": "阳江市",
        "value": "4417",
        "children": [
          {
            "label": "江城区",
            "value": "441702"
          },
          {
            "label": "阳东区",
            "value": "441704"
          },
          {
            "label": "阳西县",
            "value": "441721"
          },
          {
            "label": "阳春市",
            "value": "441781"
          }
        ]
      },
      {
        "label": "清远市",
        "value": "4418",
        "children": [
          {
            "label": "清城区",
            "value": "441802"
          },
          {
            "label": "清新区",
            "value": "441803"
          },
          {
            "label": "佛冈县",
            "value": "441821"
          },
          {
            "label": "阳山县",
            "value": "441823"
          },
          {
            "label": "连山壮族瑶族自治县",
            "value": "441825"
          },
          {
            "label": "连南瑶族自治县",
            "value": "441826"
          },
          {
            "label": "英德市",
            "value": "441881"
          },
          {
            "label": "连州市",
            "value": "441882"
          }
        ]
      },
      {
        "label": "东莞市",
        "value": "4419",
        "children": [
          {
            "label": "茶山镇",
            "value": "441902"
          }, {
            "label": "长安镇",
            "value": "441903"
          }, {
            "label": "常平镇",
            "value": "441904"
          }, {
            "label": "大朗镇",
            "value": "441905"
          }, {
            "label": "大岭山镇",
            "value": "441906"
          }, {
            "label": "道滘镇",
            "value": "441907"
          }, {
            "label": "东城街道",
            "value": "441908"
          }, {
            "label": "东莞生态园",
            "value": "441909"
          },{
            "label": "东坑镇",
            "value": "441910"
          },{
            "label": "凤岗镇",
            "value": "441911"
          },{
            "label": "高埗镇",
            "value": "441912"
          },{
            "label": "莞城街道",
            "value": "441913"
          },{
            "label": "横沥镇",
            "value": "441914"
          },{
            "label": "横梅镇",
            "value": "441915"
          },{
            "label": "厚街镇",
            "value": "441916"
          },{
            "label": "虎门港管委会",
            "value": "441917"
          },{
            "label": "虎门镇",
            "value": "441918"
          },{
            "label": "黄江镇",
            "value": "441919"
          },{
            "label": "寮步镇",
            "value": "441920"
          },{
            "label": "麻涌镇",
            "value": "441921"
          },{
            "label": "南城街道",
            "value": "441922"
          },{
            "label": "企石镇",
            "value": "441923"
          },{
            "label": "桥头镇",
            "value": "441924"
          },{
            "label": "清溪镇",
            "value": "441925"
          },{
            "label": "沙田镇",
            "value": "441926"
          },{
            "label": "石碣镇",
            "value": "441927"
          },{
            "label": "石龙镇",
            "value": "441928"
          },{
            "label": "石排镇",
            "value": "441929"
          },{
            "label": "松山湖管委会",
            "value": "441930"
          },{
            "label": "塘厦镇",
            "value": "441931"
          },{
            "label": "万江街道",
            "value": "441932"
          },{
            "label": "望牛墩镇",
            "value": "441933"
          },{
            "label": "谢岗镇",
            "value": "441934"
          },{
            "label": "樟木头镇",
            "value": "441935"
          },{
            "label": "中堂镇",
            "value": "441936"
          },
        ]
      },
      {
        "label": "中山市",
        "value": "4420",
        "children": [
          {
            "label": "石岐街道",
            "value": "442001"
          },{
            "label": "东区街道",
            "value": "442002"
          },{
            "label": "中山港街道",
            "value": "442003"
          },{
            "label": "西区街道",
            "value": "442004"
          },{
            "label": "南区街道",
            "value": "442005"
          },{
            "label": "五桂山街道",
            "value": "442006"
          },{
            "label": "小榄镇",
            "value": "442007"
          },{
            "label": "黄圃镇",
            "value": "442008"
          },{
            "label": "民众街道",
            "value": "442009"
          },{
            "label": "东凤镇",
            "value": "442010"
          },{
            "label": "古镇镇",
            "value": "442011"
          },{
            "label": "沙溪镇",
            "value": "442012"
          },{
            "label": "坦洲镇",
            "value": "442013"
          },{
            "label": "港口镇",
            "value": "442014"
          },{
            "label": "三角镇",
            "value": "442015"
          },{
            "label": "横栏镇",
            "value": "442016"
          },{
            "label": "南头镇",
            "value": "442017"
          },{
            "label": "阜沙镇",
            "value": "442018"
          },{
            "label": "南朗街道",
            "value": "442019"
          },{
            "label": "三乡镇",
            "value": "442020"
          },{
            "label": "板芙镇",
            "value": "442021"
          },{
            "label": "大涌镇",
            "value": "442022"
          },{
            "label": "神湾镇",
            "value": "442023"
          }
        ]
      },
      {
        "label": "潮州市",
        "value": "4451",
        "children": [
          {
            "label": "湘桥区",
            "value": "445102"
          },
          {
            "label": "潮安区",
            "value": "445103"
          },
          {
            "label": "饶平县",
            "value": "445122"
          }
        ]
      },
      {
        "label": "揭阳市",
        "value": "4452",
        "children": [
          {
            "label": "榕城区",
            "value": "445202"
          },
          {
            "label": "揭东区",
            "value": "445203"
          },
          {
            "label": "揭西县",
            "value": "445222"
          },
          {
            "label": "惠来县",
            "value": "445224"
          },
          {
            "label": "普宁市",
            "value": "445281"
          }
        ]
      },
      {
        "label": "云浮市",
        "value": "4453",
        "children": [
          {
            "label": "云城区",
            "value": "445302"
          },
          {
            "label": "云安区",
            "value": "445303"
          },
          {
            "label": "新兴县",
            "value": "445321"
          },
          {
            "label": "郁南县",
            "value": "445322"
          },
          {
            "label": "罗定市",
            "value": "445381"
          }
        ]
      }
    ]
  },
  {
    "label": "广西壮族自治区",
    "value": "45",
    "children": [
      {
        "label": "南宁市",
        "value": "4501",
        "children": [
          {
            "label": "兴宁区",
            "value": "450102"
          },
          {
            "label": "青秀区",
            "value": "450103"
          },
          {
            "label": "江南区",
            "value": "450105"
          },
          {
            "label": "西乡塘区",
            "value": "450107"
          },
          {
            "label": "良庆区",
            "value": "450108"
          },
          {
            "label": "邕宁区",
            "value": "450109"
          },
          {
            "label": "武鸣区",
            "value": "450110"
          },
          {
            "label": "隆安县",
            "value": "450123"
          },
          {
            "label": "马山县",
            "value": "450124"
          },
          {
            "label": "上林县",
            "value": "450125"
          },
          {
            "label": "宾阳县",
            "value": "450126"
          },
          {
            "label": "横县",
            "value": "450127"
          }
        ]
      },
      {
        "label": "柳州市",
        "value": "4502",
        "children": [
          {
            "label": "城中区",
            "value": "450202"
          },
          {
            "label": "鱼峰区",
            "value": "450203"
          },
          {
            "label": "柳南区",
            "value": "450204"
          },
          {
            "label": "柳北区",
            "value": "450205"
          },
          {
            "label": "柳江区",
            "value": "450206"
          },
          {
            "label": "柳城县",
            "value": "450222"
          },
          {
            "label": "鹿寨县",
            "value": "450223"
          },
          {
            "label": "融安县",
            "value": "450224"
          },
          {
            "label": "融水苗族自治县",
            "value": "450225"
          },
          {
            "label": "三江侗族自治县",
            "value": "450226"
          }
        ]
      },
      {
        "label": "桂林市",
        "value": "4503",
        "children": [
          {
            "label": "秀峰区",
            "value": "450302"
          },
          {
            "label": "叠彩区",
            "value": "450303"
          },
          {
            "label": "象山区",
            "value": "450304"
          },
          {
            "label": "七星区",
            "value": "450305"
          },
          {
            "label": "雁山区",
            "value": "450311"
          },
          {
            "label": "临桂区",
            "value": "450312"
          },
          {
            "label": "阳朔县",
            "value": "450321"
          },
          {
            "label": "灵川县",
            "value": "450323"
          },
          {
            "label": "全州县",
            "value": "450324"
          },
          {
            "label": "兴安县",
            "value": "450325"
          },
          {
            "label": "永福县",
            "value": "450326"
          },
          {
            "label": "灌阳县",
            "value": "450327"
          },
          {
            "label": "龙胜各族自治县",
            "value": "450328"
          },
          {
            "label": "资源县",
            "value": "450329"
          },
          {
            "label": "平乐县",
            "value": "450330"
          },
          {
            "label": "恭城瑶族自治县",
            "value": "450332"
          },
          {
            "label": "荔浦市",
            "value": "450381"
          }
        ]
      },
      {
        "label": "梧州市",
        "value": "4504",
        "children": [
          {
            "label": "万秀区",
            "value": "450403"
          },
          {
            "label": "长洲区",
            "value": "450405"
          },
          {
            "label": "龙圩区",
            "value": "450406"
          },
          {
            "label": "苍梧县",
            "value": "450421"
          },
          {
            "label": "藤县",
            "value": "450422"
          },
          {
            "label": "蒙山县",
            "value": "450423"
          },
          {
            "label": "岑溪市",
            "value": "450481"
          }
        ]
      },
      {
        "label": "北海市",
        "value": "4505",
        "children": [
          {
            "label": "海城区",
            "value": "450502"
          },
          {
            "label": "银海区",
            "value": "450503"
          },
          {
            "label": "铁山港区",
            "value": "450512"
          },
          {
            "label": "合浦县",
            "value": "450521"
          }
        ]
      },
      {
        "label": "防城港市",
        "value": "4506",
        "children": [
          {
            "label": "港口区",
            "value": "450602"
          },
          {
            "label": "防城区",
            "value": "450603"
          },
          {
            "label": "上思县",
            "value": "450621"
          },
          {
            "label": "东兴市",
            "value": "450681"
          }
        ]
      },
      {
        "label": "钦州市",
        "value": "4507",
        "children": [
          {
            "label": "钦南区",
            "value": "450702"
          },
          {
            "label": "钦北区",
            "value": "450703"
          },
          {
            "label": "灵山县",
            "value": "450721"
          },
          {
            "label": "浦北县",
            "value": "450722"
          }
        ]
      },
      {
        "label": "贵港市",
        "value": "4508",
        "children": [
          {
            "label": "港北区",
            "value": "450802"
          },
          {
            "label": "港南区",
            "value": "450803"
          },
          {
            "label": "覃塘区",
            "value": "450804"
          },
          {
            "label": "平南县",
            "value": "450821"
          },
          {
            "label": "桂平市",
            "value": "450881"
          }
        ]
      },
      {
        "label": "玉林市",
        "value": "4509",
        "children": [
          {
            "label": "玉州区",
            "value": "450902"
          },
          {
            "label": "福绵区",
            "value": "450903"
          },
          {
            "label": "容县",
            "value": "450921"
          },
          {
            "label": "陆川县",
            "value": "450922"
          },
          {
            "label": "博白县",
            "value": "450923"
          },
          {
            "label": "兴业县",
            "value": "450924"
          },
          {
            "label": "北流市",
            "value": "450981"
          }
        ]
      },
      {
        "label": "百色市",
        "value": "4510",
        "children": [
          {
            "label": "右江区",
            "value": "451002"
          },
          {
            "label": "田阳区",
            "value": "451003"
          },
          {
            "label": "田东县",
            "value": "451022"
          },
          {
            "label": "平果县",
            "value": "451023"
          },
          {
            "label": "德保县",
            "value": "451024"
          },
          {
            "label": "那坡县",
            "value": "451026"
          },
          {
            "label": "凌云县",
            "value": "451027"
          },
          {
            "label": "乐业县",
            "value": "451028"
          },
          {
            "label": "田林县",
            "value": "451029"
          },
          {
            "label": "西林县",
            "value": "451030"
          },
          {
            "label": "隆林各族自治县",
            "value": "451031"
          },
          {
            "label": "靖西市",
            "value": "451081"
          }
        ]
      },
      {
        "label": "贺州市",
        "value": "4511",
        "children": [
          {
            "label": "八步区",
            "value": "451102"
          },
          {
            "label": "平桂区",
            "value": "451103"
          },
          {
            "label": "昭平县",
            "value": "451121"
          },
          {
            "label": "钟山县",
            "value": "451122"
          },
          {
            "label": "富川瑶族自治县",
            "value": "451123"
          }
        ]
      },
      {
        "label": "河池市",
        "value": "4512",
        "children": [
          {
            "label": "金城江区",
            "value": "451202"
          },
          {
            "label": "宜州区",
            "value": "451203"
          },
          {
            "label": "南丹县",
            "value": "451221"
          },
          {
            "label": "天峨县",
            "value": "451222"
          },
          {
            "label": "凤山县",
            "value": "451223"
          },
          {
            "label": "东兰县",
            "value": "451224"
          },
          {
            "label": "罗城仫佬族自治县",
            "value": "451225"
          },
          {
            "label": "环江毛南族自治县",
            "value": "451226"
          },
          {
            "label": "巴马瑶族自治县",
            "value": "451227"
          },
          {
            "label": "都安瑶族自治县",
            "value": "451228"
          },
          {
            "label": "大化瑶族自治县",
            "value": "451229"
          }
        ]
      },
      {
        "label": "来宾市",
        "value": "4513",
        "children": [
          {
            "label": "兴宾区",
            "value": "451302"
          },
          {
            "label": "忻城县",
            "value": "451321"
          },
          {
            "label": "象州县",
            "value": "451322"
          },
          {
            "label": "武宣县",
            "value": "451323"
          },
          {
            "label": "金秀瑶族自治县",
            "value": "451324"
          },
          {
            "label": "合山市",
            "value": "451381"
          }
        ]
      },
      {
        "label": "崇左市",
        "value": "4514",
        "children": [
          {
            "label": "江州区",
            "value": "451402"
          },
          {
            "label": "扶绥县",
            "value": "451421"
          },
          {
            "label": "宁明县",
            "value": "451422"
          },
          {
            "label": "龙州县",
            "value": "451423"
          },
          {
            "label": "大新县",
            "value": "451424"
          },
          {
            "label": "天等县",
            "value": "451425"
          },
          {
            "label": "凭祥市",
            "value": "451481"
          }
        ]
      }
    ]
  },
  {
    "label": "海南省",
    "value": "46",
    "children": [
      {
        "label": "海口市",
        "value": "4601",
        "children": [
          {
            "label": "秀英区",
            "value": "460105"
          },
          {
            "label": "龙华区",
            "value": "460106"
          },
          {
            "label": "琼山区",
            "value": "460107"
          },
          {
            "label": "美兰区",
            "value": "460108"
          }
        ]
      },
      {
        "label": "三亚市",
        "value": "4602",
        "children": [
          {
            "label": "海棠区",
            "value": "460202"
          },
          {
            "label": "吉阳区",
            "value": "460203"
          },
          {
            "label": "天涯区",
            "value": "460204"
          },
          {
            "label": "崖州区",
            "value": "460205"
          }
        ]
      },
      {
        "label": "三沙市",
        "value": "4603",
        "children": [
          {
            "label": "西沙群岛",
            "value": "460321"
          },
          {
            "label": "南沙群岛",
            "value": "460322"
          },
          {
            "label": "中沙群岛的岛礁及其海域",
            "value": "460323"
          }
        ]
      },
      {
        "label": "儋州市",
        "value": "4604",
        "children": [
          {
            "label": "儋州市",
            "value": "460400"
          }
        ]
      },
      {
        "label": "省直辖县级行政区划",
        "value": "4690",
        "children": [
          {
            "label": "五指山市",
            "value": "469001"
          },
          {
            "label": "琼海市",
            "value": "469002"
          },
          {
            "label": "文昌市",
            "value": "469005"
          },
          {
            "label": "万宁市",
            "value": "469006"
          },
          {
            "label": "东方市",
            "value": "469007"
          },
          {
            "label": "定安县",
            "value": "469021"
          },
          {
            "label": "屯昌县",
            "value": "469022"
          },
          {
            "label": "澄迈县",
            "value": "469023"
          },
          {
            "label": "临高县",
            "value": "469024"
          },
          {
            "label": "白沙黎族自治县",
            "value": "469025"
          },
          {
            "label": "昌江黎族自治县",
            "value": "469026"
          },
          {
            "label": "乐东黎族自治县",
            "value": "469027"
          },
          {
            "label": "陵水黎族自治县",
            "value": "469028"
          },
          {
            "label": "保亭黎族苗族自治县",
            "value": "469029"
          },
          {
            "label": "琼中黎族苗族自治县",
            "value": "469030"
          }
        ]
      }
    ]
  },
  {
    "label": "重庆市",
    "value": "5001",
    "children": [
      {
        "label": "万州区",
        "value": "500101",
        "children": [
          {
            "label": "高笋塘街道",
            "value": "500101001"
          },
          {
            "label": "太白街道",
            "value": "500101017"
          },
          {
            "label": "牌楼街道",
            "value": "500101018"
          },
          {
            "label": "双河口街道",
            "value": "500101019"
          },
          {
            "label": "龙都街道",
            "value": "500101020"
          },
          {
            "label": "周家坝街道",
            "value": "500101021"
          },
          {
            "label": "沙河街道",
            "value": "500101022"
          },
          {
            "label": "钟鼓楼街道",
            "value": "500101023"
          },
          {
            "label": "百安坝街道",
            "value": "500101024"
          },
          {
            "label": "五桥街道",
            "value": "500101025"
          },
          {
            "label": "陈家坝街道",
            "value": "500101026"
          },
          {
            "label": "小周镇",
            "value": "500101109"
          },
          {
            "label": "大周镇",
            "value": "500101110"
          },
          {
            "label": "新乡镇",
            "value": "500101123"
          },
          {
            "label": "孙家镇",
            "value": "500101132"
          },
          {
            "label": "高峰镇",
            "value": "500101134"
          },
          {
            "label": "龙沙镇",
            "value": "500101135"
          },
          {
            "label": "响水镇",
            "value": "500101136"
          },
          {
            "label": "武陵镇",
            "value": "500101137"
          },
          {
            "label": "瀼渡镇",
            "value": "500101138"
          },
          {
            "label": "甘宁镇",
            "value": "500101139"
          },
          {
            "label": "天城镇",
            "value": "500101140"
          },
          {
            "label": "熊家镇",
            "value": "500101141"
          },
          {
            "label": "高梁镇",
            "value": "500101142"
          },
          {
            "label": "李河镇",
            "value": "500101143"
          },
          {
            "label": "分水镇",
            "value": "500101144"
          },
          {
            "label": "余家镇",
            "value": "500101145"
          },
          {
            "label": "后山镇",
            "value": "500101146"
          },
          {
            "label": "弹子镇",
            "value": "500101147"
          },
          {
            "label": "长岭镇",
            "value": "500101148"
          },
          {
            "label": "新田镇",
            "value": "500101149"
          },
          {
            "label": "白羊镇",
            "value": "500101150"
          },
          {
            "label": "龙驹镇",
            "value": "500101151"
          },
          {
            "label": "走马镇",
            "value": "500101152"
          },
          {
            "label": "罗田镇",
            "value": "500101153"
          },
          {
            "label": "太龙镇",
            "value": "500101154"
          },
          {
            "label": "长滩镇",
            "value": "500101155"
          },
          {
            "label": "太安镇",
            "value": "500101156"
          },
          {
            "label": "白土镇",
            "value": "500101157"
          },
          {
            "label": "郭村镇",
            "value": "500101158"
          },
          {
            "label": "柱山乡",
            "value": "500101201"
          },
          {
            "label": "铁峰乡",
            "value": "500101212"
          },
          {
            "label": "溪口乡",
            "value": "500101225"
          },
          {
            "label": "长坪乡",
            "value": "500101226"
          },
          {
            "label": "燕山乡",
            "value": "500101227"
          },
          {
            "label": "梨树乡",
            "value": "500101234"
          },
          {
            "label": "普子乡",
            "value": "500101237"
          },
          {
            "label": "地宝土家族乡",
            "value": "500101238"
          },
          {
            "label": "恒合土家族乡",
            "value": "500101239"
          },
          {
            "label": "黄柏乡",
            "value": "500101244"
          },
          {
            "label": "九池乡",
            "value": "500101245"
          },
          {
            "label": "茨竹乡",
            "value": "500101247"
          }
        ]
      },
      {
        "label": "涪陵区",
        "value": "500102",
        "children": [
          {
            "label": "敦仁街道",
            "value": "500102006"
          },
          {
            "label": "崇义街道",
            "value": "500102007"
          },
          {
            "label": "荔枝街道",
            "value": "500102008"
          },
          {
            "label": "江北街道",
            "value": "500102009"
          },
          {
            "label": "江东街道",
            "value": "500102010"
          },
          {
            "label": "李渡街道",
            "value": "500102011"
          },
          {
            "label": "龙桥街道",
            "value": "500102012"
          },
          {
            "label": "白涛街道",
            "value": "500102013"
          },
          {
            "label": "马鞍街道",
            "value": "500102014"
          },
          {
            "label": "南沱镇",
            "value": "500102103"
          },
          {
            "label": "青羊镇",
            "value": "500102107"
          },
          {
            "label": "百胜镇",
            "value": "500102118"
          },
          {
            "label": "珍溪镇",
            "value": "500102119"
          },
          {
            "label": "清溪镇",
            "value": "500102120"
          },
          {
            "label": "焦石镇",
            "value": "500102121"
          },
          {
            "label": "马武镇",
            "value": "500102122"
          },
          {
            "label": "龙潭镇",
            "value": "500102123"
          },
          {
            "label": "蔺市镇",
            "value": "500102124"
          },
          {
            "label": "新妙镇",
            "value": "500102125"
          },
          {
            "label": "石沱镇",
            "value": "500102126"
          },
          {
            "label": "义和镇",
            "value": "500102127"
          },
          {
            "label": "罗云乡",
            "value": "500102204"
          },
          {
            "label": "大木乡",
            "value": "500102206"
          },
          {
            "label": "武陵山乡",
            "value": "500102208"
          },
          {
            "label": "大顺乡",
            "value": "500102216"
          },
          {
            "label": "增福乡",
            "value": "500102217"
          },
          {
            "label": "同乐乡",
            "value": "500102222"
          }
        ]
      },
      {
        "label": "渝中区",
        "value": "500103",
        "children": [
          {
            "label": "七星岗街道",
            "value": "500103001"
          },
          {
            "label": "解放碑街道",
            "value": "500103003"
          },
          {
            "label": "两路口街道",
            "value": "500103004"
          },
          {
            "label": "上清寺街道",
            "value": "500103005"
          },
          {
            "label": "菜园坝街道",
            "value": "500103007"
          },
          {
            "label": "南纪门街道",
            "value": "500103008"
          },
          {
            "label": "朝天门街道",
            "value": "500103010"
          },
          {
            "label": "大溪沟街道",
            "value": "500103011"
          },
          {
            "label": "大坪街道",
            "value": "500103012"
          },
          {
            "label": "化龙桥街道",
            "value": "500103013"
          },
          {
            "label": "石油路街道",
            "value": "500103014"
          }
        ]
      },
      {
        "label": "大渡口区",
        "value": "500104",
        "children": [
          {
            "label": "新山村街道",
            "value": "500104001"
          },
          {
            "label": "跃进村街道",
            "value": "500104002"
          },
          {
            "label": "九宫庙街道",
            "value": "500104003"
          },
          {
            "label": "茄子溪街道",
            "value": "500104004"
          },
          {
            "label": "春晖路街道",
            "value": "500104005"
          },
          {
            "label": "八桥镇",
            "value": "500104101"
          },
          {
            "label": "建胜镇",
            "value": "500104102"
          },
          {
            "label": "跳磴镇",
            "value": "500104103"
          }
        ]
      },
      {
        "label": "江北区",
        "value": "500105",
        "children": [
          {
            "label": "华新街街道",
            "value": "500105001"
          },
          {
            "label": "江北城街道",
            "value": "500105002"
          },
          {
            "label": "石马河街道",
            "value": "500105003"
          },
          {
            "label": "大石坝街道",
            "value": "500105004"
          },
          {
            "label": "寸滩街道",
            "value": "500105005"
          },
          {
            "label": "观音桥街道",
            "value": "500105006"
          },
          {
            "label": "五里店街道",
            "value": "500105007"
          },
          {
            "label": "郭家沱街道",
            "value": "500105008"
          },
          {
            "label": "铁山坪街道",
            "value": "500105009"
          },
          {
            "label": "鱼嘴镇",
            "value": "500105100"
          },
          {
            "label": "复盛镇",
            "value": "500105101"
          },
          {
            "label": "五宝镇",
            "value": "500105102"
          }
        ]
      },
      {
        "label": "沙坪坝区",
        "value": "500106",
        "children": [
          {
            "label": "小龙坎街道",
            "value": "500106001"
          },
          {
            "label": "沙坪坝街道",
            "value": "500106002"
          },
          {
            "label": "渝碚路街道",
            "value": "500106003"
          },
          {
            "label": "磁器口街道",
            "value": "500106004"
          },
          {
            "label": "童家桥街道",
            "value": "500106005"
          },
          {
            "label": "石井坡街道",
            "value": "500106006"
          },
          {
            "label": "双碑街道",
            "value": "500106007"
          },
          {
            "label": "井口街道",
            "value": "500106008"
          },
          {
            "label": "歌乐山街道",
            "value": "500106009"
          },
          {
            "label": "山洞街道",
            "value": "500106010"
          },
          {
            "label": "新桥街道",
            "value": "500106011"
          },
          {
            "label": "天星桥街道",
            "value": "500106012"
          },
          {
            "label": "土湾街道",
            "value": "500106013"
          },
          {
            "label": "覃家岗街道",
            "value": "500106014"
          },
          {
            "label": "陈家桥街道",
            "value": "500106015"
          },
          {
            "label": "虎溪街道",
            "value": "500106016"
          },
          {
            "label": "西永街道",
            "value": "500106017"
          },
          {
            "label": "联芳街道",
            "value": "500106018"
          },
          {
            "label": "丰文街道",
            "value": "500106019"
          },
          {
            "label": "香炉山街道",
            "value": "500106020"
          },
          {
            "label": "青木关镇",
            "value": "500106104"
          },
          {
            "label": "凤凰镇",
            "value": "500106105"
          },
          {
            "label": "回龙坝镇",
            "value": "500106106"
          },
          {
            "label": "曾家镇",
            "value": "500106108"
          },
          {
            "label": "土主镇",
            "value": "500106111"
          },
          {
            "label": "中梁镇",
            "value": "500106112"
          }
        ]
      },
      {
        "label": "九龙坡区",
        "value": "500107",
        "children": [
          {
            "label": "杨家坪街道",
            "value": "500107001"
          },
          {
            "label": "黄桷坪街道",
            "value": "500107002"
          },
          {
            "label": "谢家湾街道",
            "value": "500107003"
          },
          {
            "label": "石坪桥街道",
            "value": "500107004"
          },
          {
            "label": "石桥铺街道",
            "value": "500107005"
          },
          {
            "label": "中梁山街道",
            "value": "500107006"
          },
          {
            "label": "渝州路街道",
            "value": "500107007"
          },
          {
            "label": "二郎街道",
            "value": "500107008"
          },
          {
            "label": "九龙镇",
            "value": "500107100"
          },
          {
            "label": "华岩镇",
            "value": "500107102"
          },
          {
            "label": "含谷镇",
            "value": "500107103"
          },
          {
            "label": "金凤镇",
            "value": "500107104"
          },
          {
            "label": "白市驿镇",
            "value": "500107105"
          },
          {
            "label": "走马镇",
            "value": "500107106"
          },
          {
            "label": "石板镇",
            "value": "500107107"
          },
          {
            "label": "巴福镇",
            "value": "500107108"
          },
          {
            "label": "陶家镇",
            "value": "500107109"
          },
          {
            "label": "西彭镇",
            "value": "500107110"
          },
          {
            "label": "铜罐驿镇",
            "value": "500107111"
          }
        ]
      },
      {
        "label": "南岸区",
        "value": "500108",
        "children": [
          {
            "label": "铜元局街道",
            "value": "500108001"
          },
          {
            "label": "花园路街道",
            "value": "500108002"
          },
          {
            "label": "南坪街道",
            "value": "500108003"
          },
          {
            "label": "海棠溪街道",
            "value": "500108004"
          },
          {
            "label": "龙门浩街道",
            "value": "500108005"
          },
          {
            "label": "弹子石街道",
            "value": "500108006"
          },
          {
            "label": "南山街道",
            "value": "500108007"
          },
          {
            "label": "天文街道",
            "value": "500108008"
          },
          {
            "label": "南坪镇",
            "value": "500108101"
          },
          {
            "label": "涂山镇",
            "value": "500108102"
          },
          {
            "label": "鸡冠石镇",
            "value": "500108103"
          },
          {
            "label": "峡口镇",
            "value": "500108105"
          },
          {
            "label": "长生桥镇",
            "value": "500108106"
          },
          {
            "label": "迎龙镇",
            "value": "500108107"
          },
          {
            "label": "广阳镇",
            "value": "500108108"
          }
        ]
      },
      {
        "label": "北碚区",
        "value": "500109",
        "children": [
          {
            "label": "天生街道",
            "value": "500109001"
          },
          {
            "label": "朝阳街道",
            "value": "500109002"
          },
          {
            "label": "北温泉街道",
            "value": "500109003"
          },
          {
            "label": "东阳街道",
            "value": "500109004"
          },
          {
            "label": "龙凤桥街道",
            "value": "500109005"
          },
          {
            "label": "蔡家岗街道",
            "value": "500109006"
          },
          {
            "label": "歇马街道",
            "value": "500109007"
          },
          {
            "label": "水土街道",
            "value": "500109008"
          },
          {
            "label": "复兴街道",
            "value": "500109009"
          },
          {
            "label": "澄江镇",
            "value": "500109102"
          },
          {
            "label": "童家溪镇",
            "value": "500109104"
          },
          {
            "label": "天府镇",
            "value": "500109105"
          },
          {
            "label": "施家梁镇",
            "value": "500109108"
          },
          {
            "label": "静观镇",
            "value": "500109110"
          },
          {
            "label": "柳荫镇",
            "value": "500109111"
          },
          {
            "label": "三圣镇",
            "value": "500109113"
          },
          {
            "label": "金刀峡镇",
            "value": "500109116"
          }
        ]
      },
      {
        "label": "綦江区",
        "value": "500110",
        "children": [
          {
            "label": "古南街道",
            "value": "500110001"
          },
          {
            "label": "文龙街道",
            "value": "500110002"
          },
          {
            "label": "三江街道",
            "value": "500110003"
          },
          {
            "label": "万盛街道",
            "value": "500110004"
          },
          {
            "label": "东林街道",
            "value": "500110005"
          },
          {
            "label": "万东镇",
            "value": "500110100"
          },
          {
            "label": "南桐镇",
            "value": "500110101"
          },
          {
            "label": "青年镇",
            "value": "500110102"
          },
          {
            "label": "关坝镇",
            "value": "500110103"
          },
          {
            "label": "丛林镇",
            "value": "500110104"
          },
          {
            "label": "石林镇",
            "value": "500110105"
          },
          {
            "label": "金桥镇",
            "value": "500110106"
          },
          {
            "label": "黑山镇",
            "value": "500110107"
          },
          {
            "label": "石角镇",
            "value": "500110108"
          },
          {
            "label": "东溪镇",
            "value": "500110109"
          },
          {
            "label": "赶水镇",
            "value": "500110110"
          },
          {
            "label": "打通镇",
            "value": "500110111"
          },
          {
            "label": "石壕镇",
            "value": "500110112"
          },
          {
            "label": "永新镇",
            "value": "500110113"
          },
          {
            "label": "三角镇",
            "value": "500110114"
          },
          {
            "label": "隆盛镇",
            "value": "500110115"
          },
          {
            "label": "郭扶镇",
            "value": "500110116"
          },
          {
            "label": "篆塘镇",
            "value": "500110117"
          },
          {
            "label": "丁山镇",
            "value": "500110118"
          },
          {
            "label": "安稳镇",
            "value": "500110119"
          },
          {
            "label": "扶欢镇",
            "value": "500110120"
          },
          {
            "label": "永城镇",
            "value": "500110121"
          },
          {
            "label": "新盛镇",
            "value": "500110122"
          },
          {
            "label": "中峰镇",
            "value": "500110123"
          },
          {
            "label": "横山镇",
            "value": "500110124"
          }
        ]
      },
      {
        "label": "大足区",
        "value": "500111",
        "children": [
          {
            "label": "龙岗街道",
            "value": "500111001"
          },
          {
            "label": "棠香街道",
            "value": "500111002"
          },
          {
            "label": "龙滩子街道",
            "value": "500111003"
          },
          {
            "label": "双路街道",
            "value": "500111004"
          },
          {
            "label": "通桥街道",
            "value": "500111005"
          },
          {
            "label": "智凤街道",
            "value": "500111006"
          },
          {
            "label": "龙水镇",
            "value": "500111100"
          },
          {
            "label": "宝顶镇",
            "value": "500111102"
          },
          {
            "label": "中敖镇",
            "value": "500111103"
          },
          {
            "label": "三驱镇",
            "value": "500111104"
          },
          {
            "label": "宝兴镇",
            "value": "500111105"
          },
          {
            "label": "玉龙镇",
            "value": "500111106"
          },
          {
            "label": "石马镇",
            "value": "500111107"
          },
          {
            "label": "拾万镇",
            "value": "500111108"
          },
          {
            "label": "回龙镇",
            "value": "500111109"
          },
          {
            "label": "金山镇",
            "value": "500111110"
          },
          {
            "label": "万古镇",
            "value": "500111111"
          },
          {
            "label": "国梁镇",
            "value": "500111112"
          },
          {
            "label": "雍溪镇",
            "value": "500111113"
          },
          {
            "label": "珠溪镇",
            "value": "500111114"
          },
          {
            "label": "龙石镇",
            "value": "500111115"
          },
          {
            "label": "邮亭镇",
            "value": "500111116"
          },
          {
            "label": "铁山镇",
            "value": "500111117"
          },
          {
            "label": "高升镇",
            "value": "500111118"
          },
          {
            "label": "季家镇",
            "value": "500111119"
          },
          {
            "label": "古龙镇",
            "value": "500111120"
          },
          {
            "label": "高坪镇",
            "value": "500111121"
          }
        ]
      },
      {
        "label": "渝北区",
        "value": "500112",
        "children": [
          {
            "label": "双龙湖街道",
            "value": "500112002"
          },
          {
            "label": "回兴街道",
            "value": "500112004"
          },
          {
            "label": "鸳鸯街道",
            "value": "500112007"
          },
          {
            "label": "翠云街道",
            "value": "500112008"
          },
          {
            "label": "人和街道",
            "value": "500112009"
          },
          {
            "label": "天宫殿街道",
            "value": "500112010"
          },
          {
            "label": "龙溪街道",
            "value": "500112011"
          },
          {
            "label": "龙山街道",
            "value": "500112012"
          },
          {
            "label": "龙塔街道",
            "value": "500112013"
          },
          {
            "label": "大竹林街道",
            "value": "500112014"
          },
          {
            "label": "悦来街道",
            "value": "500112015"
          },
          {
            "label": "两路街道",
            "value": "500112016"
          },
          {
            "label": "双凤桥街道",
            "value": "500112017"
          },
          {
            "label": "王家街道",
            "value": "500112018"
          },
          {
            "label": "礼嘉街道",
            "value": "500112019"
          },
          {
            "label": "宝圣湖街道",
            "value": "500112020"
          },
          {
            "label": "金山街道",
            "value": "500112021"
          },
          {
            "label": "康美街道",
            "value": "500112022"
          },
          {
            "label": "仙桃街道",
            "value": "500112023"
          },
          {
            "label": "玉峰山镇",
            "value": "500112129"
          },
          {
            "label": "龙兴镇",
            "value": "500112131"
          },
          {
            "label": "统景镇",
            "value": "500112133"
          },
          {
            "label": "大湾镇",
            "value": "500112135"
          },
          {
            "label": "兴隆镇",
            "value": "500112138"
          },
          {
            "label": "木耳镇",
            "value": "500112139"
          },
          {
            "label": "茨竹镇",
            "value": "500112140"
          },
          {
            "label": "古路镇",
            "value": "500112141"
          },
          {
            "label": "石船镇",
            "value": "500112142"
          },
          {
            "label": "大盛镇",
            "value": "500112143"
          },
          {
            "label": "洛碛镇",
            "value": "500112144"
          }
        ]
      },
      {
        "label": "巴南区",
        "value": "500113",
        "children": [
          {
            "label": "龙洲湾街道",
            "value": "500113003"
          },
          {
            "label": "鱼洞街道",
            "value": "500113004"
          },
          {
            "label": "花溪街道",
            "value": "500113005"
          },
          {
            "label": "李家沱街道",
            "value": "500113006"
          },
          {
            "label": "南泉街道",
            "value": "500113007"
          },
          {
            "label": "一品街道",
            "value": "500113008"
          },
          {
            "label": "南彭街道",
            "value": "500113009"
          },
          {
            "label": "惠民街道",
            "value": "500113010"
          },
          {
            "label": "界石镇",
            "value": "500113103"
          },
          {
            "label": "安澜镇",
            "value": "500113107"
          },
          {
            "label": "圣灯山镇",
            "value": "500113108"
          },
          {
            "label": "木洞镇",
            "value": "500113109"
          },
          {
            "label": "双河口镇",
            "value": "500113110"
          },
          {
            "label": "麻柳嘴镇",
            "value": "500113111"
          },
          {
            "label": "丰盛镇",
            "value": "500113112"
          },
          {
            "label": "二圣镇",
            "value": "500113113"
          },
          {
            "label": "东温泉镇",
            "value": "500113114"
          },
          {
            "label": "姜家镇",
            "value": "500113115"
          },
          {
            "label": "天星寺镇",
            "value": "500113116"
          },
          {
            "label": "接龙镇",
            "value": "500113117"
          },
          {
            "label": "石滩镇",
            "value": "500113118"
          },
          {
            "label": "石龙镇",
            "value": "500113119"
          }
        ]
      },
      {
        "label": "黔江区",
        "value": "500114",
        "children": [
          {
            "label": "城东街道",
            "value": "500114001"
          },
          {
            "label": "城南街道",
            "value": "500114002"
          },
          {
            "label": "城西街道",
            "value": "500114003"
          },
          {
            "label": "正阳街道",
            "value": "500114004"
          },
          {
            "label": "舟白街道",
            "value": "500114005"
          },
          {
            "label": "冯家街道",
            "value": "500114006"
          },
          {
            "label": "阿蓬江镇",
            "value": "500114105"
          },
          {
            "label": "石会镇",
            "value": "500114106"
          },
          {
            "label": "黑溪镇",
            "value": "500114107"
          },
          {
            "label": "黄溪镇",
            "value": "500114108"
          },
          {
            "label": "黎水镇",
            "value": "500114109"
          },
          {
            "label": "金溪镇",
            "value": "500114110"
          },
          {
            "label": "马喇镇",
            "value": "500114111"
          },
          {
            "label": "濯水镇",
            "value": "500114112"
          },
          {
            "label": "石家镇",
            "value": "500114113"
          },
          {
            "label": "鹅池镇",
            "value": "500114114"
          },
          {
            "label": "小南海镇",
            "value": "500114117"
          },
          {
            "label": "邻鄂镇",
            "value": "500114119"
          },
          {
            "label": "白石镇",
            "value": "500114120"
          },
          {
            "label": "中塘镇",
            "value": "500114121"
          },
          {
            "label": "沙坝镇",
            "value": "500114122"
          },
          {
            "label": "蓬东乡",
            "value": "500114246"
          },
          {
            "label": "杉岭乡",
            "value": "500114249"
          },
          {
            "label": "太极乡",
            "value": "500114250"
          },
          {
            "label": "水田乡",
            "value": "500114251"
          },
          {
            "label": "白土乡",
            "value": "500114252"
          },
          {
            "label": "金洞乡",
            "value": "500114253"
          },
          {
            "label": "五里乡",
            "value": "500114254"
          },
          {
            "label": "水市乡",
            "value": "500114255"
          },
          {
            "label": "新华乡",
            "value": "500114256"
          }
        ]
      },
      {
        "label": "长寿区",
        "value": "500115",
        "children": [
          {
            "label": "凤城街道",
            "value": "500115001"
          },
          {
            "label": "晏家街道",
            "value": "500115002"
          },
          {
            "label": "江南街道",
            "value": "500115003"
          },
          {
            "label": "渡舟街道",
            "value": "500115004"
          },
          {
            "label": "新市街道",
            "value": "500115005"
          },
          {
            "label": "八颗街道",
            "value": "500115006"
          },
          {
            "label": "菩提街道",
            "value": "500115007"
          },
          {
            "label": "邻封镇",
            "value": "500115123"
          },
          {
            "label": "但渡镇",
            "value": "500115124"
          },
          {
            "label": "云集镇",
            "value": "500115125"
          },
          {
            "label": "长寿湖镇",
            "value": "500115126"
          },
          {
            "label": "双龙镇",
            "value": "500115127"
          },
          {
            "label": "龙河镇",
            "value": "500115128"
          },
          {
            "label": "石堰镇",
            "value": "500115129"
          },
          {
            "label": "云台镇",
            "value": "500115130"
          },
          {
            "label": "海棠镇",
            "value": "500115131"
          },
          {
            "label": "葛兰镇",
            "value": "500115132"
          },
          {
            "label": "洪湖镇",
            "value": "500115136"
          },
          {
            "label": "万顺镇",
            "value": "500115137"
          }
        ]
      },
      {
        "label": "江津区",
        "value": "500116",
        "children": [
          {
            "label": "几江街道",
            "value": "500116001"
          },
          {
            "label": "德感街道",
            "value": "500116002"
          },
          {
            "label": "双福街道",
            "value": "500116004"
          },
          {
            "label": "鼎山街道",
            "value": "500116005"
          },
          {
            "label": "圣泉街道",
            "value": "500116006"
          },
          {
            "label": "油溪镇",
            "value": "500116101"
          },
          {
            "label": "吴滩镇",
            "value": "500116102"
          },
          {
            "label": "石门镇",
            "value": "500116103"
          },
          {
            "label": "朱杨镇",
            "value": "500116104"
          },
          {
            "label": "石蟆镇",
            "value": "500116105"
          },
          {
            "label": "永兴镇",
            "value": "500116106"
          },
          {
            "label": "塘河镇",
            "value": "500116107"
          },
          {
            "label": "白沙镇",
            "value": "500116108"
          },
          {
            "label": "龙华镇",
            "value": "500116109"
          },
          {
            "label": "李市镇",
            "value": "500116110"
          },
          {
            "label": "慈云镇",
            "value": "500116111"
          },
          {
            "label": "蔡家镇",
            "value": "500116112"
          },
          {
            "label": "中山镇",
            "value": "500116113"
          },
          {
            "label": "嘉平镇",
            "value": "500116114"
          },
          {
            "label": "柏林镇",
            "value": "500116115"
          },
          {
            "label": "先锋镇",
            "value": "500116116"
          },
          {
            "label": "珞璜镇",
            "value": "500116117"
          },
          {
            "label": "贾嗣镇",
            "value": "500116118"
          },
          {
            "label": "夏坝镇",
            "value": "500116119"
          },
          {
            "label": "西湖镇",
            "value": "500116120"
          },
          {
            "label": "杜市镇",
            "value": "500116121"
          },
          {
            "label": "广兴镇",
            "value": "500116122"
          },
          {
            "label": "四面山镇",
            "value": "500116123"
          },
          {
            "label": "支坪镇",
            "value": "500116124"
          },
          {
            "label": "四屏镇",
            "value": "500116125"
          }
        ]
      },
      {
        "label": "合川区",
        "value": "500117",
        "children": [
          {
            "label": "合阳城街道",
            "value": "500117001"
          },
          {
            "label": "钓鱼城街道",
            "value": "500117002"
          },
          {
            "label": "南津街街道",
            "value": "500117003"
          },
          {
            "label": "盐井街道",
            "value": "500117004"
          },
          {
            "label": "草街街道",
            "value": "500117005"
          },
          {
            "label": "云门街道",
            "value": "500117006"
          },
          {
            "label": "大石街道",
            "value": "500117007"
          },
          {
            "label": "沙鱼镇",
            "value": "500117100"
          },
          {
            "label": "官渡镇",
            "value": "500117101"
          },
          {
            "label": "涞滩镇",
            "value": "500117102"
          },
          {
            "label": "肖家镇",
            "value": "500117103"
          },
          {
            "label": "古楼镇",
            "value": "500117104"
          },
          {
            "label": "三庙镇",
            "value": "500117105"
          },
          {
            "label": "二郎镇",
            "value": "500117106"
          },
          {
            "label": "龙凤镇",
            "value": "500117107"
          },
          {
            "label": "隆兴镇",
            "value": "500117108"
          },
          {
            "label": "铜溪镇",
            "value": "500117109"
          },
          {
            "label": "双凤镇",
            "value": "500117112"
          },
          {
            "label": "狮滩镇",
            "value": "500117113"
          },
          {
            "label": "清平镇",
            "value": "500117114"
          },
          {
            "label": "土场镇",
            "value": "500117115"
          },
          {
            "label": "小沔镇",
            "value": "500117116"
          },
          {
            "label": "三汇镇",
            "value": "500117117"
          },
          {
            "label": "香龙镇",
            "value": "500117118"
          },
          {
            "label": "钱塘镇",
            "value": "500117120"
          },
          {
            "label": "龙市镇",
            "value": "500117121"
          },
          {
            "label": "燕窝镇",
            "value": "500117123"
          },
          {
            "label": "太和镇",
            "value": "500117124"
          },
          {
            "label": "渭沱镇",
            "value": "500117125"
          },
          {
            "label": "双槐镇",
            "value": "500117126"
          }
        ]
      },
      {
        "label": "永川区",
        "value": "500118",
        "children": [
          {
            "label": "中山路街道",
            "value": "500118001"
          },
          {
            "label": "胜利路街道",
            "value": "500118002"
          },
          {
            "label": "南大街街道",
            "value": "500118003"
          },
          {
            "label": "茶山竹海街道",
            "value": "500118004"
          },
          {
            "label": "大安街道",
            "value": "500118005"
          },
          {
            "label": "陈食街道",
            "value": "500118006"
          },
          {
            "label": "卫星湖街道",
            "value": "500118007"
          },
          {
            "label": "青峰镇",
            "value": "500118100"
          },
          {
            "label": "金龙镇",
            "value": "500118102"
          },
          {
            "label": "临江镇",
            "value": "500118104"
          },
          {
            "label": "何埂镇",
            "value": "500118106"
          },
          {
            "label": "松溉镇",
            "value": "500118107"
          },
          {
            "label": "仙龙镇",
            "value": "500118108"
          },
          {
            "label": "吉安镇",
            "value": "500118109"
          },
          {
            "label": "五间镇",
            "value": "500118110"
          },
          {
            "label": "来苏镇",
            "value": "500118111"
          },
          {
            "label": "宝峰镇",
            "value": "500118112"
          },
          {
            "label": "双石镇",
            "value": "500118113"
          },
          {
            "label": "红炉镇",
            "value": "500118114"
          },
          {
            "label": "永荣镇",
            "value": "500118115"
          },
          {
            "label": "三教镇",
            "value": "500118116"
          },
          {
            "label": "板桥镇",
            "value": "500118117"
          },
          {
            "label": "朱沱镇",
            "value": "500118118"
          }
        ]
      },
      {
        "label": "南川区",
        "value": "500119",
        "children": [
          {
            "label": "东城街道",
            "value": "500119001"
          },
          {
            "label": "南城街道",
            "value": "500119002"
          },
          {
            "label": "西城街道",
            "value": "500119003"
          },
          {
            "label": "三泉镇",
            "value": "500119100"
          },
          {
            "label": "南平镇",
            "value": "500119101"
          },
          {
            "label": "神童镇",
            "value": "500119102"
          },
          {
            "label": "鸣玉镇",
            "value": "500119103"
          },
          {
            "label": "大观镇",
            "value": "500119104"
          },
          {
            "label": "兴隆镇",
            "value": "500119105"
          },
          {
            "label": "太平场镇",
            "value": "500119106"
          },
          {
            "label": "白沙镇",
            "value": "500119107"
          },
          {
            "label": "水江镇",
            "value": "500119108"
          },
          {
            "label": "石墙镇",
            "value": "500119109"
          },
          {
            "label": "金山镇",
            "value": "500119110"
          },
          {
            "label": "头渡镇",
            "value": "500119111"
          },
          {
            "label": "大有镇",
            "value": "500119112"
          },
          {
            "label": "合溪镇",
            "value": "500119113"
          },
          {
            "label": "黎香湖镇",
            "value": "500119114"
          },
          {
            "label": "山王坪镇",
            "value": "500119115"
          },
          {
            "label": "木凉镇",
            "value": "500119116"
          },
          {
            "label": "楠竹山镇",
            "value": "500119117"
          },
          {
            "label": "石溪镇",
            "value": "500119118"
          },
          {
            "label": "德隆镇",
            "value": "500119119"
          },
          {
            "label": "民主镇",
            "value": "500119120"
          },
          {
            "label": "福寿镇",
            "value": "500119121"
          },
          {
            "label": "河图镇",
            "value": "500119122"
          },
          {
            "label": "庆元镇",
            "value": "500119123"
          },
          {
            "label": "古花镇",
            "value": "500119124"
          },
          {
            "label": "石莲镇",
            "value": "500119125"
          },
          {
            "label": "乾丰镇",
            "value": "500119126"
          },
          {
            "label": "骑龙镇",
            "value": "500119127"
          },
          {
            "label": "冷水关镇",
            "value": "500119128"
          },
          {
            "label": "中桥乡",
            "value": "500119207"
          },
          {
            "label": "峰岩乡",
            "value": "500119212"
          }
        ]
      },
      {
        "label": "璧山区",
        "value": "500120",
        "children": [
          {
            "label": "璧城街道",
            "value": "500120001"
          },
          {
            "label": "璧泉街道",
            "value": "500120002"
          },
          {
            "label": "青杠街道",
            "value": "500120003"
          },
          {
            "label": "来凤街道",
            "value": "500120004"
          },
          {
            "label": "丁家街道",
            "value": "500120005"
          },
          {
            "label": "大路街道",
            "value": "500120006"
          },
          {
            "label": "八塘镇",
            "value": "500120100"
          },
          {
            "label": "七塘镇",
            "value": "500120101"
          },
          {
            "label": "河边镇",
            "value": "500120102"
          },
          {
            "label": "福禄镇",
            "value": "500120103"
          },
          {
            "label": "大兴镇",
            "value": "500120104"
          },
          {
            "label": "正兴镇",
            "value": "500120105"
          },
          {
            "label": "广普镇",
            "value": "500120106"
          },
          {
            "label": "三合镇",
            "value": "500120107"
          },
          {
            "label": "健龙镇",
            "value": "500120108"
          }
        ]
      },
      {
        "label": "铜梁区",
        "value": "500151",
        "children": [
          {
            "label": "巴川街道",
            "value": "500151001"
          },
          {
            "label": "东城街道",
            "value": "500151002"
          },
          {
            "label": "南城街道",
            "value": "500151003"
          },
          {
            "label": "蒲吕街道",
            "value": "500151004"
          },
          {
            "label": "旧县街道",
            "value": "500151005"
          },
          {
            "label": "土桥镇",
            "value": "500151101"
          },
          {
            "label": "二坪镇",
            "value": "500151103"
          },
          {
            "label": "水口镇",
            "value": "500151104"
          },
          {
            "label": "安居镇",
            "value": "500151105"
          },
          {
            "label": "白羊镇",
            "value": "500151106"
          },
          {
            "label": "平滩镇",
            "value": "500151107"
          },
          {
            "label": "小林镇",
            "value": "500151108"
          },
          {
            "label": "双山镇",
            "value": "500151109"
          },
          {
            "label": "虎峰镇",
            "value": "500151110"
          },
          {
            "label": "石鱼镇",
            "value": "500151111"
          },
          {
            "label": "福果镇",
            "value": "500151112"
          },
          {
            "label": "庆隆镇",
            "value": "500151113"
          },
          {
            "label": "少云镇",
            "value": "500151114"
          },
          {
            "label": "维新镇",
            "value": "500151115"
          },
          {
            "label": "高楼镇",
            "value": "500151116"
          },
          {
            "label": "大庙镇",
            "value": "500151117"
          },
          {
            "label": "围龙镇",
            "value": "500151118"
          },
          {
            "label": "华兴镇",
            "value": "500151119"
          },
          {
            "label": "永嘉镇",
            "value": "500151120"
          },
          {
            "label": "安溪镇",
            "value": "500151121"
          },
          {
            "label": "西河镇",
            "value": "500151122"
          },
          {
            "label": "侣俸镇",
            "value": "500151123"
          },
          {
            "label": "太平镇",
            "value": "500151124"
          }
        ]
      },
      {
        "label": "潼南区",
        "value": "500152",
        "children": [
          {
            "label": "桂林街道",
            "value": "500152001"
          },
          {
            "label": "梓潼街道",
            "value": "500152002"
          },
          {
            "label": "上和镇",
            "value": "500152101"
          },
          {
            "label": "龙形镇",
            "value": "500152102"
          },
          {
            "label": "古溪镇",
            "value": "500152103"
          },
          {
            "label": "宝龙镇",
            "value": "500152104"
          },
          {
            "label": "玉溪镇",
            "value": "500152107"
          },
          {
            "label": "米心镇",
            "value": "500152108"
          },
          {
            "label": "群力镇",
            "value": "500152109"
          },
          {
            "label": "双江镇",
            "value": "500152110"
          },
          {
            "label": "花岩镇",
            "value": "500152112"
          },
          {
            "label": "柏梓镇",
            "value": "500152113"
          },
          {
            "label": "崇龛镇",
            "value": "500152114"
          },
          {
            "label": "塘坝镇",
            "value": "500152115"
          },
          {
            "label": "新胜镇",
            "value": "500152116"
          },
          {
            "label": "太安镇",
            "value": "500152117"
          },
          {
            "label": "小渡镇",
            "value": "500152119"
          },
          {
            "label": "卧佛镇",
            "value": "500152120"
          },
          {
            "label": "五桂镇",
            "value": "500152121"
          },
          {
            "label": "田家镇",
            "value": "500152122"
          },
          {
            "label": "别口镇",
            "value": "500152123"
          },
          {
            "label": "寿桥镇",
            "value": "500152124"
          }
        ]
      },
      {
        "label": "荣昌区",
        "value": "500153",
        "children": [
          {
            "label": "昌元街道",
            "value": "500153001"
          },
          {
            "label": "昌州街道",
            "value": "500153002"
          },
          {
            "label": "广顺街道",
            "value": "500153003"
          },
          {
            "label": "双河街道",
            "value": "500153004"
          },
          {
            "label": "安富街道",
            "value": "500153005"
          },
          {
            "label": "峰高街道",
            "value": "500153006"
          },
          {
            "label": "荣隆镇",
            "value": "500153100"
          },
          {
            "label": "仁义镇",
            "value": "500153101"
          },
          {
            "label": "盘龙镇",
            "value": "500153102"
          },
          {
            "label": "吴家镇",
            "value": "500153103"
          },
          {
            "label": "直升镇",
            "value": "500153104"
          },
          {
            "label": "万灵镇",
            "value": "500153105"
          },
          {
            "label": "清升镇",
            "value": "500153106"
          },
          {
            "label": "清江镇",
            "value": "500153107"
          },
          {
            "label": "古昌镇",
            "value": "500153108"
          },
          {
            "label": "河包镇",
            "value": "500153109"
          },
          {
            "label": "观胜镇",
            "value": "500153110"
          },
          {
            "label": "铜鼓镇",
            "value": "500153111"
          },
          {
            "label": "清流镇",
            "value": "500153112"
          },
          {
            "label": "远觉镇",
            "value": "500153113"
          },
          {
            "label": "龙集镇",
            "value": "500153114"
          }
        ]
      },
      {
        "label": "开州区",
        "value": "500154",
        "children": [
          {
            "label": "汉丰街道",
            "value": "500154001"
          },
          {
            "label": "文峰街道",
            "value": "500154002"
          },
          {
            "label": "云枫街道",
            "value": "500154003"
          },
          {
            "label": "镇东街道",
            "value": "500154004"
          },
          {
            "label": "丰乐街道",
            "value": "500154005"
          },
          {
            "label": "白鹤街道",
            "value": "500154006"
          },
          {
            "label": "赵家街道",
            "value": "500154007"
          },
          {
            "label": "大德镇",
            "value": "500154100"
          },
          {
            "label": "镇安镇",
            "value": "500154101"
          },
          {
            "label": "厚坝镇",
            "value": "500154102"
          },
          {
            "label": "金峰镇",
            "value": "500154103"
          },
          {
            "label": "温泉镇",
            "value": "500154104"
          },
          {
            "label": "郭家镇",
            "value": "500154105"
          },
          {
            "label": "白桥镇",
            "value": "500154106"
          },
          {
            "label": "和谦镇",
            "value": "500154107"
          },
          {
            "label": "河堰镇",
            "value": "500154108"
          },
          {
            "label": "大进镇",
            "value": "500154109"
          },
          {
            "label": "谭家镇",
            "value": "500154110"
          },
          {
            "label": "敦好镇",
            "value": "500154111"
          },
          {
            "label": "高桥镇",
            "value": "500154112"
          },
          {
            "label": "九龙山镇",
            "value": "500154113"
          },
          {
            "label": "天和镇",
            "value": "500154114"
          },
          {
            "label": "中和镇",
            "value": "500154115"
          },
          {
            "label": "义和镇",
            "value": "500154116"
          },
          {
            "label": "临江镇",
            "value": "500154117"
          },
          {
            "label": "竹溪镇",
            "value": "500154118"
          },
          {
            "label": "铁桥镇",
            "value": "500154119"
          },
          {
            "label": "南雅镇",
            "value": "500154120"
          },
          {
            "label": "巫山镇",
            "value": "500154121"
          },
          {
            "label": "岳溪镇",
            "value": "500154122"
          },
          {
            "label": "长沙镇",
            "value": "500154123"
          },
          {
            "label": "南门镇",
            "value": "500154124"
          },
          {
            "label": "渠口镇",
            "value": "500154125"
          },
          {
            "label": "满月镇",
            "value": "500154126"
          },
          {
            "label": "雪宝山镇",
            "value": "500154127"
          },
          {
            "label": "关面乡",
            "value": "500154201"
          },
          {
            "label": "麻柳乡",
            "value": "500154203"
          },
          {
            "label": "紫水乡",
            "value": "500154204"
          },
          {
            "label": "三汇口乡",
            "value": "500154205"
          },
          {
            "label": "五通乡",
            "value": "500154206"
          }
        ]
      },
      {
        "label": "梁平区",
        "value": "500155",
        "children": [
          {
            "label": "梁山街道",
            "value": "500155001"
          },
          {
            "label": "双桂街道",
            "value": "500155002"
          },
          {
            "label": "仁贤镇",
            "value": "500155101"
          },
          {
            "label": "礼让镇",
            "value": "500155102"
          },
          {
            "label": "云龙镇",
            "value": "500155103"
          },
          {
            "label": "屏锦镇",
            "value": "500155104"
          },
          {
            "label": "袁驿镇",
            "value": "500155106"
          },
          {
            "label": "新盛镇",
            "value": "500155107"
          },
          {
            "label": "福禄镇",
            "value": "500155108"
          },
          {
            "label": "金带镇",
            "value": "500155109"
          },
          {
            "label": "聚奎镇",
            "value": "500155110"
          },
          {
            "label": "明达镇",
            "value": "500155111"
          },
          {
            "label": "荫平镇",
            "value": "500155112"
          },
          {
            "label": "和林镇",
            "value": "500155113"
          },
          {
            "label": "回龙镇",
            "value": "500155114"
          },
          {
            "label": "碧山镇",
            "value": "500155115"
          },
          {
            "label": "虎城镇",
            "value": "500155116"
          },
          {
            "label": "七星镇",
            "value": "500155117"
          },
          {
            "label": "龙门镇",
            "value": "500155118"
          },
          {
            "label": "文化镇",
            "value": "500155119"
          },
          {
            "label": "合兴镇",
            "value": "500155120"
          },
          {
            "label": "石安镇",
            "value": "500155121"
          },
          {
            "label": "柏家镇",
            "value": "500155122"
          },
          {
            "label": "大观镇",
            "value": "500155123"
          },
          {
            "label": "竹山镇",
            "value": "500155124"
          },
          {
            "label": "蟠龙镇",
            "value": "500155125"
          },
          {
            "label": "星桥镇",
            "value": "500155126"
          },
          {
            "label": "曲水镇",
            "value": "500155127"
          },
          {
            "label": "安胜镇",
            "value": "500155128"
          },
          {
            "label": "复平镇",
            "value": "500155129"
          },
          {
            "label": "紫照镇",
            "value": "500155130"
          },
          {
            "label": "铁门乡",
            "value": "500155201"
          },
          {
            "label": "龙胜乡",
            "value": "500155202"
          }
        ]
      },
      {
        "label": "武隆区",
        "value": "500156",
        "children": [
          {
            "label": "凤山街道",
            "value": "500156001"
          },
          {
            "label": "芙蓉街道",
            "value": "500156002"
          },
          {
            "label": "白马镇",
            "value": "500156101"
          },
          {
            "label": "江口镇",
            "value": "500156102"
          },
          {
            "label": "仙女山镇",
            "value": "500156103"
          },
          {
            "label": "火炉镇",
            "value": "500156104"
          },
          {
            "label": "鸭江镇",
            "value": "500156105"
          },
          {
            "label": "羊角镇",
            "value": "500156106"
          },
          {
            "label": "长坝镇",
            "value": "500156107"
          },
          {
            "label": "平桥镇",
            "value": "500156108"
          },
          {
            "label": "桐梓镇",
            "value": "500156109"
          },
          {
            "label": "土坎镇",
            "value": "500156110"
          },
          {
            "label": "和顺镇",
            "value": "500156111"
          },
          {
            "label": "双河镇",
            "value": "500156112"
          },
          {
            "label": "凤来乡",
            "value": "500156200"
          },
          {
            "label": "庙垭乡",
            "value": "500156201"
          },
          {
            "label": "石桥苗族土家族乡",
            "value": "500156202"
          },
          {
            "label": "黄莺乡",
            "value": "500156203"
          },
          {
            "label": "沧沟乡",
            "value": "500156204"
          },
          {
            "label": "文复苗族土家族乡",
            "value": "500156205"
          },
          {
            "label": "土地乡",
            "value": "500156206"
          },
          {
            "label": "白云乡",
            "value": "500156207"
          },
          {
            "label": "后坪苗族土家族乡",
            "value": "500156208"
          },
          {
            "label": "浩口苗族仡佬族乡",
            "value": "500156209"
          },
          {
            "label": "接龙乡",
            "value": "500156210"
          },
          {
            "label": "赵家乡",
            "value": "500156211"
          },
          {
            "label": "大洞河乡",
            "value": "500156212"
          }
        ]
      },
      {
        "label": "城口县",
        "value": "500229",
        "children": [
          {
            "label": "葛城街道",
            "value": "500229001"
          },
          {
            "label": "复兴街道",
            "value": "500229002"
          },
          {
            "label": "巴山镇",
            "value": "500229102"
          },
          {
            "label": "坪坝镇",
            "value": "500229103"
          },
          {
            "label": "庙坝镇",
            "value": "500229104"
          },
          {
            "label": "明通镇",
            "value": "500229105"
          },
          {
            "label": "修齐镇",
            "value": "500229106"
          },
          {
            "label": "高观镇",
            "value": "500229107"
          },
          {
            "label": "高燕镇",
            "value": "500229108"
          },
          {
            "label": "东安镇",
            "value": "500229109"
          },
          {
            "label": "咸宜镇",
            "value": "500229110"
          },
          {
            "label": "高楠镇",
            "value": "500229111"
          },
          {
            "label": "龙田乡",
            "value": "500229201"
          },
          {
            "label": "北屏乡",
            "value": "500229202"
          },
          {
            "label": "左岚乡",
            "value": "500229205"
          },
          {
            "label": "沿河乡",
            "value": "500229208"
          },
          {
            "label": "双河乡",
            "value": "500229210"
          },
          {
            "label": "蓼子乡",
            "value": "500229211"
          },
          {
            "label": "鸡鸣乡",
            "value": "500229212"
          },
          {
            "label": "周溪乡",
            "value": "500229214"
          },
          {
            "label": "明中乡",
            "value": "500229216"
          },
          {
            "label": "治平乡",
            "value": "500229217"
          },
          {
            "label": "岚天乡",
            "value": "500229219"
          },
          {
            "label": "厚坪乡",
            "value": "500229220"
          },
          {
            "label": "河鱼乡",
            "value": "500229221"
          }
        ]
      },
      {
        "label": "丰都县",
        "value": "500230",
        "children": [
          {
            "label": "三合街道",
            "value": "500230001"
          },
          {
            "label": "名山街道",
            "value": "500230002"
          },
          {
            "label": "虎威镇",
            "value": "500230101"
          },
          {
            "label": "社坛镇",
            "value": "500230102"
          },
          {
            "label": "三元镇",
            "value": "500230103"
          },
          {
            "label": "许明寺镇",
            "value": "500230104"
          },
          {
            "label": "董家镇",
            "value": "500230105"
          },
          {
            "label": "树人镇",
            "value": "500230106"
          },
          {
            "label": "十直镇",
            "value": "500230107"
          },
          {
            "label": "高家镇",
            "value": "500230109"
          },
          {
            "label": "兴义镇",
            "value": "500230110"
          },
          {
            "label": "双路镇",
            "value": "500230111"
          },
          {
            "label": "江池镇",
            "value": "500230112"
          },
          {
            "label": "龙河镇",
            "value": "500230113"
          },
          {
            "label": "武平镇",
            "value": "500230114"
          },
          {
            "label": "包鸾镇",
            "value": "500230115"
          },
          {
            "label": "湛普镇",
            "value": "500230116"
          },
          {
            "label": "南天湖镇",
            "value": "500230118"
          },
          {
            "label": "保合镇",
            "value": "500230119"
          },
          {
            "label": "兴龙镇",
            "value": "500230120"
          },
          {
            "label": "仁沙镇",
            "value": "500230121"
          },
          {
            "label": "龙孔镇",
            "value": "500230122"
          },
          {
            "label": "暨龙镇",
            "value": "500230123"
          },
          {
            "label": "双龙镇",
            "value": "500230124"
          },
          {
            "label": "仙女湖镇",
            "value": "500230125"
          },
          {
            "label": "青龙乡",
            "value": "500230202"
          },
          {
            "label": "太平坝乡",
            "value": "500230206"
          },
          {
            "label": "都督乡",
            "value": "500230207"
          },
          {
            "label": "栗子乡",
            "value": "500230209"
          },
          {
            "label": "三建乡",
            "value": "500230210"
          }
        ]
      },
      {
        "label": "垫江县",
        "value": "500231",
        "children": [
          {
            "label": "桂溪街道",
            "value": "500231001"
          },
          {
            "label": "桂阳街道",
            "value": "500231002"
          },
          {
            "label": "新民镇",
            "value": "500231101"
          },
          {
            "label": "沙坪镇",
            "value": "500231102"
          },
          {
            "label": "周嘉镇",
            "value": "500231103"
          },
          {
            "label": "普顺镇",
            "value": "500231104"
          },
          {
            "label": "永安镇",
            "value": "500231105"
          },
          {
            "label": "高安镇",
            "value": "500231106"
          },
          {
            "label": "高峰镇",
            "value": "500231107"
          },
          {
            "label": "五洞镇",
            "value": "500231108"
          },
          {
            "label": "澄溪镇",
            "value": "500231109"
          },
          {
            "label": "太平镇",
            "value": "500231110"
          },
          {
            "label": "鹤游镇",
            "value": "500231111"
          },
          {
            "label": "坪山镇",
            "value": "500231112"
          },
          {
            "label": "砚台镇",
            "value": "500231113"
          },
          {
            "label": "曹回镇",
            "value": "500231114"
          },
          {
            "label": "杠家镇",
            "value": "500231115"
          },
          {
            "label": "包家镇",
            "value": "500231116"
          },
          {
            "label": "白家镇",
            "value": "500231117"
          },
          {
            "label": "永平镇",
            "value": "500231118"
          },
          {
            "label": "三溪镇",
            "value": "500231119"
          },
          {
            "label": "裴兴镇",
            "value": "500231120"
          },
          {
            "label": "黄沙镇",
            "value": "500231121"
          },
          {
            "label": "长龙镇",
            "value": "500231122"
          },
          {
            "label": "沙河乡",
            "value": "500231202"
          },
          {
            "label": "大石乡",
            "value": "500231204"
          }
        ]
      },
      {
        "label": "忠县",
        "value": "500233",
        "children": [
          {
            "label": "忠州街道",
            "value": "500233001"
          },
          {
            "label": "白公街道",
            "value": "500233002"
          },
          {
            "label": "乌杨街道",
            "value": "500233003"
          },
          {
            "label": "新生街道",
            "value": "500233004"
          },
          {
            "label": "任家镇",
            "value": "500233102"
          },
          {
            "label": "洋渡镇",
            "value": "500233104"
          },
          {
            "label": "东溪镇",
            "value": "500233105"
          },
          {
            "label": "复兴镇",
            "value": "500233106"
          },
          {
            "label": "石宝镇",
            "value": "500233107"
          },
          {
            "label": "汝溪镇",
            "value": "500233108"
          },
          {
            "label": "野鹤镇",
            "value": "500233109"
          },
          {
            "label": "官坝镇",
            "value": "500233110"
          },
          {
            "label": "石黄镇",
            "value": "500233111"
          },
          {
            "label": "马灌镇",
            "value": "500233112"
          },
          {
            "label": "金鸡镇",
            "value": "500233113"
          },
          {
            "label": "新立镇",
            "value": "500233114"
          },
          {
            "label": "双桂镇",
            "value": "500233115"
          },
          {
            "label": "拔山镇",
            "value": "500233116"
          },
          {
            "label": "花桥镇",
            "value": "500233117"
          },
          {
            "label": "永丰镇",
            "value": "500233118"
          },
          {
            "label": "三汇镇",
            "value": "500233119"
          },
          {
            "label": "白石镇",
            "value": "500233120"
          },
          {
            "label": "黄金镇",
            "value": "500233122"
          },
          {
            "label": "善广乡",
            "value": "500233201"
          },
          {
            "label": "石子乡",
            "value": "500233203"
          },
          {
            "label": "磨子土家族乡",
            "value": "500233204"
          },
          {
            "label": "涂井乡",
            "value": "500233206"
          },
          {
            "label": "金声乡",
            "value": "500233208"
          },
          {
            "label": "兴峰乡",
            "value": "500233210"
          }
        ]
      },
      {
        "label": "云阳县",
        "value": "500235",
        "children": [
          {
            "label": "双江街道",
            "value": "500235001"
          },
          {
            "label": "青龙街道",
            "value": "500235002"
          },
          {
            "label": "人和街道",
            "value": "500235003"
          },
          {
            "label": "盘龙街道",
            "value": "500235004"
          },
          {
            "label": "龙角镇",
            "value": "500235105"
          },
          {
            "label": "故陵镇",
            "value": "500235107"
          },
          {
            "label": "红狮镇",
            "value": "500235108"
          },
          {
            "label": "路阳镇",
            "value": "500235115"
          },
          {
            "label": "农坝镇",
            "value": "500235116"
          },
          {
            "label": "渠马镇",
            "value": "500235118"
          },
          {
            "label": "黄石镇",
            "value": "500235121"
          },
          {
            "label": "巴阳镇",
            "value": "500235122"
          },
          {
            "label": "沙市镇",
            "value": "500235123"
          },
          {
            "label": "鱼泉镇",
            "value": "500235124"
          },
          {
            "label": "凤鸣镇",
            "value": "500235125"
          },
          {
            "label": "宝坪镇",
            "value": "500235127"
          },
          {
            "label": "南溪镇",
            "value": "500235128"
          },
          {
            "label": "双土镇",
            "value": "500235129"
          },
          {
            "label": "桑坪镇",
            "value": "500235130"
          },
          {
            "label": "江口镇",
            "value": "500235131"
          },
          {
            "label": "高阳镇",
            "value": "500235132"
          },
          {
            "label": "平安镇",
            "value": "500235133"
          },
          {
            "label": "云阳镇",
            "value": "500235135"
          },
          {
            "label": "云安镇",
            "value": "500235136"
          },
          {
            "label": "栖霞镇",
            "value": "500235137"
          },
          {
            "label": "双龙镇",
            "value": "500235138"
          },
          {
            "label": "泥溪镇",
            "value": "500235139"
          },
          {
            "label": "蔈草镇",
            "value": "500235140"
          },
          {
            "label": "养鹿镇",
            "value": "500235141"
          },
          {
            "label": "水口镇",
            "value": "500235142"
          },
          {
            "label": "堰坪镇",
            "value": "500235143"
          },
          {
            "label": "龙洞镇",
            "value": "500235144"
          },
          {
            "label": "后叶镇",
            "value": "500235145"
          },
          {
            "label": "耀灵镇",
            "value": "500235146"
          },
          {
            "label": "大阳镇",
            "value": "500235147"
          },
          {
            "label": "外郎乡",
            "value": "500235208"
          },
          {
            "label": "新津乡",
            "value": "500235215"
          },
          {
            "label": "普安乡",
            "value": "500235216"
          },
          {
            "label": "洞鹿乡",
            "value": "500235218"
          },
          {
            "label": "石门乡",
            "value": "500235219"
          },
          {
            "label": "上坝乡",
            "value": "500235239"
          },
          {
            "label": "清水土家族自治乡",
            "value": "500235242"
          }
        ]
      },
      {
        "label": "奉节县",
        "value": "500236",
        "children": [
          {
            "label": "永安街道",
            "value": "500236001"
          },
          {
            "label": "鱼复街道",
            "value": "500236002"
          },
          {
            "label": "夔门街道",
            "value": "500236003"
          },
          {
            "label": "白帝镇",
            "value": "500236117"
          },
          {
            "label": "草堂镇",
            "value": "500236118"
          },
          {
            "label": "汾河镇",
            "value": "500236119"
          },
          {
            "label": "康乐镇",
            "value": "500236120"
          },
          {
            "label": "大树镇",
            "value": "500236121"
          },
          {
            "label": "竹园镇",
            "value": "500236122"
          },
          {
            "label": "公平镇",
            "value": "500236123"
          },
          {
            "label": "朱衣镇",
            "value": "500236124"
          },
          {
            "label": "甲高镇",
            "value": "500236125"
          },
          {
            "label": "羊市镇",
            "value": "500236126"
          },
          {
            "label": "吐祥镇",
            "value": "500236127"
          },
          {
            "label": "兴隆镇",
            "value": "500236128"
          },
          {
            "label": "青龙镇",
            "value": "500236129"
          },
          {
            "label": "新民镇",
            "value": "500236130"
          },
          {
            "label": "永乐镇",
            "value": "500236131"
          },
          {
            "label": "安坪镇",
            "value": "500236132"
          },
          {
            "label": "五马镇",
            "value": "500236133"
          },
          {
            "label": "青莲镇",
            "value": "500236134"
          },
          {
            "label": "岩湾乡",
            "value": "500236265"
          },
          {
            "label": "平安乡",
            "value": "500236266"
          },
          {
            "label": "红土乡",
            "value": "500236267"
          },
          {
            "label": "石岗乡",
            "value": "500236269"
          },
          {
            "label": "康坪乡",
            "value": "500236270"
          },
          {
            "label": "太和土家族乡",
            "value": "500236272"
          },
          {
            "label": "鹤峰乡",
            "value": "500236274"
          },
          {
            "label": "冯坪乡",
            "value": "500236275"
          },
          {
            "label": "长安土家族乡",
            "value": "500236276"
          },
          {
            "label": "龙桥土家族乡",
            "value": "500236277"
          },
          {
            "label": "云雾土家族乡",
            "value": "500236278"
          }
        ]
      },
      {
        "label": "巫山县",
        "value": "500237",
        "children": [
          {
            "label": "高唐街道",
            "value": "500237001"
          },
          {
            "label": "龙门街道",
            "value": "500237002"
          },
          {
            "label": "庙宇镇",
            "value": "500237101"
          },
          {
            "label": "大昌镇",
            "value": "500237102"
          },
          {
            "label": "福田镇",
            "value": "500237103"
          },
          {
            "label": "龙溪镇",
            "value": "500237104"
          },
          {
            "label": "双龙镇",
            "value": "500237105"
          },
          {
            "label": "官阳镇",
            "value": "500237106"
          },
          {
            "label": "骡坪镇",
            "value": "500237107"
          },
          {
            "label": "抱龙镇",
            "value": "500237108"
          },
          {
            "label": "官渡镇",
            "value": "500237109"
          },
          {
            "label": "铜鼓镇",
            "value": "500237110"
          },
          {
            "label": "巫峡镇",
            "value": "500237111"
          },
          {
            "label": "红椿乡",
            "value": "500237200"
          },
          {
            "label": "两坪乡",
            "value": "500237207"
          },
          {
            "label": "曲尺乡",
            "value": "500237208"
          },
          {
            "label": "建平乡",
            "value": "500237210"
          },
          {
            "label": "大溪乡",
            "value": "500237211"
          },
          {
            "label": "金坪乡",
            "value": "500237214"
          },
          {
            "label": "平河乡",
            "value": "500237216"
          },
          {
            "label": "当阳乡",
            "value": "500237219"
          },
          {
            "label": "竹贤乡",
            "value": "500237222"
          },
          {
            "label": "三溪乡",
            "value": "500237225"
          },
          {
            "label": "培石乡",
            "value": "500237227"
          },
          {
            "label": "笃坪乡",
            "value": "500237229"
          },
          {
            "label": "邓家乡",
            "value": "500237231"
          }
        ]
      },
      {
        "label": "巫溪县",
        "value": "500238",
        "children": [
          {
            "label": "宁河街道",
            "value": "500238001"
          },
          {
            "label": "柏杨街道",
            "value": "500238002"
          },
          {
            "label": "城厢镇",
            "value": "500238100"
          },
          {
            "label": "凤凰镇",
            "value": "500238101"
          },
          {
            "label": "宁厂镇",
            "value": "500238102"
          },
          {
            "label": "上磺镇",
            "value": "500238103"
          },
          {
            "label": "古路镇",
            "value": "500238104"
          },
          {
            "label": "文峰镇",
            "value": "500238105"
          },
          {
            "label": "徐家镇",
            "value": "500238106"
          },
          {
            "label": "白鹿镇",
            "value": "500238107"
          },
          {
            "label": "尖山镇",
            "value": "500238108"
          },
          {
            "label": "下堡镇",
            "value": "500238109"
          },
          {
            "label": "峰灵镇",
            "value": "500238110"
          },
          {
            "label": "塘坊镇",
            "value": "500238111"
          },
          {
            "label": "朝阳镇",
            "value": "500238112"
          },
          {
            "label": "田坝镇",
            "value": "500238113"
          },
          {
            "label": "通城镇",
            "value": "500238114"
          },
          {
            "label": "菱角镇",
            "value": "500238115"
          },
          {
            "label": "蒲莲镇",
            "value": "500238116"
          },
          {
            "label": "土城镇",
            "value": "500238117"
          },
          {
            "label": "红池坝镇",
            "value": "500238118"
          },
          {
            "label": "胜利乡",
            "value": "500238204"
          },
          {
            "label": "大河乡",
            "value": "500238207"
          },
          {
            "label": "天星乡",
            "value": "500238208"
          },
          {
            "label": "长桂乡",
            "value": "500238210"
          },
          {
            "label": "鱼鳞乡",
            "value": "500238226"
          },
          {
            "label": "乌龙乡",
            "value": "500238227"
          },
          {
            "label": "花台乡",
            "value": "500238237"
          },
          {
            "label": "兰英乡",
            "value": "500238239"
          },
          {
            "label": "双阳乡",
            "value": "500238240"
          },
          {
            "label": "中梁乡",
            "value": "500238242"
          },
          {
            "label": "天元乡",
            "value": "500238243"
          }
        ]
      },
      {
        "label": "石柱土家族自治县",
        "value": "500240",
        "children": [
          {
            "label": "南宾街道",
            "value": "500240001"
          },
          {
            "label": "万安街道",
            "value": "500240002"
          },
          {
            "label": "下路街道",
            "value": "500240003"
          },
          {
            "label": "西沱镇",
            "value": "500240101"
          },
          {
            "label": "悦崃镇",
            "value": "500240103"
          },
          {
            "label": "临溪镇",
            "value": "500240104"
          },
          {
            "label": "黄水镇",
            "value": "500240105"
          },
          {
            "label": "马武镇",
            "value": "500240106"
          },
          {
            "label": "沙子镇",
            "value": "500240107"
          },
          {
            "label": "王场镇",
            "value": "500240108"
          },
          {
            "label": "沿溪镇",
            "value": "500240109"
          },
          {
            "label": "龙沙镇",
            "value": "500240110"
          },
          {
            "label": "鱼池镇",
            "value": "500240111"
          },
          {
            "label": "三河镇",
            "value": "500240112"
          },
          {
            "label": "大歇镇",
            "value": "500240113"
          },
          {
            "label": "桥头镇",
            "value": "500240114"
          },
          {
            "label": "万朝镇",
            "value": "500240115"
          },
          {
            "label": "冷水镇",
            "value": "500240116"
          },
          {
            "label": "黄鹤镇",
            "value": "500240117"
          },
          {
            "label": "枫木镇",
            "value": "500240118"
          },
          {
            "label": "黎场乡",
            "value": "500240203"
          },
          {
            "label": "三星乡",
            "value": "500240204"
          },
          {
            "label": "六塘乡",
            "value": "500240205"
          },
          {
            "label": "三益乡",
            "value": "500240207"
          },
          {
            "label": "王家乡",
            "value": "500240208"
          },
          {
            "label": "河嘴乡",
            "value": "500240209"
          },
          {
            "label": "石家乡",
            "value": "500240210"
          },
          {
            "label": "中益乡",
            "value": "500240213"
          },
          {
            "label": "洗新乡",
            "value": "500240214"
          },
          {
            "label": "龙潭乡",
            "value": "500240216"
          },
          {
            "label": "新乐乡",
            "value": "500240217"
          },
          {
            "label": "金铃乡",
            "value": "500240218"
          },
          {
            "label": "金竹乡",
            "value": "500240219"
          }
        ]
      },
      {
        "label": "秀山土家族苗族自治县",
        "value": "500241",
        "children": [
          {
            "label": "中和街道",
            "value": "500241001"
          },
          {
            "label": "乌杨街道",
            "value": "500241002"
          },
          {
            "label": "平凯街道",
            "value": "500241003"
          },
          {
            "label": "官庄街道",
            "value": "500241004"
          },
          {
            "label": "清溪场镇",
            "value": "500241102"
          },
          {
            "label": "隘口镇",
            "value": "500241103"
          },
          {
            "label": "溶溪镇",
            "value": "500241104"
          },
          {
            "label": "龙池镇",
            "value": "500241106"
          },
          {
            "label": "石堤镇",
            "value": "500241107"
          },
          {
            "label": "峨溶镇",
            "value": "500241108"
          },
          {
            "label": "洪安镇",
            "value": "500241109"
          },
          {
            "label": "雅江镇",
            "value": "500241110"
          },
          {
            "label": "石耶镇",
            "value": "500241111"
          },
          {
            "label": "梅江镇",
            "value": "500241112"
          },
          {
            "label": "兰桥镇",
            "value": "500241113"
          },
          {
            "label": "膏田镇",
            "value": "500241114"
          },
          {
            "label": "溪口镇",
            "value": "500241115"
          },
          {
            "label": "妙泉镇",
            "value": "500241116"
          },
          {
            "label": "宋农镇",
            "value": "500241117"
          },
          {
            "label": "里仁镇",
            "value": "500241118"
          },
          {
            "label": "钟灵镇",
            "value": "500241119"
          },
          {
            "label": "孝溪乡",
            "value": "500241201"
          },
          {
            "label": "海洋乡",
            "value": "500241207"
          },
          {
            "label": "大溪乡",
            "value": "500241208"
          },
          {
            "label": "涌洞乡",
            "value": "500241211"
          },
          {
            "label": "中平乡",
            "value": "500241214"
          },
          {
            "label": "岑溪乡",
            "value": "500241215"
          }
        ]
      },
      {
        "label": "酉阳土家族苗族自治县",
        "value": "500242",
        "children": [
          {
            "label": "桃花源街道",
            "value": "500242001"
          },
          {
            "label": "钟多街道",
            "value": "500242002"
          },
          {
            "label": "龙潭镇",
            "value": "500242101"
          },
          {
            "label": "麻旺镇",
            "value": "500242102"
          },
          {
            "label": "酉酬镇",
            "value": "500242103"
          },
          {
            "label": "大溪镇",
            "value": "500242104"
          },
          {
            "label": "兴隆镇",
            "value": "500242105"
          },
          {
            "label": "黑水镇",
            "value": "500242106"
          },
          {
            "label": "丁市镇",
            "value": "500242107"
          },
          {
            "label": "龚滩镇",
            "value": "500242108"
          },
          {
            "label": "李溪镇",
            "value": "500242109"
          },
          {
            "label": "泔溪镇",
            "value": "500242110"
          },
          {
            "label": "酉水河镇",
            "value": "500242111"
          },
          {
            "label": "苍岭镇",
            "value": "500242112"
          },
          {
            "label": "小河镇",
            "value": "500242113"
          },
          {
            "label": "板溪镇",
            "value": "500242114"
          },
          {
            "label": "涂市镇",
            "value": "500242115"
          },
          {
            "label": "铜鼓镇",
            "value": "500242116"
          },
          {
            "label": "五福镇",
            "value": "500242117"
          },
          {
            "label": "万木镇",
            "value": "500242118"
          },
          {
            "label": "南腰界镇",
            "value": "500242119"
          },
          {
            "label": "可大乡",
            "value": "500242204"
          },
          {
            "label": "偏柏乡",
            "value": "500242205"
          },
          {
            "label": "木叶乡",
            "value": "500242207"
          },
          {
            "label": "毛坝乡",
            "value": "500242208"
          },
          {
            "label": "花田乡",
            "value": "500242209"
          },
          {
            "label": "后坪乡",
            "value": "500242210"
          },
          {
            "label": "天馆乡",
            "value": "500242211"
          },
          {
            "label": "宜居乡",
            "value": "500242212"
          },
          {
            "label": "两罾乡",
            "value": "500242214"
          },
          {
            "label": "板桥乡",
            "value": "500242215"
          },
          {
            "label": "官清乡",
            "value": "500242216"
          },
          {
            "label": "车田乡",
            "value": "500242218"
          },
          {
            "label": "腴地乡",
            "value": "500242219"
          },
          {
            "label": "清泉乡",
            "value": "500242220"
          },
          {
            "label": "庙溪乡",
            "value": "500242221"
          },
          {
            "label": "浪坪乡",
            "value": "500242222"
          },
          {
            "label": "双泉乡",
            "value": "500242223"
          },
          {
            "label": "楠木乡",
            "value": "500242224"
          }
        ]
      },
      {
        "label": "彭水苗族土家族自治县",
        "value": "500243",
        "children": [
          {
            "label": "汉葭街道",
            "value": "500243001"
          },
          {
            "label": "绍庆街道",
            "value": "500243002"
          },
          {
            "label": "靛水街道",
            "value": "500243003"
          },
          {
            "label": "保家镇",
            "value": "500243101"
          },
          {
            "label": "郁山镇",
            "value": "500243102"
          },
          {
            "label": "高谷镇",
            "value": "500243103"
          },
          {
            "label": "桑柘镇",
            "value": "500243104"
          },
          {
            "label": "鹿角镇",
            "value": "500243105"
          },
          {
            "label": "黄家镇",
            "value": "500243106"
          },
          {
            "label": "普子镇",
            "value": "500243107"
          },
          {
            "label": "龙射镇",
            "value": "500243108"
          },
          {
            "label": "连湖镇",
            "value": "500243109"
          },
          {
            "label": "万足镇",
            "value": "500243110"
          },
          {
            "label": "平安镇",
            "value": "500243111"
          },
          {
            "label": "长生镇",
            "value": "500243112"
          },
          {
            "label": "新田镇",
            "value": "500243113"
          },
          {
            "label": "鞍子镇",
            "value": "500243114"
          },
          {
            "label": "太原镇",
            "value": "500243115"
          },
          {
            "label": "龙溪镇",
            "value": "500243116"
          },
          {
            "label": "梅子垭镇",
            "value": "500243117"
          },
          {
            "label": "大同镇",
            "value": "500243118"
          },
          {
            "label": "岩东乡",
            "value": "500243201"
          },
          {
            "label": "鹿鸣乡",
            "value": "500243202"
          },
          {
            "label": "棣棠乡",
            "value": "500243204"
          },
          {
            "label": "三义乡",
            "value": "500243206"
          },
          {
            "label": "联合乡",
            "value": "500243207"
          },
          {
            "label": "石柳乡",
            "value": "500243208"
          },
          {
            "label": "走马乡",
            "value": "500243210"
          },
          {
            "label": "芦塘乡",
            "value": "500243211"
          },
          {
            "label": "乔梓乡",
            "value": "500243213"
          },
          {
            "label": "诸佛乡",
            "value": "500243217"
          },
          {
            "label": "桐楼乡",
            "value": "500243219"
          },
          {
            "label": "善感乡",
            "value": "500243222"
          },
          {
            "label": "双龙乡",
            "value": "500243223"
          },
          {
            "label": "石盘乡",
            "value": "500243224"
          },
          {
            "label": "大垭乡",
            "value": "500243225"
          },
          {
            "label": "润溪乡",
            "value": "500243226"
          },
          {
            "label": "朗溪乡",
            "value": "500243227"
          },
          {
            "label": "龙塘乡",
            "value": "500243228"
          }
        ]
      }
    ]
  },
  {
    "label": "四川省",
    "value": "51",
    "children": [
      {
        "label": "成都市",
        "value": "5101",
        "children": [
          {
            "label": "锦江区",
            "value": "510104"
          },
          {
            "label": "青羊区",
            "value": "510105"
          },
          {
            "label": "金牛区",
            "value": "510106"
          },
          {
            "label": "武侯区",
            "value": "510107"
          },
          {
            "label": "成华区",
            "value": "510108"
          },
          {
            "label": "龙泉驿区",
            "value": "510112"
          },
          {
            "label": "青白江区",
            "value": "510113"
          },
          {
            "label": "新都区",
            "value": "510114"
          },
          {
            "label": "温江区",
            "value": "510115"
          },
          {
            "label": "双流区",
            "value": "510116"
          },
          {
            "label": "郫都区",
            "value": "510117"
          },
          {
            "label": "金堂县",
            "value": "510121"
          },
          {
            "label": "大邑县",
            "value": "510129"
          },
          {
            "label": "蒲江县",
            "value": "510131"
          },
          {
            "label": "新津县",
            "value": "510132"
          },
          {
            "label": "都江堰市",
            "value": "510181"
          },
          {
            "label": "彭州市",
            "value": "510182"
          },
          {
            "label": "邛崃市",
            "value": "510183"
          },
          {
            "label": "崇州市",
            "value": "510184"
          },
          {
            "label": "简阳市",
            "value": "510185"
          }
        ]
      },
      {
        "label": "自贡市",
        "value": "5103",
        "children": [
          {
            "label": "自流井区",
            "value": "510302"
          },
          {
            "label": "贡井区",
            "value": "510303"
          },
          {
            "label": "大安区",
            "value": "510304"
          },
          {
            "label": "沿滩区",
            "value": "510311"
          },
          {
            "label": "荣县",
            "value": "510321"
          },
          {
            "label": "富顺县",
            "value": "510322"
          }
        ]
      },
      {
        "label": "攀枝花市",
        "value": "5104",
        "children": [
          {
            "label": "东区",
            "value": "510402"
          },
          {
            "label": "西区",
            "value": "510403"
          },
          {
            "label": "仁和区",
            "value": "510411"
          },
          {
            "label": "米易县",
            "value": "510421"
          },
          {
            "label": "盐边县",
            "value": "510422"
          }
        ]
      },
      {
        "label": "泸州市",
        "value": "5105",
        "children": [
          {
            "label": "江阳区",
            "value": "510502"
          },
          {
            "label": "纳溪区",
            "value": "510503"
          },
          {
            "label": "龙马潭区",
            "value": "510504"
          },
          {
            "label": "泸县",
            "value": "510521"
          },
          {
            "label": "合江县",
            "value": "510522"
          },
          {
            "label": "叙永县",
            "value": "510524"
          },
          {
            "label": "古蔺县",
            "value": "510525"
          }
        ]
      },
      {
        "label": "德阳市",
        "value": "5106",
        "children": [
          {
            "label": "旌阳区",
            "value": "510603"
          },
          {
            "label": "罗江区",
            "value": "510604"
          },
          {
            "label": "中江县",
            "value": "510623"
          },
          {
            "label": "广汉市",
            "value": "510681"
          },
          {
            "label": "什邡市",
            "value": "510682"
          },
          {
            "label": "绵竹市",
            "value": "510683"
          }
        ]
      },
      {
        "label": "绵阳市",
        "value": "5107",
        "children": [
          {
            "label": "涪城区",
            "value": "510703"
          },
          {
            "label": "游仙区",
            "value": "510704"
          },
          {
            "label": "安州区",
            "value": "510705"
          },
          {
            "label": "三台县",
            "value": "510722"
          },
          {
            "label": "盐亭县",
            "value": "510723"
          },
          {
            "label": "梓潼县",
            "value": "510725"
          },
          {
            "label": "北川羌族自治县",
            "value": "510726"
          },
          {
            "label": "平武县",
            "value": "510727"
          },
          {
            "label": "江油市",
            "value": "510781"
          }
        ]
      },
      {
        "label": "广元市",
        "value": "5108",
        "children": [
          {
            "label": "利州区",
            "value": "510802"
          },
          {
            "label": "昭化区",
            "value": "510811"
          },
          {
            "label": "朝天区",
            "value": "510812"
          },
          {
            "label": "旺苍县",
            "value": "510821"
          },
          {
            "label": "青川县",
            "value": "510822"
          },
          {
            "label": "剑阁县",
            "value": "510823"
          },
          {
            "label": "苍溪县",
            "value": "510824"
          }
        ]
      },
      {
        "label": "遂宁市",
        "value": "5109",
        "children": [
          {
            "label": "船山区",
            "value": "510903"
          },
          {
            "label": "安居区",
            "value": "510904"
          },
          {
            "label": "蓬溪县",
            "value": "510921"
          },
          {
            "label": "大英县",
            "value": "510923"
          },
          {
            "label": "射洪市",
            "value": "510981"
          }
        ]
      },
      {
        "label": "内江市",
        "value": "5110",
        "children": [
          {
            "label": "市中区",
            "value": "511002"
          },
          {
            "label": "东兴区",
            "value": "511011"
          },
          {
            "label": "威远县",
            "value": "511024"
          },
          {
            "label": "资中县",
            "value": "511025"
          },
          {
            "label": "内江经济开发区",
            "value": "511071"
          },
          {
            "label": "隆昌市",
            "value": "511083"
          }
        ]
      },
      {
        "label": "乐山市",
        "value": "5111",
        "children": [
          {
            "label": "市中区",
            "value": "511102"
          },
          {
            "label": "沙湾区",
            "value": "511111"
          },
          {
            "label": "五通桥区",
            "value": "511112"
          },
          {
            "label": "金口河区",
            "value": "511113"
          },
          {
            "label": "犍为县",
            "value": "511123"
          },
          {
            "label": "井研县",
            "value": "511124"
          },
          {
            "label": "夹江县",
            "value": "511126"
          },
          {
            "label": "沐川县",
            "value": "511129"
          },
          {
            "label": "峨边彝族自治县",
            "value": "511132"
          },
          {
            "label": "马边彝族自治县",
            "value": "511133"
          },
          {
            "label": "峨眉山市",
            "value": "511181"
          }
        ]
      },
      {
        "label": "南充市",
        "value": "5113",
        "children": [
          {
            "label": "顺庆区",
            "value": "511302"
          },
          {
            "label": "高坪区",
            "value": "511303"
          },
          {
            "label": "嘉陵区",
            "value": "511304"
          },
          {
            "label": "南部县",
            "value": "511321"
          },
          {
            "label": "营山县",
            "value": "511322"
          },
          {
            "label": "蓬安县",
            "value": "511323"
          },
          {
            "label": "仪陇县",
            "value": "511324"
          },
          {
            "label": "西充县",
            "value": "511325"
          },
          {
            "label": "阆中市",
            "value": "511381"
          }
        ]
      },
      {
        "label": "眉山市",
        "value": "5114",
        "children": [
          {
            "label": "东坡区",
            "value": "511402"
          },
          {
            "label": "彭山区",
            "value": "511403"
          },
          {
            "label": "仁寿县",
            "value": "511421"
          },
          {
            "label": "洪雅县",
            "value": "511423"
          },
          {
            "label": "丹棱县",
            "value": "511424"
          },
          {
            "label": "青神县",
            "value": "511425"
          }
        ]
      },
      {
        "label": "宜宾市",
        "value": "5115",
        "children": [
          {
            "label": "翠屏区",
            "value": "511502"
          },
          {
            "label": "南溪区",
            "value": "511503"
          },
          {
            "label": "叙州区",
            "value": "511504"
          },
          {
            "label": "江安县",
            "value": "511523"
          },
          {
            "label": "长宁县",
            "value": "511524"
          },
          {
            "label": "高县",
            "value": "511525"
          },
          {
            "label": "珙县",
            "value": "511526"
          },
          {
            "label": "筠连县",
            "value": "511527"
          },
          {
            "label": "兴文县",
            "value": "511528"
          },
          {
            "label": "屏山县",
            "value": "511529"
          }
        ]
      },
      {
        "label": "广安市",
        "value": "5116",
        "children": [
          {
            "label": "广安区",
            "value": "511602"
          },
          {
            "label": "前锋区",
            "value": "511603"
          },
          {
            "label": "岳池县",
            "value": "511621"
          },
          {
            "label": "武胜县",
            "value": "511622"
          },
          {
            "label": "邻水县",
            "value": "511623"
          },
          {
            "label": "华蓥市",
            "value": "511681"
          }
        ]
      },
      {
        "label": "达州市",
        "value": "5117",
        "children": [
          {
            "label": "通川区",
            "value": "511702"
          },
          {
            "label": "达川区",
            "value": "511703"
          },
          {
            "label": "宣汉县",
            "value": "511722"
          },
          {
            "label": "开江县",
            "value": "511723"
          },
          {
            "label": "大竹县",
            "value": "511724"
          },
          {
            "label": "渠县",
            "value": "511725"
          },
          {
            "label": "达州经济开发区",
            "value": "511771"
          },
          {
            "label": "万源市",
            "value": "511781"
          }
        ]
      },
      {
        "label": "雅安市",
        "value": "5118",
        "children": [
          {
            "label": "雨城区",
            "value": "511802"
          },
          {
            "label": "名山区",
            "value": "511803"
          },
          {
            "label": "荥经县",
            "value": "511822"
          },
          {
            "label": "汉源县",
            "value": "511823"
          },
          {
            "label": "石棉县",
            "value": "511824"
          },
          {
            "label": "天全县",
            "value": "511825"
          },
          {
            "label": "芦山县",
            "value": "511826"
          },
          {
            "label": "宝兴县",
            "value": "511827"
          }
        ]
      },
      {
        "label": "巴中市",
        "value": "5119",
        "children": [
          {
            "label": "巴州区",
            "value": "511902"
          },
          {
            "label": "恩阳区",
            "value": "511903"
          },
          {
            "label": "通江县",
            "value": "511921"
          },
          {
            "label": "南江县",
            "value": "511922"
          },
          {
            "label": "平昌县",
            "value": "511923"
          },
          {
            "label": "巴中经济开发区",
            "value": "511971"
          }
        ]
      },
      {
        "label": "资阳市",
        "value": "5120",
        "children": [
          {
            "label": "雁江区",
            "value": "512002"
          },
          {
            "label": "安岳县",
            "value": "512021"
          },
          {
            "label": "乐至县",
            "value": "512022"
          }
        ]
      },
      {
        "label": "阿坝藏族羌族自治州",
        "value": "5132",
        "children": [
          {
            "label": "马尔康市",
            "value": "513201"
          },
          {
            "label": "汶川县",
            "value": "513221"
          },
          {
            "label": "理县",
            "value": "513222"
          },
          {
            "label": "茂县",
            "value": "513223"
          },
          {
            "label": "松潘县",
            "value": "513224"
          },
          {
            "label": "九寨沟县",
            "value": "513225"
          },
          {
            "label": "金川县",
            "value": "513226"
          },
          {
            "label": "小金县",
            "value": "513227"
          },
          {
            "label": "黑水县",
            "value": "513228"
          },
          {
            "label": "壤塘县",
            "value": "513230"
          },
          {
            "label": "阿坝县",
            "value": "513231"
          },
          {
            "label": "若尔盖县",
            "value": "513232"
          },
          {
            "label": "红原县",
            "value": "513233"
          }
        ]
      },
      {
        "label": "甘孜藏族自治州",
        "value": "5133",
        "children": [
          {
            "label": "康定市",
            "value": "513301"
          },
          {
            "label": "泸定县",
            "value": "513322"
          },
          {
            "label": "丹巴县",
            "value": "513323"
          },
          {
            "label": "九龙县",
            "value": "513324"
          },
          {
            "label": "雅江县",
            "value": "513325"
          },
          {
            "label": "道孚县",
            "value": "513326"
          },
          {
            "label": "炉霍县",
            "value": "513327"
          },
          {
            "label": "甘孜县",
            "value": "513328"
          },
          {
            "label": "新龙县",
            "value": "513329"
          },
          {
            "label": "德格县",
            "value": "513330"
          },
          {
            "label": "白玉县",
            "value": "513331"
          },
          {
            "label": "石渠县",
            "value": "513332"
          },
          {
            "label": "色达县",
            "value": "513333"
          },
          {
            "label": "理塘县",
            "value": "513334"
          },
          {
            "label": "巴塘县",
            "value": "513335"
          },
          {
            "label": "乡城县",
            "value": "513336"
          },
          {
            "label": "稻城县",
            "value": "513337"
          },
          {
            "label": "得荣县",
            "value": "513338"
          }
        ]
      },
      {
        "label": "凉山彝族自治州",
        "value": "5134",
        "children": [
          {
            "label": "西昌市",
            "value": "513401"
          },
          {
            "label": "木里藏族自治县",
            "value": "513422"
          },
          {
            "label": "盐源县",
            "value": "513423"
          },
          {
            "label": "德昌县",
            "value": "513424"
          },
          {
            "label": "会理县",
            "value": "513425"
          },
          {
            "label": "会东县",
            "value": "513426"
          },
          {
            "label": "宁南县",
            "value": "513427"
          },
          {
            "label": "普格县",
            "value": "513428"
          },
          {
            "label": "布拖县",
            "value": "513429"
          },
          {
            "label": "金阳县",
            "value": "513430"
          },
          {
            "label": "昭觉县",
            "value": "513431"
          },
          {
            "label": "喜德县",
            "value": "513432"
          },
          {
            "label": "冕宁县",
            "value": "513433"
          },
          {
            "label": "越西县",
            "value": "513434"
          },
          {
            "label": "甘洛县",
            "value": "513435"
          },
          {
            "label": "美姑县",
            "value": "513436"
          },
          {
            "label": "雷波县",
            "value": "513437"
          }
        ]
      }
    ]
  },
  {
    "label": "贵州省",
    "value": "52",
    "children": [
      {
        "label": "贵阳市",
        "value": "5201",
        "children": [
          {
            "label": "南明区",
            "value": "520102"
          },
          {
            "label": "云岩区",
            "value": "520103"
          },
          {
            "label": "花溪区",
            "value": "520111"
          },
          {
            "label": "乌当区",
            "value": "520112"
          },
          {
            "label": "白云区",
            "value": "520113"
          },
          {
            "label": "观山湖区",
            "value": "520115"
          },
          {
            "label": "开阳县",
            "value": "520121"
          },
          {
            "label": "息烽县",
            "value": "520122"
          },
          {
            "label": "修文县",
            "value": "520123"
          },
          {
            "label": "清镇市",
            "value": "520181"
          }
        ]
      },
      {
        "label": "六盘水市",
        "value": "5202",
        "children": [
          {
            "label": "钟山区",
            "value": "520201"
          },
          {
            "label": "六枝特区",
            "value": "520203"
          },
          {
            "label": "水城县",
            "value": "520221"
          },
          {
            "label": "盘州市",
            "value": "520281"
          }
        ]
      },
      {
        "label": "遵义市",
        "value": "5203",
        "children": [
          {
            "label": "红花岗区",
            "value": "520302"
          },
          {
            "label": "汇川区",
            "value": "520303"
          },
          {
            "label": "播州区",
            "value": "520304"
          },
          {
            "label": "桐梓县",
            "value": "520322"
          },
          {
            "label": "绥阳县",
            "value": "520323"
          },
          {
            "label": "正安县",
            "value": "520324"
          },
          {
            "label": "道真仡佬族苗族自治县",
            "value": "520325"
          },
          {
            "label": "务川仡佬族苗族自治县",
            "value": "520326"
          },
          {
            "label": "凤冈县",
            "value": "520327"
          },
          {
            "label": "湄潭县",
            "value": "520328"
          },
          {
            "label": "余庆县",
            "value": "520329"
          },
          {
            "label": "习水县",
            "value": "520330"
          },
          {
            "label": "赤水市",
            "value": "520381"
          },
          {
            "label": "仁怀市",
            "value": "520382"
          }
        ]
      },
      {
        "label": "安顺市",
        "value": "5204",
        "children": [
          {
            "label": "西秀区",
            "value": "520402"
          },
          {
            "label": "平坝区",
            "value": "520403"
          },
          {
            "label": "普定县",
            "value": "520422"
          },
          {
            "label": "镇宁布依族苗族自治县",
            "value": "520423"
          },
          {
            "label": "关岭布依族苗族自治县",
            "value": "520424"
          },
          {
            "label": "紫云苗族布依族自治县",
            "value": "520425"
          }
        ]
      },
      {
        "label": "毕节市",
        "value": "5205",
        "children": [
          {
            "label": "七星关区",
            "value": "520502"
          },
          {
            "label": "大方县",
            "value": "520521"
          },
          {
            "label": "黔西县",
            "value": "520522"
          },
          {
            "label": "金沙县",
            "value": "520523"
          },
          {
            "label": "织金县",
            "value": "520524"
          },
          {
            "label": "纳雍县",
            "value": "520525"
          },
          {
            "label": "威宁彝族回族苗族自治县",
            "value": "520526"
          },
          {
            "label": "赫章县",
            "value": "520527"
          }
        ]
      },
      {
        "label": "铜仁市",
        "value": "5206",
        "children": [
          {
            "label": "碧江区",
            "value": "520602"
          },
          {
            "label": "万山区",
            "value": "520603"
          },
          {
            "label": "江口县",
            "value": "520621"
          },
          {
            "label": "玉屏侗族自治县",
            "value": "520622"
          },
          {
            "label": "石阡县",
            "value": "520623"
          },
          {
            "label": "思南县",
            "value": "520624"
          },
          {
            "label": "印江土家族苗族自治县",
            "value": "520625"
          },
          {
            "label": "德江县",
            "value": "520626"
          },
          {
            "label": "沿河土家族自治县",
            "value": "520627"
          },
          {
            "label": "松桃苗族自治县",
            "value": "520628"
          }
        ]
      },
      {
        "label": "黔西南布依族苗族自治州",
        "value": "5223",
        "children": [
          {
            "label": "兴义市",
            "value": "522301"
          },
          {
            "label": "兴仁市",
            "value": "522302"
          },
          {
            "label": "普安县",
            "value": "522323"
          },
          {
            "label": "晴隆县",
            "value": "522324"
          },
          {
            "label": "贞丰县",
            "value": "522325"
          },
          {
            "label": "望谟县",
            "value": "522326"
          },
          {
            "label": "册亨县",
            "value": "522327"
          },
          {
            "label": "安龙县",
            "value": "522328"
          }
        ]
      },
      {
        "label": "黔东南苗族侗族自治州",
        "value": "5226",
        "children": [
          {
            "label": "凯里市",
            "value": "522601"
          },
          {
            "label": "黄平县",
            "value": "522622"
          },
          {
            "label": "施秉县",
            "value": "522623"
          },
          {
            "label": "三穗县",
            "value": "522624"
          },
          {
            "label": "镇远县",
            "value": "522625"
          },
          {
            "label": "岑巩县",
            "value": "522626"
          },
          {
            "label": "天柱县",
            "value": "522627"
          },
          {
            "label": "锦屏县",
            "value": "522628"
          },
          {
            "label": "剑河县",
            "value": "522629"
          },
          {
            "label": "台江县",
            "value": "522630"
          },
          {
            "label": "黎平县",
            "value": "522631"
          },
          {
            "label": "榕江县",
            "value": "522632"
          },
          {
            "label": "从江县",
            "value": "522633"
          },
          {
            "label": "雷山县",
            "value": "522634"
          },
          {
            "label": "麻江县",
            "value": "522635"
          },
          {
            "label": "丹寨县",
            "value": "522636"
          }
        ]
      },
      {
        "label": "黔南布依族苗族自治州",
        "value": "5227",
        "children": [
          {
            "label": "都匀市",
            "value": "522701"
          },
          {
            "label": "福泉市",
            "value": "522702"
          },
          {
            "label": "荔波县",
            "value": "522722"
          },
          {
            "label": "贵定县",
            "value": "522723"
          },
          {
            "label": "瓮安县",
            "value": "522725"
          },
          {
            "label": "独山县",
            "value": "522726"
          },
          {
            "label": "平塘县",
            "value": "522727"
          },
          {
            "label": "罗甸县",
            "value": "522728"
          },
          {
            "label": "长顺县",
            "value": "522729"
          },
          {
            "label": "龙里县",
            "value": "522730"
          },
          {
            "label": "惠水县",
            "value": "522731"
          },
          {
            "label": "三都水族自治县",
            "value": "522732"
          }
        ]
      }
    ]
  },
  {
    "label": "云南省",
    "value": "53",
    "children": [
      {
        "label": "昆明市",
        "value": "5301",
        "children": [
          {
            "label": "五华区",
            "value": "530102"
          },
          {
            "label": "盘龙区",
            "value": "530103"
          },
          {
            "label": "官渡区",
            "value": "530111"
          },
          {
            "label": "西山区",
            "value": "530112"
          },
          {
            "label": "东川区",
            "value": "530113"
          },
          {
            "label": "呈贡区",
            "value": "530114"
          },
          {
            "label": "晋宁区",
            "value": "530115"
          },
          {
            "label": "富民县",
            "value": "530124"
          },
          {
            "label": "宜良县",
            "value": "530125"
          },
          {
            "label": "石林彝族自治县",
            "value": "530126"
          },
          {
            "label": "嵩明县",
            "value": "530127"
          },
          {
            "label": "禄劝彝族苗族自治县",
            "value": "530128"
          },
          {
            "label": "寻甸回族彝族自治县",
            "value": "530129"
          },
          {
            "label": "安宁市",
            "value": "530181"
          }
        ]
      },
      {
        "label": "曲靖市",
        "value": "5303",
        "children": [
          {
            "label": "麒麟区",
            "value": "530302"
          },
          {
            "label": "沾益区",
            "value": "530303"
          },
          {
            "label": "马龙区",
            "value": "530304"
          },
          {
            "label": "陆良县",
            "value": "530322"
          },
          {
            "label": "师宗县",
            "value": "530323"
          },
          {
            "label": "罗平县",
            "value": "530324"
          },
          {
            "label": "富源县",
            "value": "530325"
          },
          {
            "label": "会泽县",
            "value": "530326"
          },
          {
            "label": "宣威市",
            "value": "530381"
          }
        ]
      },
      {
        "label": "玉溪市",
        "value": "5304",
        "children": [
          {
            "label": "红塔区",
            "value": "530402"
          },
          {
            "label": "江川区",
            "value": "530403"
          },
          {
            "label": "澄江县",
            "value": "530422"
          },
          {
            "label": "通海县",
            "value": "530423"
          },
          {
            "label": "华宁县",
            "value": "530424"
          },
          {
            "label": "易门县",
            "value": "530425"
          },
          {
            "label": "峨山彝族自治县",
            "value": "530426"
          },
          {
            "label": "新平彝族傣族自治县",
            "value": "530427"
          },
          {
            "label": "元江哈尼族彝族傣族自治县",
            "value": "530428"
          }
        ]
      },
      {
        "label": "保山市",
        "value": "5305",
        "children": [
          {
            "label": "隆阳区",
            "value": "530502"
          },
          {
            "label": "施甸县",
            "value": "530521"
          },
          {
            "label": "龙陵县",
            "value": "530523"
          },
          {
            "label": "昌宁县",
            "value": "530524"
          },
          {
            "label": "腾冲市",
            "value": "530581"
          }
        ]
      },
      {
        "label": "昭通市",
        "value": "5306",
        "children": [
          {
            "label": "昭阳区",
            "value": "530602"
          },
          {
            "label": "鲁甸县",
            "value": "530621"
          },
          {
            "label": "巧家县",
            "value": "530622"
          },
          {
            "label": "盐津县",
            "value": "530623"
          },
          {
            "label": "大关县",
            "value": "530624"
          },
          {
            "label": "永善县",
            "value": "530625"
          },
          {
            "label": "绥江县",
            "value": "530626"
          },
          {
            "label": "镇雄县",
            "value": "530627"
          },
          {
            "label": "彝良县",
            "value": "530628"
          },
          {
            "label": "威信县",
            "value": "530629"
          },
          {
            "label": "水富市",
            "value": "530681"
          }
        ]
      },
      {
        "label": "丽江市",
        "value": "5307",
        "children": [
          {
            "label": "古城区",
            "value": "530702"
          },
          {
            "label": "玉龙纳西族自治县",
            "value": "530721"
          },
          {
            "label": "永胜县",
            "value": "530722"
          },
          {
            "label": "华坪县",
            "value": "530723"
          },
          {
            "label": "宁蒗彝族自治县",
            "value": "530724"
          }
        ]
      },
      {
        "label": "普洱市",
        "value": "5308",
        "children": [
          {
            "label": "思茅区",
            "value": "530802"
          },
          {
            "label": "宁洱哈尼族彝族自治县",
            "value": "530821"
          },
          {
            "label": "墨江哈尼族自治县",
            "value": "530822"
          },
          {
            "label": "景东彝族自治县",
            "value": "530823"
          },
          {
            "label": "景谷傣族彝族自治县",
            "value": "530824"
          },
          {
            "label": "镇沅彝族哈尼族拉祜族自治县",
            "value": "530825"
          },
          {
            "label": "江城哈尼族彝族自治县",
            "value": "530826"
          },
          {
            "label": "孟连傣族拉祜族佤族自治县",
            "value": "530827"
          },
          {
            "label": "澜沧拉祜族自治县",
            "value": "530828"
          },
          {
            "label": "西盟佤族自治县",
            "value": "530829"
          }
        ]
      },
      {
        "label": "临沧市",
        "value": "5309",
        "children": [
          {
            "label": "临翔区",
            "value": "530902"
          },
          {
            "label": "凤庆县",
            "value": "530921"
          },
          {
            "label": "云县",
            "value": "530922"
          },
          {
            "label": "永德县",
            "value": "530923"
          },
          {
            "label": "镇康县",
            "value": "530924"
          },
          {
            "label": "双江拉祜族佤族布朗族傣族自治县",
            "value": "530925"
          },
          {
            "label": "耿马傣族佤族自治县",
            "value": "530926"
          },
          {
            "label": "沧源佤族自治县",
            "value": "530927"
          }
        ]
      },
      {
        "label": "楚雄彝族自治州",
        "value": "5323",
        "children": [
          {
            "label": "楚雄市",
            "value": "532301"
          },
          {
            "label": "双柏县",
            "value": "532322"
          },
          {
            "label": "牟定县",
            "value": "532323"
          },
          {
            "label": "南华县",
            "value": "532324"
          },
          {
            "label": "姚安县",
            "value": "532325"
          },
          {
            "label": "大姚县",
            "value": "532326"
          },
          {
            "label": "永仁县",
            "value": "532327"
          },
          {
            "label": "元谋县",
            "value": "532328"
          },
          {
            "label": "武定县",
            "value": "532329"
          },
          {
            "label": "禄丰县",
            "value": "532331"
          }
        ]
      },
      {
        "label": "红河哈尼族彝族自治州",
        "value": "5325",
        "children": [
          {
            "label": "个旧市",
            "value": "532501"
          },
          {
            "label": "开远市",
            "value": "532502"
          },
          {
            "label": "蒙自市",
            "value": "532503"
          },
          {
            "label": "弥勒市",
            "value": "532504"
          },
          {
            "label": "屏边苗族自治县",
            "value": "532523"
          },
          {
            "label": "建水县",
            "value": "532524"
          },
          {
            "label": "石屏县",
            "value": "532525"
          },
          {
            "label": "泸西县",
            "value": "532527"
          },
          {
            "label": "元阳县",
            "value": "532528"
          },
          {
            "label": "红河县",
            "value": "532529"
          },
          {
            "label": "金平苗族瑶族傣族自治县",
            "value": "532530"
          },
          {
            "label": "绿春县",
            "value": "532531"
          },
          {
            "label": "河口瑶族自治县",
            "value": "532532"
          }
        ]
      },
      {
        "label": "文山壮族苗族自治州",
        "value": "5326",
        "children": [
          {
            "label": "文山市",
            "value": "532601"
          },
          {
            "label": "砚山县",
            "value": "532622"
          },
          {
            "label": "西畴县",
            "value": "532623"
          },
          {
            "label": "麻栗坡县",
            "value": "532624"
          },
          {
            "label": "马关县",
            "value": "532625"
          },
          {
            "label": "丘北县",
            "value": "532626"
          },
          {
            "label": "广南县",
            "value": "532627"
          },
          {
            "label": "富宁县",
            "value": "532628"
          }
        ]
      },
      {
        "label": "西双版纳傣族自治州",
        "value": "5328",
        "children": [
          {
            "label": "景洪市",
            "value": "532801"
          },
          {
            "label": "勐海县",
            "value": "532822"
          },
          {
            "label": "勐腊县",
            "value": "532823"
          }
        ]
      },
      {
        "label": "大理白族自治州",
        "value": "5329",
        "children": [
          {
            "label": "大理市",
            "value": "532901"
          },
          {
            "label": "漾濞彝族自治县",
            "value": "532922"
          },
          {
            "label": "祥云县",
            "value": "532923"
          },
          {
            "label": "宾川县",
            "value": "532924"
          },
          {
            "label": "弥渡县",
            "value": "532925"
          },
          {
            "label": "南涧彝族自治县",
            "value": "532926"
          },
          {
            "label": "巍山彝族回族自治县",
            "value": "532927"
          },
          {
            "label": "永平县",
            "value": "532928"
          },
          {
            "label": "云龙县",
            "value": "532929"
          },
          {
            "label": "洱源县",
            "value": "532930"
          },
          {
            "label": "剑川县",
            "value": "532931"
          },
          {
            "label": "鹤庆县",
            "value": "532932"
          }
        ]
      },
      {
        "label": "德宏傣族景颇族自治州",
        "value": "5331",
        "children": [
          {
            "label": "瑞丽市",
            "value": "533102"
          },
          {
            "label": "芒市",
            "value": "533103"
          },
          {
            "label": "梁河县",
            "value": "533122"
          },
          {
            "label": "盈江县",
            "value": "533123"
          },
          {
            "label": "陇川县",
            "value": "533124"
          }
        ]
      },
      {
        "label": "怒江傈僳族自治州",
        "value": "5333",
        "children": [
          {
            "label": "泸水市",
            "value": "533301"
          },
          {
            "label": "福贡县",
            "value": "533323"
          },
          {
            "label": "贡山独龙族怒族自治县",
            "value": "533324"
          },
          {
            "label": "兰坪白族普米族自治县",
            "value": "533325"
          }
        ]
      },
      {
        "label": "迪庆藏族自治州",
        "value": "5334",
        "children": [
          {
            "label": "香格里拉市",
            "value": "533401"
          },
          {
            "label": "德钦县",
            "value": "533422"
          },
          {
            "label": "维西傈僳族自治县",
            "value": "533423"
          }
        ]
      }
    ]
  },
  {
    "label": "西藏自治区",
    "value": "54",
    "children": [
      {
        "label": "拉萨市",
        "value": "5401",
        "children": [
          {
            "label": "城关区",
            "value": "540102"
          },
          {
            "label": "堆龙德庆区",
            "value": "540103"
          },
          {
            "label": "达孜区",
            "value": "540104"
          },
          {
            "label": "林周县",
            "value": "540121"
          },
          {
            "label": "当雄县",
            "value": "540122"
          },
          {
            "label": "尼木县",
            "value": "540123"
          },
          {
            "label": "曲水县",
            "value": "540124"
          },
          {
            "label": "墨竹工卡县",
            "value": "540127"
          },
          {
            "label": "格尔木藏青工业园区",
            "value": "540171"
          },
          {
            "label": "拉萨经济技术开发区",
            "value": "540172"
          },
          {
            "label": "西藏文化旅游创意园区",
            "value": "540173"
          },
          {
            "label": "达孜工业园区",
            "value": "540174"
          }
        ]
      },
      {
        "label": "日喀则市",
        "value": "5402",
        "children": [
          {
            "label": "桑珠孜区",
            "value": "540202"
          },
          {
            "label": "南木林县",
            "value": "540221"
          },
          {
            "label": "江孜县",
            "value": "540222"
          },
          {
            "label": "定日县",
            "value": "540223"
          },
          {
            "label": "萨迦县",
            "value": "540224"
          },
          {
            "label": "拉孜县",
            "value": "540225"
          },
          {
            "label": "昂仁县",
            "value": "540226"
          },
          {
            "label": "谢通门县",
            "value": "540227"
          },
          {
            "label": "白朗县",
            "value": "540228"
          },
          {
            "label": "仁布县",
            "value": "540229"
          },
          {
            "label": "康马县",
            "value": "540230"
          },
          {
            "label": "定结县",
            "value": "540231"
          },
          {
            "label": "仲巴县",
            "value": "540232"
          },
          {
            "label": "亚东县",
            "value": "540233"
          },
          {
            "label": "吉隆县",
            "value": "540234"
          },
          {
            "label": "聂拉木县",
            "value": "540235"
          },
          {
            "label": "萨嘎县",
            "value": "540236"
          },
          {
            "label": "岗巴县",
            "value": "540237"
          }
        ]
      },
      {
        "label": "昌都市",
        "value": "5403",
        "children": [
          {
            "label": "卡若区",
            "value": "540302"
          },
          {
            "label": "江达县",
            "value": "540321"
          },
          {
            "label": "贡觉县",
            "value": "540322"
          },
          {
            "label": "类乌齐县",
            "value": "540323"
          },
          {
            "label": "丁青县",
            "value": "540324"
          },
          {
            "label": "察雅县",
            "value": "540325"
          },
          {
            "label": "八宿县",
            "value": "540326"
          },
          {
            "label": "左贡县",
            "value": "540327"
          },
          {
            "label": "芒康县",
            "value": "540328"
          },
          {
            "label": "洛隆县",
            "value": "540329"
          },
          {
            "label": "边坝县",
            "value": "540330"
          }
        ]
      },
      {
        "label": "林芝市",
        "value": "5404",
        "children": [
          {
            "label": "巴宜区",
            "value": "540402"
          },
          {
            "label": "工布江达县",
            "value": "540421"
          },
          {
            "label": "米林县",
            "value": "540422"
          },
          {
            "label": "墨脱县",
            "value": "540423"
          },
          {
            "label": "波密县",
            "value": "540424"
          },
          {
            "label": "察隅县",
            "value": "540425"
          },
          {
            "label": "朗县",
            "value": "540426"
          }
        ]
      },
      {
        "label": "山南市",
        "value": "5405",
        "children": [
          {
            "label": "乃东区",
            "value": "540502"
          },
          {
            "label": "扎囊县",
            "value": "540521"
          },
          {
            "label": "贡嘎县",
            "value": "540522"
          },
          {
            "label": "桑日县",
            "value": "540523"
          },
          {
            "label": "琼结县",
            "value": "540524"
          },
          {
            "label": "曲松县",
            "value": "540525"
          },
          {
            "label": "措美县",
            "value": "540526"
          },
          {
            "label": "洛扎县",
            "value": "540527"
          },
          {
            "label": "加查县",
            "value": "540528"
          },
          {
            "label": "隆子县",
            "value": "540529"
          },
          {
            "label": "错那县",
            "value": "540530"
          },
          {
            "label": "浪卡子县",
            "value": "540531"
          }
        ]
      },
      {
        "label": "那曲市",
        "value": "5406",
        "children": [
          {
            "label": "色尼区",
            "value": "540602"
          },
          {
            "label": "嘉黎县",
            "value": "540621"
          },
          {
            "label": "比如县",
            "value": "540622"
          },
          {
            "label": "聂荣县",
            "value": "540623"
          },
          {
            "label": "安多县",
            "value": "540624"
          },
          {
            "label": "申扎县",
            "value": "540625"
          },
          {
            "label": "索县",
            "value": "540626"
          },
          {
            "label": "班戈县",
            "value": "540627"
          },
          {
            "label": "巴青县",
            "value": "540628"
          },
          {
            "label": "尼玛县",
            "value": "540629"
          },
          {
            "label": "双湖县",
            "value": "540630"
          }
        ]
      },
      {
        "label": "阿里地区",
        "value": "5425",
        "children": [
          {
            "label": "普兰县",
            "value": "542521"
          },
          {
            "label": "札达县",
            "value": "542522"
          },
          {
            "label": "噶尔县",
            "value": "542523"
          },
          {
            "label": "日土县",
            "value": "542524"
          },
          {
            "label": "革吉县",
            "value": "542525"
          },
          {
            "label": "改则县",
            "value": "542526"
          },
          {
            "label": "措勤县",
            "value": "542527"
          }
        ]
      }
    ]
  },
  {
    "label": "陕西省",
    "value": "61",
    "children": [
      {
        "label": "西安市",
        "value": "6101",
        "children": [
          {
            "label": "新城区",
            "value": "610102"
          },
          {
            "label": "碑林区",
            "value": "610103"
          },
          {
            "label": "莲湖区",
            "value": "610104"
          },
          {
            "label": "灞桥区",
            "value": "610111"
          },
          {
            "label": "未央区",
            "value": "610112"
          },
          {
            "label": "雁塔区",
            "value": "610113"
          },
          {
            "label": "阎良区",
            "value": "610114"
          },
          {
            "label": "临潼区",
            "value": "610115"
          },
          {
            "label": "长安区",
            "value": "610116"
          },
          {
            "label": "高陵区",
            "value": "610117"
          },
          {
            "label": "鄠邑区",
            "value": "610118"
          },
          {
            "label": "蓝田县",
            "value": "610122"
          },
          {
            "label": "周至县",
            "value": "610124"
          }
        ]
      },
      {
        "label": "铜川市",
        "value": "6102",
        "children": [
          {
            "label": "王益区",
            "value": "610202"
          },
          {
            "label": "印台区",
            "value": "610203"
          },
          {
            "label": "耀州区",
            "value": "610204"
          },
          {
            "label": "宜君县",
            "value": "610222"
          }
        ]
      },
      {
        "label": "宝鸡市",
        "value": "6103",
        "children": [
          {
            "label": "渭滨区",
            "value": "610302"
          },
          {
            "label": "金台区",
            "value": "610303"
          },
          {
            "label": "陈仓区",
            "value": "610304"
          },
          {
            "label": "凤翔县",
            "value": "610322"
          },
          {
            "label": "岐山县",
            "value": "610323"
          },
          {
            "label": "扶风县",
            "value": "610324"
          },
          {
            "label": "眉县",
            "value": "610326"
          },
          {
            "label": "陇县",
            "value": "610327"
          },
          {
            "label": "千阳县",
            "value": "610328"
          },
          {
            "label": "麟游县",
            "value": "610329"
          },
          {
            "label": "凤县",
            "value": "610330"
          },
          {
            "label": "太白县",
            "value": "610331"
          }
        ]
      },
      {
        "label": "咸阳市",
        "value": "6104",
        "children": [
          {
            "label": "秦都区",
            "value": "610402"
          },
          {
            "label": "杨陵区",
            "value": "610403"
          },
          {
            "label": "渭城区",
            "value": "610404"
          },
          {
            "label": "三原县",
            "value": "610422"
          },
          {
            "label": "泾阳县",
            "value": "610423"
          },
          {
            "label": "乾县",
            "value": "610424"
          },
          {
            "label": "礼泉县",
            "value": "610425"
          },
          {
            "label": "永寿县",
            "value": "610426"
          },
          {
            "label": "长武县",
            "value": "610428"
          },
          {
            "label": "旬邑县",
            "value": "610429"
          },
          {
            "label": "淳化县",
            "value": "610430"
          },
          {
            "label": "武功县",
            "value": "610431"
          },
          {
            "label": "兴平市",
            "value": "610481"
          },
          {
            "label": "彬州市",
            "value": "610482"
          }
        ]
      },
      {
        "label": "渭南市",
        "value": "6105",
        "children": [
          {
            "label": "临渭区",
            "value": "610502"
          },
          {
            "label": "华州区",
            "value": "610503"
          },
          {
            "label": "潼关县",
            "value": "610522"
          },
          {
            "label": "大荔县",
            "value": "610523"
          },
          {
            "label": "合阳县",
            "value": "610524"
          },
          {
            "label": "澄城县",
            "value": "610525"
          },
          {
            "label": "蒲城县",
            "value": "610526"
          },
          {
            "label": "白水县",
            "value": "610527"
          },
          {
            "label": "富平县",
            "value": "610528"
          },
          {
            "label": "韩城市",
            "value": "610581"
          },
          {
            "label": "华阴市",
            "value": "610582"
          }
        ]
      },
      {
        "label": "延安市",
        "value": "6106",
        "children": [
          {
            "label": "宝塔区",
            "value": "610602"
          },
          {
            "label": "安塞区",
            "value": "610603"
          },
          {
            "label": "延长县",
            "value": "610621"
          },
          {
            "label": "延川县",
            "value": "610622"
          },
          {
            "label": "志丹县",
            "value": "610625"
          },
          {
            "label": "吴起县",
            "value": "610626"
          },
          {
            "label": "甘泉县",
            "value": "610627"
          },
          {
            "label": "富县",
            "value": "610628"
          },
          {
            "label": "洛川县",
            "value": "610629"
          },
          {
            "label": "宜川县",
            "value": "610630"
          },
          {
            "label": "黄龙县",
            "value": "610631"
          },
          {
            "label": "黄陵县",
            "value": "610632"
          },
          {
            "label": "子长市",
            "value": "610681"
          }
        ]
      },
      {
        "label": "汉中市",
        "value": "6107",
        "children": [
          {
            "label": "汉台区",
            "value": "610702"
          },
          {
            "label": "南郑区",
            "value": "610703"
          },
          {
            "label": "城固县",
            "value": "610722"
          },
          {
            "label": "洋县",
            "value": "610723"
          },
          {
            "label": "西乡县",
            "value": "610724"
          },
          {
            "label": "勉县",
            "value": "610725"
          },
          {
            "label": "宁强县",
            "value": "610726"
          },
          {
            "label": "略阳县",
            "value": "610727"
          },
          {
            "label": "镇巴县",
            "value": "610728"
          },
          {
            "label": "留坝县",
            "value": "610729"
          },
          {
            "label": "佛坪县",
            "value": "610730"
          }
        ]
      },
      {
        "label": "榆林市",
        "value": "6108",
        "children": [
          {
            "label": "榆阳区",
            "value": "610802"
          },
          {
            "label": "横山区",
            "value": "610803"
          },
          {
            "label": "府谷县",
            "value": "610822"
          },
          {
            "label": "靖边县",
            "value": "610824"
          },
          {
            "label": "定边县",
            "value": "610825"
          },
          {
            "label": "绥德县",
            "value": "610826"
          },
          {
            "label": "米脂县",
            "value": "610827"
          },
          {
            "label": "佳县",
            "value": "610828"
          },
          {
            "label": "吴堡县",
            "value": "610829"
          },
          {
            "label": "清涧县",
            "value": "610830"
          },
          {
            "label": "子洲县",
            "value": "610831"
          },
          {
            "label": "神木市",
            "value": "610881"
          }
        ]
      },
      {
        "label": "安康市",
        "value": "6109",
        "children": [
          {
            "label": "汉滨区",
            "value": "610902"
          },
          {
            "label": "汉阴县",
            "value": "610921"
          },
          {
            "label": "石泉县",
            "value": "610922"
          },
          {
            "label": "宁陕县",
            "value": "610923"
          },
          {
            "label": "紫阳县",
            "value": "610924"
          },
          {
            "label": "岚皋县",
            "value": "610925"
          },
          {
            "label": "平利县",
            "value": "610926"
          },
          {
            "label": "镇坪县",
            "value": "610927"
          },
          {
            "label": "旬阳县",
            "value": "610928"
          },
          {
            "label": "白河县",
            "value": "610929"
          }
        ]
      },
      {
        "label": "商洛市",
        "value": "6110",
        "children": [
          {
            "label": "商州区",
            "value": "611002"
          },
          {
            "label": "洛南县",
            "value": "611021"
          },
          {
            "label": "丹凤县",
            "value": "611022"
          },
          {
            "label": "商南县",
            "value": "611023"
          },
          {
            "label": "山阳县",
            "value": "611024"
          },
          {
            "label": "镇安县",
            "value": "611025"
          },
          {
            "label": "柞水县",
            "value": "611026"
          }
        ]
      }
    ]
  },
  {
    "label": "甘肃省",
    "value": "62",
    "children": [
      {
        "label": "兰州市",
        "value": "6201",
        "children": [
          {
            "label": "城关区",
            "value": "620102"
          },
          {
            "label": "七里河区",
            "value": "620103"
          },
          {
            "label": "西固区",
            "value": "620104"
          },
          {
            "label": "安宁区",
            "value": "620105"
          },
          {
            "label": "红古区",
            "value": "620111"
          },
          {
            "label": "永登县",
            "value": "620121"
          },
          {
            "label": "皋兰县",
            "value": "620122"
          },
          {
            "label": "榆中县",
            "value": "620123"
          },
          {
            "label": "兰州新区",
            "value": "620171"
          }
        ]
      },
      {
        "label": "嘉峪关市",
        "value": "6202",
        "children": [
          {
            "label": "嘉峪关市",
            "value": "620201"
          }
        ]
      },
      {
        "label": "金昌市",
        "value": "6203",
        "children": [
          {
            "label": "金川区",
            "value": "620302"
          },
          {
            "label": "永昌县",
            "value": "620321"
          }
        ]
      },
      {
        "label": "白银市",
        "value": "6204",
        "children": [
          {
            "label": "白银区",
            "value": "620402"
          },
          {
            "label": "平川区",
            "value": "620403"
          },
          {
            "label": "靖远县",
            "value": "620421"
          },
          {
            "label": "会宁县",
            "value": "620422"
          },
          {
            "label": "景泰县",
            "value": "620423"
          }
        ]
      },
      {
        "label": "天水市",
        "value": "6205",
        "children": [
          {
            "label": "秦州区",
            "value": "620502"
          },
          {
            "label": "麦积区",
            "value": "620503"
          },
          {
            "label": "清水县",
            "value": "620521"
          },
          {
            "label": "秦安县",
            "value": "620522"
          },
          {
            "label": "甘谷县",
            "value": "620523"
          },
          {
            "label": "武山县",
            "value": "620524"
          },
          {
            "label": "张家川回族自治县",
            "value": "620525"
          }
        ]
      },
      {
        "label": "武威市",
        "value": "6206",
        "children": [
          {
            "label": "凉州区",
            "value": "620602"
          },
          {
            "label": "民勤县",
            "value": "620621"
          },
          {
            "label": "古浪县",
            "value": "620622"
          },
          {
            "label": "天祝藏族自治县",
            "value": "620623"
          }
        ]
      },
      {
        "label": "张掖市",
        "value": "6207",
        "children": [
          {
            "label": "甘州区",
            "value": "620702"
          },
          {
            "label": "肃南裕固族自治县",
            "value": "620721"
          },
          {
            "label": "民乐县",
            "value": "620722"
          },
          {
            "label": "临泽县",
            "value": "620723"
          },
          {
            "label": "高台县",
            "value": "620724"
          },
          {
            "label": "山丹县",
            "value": "620725"
          }
        ]
      },
      {
        "label": "平凉市",
        "value": "6208",
        "children": [
          {
            "label": "崆峒区",
            "value": "620802"
          },
          {
            "label": "泾川县",
            "value": "620821"
          },
          {
            "label": "灵台县",
            "value": "620822"
          },
          {
            "label": "崇信县",
            "value": "620823"
          },
          {
            "label": "庄浪县",
            "value": "620825"
          },
          {
            "label": "静宁县",
            "value": "620826"
          },
          {
            "label": "华亭市",
            "value": "620881"
          }
        ]
      },
      {
        "label": "酒泉市",
        "value": "6209",
        "children": [
          {
            "label": "肃州区",
            "value": "620902"
          },
          {
            "label": "金塔县",
            "value": "620921"
          },
          {
            "label": "瓜州县",
            "value": "620922"
          },
          {
            "label": "肃北蒙古族自治县",
            "value": "620923"
          },
          {
            "label": "阿克塞哈萨克族自治县",
            "value": "620924"
          },
          {
            "label": "玉门市",
            "value": "620981"
          },
          {
            "label": "敦煌市",
            "value": "620982"
          }
        ]
      },
      {
        "label": "庆阳市",
        "value": "6210",
        "children": [
          {
            "label": "西峰区",
            "value": "621002"
          },
          {
            "label": "庆城县",
            "value": "621021"
          },
          {
            "label": "环县",
            "value": "621022"
          },
          {
            "label": "华池县",
            "value": "621023"
          },
          {
            "label": "合水县",
            "value": "621024"
          },
          {
            "label": "正宁县",
            "value": "621025"
          },
          {
            "label": "宁县",
            "value": "621026"
          },
          {
            "label": "镇原县",
            "value": "621027"
          }
        ]
      },
      {
        "label": "定西市",
        "value": "6211",
        "children": [
          {
            "label": "安定区",
            "value": "621102"
          },
          {
            "label": "通渭县",
            "value": "621121"
          },
          {
            "label": "陇西县",
            "value": "621122"
          },
          {
            "label": "渭源县",
            "value": "621123"
          },
          {
            "label": "临洮县",
            "value": "621124"
          },
          {
            "label": "漳县",
            "value": "621125"
          },
          {
            "label": "岷县",
            "value": "621126"
          }
        ]
      },
      {
        "label": "陇南市",
        "value": "6212",
        "children": [
          {
            "label": "武都区",
            "value": "621202"
          },
          {
            "label": "成县",
            "value": "621221"
          },
          {
            "label": "文县",
            "value": "621222"
          },
          {
            "label": "宕昌县",
            "value": "621223"
          },
          {
            "label": "康县",
            "value": "621224"
          },
          {
            "label": "西和县",
            "value": "621225"
          },
          {
            "label": "礼县",
            "value": "621226"
          },
          {
            "label": "徽县",
            "value": "621227"
          },
          {
            "label": "两当县",
            "value": "621228"
          }
        ]
      },
      {
        "label": "临夏回族自治州",
        "value": "6229",
        "children": [
          {
            "label": "临夏市",
            "value": "622901"
          },
          {
            "label": "临夏县",
            "value": "622921"
          },
          {
            "label": "康乐县",
            "value": "622922"
          },
          {
            "label": "永靖县",
            "value": "622923"
          },
          {
            "label": "广河县",
            "value": "622924"
          },
          {
            "label": "和政县",
            "value": "622925"
          },
          {
            "label": "东乡族自治县",
            "value": "622926"
          },
          {
            "label": "积石山保安族东乡族撒拉族自治县",
            "value": "622927"
          }
        ]
      },
      {
        "label": "甘南藏族自治州",
        "value": "6230",
        "children": [
          {
            "label": "合作市",
            "value": "623001"
          },
          {
            "label": "临潭县",
            "value": "623021"
          },
          {
            "label": "卓尼县",
            "value": "623022"
          },
          {
            "label": "舟曲县",
            "value": "623023"
          },
          {
            "label": "迭部县",
            "value": "623024"
          },
          {
            "label": "玛曲县",
            "value": "623025"
          },
          {
            "label": "碌曲县",
            "value": "623026"
          },
          {
            "label": "夏河县",
            "value": "623027"
          }
        ]
      }
    ]
  },
  {
    "label": "青海省",
    "value": "63",
    "children": [
      {
        "label": "西宁市",
        "value": "6301",
        "children": [
          {
            "label": "城东区",
            "value": "630102"
          },
          {
            "label": "城中区",
            "value": "630103"
          },
          {
            "label": "城西区",
            "value": "630104"
          },
          {
            "label": "城北区",
            "value": "630105"
          },
          {
            "label": "大通回族土族自治县",
            "value": "630121"
          },
          {
            "label": "湟中县",
            "value": "630122"
          },
          {
            "label": "湟源县",
            "value": "630123"
          }
        ]
      },
      {
        "label": "海东市",
        "value": "6302",
        "children": [
          {
            "label": "乐都区",
            "value": "630202"
          },
          {
            "label": "平安区",
            "value": "630203"
          },
          {
            "label": "民和回族土族自治县",
            "value": "630222"
          },
          {
            "label": "互助土族自治县",
            "value": "630223"
          },
          {
            "label": "化隆回族自治县",
            "value": "630224"
          },
          {
            "label": "循化撒拉族自治县",
            "value": "630225"
          }
        ]
      },
      {
        "label": "海北藏族自治州",
        "value": "6322",
        "children": [
          {
            "label": "门源回族自治县",
            "value": "632221"
          },
          {
            "label": "祁连县",
            "value": "632222"
          },
          {
            "label": "海晏县",
            "value": "632223"
          },
          {
            "label": "刚察县",
            "value": "632224"
          }
        ]
      },
      {
        "label": "黄南藏族自治州",
        "value": "6323",
        "children": [
          {
            "label": "同仁县",
            "value": "632321"
          },
          {
            "label": "尖扎县",
            "value": "632322"
          },
          {
            "label": "泽库县",
            "value": "632323"
          },
          {
            "label": "河南蒙古族自治县",
            "value": "632324"
          }
        ]
      },
      {
        "label": "海南藏族自治州",
        "value": "6325",
        "children": [
          {
            "label": "共和县",
            "value": "632521"
          },
          {
            "label": "同德县",
            "value": "632522"
          },
          {
            "label": "贵德县",
            "value": "632523"
          },
          {
            "label": "兴海县",
            "value": "632524"
          },
          {
            "label": "贵南县",
            "value": "632525"
          }
        ]
      },
      {
        "label": "果洛藏族自治州",
        "value": "6326",
        "children": [
          {
            "label": "玛沁县",
            "value": "632621"
          },
          {
            "label": "班玛县",
            "value": "632622"
          },
          {
            "label": "甘德县",
            "value": "632623"
          },
          {
            "label": "达日县",
            "value": "632624"
          },
          {
            "label": "久治县",
            "value": "632625"
          },
          {
            "label": "玛多县",
            "value": "632626"
          }
        ]
      },
      {
        "label": "玉树藏族自治州",
        "value": "6327",
        "children": [
          {
            "label": "玉树市",
            "value": "632701"
          },
          {
            "label": "杂多县",
            "value": "632722"
          },
          {
            "label": "称多县",
            "value": "632723"
          },
          {
            "label": "治多县",
            "value": "632724"
          },
          {
            "label": "囊谦县",
            "value": "632725"
          },
          {
            "label": "曲麻莱县",
            "value": "632726"
          }
        ]
      },
      {
        "label": "海西蒙古族藏族自治州",
        "value": "6328",
        "children": [
          {
            "label": "格尔木市",
            "value": "632801"
          },
          {
            "label": "德令哈市",
            "value": "632802"
          },
          {
            "label": "茫崖市",
            "value": "632803"
          },
          {
            "label": "乌兰县",
            "value": "632821"
          },
          {
            "label": "都兰县",
            "value": "632822"
          },
          {
            "label": "天峻县",
            "value": "632823"
          },
          {
            "label": "大柴旦行政委员会",
            "value": "632857"
          }
        ]
      }
    ]
  },
  {
    "label": "宁夏回族自治区",
    "value": "64",
    "children": [
      {
        "label": "银川市",
        "value": "6401",
        "children": [
          {
            "label": "兴庆区",
            "value": "640104"
          },
          {
            "label": "西夏区",
            "value": "640105"
          },
          {
            "label": "金凤区",
            "value": "640106"
          },
          {
            "label": "永宁县",
            "value": "640121"
          },
          {
            "label": "贺兰县",
            "value": "640122"
          },
          {
            "label": "灵武市",
            "value": "640181"
          }
        ]
      },
      {
        "label": "石嘴山市",
        "value": "6402",
        "children": [
          {
            "label": "大武口区",
            "value": "640202"
          },
          {
            "label": "惠农区",
            "value": "640205"
          },
          {
            "label": "平罗县",
            "value": "640221"
          }
        ]
      },
      {
        "label": "吴忠市",
        "value": "6403",
        "children": [
          {
            "label": "利通区",
            "value": "640302"
          },
          {
            "label": "红寺堡区",
            "value": "640303"
          },
          {
            "label": "盐池县",
            "value": "640323"
          },
          {
            "label": "同心县",
            "value": "640324"
          },
          {
            "label": "青铜峡市",
            "value": "640381"
          }
        ]
      },
      {
        "label": "固原市",
        "value": "6404",
        "children": [
          {
            "label": "原州区",
            "value": "640402"
          },
          {
            "label": "西吉县",
            "value": "640422"
          },
          {
            "label": "隆德县",
            "value": "640423"
          },
          {
            "label": "泾源县",
            "value": "640424"
          },
          {
            "label": "彭阳县",
            "value": "640425"
          }
        ]
      },
      {
        "label": "中卫市",
        "value": "6405",
        "children": [
          {
            "label": "沙坡头区",
            "value": "640502"
          },
          {
            "label": "中宁县",
            "value": "640521"
          },
          {
            "label": "海原县",
            "value": "640522"
          }
        ]
      }
    ]
  },
  {
    "label": "新疆维吾尔自治区",
    "value": "65",
    "children": [
      {
        "label": "乌鲁木齐市",
        "value": "6501",
        "children": [
          {
            "label": "天山区",
            "value": "650102"
          },
          {
            "label": "沙依巴克区",
            "value": "650103"
          },
          {
            "label": "新市区",
            "value": "650104"
          },
          {
            "label": "水磨沟区",
            "value": "650105"
          },
          {
            "label": "头屯河区",
            "value": "650106"
          },
          {
            "label": "达坂城区",
            "value": "650107"
          },
          {
            "label": "米东区",
            "value": "650109"
          },
          {
            "label": "乌鲁木齐县",
            "value": "650121"
          }
        ]
      },
      {
        "label": "克拉玛依市",
        "value": "6502",
        "children": [
          {
            "label": "独山子区",
            "value": "650202"
          },
          {
            "label": "克拉玛依区",
            "value": "650203"
          },
          {
            "label": "白碱滩区",
            "value": "650204"
          },
          {
            "label": "乌尔禾区",
            "value": "650205"
          }
        ]
      },
      {
        "label": "吐鲁番市",
        "value": "6504",
        "children": [
          {
            "label": "高昌区",
            "value": "650402"
          },
          {
            "label": "鄯善县",
            "value": "650421"
          },
          {
            "label": "托克逊县",
            "value": "650422"
          }
        ]
      },
      {
        "label": "哈密市",
        "value": "6505",
        "children": [
          {
            "label": "伊州区",
            "value": "650502"
          },
          {
            "label": "巴里坤哈萨克自治县",
            "value": "650521"
          },
          {
            "label": "伊吾县",
            "value": "650522"
          }
        ]
      },
      {
        "label": "昌吉回族自治州",
        "value": "6523",
        "children": [
          {
            "label": "昌吉市",
            "value": "652301"
          },
          {
            "label": "阜康市",
            "value": "652302"
          },
          {
            "label": "呼图壁县",
            "value": "652323"
          },
          {
            "label": "玛纳斯县",
            "value": "652324"
          },
          {
            "label": "奇台县",
            "value": "652325"
          },
          {
            "label": "吉木萨尔县",
            "value": "652327"
          },
          {
            "label": "木垒哈萨克自治县",
            "value": "652328"
          }
        ]
      },
      {
        "label": "博尔塔拉蒙古自治州",
        "value": "6527",
        "children": [
          {
            "label": "博乐市",
            "value": "652701"
          },
          {
            "label": "阿拉山口市",
            "value": "652702"
          },
          {
            "label": "精河县",
            "value": "652722"
          },
          {
            "label": "温泉县",
            "value": "652723"
          }
        ]
      },
      {
        "label": "巴音郭楞蒙古自治州",
        "value": "6528",
        "children": [
          {
            "label": "库尔勒市",
            "value": "652801"
          },
          {
            "label": "轮台县",
            "value": "652822"
          },
          {
            "label": "尉犁县",
            "value": "652823"
          },
          {
            "label": "若羌县",
            "value": "652824"
          },
          {
            "label": "且末县",
            "value": "652825"
          },
          {
            "label": "焉耆回族自治县",
            "value": "652826"
          },
          {
            "label": "和静县",
            "value": "652827"
          },
          {
            "label": "和硕县",
            "value": "652828"
          },
          {
            "label": "博湖县",
            "value": "652829"
          },
          {
            "label": "库尔勒经济技术开发区",
            "value": "652871"
          }
        ]
      },
      {
        "label": "阿克苏地区",
        "value": "6529",
        "children": [
          {
            "label": "阿克苏市",
            "value": "652901"
          },
          {
            "label": "温宿县",
            "value": "652922"
          },
          {
            "label": "库车县",
            "value": "652923"
          },
          {
            "label": "沙雅县",
            "value": "652924"
          },
          {
            "label": "新和县",
            "value": "652925"
          },
          {
            "label": "拜城县",
            "value": "652926"
          },
          {
            "label": "乌什县",
            "value": "652927"
          },
          {
            "label": "阿瓦提县",
            "value": "652928"
          },
          {
            "label": "柯坪县",
            "value": "652929"
          }
        ]
      },
      {
        "label": "克孜勒苏柯尔克孜自治州",
        "value": "6530",
        "children": [
          {
            "label": "阿图什市",
            "value": "653001"
          },
          {
            "label": "阿克陶县",
            "value": "653022"
          },
          {
            "label": "阿合奇县",
            "value": "653023"
          },
          {
            "label": "乌恰县",
            "value": "653024"
          }
        ]
      },
      {
        "label": "喀什地区",
        "value": "6531",
        "children": [
          {
            "label": "喀什市",
            "value": "653101"
          },
          {
            "label": "疏附县",
            "value": "653121"
          },
          {
            "label": "疏勒县",
            "value": "653122"
          },
          {
            "label": "英吉沙县",
            "value": "653123"
          },
          {
            "label": "泽普县",
            "value": "653124"
          },
          {
            "label": "莎车县",
            "value": "653125"
          },
          {
            "label": "叶城县",
            "value": "653126"
          },
          {
            "label": "麦盖提县",
            "value": "653127"
          },
          {
            "label": "岳普湖县",
            "value": "653128"
          },
          {
            "label": "伽师县",
            "value": "653129"
          },
          {
            "label": "巴楚县",
            "value": "653130"
          },
          {
            "label": "塔什库尔干塔吉克自治县",
            "value": "653131"
          }
        ]
      },
      {
        "label": "和田地区",
        "value": "6532",
        "children": [
          {
            "label": "和田市",
            "value": "653201"
          },
          {
            "label": "和田县",
            "value": "653221"
          },
          {
            "label": "墨玉县",
            "value": "653222"
          },
          {
            "label": "皮山县",
            "value": "653223"
          },
          {
            "label": "洛浦县",
            "value": "653224"
          },
          {
            "label": "策勒县",
            "value": "653225"
          },
          {
            "label": "于田县",
            "value": "653226"
          },
          {
            "label": "民丰县",
            "value": "653227"
          }
        ]
      },
      {
        "label": "伊犁哈萨克自治州",
        "value": "6540",
        "children": [
          {
            "label": "伊宁市",
            "value": "654002"
          },
          {
            "label": "奎屯市",
            "value": "654003"
          },
          {
            "label": "霍尔果斯市",
            "value": "654004"
          },
          {
            "label": "伊宁县",
            "value": "654021"
          },
          {
            "label": "察布查尔锡伯自治县",
            "value": "654022"
          },
          {
            "label": "霍城县",
            "value": "654023"
          },
          {
            "label": "巩留县",
            "value": "654024"
          },
          {
            "label": "新源县",
            "value": "654025"
          },
          {
            "label": "昭苏县",
            "value": "654026"
          },
          {
            "label": "特克斯县",
            "value": "654027"
          },
          {
            "label": "尼勒克县",
            "value": "654028"
          }
        ]
      },
      {
        "label": "塔城地区",
        "value": "6542",
        "children": [
          {
            "label": "塔城市",
            "value": "654201"
          },
          {
            "label": "乌苏市",
            "value": "654202"
          },
          {
            "label": "额敏县",
            "value": "654221"
          },
          {
            "label": "沙湾县",
            "value": "654223"
          },
          {
            "label": "托里县",
            "value": "654224"
          },
          {
            "label": "裕民县",
            "value": "654225"
          },
          {
            "label": "和布克赛尔蒙古自治县",
            "value": "654226"
          }
        ]
      },
      {
        "label": "阿勒泰地区",
        "value": "6543",
        "children": [
          {
            "label": "阿勒泰市",
            "value": "654301"
          },
          {
            "label": "布尔津县",
            "value": "654321"
          },
          {
            "label": "富蕴县",
            "value": "654322"
          },
          {
            "label": "福海县",
            "value": "654323"
          },
          {
            "label": "哈巴河县",
            "value": "654324"
          },
          {
            "label": "青河县",
            "value": "654325"
          },
          {
            "label": "吉木乃县",
            "value": "654326"
          }
        ]
      },
      {
        "label": "自治区直辖县级行政区划",
        "value": "6590",
        "children": [
          {
            "label": "石河子市",
            "value": "659001"
          },
          {
            "label": "阿拉尔市",
            "value": "659002"
          },
          {
            "label": "图木舒克市",
            "value": "659003"
          },
          {
            "label": "五家渠市",
            "value": "659004"
          },
          {
            "label": "铁门关市",
            "value": "659006"
          }
        ]
      }
    ]
  },
  {
    "label": "中国台湾",
    "value": 66,
    "children": [
      {
        "label": "台北市",
        "value": "6601",
        "children": [
          {
            "label": "天山区",
            "value": "660101"
          },
          {
            "label": "大同区",
            "value": "660102"
          },
          {
            "label": "中山区",
            "value": "660103"
          },
          {
            "label": "松山区",
            "value": "660104"
          },
          {
            "label": "大安区",
            "value": "660105"
          },
          {
            "label": "万华区",
            "value": "660106"
          },
          {
            "label": "信义区",
            "value": "660107"
          },
          {
            "label": "士林区",
            "value": "660108"
          },
          {
            "label": "北投区",
            "value": "660109"
          },
          {
            "label": "内湖区",
            "value": "660110"
          },
          {
            "label": "南港区",
            "value": "660111"
          },
          {
            "label": "文山区",
            "value": "660112"
          },
          {
            "label": "其它区",
            "value": "660113"
          }
        ]
      },
      {
        "label": "高雄市",
        "value": "6602",
        "children": [
          {
            "label": "新兴区",
            "value": "660201"
          },
          {
            "label": "前金区",
            "value": "660202"
          },
          {
            "label": "芩雅区",
            "value": "660203"
          },
          {
            "label": "盐埕区",
            "value": "660204"
          },
          {
            "label": "鼓山区",
            "value": "660205"
          },
          {
            "label": "旗津区",
            "value": "660206"
          },
          {
            "label": "前镇区",
            "value": "660207"
          },
          {
            "label": "三民区",
            "value": "660208"
          },
          {
            "label": "左营区",
            "value": "660209"
          },
          {
            "label": "楠梓区",
            "value": "660210"
          },
          {
            "label": "小港区",
            "value": "660211"
          },
          {
            "label": "其它区",
            "value": "660212"
          },
          {
            "label": "苓雅区",
            "value": "660213"
          },
          {
            "label": "仁武区",
            "value": "660214"
          },
          {
            "label": "大社区",
            "value": "660215"
          },
          {
            "label": "冈山区",
            "value": "660216"
          },
          {
            "label": "路竹区",
            "value": "660217"
          },
          {
            "label": "阿莲区",
            "value": "660218"
          },
          {
            "label": "填寮区",
            "value": "660219"
          },
          {
            "label": "燕巢区",
            "value": "660220"
          },
          {
            "label": "桥头区",
            "value": "660221"
          },
          {
            "label": "梓官区",
            "value": "660222"
          },
          {
            "label": "弥陀区",
            "value": "660223"
          },
          {
            "label": "永安区",
            "value": "660224"
          },
          {
            "label": "湖内区",
            "value": "660225"
          },
          {
            "label": "凤山区",
            "value": "660226"
          },
          {
            "label": "大寮区",
            "value": "660227"
          },
          {
            "label": "林园区",
            "value": "660228"
          },
          {
            "label": "鸟松区",
            "value": "660229"
          },
          {
            "label": "大树区",
            "value": "660230"
          },
          {
            "label": "旗山区",
            "value": "660231"
          },
          {
            "label": "美浓区",
            "value": "660232"
          },
          {
            "label": "六龟区",
            "value": "660233"
          },
          {
            "label": "内门区",
            "value": "660234"
          },
          {
            "label": "杉林区",
            "value": "660235"
          },
          {
            "label": "甲仙区",
            "value": "660236"
          },
          {
            "label": "桃源区",
            "value": "660237"
          },
          {
            "label": "那玛夏区",
            "value": "660238"
          },
          {
            "label": "茂林区",
            "value": "660239"
          },
          {
            "label": "茄萣区",
            "value": "660240"
          }
        ]
      },
      {
        "label": "台南市",
        "value": "6603",
        "children": [
          {
            "label": "中西区",
            "value": "660301"
          },
          {
            "label": "东区",
            "value": "660302"
          },
          {
            "label": "南区",
            "value": "660303"
          },
          {
            "label": "北区",
            "value": "660304"
          },
          {
            "label": "安平区",
            "value": "660305"
          },
          {
            "label": "安南区",
            "value": "660306"
          },
          {
            "label": "其他区",
            "value": "660307"
          },
          {
            "label": "永康区",
            "value": "660308"
          },
          {
            "label": "归仁区",
            "value": "660309"
          },
          {
            "label": "新化区",
            "value": "660310"
          },
          {
            "label": "左镇区",
            "value": "660311"
          },
          {
            "label": "玉井区",
            "value": "660312"
          },
          {
            "label": "楠西区",
            "value": "660313"
          },
          {
            "label": "南化区",
            "value": "660314"
          },
          {
            "label": "仁德区",
            "value": "660315"
          },
          {
            "label": "关庙区",
            "value": "660316"
          },
          {
            "label": "龙崎区",
            "value": "660317"
          },
          {
            "label": "管田区",
            "value": "660318"
          },
          {
            "label": "麻豆区",
            "value": "660319"
          },
          {
            "label": "佳里区",
            "value": "660320"
          },
          {
            "label": "西港区",
            "value": "660321"
          },
          {
            "label": "七股区",
            "value": "660322"
          },
          {
            "label": "将军区",
            "value": "660323"
          },
          {
            "label": "学甲区",
            "value": "660324"
          },
          {
            "label": "北门区",
            "value": "660325"
          },
          {
            "label": "新营区",
            "value": "660326"
          },
          {
            "label": "后壁区",
            "value": "660327"
          },
          {
            "label": "白河区",
            "value": "660328"
          },
          {
            "label": "东山区",
            "value": "660329"
          },
          {
            "label": "六甲区",
            "value": "660330"
          },
          {
            "label": "下营区",
            "value": "660331"
          },
          {
            "label": "柳营区",
            "value": "660332"
          },
          {
            "label": "盐水区",
            "value": "660333"
          },
          {
            "label": "善化区",
            "value": "660334"
          },
          {
            "label": "大内区",
            "value": "660335"
          },
          {
            "label": "山上区",
            "value": "660336"
          },
          {
            "label": "新市区",
            "value": "660337"
          },
          {
            "label": "安定区",
            "value": "660338"
          }
        ]
      },
      {
        "label": "台中市",
        "value": "6604",
        "children": [
          {
            "label": "中区",
            "value": "660401"
          },
          {
            "label": "东区",
            "value": "660402"
          },
          {
            "label": "南区",
            "value": "660403"
          },
          {
            "label": "西区",
            "value": "660404"
          },
          {
            "label": "北区",
            "value": "660405"
          },
          {
            "label": "北屯区",
            "value": "660406"
          },
          {
            "label": "西屯区",
            "value": "660407"
          },
          {
            "label": "南屯区",
            "value": "660408"
          },
          {
            "label": "其它区",
            "value": "660409"
          },
          {
            "label": "太平区",
            "value": "660410"
          },
          {
            "label": "大里区",
            "value": "660411"
          },
          {
            "label": "雾峰区",
            "value": "660412"
          },
          {
            "label": "乌日区",
            "value": "660413"
          },
          {
            "label": "丰原区",
            "value": "660414"
          },
          {
            "label": "后里区",
            "value": "660415"
          },
          {
            "label": "石岗区",
            "value": "660416"
          },
          {
            "label": "东势区",
            "value": "660417"
          },
          {
            "label": "和平区",
            "value": "660418"
          },
          {
            "label": "行社区",
            "value": "660419"
          },
          {
            "label": "潭子区",
            "value": "660420"
          },
          {
            "label": "大雅区",
            "value": "660421"
          },
          {
            "label": "神冈区",
            "value": "660422"
          },
          {
            "label": "大肚区",
            "value": "660423"
          },
          {
            "label": "沙鹿区",
            "value": "660424"
          },
          {
            "label": "龙井区",
            "value": "660425"
          },
          {
            "label": "梧栖区",
            "value": "660426"
          },
          {
            "label": "清水区",
            "value": "660427"
          },
          {
            "label": "大甲区",
            "value": "660428"
          },
          {
            "label": "外埔区",
            "value": "660429"
          },
          {
            "label": "大安区",
            "value": "660430"
          }
        ]
      },
      {
        "label": "金门县",
        "value": "6605",
        "children": [
          {
            "label": "金沙镇",
            "value": "660501"
          },
          {
            "label": "金湖镇",
            "value": "660502"
          },
          {
            "label": "金宁乡",
            "value": "660503"
          },
          {
            "label": "烈屿乡",
            "value": "660504"
          },
          {
            "label": "乌坵乡",
            "value": "660505"
          }
        ]
      },
      {
        "label": "南投县",
        "value": "6606",
        "children": [
          {
            "label": "南投市",
            "value": "660601"
          },
          {
            "label": "中寮乡",
            "value": "660602"
          },
          {
            "label": "草屯镇",
            "value": "660603"
          },
          {
            "label": "国姓乡",
            "value": "660604"
          },
          {
            "label": "埔里镇",
            "value": "660605"
          },
          {
            "label": "仁爱镇",
            "value": "660606"
          },
          {
            "label": "名间乡",
            "value": "660607"
          },
          {
            "label": "集集镇",
            "value": "660608"
          },
          {
            "label": "水里乡",
            "value": "660609"
          },
          {
            "label": "鱼池乡",
            "value": "660610"
          },
          {
            "label": "信义乡",
            "value": "660611"
          },
          {
            "label": "竹山镇",
            "value": "660612"
          },
          {
            "label": "鹿谷乡",
            "value": "660613"
          }
        ]
      },
      {
        "label": "基隆市",
        "value": "6607",
        "children": [
          {
            "label": "仁爱区",
            "value": "660701"
          },
          {
            "label": "信义区",
            "value": "660702"
          },
          {
            "label": "中正区",
            "value": "660703"
          },
          {
            "label": "安乐区",
            "value": "660704"
          },
          {
            "label": "暖暖区",
            "value": "660705"
          },
          {
            "label": "七堵区",
            "value": "660706"
          },
          {
            "label": "其他区",
            "value": "660707"
          }
        ]
      },
      {
        "label": "新竹市",
        "value": "6608",
        "children": [
          {
            "label": "东区",
            "value": "660801"
          },
          {
            "label": "北区",
            "value": "660802"
          },
          {
            "label": "香山区",
            "value": "660803"
          },
          {
            "label": "其它区",
            "value": "660804"
          }
        ]
      },
      {
        "label": "嘉义市",
        "value": "6609",
        "children": [
          {
            "label": "东区",
            "value": "660901"
          },
          {
            "label": "西区",
            "value": "660902"
          },
          {
            "label": "其它区",
            "value": "660903"
          }
        ]
      },
      {
        "label": "新北市",
        "value": "6610",
        "children": [
          {
            "label": "万里区",
            "value": "661001"
          },
          {
            "label": "金山区",
            "value": "661002"
          },
          {
            "label": "板桥区",
            "value": "661003"
          },
          {
            "label": "汐止区",
            "value": "661004"
          },
          {
            "label": "深坑区",
            "value": "661005"
          },
          {
            "label": "石碇区",
            "value": "661006"
          },
          {
            "label": "瑞芳区",
            "value": "661007"
          },
          {
            "label": "平溪区",
            "value": "661008"
          },
          {
            "label": "双溪区",
            "value": "661009"
          },
          {
            "label": "贡寮区",
            "value": "661010"
          },
          {
            "label": "新店区",
            "value": "661011"
          },
          {
            "label": "坪林区",
            "value": "661012"
          },
          {
            "label": "乌来区",
            "value": "661013"
          },
          {
            "label": "永和区",
            "value": "661014"
          },
          {
            "label": "中和区",
            "value": "661015"
          },
          {
            "label": "土城区",
            "value": "661016"
          },
          {
            "label": "三峡区",
            "value": "661017"
          },
          {
            "label": "树林区",
            "value": "661018"
          },
          {
            "label": "莺歌区",
            "value": "661019"
          },
          {
            "label": "三重区",
            "value": "661020"
          },
          {
            "label": "新庄区",
            "value": "661021"
          },
          {
            "label": "泰山区",
            "value": "661022"
          },
          {
            "label": "林口区",
            "value": "661023"
          },
          {
            "label": "泸州区",
            "value": "661024"
          },
          {
            "label": "八区区",
            "value": "661025"
          },
          {
            "label": "谈水区",
            "value": "661026"
          },
          {
            "label": "三芝区",
            "value": "661027"
          },
          {
            "label": "石门区",
            "value": "661028"
          }
        ]
      },
      {
        "label": "宜兰县",
        "value": "6611",
        "children": [
          {
            "label": "宜兰市",
            "value": "661101"
          },
          {
            "label": "头城镇",
            "value": "661102"
          },
          {
            "label": "礁溪乡",
            "value": "661103"
          },
          {
            "label": "状围乡",
            "value": "661104"
          },
          {
            "label": "员山乡",
            "value": "661105"
          },
          {
            "label": "罗东镇",
            "value": "661106"
          },
          {
            "label": "三星乡",
            "value": "661107"
          },
          {
            "label": "大同乡",
            "value": "661108"
          },
          {
            "label": "五结乡",
            "value": "661109"
          },
          {
            "label": "冬山乡",
            "value": "661110"
          },
          {
            "label": "苏澳镇",
            "value": "661111"
          },
          {
            "label": "钓鱼台",
            "value": "661112"
          }
        ]
      },
      {
        "label": "新竹县",
        "value": "6612",
        "children": [
          {
            "label": "竹北市",
            "value": "661201"
          },
          {
            "label": "湖口乡",
            "value": "661202"
          },
          {
            "label": "新浦镇",
            "value": "661203"
          },
          {
            "label": "关西镇",
            "value": "661204"
          },
          {
            "label": "芎林乡",
            "value": "661205"
          },
          {
            "label": "宝山乡",
            "value": "661206"
          },
          {
            "label": "竹东镇",
            "value": "661207"
          },
          {
            "label": "五峰乡",
            "value": "661208"
          },
          {
            "label": "横山乡",
            "value": "661209"
          },
          {
            "label": "尖石乡",
            "value": "661210"
          },
          {
            "label": "北埔乡",
            "value": "661211"
          },
          {
            "label": "峨眉乡",
            "value": "661212"
          }
        ]
      },
      {
        "label": "桃园市",
        "value": "6613",
        "children": [
          {
            "label": "中坜区",
            "value": "661301"
          },
          {
            "label": "平镇区",
            "value": "661302"
          },
          {
            "label": "龙潭区",
            "value": "661303"
          },
          {
            "label": "杨梅区",
            "value": "661304"
          },
          {
            "label": "新屋区",
            "value": "661305"
          },
          {
            "label": "观音区",
            "value": "661306"
          },
          {
            "label": "桃园区",
            "value": "661307"
          },
          {
            "label": "龟山区",
            "value": "661308"
          },
          {
            "label": "八德区",
            "value": "661309"
          },
          {
            "label": "大溪区",
            "value": "661310"
          },
          {
            "label": "复兴区",
            "value": "661311"
          },
          {
            "label": "大圆区",
            "value": "661312"
          },
          {
            "label": "芦竹区",
            "value": "661313"
          }
        ]
      },
      {
        "label": "苗栗县",
        "value": "6614",
        "children": [
          {
            "label": "竹南镇",
            "value": "661401"
          },
          {
            "label": "头份市",
            "value": "661402"
          },
          {
            "label": "三湾乡",
            "value": "661403"
          },
          {
            "label": "南庄乡",
            "value": "661404"
          },
          {
            "label": "狮潭乡",
            "value": "661405"
          },
          {
            "label": "后龙镇",
            "value": "661406"
          },
          {
            "label": "通宵镇",
            "value": "661407"
          },
          {
            "label": "苑里镇",
            "value": "661408"
          },
          {
            "label": "苗粟市",
            "value": "661409"
          },
          {
            "label": "造桥乡",
            "value": "661410"
          },
          {
            "label": "头屋乡",
            "value": "661411"
          },
          {
            "label": "公馆乡",
            "value": "661412"
          },
          {
            "label": "大湖乡",
            "value": "661413"
          },
          {
            "label": "泰安乡",
            "value": "661414"
          },
          {
            "label": "铜锣乡",
            "value": "661415"
          },
          {
            "label": "三义乡",
            "value": "661416"
          },
          {
            "label": "西湖乡",
            "value": "661417"
          },
          {
            "label": "卓兰镇",
            "value": "661418"
          }
        ]
      },
      {
        "label": "彰化县",
        "value": "6615",
        "children": [
          {
            "label": "彰化市",
            "value": "661501"
          },
          {
            "label": "芬园乡",
            "value": "661502"
          },
          {
            "label": "花坛乡",
            "value": "661503"
          },
          {
            "label": "秀水乡",
            "value": "661504"
          },
          {
            "label": "鹿港镇",
            "value": "661505"
          },
          {
            "label": "福兴乡",
            "value": "661506"
          },
          {
            "label": "线西乡",
            "value": "661507"
          },
          {
            "label": "和美镇",
            "value": "661508"
          },
          {
            "label": "伸港乡",
            "value": "661509"
          },
          {
            "label": "员林市",
            "value": "661510"
          },
          {
            "label": "社头乡",
            "value": "661511"
          },
          {
            "label": "永靖乡",
            "value": "661512"
          },
          {
            "label": "埔心乡",
            "value": "661513"
          },
          {
            "label": "溪湖镇",
            "value": "661514"
          },
          {
            "label": "大村乡",
            "value": "661515"
          },
          {
            "label": "埔盐乡",
            "value": "661516"
          },
          {
            "label": "田中镇",
            "value": "661517"
          },
          {
            "label": "北斗镇",
            "value": "661518"
          },
          {
            "label": "田尾乡",
            "value": "661519"
          },
          {
            "label": "埤头乡",
            "value": "661520"
          },
          {
            "label": "溪州乡",
            "value": "661521"
          },
          {
            "label": "竹塘乡",
            "value": "661522"
          },
          {
            "label": "二林镇",
            "value": "661523"
          },
          {
            "label": "大城乡",
            "value": "661524"
          },
          {
            "label": "芳苑乡",
            "value": "661525"
          },
          {
            "label": "二水乡",
            "value": "661526"
          }
        ]
      },
      {
        "label": "嘉义县",
        "value": "6616",
        "children": [
          {
            "label": "番路乡",
            "value": "661601"
          },
          {
            "label": "梅山乡",
            "value": "661602"
          },
          {
            "label": "竹崎乡",
            "value": "661603"
          },
          {
            "label": "阿里山乡",
            "value": "661604"
          },
          {
            "label": "中埔乡",
            "value": "661605"
          },
          {
            "label": "大浦乡",
            "value": "661606"
          },
          {
            "label": "水上乡",
            "value": "661607"
          },
          {
            "label": "鹿草乡",
            "value": "661608"
          },
          {
            "label": "太保市",
            "value": "661609"
          },
          {
            "label": "朴子市",
            "value": "661610"
          },
          {
            "label": "东石乡",
            "value": "661611"
          },
          {
            "label": "六脚乡",
            "value": "661612"
          },
          {
            "label": "新港乡",
            "value": "661613"
          },
          {
            "label": "民雄乡",
            "value": "661614"
          },
          {
            "label": "大林镇",
            "value": "661615"
          },
          {
            "label": "溪口乡",
            "value": "661616"
          },
          {
            "label": "义竹乡",
            "value": "661617"
          },
          {
            "label": "布袋镇",
            "value": "661618"
          }
        ]
      },
      {
        "label": "云林县",
        "value": "6617",
        "children": [
          {
            "label": "斗南镇",
            "value": "661701"
          },
          {
            "label": "大埤乡",
            "value": "661702"
          },
          {
            "label": "虎尾镇",
            "value": "661703"
          },
          {
            "label": "土库镇",
            "value": "661704"
          },
          {
            "label": "褒忠乡",
            "value": "661705"
          },
          {
            "label": "东势乡",
            "value": "661706"
          },
          {
            "label": "台西乡",
            "value": "661707"
          },
          {
            "label": "仑背乡",
            "value": "661708"
          },
          {
            "label": "麦寮乡",
            "value": "661709"
          },
          {
            "label": "斗六市",
            "value": "661710"
          },
          {
            "label": "林内乡",
            "value": "661711"
          },
          {
            "label": "古坑乡",
            "value": "661712"
          },
          {
            "label": "莿桐乡",
            "value": "661713"
          },
          {
            "label": "西螺镇",
            "value": "661714"
          },
          {
            "label": "二仑乡",
            "value": "661715"
          },
          {
            "label": "北港镇",
            "value": "661716"
          },
          {
            "label": "水林乡",
            "value": "661717"
          },
          {
            "label": "口湖乡",
            "value": "661718"
          },
          {
            "label": "四湖乡",
            "value": "661719"
          },
          {
            "label": "元长乡",
            "value": "661720"
          }
        ]
      },
      {
        "label": "屏东县",
        "value": "6618",
        "children": [
          {
            "label": "屏东市",
            "value": "661801"
          },
          {
            "label": "三地门乡",
            "value": "661802"
          },
          {
            "label": "雾台乡",
            "value": "661803"
          },
          {
            "label": "玛家乡",
            "value": "661804"
          },
          {
            "label": "九如乡",
            "value": "661805"
          },
          {
            "label": "里港乡",
            "value": "661806"
          },
          {
            "label": "高树乡",
            "value": "661807"
          },
          {
            "label": "盐埔乡",
            "value": "661808"
          },
          {
            "label": "长治乡",
            "value": "661809"
          },
          {
            "label": "麟洛乡",
            "value": "661810"
          },
          {
            "label": "竹田乡",
            "value": "661811"
          },
          {
            "label": "内埔乡",
            "value": "661812"
          },
          {
            "label": "万丹乡",
            "value": "661813"
          },
          {
            "label": "潮州镇",
            "value": "661814"
          },
          {
            "label": "泰武乡",
            "value": "661815"
          },
          {
            "label": "来义乡",
            "value": "661816"
          },
          {
            "label": "万峦乡",
            "value": "661817"
          },
          {
            "label": "崁顶乡",
            "value": "661818"
          },
          {
            "label": "新埤乡",
            "value": "661819"
          },
          {
            "label": "南州乡",
            "value": "661820"
          },
          {
            "label": "林边乡",
            "value": "661821"
          },
          {
            "label": "东港镇",
            "value": "661822"
          },
          {
            "label": "琉球乡",
            "value": "661823"
          },
          {
            "label": "佳冬乡",
            "value": "661824"
          },
          {
            "label": "新园乡",
            "value": "661825"
          },
          {
            "label": "枋寮乡",
            "value": "661826"
          },
          {
            "label": "枋山乡",
            "value": "661827"
          },
          {
            "label": "春日乡",
            "value": "661828"
          },
          {
            "label": "狮子乡",
            "value": "661829"
          },
          {
            "label": "车城乡",
            "value": "661830"
          },
          {
            "label": "牡丹乡",
            "value": "661831"
          },
          {
            "label": "恒春镇",
            "value": "661832"
          },
          {
            "label": "满洲乡",
            "value": "661833"
          }
        ]
      },
      {
        "label": "台东县",
        "value": "6619",
        "children": [
          {
            "label": "台东市",
            "value": "661901"
          },
          {
            "label": "绿岛乡",
            "value": "661902"
          },
          {
            "label": "兰屿乡",
            "value": "661903"
          },
          {
            "label": "延平乡",
            "value": "661904"
          },
          {
            "label": "卑南乡",
            "value": "661905"
          },
          {
            "label": "鹿野乡",
            "value": "661906"
          },
          {
            "label": "关山镇",
            "value": "661907"
          },
          {
            "label": "海端乡",
            "value": "661908"
          },
          {
            "label": "池上乡",
            "value": "661909"
          },
          {
            "label": "东河乡",
            "value": "661910"
          },
          {
            "label": "成功镇",
            "value": "661911"
          },
          {
            "label": "长滨乡",
            "value": "661912"
          },
          {
            "label": "金峰乡",
            "value": "661913"
          },
          {
            "label": "大武乡",
            "value": "661914"
          },
          {
            "label": "达仁乡",
            "value": "661915"
          },
          {
            "label": "太麻里乡",
            "value": "661916"
          }
        ]
      },
      {
        "label": "花莲县",
        "value": "6620",
        "children": [
          {
            "label": "花莲市",
            "value": "662001"
          },
          {
            "label": "新城乡",
            "value": "662002"
          },
          {
            "label": "太鲁阁",
            "value": "662003"
          },
          {
            "label": "秀林乡",
            "value": "662004"
          },
          {
            "label": "吉安乡",
            "value": "662005"
          },
          {
            "label": "寿丰乡",
            "value": "662006"
          },
          {
            "label": "凤林镇",
            "value": "662007"
          },
          {
            "label": "光复乡",
            "value": "662008"
          },
          {
            "label": "丰滨乡",
            "value": "662009"
          },
          {
            "label": "瑞穗乡",
            "value": "662010"
          },
          {
            "label": "万荣乡",
            "value": "662011"
          },
          {
            "label": "玉里镇",
            "value": "662012"
          },
          {
            "label": "卓溪乡",
            "value": "662013"
          },
          {
            "label": "富里乡",
            "value": "662014"
          }
        ]
      },
      {
        "label": "澎湖县",
        "value": "6621",
        "children": [
          {
            "label": "马公市",
            "value": "662101"
          },
          {
            "label": "西屿乡",
            "value": "662102"
          },
          {
            "label": "望安乡",
            "value": "662103"
          },
          {
            "label": "七美乡",
            "value": "662104"
          },
          {
            "label": "白沙乡",
            "value": "662105"
          },
          {
            "label": "湖西乡",
            "value": "662106"
          }
        ]
      },
      {
        "label": "连江县",
        "value": "6622",
        "children": [
          {
            "label": "南竿乡",
            "value": "662201"
          },
          {
            "label": "北竿乡",
            "value": "662202"
          },
          {
            "label": "莒光乡",
            "value": "662203"
          },
          {
            "label": "东引乡",
            "value": "662204"
          }
        ]
      }
    ]
  },
  {
    "label": "中国香港",
    "value": 67,
    "children": [
      {
        "label": "香港岛",
        "value": "6701",
        "children": [
          {
            "label": "中西区",
            "value": "670101"
          },
          {
            "label": "湾仔区",
            "value": "670102"
          },
          {
            "label": "东区",
            "value": "670103"
          },
          {
            "label": "南区",
            "value": "670104"
          }
        ]
      },
      {
        "label": "九龙",
        "value": "6702",
        "children": [
          {
            "label": "九龙城区",
            "value": "670201"
          },
          {
            "label": "油尖旺区",
            "value": "670202"
          },
          {
            "label": "深水涉区",
            "value": "670203"
          },
          {
            "label": "黄大仙区",
            "value": "670204"
          },
          {
            "label": "观塘区",
            "value": "670205"
          }
        ]
      },
      {
        "label": "新界",
        "value": "6703",
        "children": [
          {
            "label": "北区",
            "value": "670301"
          },
          {
            "label": "大埔区",
            "value": "670302"
          },
          {
            "label": "沙田区",
            "value": "670303"
          },
          {
            "label": "西贡区",
            "value": "670304"
          },
          {
            "label": "元朗区",
            "value": "670305"
          },
          {
            "label": "屯门区",
            "value": "670306"
          },
          {
            "label": "荃湾区",
            "value": "670307"
          },
          {
            "label": "葵青区",
            "value": "670308"
          },
          {
            "label": "离岛区",
            "value": "670309"
          }
        ]
      }
    ]
  },
  {
    "label": "中国澳门",
    "value": 68,
    "children": [
      {
        "label": "澳门半岛",
        "value": "6801",
        "children": [
          {
            "label": "花地玛堂区",
            "value": "680101"
          },
          {
            "label": "花王堂区",
            "value": "680102"
          },
          {
            "label": "望德堂区",
            "value": "680103"
          },
          {
            "label": "大堂区",
            "value": "680104"
          },
          {
            "label": "风顺堂区",
            "value": "680105"
          }
        ]
      },
      {
        "label": "离岛",
        "value": "6802",
        "children": [
          {
            "label": "嘉模堂区",
            "value": "680201"
          },
          {
            "label": "路凼填海区",
            "value": "680202"
          },
          {
            "label": "圣方济各堂区",
            "value": "680203"
          }
        ]
      }
    ]
  }
]