/*
 * @Description: 模拟网络请求得到的数据
 * @Author: CcSimple
 * @Github: https://github.com/CcSimple
 * @Date: 2023-04-02 15:14:15
 * @LastEditors: CcSimple
 * @LastEditTime: 2023-04-02 15:47:33
 */

// 配合 provider-helper.js 使用
const data = {
  key: "NetProvider1",
  options: {
    groupName: "NetProvider1分组名称",
    printElements: [
      {
        id: "customerValue",
        type: "txt", // 这里的 type 是后端返回的, 需要转换成 hiprint 的 type
        width: 100,
        height: 20,
        title: "需方",
        field: "customerValue",
        testData: "customerValue",
        // 协商的可选参数, 没有可选参数就返回 {}
        options: {
          fontSize: 12,
          color: "#f00808",
        },
      },
      {
        id: "work_name",
        type: "txt", // 这里的 type 是后端返回的, 需要转换成 hiprint 的 type
        width: 100,
        height: 20,
        title: "单位",
        field: "work_name",
        testData: "单位值后端传",
        // 协商的可选参数, 没有可选参数就返回 {}
        options: {
          fontSize: 12,
          color: "#f00808",
        },
      },
      {
        id: "logo",
        type: "img",
        width: 100,
        height: 100,
        title: "logo",
        field: "logoTest",
        options: {
          src: "https://foruda.gitee.com/avatar/1677050350324030848/5400665_ccsimple_1591166830.png!avatar200",
        },
      },
      {
        id: "table",
        type: "table",
        title: "空白表格",
        field: "table",
        options: {

        },
      },
      {
        id: "tableCustom",
        type: "tableCustom",
        title: "默认表格",
        field: "tableCustom",
        options: {
          tableBodyCellBorder: 'border',
          fields: [
            {
              "text": "ID",
              "title":"ID",
              "field": "id",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 1
            },
            {
              "text": "账期(月)",
              "field": "payment_days",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 2
            },
            {
              "text": "关联客户",
              "field": "related_company",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 3
            },
            {
              "text": "客户名称",
              "field": "company_name",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 4
            },
            {
              "text": "信用额度",
              "field": "credit_limit",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 5
            },
            {
              "text": "业务员",
              "field": "salesman",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 6
            },
            {
              "text": "新客户状态开始时间",
              "field": "new_customer_time_start",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 7
            },
            {
              "text": "新客户状态有效期",
              "field": "new_customer_time_limit",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 8
            },
            {
              "text": "创建时间",
              "field": "create_time",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 9
            },
            {
              "text": "修改时间",
              "field": "update_time",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 10
            },
            {
              "text": "公海",
              "field": "is_open_sea",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 11
            },
            {
              "text": "公海状态",
              "field": "open_sea_type",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 12
            },
            {
              "text": "客户代码",
              "field": "company_code",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 13
            },
            {
              "text": "客户简称",
              "field": "company_brief",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 14
            },
            {
              "text": "公海类型",
              "field": "sea_type",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 15
            },
            {
              "text": "重要度",
              "field": "importance",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 16
            },
            {
              "text": "态度",
              "field": "attitude",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 17
            },
            {
              "text": "需求度",
              "field": "demand_degree",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 18
            },
            {
              "text": "衍生产品",
              "field": "derivative_product",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 19
            },
            {
              "title": "客户所在地",
              "field": "address",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 20
            },
            {
              "title": "交易法人单位",
              "field": "impersonal_entity",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 21
            },
            {
              "title": "客户行业",
              "field": "industry",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 22
            },
            {
              "title": "客户来源",
              "field": "source",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 23
            },
            {
              "title": "人员规模",
              "field": "staff_num_range",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 24
            },
            {
              "title": "经营开始时间",
              "field": "from_time",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 25
            },
            {
              "title": "是否是小微企业",
              "field": "is_micro_ent",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 26
            },
            {
              "title": "企业评分(万分制)",
              "field": "percentile_score",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 27
            },
            {
              "title": "注册资本",
              "field": "reg_capital",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 28
            },
            {
              "title": "登记机关",
              "field": "regInstitute",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 29
            },
            {
              "title": "注册地址",
              "field": "reg_location",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 30
            },
            {
              "title": "核准时间",
              "field": "approved_time",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 31
            },
            {
              "title": "参保人数",
              "field": "social_staff_num",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 32
            },
            {
              "title": "纳税人识别号",
              "field": "tax_number",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 33
            },
            {
              "title": "经营范围",
              "field": "business_scope",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 34
            },
            {
              "title": "英文名",
              "field": "eng_name",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 35
            },
            {
              "title": "企业状态",
              "field": "reg_status",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 36
            },
            {
              "title": "成立日期",
              "field": "establish_time",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 37
            },
            {
              "title": "法人",
              "field": "legal_person_name",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 38
            },
            {
              "title": "经营结束时间",
              "field": "to_time",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 39
            },
            {
              "title": "实收注册资金",
              "field": "actual_capital",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 40
            },
            {
              "title": "省份简称",
              "field": "province",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 41
            },
            {
              "title": "统一社会信用代码",
              "field": "credit_code",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 42
            },
            {
              "title": "曾用名",
              "field": "history_names",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 43
            },
            {
              "title": "曾用名列表",
              "field": "history_names_list",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 44
            },
            {
              "title": "注册资本币种",
              "field": "reg_capital_currency",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 45
            },
            {
              "title": "实收注册资本币种",
              "field": "actual_capital_currency",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 46
            },
            {
              "title": "市",
              "field": "city",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 47
            },
            {
              "title": "区",
              "field": "district",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 48
            },
            {
              "title": "国民经济行业分类",
              "field": "industry_all",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 49
            }
          ],
        },
        columns: [
          [
            {
              "title": "ID",
              "field": "id",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300px",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 1
            },
            {
              "title": "账期(月)",
              "field": "payment_days",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 2
            },
            {
              "title": "关联客户",
              "field": "related_company",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 3
            },
            {
              "title": "客户名称",
              "field": "company_name",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 4
            },
            {
              "title": "信用额度",
              "field": "credit_limit",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 5
            },
            {
              "title": "业务员",
              "field": "salesman",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 6
            },
            {
              "title": "新客户状态开始时间",
              "field": "new_customer_time_start",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "300",
              "minWidth": "",
              "fixDirection": "",
              align: "center",
              "id": 7
            },
            {
              "title": "新客户状态有效期",
              "field": "new_customer_time_limit",
              "isShow": true,
              "currentUserEditTitle": "",
              "aggregation": "",
              "width": "",
              "minWidth": "",
              "fixDirection": "",
              "id": 8
            },
            // {
            //   "title": "创建时间",
            //   "field": "create_time",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 9
            // },
            // {
            //   "title": "修改时间",
            //   "field": "update_time",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 10
            // },
            // {
            //   "title": "公海",
            //   "field": "is_open_sea",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 11
            // },
            // {
            //   "title": "公海状态",
            //   "field": "open_sea_type",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 12
            // },
            // {
            //   "title": "客户代码",
            //   "field": "company_code",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 13
            // },
            // {
            //   "title": "客户简称",
            //   "field": "company_brief",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 14
            // },
            // {
            //   "title": "公海类型",
            //   "field": "sea_type",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 15
            // },
            // {
            //   "title": "重要度",
            //   "field": "importance",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 16
            // },
            // {
            //   "title": "态度",
            //   "field": "attitude",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 17
            // },
            // {
            //   "title": "需求度",
            //   "field": "demand_degree",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 18
            // },
            // {
            //   "title": "衍生产品",
            //   "field": "derivative_product",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 19
            // },
            // {
            //   "title": "客户所在地",
            //   "field": "address",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 20
            // },
            // {
            //   "title": "交易法人单位",
            //   "field": "impersonal_entity",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 21
            // },
            // {
            //   "title": "客户行业",
            //   "field": "industry",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 22
            // },
            // {
            //   "title": "客户来源",
            //   "field": "source",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 23
            // },
            // {
            //   "title": "人员规模",
            //   "field": "staff_num_range",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 24
            // },
            // {
            //   "title": "经营开始时间",
            //   "field": "from_time",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 25
            // },
            // {
            //   "title": "是否是小微企业",
            //   "field": "is_micro_ent",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 26
            // },
            // {
            //   "title": "企业评分(万分制)",
            //   "field": "percentile_score",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 27
            // },
            // {
            //   "title": "注册资本",
            //   "field": "reg_capital",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 28
            // },
            // {
            //   "title": "登记机关",
            //   "field": "regInstitute",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 29
            // },
            // {
            //   "title": "注册地址",
            //   "field": "reg_location",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 30
            // },
            // {
            //   "title": "核准时间",
            //   "field": "approved_time",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 31
            // },
            // {
            //   "title": "参保人数",
            //   "field": "social_staff_num",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 32
            // },
            // {
            //   "title": "纳税人识别号",
            //   "field": "tax_number",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 33
            // },
            // {
            //   "title": "经营范围",
            //   "field": "business_scope",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 34
            // },
            // {
            //   "title": "英文名",
            //   "field": "eng_name",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 35
            // },
            // {
            //   "title": "企业状态",
            //   "field": "reg_status",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 36
            // },
            // {
            //   "title": "成立日期",
            //   "field": "establish_time",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 37
            // },
            // {
            //   "title": "法人",
            //   "field": "legal_person_name",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 38
            // },
            // {
            //   "title": "经营结束时间",
            //   "field": "to_time",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 39
            // },
            // {
            //   "title": "实收注册资金",
            //   "field": "actual_capital",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 40
            // },
            // {
            //   "title": "省份简称",
            //   "field": "province",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 41
            // },
            // {
            //   "title": "统一社会信用代码",
            //   "field": "credit_code",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 42
            // },
            // {
            //   "title": "曾用名",
            //   "field": "history_names",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 43
            // },
            // {
            //   "title": "曾用名列表",
            //   "field": "history_names_list",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 44
            // },
            // {
            //   "title": "注册资本币种",
            //   "field": "reg_capital_currency",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 45
            // },
            // {
            //   "title": "实收注册资本币种",
            //   "field": "actual_capital_currency",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 46
            // },
            // {
            //   "title": "市",
            //   "field": "city",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 47
            // },
            // {
            //   "title": "区",
            //   "field": "district",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 48
            // },
            // {
            //   "title": "国民经济行业分类",
            //   "field": "industry_all",
            //   "isShow": true,
            //   "currentUserEditTitle": "",
            //   "aggregation": "",
            //   "width": "",
            //   "minWidth": "",
            //   "fixDirection": "",
            //   "id": 49
            // }
          ]
          // [
          //   {
          //     title: "ID",
          //     field: "id",
          //     width: "",
          //     align: "center",
          //     // id: 1
          //   },
          //   {
          //     title: "账期(月)",
          //     field: "payment_days",
          //     width: "",
          //     align: "center",
          //     // id: 2
          //   },
          //   {
          //     title: "关联客户",
          //     field: "related_company",
          //     width: "",
          //     align: "center",
          //     // id: 3
          //   },
          //   {
          //     title: "客户名称",
          //     field: "company_name",
          //     width: "",
          //     align: "center",
          //     // id: 4
          //   },
          //   {
          //     title: "信用额度",
          //     field: "credit_limit",
          //     width: "",
          //     align: "center",
          //     // id: 5
          //   },
          //   // { title: "名称", align: "center", field: "NAME", width: 100 },
          //   // { title: "数量", align: "center", field: "SL", width: 100 },
          //   // { title: "条码", align: "center", field: "TM", width: 100 },
          //   // { title: "规格", align: "center", field: "GG", width: 100 },
          //   // { title: "单价", align: "center", field: "DJ", width: 100 },
          //   // { title: "金额", align: "center", field: "JE", width: 100 },
          //   // { title: "备注", align: "center", field: "DETAIL", width: 100 },
          // ],
        ],
      },
    ],
  },
};
const data1 = {
  key: "NetProvider2",
  options: {
    groupName: "标题名称",
    printElements:[
      {
        id: "",
        type: "txt", // 这里的 type 是后端返回的, 需要转换成 hiprint 的 type
        width: 100,
        height: 20,
        title: "出库单",
        field: "",
        testData: "",
        // 协商的可选参数, 没有可选参数就返回 {}
        options: {
          fontSize: 12,
          color: "#f00808",
        },
      },
    ]
  },
};

export default [data, data1];
