import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { createWebHistory } from 'vue-router'
import path from 'path-browserify'
// import HomeView from '../views/HomeView.vue'
// import Productivity from '../components/productivity/Productivity.vue'
// import HelloWorld from '../components/HelloWorld.vue'
// import Box from '../components/productivity/Box.vue'
// import Phome from '../components/productivity/Phome.vue'
// import Plan from '../components/productivity/Plan.vue'
// import Login from '../views/Login.vue'
// import Register from '../views/Register.vue'
// import ProduceHome from '../components/Produce/ProduceHome.vue'
// import Product from '../components/Produce/Product.vue'
// import EfficiencyStatistics from '../components/Produce/EfficiencyStatistics.vue'
// import Check from '../components/Produce/Check.vue'
// import Storage from '../components/Storage/Storage.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [{
  path: '/',
  component: () => import('@/views/HomeView.vue'),
  name: 'homeview',
  children: [{
    path: '/inaccessible',
    component: () => import('@/views/inaccessible.vue')
  },
  {
    path: '',
    component: () => import('@/components/HelloWorld.vue'),
    name: 'home'
  },
  // 职能
  // {
  //   path: '/functions',
  //   component: () => import('@/components/Functions/Functions.vue'),
  //   name: 'functions',
  //   children: [{
  //     path: '',
  //     component: () => import('@/components/Functions/FunctionsHome.vue'),
  //     name: 'functionshome'
  //   },
  //   ]
  // },
  // 讯息流
  {
    path: '/messageflow',
    component: () => import('@/components/messageflow/messageflow.vue'),
    name: 'messageflow',
    children: [
      // 讯息流
      {
        path: '',
        component: () => import('@/components/messageflow/MessageFlowHome.vue'),
        name: 'messageflowhome'
      },
      // 讯息流/业务员
      {
        path: '/messageflow_order',
        component: () => import('@/components/messageflow/messageflow/messageflow_order.vue'),
        name: 'messageflow_order'
      },
      // 讯息流/数字中心
      {
        path: '/messageflow_digitalintelligence',
        component: () => import('@/components/messageflow/messageflow/MessageFlow_DigitalIntelligence.vue'),
        name: 'messageflow_digitalintelligence'
      },
      // // 讯息流/销售业务员日工作阶段性汇报表展示
      // {
      //   path: '/salesman_scheduleinfo',
      //   component: () => import('@/components/messageflow/Salesman_scheduleInfo.vue'),
      //   name: 'salesman_scheduleinfo'
      // },
      // 讯息流/部门日报展示
      {
        path: '/deptdayworkinfo',
        component: () => import('@/components/messageflow/DeptDayWorkInfo.vue'),
        name: 'deptdayworkinfo'
      },
      // 讯息流/okr看板
      {
        path: '/okrboard',
        component: () => import('@/components/messageflow/OkrBoard.vue'),
        name: 'okrboard'
      },
      // 讯息流/天眼查看板
      {
        path: '/skyeyeboard',
        component: () => import('@/components/messageflow/SkyEyeBoard.vue'),
        name: 'skyeyeboard'
      },
    ]
  },
  // 物料流
  {
    path: '/materialflow',
    component: () => import('@/components/materialflow/materialflow.vue'),
    name: 'materialflow',
    children: [
      {
        path: '',
        component: () => import('@/components/materialflow/materialflowhome.vue'),
        name: 'materialflowhome'
      },
      //物料管理
      // {
      //   path: '/materialflowindex',
      //   component: () => import('@/components/materialflow/MaterialFlow/MaterialFlowIndex.vue'),
      //   name: 'materialflowindex'
      // },
      // // 制氧阀专用物料流
      // {
      //   path: '/distributeChart',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/DistributeChart.vue'),
      //   name: 'distributeChart'
      // },
      // // 入库管理
      // {
      //   path: '/purchasinginboundmanagement',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/purchasinginboundmanagement.vue'),
      //   name: 'purchasinginboundmanagement'
      // },
      // // 日产出管理
      // {
      //   path: '/outputmanagement',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/outputmanagement.vue'),
      //   name: 'outputmanagement'
      // },
    ]
  },
  // 资金流
  {
    path: '/moneyflow',
    component: () => import('@/components/Flows/MoneyFlow/MoneyFlow.vue'),
    name: 'moneyflow',
    children: [
      {
        path: '',
        component: () => import('@/components/Flows/MoneyFlow/MoneyFlowHome.vue'),
        name: 'moneyflowhome'
      },
      //销售
      // {
      //   path: '/moneyfloworder',
      //   component: () => import('@/components/Flows/MoneyFlow/MoneyFlow/MoneyFlowOrder.vue'),
      //   name: 'moneyfloworder'
      // },
      // 应收账款跟进表
      // {
      //   path: '/accountsreceivable',
      //   component: () => import('@/components/Flows/MoneyFlow/Accountsreceivable.vue'),
      //   name: 'accountsreceivable'
      // },
      // 总应收账款跟进表
      // {
      //   path: '/sumaccountsreceivable',
      //   component: () => import('@/components/Flows/MoneyFlow/sumAccountsreceivable.vue'),
      //   name: 'sumaccountsreceivable'
      // },
      // 总银收账款统计表
      // {
      //   path: '/accountsreceivablestatistics',
      //   component: () => import('@/components/Flows/MoneyFlow/AccountsreceivableStatistics.vue'),
      //   name: 'accountsreceivablestatistics'
      // },
      // 业务员提成明细
      // {
      //   path: '/salesmanhome',
      //   component: () => import('@/components/messageflow/salesmanhome.vue'),
      //   name: 'messageflow'
      // },
    ]
  },
  // 人员流
  {
    path: '/personnelflow',
    component: () => import('@/components/Personnel/Personnelflow.vue'),
    name: 'personnelflow',
    children: [
      // 
      {
        path: '',
        component: () => import('@/components/Personnel/PersonnelflowHome.vue'),
        name: 'processflowhome'
      },
      //人员流
      {
        path: '/personnelflowindex',
        component: () => import('@/components/Personnel/PersonnelFlow/PersonnelFlowIndex.vue'),
        name: 'personnelflowindex'
      },
      //数据导入
      {
        path: '/personnelflowupload',
        component: () => import('@/components/Personnel/PersonnelFlow/PersonnelFlowUpload.vue'),
        name: 'personnelflowupload'
      },
      //职位表数据导入
      {
        path: '/title_dataImporting',
        component: () => import('@/components/Personnel/Title_DataImporting.vue'),
        name: 'title_dataImporting'
      },
      //职位管理表
      {
        path: '/title_conservator',
        component: () => import('@/components/Personnel/Title_Conservator.vue'),
        name: 'title_conservator'
      },
      //月绩效表数据导入
      {
        path: '/perf_dataImporting',
        component: () => import('@/components/Personnel/Perf_DataImporting.vue'),
        name: 'perf_dataImporting'
      },
      //绩效管理表
      {
        path: '/perf_conservator',
        component: () => import('@/components/Personnel/Perf_Conservator.vue'),
        name: 'perf_conservator'
      },
      //年度绩效考核表
      {
        path: '/year_performance',
        component: () => import('@/components/Personnel/Year_Performance.vue'),
        name: 'year_performance'
      },
    ]
  },
  // 过程流
  {
    path: '/processflow',
    component: () => import('@/components/ProcessFlow/ProcessFlow.vue'),
    name: 'processflow',
    children: [
      {
        path: '',
        component: () => import('@/components/ProcessFlow/ProcessFlowHome.vue'),
        name: 'processflowhome'
      },
      // 过程流/进销存/销售
      {
        path: '/ProcessFlowSale',
        component: () => import('@/components/ProcessFlow/ProcessFlowSale/ProcessFlowSale.vue'),
        name: 'ProcessFlowSale'
      },
      // 过程流/进销存/销售/导入销售出库单
      // {
      //   path: '/processsalesorder',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/UploadSalesOrder.vue'),
      //   name: 'processsalesorder'
      // },
      // 过程流/进销存/销售/销售出库单管理
      // {
      //   path: '/processsalemessagement',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/ProcesssaleMessagement/ProcesssaleMessagement.vue'),
      //   name: 'processsalemessagement'
      // },
      // 过程流/进销存/销售/业务员日工作量填写
      // {
      //   path: '/orderwriteinfo',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/Order_writeInfo.vue'),
      //   name: 'orderwriteinfo'
      // },
      // 过程流/进销存/销售
      // {
      //   path: '/sales',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/Sales/Sales.vue'),
      //   name: 'sales'
      // },
      // 过程流/进销存/销售/拜访记录
      {
        path: '/visitrecords',
        component: () => import('@/components/ProcessFlow/ProcessFlowSale/Sales/VisitRecords.vue'),
        name: 'visitrecords'
      },
      // // 过程流/进销存/销售/销售订单登记表
      // {
      //   path: '/salesorders',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/Sales/salesorders.vue'),
      //   name: 'salesorders'
      // },
      // 过程流/进销存/销售//新增销售订单登记表
      // {
      //   path: '/salesordersform',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/Sales/salesordersform.vue'),
      //   name: 'salesordersform'
      // },
      // 过程流/进销存/销售/对账单
      // {
      //   path: '/customerinfo',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/CustomerInfo.vue'),
      //   name: 'customerinfo'
      // },
      //过程流/进销存/销售/手工调账
      // {
      //   path: '/handadjustaccount',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/HandAdjustAccount.vue'),
      //   name: 'handadjustaccount'
      // },
      //过程流/进销存/销售/订单打印
      // {
      //   path: '/salesorderprint',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/Sales/SalesordersPrint.vue'),
      //   name: 'salesorderprint'
      // },
      //过程流/人事/花名册
      {
        path: '/manageapplylist',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/ManageApplyList.vue'),
        name: 'manageapplylist'
      },
      // 过程流/
      // {
      //   path: '/customer',
      //   component: () => import('@/components/ProcessFlow/Customer/Customer.vue'),
      //   name: 'customer'
      // },
      // 过程流/进销存/基础资料/往来单位
      {
        path: '/processcustom',
        component: () => import('@/components/ProcessFlow/Customer/Custom/Custom.vue'),
        name: 'processcustom'
      },
      // 过程流/客户/客户详情
      // {
      //   path: '/customerdetail',
      //   component: () => import('@/components/ProcessFlow/Customer/Custom/CustomDetail.vue'),
      //   name: 'customerdetail'
      // },
      // 过程流/客户/回款
      // {
      //   path: '/processbackmoney',
      //   component: () => import('@/components/ProcessFlow/Customer/BackMoney/BackMoney.vue'),
      //   name: 'processbackmoney'
      // },
      //过程流/客户/预付款
      // {
      //   path: '/advancepayment',
      //   component: () => import('@/components/ProcessFlow/Customer/AdvancePayment/AdvancePayment.vue'),
      //   name: 'advancepayment'
      // },
      //过程流/客户/品目信息测试
      // {
      //   path: '/iteminfo',
      //   component: () => import('@/components/ProcessFlow/Customer/ItemInfo.vue'),
      //   name: 'iteminfo'
      // },
      //过程流/进销存/基础资料/品目信息
      {
        path: '/productinfo',
        component: () => import('@/components/ProcessFlow/Customer/ProductInfo.vue'),
        name: 'productinfo'
      },
      //过程流/客户/查公司
      // {
      //   path: '/searchcompany',
      //   component: () => import('@/components/ProcessFlow/Customer/SearchCompany.vue'),
      //   name: 'searchcompany'
      // },
      //过程流/CRM/销售工作台/客户管理/客户领取
      {
        path: '/companydetailinfo',
        component: () => import('@/components/ProcessFlow/Customer/CompanyDetailInfo.vue'),
        name: 'companydetailinfo',
        children: [
          {
            // 企业背景
            path: '/companybackground',
            component: () => import('@/components/ProcessFlow/Customer/CompanyInfo/CompanyBackground.vue'),
            name: 'companybackground',
          },
        ]
      },
      //公司风险详情
      {
        path: '/companyrisk',
        component: () => import('@/components/ProcessFlow/Customer/CompanyInfo/CompanyRisk.vue'),
        name: 'companyrisk'
      },
      //公司年报详情
      {
        path: '/companyyear',
        component: () => import('@/components/ProcessFlow/Customer/CompanyInfo/CompanyYear.vue'),
        name: 'companyyear'
      },
      {
        // 司法风险 // 法律诉讼 // 详情
        path: '/lawsuitListdetail',
        component: () => import('@/components/ProcessFlow/Customer/CompanyInfo/LawsuitListDetail.vue'),
        name: 'lawsuitListdetail',
      },
      // 过程流/移动端主页/
      {
        path: '/phone',
        component: () => import('@/components/Tools/Phone/Phone.vue'),
        name: 'phone'
      },
      // 过程流/人事/
      {
        path: '/processflowhr',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/ProcessFlowHr.vue'),
        name: 'processflowhr'
      },
      // 人事
      // {
      //   path: '/hr',
      //   component: () => import('@/components/ProcessFlow/ProcessFlowHr/HR/HR.vue'),
      //   name: 'hr'
      // },
      // 过程流// 人事/拜访记录
      {
        path: '/visitrecorde',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/HR/visitrecorde.vue'),
        name: 'visitrecorde'
      },
      // 过程流// 人事/管理检查表
      {
        path: '/viewchecklist',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/HR/ViewCheckList.vue'),
        name: 'viewchecklist'
      },
      // 过程流// 人事/厂区安全巡查检查表
      {
        path: '/addchecklist',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/HR/AddCheckList.vue'),
        name: 'addchecklist'
      },

      // 过程流/人事/部门管理
      {
        path: '/deptmessagement',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/DeptMessagement/DeptMessagement.vue'),
        name: 'deptmessagement'
      },
      // 过程流/人事/部门人员管理
      {
        path: '/deptusermessagement',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/DeptUserMessagement/DeptUserMessagement.vue'),
        name: 'deptusermessagement'
      },
      // 过程流/人事/应聘品评单
      {
        path: '/manageapplylist',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/ManageApplyList.vue'),
        name: 'manageapplylist'
      },
      // 过程流/人事/花名册
      {
        path: '/messageform',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/MessageForm/MessageForm.vue'),
        name: 'messageform'
      },
      // 过程流/人事/花名册表导入
      {
        path: '/uploadmessageform',
        component: () => import('@/components/ProcessFlow/ProcessFlowHr/MessageForm/UploadMessageForm.vue'),
        name: 'uploadmessageform'
      },
      // 过程流/数智中心/部门日报填写
      {
        path: '/dayworkwrite',
        component: () => import('@/components/ProcessFlow/DigitalIntelligence/DayWorkWrite/DayWorkWrite.vue'),
        name: 'dayworkwrite'
      },
      // 过程流/数智中心
      {
        path: '/digitalintelligence',
        component: () => import('@/components/ProcessFlow/DigitalIntelligence/DigitalIntelligence.vue'),
        name: 'digitalintelligence'
      },
      // 过程流/天眼查
      // {
      //   path: '/skyeyecheck',
      //   component: () => import('@/components/ProcessFlow/SkyEyeCheck/SkyEyeCheck.vue'),
      //   name: 'skyeyecheck'
      // },
      // // 过程流/天眼查/查公司
      // {
      //   path: '/searchcompony',
      //   component: () => import('@/components/ProcessFlow/SkyEyeCheck/SearchCompony/SearchCompony.vue'),
      //   name: 'searchcompony'
      // },
      //过程流/品质
      {
        path: '/quality',
        component: () => import('@/components/ProcessFlow/Quality/Quality.vue'),
        name: 'quality'
      },
      //过程流/品质/点检日志查询
      {
        path: '/logqueries',
        component: () => import('@/components/ProcessFlow/Quality/LogQueries.vue'),
        name: 'logqueries'
      },
      //过程流/品质/NG日志
      {
        path: '/qualitypanel',
        component: () => import('@/components/ProcessFlow/Quality/QualityPanel.vue'),
        name: 'qualitypanel'
      },
      //过程流/品质/点检日志填表时间排序
      {
        path: '/inputorder',
        component: () => import('@/components/ProcessFlow/Quality/InputOrder.vue'),
        name: 'inputorder'
      },
      //过程流/品质/仪表板
      {
        path: '/timeline',
        component: () => import('@/components/ProcessFlow/Quality/TimeLine.vue'),
        name: 'timeline'
      },
      //过程流/品质/点检表设置
      {
        path: '/settinglist',
        component: () => import('@/components/ProcessFlow/Quality/SettingList.vue'),
        name: 'settinglist'
      },
      //过程流/品质/打标首检标准
      {
        path: '/checkstandard',
        component: () => import('@/components/ProcessFlow/Quality/CheckStandard.vue'),
        name: 'checkstandard'
      },
      // 过程流/进销存/仓储
      {
        path: '/storage',
        component: () => import('@/components/ProcessFlow/Storage/Storage.vue'),
        name: 'storage'
      },
      // // 过程流/进销存/仓储/仪表板
      // {
      //   path: '/panel',
      //   component: () => import('@/components/ProcessFlow/Storage/Panel.vue'),
      //   name: 'panel'
      // },
      // //过程流/进销存/ 仓储/图表
      // {
      //   path: '/storechart',
      //   component: () => import('@/components/ProcessFlow/Storage/Chart.vue'),
      //   name: 'storechart'
      // },
      // //过程流/进销存/ 仓储/明细
      // {
      //   path: '/detail',
      //   component: () => import('@/components/ProcessFlow/Storage/Detail.vue'),
      //   name: 'detail'
      // },
      // //过程流/进销存/ 仓储/导入库存金额仓储
      // {
      //   path: '/amountwarehousing',
      //   component: () => import('@/components/ProcessFlow/Storage/AmountWarehousing.vue'),
      //   name: 'amountwarehousing'
      // },
      // //过程流/进销存/ 仓储/导入采购入库
      // {
      //   path: '/purchasereceipt',
      //   component: () => import('@/components/ProcessFlow/Storage/PurchaseReceipt.vue'),
      //   name: 'purchasereceipt'
      // },
      // // 过程流/进销存/仓储/导入销售入库
      // {
      //   path: '/salesissue',
      //   component: () => import('@/components/ProcessFlow/Storage/SalesIssue.vue'),
      //   name: 'salesissue'
      // },
      // //过程流/进销存/ 仓储/修改导入日期
      // {
      //   path: '/settingdate',
      //   component: () => import('@/components/ProcessFlow/Storage/SettingDate.vue'),
      //   name: 'settingdate'
      // },
      // 过程流/生产管理
      {
        path: '/produce',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/Produce.vue'),
        name: 'produce'
      },
      // 过程流/生产管理
      {
        path: '/processflowproduce',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/ProcessFlowProduce.vue'),
        name: 'processflowproduce'
      },
      // 过程流/生产管理/生产日志（一）
      {
        path: '/productionlogfirst',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/ProductionLogFirst/ProductionLogFirst.vue'),
        name: 'productionlogfirst'
      },
      // 过程流/生产管理/效率
      {
        path: '/efficiencyStatistics',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/Efficiency/EfficiencyStatistics.vue'),
        name: 'efficiencyStatistics'
      },
      //  过程流/生产管理/日志管理
      {
        path: '/logmanagement',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/Efficiency/LogManagement.vue'),
        name: 'logmanagement'
      },
      //  过程流/生产管理/产值日志填写异常
      {
        path: '/check',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/Efficiency/Check.vue'),
        name: 'check'
      },
      //  过程流/生产管理/员工效率趋势
      {
        path: '/workerefficiency',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/Efficiency/WorkerEfficiency.vue'),
        name: 'workerefficiency'
      },
      // //  过程流/生产管理/设备
      {
        path: '/equipmentshow',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/Equipment/EquipmentShow.vue'),
        name: 'equipmentshow'
      },
      // //  过程流/生产管理/生管
      {
        path: '/uploadexcel',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Uploadexcel2.vue'),
        name: 'uploadexcel'
      },
      //  过程流/生产管理/销售订单汇总
      {
        path: '/ordercount',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Ordercount.vue'),
        name: 'ordercount'
      },
      //  过程流/生产管理/销售订单清单
      {
        path: '/orderlist',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Orderlist.vue'),
        name: 'orderlist'
      },
      //  过程流/生产管理/日计划
      {
        path: '/dayplan',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Dayplan.vue'),
        name: 'dayplan'
      },
      //  过程流/生产管理/物料需求
      {
        path: '/material',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Material.vue'),
        name: 'material'
      },
      //  过程流/生产管理/在线库存图表
      {
        path: '/totalchart',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/TotalChart.vue'),
        name: 'totalchart'
      },
      //  过程流/生产管理/导入BOM
      {
        path: '/uploadbomexcel',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Uploadbomexcel.vue'),
        name: 'uploadbomexcel'
      },
      //  过程流/生产管理/导入亨嘉仓库库存
      {
        path: '/UploadHJstoreexcel',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/UploadHJstoreexcel.vue'),
        name: 'uploadHJstoreexcel'
      },
      //  过程流/生产管理/导入入库入库明细
      {
        path: '/Uploadinbounddetailsexcel',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Uploadinbounddetailsexcel.vue'),
        name: 'uploadinbounddetailsexcel'
      },
      //  过程流/生产管理/导入生产领料明细
      {
        path: '/pickingdetails',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/pickingdetails.vue'),
        name: 'pickingdetails'
      },
      //  过程流/生产管理/BOM清单
      {
        path: '/bomlist',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Bomlist.vue'),
        name: 'bomlist'
      },
      //  过程流/生产管理/查看BOM清单
      {
        path: '/bomsetting',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Bomsetting.vue'),
        name: 'bomsetting'
      },
      //  过程流/生产管理/周转箱管理
      {
        path: '/box',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/Box.vue'),
        name: 'box'
      },
      //  过程流/生产管理/制氧阀生产入库单
      {
        path: '/productioninbound',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/productioninbound.vue'),
        name: 'productioninbound'
      },
      // 过程流/生产管理/生产条码
      {
        path: '/barcode',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/productivity/barcode.vue'),
        name: 'barcode'
      },
      //过程流/生成管理/三率
      {
        path: '/outputvaluehome',
        component: () => import('@/components/ProcessFlow/ProcessFlowProduce/Produce/OutputValue/ProduceHome.vue'),
        name: 'outputvaluehome'
      },
      // // 财务
      // {
      //   path: '/finance',
      //   component: () => import('@/components/ProcessFlow/Finance/Finance.vue'),
      //   name: 'finance'
      // },
      // // 研发
      // {
      //   path: '/development',
      //   component: () => import('@/components/ProcessFlow/Development/Development.vue'),
      //   name: 'development'
      // },
      // // 采购
      // {
      //   path: '/procurement',
      //   component: () => import('@/components/ProcessFlow/Procurement/Procurement.vue'),
      //   name: 'procurement'
      // },
      // okr
      {
        path: '/okr',
        component: () => import('@/components/ProcessFlow/OKR/Okr.vue'),
        name: 'okr'
      },
      // {
      //   path: '/viewokrplan',
      //   component: () => import('@/components/ProcessFlow/OKR/ViewOkrPlan.vue'),
      //   name: 'viewokrplan'
      // },
      // {
      //   path: '/createplan',
      //   component: () => import('@/components/ProcessFlow/OKR/CreatePlan.vue'),
      //   name: 'createplan'
      // },
      {
        path: '/gantt',
        component: () => import('@/components/ProcessFlow/OKR/Gantt.vue'),
        name: 'gantt'
      },
      // {
      //   path: '/okrstatistics',
      //   component: () => import('@/components/ProcessFlow/OKR/OkrStatistics.vue'),
      //   name: 'okrstatistics'
      // },
      //过程流/OKR/计划
      {
        path: '/okrplan',
        component: () => import('@/components/ProcessFlow/OKR/OkrPlan.vue'),
        name: 'okrplan'
      },
      //过程流/OKR/计划/我要找人协助
      {
        path: '/approval',
        component: () => import('@/components/ProcessFlow/OKR/Approval.vue'),
        name: 'approval'
      },
      //过程流/OKR/项目/我要创建总项目
      {
        path: '/createproject',
        component: () => import('@/components/ProcessFlow/OKR/CreateProject.vue'),
        name: 'createproject'
      },
      {
        path: '/createdepartment',
        component: () => import('@/components/ProcessFlow/OKR/CreateDepartment.vue'),
        name: 'createdepartment'
      },
      //过程流/OKR/计划管理
      {
        path: '/manageplan',
        component: () => import('@/components/ProcessFlow/OKR/ManagePlan.vue'),
        name: 'manageplan'
      },
      //过程流/OKR/项目管理
      {
        path: '/projectmanagement',
        component: () => import('@/components/ProcessFlow/OKR/ProjectManagement.vue'),
        name: 'projectmanagement'
      },
      {
        path: '',
        component: () => import('@/components/ProcessFlow/OKR/OkrHome2.vue'),
        name: 'okrhome'
      },
      //过程流/OKR/项目
      {
        path: '/project',
        component: () => import('@/components/ProcessFlow/OKR/Project.vue'),
        name: 'project'
      },
      //过程流/OKR/项目/项目
      {
        path: '/summaryprojects',
        component: () => import('@/components/ProcessFlow/OKR/SummaryProjects.vue'),
        name: 'summaryprojects'
      },
      //过程流/OKR/项目/我要看动态
      {
        path: '/projectnews',
        component: () => import('@/components/ProcessFlow/OKR/ProjectNews.vue'),
        name: 'projectnews'
      },
      {
        path: '/unfinishproject',
        component: () => import('@/components/ProcessFlow/OKR/UnfinishProject.vue'),
        name: 'unfinishproject'
      },
      // okr/签到
      {
        path: '/projectremind',
        component: () => import('@/components/ProcessFlow/OKR/ProjectRemind.vue'),
        name: 'projectremind'
      },
      // OKR/计划表
      {
        path: '/schedule',
        component: () => import('@/components/ProcessFlow/OKR/Schedule.vue'),
        name: 'schedule'
      },
      // 过程流/OA
      {
        path: '/oa',
        component: () => import('@/components/OA/OA.vue'),
        name: 'oa',
      },
      //
      {
        path: '/oa_operate',
        component: () => import('@/components/OA/OaOperate.vue'),
        name: 'oa_operate',
      },
      //过程流/OA/OA
      {
        path: '/oahome',
        component: () => import('@/components/OA/OaHome.vue'),
        name: 'oahome'
      },
      //过程流/OA/OA管理里后台
      {
        path: '/oaapproval',
        component: () => import('@/components/OA/OaApproval.vue'),
        name: 'oaapproval'
      },
      //过程流/OA/OA管理里后台/创建表单
      {
        path: '/oacreateform',
        component: () => import('@/components/OA/OaCreateForm.vue'),
        name: 'oacreateform'
      },
      //过程流/OA/提交表单
      {
        path: '/oaintoform',
        component: () => import('@/components/OA/OaIntoForm.vue'),
        name: 'oaintoform'
      },
      //过程流/OA/OA管理里后台/详情
      {
        path: '/oaformdata',
        component: () => import('@/components/OA/OaFormData.vue'),
        name: 'oaformdata'
      },
      //过程流/OA/待办
      {
        path: '/goedkeuringscentrum',
        component: () => import('@/components/OA/Goedkeuringscentrum.vue'),
        name: 'goedkeuringscentrum'
      },
      // 过程流/进销存
      {
        path: '/purchasesalestock',
        component: () => import('@/components/ProcessFlow/PurchaseSaleStock/purchasesalestock.vue'),
      },
      // 过程流/进销存/基础资料/仓库管理
      {
        path: '/warehouse',
        component: () => import('@/components/ProcessFlow/PurchaseSaleStock/WareHouse/WareHouse.vue'),
        name: 'warehouse',
      },
      //过程流/进销存/基础资料
      {
        path: '/fundamentaldate',
        component: () => import('@/components/ProcessFlow/PurchaseSaleStock/fundamentaldate/fundamentaldate.vue'),
        name: 'fundamentaldate',
      },
      //过程流/进销存/基础资料/品目信息单价
      {
        path: '/productpriceinfo',
        component: () => import('@/components/ProcessFlow/PurchaseSaleStock/fundamentaldate/ProductPriceInfo.vue'),
        name: 'productpriceinfo',
      },
      //过程流/进销存/采购
      {
        path: '/purchase',
        component: () => import('@/components/ProcessFlow/PurchaseSaleStock/purchase/purchase.vue'),
      },
      //过程流/进销存/采购订单
      {
        path: '/purchase_order',
        component: () => import('@/components/ProcessFlow/PurchaseSaleStock/purchase/purchase.vue'),
      },

      //过程流/CRM
      {
        path: '/crm',
        component: () => import('@/components/ProcessFlow/CRM/CRM.vue'),
        name: "crm",
      },
      // CRM/工作台
      // {
      //   path: '/workbench',
      //   component: () => import('@/components/ProcessFlow/CRM/Workbench/Workbench.vue'),
      //   name: "workbench",
      // },
      //过程流/CRM/客户列表/客户详情
      {
        path: '/customerparticulars',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/CustomerParticulars.vue'),
        name: 'customerparticulars'
      },
      // 过程流/CRM/设置
      {
        path: '/crmset',
        component: () => import('@/components/ProcessFlow/CRM/Settings/CrmSet.vue'),
        name: 'crmset'
      },
      //过程流/CRM/销售工作台/单据管理
      {
        path: '/invoicesmanage',
        component: () => import('@/components/ProcessFlow/CRM/InvoicesManage/InvoicesManage.vue'),
        name: "invoicesmanage",
      },
      // 过程流/CRM/销售工作台/拜访跟进/跟进记录
      {
        path: '/followuprecord',
        component: () => import('@/components/ProcessFlow/CRM/Workbench/FollowUpRecord'),
        name: "followuprecord",
      },
      // 过程流/CRM/销售工作台/拜访跟进/拜访签到
      {
        path: '/visitsignin',
        component: () => import('@/components/ProcessFlow/CRM/Workbench/VisitSignIn.vue'),
        name: "visitsignin",
      },
      //过程流/CRM/销售工作台
      {
        path: '/saleworkbench',
        component: () => import('@/components/ProcessFlow/CRM/Workbench/SaleWorkbench.vue'),
        name: "saleworkbench",
      },
      // CRM/销售工作台/拜访记录
      {
        path: '/visittofollowup',
        component: () => import('@/components/ProcessFlow/CRM/Workbench/VisitToFollowUp.vue'),
        name: "visittofollowup",
      },

      // 过程流/CRM/单据中心/新增入库单
      // {
      //   path: '/storageorder',
      //   component: () => import('@/components/ProcessFlow/CRM/StorageOrder/StorageOrder.vue'),
      //   name: "storageorder"
      // },
      // CRM/单据中心/入库单查询
      // {
      //   path: '/searchstorageorder',
      //   component: () => import('@/components/ProcessFlow/CRM/StorageOrder/SearchStorageOrder.vue'),
      //   name: "searchstorageorder"
      // },
      // CRM/单据中心/入库单明细
      // {
      //   path: '/detailstorageorder',
      //   component: () => import('@/components/ProcessFlow/CRM/StorageOrder/DetailStorageOrder.vue'),
      //   name: "detailstorageorder"
      // },
      // CRM/单据中心/出库申请
      {
        path: '/addoutboundorder',
        component: () => import('@/components/ProcessFlow/CRM/SalesOutbound/AddOutboundOrder.vue'),
        name: "addoutboundorder"
      },

      // CRM/单据管理/销售出库单查询
      {
        path: '/outboundorder',
        component: () => import('@/components/ProcessFlow/CRM/SalesOutbound/OutboundOrder.vue'),
        name: "outboundorder"
      },
      // CRM/销售工作台/销售出库/销售出库单明细
      {
        path: '/detailenquiry',
        component: () => import('@/components/ProcessFlow/CRM/SalesOutbound/DetailEnquiry.vue'),
        name: "detailenquiry"
      },
      //销售工作台/单据管理/报价单
      {
        path: '/quotation',
        component: () => import('@/components/ProcessFlow/CRM/Forms/Quotation/Quotation.vue'),
        name: "quotation"
      },

      //销售工作台/单据管理/添加报价单
      {
        path: '/addquotation',
        component: () => import('@/components/ProcessFlow/CRM/Forms/Quotation/AddQuotation.vue'),
        name: "addquotation"
      },
      //销售工作台/单据管理/添加订单
      {
        path: '/addorders',
        component: () => import('@/components/ProcessFlow/CRM/Forms/Orders/AddOrders.vue'),
        name: "addorders"
      },
      //销售工作台/单据管理/销售订单
      {
        path: '/orders',
        component: () => import('@/components/ProcessFlow/CRM/Forms/Orders/Orders.vue'),
        name: "orders"
      },
      //销售工作台/单据管理/长期合同
      {
        path: '/addlongtermagreement',
        component: () => import('@/components/ProcessFlow/CRM/Forms/LongTermAgreement/AddLongTermAgreement.vue'),
        name: "addlongtermagreement"
      },
      //销售工作台/单据管理/长期合同
      {
        path: '/longtermagreement',
        component: () => import('@/components/ProcessFlow/CRM/Forms/LongTermAgreement/LongTermAgreement.vue'),
        name: "longtermagreement"
      },
      // 销售工作台/单据管理/添加送样单
      {
        path: '/addsamplesubmission',
        component: () => import('@/components/ProcessFlow/CRM/Forms/SampleSubmissions/AddSampleSubmissions.vue'),
        name: "addsamplesubmission"
      },
      //销售工作台/单据管理/送样单
      {
        path: '/samplesubmission',
        component: () => import('@/components/ProcessFlow/CRM/Forms/SampleSubmissions/SampleSubmissions.vue'),
        name: "samplesubmission"
      },
      // 销售工作台/单据管理/添加售后申请
      {
        path: '/refundorder',
        component: () => import('@/components/ProcessFlow/CRM/Forms/RefundOrder/RefundOrder.vue'),
        name: "refundorder"
      },
      // 销售工作台/单据管理/退货申请查询
      {
        path: '/searchRefundorder',
        component: () => import('@/components/ProcessFlow/CRM/Forms/RefundOrder/SearchRefundOrder.vue'),
        name: "searchRefundorder"
      },
      //销售工作台/单据管理/添加回款申请
      {
        path: '/addbackmoney',
        component: () => import('@/components/ProcessFlow/CRM/Forms/BackMoney/AddBackMoney.vue'),
        name: "addbackmoney"
      },
      //销售工作台/单据管理/回款单
      {
        path: '/backmoney',
        component: () => import('@/components/ProcessFlow/CRM/Forms/BackMoney/Backmoney.vue'),
        name: "backmoney"
      },
      // 销售工作台/单据管理/分配申请
      {
        path: '/addassignmentrequest',
        component: () => import('@/components/ProcessFlow/CRM/Forms/AssignmentRequest/AddAssignmentRequest.vue'),
        name: "addassignmentrequest"
      },
      // 过程流/CRM/仪表板
      {
        path: '/dashboard',
        component: () => import('@/components/ProcessFlow/CRM/Dashboard/Dashboard.vue'),
        name: "dashboard",
      },
      // 过程流/CRM/仪表板/销售看板
      {
        path: '/salesboard',
        component: () => import('@/components/ProcessFlow/CRM/Dashboard/SalesDoard.vue'),
        name: "dashboard",
      },
      {
        path: '/phonesalesboard',
        component: () => import('@/components/ProcessFlow/CRM/Dashboard/PhoneSalesDoard.vue'),
        name: "phonesalesboard",
      },
      // 过程流/CRM/仪表板/利润看板
      {
        path: '/profitdashboard',
        component: () => import('@/components/ProcessFlow/CRM/Dashboard/ProfitDashboard.vue'),
        name: "profitdashboard",
      },
      // 过程流/CRM/仪表板/销售订单&出货统计
      {
        path: '/salesordershipmentstatistics',
        component: () => import('@/components/ProcessFlow/CRM/Dashboard/SalesOrderShipmentStatistics.vue'),
        name: "salesordershipmentstatistics",
      },
      //过程流/ERP/仪表板/采购订单
      {
        path: "/purchasedashboard",
        component: () => import("@/components/ERP/Dashboard/PurchaseDashboard.vue"),
        name: "purchasedashboard",
      },
      //过程流/仪表盘/合伙人看板
      // {
      //   path: '/partnerkanban',
      //   component: () => import('@/components/ProcessFlow/CRM/Dashboard/PartnerKanban.vue'),
      //   name: "partnerkanban",
      // },
      // CRM/单据中心
      // {
      //   path: '/documentcenter',
      //   component: () => import('@/components/ProcessFlow/CRM/DocumentCenter/DocumentCenter.vue'),
      //   name: "documentcenter",
      // },
      // 过程流/CRM/销售工作台/库存查询
      {
        path: '/inquire',
        component: () => import('@/components/ProcessFlow/CRM/Inquire/Inquire.vue'),
        name: "inquire",
      },
      //  过程流/CRM/销售工作台/库存查询/亿看库存查询
      {
        path: "/inventoryinquiry",
        component: () => import('@/components/ProcessFlow/CRM/Inquire/InquireInquire/InventoryInquiry.vue'),
        name: "inventoryinquiry",
      },
      //  过程流/CRM/销售工作台/库存查询/库存查询
      {
        path: '/inquireinquire',
        component: () => import('@/components/ProcessFlow/CRM/Inquire/InquireInquire/InquireInquire.vue'),
        name: "inquireinquire",
      },
      //  过程流/CRM/销售工作台/库存查询/按仓库库存查询
      {
        path: '/warehouseinquire',
        component: () => import('@/components/ProcessFlow/CRM/Inquire/InquireInquire/WarehouseInquire.vue'),
        name: "warehouseinquire",
      },
      // 过程流/CRM/销售工作台/客户管理
      {
        path: '/customermanagement',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/CustomerManagementHome.vue'),
        name: 'customermanagement'
      },
      // 过程流/CRM/销售工作台/帐信管理
      {
        path: '/accountcredit',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/AccountCredit.vue'),
        name: 'accountcredit'
      },
      // 过程流/CRM/销售工作台/客户管理/客户列表
      {
        path: '/customermanagementcustomer',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/Customer.vue'),
        name: 'customermanagementcustomer'
      },
      // 过程流/CRM/销售工作台/客户管理/客户分配
      {
        path: '/customermanagementhighseas',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/Highseas.vue'),
        name: 'customermanagementhighseas'
      },
      // 过程流/CRM/销售工作台/客户管理/联系人
      {
        path: '/customermanagementcontact',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/Contact.vue'),
        name: 'customermanagementcontact'
      },
      // 过程流/CRM/销售工作台/客户管理/客户履历
      {
        path: '/customerresume',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/CustomerResume.vue'),
        name: 'customerresume'
      },
      // 
      {
        path: "/invoice",
        component: () => import('@/components/ProcessFlow/CRM/Invoice/Invoice.vue'),
        name: 'invoice'
      },
      // 
      {
        path: "/invoicedetails",
        component: () => import('@/components/ProcessFlow/CRM/Invoice/InvoiceDetails.vue'),
        name: 'invoicedetails'
      },
      // 
      {
        path: "/noinvoicedetails",
        component: () => import('@/components/ProcessFlow/CRM/Invoice/NoInvoiceDetails.vue'),
        name: 'noinvoicedetails'
      },
      // 
      {
        path: "/signagereceivable",
        component: () => import('@/components/ProcessFlow/CRM/Dashboard/SignageReceivable.vue'),
        name: 'signagereceivable'
      },
      // 过程流/CRM/设置/公海
      {
        path: '/highseas',
        component: () => import('@/components/ProcessFlow/CRM/Settings/HighSeas.vue'),
        name: 'highseas'
      },
      // 过程流/CRM/设置/单价管理
      {
        path: '/preferential',
        component: () => import('@/components/ProcessFlow/CRM/Settings/Preferential.vue'),
        name: 'preferential'
      },
      // 过程流/CRM/设置/角色设置
      {
        path: '/rolesetting',
        component: () => import('@/components/ProcessFlow/CRM/Settings/RoleSetting.vue'),
        name: 'rolesetting'
      },
      // 过程流/CRM/设置/合伙人管理
      {
        path: '/partnermanagement',
        component: () => import('@/components/ProcessFlow/CRM/Settings/partnerManagement.vue'),
        name: 'rolesetting'
      },
      // 过程流/CRM/设置/高级设置
      {
        path: "/advancedset",
        component: () => import('@/components/ProcessFlow/CRM/Settings/AdvancedSet.vue'),
        name: 'advancedset'
      },
      //ERP
      {
        path: "/ERP",
        component: () => import('@/components/ERP/ERP.vue'),
        name: 'ERP'
      },
      //ERP/采购管理
      {
        path: "/purchasingmanagement",
        component: () => import('@/components/ERP/PurchasingManagement/PurchasingManagement.vue'),
        name: 'purchasingmanagement'
      },
      //ERP/采购管理/采购工作台
      {
        path: "/purchasingworkbench",
        component: () => import('@/components/ERP/PurchasingManagement/PurchasingWorkbench/PurchasingWorkbench.vue'),
        name: 'purchasingworkbench'
      },
      //ERP/采购工作台/客户管理
      {
        path: '/purchasecustomermanagement',
        component: () => import('@/components/ProcessFlow/CRM/CustomerManagement/CustomerManagementHome.vue'),
        name: 'purchasecustomermanagement'
      },
      // ERP/采购工作台/客户管理/供应商管理
      {
        path: '/supplier',
        component: () => import('@/components/ERP/SupplierManagement/Supplier.vue'),
        name: 'supplier'
      },
      // 过程流/CRM/销售工作台/客户管理/供应商分配
      {
        path: '/supplierassignment',
        component: () => import('@/components/ERP/SupplierManagement/SupplierAssignment.vue'),
        name: 'supplierassignment'
      },
      // ERP/采购工作台/客户管理/供应商管理/供应商详情
      {
        path: '/supplierparticulars',
        component: () => import('@/components/ERP/SupplierManagement/SupplierParticulars.vue'),
        name: 'supplierparticulars'
      },
      //ERP/采购管理/采购工作台/单据管理
      {
        path: "/receiptsmanagement",
        component: () => import('@/components/ERP/PurchasingManagement/PurchasingWorkbench/ReceiptsManagement/ReceiptsManagement.vue'),
        name: 'receiptsmanagement'
      },
      //ERP/采购管理/采购工作台/单据管理/查询采购入库
      {
        path: "/inquirepurchasing",
        component: () => import('@/components/ProcessFlow/CRM/Forms/Storage/Purchasing/InquirePurchasing.vue'),
        name: 'inquirepurchasing'
      },
      //ERP/生产工作台
      {
        path: "/productionworkbench",
        component: () => import('@/components/ERP/ProductionWorkbench/ProductionWorkbench.vue'),
        name: 'productionworkbench'
      },
      //ERP/仓库列表
      {
        path: "/warehouselist",
        component: () => import('@/components/ERP/WarehouseList/WarehouseList.vue'),
        name: 'warehouselist'
      },
      //ERP/生产工作台/单据管理/查询生产领料
      {
        path: '/inquireproductionmaterialrequisition',
        component: () => import('@/components/ProcessFlow/CRM/Forms/ProductionMaterialRequisition/InquireProductionMaterialRequisition.vue'),
        name: 'inquireproductionmaterialrequisition'
      },
      {
        path: '/warehousedetails',
        component: () => import('@/components/ERP/components/WarehouseDetails.vue'),
        name: 'warehousedetails'
      },
      //ERP/设置/
      {
        path: '/erpsetting',
        component: () => import('@/components/ERP/Setting/Setting.vue'),
        name: 'erpsetting'
      },
      //ERP/设置/刀具管理
      {
        path: '/cuttermanagement',
        component: () => import('@/components/ERP/Setting/CutterManagement/CutterManagement.vue'),
        name: 'cuttermanagement'
      },
      //ERP/设置/成本管理
      {
        path: '/costmanagement',
        component: () => import('@/components/ERP/Setting/CostManagement/CostManagement.vue'),
        name: 'costmanagement'
      },
      //ERP/设置/费用代码管理
      {
        path: '/chargecodemanagement',
        component: () => import('@/components/ERP/Setting/ChargeCodeManagement/ChargeCodeManagement.vue'),
        name: 'chargecodemanagement'
      },
      //ERP/设置/费用类别管理
      {
        path: '/expensecategory',
        component: () => import('@/components/ERP/Setting/ExpenseCategory/ExpenseCategory.vue'),
        name: 'expensecategory'
      },
      //ERP/单据管理/零星采购查询
      {
        path: "/demandapplication",
        component: () => import('@/components/ProcessFlow/CRM/Forms/DemandApplication/DemandApplication.vue'),
        name: 'demandapplication'
      },
      //ERP/单据管理/采购需求申请查询
      {
        path: "/purchasingrequisition",
        component: () => import('@/components/ProcessFlow/CRM/Forms/PurchasingRequisition/PurchasingRequisition.vue'),
        name: 'purchasingrequisition'
      },
      //ERP/单据管理/采购计划查询
      {
        path: "/procurementplan",
        component: () => import('@/components/ProcessFlow/CRM/Forms/ProcurementPlan/ProcurementPlan.vue'),
        name: 'procurementplan'
      },
      //ERP/单据管理/采购订单查询
      {
        path: "/procurementorders",
        component: () => import('@/components/ProcessFlow/CRM/Forms/ProcurementOrders/ProcurementOrders.vue'),
        name: 'procurementorders'
      },
    ]
  },
  // 技术流
  {
    path: '/technologyflow',
    component: () => import('@/components/Flows/TechnologyFlow/TechnologyFlow.vue'),
    name: 'technologyflow',
    children: [
      {
        path: '',
        name: 'technologyflowhome',
        component: () => import('@/components/Flows/TechnologyFlow/technologyflowhome.vue')
      },
      // //技术流/技术流首页
      // {
      //   path: '/technologyflowhome',
      //   name: 'technologyflowhome',
      //   component: () => import('@/components/Flows/TechnologyFlow/technologyflowhome.vue')
      // }
    ]
  },
  // {
  //   path: '/registrationform',
  //   component: () => import('@/components/ProcessFlow/ProcessFlowSale/Sales/salesordersform.vue'),
  //   name: 'registrationform'
  // },
  // 六流
  // {
  //   path: '/flows',
  //   component: () => import('@/components/Flows/Flows.vue'),
  //   name: 'flows',
  //   children: [{
  //     path: '',
  //     component: () => import('@/components/Flows/FlowsHome.vue'),
  //     name: 'flowshome'
  //   },
  //   ]
  // },
  // 系统设置
  {
    path: '/tools',
    component: () => import('@/components/Tools/Tools.vue'),
    name: 'tools',
    children: [{
      path: '',
      component: () => import('@/components/Tools/ToolsHome.vue'),
      name: 'toolshome'
    },
    // 系统设置/值日提醒
    {
      path: '/remindduty',
      component: () => import('@/components/Tools/Office/Office.vue'),
      name: 'remindduty'
    },
    // 系统设置/值日提醒/办公室管理
    {
      path: '/office_management',
      component: () => import('@/components/Tools/Office/Office_management.vue'),
      name: 'office_management'
    },
    // 系统设置/值日提醒/办公室管理/办公室管理
    {
      path: '/addlogs',
      component: () => import('@/components/Tools/AddLogs.vue'),
      name: 'addlogs'
    },
    //系统设置/值日提醒/值日员工管理
    {
      path: '/duty_management',
      component: () => import('@/components/Tools/Duty/Duty_management.vue'),
      name: 'duty_management'
    },
    // 系统设置/组件工具
    {
      path: '/subassembly',
      component: () => import('@/components/Tools/SubassemblyTools/SubassemblyToolsHome.vue'),
      name: 'subassembly'
    },
    // 系统设置/组件工具/下拉框组件管理
    {
      path: '/selecttoolsmanagement',
      component: () => import('@/components/Tools/SubassemblyTools/SelectToolsManagement.vue'),
      name: 'selecttoolsmanagement'
    },
    // 系统设置/组件工具/新手指南管理
    {
      path: '/newbieguide',
      component: () => import('@/components/Tools/SubassemblyTools/NewbieGuide.vue'),
      name: 'newbieguide'
    },
    // 辅助设置/组件工具/表格组件
    {
      path: '/tablesetting',
      name: 'tablesetting',
      component: () => import('@/components/Tools/SubassemblyTools/TableSetting.vue')
    },
    // 系统设置/OA表单管理
    {
      path: '/oaformmanagement',
      component: () => import('@/components/Tools/OAFormManagement/OAFormManagement.vue'),
      name: 'oaformmanagement'
    },
    // 系统设置/oa表单管理/所有
    {
      path: '/oaformsetting',
      component: () => import('@/components/Tools/OAFormManagement/OAFormSetting.vue'),
      name: 'oaformsetting'
    },
    // 系统设置/api日志
    {
      path: '/searchapihome',
      component: () => import('@/components/Tools/SearchApi/SearchApiHome.vue'),
      name: 'searchapihome'
    },
    // 系统设置/api日志/api日志查询
    {
      path: '/searchapi',
      component: () => import('@/components/Tools/SearchApi/SearchApi.vue'),
      name: 'searchapi'
    },
    // 系统设置/基础设置
    {
      path: '/foundation',
      name: 'foundation',
      component: () => import('@/components/Tools/Foundation/FoundationHome.vue')
    },
    // 系统设置/基础设置/编码规则
    {
      path: '/encodingrules',
      name: 'encodingrules',
      component: () => import('@/components/Tools/Foundation/EncodingRules.vue')
    },
    // 系统设置/基础设置/货币类型
    {
      path: '/currencytype',
      name: 'currencytype',
      component: () => import('@/components/Tools/Foundation/CurrencyType.vue')
    },

      // 66
      // {
      //   path: '/duty',
      //   component: () => import('@/components/Tools/Duty/Duty.vue'),
      //   name: 'duty'
      // },
      // 辅助设置/组件工具/下拉选项
      // {
      //   path: '/subassemblytools',
      //   component: () => import('@/components/Tools/SubassemblyTools/SubassemblyTools.vue'),
      //   name: 'subassemblytools'
      // },
      // 表格组件测试页面
      // {
      //   path: '/layout',
      //   name: 'layout',
      //   component: () => import('@/components/Tools/SubassemblyTools/Layout.vue')
      // },

    ]
  },
  // 权限管理
  {
    path: 'permission',
    component: () => import('@/components/Permission/Permission.vue'),
    name: 'permission',
    children: [{
      path: '',
      name: 'permissionhome',
      component: () => import('@/components/Permission/PermissionHome.vue')
    },
    // 权限管理/菜单
    {
      path: '/menu',
      name: 'menu',
      component: () => import('@/components/Permission/Menu/Menu.vue')
    },
    // 权限管理/菜单/菜单管理
    {
      path: '/menumanagement',
      name: 'menumanagement',
      component: () => import('@/components/Permission/Menu/MenuManagement.vue')
    },
    // 权限管理/用户
    {
      path: '/user',
      name: 'user',
      component: () => import('@/components/Permission/User/User.vue')
    },
    // 权限管理/用户/用户管理
    {
      path: '/usermanagement',
      name: 'usermanagement',
      component: () => import('@/components/Permission/User/UserManagement.vue')
    },
    // 权限管理/角色
    {
      path: '/role',
      name: 'role',
      component: () => import('@/components/Permission/Role/Role.vue')
    },
    // 权限管理/角色/所有
    {
      path: '/rolemanagement',
      name: 'rolemanagement',
      component: () => import('@/components/Permission/Role/RoleManagement.vue')
    },
    // 权限管理/角色/角色首页
    {
      path: '/rolehomepage',
      name: 'rolehomepage',
      component: () => import('@/components/Permission/Role/RoleHomepage.vue')
    },
    // 权限管理/表格/
    {
      path: '/tablesetup',
      name: 'tablesetup',
      component: () => import('@/components/Permission/Table/Table.vue')
    },
    // 权限管理/表格/所有
    {
      path: '/alltable',
      name: 'alltable',
      component: () => import('@/components/Permission/Table/AllTable.vue')
    },
    //权限管理/权限
    {
      path: '/authority',
      name: 'authority',
      component: () => import('@/components/Permission/Authority/Authority.vue')
    },
    //权限管理/权限/权限管理
    {
      path: '/authoritymanagement',
      name: 'authoritymanagement',
      component: () => import('@/components/Permission/Authority/AuthorityManagement.vue')
    },
    ]
  },
  // 个人首页
  {
    path: '/homepage',
    component: () => import('@/components/Homepage/Homepage.vue'),
    name: 'homepage',
    children: [
      {
        path: '',
        name: 'permissionhome',
        component: () => import('@/components/Homepage/HomepageHome.vue')
      },
      // 个人首页/首页
      {
        path: '/personagehome',
        name: 'personagehome',
        component: () => import('@/components/Homepage/personagehome.vue'),
      },
      // 个人首页/首页/主页
      {
        path: '/personaldetails',
        name: 'personaldetails',
        component: () => import('@/components/Homepage/personaldetails.vue'),
      },
      // 个人首页/首页/库存报表
      {
        path: '/inventoryreport',
        name: 'inventoryreport',
        component: () => import('@/components/Homepage/Inventoryreport.vue'),
      },
      //个人首页/设置首页
      {
        path: '/sethome',
        name: 'sethome',
        component: () => import('@/components/Homepage/SetHome.vue'),
      },
      {
        path: '/Infrom',
        name: 'Infrom',
        component: () => import('@/components/Tools/Inform/Inform.vue')
      },
      {
        path: '/calendar',
        name: 'calendar',
        component: () => import('@/components/Tools/Calendar/Calendar.vue')
      },
    ]
  },
    // 布局组件
    // {
    //   path: '/layout',
    //   name: 'layout',
    //   component: () => import('@/components/Layout/Layout.vue')
    // }
  ]
},
// {
//   path: '/loginphone',
//   component: () => import('@/views/Login.vue')
// },
//
// 登录
{
  path: '/login',
  component: () => import('@/views/Login1.vue')
},
// 扫码登录
{
  path: '/dingding_qr_login',
  name: 'dingding_qr_login',
  component: () => import('@/views/dingding_qr_login.vue')
},
//获取扫码登录数据
{
  path: '/get_user_info',
  name: 'get_user_info',
  component: () => import('@/views/get_user_info.vue')
},
{
  path: '/dingding_qr_login2',
  name: 'dingding_qr_login',
  component: () => import('@/views/dingding_qr_login2.vue')
},
// 注册
{
  path: '/register',
  component: () => import('@/views/Register.vue')
},
// 修改密码
{
  path: '/editpassword',
  component: () => import('@/views/EditPassword.vue')
},
// OA表单
{
  path: '/oaoperates',
  component: () => import('@/components/OA/OaOperate.vue')
},
// 应聘登记
{
  path: '/applyregistration',
  component: () => import('@/views/ApplyRegistration.vue'),
  name: 'applyregistration'
},
//招聘栏
{
  path: '/recruitmentadvertising',
  component: () => import('@/views/RecruitmentAdvertising.vue'),
  name: 'recruitmentadvertising'
},
// 测试
{
  path: '/test',
  component: () => import('@/views/test.vue'),
  name: 'test'
},
// 提交成功
{
  path: '/submityes',
  component: () => import('@/views/submityes.vue'),
  name: 'submityes'
},
// 过程流// 人事/新增厂区安全检查表
{
  path: '/fillchecklist',
  component: () => import('@/components/ProcessFlow/ProcessFlowHr/HR/FillCheckList.vue'),
  name: 'fillchecklist'
},

]

const router = new VueRouter({
  // mode: 'history',
  // base: './',
  // history: createWebHistory(),
  routes
  // scrollBehavior(to, from, savedPosition) {
  //   // 始终滚动到顶部
  //   return { top: 100 }
  // },
})
router.beforeEach(async (to, from, next) => {
  Vue.prototype.$http.defaults.baseURL = Vue.prototype.$baseurl
  setTimeout(() => {
    document.querySelector('html').scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, 500);
  // console.log(to, from);
  // if (from.path === '/dingding_qr_login') return next()
  // if (from.path == 'oaintoform') {
  //   this.
  // }

  // if (router.app.$options.store.state.isBack == false) {
  // //  console.log("非返回按钮");
  //   return next()
  // }
  // if (router.app.$options.store.state.isBack == true) {
  // //  console.log(to,from);
  //   // return next()
  // }
  // console.log(to, from, "555")
  if (to.path == "/Login" && from.path == "/inaccessible" && window.localStorage.getItem('token') != undefined && window.localStorage.getItem('token') != null && window.localStorage.getItem('token') != 'null') {
    return next({
      path: '/',
    })
  }
  if (to.name == "oa_operate") {
    window.localStorage.setItem('recordingUrl', to.fullPath)
    store.commit('changeRecordingUrl1', to.fullPath)
  }
  //  console.log(store)
  if (to.path === '/dingding_qr_login') {
    Vue.prototype.$http.defaults.baseURL = 'https://api.dingtalk.com'
    // console.log(to);
    return next()
  }

  if (to.path === '/get_user_info') {
    return next()
  }
  if (to.path === '/dingding_qr_login2') {
    // console.log(to);
    return next()
  }
  if (to.path === '/test') return next()
  if (to.path === '/recruitmentadvertising') return next()
  if (to.path === '/Login') {
    window.localStorage.clear()
    window.sessionStorage.clear()
    return next()
  }
  if (to.path === '/applyregistration') return next()
  if (to.path === '/submityes') return next()
  if (to.path === '/checkstandard') return next()
  if (to.name === 'fillchecklist') return next()
  if (to.path === '/visitrecords') return next()
  if (to.path === '/inaccessible') return next()
  if (to.path === '/register') {
    const res = await Vue.prototype.$http.get('register/')
    //  console.log(res)
    if (res.status !== 200) {
      return next({
        path: '/login',
        query: {
          rediction: to.fullPath
        }
      })
    } else {
      return next()
    }
  }
  // if(from.path === '/workerefficiency/:id'){
  //
  //   return next();
  // }

  if (window.localStorage.getItem('token')) {
    const flag = JSON.parse(window.localStorage.getItem('routers')).filter((item) => {

      if (item === to.fullPath) {
        //  console.log(to, item);
        return item
      } else {
        if (item == to.path) {
          return item
        }
      }
    })
    //  console.log(flag, 'quanxian');
    if (flag.length > 0) {
      return next()
    } else {
      if (to.path == '/login') {
        return next()
      } else {
        // return next()
        return next({
          path: '/inaccessible'
        }) // 拥有权限
      }
    }
  } else {
    store.state.recordingUrl = to.fullPath
    // console.log(store.state.recordingUrl)
  }
  next({
    path: '/Login',
    query: {
      rediction: to.fullPath
    }
  })
  // Vue.prototype.$message.error('请重新登录');
  // console.log(this,Vue);
})

export default router